import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Icon, DropdownMenu, Dropdown, DropdownToggle } from '@just-ai/just-ui';

import { ItemMenu } from './types';
import styles from './styles.module.scss';
import { useToggle } from '@just-ai/just-ui/dist/utils';
import MenuItem from './MenuItem';
import cn from 'classnames';

interface MultiLevelSelectInterface {
  options: ItemMenu[];
  activeItemCode?: ItemMenu['value'];
  baseMenuItem?: ItemMenu;
  activeOptionsIsProcessing?: boolean;
  'data-test-id'?: string;
  onSelect: (item: ItemMenu, stop?: boolean) => void;
  width?: string;
  disabledByLicense?: boolean;
}
const MultiLevelSelect = function ({
  options,
  activeItemCode,
  baseMenuItem,
  onSelect,
  activeOptionsIsProcessing,
  width = '290px',
  'data-test-id': dataTestId = 'LogPanel.toolbar.time',
  disabledByLicense,
}: MultiLevelSelectInterface) {
  const [isOpen, , close, toggle] = useToggle(false);
  const [expandedChild, setExpandedChild] = useState<ItemMenu>();

  useEffect(() => {
    if (isOpen) return;
    setExpandedChild(undefined);
  }, [isOpen]);

  const onSelectInner = useCallback(
    item => {
      if (item.children && item.children?.length > 0) {
        setExpandedChild(prevItem => (prevItem?.value === item.value ? prevItem : item));
        return;
      }
      close();
      onSelect(item);
    },
    [close, onSelect]
  );

  const optionsFlatMap = useMemo(() => {
    return options.reduce((acc, el) => {
      acc[el.value] = el;
      if (el.children) {
        el.children.forEach(el => {
          acc[el.value] = el;
        });
      }
      return acc;
    }, {} as Record<string, ItemMenu>);
  }, [options]);

  const selectedItemView = useMemo(() => {
    const activeItem = activeItemCode
      ? optionsFlatMap[activeItemCode]
      : Object.values(optionsFlatMap).find(el => !el.children || el.children?.length === 0);

    if (!activeItem) return;
    if (baseMenuItem) {
      return {
        ...activeItem,
        label: baseMenuItem.label,
      };
    }

    const parts = activeItem.value.split(':');
    if (parts.length === 1) return activeItem;

    const parentValue = parts.slice(0, parts.length - 1).join('');
    const parentItem = optionsFlatMap[parentValue];
    if (!parentItem) return activeItem;
    return {
      ...activeItem,
      label: parentItem.label,
    };
  }, [activeItemCode, baseMenuItem, optionsFlatMap]);

  const selectActive = useCallback(() => {
    if (!selectedItemView || disabledByLicense) return;
    onSelect(selectedItemView, activeOptionsIsProcessing);
    close();
  }, [selectedItemView, disabledByLicense, onSelect, activeOptionsIsProcessing, close]);

  const onToggle = useCallback(() => {
    !disabledByLicense && toggle();
  }, [disabledByLicense, toggle]);

  const IconCmp = selectedItemView?.iconCmp;
  return (
    <Dropdown
      style={{ '--dropdown-width': width } as React.CSSProperties}
      direction='down'
      isOpen={isOpen}
      addonType='prepend'
      toggle={toggle}
      data-test-id={dataTestId}
    >
      <DropdownToggle tag='div' outline withoutPadding compact disabled>
        <div className={cn(styles.MultiLevelSelect, { [styles.disabled]: disabledByLicense })}>
          <div
            className={cn(styles.MultiLevelSelect__content, styles.MultiLevelSelect__menuElement)}
            onClick={selectActive}
            data-test-id={`${dataTestId}-selected`}
          >
            {activeOptionsIsProcessing ? (
              <Icon name='faStop' color='danger' size='sm' />
            ) : (
              IconCmp && <IconCmp size='sm' />
            )}
            <div>{selectedItemView?.label || ''}</div>
          </div>
          <div
            className={cn(styles.MultiLevelSelect__dropdownToggle, styles.MultiLevelSelect__menuElement)}
            onClick={onToggle}
            data-test-id={`${dataTestId}-openDropDown`}
          >
            <Icon name='faCaretDown' size='sm' />
          </div>
          <DropdownMenu className={styles.MultiLevelSelect__menu}>
            {options.map(option => (
              <MenuItem
                key={option.value}
                menuItem={option}
                expanded={expandedChild?.value === option.value}
                onSelect={onSelectInner}
                data-test-id={`${dataTestId}:${option.value}`}
              />
            ))}
          </DropdownMenu>
        </div>
      </DropdownToggle>
    </Dropdown>
  );
};

export default React.memo(MultiLevelSelect);
