// @ts-nocheck
/**
 * Audit Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountEventDataDto
 */
export interface AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof AccountEventDataDto
   */
  eventType: string;
}
/**
 *
 * @export
 * @interface AccountEventDto
 */
export interface AccountEventDto {
  /**
   *
   * @type {number}
   * @memberof AccountEventDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  requestId: string;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  serviceName: string;
  /**
   *
   * @type {number}
   * @memberof AccountEventDto
   */
  accountId?: number;
  /**
   *
   * @type {Date}
   * @memberof AccountEventDto
   */
  creationTime: Date;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  eventType: string;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  localizedName: string;
  /**
   *
   * @type {EventStatus}
   * @memberof AccountEventDto
   */
  status: EventStatus;
  /**
   *
   * @type {UserInfoDto}
   * @memberof AccountEventDto
   */
  user?: UserInfoDto;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  projectShortName?: string;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  botId?: string;
  /**
   *
   * @type {number}
   * @memberof AccountEventDto
   */
  processingTime?: number;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  exception?: string;
  /**
   * JSON object. Cannot be a array or literal.
   * @type {object}
   * @memberof AccountEventDto
   */
  data?: object;
  /**
   *
   * @type {string}
   * @memberof AccountEventDto
   */
  comment?: string;
  /**
   *
   * @type {TargetUserInfoDto}
   * @memberof AccountEventDto
   */
  targetUser?: TargetUserInfoDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AccountEventType {
  USERLOGIN = 'USER_LOGIN',
  USERLOGOUT = 'USER_LOGOUT',
  BOTPROJECTCREATE = 'BOT_PROJECT_CREATE',
  BOTPROJECTUPDATE = 'BOT_PROJECT_UPDATE',
  BOTPROJECTDELETE = 'BOT_PROJECT_DELETE',
  BOTPROJECTCLONE = 'BOT_PROJECT_CLONE',
  BOTPROJECTIMPORT = 'BOT_PROJECT_IMPORT',
  BOTPROJECTEXPORT = 'BOT_PROJECT_EXPORT',
  INBOUNDCHANNELCREATE = 'INBOUND_CHANNEL_CREATE',
  INBOUNDCHANNELUPDATE = 'INBOUND_CHANNEL_UPDATE',
  INBOUNDCHANNELDELETE = 'INBOUND_CHANNEL_DELETE',
  INBOUNDCHANNELBLOCK = 'INBOUND_CHANNEL_BLOCK',
  INBOUNDCHANNELUNBLOCK = 'INBOUND_CHANNEL_UNBLOCK',
  OPERATORCHANNELCREATE = 'OPERATOR_CHANNEL_CREATE',
  OPERATORCHANNELUPDATE = 'OPERATOR_CHANNEL_UPDATE',
  OPERATORCHANNELDELETE = 'OPERATOR_CHANNEL_DELETE',
  USERCREATE = 'USER_CREATE',
  USERDELETE = 'USER_DELETE',
  USERUPDATE = 'USER_UPDATE',
  USERPERMISSIONSUPDATE = 'USER_PERMISSIONS_UPDATE',
  USERPASSWORDCHANGE = 'USER_PASSWORD_CHANGE',
  USEREMAILCHANGE = 'USER_EMAIL_CHANGE',
  USERAVATARCHANGE = 'USER_AVATAR_CHANGE',
  FULLNAMECHANGE = 'FULL_NAME_CHANGE',
  LANGUAGECHANGE = 'LANGUAGE_CHANGE',
  USERBLOCK = 'USER_BLOCK',
  USERUNBLOCK = 'USER_UNBLOCK',
  CLIENTBLOCK = 'CLIENT_BLOCK',
  CLIENTUNBLOCK = 'CLIENT_UNBLOCK',
  LOGISSUECREATE = 'LOG_ISSUE_CREATE',
  LOGISSUEUPDATE = 'LOG_ISSUE_UPDATE',
  LOGISSUECLOSE = 'LOG_ISSUE_CLOSE',
  MESSAGELABELGROUPCREATE = 'MESSAGE_LABEL_GROUP_CREATE',
  MESSAGELABELGROUPUPDATE = 'MESSAGE_LABEL_GROUP_UPDATE',
  MESSAGELABELGROUPDELETE = 'MESSAGE_LABEL_GROUP_DELETE',
  MESSAGELABELCREATE = 'MESSAGE_LABEL_CREATE',
  MESSAGELABELUPDATE = 'MESSAGE_LABEL_UPDATE',
  MESSAGELABELDELETE = 'MESSAGE_LABEL_DELETE',
  SESSIONLABELCREATE = 'SESSION_LABEL_CREATE',
  SESSIONLABELUPDATE = 'SESSION_LABEL_UPDATE',
  SESSIONLABELDELETE = 'SESSION_LABEL_DELETE',
  ISSUELABELCREATE = 'ISSUE_LABEL_CREATE',
  ISSUELABELUPDATE = 'ISSUE_LABEL_UPDATE',
  ISSUELABELDELETE = 'ISSUE_LABEL_DELETE',
  PROJECTTAGCREATE = 'PROJECT_TAG_CREATE',
  PROJECTTAGUPDATE = 'PROJECT_TAG_UPDATE',
  PROJECTTAGDELETE = 'PROJECT_TAG_DELETE',
  PHONEVERIFICATIONREQUEST = 'PHONE_VERIFICATION_REQUEST',
  PHONEVERIFICATION = 'PHONE_VERIFICATION',
  MASKINGRULECREATE = 'MASKING_RULE_CREATE',
  MASKINGRULEUPDATE = 'MASKING_RULE_UPDATE',
  MASKINGRULEENABLE = 'MASKING_RULE_ENABLE',
  MASKINGRULEDISABLE = 'MASKING_RULE_DISABLE',
  MASKINGRULEDELETE = 'MASKING_RULE_DELETE',
  MASKINGALLRULESDELETE = 'MASKING_ALL_RULES_DELETE',
}

/**
 *
 * @export
 * @interface AccountEventsDto
 */
export interface AccountEventsDto {
  /**
   *
   * @type {Pagination}
   * @memberof AccountEventsDto
   */
  paging: Pagination;
  /**
   *
   * @type {Array<AccountEventDto>}
   * @memberof AccountEventsDto
   */
  events: Array<AccountEventDto>;
}
/**
 *
 * @export
 * @interface AuditFilter
 */
export interface AuditFilter {
  /**
   *
   * @type {FilterKey}
   * @memberof AuditFilter
   */
  key: FilterKey;
  /**
   *
   * @type {string}
   * @memberof AuditFilter
   */
  type: string;
}
/**
 *
 * @export
 * @interface BotProjectEventDataDto
 */
export interface BotProjectEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof BotProjectEventDataDto
   */
  name?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof BotProjectEventDataDto
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @interface BotProjectEventDataDtoAllOf
 */
export interface BotProjectEventDataDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof BotProjectEventDataDtoAllOf
   */
  name?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof BotProjectEventDataDtoAllOf
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @interface CheckboxComparisonOption
 */
export interface CheckboxComparisonOption {
  /**
   *
   * @type {CheckboxComparisonType}
   * @memberof CheckboxComparisonOption
   */
  type: CheckboxComparisonType;
  /**
   *
   * @type {string}
   * @memberof CheckboxComparisonOption
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckboxComparisonOption
   */
  isDefault: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CheckboxComparisonType {
  NOTIMPORTANT = 'NOT_IMPORTANT',
  NOTEXISTS = 'NOT_EXISTS',
  EXISTS = 'EXISTS',
  CONTAINS = 'CONTAINS',
  NOTCONTAINS = 'NOT_CONTAINS',
}

/**
 *
 * @export
 * @interface CheckboxFilter
 */
export interface CheckboxFilter extends AuditFilter {
  /**
   *
   * @type {Array<string>}
   * @memberof CheckboxFilter
   */
  options: Array<string>;
  /**
   *
   * @type {CheckboxComparisonType}
   * @memberof CheckboxFilter
   */
  comparisonType?: CheckboxComparisonType;
}
/**
 *
 * @export
 * @interface CheckboxFilterAllOf
 */
export interface CheckboxFilterAllOf {
  /**
   *
   * @type {Array<string>}
   * @memberof CheckboxFilterAllOf
   */
  options: Array<string>;
  /**
   *
   * @type {CheckboxComparisonType}
   * @memberof CheckboxFilterAllOf
   */
  comparisonType?: CheckboxComparisonType;
}
/**
 *
 * @export
 * @interface CheckboxFilterSettings
 */
export interface CheckboxFilterSettings extends FilterSettings {
  /**
   *
   * @type {string}
   * @memberof CheckboxFilterSettings
   */
  placeholder: string;
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof CheckboxFilterSettings
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {number}
   * @memberof CheckboxFilterSettings
   */
  defaultPageSizeForOptions: number;
  /**
   *
   * @type {Array<FilterOption>}
   * @memberof CheckboxFilterSettings
   */
  staticOptions: Array<FilterOption>;
}
/**
 *
 * @export
 * @interface CheckboxFilterSettingsAllOf
 */
export interface CheckboxFilterSettingsAllOf {
  /**
   *
   * @type {string}
   * @memberof CheckboxFilterSettingsAllOf
   */
  placeholder: string;
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof CheckboxFilterSettingsAllOf
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {number}
   * @memberof CheckboxFilterSettingsAllOf
   */
  defaultPageSizeForOptions: number;
  /**
   *
   * @type {Array<FilterOption>}
   * @memberof CheckboxFilterSettingsAllOf
   */
  staticOptions: Array<FilterOption>;
}
/**
 *
 * @export
 * @interface DateTimeRangeDefaultValue
 */
export interface DateTimeRangeDefaultValue {
  /**
   *
   * @type {number}
   * @memberof DateTimeRangeDefaultValue
   */
  offset: number;
  /**
   *
   * @type {DateTimeUnit}
   * @memberof DateTimeRangeDefaultValue
   */
  unit: DateTimeUnit;
}
/**
 *
 * @export
 * @interface DateTimeRangeFilter
 */
export interface DateTimeRangeFilter extends AuditFilter {
  /**
   *
   * @type {Date}
   * @memberof DateTimeRangeFilter
   */
  from?: Date;
  /**
   *
   * @type {Date}
   * @memberof DateTimeRangeFilter
   */
  to?: Date;
}
/**
 *
 * @export
 * @interface DateTimeRangeFilterAllOf
 */
export interface DateTimeRangeFilterAllOf {
  /**
   *
   * @type {Date}
   * @memberof DateTimeRangeFilterAllOf
   */
  from?: Date;
  /**
   *
   * @type {Date}
   * @memberof DateTimeRangeFilterAllOf
   */
  to?: Date;
}
/**
 *
 * @export
 * @interface DateTimeRangeFilterSettings
 */
export interface DateTimeRangeFilterSettings extends FilterSettings {
  /**
   *
   * @type {DateTimeRangeDefaultValue}
   * @memberof DateTimeRangeFilterSettings
   */
  defaultValue?: DateTimeRangeDefaultValue;
}
/**
 *
 * @export
 * @interface DateTimeRangeFilterSettingsAllOf
 */
export interface DateTimeRangeFilterSettingsAllOf {
  /**
   *
   * @type {DateTimeRangeDefaultValue}
   * @memberof DateTimeRangeFilterSettingsAllOf
   */
  defaultValue?: DateTimeRangeDefaultValue;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DateTimeUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

/**
 *
 * @export
 * @interface DeviceInfoDto
 */
export interface DeviceInfoDto {
  /**
   *
   * @type {string}
   * @memberof DeviceInfoDto
   */
  os?: string;
  /**
   *
   * @type {string}
   * @memberof DeviceInfoDto
   */
  browser?: string;
}
/**
 *
 * @export
 * @interface E2ECheckResult
 */
export interface E2ECheckResult {
  /**
   *
   * @type {string}
   * @memberof E2ECheckResult
   */
  totalStatus?: string;
  /**
   *
   * @type {number}
   * @memberof E2ECheckResult
   */
  totalTime?: number;
  /**
   *
   * @type {Array<E2EComponent>}
   * @memberof E2ECheckResult
   */
  e2EComponents?: Array<E2EComponent>;
}
/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime?: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EventStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

/**
 *
 * @export
 * @interface FilterInfo
 */
export interface FilterInfo {
  /**
   *
   * @type {FilterKey}
   * @memberof FilterInfo
   */
  key: FilterKey;
  /**
   *
   * @type {FilterType}
   * @memberof FilterInfo
   */
  type: FilterType;
  /**
   *
   * @type {string}
   * @memberof FilterInfo
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof FilterInfo
   */
  isDefault: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FilterInfo
   */
  isRequired: boolean;
  /**
   *
   * @type {FilterSettings}
   * @memberof FilterInfo
   */
  settings?: FilterSettings;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum FilterKey {
  EVENTTIME = 'EVENT_TIME',
  EVENTTYPE = 'EVENT_TYPE',
  PROJECTSHORTNAME = 'PROJECT_SHORT_NAME',
  USERLOGIN = 'USER_LOGIN',
  TARGETUSERLOGIN = 'TARGET_USER_LOGIN',
  USERLOGINORTARGETUSERLOGIN = 'USER_LOGIN_OR_TARGET_USER_LOGIN',
}

/**
 *
 * @export
 * @interface FilterOption
 */
export interface FilterOption {
  /**
   *
   * @type {string}
   * @memberof FilterOption
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof FilterOption
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof FilterOption
   */
  isSelected: boolean;
  /**
   *
   * @type {number}
   * @memberof FilterOption
   */
  frequency?: number;
  /**
   *
   * @type {string}
   * @memberof FilterOption
   */
  parentValue?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FilterOption
   */
  childValues?: Array<string>;
}
/**
 *
 * @export
 * @interface FilterSettings
 */
export interface FilterSettings {
  /**
   *
   * @type {FilterKey}
   * @memberof FilterSettings
   */
  key: FilterKey;
  /**
   *
   * @type {string}
   * @memberof FilterSettings
   */
  type: string;
  /**
   *
   * @type {boolean}
   * @memberof FilterSettings
   */
  filterDependent: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FilterSettings
   */
  withFilterInput: boolean;
  /**
   *
   * @type {string}
   * @memberof FilterSettings
   */
  helpMessage?: string;
  /**
   *
   * @type {string}
   * @memberof FilterSettings
   */
  helpLink?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum FilterType {
  TEXT = 'TEXT',
  DATETIMERANGE = 'DATE_TIME_RANGE',
  NUMERICRANGE = 'NUMERIC_RANGE',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  TREE = 'TREE',
}

/**
 *
 * @export
 * @interface FiltersDto
 */
export interface FiltersDto {
  /**
   *
   * @type {Array<AuditFilter>}
   * @memberof FiltersDto
   */
  filters?: Array<AuditFilter>;
}
/**
 *
 * @export
 * @interface InboundChannelEventDataDto
 */
export interface InboundChannelEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDto
   */
  channelType?: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDto
   */
  botserverBotId?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof InboundChannelEventDataDto
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @interface InboundChannelEventDataDtoAllOf
 */
export interface InboundChannelEventDataDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDtoAllOf
   */
  channelType?: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDtoAllOf
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelEventDataDtoAllOf
   */
  botserverBotId?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof InboundChannelEventDataDtoAllOf
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum IntervalType {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

/**
 *
 * @export
 * @interface NameEventDataDto
 */
export interface NameEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof NameEventDataDto
   */
  name?: string;
}
/**
 *
 * @export
 * @interface NameEventDataDtoAllOf
 */
export interface NameEventDataDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof NameEventDataDtoAllOf
   */
  name?: string;
}
/**
 *
 * @export
 * @interface NumericRangeFilter
 */
export interface NumericRangeFilter extends AuditFilter {
  /**
   *
   * @type {number}
   * @memberof NumericRangeFilter
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof NumericRangeFilter
   */
  to?: number;
}
/**
 *
 * @export
 * @interface NumericRangeFilterAllOf
 */
export interface NumericRangeFilterAllOf {
  /**
   *
   * @type {number}
   * @memberof NumericRangeFilterAllOf
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof NumericRangeFilterAllOf
   */
  to?: number;
}
/**
 *
 * @export
 * @interface NumericRangeFilterSettings
 */
export interface NumericRangeFilterSettings extends FilterSettings {}
/**
 *
 * @export
 * @interface OperatorChannelEventDataDto
 */
export interface OperatorChannelEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof OperatorChannelEventDataDto
   */
  operatorChannelType?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorChannelEventDataDto
   */
  livechatId?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof OperatorChannelEventDataDto
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @interface OperatorChannelEventDataDtoAllOf
 */
export interface OperatorChannelEventDataDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof OperatorChannelEventDataDtoAllOf
   */
  operatorChannelType?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorChannelEventDataDtoAllOf
   */
  livechatId?: string;
  /**
   *
   * @type {Array<UpdatedField>}
   * @memberof OperatorChannelEventDataDtoAllOf
   */
  updatedFields?: Array<UpdatedField>;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface RadioFilter
 */
export interface RadioFilter extends AuditFilter {
  /**
   *
   * @type {string}
   * @memberof RadioFilter
   */
  option?: string;
}
/**
 *
 * @export
 * @interface RadioFilterAllOf
 */
export interface RadioFilterAllOf {
  /**
   *
   * @type {string}
   * @memberof RadioFilterAllOf
   */
  option?: string;
}
/**
 *
 * @export
 * @interface RadioFilterSettings
 */
export interface RadioFilterSettings extends FilterSettings {
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof RadioFilterSettings
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {Array<FilterOption>}
   * @memberof RadioFilterSettings
   */
  staticOptions: Array<FilterOption>;
}
/**
 *
 * @export
 * @interface RadioFilterSettingsAllOf
 */
export interface RadioFilterSettingsAllOf {
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof RadioFilterSettingsAllOf
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {Array<FilterOption>}
   * @memberof RadioFilterSettingsAllOf
   */
  staticOptions: Array<FilterOption>;
}
/**
 *
 * @export
 * @interface SaveAccountEventDto
 */
export interface SaveAccountEventDto {
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  requestId: string;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  serviceName: string;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  instanceId: string;
  /**
   *
   * @type {number}
   * @memberof SaveAccountEventDto
   */
  accountId?: number;
  /**
   *
   * @type {Date}
   * @memberof SaveAccountEventDto
   */
  creationTime: Date;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  eventType: string;
  /**
   *
   * @type {EventStatus}
   * @memberof SaveAccountEventDto
   */
  status: EventStatus;
  /**
   *
   * @type {UserInfoDto}
   * @memberof SaveAccountEventDto
   */
  user: UserInfoDto;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  projectShortName?: string;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  botId?: string;
  /**
   *
   * @type {number}
   * @memberof SaveAccountEventDto
   */
  processingTime?: number;
  /**
   *
   * @type {string}
   * @memberof SaveAccountEventDto
   */
  exception?: string;
  /**
   * JSON object. Cannot be a array or literal.
   * @type {object}
   * @memberof SaveAccountEventDto
   */
  data?: object;
  /**
   *
   * @type {TargetUserInfoDto}
   * @memberof SaveAccountEventDto
   */
  targetUser?: TargetUserInfoDto;
}
/**
 *
 * @export
 * @interface SaveAccountEventsDto
 */
export interface SaveAccountEventsDto {
  /**
   *
   * @type {Array<SaveAccountEventDto>}
   * @memberof SaveAccountEventsDto
   */
  events: Array<SaveAccountEventDto>;
}
/**
 *
 * @export
 * @interface SaveSystemEventDto
 */
export interface SaveSystemEventDto {
  /**
   *
   * @type {string}
   * @memberof SaveSystemEventDto
   */
  requestId: string;
  /**
   *
   * @type {string}
   * @memberof SaveSystemEventDto
   */
  serviceName: string;
  /**
   *
   * @type {string}
   * @memberof SaveSystemEventDto
   */
  instanceId: string;
  /**
   *
   * @type {string}
   * @memberof SaveSystemEventDto
   */
  eventType: string;
  /**
   *
   * @type {EventStatus}
   * @memberof SaveSystemEventDto
   */
  status: EventStatus;
  /**
   *
   * @type {Date}
   * @memberof SaveSystemEventDto
   */
  creationTime: Date;
  /**
   *
   * @type {number}
   * @memberof SaveSystemEventDto
   */
  processingTime?: number;
  /**
   *
   * @type {string}
   * @memberof SaveSystemEventDto
   */
  exception?: string;
  /**
   * JSON object. Cannot be a array or literal.
   * @type {object}
   * @memberof SaveSystemEventDto
   */
  data?: object;
}
/**
 *
 * @export
 * @interface SystemEventDataDto
 */
export interface SystemEventDataDto {
  /**
   *
   * @type {string}
   * @memberof SystemEventDataDto
   */
  eventType: string;
}
/**
 *
 * @export
 * @interface SystemEventDto
 */
export interface SystemEventDto {
  /**
   *
   * @type {number}
   * @memberof SystemEventDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SystemEventDto
   */
  requestId: string;
  /**
   *
   * @type {string}
   * @memberof SystemEventDto
   */
  serviceName: string;
  /**
   *
   * @type {Date}
   * @memberof SystemEventDto
   */
  creationTime: Date;
  /**
   *
   * @type {string}
   * @memberof SystemEventDto
   */
  eventType: string;
  /**
   *
   * @type {EventStatus}
   * @memberof SystemEventDto
   */
  status: EventStatus;
  /**
   *
   * @type {number}
   * @memberof SystemEventDto
   */
  processingTime?: number;
  /**
   *
   * @type {string}
   * @memberof SystemEventDto
   */
  exception?: string;
  /**
   * JSON object. Cannot be a array or literal.
   * @type {object}
   * @memberof SystemEventDto
   */
  data?: object;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SystemEventType {
  STARTSERVICE = 'START_SERVICE',
  SHUTDOWNSERVICE = 'SHUTDOWN_SERVICE',
}

/**
 *
 * @export
 * @interface SystemEventsDto
 */
export interface SystemEventsDto {
  /**
   *
   * @type {Pagination}
   * @memberof SystemEventsDto
   */
  paging: Pagination;
  /**
   *
   * @type {Array<SystemEventDto>}
   * @memberof SystemEventsDto
   */
  events: Array<SystemEventDto>;
}
/**
 *
 * @export
 * @interface TargetUserInfoDto
 */
export interface TargetUserInfoDto {
  /**
   *
   * @type {number}
   * @memberof TargetUserInfoDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TargetUserInfoDto
   */
  login: string;
}
/**
 *
 * @export
 * @interface TextComparisonOption
 */
export interface TextComparisonOption {
  /**
   *
   * @type {TextComparisonType}
   * @memberof TextComparisonOption
   */
  type: TextComparisonType;
  /**
   *
   * @type {string}
   * @memberof TextComparisonOption
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof TextComparisonOption
   */
  isDefault: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TextComparisonType {
  NOTIMPORTANT = 'NOT_IMPORTANT',
  CONTAINS = 'CONTAINS',
  NOTCONTAINS = 'NOT_CONTAINS',
  EQUALS = 'EQUALS',
}

/**
 *
 * @export
 * @interface TextFilter
 */
export interface TextFilter extends AuditFilter {
  /**
   *
   * @type {Array<string>}
   * @memberof TextFilter
   */
  values: Array<string>;
  /**
   *
   * @type {TextComparisonType}
   * @memberof TextFilter
   */
  comparisonType: TextComparisonType;
  /**
   *
   * @type {boolean}
   * @memberof TextFilter
   */
  isCaseSensitive: boolean;
}
/**
 *
 * @export
 * @interface TextFilterAllOf
 */
export interface TextFilterAllOf {
  /**
   *
   * @type {Array<string>}
   * @memberof TextFilterAllOf
   */
  values: Array<string>;
  /**
   *
   * @type {TextComparisonType}
   * @memberof TextFilterAllOf
   */
  comparisonType: TextComparisonType;
  /**
   *
   * @type {boolean}
   * @memberof TextFilterAllOf
   */
  isCaseSensitive: boolean;
}
/**
 *
 * @export
 * @interface TextFilterSettings
 */
export interface TextFilterSettings extends FilterSettings {
  /**
   *
   * @type {string}
   * @memberof TextFilterSettings
   */
  placeholder?: string;
  /**
   *
   * @type {Array<TextComparisonOption>}
   * @memberof TextFilterSettings
   */
  comparisonOptions?: Array<TextComparisonOption>;
}
/**
 *
 * @export
 * @interface TextFilterSettingsAllOf
 */
export interface TextFilterSettingsAllOf {
  /**
   *
   * @type {string}
   * @memberof TextFilterSettingsAllOf
   */
  placeholder?: string;
  /**
   *
   * @type {Array<TextComparisonOption>}
   * @memberof TextFilterSettingsAllOf
   */
  comparisonOptions?: Array<TextComparisonOption>;
}
/**
 *
 * @export
 * @interface TreeFilter
 */
export interface TreeFilter extends AuditFilter {
  /**
   *
   * @type {Array<string>}
   * @memberof TreeFilter
   */
  options: Array<string>;
  /**
   *
   * @type {CheckboxComparisonType}
   * @memberof TreeFilter
   */
  comparisonType?: CheckboxComparisonType;
}
/**
 *
 * @export
 * @interface TreeFilterSettings
 */
export interface TreeFilterSettings extends FilterSettings {
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof TreeFilterSettings
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {string}
   * @memberof TreeFilterSettings
   */
  placeholder: string;
  /**
   *
   * @type {boolean}
   * @memberof TreeFilterSettings
   */
  altClickEnabled: boolean;
}
/**
 *
 * @export
 * @interface TreeFilterSettingsAllOf
 */
export interface TreeFilterSettingsAllOf {
  /**
   *
   * @type {Array<CheckboxComparisonOption>}
   * @memberof TreeFilterSettingsAllOf
   */
  comparisonOptions: Array<CheckboxComparisonOption>;
  /**
   *
   * @type {string}
   * @memberof TreeFilterSettingsAllOf
   */
  placeholder: string;
  /**
   *
   * @type {boolean}
   * @memberof TreeFilterSettingsAllOf
   */
  altClickEnabled: boolean;
}
/**
 *
 * @export
 * @interface UpdatedField
 */
export interface UpdatedField {
  /**
   *
   * @type {string}
   * @memberof UpdatedField
   */
  fieldName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatedField
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatedField
   */
  to?: string;
}
/**
 *
 * @export
 * @interface UserFieldUpdateEventDataDto
 */
export interface UserFieldUpdateEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {string}
   * @memberof UserFieldUpdateEventDataDto
   */
  oldValue?: string;
  /**
   *
   * @type {string}
   * @memberof UserFieldUpdateEventDataDto
   */
  newValue?: string;
}
/**
 *
 * @export
 * @interface UserFieldUpdateEventDataDtoAllOf
 */
export interface UserFieldUpdateEventDataDtoAllOf {
  /**
   *
   * @type {string}
   * @memberof UserFieldUpdateEventDataDtoAllOf
   */
  oldValue?: string;
  /**
   *
   * @type {string}
   * @memberof UserFieldUpdateEventDataDtoAllOf
   */
  newValue?: string;
}
/**
 *
 * @export
 * @interface UserInfoDto
 */
export interface UserInfoDto {
  /**
   *
   * @type {number}
   * @memberof UserInfoDto
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserInfoDto
   */
  isSuperUser: boolean;
  /**
   *
   * @type {string}
   * @memberof UserInfoDto
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UserInfoDto
   */
  ip?: string;
  /**
   *
   * @type {DeviceInfoDto}
   * @memberof UserInfoDto
   */
  device?: DeviceInfoDto;
}
/**
 *
 * @export
 * @interface UserPermissionsUpdateEventDataDto
 */
export interface UserPermissionsUpdateEventDataDto extends AccountEventDataDto {
  /**
   *
   * @type {Array<string>}
   * @memberof UserPermissionsUpdateEventDataDto
   */
  oldRoles: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserPermissionsUpdateEventDataDto
   */
  newRoles: Array<string>;
}
/**
 *
 * @export
 * @interface UserPermissionsUpdateEventDataDtoAllOf
 */
export interface UserPermissionsUpdateEventDataDtoAllOf {
  /**
   *
   * @type {Array<string>}
   * @memberof UserPermissionsUpdateEventDataDtoAllOf
   */
  oldRoles: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserPermissionsUpdateEventDataDtoAllOf
   */
  newRoles: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum YesNoFilterOption {
  NO = 'NO',
  YES = 'YES',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum YesNoNotImportantFilterOption {
  NO = 'NO',
  YES = 'YES',
  NOTIMPORTANT = 'NOT_IMPORTANT',
}

/**
 * AccountEventApi - axios parameter creator
 * @export
 */
export const AccountEventApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account events by filters
     * @param {number} accountId Account identifier
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventsByFilters(
      accountId: number,
      page?: number,
      size?: number,
      language?: string,
      filtersDto?: FiltersDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountEventsByFilters.'
        );
      }
      const localVarPath = `/api/audit-service/accounts/{accountId}/account-events/filter`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (language !== undefined && language !== null) {
        localVarHeaderParameter['language'] = String(language);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof filtersDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
        : filtersDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Save account event
     * @param {SaveAccountEventDto} saveAccountEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEvent(saveAccountEventDto: SaveAccountEventDto, options: any = {}): RequestArgs {
      // verify required parameter 'saveAccountEventDto' is not null or undefined
      if (saveAccountEventDto === null || saveAccountEventDto === undefined) {
        throw new RequiredError(
          'saveAccountEventDto',
          'Required parameter saveAccountEventDto was null or undefined when calling saveAccountEvent.'
        );
      }
      const localVarPath = `/api/audit-service/account-events`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof saveAccountEventDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(saveAccountEventDto !== undefined ? saveAccountEventDto : {})
        : saveAccountEventDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Save account event
     * @param {SaveAccountEventsDto} saveAccountEventsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEventsBatch(saveAccountEventsDto: SaveAccountEventsDto, options: any = {}): RequestArgs {
      // verify required parameter 'saveAccountEventsDto' is not null or undefined
      if (saveAccountEventsDto === null || saveAccountEventsDto === undefined) {
        throw new RequiredError(
          'saveAccountEventsDto',
          'Required parameter saveAccountEventsDto was null or undefined when calling saveAccountEventsBatch.'
        );
      }
      const localVarPath = `/api/audit-service/account-events-batch`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof saveAccountEventsDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(saveAccountEventsDto !== undefined ? saveAccountEventsDto : {})
        : saveAccountEventsDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountEventApi - functional programming interface
 * @export
 */
export const AccountEventApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account events by filters
     * @param {number} accountId Account identifier
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventsByFilters(
      accountId: number,
      page?: number,
      size?: number,
      language?: string,
      filtersDto?: FiltersDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountEventsDto> {
      const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).getAccountEventsByFilters(
        accountId,
        page,
        size,
        language,
        filtersDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Save account event
     * @param {SaveAccountEventDto} saveAccountEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEvent(
      saveAccountEventDto: SaveAccountEventDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountEventDto> {
      const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).saveAccountEvent(
        saveAccountEventDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Save account event
     * @param {SaveAccountEventsDto} saveAccountEventsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEventsBatch(
      saveAccountEventsDto: SaveAccountEventsDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).saveAccountEventsBatch(
        saveAccountEventsDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountEventApi - factory interface
 * @export
 */
export const AccountEventApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get account events by filters
     * @param {number} accountId Account identifier
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventsByFilters(
      accountId: number,
      page?: number,
      size?: number,
      language?: string,
      filtersDto?: FiltersDto,
      options?: any
    ): AxiosPromise<AccountEventsDto> {
      return AccountEventApiFp(configuration).getAccountEventsByFilters(
        accountId,
        page,
        size,
        language,
        filtersDto,
        options
      )(axios, basePath);
    },
    /**
     * Save account event
     * @param {SaveAccountEventDto} saveAccountEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEvent(saveAccountEventDto: SaveAccountEventDto, options?: any): AxiosPromise<AccountEventDto> {
      return AccountEventApiFp(configuration).saveAccountEvent(saveAccountEventDto, options)(axios, basePath);
    },
    /**
     * Save account event
     * @param {SaveAccountEventsDto} saveAccountEventsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountEventsBatch(saveAccountEventsDto: SaveAccountEventsDto, options?: any): AxiosPromise<void> {
      return AccountEventApiFp(configuration).saveAccountEventsBatch(saveAccountEventsDto, options)(axios, basePath);
    },
  };
};

/**
 * AccountEventApi - object-oriented interface
 * @export
 * @class AccountEventApi
 * @extends {BaseAPI}
 */
export class AccountEventApi extends BaseAPI {
  /**
   *
   * @summary Get account events by filters
   * @param {number} accountId Account identifier
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [language] Language of resources
   * @param {FiltersDto} [filtersDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEventApi
   */
  public getAccountEventsByFilters(
    accountId: number,
    page?: number,
    size?: number,
    language?: string,
    filtersDto?: FiltersDto,
    options?: any
  ) {
    return AccountEventApiFp(this.configuration).getAccountEventsByFilters(
      accountId,
      page,
      size,
      language,
      filtersDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   * Save account event
   * @param {SaveAccountEventDto} saveAccountEventDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEventApi
   */
  public saveAccountEvent(saveAccountEventDto: SaveAccountEventDto, options?: any) {
    return AccountEventApiFp(this.configuration).saveAccountEvent(saveAccountEventDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Save account event
   * @param {SaveAccountEventsDto} saveAccountEventsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountEventApi
   */
  public saveAccountEventsBatch(saveAccountEventsDto: SaveAccountEventsDto, options?: any) {
    return AccountEventApiFp(this.configuration).saveAccountEventsBatch(saveAccountEventsDto, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.'
        );
      }
      const localVarPath = `/api/audit-service/e2e-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (zRequestId !== undefined && zRequestId !== null) {
        localVarHeaderParameter['Z-requestId'] = String(zRequestId);
      }

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(
      loggingEnabledE2ECheck: boolean,
      zRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ECheckResult> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(
        loggingEnabledE2ECheck,
        zRequestId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any): AxiosPromise<E2ECheckResult> {
      return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   * Method for E2ECheck.
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {string} [zRequestId] Request id header
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public e2ECheck(loggingEnabledE2ECheck: boolean, zRequestId?: string, options?: any) {
    return E2ECheckApiFp(this.configuration).e2ECheck(
      loggingEnabledE2ECheck,
      zRequestId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get available filters
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFilters(accountId: number, language?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAvailableFilters.'
        );
      }
      const localVarPath = `/api/audit-service/accounts/{accountId}/filters`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (language !== undefined && language !== null) {
        localVarHeaderParameter['language'] = String(language);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {string} [language] Language of resources
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterOptions(
      accountId: number,
      filterKey: FilterKey,
      language?: string,
      page?: number,
      size?: number,
      filtersDto?: FiltersDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getFilterOptions.'
        );
      }
      // verify required parameter 'filterKey' is not null or undefined
      if (filterKey === null || filterKey === undefined) {
        throw new RequiredError(
          'filterKey',
          'Required parameter filterKey was null or undefined when calling getFilterOptions.'
        );
      }
      const localVarPath = `/api/audit-service/accounts/{accountId}/filter-options`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (filterKey !== undefined) {
        localVarQueryParameter['filterKey'] = filterKey;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (language !== undefined && language !== null) {
        localVarHeaderParameter['language'] = String(language);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof filtersDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
        : filtersDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get available filters
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFilters(
      accountId: number,
      language?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterInfo>> {
      const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getAvailableFilters(
        accountId,
        language,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {string} [language] Language of resources
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterOptions(
      accountId: number,
      filterKey: FilterKey,
      language?: string,
      page?: number,
      size?: number,
      filtersDto?: FiltersDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterOption>> {
      const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getFilterOptions(
        accountId,
        filterKey,
        language,
        page,
        size,
        filtersDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get available filters
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableFilters(accountId: number, language?: string, options?: any): AxiosPromise<Array<FilterInfo>> {
      return FiltersApiFp(configuration).getAvailableFilters(accountId, language, options)(axios, basePath);
    },
    /**
     *
     * @summary Get filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {string} [language] Language of resources
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilterOptions(
      accountId: number,
      filterKey: FilterKey,
      language?: string,
      page?: number,
      size?: number,
      filtersDto?: FiltersDto,
      options?: any
    ): AxiosPromise<Array<FilterOption>> {
      return FiltersApiFp(configuration).getFilterOptions(
        accountId,
        filterKey,
        language,
        page,
        size,
        filtersDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
  /**
   *
   * @summary Get available filters
   * @param {number} accountId Account identifier
   * @param {string} [language] Language of resources
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public getAvailableFilters(accountId: number, language?: string, options?: any) {
    return FiltersApiFp(this.configuration).getAvailableFilters(
      accountId,
      language,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get filter options
   * @param {number} accountId Account identifier
   * @param {FilterKey} filterKey filterkey
   * @param {string} [language] Language of resources
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {FiltersDto} [filtersDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public getFilterOptions(
    accountId: number,
    filterKey: FilterKey,
    language?: string,
    page?: number,
    size?: number,
    filtersDto?: FiltersDto,
    options?: any
  ) {
    return FiltersApiFp(this.configuration).getFilterOptions(
      accountId,
      filterKey,
      language,
      page,
      size,
      filtersDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get report for account events
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventReport(
      accountId: number,
      language?: string,
      filtersDto?: FiltersDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountEventReport.'
        );
      }
      const localVarPath = `/api/audit-service/accounts/{accountId}/account-events/report`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (language !== undefined && language !== null) {
        localVarHeaderParameter['language'] = String(language);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof filtersDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
        : filtersDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get report for account events
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventReport(
      accountId: number,
      language?: string,
      filtersDto?: FiltersDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getAccountEventReport(
        accountId,
        language,
        filtersDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Get report for account events
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountEventReport(
      accountId: number,
      language?: string,
      filtersDto?: FiltersDto,
      options?: any
    ): AxiosPromise<any> {
      return ReportsApiFp(configuration).getAccountEventReport(
        accountId,
        language,
        filtersDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
  /**
   * Get report for account events
   * @param {number} accountId Account identifier
   * @param {string} [language] Language of resources
   * @param {FiltersDto} [filtersDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportsApi
   */
  public getAccountEventReport(accountId: number, language?: string, filtersDto?: FiltersDto, options?: any) {
    return ReportsApiFp(this.configuration).getAccountEventReport(
      accountId,
      language,
      filtersDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options: any = {}): RequestArgs {
      const localVarPath = `/healthCheck`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options: any = {}): RequestArgs {
      const localVarPath = `/version`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
    },
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options?: any): AxiosPromise<object> {
      return SystemApiFp(configuration).version(options)(axios, basePath);
    },
  };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
  /**
   *
   * @summary Service health check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public healthCheck(options?: any) {
    return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Service version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public version(options?: any) {
    return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
  }
}

/**
 * SystemEventApi - axios parameter creator
 * @export
 */
export const SystemEventApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Save system event
     * @param {SaveSystemEventDto} saveSystemEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSystemEvent(saveSystemEventDto: SaveSystemEventDto, options: any = {}): RequestArgs {
      // verify required parameter 'saveSystemEventDto' is not null or undefined
      if (saveSystemEventDto === null || saveSystemEventDto === undefined) {
        throw new RequiredError(
          'saveSystemEventDto',
          'Required parameter saveSystemEventDto was null or undefined when calling saveSystemEvent.'
        );
      }
      const localVarPath = `/api/audit-service/system-events`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof saveSystemEventDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(saveSystemEventDto !== undefined ? saveSystemEventDto : {})
        : saveSystemEventDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemEventApi - functional programming interface
 * @export
 */
export const SystemEventApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Save system event
     * @param {SaveSystemEventDto} saveSystemEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSystemEvent(
      saveSystemEventDto: SaveSystemEventDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemEventDto> {
      const localVarAxiosArgs = SystemEventApiAxiosParamCreator(configuration).saveSystemEvent(
        saveSystemEventDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemEventApi - factory interface
 * @export
 */
export const SystemEventApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Save system event
     * @param {SaveSystemEventDto} saveSystemEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSystemEvent(saveSystemEventDto: SaveSystemEventDto, options?: any): AxiosPromise<SystemEventDto> {
      return SystemEventApiFp(configuration).saveSystemEvent(saveSystemEventDto, options)(axios, basePath);
    },
  };
};

/**
 * SystemEventApi - object-oriented interface
 * @export
 * @class SystemEventApi
 * @extends {BaseAPI}
 */
export class SystemEventApi extends BaseAPI {
  /**
   * Save system event
   * @param {SaveSystemEventDto} saveSystemEventDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemEventApi
   */
  public saveSystemEvent(saveSystemEventDto: SaveSystemEventDto, options?: any) {
    return SystemEventApiFp(this.configuration).saveSystemEvent(saveSystemEventDto, options)(this.axios, this.basePath);
  }
}
