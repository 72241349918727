import React, { PureComponent } from 'react';
import memoize from 'memoizerific';
import { createId, prevent, keyEventToAction, getParent, getParents, getPrevious, getMains, getNext, toFiltered, hasShiftOrMetaOrCtrlModifier, } from './utils';
import { Link, DefaultLeaf, DefaultHead, DefaultEdit, DefaultMessage, Theme, DraggableLink, } from './components';
import { toggleElementInArray } from '../utils';
import { getContainer, getInnerContainer } from './Container';
import { getMainBranches } from './MainBranches';
const DEFAULT_HEIGHT = 500;
const DEFAULT_LOCAL_STORAGE_PREFIX = 'TreeState';
const REMOVE_STATE_FROM_LOCAL_STORAGE_PERIOD = 24 * 60 * 60 * 1000; //24 hours
const DEFAULT_ITEM_HEIGHT = 32;
const COMPACT_ITEM_HEIGHT = 26;
const createHandler = memoize(10000)((node, callback) => (event) => callback(event, node));
const linked = (C, { onClick, onKeyUp, toggleExpanded, prefix, flat, itemHeight, theme, onContextMenu, iconsMode, dragndrop }) => {
    const Linked = dragndrop
        ? React.memo(p => (React.createElement(DraggableLink, { id: p.node.nodeId, prefix: prefix, node: p.node, depth: p.depth, isExpanded: p.isExpanded, isSelected: p.isSelected, onKeyUp: createHandler(p.node, onKeyUp), onClick: createHandler(p.node, onClick), toggleExpanded: createHandler(p.node, toggleExpanded), flat: flat, itemHeight: itemHeight, theme: theme, key: `node_link_${p.node.nodeId}`, onContextMenu: onContextMenu },
            React.createElement(C, Object.assign({}, p)))))
        : React.memo(p => (React.createElement(Link, { id: p.node.nodeId, prefix: prefix, node: p.node, depth: p.depth, isExpanded: p.isExpanded, isSelected: p.isSelected, onKeyUp: createHandler(p.node, onKeyUp), onClick: createHandler(p.node, onClick), toggleExpanded: createHandler(p.node, toggleExpanded), flat: flat, itemHeight: itemHeight, theme: theme, key: `node_link_${p.node.nodeId}`, onContextMenu: onContextMenu, iconsMode: iconsMode, dragndrop: dragndrop },
            React.createElement(C, Object.assign({}, p)))));
    Linked.displayName = `Linked${C.displayName}`;
    return Linked;
};
const getHead = memoize(1)((Head = DefaultHead, prefix, flat, events, theme, itemHeight, onContextMenu, iconsMode, dragndrop) => linked(Head, {
    onClick: events.onClick,
    toggleExpanded: events.toggleExpanded,
    onKeyUp: events.onKeyUp,
    prefix,
    flat,
    theme,
    itemHeight,
    onContextMenu,
    iconsMode,
    dragndrop,
}));
const getLeaf = memoize(1)((Leaf = DefaultLeaf, prefix, flat, events, theme, itemHeight, onContextMenu, iconsMode, dragndrop) => linked(Leaf, {
    onClick: events.onClick,
    toggleExpanded: events.toggleExpanded,
    onKeyUp: events.onKeyUp,
    prefix,
    flat,
    theme,
    itemHeight,
    onContextMenu,
    iconsMode,
    dragndrop,
}));
const getEdit = memoize(1)((Edit, prefix, flat, events, theme, itemHeight, iconsMode) => linked(Edit || DefaultEdit, {
    onClick: events.onClick,
    toggleExpanded: events.toggleExpanded,
    onKeyUp: events.onKeyUp,
    prefix,
    flat,
    theme,
    itemHeight,
    iconsMode,
    dragndrop: false,
}));
const getMessage = memoize(1)((Message) => Message || DefaultMessage);
const calculateTreeState = memoize(50)((props, prevState) => {
    if (Object.entries(props.dataset).length === 0)
        return null;
    const filteredDataset = getFilteredDataset(props);
    if (JSON.stringify(props.selectedIds) === JSON.stringify(prevState.lastSelectedIds)) {
        return {
            filteredExpanded: Boolean(props.filter)
                ? Object.keys(filteredDataset).reduce((acc, k) => Object.assign(acc, { [k]: true }), {})
                : prevState.filteredExpanded,
        };
    }
    // If a new selection is made, we need to ensure it is part of the expanded set
    const selectedAncestorIds = props.selectedIds
        ? props.selectedIds.flatMap(selectedId => getParents(selectedId, props.dataset)).map(i => i.nodeId)
        : [];
    return {
        lastSelectedIds: props.selectedIds,
        unfilteredExpanded: selectedAncestorIds.reduce((acc, k) => Object.assign(acc, { [k]: true }), Object.assign({}, prevState.unfilteredExpanded)),
        filteredExpanded: Boolean(props.filter)
            ? Object.keys(filteredDataset).reduce((acc, k) => Object.assign(acc, { [k]: true }), {})
            : prevState.filteredExpanded,
    };
});
const getExpanded = ({ unfilteredExpanded, filteredExpanded }, filter) => filter ? filteredExpanded : unfilteredExpanded;
const getFilteredDataset = memoize(50)(({ dataset, filter, searchField }) => filter ? toFiltered(dataset, filter, searchField) : dataset);
const updateExpanded = (fn, filter) => ({ unfilteredExpanded, filteredExpanded, }) => {
    if (filter) {
        return {
            filteredExpanded: fn(filteredExpanded),
            unfilteredExpanded,
        };
    }
    return {
        filteredExpanded,
        unfilteredExpanded: fn(unfilteredExpanded),
    };
};
const getPropsForTree = memoize(50)(({ dataset, selectedIds }) => {
    const selected = Object.keys(dataset).reduce((acc, k) => Object.assign(acc, { [k]: selectedIds && selectedIds.includes(k) }), {});
    const mains = getMains(dataset);
    return { selected, mains };
});
class TreeStateState {
    constructor() {
        // We maintain two sets of expanded nodes, so we remember which were expanded if we clear the filter
        this.unfilteredExpanded = {};
        this.filteredExpanded = {};
        this.lastSelectedIds = null;
        this.selectedNodes = [];
        this.treeHeight = DEFAULT_HEIGHT;
    }
}
export class TreeState extends PureComponent {
    constructor(props) {
        super(props);
        this.state = new TreeStateState();
        this.updateTreeHeight = (rect) => {
            this.setState({ treeHeight: rect.height });
        };
        this.windowResize = (event) => {
            var _a;
            let windowInnerHeight = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.innerHeight;
            if (this.state.treeHeight > windowInnerHeight) {
                this.setState({
                    treeHeight: DEFAULT_HEIGHT,
                });
            }
        };
        this.saveTreeState = () => {
            if (!this.props.saveToLocalStorageName)
                return;
            Object.entries(localStorage)
                .filter(([key]) => this.hasPrefix(key))
                .filter(([_key, value]) => this.isOldOrIncorrectLocalStorageState(value))
                .forEach(([key]) => localStorage.removeItem(key));
            localStorage.setItem(this.getLocalStorageKey(), JSON.stringify({
                unfilteredExpanded: this.state.unfilteredExpanded,
                filteredExpanded: this.state.filteredExpanded,
                timestamp: Date.now(),
            }));
        };
        this.isOldOrIncorrectLocalStorageState = (value) => {
            if (!value)
                return true;
            try {
                const parsedValue = JSON.parse(value);
                if (!parsedValue ||
                    !parsedValue.timestamp ||
                    Date.now() - parsedValue.timestamp > REMOVE_STATE_FROM_LOCAL_STORAGE_PERIOD)
                    return true;
            }
            catch (error) {
                return true;
            }
            return false;
        };
        this.getTreeStateFromLocalStorage = () => {
            if (!this.props.saveToLocalStorageName)
                return;
            const savedString = localStorage.getItem(this.getLocalStorageKey());
            if (!savedString)
                return;
            try {
                const savedTreeState = JSON.parse(savedString);
                if (!savedTreeState)
                    return;
                const savedState = {
                    unfilteredExpanded: {},
                    filteredExpanded: {},
                };
                if (savedTreeState.unfilteredExpanded)
                    savedState.unfilteredExpanded = savedTreeState.unfilteredExpanded;
                if (savedTreeState.filteredExpanded)
                    savedState.filteredExpanded = savedTreeState.filteredExpanded;
                return savedState;
            }
            catch (error) {
                console.error(error);
            }
        };
        this.getLocalStorageKey = () => `${this.props.saveToLocalStorageNamePrefix}__${this.props.saveToLocalStorageName}`;
        this.hasPrefix = (value) => value.startsWith(`${this.props.saveToLocalStorageNamePrefix}__`);
        this.events = {
            onClick: (event, node) => {
                const selectedNodes = this.props.multipleSelectByClick || hasShiftOrMetaOrCtrlModifier(event)
                    ? toggleElementInArray(this.props.selectedIds, node.nodeId)
                    : [node.nodeId];
                this.setState({ selectedNodes });
                this.props.onSelect(selectedNodes);
                if (this.props.expandOnClick) {
                    this.events.toggleExpanded(event, node);
                }
            },
            toggleExpanded: (e, node) => {
                const { filter } = this.props;
                this.setState(updateExpanded(expanded => (Object.assign(Object.assign({}, expanded), { [node.nodeId]: !expanded[node.nodeId] })), filter), this.saveTreeState);
            },
            onKeyUp: (e, node) => {
                const { prefix, dataset, filter, searchField } = this.props;
                const filteredDataset = getFilteredDataset({ dataset, filter, searchField });
                const expanded = getExpanded(this.state, filter);
                const action = keyEventToAction(e);
                if (action) {
                    prevent(e);
                }
                if (action === 'RIGHT') {
                    const next = getNext({ id: node.nodeId, dataset: filteredDataset, expanded });
                    if (!filteredDataset[node.nodeId].children || expanded[node.nodeId]) {
                        if (next) {
                            const el = document.getElementById(createId(next.nodeId, prefix));
                            el && el.focus();
                        }
                    }
                    this.setState(updateExpanded(currExpanded => (Object.assign(Object.assign({}, currExpanded), { [node.nodeId]: true }))));
                }
                if (action === 'LEFT') {
                    const prev = getPrevious({ id: node.nodeId, dataset: filteredDataset, expanded });
                    if (!filteredDataset[node.nodeId].children || !expanded[node.nodeId]) {
                        const parent = getParent(node.nodeId, filteredDataset);
                        if (parent && parent.children) {
                            const el = document.getElementById(createId(parent.nodeId, prefix));
                            el && el.focus();
                            if (prev) {
                                const el = document.getElementById(createId(prev.nodeId, prefix));
                                el && el.focus();
                            }
                        }
                    }
                    this.setState(updateExpanded(currExpanded => (Object.assign(Object.assign({}, currExpanded), { [node.nodeId]: false }))));
                }
                if (action === 'DOWN') {
                    const next = getNext({ id: node.nodeId, dataset: filteredDataset, expanded });
                    if (next) {
                        const el = document.getElementById(createId(next.nodeId, prefix));
                        el && el.focus();
                    }
                }
                if (action === 'UP') {
                    const prev = getPrevious({ id: node.nodeId, dataset: filteredDataset, expanded });
                    if (prev) {
                        const el = document.getElementById(createId(prev.nodeId, prefix));
                        el && el.focus();
                    }
                }
            },
        };
        this.handleContextMenu = (event) => {
            if (this.props.onContextMenu)
                this.props.onContextMenu(event);
        };
        this.handleDragndrop = (node, toNode) => {
            const { onDragndrop } = this.props;
            if (onDragndrop && node.nodeId !== (toNode === null || toNode === void 0 ? void 0 : toNode.nodeId))
                onDragndrop(node, toNode);
        };
        const savedState = this.getTreeStateFromLocalStorage();
        if (savedState)
            this.state = Object.assign(Object.assign({}, this.state), savedState);
    }
    static getDerivedStateFromProps(props, state) {
        return calculateTreeState(props, state);
    }
    componentDidMount() {
        window.addEventListener('resize', this.windowResize);
    }
    componentWillUnmount() {
        this.saveTreeState();
        window.removeEventListener('resize', this.windowResize);
    }
    render() {
        const { events, state: { unfilteredExpanded, filteredExpanded, treeHeight }, props, } = this;
        const { prefix, dataset, selectedIds, filter, searchField, disableVirtualList, flat, theme, compact, itemHeight = compact ? COMPACT_ITEM_HEIGHT : DEFAULT_ITEM_HEIGHT, style, className, editId, dragndrop = false, } = props;
        const Leaf = getLeaf(props.Leaf, prefix, flat, events, theme, itemHeight, props.onContextMenu, props.iconsMode, dragndrop);
        const Head = getHead(props.Head, prefix, flat, events, theme, itemHeight, props.onContextMenu, props.iconsMode, dragndrop);
        const Edit = getEdit(props.Edit, prefix, flat, events, theme, itemHeight, props.iconsMode);
        const Message = getMessage(props.Message);
        const Container = getContainer(dragndrop);
        const InnerContainer = getInnerContainer(dragndrop, this.handleDragndrop);
        const MainBranches = getMainBranches(disableVirtualList);
        const filteredDataset = getFilteredDataset({ dataset, filter, searchField });
        const expanded = filter ? filteredExpanded : unfilteredExpanded;
        const { selected, mains } = getPropsForTree({ dataset: filteredDataset, selectedIds });
        return mains.length ? (React.createElement(Container, { "data-test-id": props['data-test-id'], className: className, style: style, onDragndrop: this.handleDragndrop, onContextMenu: this.handleContextMenu, theme: theme },
            React.createElement(MainBranches, { mains: mains, dataset: dataset, filteredDataset: filteredDataset, itemHeight: itemHeight, treeHeight: treeHeight, editId: editId, expanded: expanded, selected: selected, Head: Head, Leaf: Leaf, Edit: Edit, InnerContainer: InnerContainer, onResize: this.updateTreeHeight }))) : (React.createElement(Message, { type: filter ? 'nothingFound' : 'isEmpty' }));
    }
}
TreeState.defaultProps = {
    prefix: '',
    selectedIds: [],
    onSelect: () => { },
    saveToLocalStorageNamePrefix: DEFAULT_LOCAL_STORAGE_PREFIX,
    theme: Theme.dark,
};
