var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ReportApi } from "../../generated/Reporter";
export default class ReportApiService {
    constructor(axios) {
        this.reportApi = new ReportApi({}, ReportApiService.BASE_PATH, axios);
    }
    createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language) {
        return __awaiter(this, void 0, void 0, function* () {
            if (language === "eng")
                language = "en";
            const { data } = yield this.reportApi.createBillingReportForSystemAdmin(userId, reportAccountId, type, dateFrom, dateTo, projectShortName, language);
            return data;
        });
    }
}
ReportApiService.BASE_PATH = "";
