export const jaicfLocalization = {
  'jaicf form field name': {
    eng: 'Project name',
    ru: 'Название проекта',
    cn: '项目名称',
  },
  'jaicf form field info 1': {
    eng: '1. Clone a bot template from the $[1] repository',
    ru: '1. Склонируйте шаблон бота из репозитория $[1]',
    cn: '1. 把从 $[1] 代码仓库中取来的机器人程序模板关闭',
  },
  'jaicf form field info 2': {
    eng: '2. Copy and paste the API token to your bot',
    ru: '2. Скопируйте и вставьте в бота API-токен',
    cn: '2. 复制接口调用令牌并粘贴到您的机器人',
  },
  'jaicf form field info 3': {
    eng: '3. Select the platform connection method',
    ru: '3. Выберите способ подключения к платформе',
    cn: '3. 选择平台连接方式',
  },
  'jaicf form field template': {
    eng: 'Project template',
    ru: 'Шаблон проекта',
    cn: '项目模板',
  },
  'jaicf form field template description': {
    eng: 'Use the template as a base for your project',
    ru: 'Используйте шаблон как основу для проекта',
    cn: '将该模板用作项目的模板',
  },
  'jaicf form field api token': {
    eng: 'API token',
    ru: 'API Токен',
    cn: '接口调用令牌',
  },
  'jaicf form field api token description': {
    eng: 'Copy the API token and paste it into the bot code',
    ru: 'Скопируйте API-токен и вставьте в код бота',
    cn: '复制接口调用令牌并将其粘贴到机器人程序代码中',
  },
  'jaicf form update token': {
    eng: 'Update token',
    ru: 'Обновить токен',
    cn: '更新令牌',
  },
  'jaicf form update token title': {
    eng: 'Important',
    ru: 'Внимание',
    cn: '重要',
  },
  'jaicf form update token message': {
    eng: 'The current token will become invalid after the update',
    ru: 'После обновления используемый токен перестанет работать',
    cn: '更新后当前令牌将无效',
  },
  'jaicf form update token button': {
    eng: 'Update',
    ru: 'Обновить',
    cn: '更新',
  },
  'jaicf form update token button cancel': {
    eng: 'Cancel',
    ru: 'Отменить',
    cn: '取消',
  },
  'jaicf form field connection type': {
    eng: 'Platform connection method',
    ru: 'Способ подключения к платформе',
    cn: '平台连接方式',
  },
  'jaicf form field connection type description': {
    eng: 'Select the bot connection method',
    ru: 'Выберите способ подключения бота',
    cn: '选择机器人连接方式',
  },
  'jaicf form field connection type long pooling': {
    eng: 'long polling',
    ru: 'long polling',
    cn: '长轮询',
  },
  'jaicf form field connection type long pooling description': {
    eng: 'The bot will automatically connect to the $[1] server to interact with the platform',
    ru: 'Бот будет сам подключаться к серверу $[1] для взаимодействия с платформой',
    cn: '机器人将自动连接到 $[1] 服务器与平台交流',
  },
  'jaicf form field connection type webhook': {
    eng: 'webhook',
    ru: 'webhook',
  },
  'jaicf form field connection type webhook description': {
    eng: 'The bot will accept messages from the platform via the specified link',
    ru: 'По указанной ссылке бот будет принимать сообщения платформы',
    cn: '机器人将通过指定的链接接收来自平台的消息',
  },
  'jaicf form field connection type webhook placeholder': {
    eng: 'Please enter the URL',
    ru: 'Введите URL',
    cn: '请输入网址',
  },
  'jaicf copy tooltip': {
    eng: 'Copy',
    ru: 'Скопировать',
    cn: '复制',
  },
  'jaicf tokenRefresh': {
    eng: 'Copy',
    ru: 'Скопировать',
    cn: '复制',
  },
  'jaicf copied tooltip': {
    eng: 'Copied',
    ru: 'Скопировано',
    cn: '被复制',
  },
  'jaicf go to link tooltip': {
    eng: 'Follow link',
    ru: 'Перейти по ссылке',
    cn: '跟踪链接',
  },
  'jaicf show tooltip': {
    eng: 'Show',
    ru: 'Показать',
    cn: '显示',
  },
  'jaicf hide tooltip': {
    eng: 'Hide',
    ru: 'Скрыть',
    cn: '隐藏',
  },
  'jaicf runtime environment': {
    eng: 'Runtime Environment',
    ru: 'Среда исполнения',
  },
  'jaicf runtime environment jaicpCloud': {
    eng: 'JAICP Cloud',
    ru: 'Облако JAICP',
  },
  'jaicf runtime environment external': {
    eng: 'External',
    ru: 'Внешний хостинг',
  },
  'jaicf runtime environment jaicpCloud help text': {
    eng: 'The bot will be deployed in the JAICP cloud. The source code could be stored either in JAICP or in the external Git repository. Configure this on the <a href="#">$[1]</a> page.',
    ru: 'Проект компилируется и исполняется в облаке JAICP. Исходный код проекта хранится внутри JAICP, либо во внешнем Git репозитории. Настройте на вкладке <a href="#">$[1]</a>',
  },
  'jaicf runtime environment external help text': {
    eng: 'Store bot in an external hosting service. Read more about available hosting options in the <a href="https://help.jaicf.com/Environments" target="_blank">JAICF documentation</a>',
    ru: 'Разместите бота на внешнем хостинге. Варианты описаны в <a href="https://help.jaicf.com/Environments" target="_blank">документации JAICF</a>',
  },
  'jaicf runtime environment jaicpCloud wiki link': {
    eng: 'https://help.jaicf.com/Environments',
    ru: 'https://help.jaicf.com/Environments',
  },
  'jaicf initial code system template': {
    eng: 'System template',
    ru: 'Системный шаблон',
  },
  'jaicf initial code system template help text': {
    eng: 'Project will be initialized with the system template. When using an external repository to store project code, the contents of the master branch will be overwritten',
    ru: 'Проект будет инициализирован системным шаблоном. При использовании внешнего репозитория содержимое ветки master будет перезаписано',
  },
  'jaicf initial code external template': {
    eng: 'External template',
    ru: 'Внешний шаблон',
  },
  'jaicf initial code external template placeholder': {
    eng: 'Enter Git repository url',
    ru: 'Введите адрес Git-репозитория',
  },
  'jaicf initial code external template help text': {
    eng: 'Project will be initialized with the external Git repository template. When using an external repository to store project code, the contents of the master branch will be overwritten.',
    ru: 'Проект будет инициализирован внешним шаблоном. При использовании внешнего репозитория содержимое ветки master будет перезаписано.',
  },
  'jaicf initial code connect template': {
    eng: 'Connect to existing project',
    ru: 'Подключить существующий проект',
  },
  'jaicf initial code connect template help text': {
    eng: 'Connecting to an existing project without template initialization',
    ru: 'Подключение к существующему проекту без инициализации шаблоном',
  },
  'jaicf initial code connect template help text with link': {
    eng: 'Connecting to an existing project without template initialization. Provide access to your Git repository on the <a href="#">$[1]</a> tab',
    ru: 'Подключение к существующему проекту без инициализации шаблоном. Укажите подключение к Git репозиторию на вкладке <a href="#">$[1]</a>',
  },
};
