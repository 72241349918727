var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var makeLabelsFromGroups = function (newGroups) {
    return Array.isArray(newGroups)
        ? newGroups.reduce(function (prevValue, currValue) {
            prevValue = __spreadArray(__spreadArray([], prevValue, true), currValue.labels, true);
            return prevValue;
        }, [])
        : [];
};
