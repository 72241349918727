var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import cn from 'classnames';
import { Icon, Tooltip, useTranslation } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';
import usePressKey from '@just-ai/just-ui/dist/utils/usePressKey';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toggleIntentItemType } from '../utils';
import styles from './styles.module.scss';
var PhraseRowForInsertion = function (_a, ref) {
    var item = _a.item, onChange = _a.onChange, onInsert = _a.onInsert, index = _a.index, writeDisabled = _a.writeDisabled;
    var iconRef = useRef(null);
    var t = useTranslation().t;
    var onPress = usePressKey({
        key: 'Enter',
    }, onInsert);
    return (React.createElement("div", { "data-test-id": "AlternativeQuestion.Row.id-".concat(index), ref: ref, className: cn(styles.phraseRow, 'edit-phrase-block') },
        React.createElement("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--prepend input-group-prepend') },
            React.createElement(IconButton, { id: "changePhraseType-".concat(item.id), "data-test-id": 'changePhraseType', tabIndex: 0, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), name: item.type === IntentItemType.phrase ? 'farText' : 'faAt', disabled: writeDisabled, onClick: function () {
                    onChange(toggleIntentItemType(item));
                } }),
            React.createElement(Tooltip, { target: "changePhraseType-".concat(item.id), className: styles.phraseRow__tooltip, boundariesElement: 'window', placement: 'top', "data-test-id": 'changePhraseType.tooltip' }, item.type === IntentItemType.phrase ? t('FAQ:Phrases:ChangeTypeText') : t('FAQ:Phrases:ChangeTypePhrase'))),
        React.createElement("div", { className: styles.phraseRow__part },
            React.createElement(TextAreaLikeElement, { id: "PhraseRow-".concat(item.id), dataTestId: 'PhraseRow.input', value: item.text, richValue: item.text, placeholder: item.type === IntentItemType.phrase ? t('FAQ:Phrase:Placeholder') : t('FAQ:Text:Placeholder'), onChange: function (text) { return onChange(__assign(__assign({}, item), { text: text })); }, onKeyDown: onPress, readOnly: writeDisabled, autoFocus: true }),
            item.error && (React.createElement(React.Fragment, null,
                React.createElement("div", { ref: iconRef, style: { display: 'flex' } },
                    React.createElement(Icon, { name: 'faExclamationCircle', color: 'danger' })),
                React.createElement(Tooltip, { target: iconRef }, t('PhraseBlocks:validationTooltip'))))),
        React.createElement("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--append input-group-append') },
            React.createElement(IconButton, { id: "insertPhrase-".concat(item.id), "data-test-id": 'insertPhrase', tabIndex: 0, name: 'farCheck', onClick: onInsert, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), disabled: writeDisabled }),
            React.createElement(Tooltip, { target: "insertPhrase-".concat(item.id), className: styles.phraseRow__tooltip, placement: 'top', boundariesElement: 'window', "data-test-id": 'insertPhrase.tooltip' }, t('FAQ:Phrases:InsertTooltip')))));
};
export default React.memo(React.forwardRef(PhraseRowForInsertion));
