var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { SearchInput, useToggle, useTranslation } from '@just-ai/just-ui';
import { findMatchPositionsInText } from '@just-ai/just-ui/dist/utils';
import { assignValidationErrorToPhrase, useDebounce } from '../../../../nlu-related/utils';
import LinkButton from '../IntentFaqEditView/components/LinkButton';
import Collapse from '../IntentFaqEditView/components/Collapse';
import PhraseList from './PhraseList';
function SearchField(_a) {
    var onChange = _a.onChange, _b = _a.debounce, debounce = _b === void 0 ? 0 : _b;
    var t = useTranslation().t;
    var _c = useState(''), value = _c[0], setValue = _c[1];
    useDebounce(value, debounce, onChange);
    return (React.createElement(SearchInput, { maxWidth: '50%', clearable: true, alwaysOpen: true, value: value, onClear: function () { return setValue(''); }, onChange: setValue, placeholder: t('FAQ:Phrases:SearchField:Placeholder'), "data-test-id": 'FAQ.FAQTree.SearchField' }));
}
function getPhrasesBlockLabel(t, counter) {
    var resultString = t('FAQ:Phrases:AlternativePhrases');
    if (counter && counter > 0) {
        resultString += " (".concat(counter, ")");
    }
    return resultString;
}
function isNeedToShowExpandedView(intentName, items) {
    return items.length > 0;
}
var PhrasesBlock = function (_a) {
    var name = _a.name, intentName = _a.intentName, items = _a.items, onChange = _a.onChange, writeDisabled = _a.writeDisabled, valErrors = _a.valErrors;
    var _b = useToggle(isNeedToShowExpandedView(intentName, items)), isShowExpandedView = _b[0], toggleIsShowExpandedView = _b[3];
    var _c = useToggle(!!valErrors && !!valErrors.length), isCollapseOpened = _c[0], toggleIsCollapseOpened = _c[3];
    var _d = useState(false), isSearching = _d[0], setIsSearching = _d[1];
    var t = useTranslation().t;
    var itemsWithErrors = useMemo(function () {
        if (!valErrors)
            return items.map(function (item) { return (__assign(__assign({}, item), { error: undefined })); });
        return assignValidationErrorToPhrase(valErrors, items);
    }, [items, valErrors]);
    var _e = useState(itemsWithErrors), filteredElements = _e[0], setFilteredElements = _e[1];
    var timeoutId = useRef();
    var onUpdate = useCallback(function (items) {
        onChange(items, name);
        if (timeoutId.current)
            clearTimeout(timeoutId.current);
        var filteredList = items.filter(function (element, index, arr) {
            delete element.error;
            if (element.text === intentName)
                return false;
            return index === arr.findIndex(function (t) { return t.text === element.text; });
        });
        if (items.length === filteredList.length)
            return;
        timeoutId.current = setTimeout(function () {
            onChange(filteredList, name);
        }, 1000);
    }, [intentName, name, onChange]);
    var onSearch = useCallback(function (searchValue) {
        setIsSearching(!!searchValue);
        if (!searchValue) {
            setFilteredElements(itemsWithErrors);
            return;
        }
        searchValue = searchValue.toLowerCase();
        var filteredItems = itemsWithErrors.reduce(function (acc, item) {
            var matches = findMatchPositionsInText(item.text, searchValue);
            if (matches.length === 0)
                return acc;
            acc.push(__assign(__assign({}, item), { searchMatches: matches }));
            return acc;
        }, []);
        setFilteredElements(filteredItems);
    }, [itemsWithErrors]);
    var focusOnExpand = useCallback(function () {
        var input = document.getElementById('PhraseRow-ForInsertion');
        if (input && input.focus) {
            input.focus();
        }
    }, []);
    if (!isShowExpandedView) {
        return (React.createElement(LinkButton, { text: t('FAQ:Phrases:AddAlternativePhrases'), onClick: function () {
                toggleIsShowExpandedView();
                toggleIsCollapseOpened();
            }, dataTestId: 'FAQ.Phrases.AddAlternativePhrases' }));
    }
    return (React.createElement(Collapse, { text: getPhrasesBlockLabel(t, isCollapseOpened ? 0 : items.length), isOpened: isCollapseOpened, onToggle: toggleIsCollapseOpened, dataTestId: 'FAQ.PhrasesCollapse', onEntered: focusOnExpand },
        React.createElement("div", { className: 'flex-column gap-3x margin-top-3x' },
            React.createElement(SearchField, { debounce: 500, onChange: onSearch }),
            isSearching && filteredElements.length === 0 ? (React.createElement("span", { className: 'margin-bottom-7x' }, t('FAQ:Phrases:NoMatchesFound'))) : (React.createElement(PhraseList, { isSearching: isSearching, filteredItems: filteredElements, items: itemsWithErrors, onChange: onUpdate, writeDisabled: writeDisabled || !!(valErrors === null || valErrors === void 0 ? void 0 : valErrors.length) })))));
};
export default React.memo(PhrasesBlock);
