import React from 'react';
import styles from './styles.module.scss';
var LinkButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, dataTestId = _a.dataTestId;
    return (React.createElement("a", { href: '#!', className: styles.linkButton, onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }, "data-test-id": dataTestId }, text));
};
export default React.memo(LinkButton);
