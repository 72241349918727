import React, { FC, useCallback } from 'react';

import { TagNames } from '../../../utils/types';
import { PatternActivationEdit } from '../RSAddingMenu/ActivationsAddingMenu/PatternActivationEdit';
import { useCommonActivationSettings } from './utils';

export const PatternEventSettings: FC = () => {
  const { mapTagParametersToObj, isGlobal, changeEventParams, block, onChangeCommonTag } = useCommonActivationSettings(
    TagNames.q_,
    TagNames.q
  );

  const pattern = block.tagValue || '';

  const changePattern = useCallback(
    (value: string) => {
      onChangeCommonTag({ tagValue: value });
    },
    [onChangeCommonTag]
  );

  return (
    <div className='JGraph-RightSideMenu-commonContainer'>
      <PatternActivationEdit
        eventState={{
          isGlobal: isGlobal,
          onlyThisState: mapTagParametersToObj.onlyThisState?.value,
          fromStateValue: mapTagParametersToObj.fromState?.value || '',
          toStateValue: mapTagParametersToObj.toState?.value || '',
        }}
        pattern={pattern}
        changePattern={changePattern}
        isGlobal={isGlobal}
        changeEventParams={changeEventParams}
        isToState={!!mapTagParametersToObj.toState?.value}
        isFromState={!mapTagParametersToObj.toState?.value || isGlobal}
      />
    </div>
  );
};
