import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { RefObject, useEffect } from 'react';
import { EditMenuBlock, JStateWithId } from '../../../reducers/JGraph.reducer/types';
import { animationFrames, endWith, map, Observable, scan, shareReplay, Subject, takeWhile } from 'rxjs';
import { Vector2d } from 'konva/lib/types';
import { GlobalScrollToTarget, ScrollToTarget } from '../contexts/types';
import { StageObservablesContextType } from '../contexts/StageObservablesProvider';
import { findParentByPathId, findScreenByPathId } from '../../../reducers/JGraph.reducer/Graph';

export const setScale = (e: KonvaEventObject<WheelEvent>): [number, { x: number; y: number }] => {
  e.evt.preventDefault();
  const scaleBy = 1.05;
  // const bgSize = 112;
  const stageStage = getStageFromEvent(e);

  if (stageStage) {
    const oldScale = stageStage.scaleX();

    const stagePointerPosition = stageStage.getPointerPosition() || { x: 0, y: 0 };

    const mousePointTo = {
      x: stagePointerPosition.x / oldScale - stageStage.x() / oldScale,
      y: stagePointerPosition.y / oldScale - stageStage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    // bg.style.backgroundSize = bgSize*newScale + 'px';

    stageStage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: -(mousePointTo.x - stagePointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - stagePointerPosition.y / newScale) * newScale,
    };
    stageStage.position(newPos);

    return [newScale, newPos];
  }
  return [1, { x: 0, y: 0 }];
};

export const setCursorOnMouseEnterAndLeave = (fn?: () => unknown) => (e: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const evtType = e.type;
    const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
    if (parent) {
      parent.style.cursor = evtType === 'mouseenter' ? 'pointer' : '';
      if (evtType === 'mouseenter') {
        parent.style.cursor = 'pointer';
      } else {
        parent.style.cursor = '';
      }
    }

    if (fn) {
      fn();
    }
  }
};
export const setTitleOnMouseEnterAndLeave = (title?: string) => (e: KonvaEventObject<MouseEvent>) => {
  const stage = getStageFromEvent(e);
  if (stage) {
    const evtType = e.type;
    const parent = stage.content ? (stage.content.parentNode as HTMLDivElement) : null;
    if (parent) {
      if (evtType === 'mouseenter') {
        parent.title = title || '';
      } else {
        parent.title = '';
      }
    }
  }
};

export type StageActions = {
  toggleAddingActionsMenu: (event: Konva.KonvaEventObject<MouseEvent>, screenId?: string) => unknown;
  showStateNameEditField: (event: Konva.KonvaEventObject<MouseEvent>, stateName: string) => unknown;
  setEditMenuBlock: (editMenuBlock?: EditMenuBlock) => unknown;
};

export const getStageActionsFromEvent = (event: KonvaEventObject<MouseEvent>): StageActions => {
  return event.currentTarget.getStage()?.attrs.actions;
};
export const getStageFromEvent = (event: KonvaEventObject<MouseEvent>): Konva.Stage | null => {
  return event.currentTarget.getStage();
};
export const getStageActionsFromRef = (ref: RefObject<Konva.Node>): StageActions => {
  return ref.current?.getStage()?.attrs.actions;
};
export const getStageFromRef = (ref: RefObject<Konva.Node>): Konva.Stage | null | undefined => {
  return ref.current?.getStage();
};
export const getStageContextFromRef = (ref: RefObject<Konva.Node>): StageObservablesContextType => {
  return ref.current?.getStage()?.attrs.ctx || {};
};
export const getStageContextFromEvent = (event: KonvaEventObject<MouseEvent>): StageObservablesContextType => {
  return event.currentTarget.getStage()?.attrs.ctx || {};
};

function tween(start: Vector2d, end: Vector2d, duration: number) {
  const diffX = end.x - start.x;
  const diffY = end.y - start.y;
  return animationFrames().pipe(
    // Figure out what percentage of time has passed
    map(({ elapsed }) => elapsed / duration),
    // Take the vector while less than 100%
    takeWhile(v => v < 1),
    // Finish with 100%
    endWith(1),
    // Calculate the distance traveled between start and end
    map(v => ({
      x: v * diffX + start.x,
      y: v * diffY + start.y,
    }))
  );
}

export const stateWidth = 280;

export const useScrollToTarget = (
  scrollToTarget$: Subject<ScrollToTarget>,
  renderStorePipe$: Observable<string>,
  stage: Konva.Stage | null
) => {
  useEffect(() => {
    let sub = scrollToTarget$.subscribe(value => {
      const { targetPathId, isSideMenuOpen } = value;
      if (!stage) return;
      const { target, targetHeight } = getTargetAndHeightByPathId(targetPathId, stage);
      if (target) {
        const stageScale = stage.scale();
        const stageViewPort = {
          width: stage.width() - (isSideMenuOpen ? 460 : 0),
          height: stage.height(),
        };
        let targetPosition = target.getAbsolutePosition(stage);
        targetPosition.x = targetPosition.x * stageScale.x;
        targetPosition.y = targetPosition.y * stageScale.y;

        tween(
          stage.position(),
          {
            x: -targetPosition.x + stageViewPort.width / 2 - (stateWidth * stageScale.x) / 2,
            y: -targetPosition.y + stageViewPort.height / 2 - (targetHeight * stageScale.y) / 2,
          },
          1000
        ).subscribe(positionValue => {
          stage.position({
            x: positionValue.x,
            y: positionValue.y,
          });
        });
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [stage, renderStorePipe$, scrollToTarget$]);
};

export const getTargetAndHeightByPathId = (scrollToPathId: string, stage: Konva.Stage) => {
  const target = stage.findOne(`.${scrollToPathId}`) as Konva.Group;
  let targetHeight = 0;
  if (target && target.hasChildren() && target.attrs.isScreen) {
    target.children!.forEach(group => {
      if (group instanceof Konva.Group) {
        const FirstAutosizeRectInGroup = (group as Konva.Group).findOne((node: { name: () => string }) => {
          return node.name().startsWith('AutosizeRect');
        });
        if (FirstAutosizeRectInGroup) {
          targetHeight += FirstAutosizeRectInGroup.height();
        }
      }
    });
    return {
      target,
      targetHeight,
    };
  }
  return {
    target: null,
    targetHeight: 0,
  };
};

export const getCenterOfStage = (stage: Konva.Stage): Vector2d => {
  const stageScale = stage.scale();
  const stageViewPortCenter = {
    width: stage.width() / 2 / stageScale.x,
    height: stage.height() / 2 / stageScale.y,
  };
  let targetPosition = stage.getAbsolutePosition();

  return {
    x: -targetPosition.x / stageScale.x + stageViewPortCenter.width,
    y: -targetPosition.y / stageScale.y + stageViewPortCenter.height,
  };
};

export const scrollToTargetGlobal$ = new Subject<GlobalScrollToTarget>();
export const scrollToTargetGlobalPipe$ = scrollToTargetGlobal$.pipe(
  scan((prevValue, newValue) => {
    return newValue;
  }, {} as GlobalScrollToTarget),
  shareReplay(1)
);

export const useScrollToTargetGlobal = (
  screens: JStateWithId[],
  scrollToTarget$: Subject<ScrollToTarget>,
  setSelectedGroupToTop: (group: { path?: string; pathId?: string }) => unknown,
  renderStorePipe$: Observable<string>,
  currentOpenGroup: { path?: string; pathId?: string },
  selectedGroup: { path?: string; pathId?: string }
) => {
  useEffect(() => {
    const sub = scrollToTargetGlobalPipe$.subscribe(({ targetPathId, isSideMenuOpen }) => {
      if (!targetPathId) return;
      //Screen that we want to scroll to
      const target = findScreenByPathId(targetPathId, screens);
      const parent = findParentByPathId(screens, targetPathId);
      const screenByTargetPathId = screens.find(screen => screen.pathId === targetPathId);
      const isTargetOnCurrentOpenedGroup = !!screenByTargetPathId;

      if (currentOpenGroup.pathId) {
        const screenByOpenGroup = screens.find(screen => screen.pathId === currentOpenGroup.pathId);
        if (screenByOpenGroup) {
          if (parent?.pathId === currentOpenGroup.pathId || (isTargetOnCurrentOpenedGroup && !target?.states?.length)) {
            //opened group cannot have states
            renderStorePipe$.subscribe({
              next: value => {
                if (value !== '__END__') return;
                scrollToTarget$.next({ targetPathId: targetPathId, isSideMenuOpen });
                scrollToTargetGlobal$.next({ targetPathId: undefined });
              },
            });
          }
          return;
        }
      }

      if (isTargetOnCurrentOpenedGroup) {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            renderStorePipe$.subscribe({
              next: value => {
                if (value !== '__END__') return;
                scrollToTarget$.next({ targetPathId: targetPathId, isSideMenuOpen });
              },
            });
          });
        }, 0);
        scrollToTargetGlobal$.next({ targetPathId: undefined });
        return;
      }

      if (parent) {
        if (parent.states && parent.states.length > 0) {
          setSelectedGroupToTop({ path: parent.path, pathId: parent.pathId });
        } else {
          setSelectedGroupToTop({ path: undefined, pathId: undefined });
          renderStorePipe$.subscribe({
            next: value => {
              if (value !== '__END__') return;
              scrollToTarget$.next({ targetPathId: targetPathId, isSideMenuOpen });
              scrollToTargetGlobal$.next({ targetPathId: undefined });
            },
          });
        }
      } else {
        setSelectedGroupToTop({ path: undefined, pathId: undefined });
        scrollToTargetGlobal$.next({ targetPathId: undefined });
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [
    currentOpenGroup.path,
    currentOpenGroup.pathId,
    renderStorePipe$,
    screens,
    scrollToTarget$,
    setSelectedGroupToTop,
  ]);
};

export const getInnerScreensPathIdMap = (screens: JStateWithId[]): string[] => {
  return screens
    .map(screen => {
      let value: string[] = [];
      if (screen.states) value = getInnerScreensPathIdMap(screen.states);
      value.push(screen.pathId);
      return value;
    })
    .flat(1);
};

export function isPathInValid(name: string) {
  const valueToCheckSplitPath = name.split('/');
  return valueToCheckSplitPath.reduce((prevValue, currentItem, index, initialArr) => {
    if (prevValue) return prevValue;
    if (initialArr.length >= 2 && index < initialArr.length - 1) {
      prevValue = !currentItem.replace(/\W/gu, '');
    }
    if (index > 0) {
      prevValue = currentItem.replace(/\W/gu, '').length === 0;
    }
    return prevValue;
  }, false);
}

export function isScreenNameValid(name: string): boolean {
  name = name.trim();
  if (!name) return false;
  return !isTextHasSlash(name);
}

export function isTextHasSlash(name: string): boolean {
  return /\//.test(name);
}

export function isStateNameAlreadyExist(name: string, allStates: string[], parentPath?: string) {
  const valueToCheck = parentPath ? `${parentPath}/${name.trim()}` : `/${name.trim()}`.replace(/\/+/g, '/');
  return allStates.includes(valueToCheck);
}
