import React, { FC, useCallback } from 'react';
import { InputText } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import { CommonFieldProps } from './types';

interface StringArrayItemProps extends Omit<CommonFieldProps, 'onChange' | 'name' | 'isRequired'> {
  index: number;
  value: string;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
}

export const StringArrayItem: FC<StringArrayItemProps> = ({ index, value, onChange, onRemove }) => {
  const onChangeByIndexHandler = useCallback((value: string) => onChange(index, value), [onChange, index]);
  const onRemoveByIndexHandler = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <div className='d-flex gap-8 mb-2'>
      <div className='d-flex flex-fill'>
        <InputText onChange={onChangeByIndexHandler} value={value} autoFocus={!value} />
      </div>
      <div>
        <IconButton color='primary' name='farTrashAlt' flat onClick={onRemoveByIndexHandler} size='md' />
      </div>
    </div>
  );
};
