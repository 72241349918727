var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { SwitchButton, Icon, DropdownMenu, DropdownItem, Dropdown, useToggle, DropdownToggle, Button, useTranslation, } from '@just-ai/just-ui';
import { SkillType } from '@just-ai/api/dist/generated/Caila';
import { useFormatDate, usePromiseProcessing } from '@just-ai/just-ui/dist/utils';
import { useDeParametrizedFunction } from '../../../../nlu-related/utils/hooks';
import CDQAModal from '../../../../views/FAQModal/CDQAModal';
import { useNLUModuleContext } from '../../../../context';
import { HOME_TAB_CODE } from '../../constants';
import RenameSkillModal from '../../Modals/RenameSkillModal';
import DeleteSkillModal from '../../Modals/DeleteSkillModal';
import SimilarsModal from '../../Modals/SimilarsModal';
import InfoCell from './cells/InfoCell';
import TitleCell from './cells/TitleCell';
import EmptyFaqContent from './EmptyFaqContent';
import styles from './styles.module.scss';
function SkillRow(_a) {
    var _b, _c;
    var _d, _e;
    var skill = _a.skill, onRename = _a.onRename, toggleActive = _a.toggleActive, onDelete = _a.onDelete, showAnalytic = _a.showAnalytic, isLoading = _a.isLoading, onClick = _a.onClick, copySkill = _a.copySkill, writeDisabled = _a.writeDisabled;
    var onRenameInner = useDeParametrizedFunction(onRename, skill);
    var toggleActiveInner = useDeParametrizedFunction(toggleActive, skill);
    var onDeleteInner = useDeParametrizedFunction(onDelete, skill);
    var showAnalyticInner = useDeParametrizedFunction(showAnalytic, skill);
    var copySkillInner = useDeParametrizedFunction(copySkill, skill.name);
    var onClickInner = useDeParametrizedFunction(onClick, skill.name);
    var _f = useToggle(false), dropDownOpen = _f[0], dropDownToggle = _f[3];
    var isZfl = useNLUModuleContext().isZfl;
    var t = useTranslation().t;
    var updateDate = useFormatDate((_d = skill.updated) === null || _d === void 0 ? void 0 : _d.timestamp, 'd MMMM yyyy HH:mm');
    var createDate = useFormatDate((_e = skill.created) === null || _e === void 0 ? void 0 : _e.timestamp, 'd MMMM yyyy HH:mm');
    return (React.createElement("tr", { tabIndex: 0, "data-test-id": "FAQ.SkillRow.".concat(skill.name), onClick: skill.type !== SkillType.Cdqa ? onClickInner : undefined, className: cn((_b = {},
            _b[styles.loading] = isLoading,
            _b[styles.cdqa] = skill.type === SkillType.Cdqa,
            _b)) },
        React.createElement("td", null,
            React.createElement(TitleCell, { className: cn(styles.titleCell, (_c = {},
                    _c[styles.disabled] = !skill.enabled,
                    _c)), title: skill.name, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Title") })),
        React.createElement("td", null, skill.updated && (React.createElement(InfoCell, null,
            skill.updated.timestamp && React.createElement("span", null, updateDate),
            skill.updated.user && React.createElement("span", null, skill.updated.user)))),
        React.createElement("td", null, skill.created && (React.createElement(InfoCell, null,
            skill.created.timestamp && React.createElement("span", null, createDate),
            skill.created.user && React.createElement("span", null, skill.created.user)))),
        React.createElement("td", { onClick: function (ev) { return ev.stopPropagation(); } },
            React.createElement(SwitchButton, { id: "skill-".concat(skill.name), "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Toggle"), onChange: toggleActiveInner, value: skill.enabled, disabled: writeDisabled, size: 'sm' })),
        React.createElement("td", null,
            React.createElement(Dropdown, { isOpen: dropDownOpen, toggle: function (event) {
                    event.stopPropagation();
                    dropDownToggle();
                }, direction: 'down' },
                React.createElement(DropdownToggle, { color: 'secondary', size: 'md', flat: true, withoutPadding: true, compact: true, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Dropdown") },
                    React.createElement(Icon, { name: 'farEllipsisH' })),
                React.createElement(DropdownMenu, { right: true },
                    React.createElement(DropdownItem, { className: styles.dropDownElement, onClick: onRenameInner, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Dropdown.Rename"), disabled: writeDisabled },
                        React.createElement(Icon, { name: 'farPen' }),
                        " ",
                        t(skill.type === SkillType.Faq ? 'FAQ:Home:SkillTable:Action:Rename' : 'Edit')),
                    skill.type === SkillType.Faq && !isZfl && (React.createElement(DropdownItem, { className: styles.dropDownElement, onClick: showAnalyticInner, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Dropdown.ViewAnalytics") },
                        React.createElement(Icon, { name: 'farPoll' }),
                        " ",
                        t('FAQ:Home:SkillTable:Action:ViewAnalytics'))),
                    skill.type === SkillType.Cdqa && (React.createElement(DropdownItem, { className: styles.dropDownElement, onClick: copySkillInner, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Dropdown.CopySkill") },
                        React.createElement("div", { className: 'dropdown-item--cdqa-copy' },
                            React.createElement("div", null,
                                React.createElement(Icon, { name: 'farCode' })),
                            React.createElement("div", { className: 'mt-1' },
                                React.createElement("p", null, t('FAQ:Home:SkillTable:Action:CopySkill')),
                                React.createElement("p", { className: 'text-secondary font-size-12' }, t('FAQ:Home:SkillTable:Action:CopySkillSub')))))),
                    React.createElement(DropdownItem, { className: cn(styles.dropDownElement, styles.danger), onClick: onDeleteInner, "data-test-id": "FAQ.SkillRow.".concat(skill.name, ".Dropdown.Delete"), disabled: writeDisabled },
                        React.createElement(Icon, { name: 'farTrashAlt' }),
                        " ",
                        t('FAQ:Home:SkillTable:Action:Delete')))))));
}
var FaqHomeView = function (_a) {
    var skills = _a.skills, loading = _a.loading, onToggle = _a.onToggle, onRename = _a.onRename, onDelete = _a.onDelete, onSelect = _a.onSelect, goToAnalytic = _a.goToAnalytic, onUpdate = _a.onUpdate;
    var _b = useState(), selectedSkill = _b[0], setSelectedSkill = _b[1];
    var t = useTranslation().t;
    var _c = useNLUModuleContext(), addAlert = _c.actions.addAlert, isAccessFunction = _c.isAccessFunction, isZfl = _c.isZfl, wrappedHistoryPush = _c.wrappedHistoryPush;
    var _d = usePromiseProcessing(function () { return (selectedSkill ? onDelete(selectedSkill.name) : Promise.resolve()); }, { deps: [onDelete, selectedSkill] }), deleteSkillState = _d[0], deleteSkillApi = _d[1];
    var _e = usePromiseProcessing(function (title) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (selectedSkill ? onRename(selectedSkill.name, title, false) : Promise.resolve())];
    }); }); }, { deps: [onRename, selectedSkill] }), renameSkillState = _e[0], renameSkillApi = _e[1];
    var _f = usePromiseProcessing(function (updateData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (selectedSkill ? onUpdate(selectedSkill.id, updateData) : Promise.resolve())];
    }); }); }, { deps: [onRename, selectedSkill] }), updateSkillState = _f[0], updateSkillApi = _f[1];
    var _g = usePromiseProcessing(function (skillName, value) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, onToggle(skillName, value)];
    }); }); }, { deps: [onToggle] }), toggleSkillState = _g[0], toggleSkillApi = _g[1];
    var _h = usePromiseProcessing(function (skill) { return goToAnalytic(skill); }, {
        deps: [goToAnalytic],
    }), goToAnalyticState = _h[0], goToAnalyticSkillApi = _h[1];
    var _j = useToggle(false), isRenameModalOpen = _j[0], toggleRenameModalOpen = _j[3];
    var _k = useToggle(false), isDeleteModalOpen = _k[0], toggleDeleteModalOpen = _k[3];
    var _l = useToggle(false), isSimilarsModalOpen = _l[0], toggleSimilarsModalOpen = _l[3];
    var onRenameModalOpen = useCallback(function (skill) {
        toggleRenameModalOpen();
        setSelectedSkill(skill);
    }, [toggleRenameModalOpen]);
    var onToggleInner = useCallback(function (skill) {
        setSelectedSkill(skill);
        toggleSkillApi(skill.name, !skill.enabled);
    }, [toggleSkillApi]);
    var onDeleteInner = useCallback(function (skill) {
        toggleDeleteModalOpen();
        setSelectedSkill(skill);
    }, [toggleDeleteModalOpen]);
    var showAnalytic = useCallback(function (skill) {
        setSelectedSkill(skill);
        goToAnalyticSkillApi(skill);
    }, [goToAnalyticSkillApi]);
    var copySkillCode = useCallback(function (skillName) {
        navigator.clipboard.writeText("state: NoMatch\n    event!: noMatch\n    script:\n      var result = $caila.cdqaQuery($request.query, \"".concat(skillName, "\", 0.5)\n        if (result.predicted){\n              $reactions.answer(result.predicted)\n          } else {\n              $reactions.answer(\"").concat(t('FAQ:Home:SkillNotFoundAnswer'), "\")\n          }"));
        addAlert &&
            addAlert({
                message: t('FAQ:Home:SkillCopiedText'),
                type: 'info',
                showed: true,
                title: t('FAQ:Home:SkillCopiedTitle'),
                time: Date.now(),
            });
    }, [addAlert, t]);
    var onAddSkill = useCallback(function () {
        wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true, fromTab: HOME_TAB_CODE } });
    }, [wrappedHistoryPush]);
    var goToLL = useCallback(function () {
        wrappedHistoryPush({ url: 'log-labeling', historyState: { addSkill: true, fromTab: HOME_TAB_CODE } });
    }, [wrappedHistoryPush]);
    return (React.createElement("div", { className: styles.FaqHomeView },
        React.createElement("div", { className: styles.FaqHomeView__content },
            React.createElement("h1", { className: styles.title }, t('FAQ:Home:Title')),
            (skills === null || skills === void 0 ? void 0 : skills.length) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.FaqHomeView__addPanel },
                    React.createElement(Button, { flat: true, color: 'secondary', onClick: onAddSkill, disabled: !isAccessFunction('BOT_CONTENT_WRITE'), size: 'sm' }, t('FAQ:AddSkill')),
                    isAccessFunction('nlu.log_labeling') && !isZfl && (React.createElement(Button, { flat: true, color: 'secondary', onClick: goToLL, size: 'sm' }, t('FAQ:Home:GoToLL'))),
                    !isZfl && (React.createElement(Button, { flat: true, color: 'secondary', onClick: toggleSimilarsModalOpen, size: 'sm' }, t('similarsSettingBtn')))),
                React.createElement("table", { className: styles.table, style: {
                        '--col-1-width': '100%;',
                        '--col-2-width': '200px',
                        '--col-3-width': '200px',
                        '--col-4-width': '100px',
                        '--col-5-width': '70px',
                    } },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, t('FAQ:Home:SkillTable:Headers:Title')),
                            React.createElement("th", null, t('FAQ:Home:SkillTable:Headers:Edited')),
                            React.createElement("th", null, t('FAQ:Home:SkillTable:Headers:Created')),
                            React.createElement("th", null, t('FAQ:Home:SkillTable:Headers:Active')),
                            React.createElement("th", null))),
                    React.createElement("tbody", null, skills.map(function (skill) { return (React.createElement(SkillRow, { onClick: onSelect, key: skill.name, skill: skill, isLoading: (selectedSkill === null || selectedSkill === void 0 ? void 0 : selectedSkill.id) === skill.id &&
                            (renameSkillState.loading ||
                                deleteSkillState.loading ||
                                toggleSkillState.loading ||
                                updateSkillState.loading ||
                                goToAnalyticState.loading), copySkill: copySkillCode, toggleActive: onToggleInner, onRename: onRenameModalOpen, onDelete: onDeleteInner, showAnalytic: showAnalytic })); }))),
                React.createElement(SimilarsModal, { isOpen: isSimilarsModalOpen, onClose: toggleSimilarsModalOpen }),
                selectedSkill && (React.createElement(React.Fragment, null,
                    selectedSkill.type === SkillType.Faq ? (React.createElement(RenameSkillModal, { isOpen: isRenameModalOpen, skill: selectedSkill, skills: skills, onRename: renameSkillApi, onClose: toggleRenameModalOpen })) : (React.createElement(CDQAModal, { isOpen: isRenameModalOpen, toggle: toggleRenameModalOpen, onSubmit: updateSkillApi, existingData: {
                            name: selectedSkill.name,
                            skillId: selectedSkill.id,
                            settings: selectedSkill.settings,
                        } })),
                    React.createElement(DeleteSkillModal, { skill: selectedSkill, isOpen: isDeleteModalOpen, onDelete: deleteSkillApi, onClose: toggleDeleteModalOpen }))))) : !loading ? (React.createElement(EmptyFaqContent, { onCreate: onAddSkill })) : null)));
};
export default React.memo(FaqHomeView);
