import React, { FC, useCallback } from 'react';

import { TagNames } from '../../../utils/types';

import { IntentsActivationEdit } from '../RSAddingMenu/ActivationsAddingMenu/IntentsActivationEdit';
import { useCommonActivationSettings } from './utils';

export const IntentEventSettings: FC = () => {
  const { mapTagParametersToObj, isGlobal, changeEventParams, block, onChangeCommonTag } = useCommonActivationSettings(
    TagNames.intent_,
    TagNames.intent
  );

  const changeIntent = useCallback(
    (value: string) => {
      onChangeCommonTag({
        tagValue: value,
      });
    },
    [onChangeCommonTag]
  );

  return (
    <div className='JGraph-RightSideMenu-commonContainer'>
      <IntentsActivationEdit
        intent={block.tagValue || ''}
        changeEventParams={changeEventParams}
        eventState={{
          isGlobal: isGlobal,
          onlyThisState: mapTagParametersToObj.onlyThisState?.value,
          fromStateValue: mapTagParametersToObj.fromState?.value || '',
          toStateValue: mapTagParametersToObj.toState?.value || '',
        }}
        changeIntent={changeIntent}
        isGlobal={isGlobal}
        isToState={!!mapTagParametersToObj.toState?.value}
        isFromState={!mapTagParametersToObj.toState?.value || isGlobal}
      />
    </div>
  );
};
