import { useCallback, useEffect, useState } from 'react';
export var useToggleSelect = function (initialValue) {
    if (initialValue === void 0) { initialValue = undefined; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    var toggleSelect = useCallback(function (newSelectedValue) {
        if (newSelectedValue === value) {
            setValue(undefined);
        }
        else {
            setValue(newSelectedValue);
        }
    }, [value]);
    var clearSelect = useCallback(function () {
        setValue(undefined);
    }, []);
    return [value, setValue, toggleSelect, clearSelect];
};
export var useErrorDirty = function (dependencie) {
    var _a = useState(undefined), error = _a[0], setError = _a[1];
    useEffect(function () {
        if (dependencie && error) {
            setError(undefined);
        }
    }, [error, dependencie]);
    var clearError = useCallback(function () {
        setError(undefined);
    }, []);
    return [error, setError, clearError];
};
