import React, { Fragment, useEffect, useRef, useState } from 'react';
import localize from '../../../localization';
import { Icon, InputText, Popover, InputGroup, Tooltip, IconButton, Select } from '@just-ai/just-ui';
import { FormGroup, Input, Label, UncontrolledAlert, DropdownMenu } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { useToggle } from '../../../modules/Caila/utils';
import isAccess, { isConfigEnabled, isEuroInstance } from '../../../isAccessFunction';
import { Popohover } from '../../TariffPage/Popohover';

const JAICF_TEMPLATE_EXAMPLE = 'https://github.com/just-ai/jaicf-jaicp-caila-template';
const TOOLTIP_DELAY = 3000;

export function RepoLocationJAICF({
  onValidateForm,
  externalBotToken,
  updateExternalBotToken,
  webHookUrlRef,
  webHookUrlSelected,
  editableProject,
  nameRef,
  languages,
  selectedLanguage,
  customStyles,
  changeLanguage,
}) {
  const [isOpen, , close, toggle] = useToggle(false);
  const [isHidden, hide, display, toggleHidden] = useToggle(true);
  const externalBotTokenRef = useRef(null);
  const templateExampleRef = useRef(null);
  const [webhookSelected, setWebhookSelected] = useState(webHookUrlSelected);
  const [isOpenedLinkTooltip, openLinkTooltip, closeLinkTooltip] = useToggle(false);
  const [isOpenedTokenTooltip, openTokenTooltip, closeTokenTooltip] = useToggle(false);
  const [tmpWebhookName, setTmpWebhookName] = useState('');

  useEffect(() => {
    setWebhookSelected(webHookUrlSelected);
  }, [webHookUrlSelected]);

  const onClickUpdateToken = e => {
    e.preventDefault();
    toggle();
  };

  const copyBotToken = () => {
    if (!externalBotTokenRef) return;
    externalBotTokenRef.current.type = 'text';
    externalBotTokenRef.current.select();
    document.execCommand('copy');
    externalBotTokenRef.current.type = 'password';
    hide();
    openTokenTooltip();
    setTimeout(closeTokenTooltip, TOOLTIP_DELAY);
  };

  const copyLink = () => {
    if (!templateExampleRef) return;
    templateExampleRef.current.select();
    document.execCommand('copy');
    openLinkTooltip();
    setTimeout(closeLinkTooltip, TOOLTIP_DELAY);
  };

  const changeWebhook = e => {
    setWebhookSelected(e.target.value === 'true');
    if (e.target.value === 'false') {
      webHookUrlRef.current.value = '';
    } else {
      webHookUrlRef.current.value = tmpWebhookName;
    }
    setTimeout(onValidateForm, 0);
  };

  const onChangeWebhookUrl = value => {
    onValidateForm();
    value && setTmpWebhookName(value);
  };

  const openLink = () => window.open(JAICF_TEMPLATE_EXAMPLE);

  return (
    <Fragment>
      {!isAccess(['framework.cloud']) && (
        <>
          <div className='form-group row'>
            <label className='col-md-4 form-control-label form-control-label-jaicf' htmlFor='name'>
              <span>
                {localize.translate('jaicf form field name')}
                <span className='red-dot-jaicf'>*</span>
              </span>
            </label>
            <div className='col-md-8'>
              <InputText
                type='text'
                name='name'
                maxLength='30'
                id='name'
                required
                placeholder={localize.translate('Description')}
                defaultValue={Boolean(editableProject) ? editableProject.name : ''}
                innerRef={nameRef}
                onChange={onValidateForm}
                data-test-id='projectsPage.createProjectModal.nameInputText'
                autoFocus
              />
            </div>
          </div>
          {isAccess(['BOT_CONTENT_READ', 'nlu']) && Boolean(languages.length) && (
            <div className='form-group row'>
              <label className='col-md-4 form-control-label'>
                {localize.translate('caila project language')}
                <Icon color='primary' name='faInfoCircle' size='sm' id='cailaLanguage' />
                <Popohover className='project-language-popover' target='cailaLanguage' closable={false}>
                  <p>{localize.translate('caila project language tooltip')}</p>
                  <p>{localize.translate('caila project language tooltip list header')}</p>
                  <ul>
                    <li>{localize.translate('caila project language tooltip list item1')}</li>
                    <li>{localize.translate('caila project language tooltip list item2')}</li>
                    <li>{localize.translate('caila project language tooltip list item3')}</li>
                  </ul>
                  {isConfigEnabled('showHelp') && (
                    <a href={localize.translate('caila project language tooltip link')}>
                      <Button color='primary'>
                        {localize.translate('caila project language tooltip btn caption')}
                      </Button>
                    </a>
                  )}
                </Popohover>
              </label>
              <div className='col-md-8'>
                <Select
                  options={languages}
                  isMulti={false}
                  isClearable={false}
                  styles={customStyles}
                  onChange={changeLanguage}
                  value={selectedLanguage}
                  dropdownComponent={DropdownMenu}
                  menuPosition='fixed'
                  data-test-id='projectsPage.createProjectModal.languageSelect'
                  noOptionsMessage={() => localize.translate('BotControls:not_found')}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className='form-group row mb-1'>
        <div className='col-md-12'>
          <UncontrolledAlert color='info' className='info'>
            <div className='mr-2'>
              <Icon color='primary' name='faInfoCircle' size='sm' />
            </div>
            <div>
              <p>{localize.translate('jaicf form field info 1', isEuroInstance() ? 'Tovie Framework' : 'JAICF')}</p>
              <p>{localize.translate('jaicf form field info 2')}</p>
              <p>{localize.translate('jaicf form field info 3')}</p>
            </div>
          </UncontrolledAlert>
        </div>
      </div>

      <div className='form-group row ml-0 mr-0 mb-1'>
        <div className='col-md-12 bordered-box'>
          <h3>{localize.translate('jaicf form field template')}</h3>
          <p>{localize.translate('jaicf form field template description')}</p>
          <div className='fake-input'>
            <div>
              <a rel='noopener noreferrer' href={JAICF_TEMPLATE_EXAMPLE} target='_blank'>
                {JAICF_TEMPLATE_EXAMPLE}
              </a>
            </div>
            <div>
              <IconButton
                name='faCopy'
                onClick={copyLink}
                style={{ color: 'var(--gray)' }}
                id='templateCopy'
                flat
                withoutPadding
              />
              <IconButton
                name='faLink'
                onClick={openLink}
                style={{ color: 'var(--gray)' }}
                id='templateLink'
                flat
                withoutPadding
              />
              <Tooltip placement='top' target='templateCopy'>
                {localize.translate('jaicf copy tooltip')}
              </Tooltip>
              <Tooltip isOpen={isOpenedLinkTooltip} placement='top' target='templateCopy'>
                {localize.translate('jaicf copied tooltip')}
              </Tooltip>
              <Tooltip placement='top' target='templateLink'>
                {localize.translate('jaicf go to link tooltip')}
              </Tooltip>
            </div>
            <input
              type='text'
              value={JAICF_TEMPLATE_EXAMPLE}
              ref={templateExampleRef}
              readOnly
              className='hiddenInput'
            />
          </div>
        </div>
      </div>

      <div className='form-group row ml-0 mr-0 mb-1'>
        <div className='col-md-12 bordered-box'>
          <h3>{localize.translate('jaicf form field api token')}</h3>
          <p>{localize.translate('jaicf form field api token description')}</p>
          <div className='inputs-block'>
            <div>
              <InputGroup
                AppendInner={[
                  {
                    name: isHidden ? 'faEye' : 'faEyeSlash',
                    onClick: toggleHidden,
                    style: { color: 'var(--gray)' },
                    id: 'tokenShow',
                  },
                  {
                    name: 'faCopy',
                    onClick: copyBotToken,
                    style: { color: 'var(--gray)' },
                    id: 'tokenCopy',
                  },
                ]}
              >
                <input
                  className='form-control'
                  value={externalBotToken}
                  style={{ borderRadius: 4 }}
                  type={isHidden ? 'password' : 'text'}
                  ref={externalBotTokenRef}
                  name='externalBotTokenText'
                  data-test-id='projectsPage.createProjectModal.externalBotTokenText'
                  readOnly
                />
                <Tooltip placement='top' target='tokenShow'>
                  {localize.translate(isHidden ? 'jaicf show tooltip' : 'jaicf hide tooltip')}
                </Tooltip>
                <Tooltip placement='top' target='tokenCopy'>
                  {localize.translate('jaicf copy tooltip')}
                </Tooltip>
                <Tooltip isOpen={isOpenedTokenTooltip} placement='top' target='tokenCopy'>
                  {localize.translate('jaicf copied tooltip')}
                </Tooltip>
              </InputGroup>
            </div>
            <div>
              <Button color='danger' id='update-token' outline onClick={onClickUpdateToken}>
                {localize.translate('jaicf form update token')}
              </Button>
              <Popover
                isOpen={isOpen}
                onCancelClick={close}
                target='update-token'
                title={localize.translate('jaicf form update token title')}
                placement='top'
                buttonLeftText={localize.translate('jaicf form update token button')}
                buttonLeftColor='danger'
                onButtonLeftClick={() => {
                  updateExternalBotToken();
                  display();
                  close();
                }}
                buttonRightText={localize.translate('jaicf form update token button cancel')}
                buttonRightColor='secondary'
                buttonRightOutline
                onButtonRightClick={close}
              >
                <span>{localize.translate('jaicf form update token message')}</span>
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <div className='form-group row ml-0 mr-0'>
        <div className='col-md-12 bordered-box'>
          <h3>{localize.translate('jaicf form field connection type')}</h3>
          <p>{localize.translate('jaicf form field connection type description')}</p>
          <FormGroup check className='radio-buttons'>
            <Label check className='mb-3'>
              <Input type='radio' name='webhook' value='false' onChange={changeWebhook} checked={!webhookSelected} />{' '}
              {localize.translate('jaicf form field connection type long pooling')}
              <p>
                {localize.translate(
                  'jaicf form field connection type long pooling description',
                  localize.translate(isEuroInstance() ? 'Tovie Platform' : 'JAICP')
                )}
              </p>
            </Label>
            <Label check>
              <Input type='radio' name='webhook' value='true' onChange={changeWebhook} checked={webhookSelected} />{' '}
              {localize.translate('jaicf form field connection type webhook')}
              <p>{localize.translate('jaicf form field connection type webhook description')}</p>
            </Label>
            <InputText
              type='text'
              name='webhook'
              innerRef={webHookUrlRef}
              onChange={onChangeWebhookUrl}
              placeholder={localize.translate('jaicf form field connection type webhook placeholder')}
              disabled={!webhookSelected}
              pattern='https?://.+'
              data-test-id='projectsPage.createProjectModal.webhookText'
            />
          </FormGroup>
        </div>
      </div>
    </Fragment>
  );
}
