var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useCallback, useState, useMemo, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDrop, useDragLayer } from 'react-dnd';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { DragTypes } from '../../../../../../views/FAQTreePage/DragTypes';
import { TreeContext, TREE_DEPTH_PADDING } from '../../index';
import styles from '../../styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
var GROUP_ICON_WIDTH = 40;
var Branch = function (_a) {
    var _b;
    var _this = this;
    var _c, _d, _e;
    var node = _a.node, isEdit = _a.isEdit, style = _a.style, initialChildrenLength = _a.initialChildrenLength, searchValue = _a.searchValue, isSearchActive = _a.isSearchActive, toggleSearchMode = _a.toggleSearchMode;
    var context = useContext(TreeContext);
    var isExpanded = useMemo(function () { return context.expandedMap[node.id]; }, [context.expandedMap, node.id]);
    var isSelected = useMemo(function () { return context.selectedIds.includes(node.id); }, [context.selectedIds, node.id]);
    var _f = useState(node.name), editText = _f[0], setEditText = _f[1];
    var containerNodeRef = useRef(null);
    var t = useTranslation().t;
    useEffect(function () {
        if (!!searchValue && node.children.length > 0 && !isExpanded) {
            context.onExpandToggle(node);
        }
        if (!!searchValue && node.children.length === 0 && isExpanded) {
            context.onExpandToggle(node);
        }
        if (isSearchActive && (searchValue === null || searchValue === void 0 ? void 0 : searchValue.length) === 0 && isExpanded) {
            context.onExpandToggle(node);
        }
    }, [searchValue, node, isExpanded, context, isSearchActive, isSelected]);
    useEffect(function () {
        setEditText(node.name);
    }, [node.name]);
    var onContextMenu = useCallback(function (event) {
        event.stopPropagation();
        event.preventDefault();
        !isEdit && context.handleContextMenu(event, node);
    }, [context, isEdit, node]);
    var onRename = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var setEditId, onRename, setError, hasError;
        return __generator(this, function (_a) {
            setEditId = context.setEditId, onRename = context.onRename, setError = context.setError, hasError = context.hasError;
            if (node.name === editText) {
                setEditId(undefined);
                return [2 /*return*/];
            }
            if (hasError) {
                setEditText(node.name);
                setEditId(undefined);
                setError(undefined);
                return [2 /*return*/];
            }
            onRename(editText, node)
                .then(function () {
                setEditText(node.name);
                setEditId(undefined);
                setError(undefined);
            })
                .catch(function (error) {
                setError({
                    text: t('FAQ:RenameError:AlreadyExists'),
                    node: containerNodeRef.current || undefined,
                    direction: 'left',
                });
            });
            return [2 /*return*/];
        });
    }); }, [context, editText, node]);
    var onKeydownInput = useCallback(function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (e.key !== 'Enter')
                        return [2 /*return*/];
                    return [4 /*yield*/, onRename()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onRename]);
    var onChange = useCallback(function (e) {
        setEditText(e.target.value);
        var hasError = context.hasError, setError = context.setError;
        if (hasError)
            setError(undefined);
    }, [context]);
    var _g = useDrop({
        accept: DragTypes.LEAF,
        drop: function (item) {
            context.onDragDrop(item.node, node);
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
        }); },
    }), isOver = _g[0].isOver, drop = _g[1];
    var isDraggingOtherElement = useDragLayer(function (monitor) { return ({
        isDraggingOtherElement: monitor.isDragging(),
    }); }).isDraggingOtherElement;
    var handleGroupClick = useCallback(function () {
        if (isSearchActive) {
            toggleSearchMode && toggleSearchMode();
            context.onSelectToggle(node);
            !isExpanded && context.onExpandToggle(node);
        }
        else if (!isEdit)
            context.onSelectToggle(node);
    }, [isSearchActive, isEdit, context, node, toggleSearchMode, isExpanded]);
    var depthLevel = (_c = node.depthLevel) !== null && _c !== void 0 ? _c : 0;
    var paddingLeft = depthLevel ? depthLevel * TREE_DEPTH_PADDING : '';
    return (React.createElement("div", { style: style, ref: containerNodeRef },
        React.createElement("div", { ref: drop, className: cn(styles.node, (_b = {},
                _b[styles.node__groupSelected] = isSelected,
                _b[styles.disableHover] = isDraggingOtherElement && !isOver,
                _b[styles.node__elementDisabled] = !node.enabled,
                _b[styles.node__isOver] = isOver,
                _b)), style: { paddingLeft: paddingLeft }, onClick: handleGroupClick, onContextMenu: onContextMenu, "data-test-id": "FAQ.Tree.Branch.".concat(node.name) },
            React.createElement("span", { onClick: function (e) {
                    if (isSearchActive)
                        return;
                    e.stopPropagation();
                    !isEdit && context.onExpandToggle(node);
                }, "data-test-id": "FAQ.Tree.Branch.".concat(node.name, ".toggle") }, isExpanded ? React.createElement(Icon, { name: 'faChevronDown', size: 'sm' }) : React.createElement(Icon, { name: 'faChevronRight', size: 'sm' })),
            isEdit ? (React.createElement("div", { className: styles.editContainer },
                React.createElement("input", { value: editText, type: 'text', autoFocus: true, "data-test-id": "FAQ.Tree.Branch.".concat(node.name, ".input"), placeholder: t('FAQ:Intent:BaseGroupName'), className: styles.node__editControl, onKeyDown: onKeydownInput, onChange: onChange, onBlur: onRename }),
                context.hasError && React.createElement(Icon, { name: 'farExclamationCircle', className: styles.errorIcon }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: styles.text }, node.name),
                React.createElement("span", { style: { color: 'var(--gray-500)' } }, !!searchValue ? "".concat(node.children.length, "/").concat(initialChildrenLength) : node.children.length)))),
        isExpanded &&
            (!node.leafs ||
                (((_d = node.leafs) === null || _d === void 0 ? void 0 : _d.length) === 0 && ((_e = node === null || node === void 0 ? void 0 : node.branches) === null || _e === void 0 ? void 0 : _e.length) === 0 && (React.createElement("div", { style: { paddingLeft: (paddingLeft || 0) + GROUP_ICON_WIDTH }, className: styles.node__emptyMessage }, t('FAQ:Intent:EmptyGroup')))))));
};
export default React.memo(Branch);
