var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import { Popover as RsPopover } from 'reactstrap';
import './Popover.scss';
import classNames from 'classnames';
import { Button, IconButton } from '../Button';
import { useToggle } from '../utils';
export const Popover = (_a) => {
    var { innerClassName, title, className, onCancelClick, children, smallText, withoutBoxShadow, buttonLeftText, buttonLeftSize = 'sm', buttonLeftColor = 'primary', buttonLeftOutline, buttonLeftTestId, buttonLeftDisabled, buttonRightText, buttonRightSize = 'sm', buttonRightColor = 'primary', buttonRightOutline, buttonRightTestId, buttonRightDisabled, onButtonLeftClick, onButtonRightClick, target, dark, placement } = _a, restProps = __rest(_a, ["innerClassName", "title", "className", "onCancelClick", "children", "smallText", "withoutBoxShadow", "buttonLeftText", "buttonLeftSize", "buttonLeftColor", "buttonLeftOutline", "buttonLeftTestId", "buttonLeftDisabled", "buttonRightText", "buttonRightSize", "buttonRightColor", "buttonRightOutline", "buttonRightTestId", "buttonRightDisabled", "onButtonLeftClick", "onButtonRightClick", "target", "dark", "placement"]);
    return (React.createElement(RsPopover, Object.assign({ placement: placement, className: classNames('justui_popover', className, {
            'justui_popover--without-shadow': withoutBoxShadow,
            dark: dark,
        }), target: target || 'Popover1', boundariesElement: 'window' }, restProps),
        title ? (React.createElement("div", { className: 'justui_popover-header' },
            React.createElement("span", { className: 'justui_popover-title' }, title),
            onCancelClick && (React.createElement(IconButton, { name: 'faTimes', className: 'close justui_popover-btn-close', onClick: onCancelClick })))) : null,
        React.createElement("div", { className: classNames('justui_popover-body', {
                'justui_popover-body--small': smallText,
                'justui_popover-body--without-buttons': !onButtonLeftClick && !onButtonRightClick,
                'justui_popover-body--close-button': !title && onCancelClick,
            }) }, !title && onCancelClick ? (React.createElement(Fragment, null,
            React.createElement("div", null, children),
            React.createElement(IconButton, { name: 'faTimes', className: 'close justui_popover-btn-close justui_popover-btn-close--absolute', onClick: onCancelClick }))) : (children)),
        (onButtonLeftClick || onButtonRightClick) && (React.createElement("div", { className: 'justui_popover-buttons' },
            onButtonLeftClick && (React.createElement(Button, { size: buttonLeftSize, color: buttonLeftColor, outline: buttonLeftOutline, onClick: onButtonLeftClick, "data-test-id": buttonLeftTestId, disabled: buttonLeftDisabled, type: 'button', dark: dark }, buttonLeftText)),
            onButtonRightClick && (React.createElement(Button, { size: buttonRightSize, color: buttonRightColor, outline: buttonRightOutline, onClick: onButtonRightClick, "data-test-id": buttonRightTestId, disabled: buttonRightDisabled, type: 'button', dark: dark }, buttonRightText))))));
};
export function Pophover(props) {
    const { target, children, className, closable = true } = props, restProps = __rest(props, ["target", "children", "className", "closable"]);
    const [isOpen, setOpen, setClose, toggle] = useToggle(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, Object.assign({ isOpen: isOpen, toggle: toggle, target: target, className: `popohover ${className}`, trigger: 'hover' }, restProps, { onCancelClick: closable ? setClose : undefined }),
            React.createElement("div", null, children))));
}
