import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Icon, JustSelect, MessagesTypes, useToggle } from '@just-ai/just-ui';

import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { findScreenByPathId, getAllStates, getValidKonvaName } from 'reducers/JGraph.reducer/Graph';
import { setEditMenuBlock } from 'reducers/JGraph.reducer';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

import { FloatingConnectorMenu$ } from '../FloatingConnectorMenu';
import classes from './style.module.scss';
import keyboardJS, { KeyEvent } from 'keyboardjs';
import { Subject } from 'rxjs';
import { composeSubComponents } from '../../../../utils/helpFunctions';

type SearchStateProps = {};

const State$ = new Subject<boolean>();

const SearchState: FC<SearchStateProps> = () => {
  const { screens } = useAppSelector(state => ({
    screens: state.JGraphReducer.graph.blocks,
  }));
  const dispatch = useAppDispatch();
  const selectRef = useRef<HTMLDivElement | null>(null);

  const [openSearch, setOpenSearch, setCloseSearch] = useToggle(false);

  const options = useMemo(() => {
    return getAllStates(screens).map(state => ({
      label: hideRootSlashInPath(state),
      value: state,
    }));
  }, [screens]);

  const handleChange = useCallback(
    value => {
      const valueToTransition = value[0];
      if (valueToTransition) {
        const screenPathId = getValidKonvaName(valueToTransition);
        scrollToTargetGlobal$.next({
          targetPathId: screenPathId,
          isSideMenuOpen: true,
        });
        FloatingConnectorMenu$.next({ connector: undefined });
        const targetState = findScreenByPathId(screenPathId, screens);
        dispatch(setEditMenuBlock({ screen: targetState, jBlockIndex: undefined, path: undefined }));
      }
      setCloseSearch();
    },
    [dispatch, screens, setCloseSearch]
  );

  useEffect(() => {
    const input = selectRef.current?.querySelector('input');
    if (input && openSearch) {
      input.focus();
    }
  }, [openSearch]);

  useEffect(() => {
    const open = (e?: KeyEvent) => {
      e?.preventDefault();
      setOpenSearch();
    };
    const close = (e?: KeyEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      setCloseSearch();
    };

    const sub = State$.subscribe(value => {
      if (value) setOpenSearch();
      if (!value) setCloseSearch();
    });

    keyboardJS.bind(['ctrl + f', 'command + f'], open);
    keyboardJS.bind('esc', close);

    return () => {
      sub.unsubscribe();
      keyboardJS.unbind(['ctrl + f', 'command + f'], open);
      keyboardJS.unbind('esc', close);
    };
  }, [setCloseSearch, setOpenSearch]);

  if (!openSearch) return null;

  return (
    <>
      <div className={classes.backDrop} onClick={setCloseSearch} />
      <div className={classes.searchState}>
        <Icon wrapperClassName={classes.searchIcon} name='farSearch' color='secondary' />
        <JustSelect
          inputPlaceholder={t('JGraph:SearchStatePlaceholder')}
          getRef={ref => {
            selectRef.current = ref;
          }}
          className={classes.select}
          classNameForList={classes.searchStateList}
          fullWidth
          options={options}
          onChange={handleChange}
          messages={{
            [MessagesTypes.NOT_FOUND]: t('JGraph:SearchState_NOT_FOUND'),
            [MessagesTypes.EMPTY]: t('JGraph:SearchState_EMPTY'),
          }}
        />
      </div>
    </>
  );
};

export default composeSubComponents(SearchState, {
  State$,
});
