import React, { useContext, Fragment } from 'react';
import { getUserValidName } from '../../../../utils/pipes/pureFunctions';
import classNames from 'classnames';
import { Icon, Spoiler } from '@just-ai/just-ui';
import AudioBlock from './AudioBlock';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
export default function Question(_a) {
    var _b, _c;
    var question = _a.question, questionId = _a.questionId, event = _a.event, data = _a.data, cleaned = _a.cleaned, utterancesJson = _a.utterancesJson, responseData = _a.responseData, textAnswer = _a.textAnswer, operatorInfo = _a.operatorInfo, requestData = _a.requestData, t = _a.t, _d = _a.showAnswers, showAnswers = _d === void 0 ? true : _d, startTimeDialog = _a.startTimeDialog;
    var accountId = useContext(AnalyticsContext).accountId;
    var imageEvent = null;
    var fileEvent;
    var questionBargin;
    var answer = textAnswer || (responseData === null || responseData === void 0 ? void 0 : responseData.answer);
    if (!!event) {
        if (event === 'imageEvent') {
            if (!!(data === null || data === void 0 ? void 0 : data.eventData)) {
                imageEvent = data.eventData.url;
            }
        }
        if (event === 'fileEvent') {
            if (data && (data === null || data === void 0 ? void 0 : data.eventData)) {
                fileEvent = data.eventData;
            }
        }
        if (event === 'bargeInIntent') {
            questionBargin = (_b = requestData === null || requestData === void 0 ? void 0 : requestData.bargeInIntentStatus) === null || _b === void 0 ? void 0 : _b.recognitionResult.text;
        }
    }
    //@ts-ignore
    var rawReplies = ((responseData === null || responseData === void 0 ? void 0 : responseData.replies) && ((_c = responseData === null || responseData === void 0 ? void 0 : responseData.replies) === null || _c === void 0 ? void 0 : _c.length) > 0 && (responseData === null || responseData === void 0 ? void 0 : responseData.replies)) || answer || textAnswer;
    var replies = rawReplies;
    var repliesToRender = [];
    var repliesInlineButtons = [];
    var repliesButtons = [];
    var imageUrl = [];
    var googleSheets = responseData === null || responseData === void 0 ? void 0 : responseData.googleSheets;
    if (Boolean(rawReplies)) {
        try {
            if (typeof rawReplies !== 'object') {
                replies = [
                    {
                        text: rawReplies,
                        type: 'text',
                    },
                ];
            }
            if (Array.isArray(replies)) {
                replies.map(function (reply, key) {
                    switch (reply.type) {
                        case 'raw':
                            repliesToRender.push(React.createElement("div", { key: questionId + '_raw_' + key }, JSON.stringify(reply) || t('Message has been deleted')));
                            break;
                        case 'text': {
                            if (reply.hasOwnProperty('errorStacktrace')) {
                                repliesToRender.push(React.createElement(React.Fragment, null,
                                    reply.text && React.createElement("div", { key: questionId + '_error_textAnswer_' + key }, reply.text),
                                    React.createElement("div", { key: questionId + '_error_' + key, className: 'text-danger' }, reply.errorStacktrace)));
                                break;
                            }
                            repliesToRender.push(React.createElement("div", { key: questionId + '_text_' + key }, reply.text === 'null' ? t('Message has been deleted') : reply.text));
                            break;
                        }
                        case 'inlineButtons': {
                            reply.buttons.forEach(function (button, index) {
                                repliesInlineButtons.push(React.createElement("button", { key: questionId + "_inlineButtons_".concat(index, "_") + key, title: button.callback_data, className: 'btn btn-outline-info btn-sm mr-1 mb-1' }, button.text));
                            });
                            break;
                        }
                        case 'buttons': {
                            reply.buttons.forEach(function (button, index) {
                                repliesButtons.push(React.createElement("button", { key: questionId + "_buttons_".concat(index, "_") + key, className: 'btn btn-outline-info btn-sm mr-1 mb-1' }, button.text));
                            });
                            break;
                        }
                        case 'image': {
                            imageUrl.push({ url: reply.imageUrl, text: reply.text });
                            break;
                        }
                        case 'file': {
                            repliesToRender.push(React.createElement(File, { fromBot: true, file: { url: reply.fileUrl, name: reply.fileName, type: reply.mimeType }, index: questionId }));
                            break;
                        }
                        case 'audio': {
                            repliesToRender.push(React.createElement("div", { key: questionId + '_audio_' + key },
                                React.createElement("a", { rel: 'noopener noreferrer', href: reply.audioUrl, target: '_blank' }, reply.audioName ? reply.audioName : decodeURIComponent(reply.audioUrl))));
                            break;
                        }
                        case 'ttsTemplate': {
                            repliesToRender.push(React.createElement("div", { key: questionId + '_text_' + key }, reply.ttsTemplate.text === 'null' ? t('Message has been deleted') : reply.ttsTemplate.text));
                            break;
                        }
                        case 'telegramPayment': {
                            repliesToRender.push(React.createElement("div", { className: 'ClientDialogMessage_payment', key: questionId + '_payment' + key },
                                t('Analytics: messages event'),
                                ": ",
                                reply.type,
                                " ",
                                reply.amount,
                                " ",
                                reply.currency));
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    return (React.createElement("div", { className: 'phrases-messages' },
        React.createElement("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item' },
            React.createElement(ClientReply, { imageEvent: imageEvent, fileEvent: fileEvent, cleaned: cleaned, question: questionBargin || question, utterancesJson: utterancesJson, accountId: accountId, timestamp: startTimeDialog, t: t })),
        showAnswers && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames('qa-field qa-answer qa-block Message_answer_question', {
                    'qa-answer-operatorInfo': !!operatorInfo,
                }) },
                React.createElement("div", { className: 'text-muted phrases-messages--qa-bottom-text' }, t('Analytics: messages bot label')),
                operatorInfo && (React.createElement("div", { className: 'text-muted small' },
                    React.createElement("strong", null, "#".concat(operatorInfo.id)),
                    ' ', "".concat(getUserValidName(operatorInfo.firstName, operatorInfo.lastName, t)))),
                React.createElement("div", null,
                    React.createElement("div", null,
                        cleaned ? (React.createElement("div", null,
                            React.createElement("i", null, t('Message has been deleted')))) : (repliesToRender),
                        imageUrl[0] &&
                            imageUrl.map(function (_a, index) {
                                var url = _a.url, text = _a.text;
                                return (React.createElement(Fragment, { key: index },
                                    url && (React.createElement("div", { style: { marginTop: 8, marginBottom: 8 } },
                                        React.createElement("img", { alt: 'message image', style: { maxHeight: 300 }, src: url }))),
                                    text && React.createElement("div", null, text)));
                            })),
                    repliesInlineButtons.length > 0 && React.createElement("div", null, repliesInlineButtons),
                    repliesButtons.length > 0 && React.createElement("div", null, repliesButtons))),
            googleSheets && (React.createElement(React.Fragment, null,
                React.createElement("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item' },
                    React.createElement("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-without-icon' },
                        React.createElement("div", { className: 'text-muted phrases-messages--qa-bottom-text' },
                            t('GoogleSheets'),
                            ":")),
                    React.createElement("div", { className: 'qa-field qa-answer qa-block' },
                        React.createElement("div", null, googleSheets.integrationId))),
                React.createElement("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item' },
                    React.createElement("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-without-icon' },
                        React.createElement("div", { className: 'text-muted phrases-messages--qa-bottom-text' },
                            t('ProjectReview:volumeTable header result'),
                            ":")),
                    React.createElement("div", { className: 'qa-field qa-answer qa-block' },
                        React.createElement("div", null, googleSheets.result)))))))));
}
//@ts-ignore
var ClientReply = function (_a) {
    var imageEvent = _a.imageEvent, fileEvent = _a.fileEvent, cleaned = _a.cleaned, question = _a.question, utterancesJson = _a.utterancesJson, t = _a.t, accountId = _a.accountId, timestamp = _a.timestamp;
    var text = utterancesJson && utterancesJson.length ? getUtteranceQuestion(utterancesJson, t) : question;
    if (text === 'null')
        text = t('Message has been deleted');
    if (!text && !fileEvent && !imageEvent) {
        return null;
    }
    if (Array.isArray(utterancesJson) && utterancesJson.length > 0) {
        return (React.createElement(Spoiler, { Header: function (_a) {
                var toggleOpen = _a.toggleOpen, isOpen = _a.isOpen;
                return (React.createElement("div", { "data-test-id": 'Question.ClientReply.messageAudioSpoiler', className: 'text-muted Message_audio_head', onClick: toggleOpen },
                    React.createElement("span", null, t('Analytics: messages client label')),
                    React.createElement(Icon, { name: isOpen ? 'farMinusSquare' : 'farPlusSquare', color: 'info', className: 'phrases-messages--qa-icon', onClick: toggleOpen }),
                    ' ',
                    React.createElement("span", null, utterancesJson.map(function (x) { return x.text; }).join(', ') || text)));
            } },
            React.createElement("div", { className: 'qa-block qa-block--opened-bottom Message_audios_answers' }, utterancesJson.map(function (utterance, i) { return (React.createElement("div", { key: "audio-utterance-".concat(i) },
                React.createElement(AudioBlock, { url: "/restapi/download/".concat(accountId, "/recordings/asr/").concat(utterance.resourcePath), title: utterance.text, meta: utterance, t: t, dataTestId: "Question.ClientReply-".concat(i), startTime: timestamp }))); }))));
    }
    return (React.createElement("div", { className: 'phrases-messages--qa-container Message_answer_question' },
        React.createElement("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-question' },
            React.createElement("div", { className: 'text-muted' }, t('Analytics: messages client label'))),
        React.createElement("div", { className: 'qa-block' },
            React.createElement("div", { className: 'qa-field qa-question' },
                imageEvent && React.createElement("img", { src: imageEvent, alt: 'event' }),
                Array.isArray(fileEvent) &&
                    fileEvent.map(function (file, index) {
                        return Array.isArray(file) ? (file.map(function (file, k) { return React.createElement(File, { file: file, index: "".concat(index, "_").concat(k), key: "".concat(index, "_").concat(k) }); })) : (React.createElement(File, { file: file, index: index }));
                    }),
                imageEvent || fileEvent ? ' ' : null,
                cleaned && (React.createElement("div", null,
                    React.createElement("i", null, t('Message has been deleted')))),
                text))));
};
function File(_a) {
    var file = _a.file, index = _a.index, fromBot = _a.fromBot;
    return (React.createElement("a", { className: 'text-decoration-none text-body', href: file.url, target: '_blank', rel: 'noreferrer' },
        React.createElement("span", { className: 'py-2 px-3 bg-light rounded', key: "file_received_".concat(file.url, "_").concat(index) },
            !fromBot && React.createElement(React.Fragment, null,
                file.url.split('/').slice(-1)[0],
                " \u00A0"),
            React.createElement(Icon, { name: 'farDownload' }),
            file.name && React.createElement("span", { style: { marginLeft: '0.25rem' } }, file.name))));
}
var getUtteranceQuestion = function (utterancesJson, t) {
    return utterancesJson.map(function (utterance, i) {
        return !utterance.text || utterance.status === 'NoMatch' ? (React.createElement("span", { className: 'qa-question--not-recognized' }, t('details phrase not recognized'))) : (React.createElement("span", { key: "utterance-".concat(i) },
            utterance.text,
            " "));
    });
};
