import React, { useCallback, useMemo } from 'react';
import { Icon, useToggle } from '@just-ai/just-ui';
import { mapTemplateIdToIconName } from '../../../constants/faqTemplates';
import styles from './styles.module.scss';
import cn from 'classnames';
import FAQTemplateQuestionsModal from './FAQTemplateQuestionsModal';
import { useNLUModuleContext } from '../../../context';
var FAQTemplateCard = function (_a) {
    var className = _a.className, template = _a.template, selectFAQTemplate = _a.selectFAQTemplate, toggleFAQTemplate = _a.toggleFAQTemplate, createSkill = _a.createSkill, toggleFileImportModal = _a.toggleFileImportModal, disabled = _a.disabled;
    var _b = useToggle(), modalOpen = _b[0], openModal = _b[1], toggleModal = _b[3];
    var isAccess = useNLUModuleContext().isAccessFunction;
    var type = template.type, name = template.name, description = template.description, questions = template.sampleQuestions;
    var iconName = useMemo(function () { return (type && mapTemplateIdToIconName[type]) || ''; }, [type]);
    var writeDisabled = !isAccess('BOT_CONTENT_WRITE');
    var handleSubmit = function () {
        createSkill();
        toggleModal();
    };
    var closeModal = function () {
        toggleModal();
        toggleFAQTemplate(template);
    };
    var handleCardClick = useCallback(function (event) {
        if (writeDisabled)
            return;
        if (type === 'faq_from_user_file' || type === 'cdqa') {
            selectFAQTemplate(template);
            toggleFileImportModal();
            return;
        }
        event.stopPropagation();
        openModal();
        selectFAQTemplate(template);
    }, [writeDisabled, type, openModal, selectFAQTemplate, template, toggleFileImportModal]);
    return (React.createElement("div", { className: cn(styles['faq-template-card'], className && styles[className], (writeDisabled || disabled) && styles['faq-template-card--disabled']), onClick: handleCardClick, "data-test-id": "FAQ.FAQTemplateCard.".concat(name) },
        React.createElement("div", { className: styles['faq-template-card__icon'] },
            React.createElement(Icon, { name: iconName })),
        React.createElement("h4", { className: styles['faq-template-card__title'] }, name),
        type !== 'empty_template' && React.createElement("span", { className: styles['faq-template-card__description'] }, description),
        React.createElement(FAQTemplateQuestionsModal, { isOpen: modalOpen, toggle: closeModal, type: type, name: name, onSubmit: handleSubmit, questions: questions })));
};
export default React.memo(FAQTemplateCard);
