export const JGraphGuideTourLocalization = {
  'JGraphGuideTour:Step:1:Title': {
    eng: 'Welcome to J-Graph<br />🎉 🎉 🎉',
    ru: 'Добро пожаловать в J-Graph<br />🎉 🎉 🎉',
  },
  'JGraphGuideTour:Step:1': {
    eng: 'Hi! This is the J‑Graph visual editor. Would you like to have a quick tour around here? This will take you 7 minutes at most.',
    ru: 'Здравствуйте! Это графический редактор сценариев J‑Graph. Хотите узнать, как с ним работать? Это займет у вас не более 7 минут.',
  },
  'JGraphGuideTour:Step:2': {
    eng: 'The dialog script consists of states which are displayed on the canvas. Find the start state and click on its title.',
    ru: 'Сценарий диалога состоит из шагов, которые вы видите на холсте. Найдите стартовый шаг и нажмите на его заголовок.',
  },
  'JGraphGuideTour:Step:2:Image': {
    eng: '<img src="/img/jgraph/startStateEng.png" alt="" />',
    ru: '<img src="/img/jgraph/startStateRu.png" alt="" />',
  },
  'JGraphGuideTour:Step:3': {
    eng: 'This is the state editing menu. Usually, states contain some bot reactions and phrases that users may reply with.',
    ru: 'Это меню редактирования шага. Обычно шаг состоит из реакций бота и фраз, которые клиент может сказать боту в ответ.',
  },
  'JGraphGuideTour:Step:4': {
    eng: 'Possible bot reactions include text messages, images, buttons, and many other items.',
    ru: 'В качестве реакций бот может отправлять текст, изображения, кнопки и другие элементы.',
  },
  'JGraphGuideTour:Step:5': {
    eng: 'User phrases represent what people can say to the bot in response to its reactions.',
    ru: 'Фразы клиента — это то, что человек может сказать боту в ответ на его реакции.',
  },
  'JGraphGuideTour:Step:6': {
    eng: 'Add a new text block to the start state.',
    ru: 'Добавьте в стартовый шаг новый блок с текстом.',
  },
  'JGraphGuideTour:Step:7': {
    eng: 'Next, add an expected user phrase which will make the bot go to another state.',
    ru: 'Далее добавьте ожидаемую фразу клиента и переход в другой шаг по этой фразе.',
  },
  'JGraphGuideTour:Step:7-1': {
    eng: 'A user phrase usually contains the phrase itself and various examples which are formulated differently but have the same meaning.<br /><br />First, create a new user phrase or select an existing one.',
    ru: 'Фраза клиента обычно включает в себя саму фразу и различные примеры, которые сформулированы другими словами, но имеют тот же смысл.<br /><br />Сначала создайте новую фразу клиента или выберите существующую.',
  },
  'JGraphGuideTour:Step:7-2': {
    eng: 'Enter the user phrase and press <span class="$[1] $[2]" style="display: inline">Enter</span> to save it.',
    ru: 'Введите фразу клиента и нажмите <span class="$[1] $[2]" style="display: inline">Enter</span>, чтобы сохранить ее.',
  },
  'JGraphGuideTour:Step:7-3': {
    eng: 'You have created a new phrase. Good job! Now you can add alternative examples to the phrase, so the bot can understand it better.<br/><br/>And you can define the next state to be activated by this phrase.<br/><br/>Click on the phrase to set it up and fill it with examples.',
    ru: 'Вы создали фразу. Отлично! Теперь можно добавить в нее примеры других формулировок, чтобы бот мог лучше понимать фразу.<br/><br/>А также вы можете определить, в какой шаг переходить по этой фразе.<br/><br/>Нажмите на фразу, чтобы настроить ее.',
  },
  'JGraphGuideTour:Step:7-4': {
    eng: 'You can add some alternative examples to the phrase. The more examples it has, the better the bot will understand that the user wanted to express this meaning.',
    ru: 'Вы можете добавить несколько альтернативных примеров во фразу. Чем больше примеров, тем лучше бот поймет, что клиент хотел выразить именно этот смысл.',
  },
  'JGraphGuideTour:Step:7-5': {
    eng: 'Select which state should be triggered by this phrase.',
    ru: 'Выберите, в какой шаг бот должен перейти по этой фразе.',
  },
  'JGraphGuideTour:Step:8': {
    eng: 'Finally, you can test the way your bot works.',
    ru: 'Теперь вы можете протестировать, как работает ваш бот.',
  },
  'JGraphGuideTour:Step:8-aimy': {
    eng: '',
    ru: 'Теперь вы можете протестировать, как работает ваш бот. Нажмите кнопку “Тестировать” в верхнем правом углу экрана',
  },
  'JGraphGuideTour:Step:9': {
    eng: 'This is the test widget. When you launch it, the bot begins the script from the start state. Now enter the expected user phrase you’ve just added to the state.',
    ru: 'Это тестовый виджет. При его запуске бот сразу переходит на стартовый шаг. Введите ожидаемую фразу клиента, которую вы только что добавили в шаг.',
  },
  'JGraphGuideTour:Step:10': {
    eng: 'The state where the bot goes to is highlighted on the canvas.',
    ru: 'На холсте подсвечивается шаг, в который переходит бот.',
  },
  'JGraphGuideTour:Step:11': {
    eng: 'That’s it for now. Thank you and good luck developing bot projects!',
    ru: 'На этом пока все. Спасибо и успехов в создании ботов!',
  },
  'JGraphGuideTour:MainModal:Action:Next': {
    eng: 'Start the tutorial now',
    ru: 'Начать обучение сейчас',
  },
  'JGraphGuideTour:SkipForeverModal:Title': {
    eng: 'Do you want to take the tutorial another time?',
    ru: 'Вы хотите пройти обучение в другой раз?',
  },
  'JGraphGuideTour:SkipForeverModal:SubmitText': {
    eng: 'Yes',
    ru: 'Да',
  },
  'JGraphGuideTour:SkipForeverModal:CancelText': {
    eng: 'No, don’t offer again',
    ru: 'Нет, больше не предлагать',
  },
};
