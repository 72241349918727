export var getRandomString = function (length) {
    if (length === void 0) { length = 3; }
    return String(Math.random()).slice(2, 2 + length);
};
export var findDuplicates = function (arr) { return arr.filter(function (item, index) { return arr.indexOf(item) !== index; }); };
export var uniquify = function (arr) { return Array.from(new Set(arr)); };
export var downloadTextFileWithContent = function (fileContent, fileName) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
