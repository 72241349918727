import { useAppDispatch, useAppSelector } from '../../../storeHooks';
import { useHistory } from 'react-router-dom';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import queryString from 'query-string';
import { getCustomTags, getGraph, getJGraphVisuals } from '../../../reducers/JGraph.reducer/JGraphAsyncActions';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { updateUsingStageProps } from '../utils/connectionLayerUtils';
import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';
import { JGLS } from 'reducers/JGraph.reducer';
import { concatMap, debounceTime, ObservableInput, scan, shareReplay, Subject } from 'rxjs';
import { EditMenuBlock, JStateWithId } from '../../../reducers/JGraph.reducer/types';

import { FloatingConnectorMenu$ } from '../view/FloatingConnectorMenu';
import { Vector2d } from 'konva/lib/types';
import { getStageActionsFromEvent } from '../utils/stageUtils';

export const useJGraphBaseLoading = () => {
  const { loadingCustomTags, projectShortName, loadingVisuals } = useAppSelector(state => ({
    loadingCustomTags: state.JGraphReducer.loadingCustomTags,
    loadingVisuals: state.JGraphReducer.visuals.loading,
    projectShortName: state.CurrentProjectsReducer.currentProject,
  }));
  const dispatch = useAppDispatch();
  const { location } = useHistory();

  const urlParams = useMemo(() => queryString.parse(location.search), [location.search]);

  JGLS.setPSN(projectShortName);
  useEffect(() => {
    if (loadingCustomTags) {
      dispatch(getCustomTags({}));
    }
  }, [dispatch, loadingCustomTags]);
  useEffect(() => {
    if (loadingVisuals) {
      dispatch(getJGraphVisuals());
    }
  }, [dispatch, loadingVisuals]);

  useEffect(() => {
    if (!loadingCustomTags && !loadingVisuals) {
      dispatch(getGraph({}));
    }
  }, [dispatch, urlParams, loadingCustomTags, loadingVisuals]);
};

export const useStageSizes = (containerRef: HTMLDivElement | null) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const setSizes = useCallback(() => {
    timerRef.current && clearTimeout(timerRef.current);
    if (containerRef) {
      const width = containerRef?.clientWidth || 0;
      const height = containerRef?.clientHeight || 0;
      if (width === 0 && height === 0) {
        timerRef.current = setTimeout(() => {
          setSizes();
        }, 1000);
      } else {
        setSize({
          width: containerRef?.clientWidth || 0,
          height: containerRef?.clientHeight || 0,
        });
      }
    } else {
      timerRef.current = setTimeout(() => {
        setSizes();
      }, 1000);
    }
  }, [containerRef]);

  const widgetAppearEvent = useCallback(() => {
    setSizes();
  }, [setSizes]);

  useEffect(() => {
    setSizes();

    document.addEventListener('justwidget_mounted', widgetAppearEvent);
    document.addEventListener('justwidget_unmounted', widgetAppearEvent);

    return () => {
      document.removeEventListener('justwidget_mounted', widgetAppearEvent);
      document.removeEventListener('justwidget_unmounted', widgetAppearEvent);
    };
  }, [containerRef, setSizes, widgetAppearEvent]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setSize({ width, height });
      }, 0);
    });
    if (containerRef) {
      resizeObserver.observe(containerRef);
    }

    return () => {
      if (containerRef) {
        resizeObserver.unobserve(containerRef);
      }
    };
  }, [containerRef]);

  return size;
};

export const findParentScreenName = (
  target: Shape | Stage | Konva.Group | undefined
): [string, Konva.Group | undefined] => {
  if (target) {
    if (target.getType() !== 'Stage') {
      if (target.getAttr('isScreen')) {
        return [target.name(), target as unknown as Konva.Group];
      } else {
        return findParentScreenName(target.getParent());
      }
    }
    return [target.name(), target as unknown as Konva.Group];
  }
  return ['', undefined];
};
type NewConnectorType = {
  mouseUp: boolean;
  canStart: boolean;
  started: boolean;
  from: string;
  to: string;
  fromPosition: Vector2d;
  toPosition: Vector2d;
  GStage: Konva.Stage | null;
  transitionTo?: string;
};
export const initialNewConnectorState: NewConnectorType = {
  mouseUp: false,
  canStart: false,
  started: false,
  from: '',
  to: '',
  transitionTo: '',
  fromPosition: { x: 0, y: 0 },
  toPosition: { x: 0, y: 0 },
  GStage: null,
};
export const newConnectorSubject$ = new Subject<Partial<NewConnectorType>>();
export const newConnectorPipe$ = newConnectorSubject$.pipe(
  scan((prevValue, newValue) => {
    const newInnerValue = {
      ...prevValue,
      ...newValue,
    };
    const stage = newInnerValue.GStage;
    if (!stage) return newInnerValue;
    if (!newInnerValue.mouseUp) {
      if (newValue.from && newValue.fromPosition) {
        stage.on('mousemove', () => {
          const pointerPosition = stage.getPointerPosition();
          let [, toPosition] = updateUsingStageProps(stage, { x: 0, y: 0 }, pointerPosition || { x: 0, y: 0 });
          newConnectorSubject$.next({ toPosition: toPosition, GStage: stage });
        });
      }
    }
    if (newInnerValue.mouseUp || (!newInnerValue.started && !newInnerValue.canStart)) {
      stage.off('mousemove');
    }

    return newInnerValue;
  }, initialNewConnectorState),
  shareReplay(1)
);

export const useOnClickBlockDefault = (screen: JStateWithId, index?: number, path?: string) => {
  const onClickDefault = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      const stageActions = getStageActionsFromEvent(evt);
      evt.cancelBubble = true;
      FloatingConnectorMenu$.next({ connector: undefined });
      stageActions.setEditMenuBlock({
        screen: screen,
        jBlockIndex: index,
        path: path === '' ? undefined : path,
      });
    },
    [screen, index, path]
  );

  return { onClickDefault };
};

type LZAction = { type: string; action: () => ObservableInput<any> };
export const lazyActionsSubject = new Subject<LZAction>();
export const lazyActionsPipe$ = lazyActionsSubject.pipe(
  debounceTime(1000),
  concatMap(value => {
    return value.action();
  })
);

type TScreenContext = {
  screen: JStateWithId;
  editMenuBlock?: EditMenuBlock;
  isEditModeEnable?: boolean;
};
export const ScreenContext = createContext({} as TScreenContext);
export const useScreenContext = () => useContext(ScreenContext);
