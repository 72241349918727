export const buildRafScheduler = () => {
  let callbacks: Function[] = [];
  return (cb: () => void) => {
    callbacks.push(cb);
    if (callbacks.length === 1) {
      requestAnimationFrame(() => {
        callbacks.forEach(cb => cb());
        callbacks = [];
      });
    }
  };
};

export const rafAppScheduler = buildRafScheduler();
