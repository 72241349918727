import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import cn from 'classnames';
import { CailaIntentsHelper } from './CailaIntentsHelper';
import { TActivationParameters, TagNames } from '../../../../../utils/types';
import {
  deleteBlockInScreenAsync,
  resortBlocksInScreenAsync,
  saveEventAsync,
} from '../../../../../../../reducers/JGraph.reducer/JGraphAsyncActions';

import { isActivationToStateByTagParam, makeMapTagParametersFromBlock } from '../../../../../utils/blockLayerUtils';

import { TSortableItem } from '../../../types';
import { t } from 'localization';
import { JGraphCailaIntentItem } from './JGraphCailaIntentItem';
import { arrayMove } from 'react-sortable-hoc';
import { useRightSideMenuContext } from '../../../index';
import { SortableReactionsContainer } from '../../../../../components/SortableBlocks';
import { Modal } from '@just-ai/just-ui';
import { backToNewCailaIntentsCreation, JGraphCailaIntentChooseCreation } from './JGraphCailaIntentChooseCreation';
import { setEditMenuBlock } from '../../../../../../../reducers/JGraph.reducer';

export const JGraphCailaIntents: FC = () => {
  const { editMenuScreen, dispatch, setWasFromNewIntentsMenu } = useRightSideMenuContext();
  const [openDeleteIndex, setOpenDelete] = useState<number | undefined>(undefined);

  const noMatch = useMemo<TSortableItem>(() => {
    const noMatchIndex = editMenuScreen.blocks.findIndex(
      block => block.tagName === TagNames.event && block.tagValue === 'noMatch'
    );

    return {
      block: editMenuScreen.blocks[noMatchIndex],
      blockIndex: noMatchIndex,
      parentBlocks: [],
    };
  }, [editMenuScreen]);

  useEffect(() => {
    if (!noMatch.block) {
      const save = new Promise(resolve => {
        resolve(
          dispatch(
            saveEventAsync({
              value: 'noMatch',
              isGlobal: false,
              tagParameters: [
                { name: 'fromState', value: null, required: false },
                { name: 'onlyThisState', value: null, required: false },
                { name: 'toState', value: './', required: false },
              ] as TActivationParameters,
            })
          )
        );
      });
      save.then(() => {
        backToNewCailaIntentsCreation();
      });
    }
  }, [dispatch, editMenuScreen, noMatch]);

  const activationsToStateEvents = useMemo(() => {
    return editMenuScreen.blocks.reduce((currentList, block, index) => {
      if ([TagNames.intent, TagNames.intent_].includes(block.tagName)) {
        const tagParamsMap = makeMapTagParametersFromBlock(block);
        if (isActivationToStateByTagParam(tagParamsMap)) {
          currentList.push({
            block: block,
            blockIndex: index,
            parentBlocks: [],
            componentType: JGraphCailaIntentItem,
          });
        }
      }
      return currentList;
    }, [] as TSortableItem[]);
  }, [editMenuScreen]);

  const onSortActivationsEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = activationsToStateEvents[oldIndex]?.blockIndex;
        const newGlobalIndex = activationsToStateEvents[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(editMenuScreen.blocks, oldGlobalIndex, newGlobalIndex);
          Promise.resolve(dispatch(resortBlocksInScreenAsync({ newBlocks }))).then(() => {
            backToNewCailaIntentsCreation();
          });
        }
      }
    },
    [activationsToStateEvents, editMenuScreen.blocks, dispatch]
  );

  const setOpenDeleteHandler = useCallback((index: number | undefined) => {
    setOpenDelete(index);
  }, []);

  const deleteHandler = useCallback(async () => {
    setOpenDelete(undefined);
    await dispatch(deleteBlockInScreenAsync({ screenId: editMenuScreen.pathId, blockIndex: openDeleteIndex || 0 }));
    backToNewCailaIntentsCreation();
  }, [dispatch, editMenuScreen.pathId, openDeleteIndex]);

  return (
    <div className={cn(classes.JGraphCailaIntents, 'JGraphIntentsToState')}>
      <div className={cn(classes.collapseOffset)}>
        <CailaIntentsHelper />
      </div>
      <div className={classes.bodyOffset}>
        <SortableReactionsContainer
          setOpenDelete={setOpenDeleteHandler}
          sortableBlocks={activationsToStateEvents}
          useDragHandle
          axis='y'
          lockAxis='y'
          lockOffset={['0%', '100%']}
          onSortEnd={onSortActivationsEnd}
          lockToContainerEdges={true}
          helperClass='draggingHelper'
          isFromNewIntentsMenu={true}
          data-test-id='JGraphCailaIntents'
        />
        <JGraphCailaIntentChooseCreation expanded={activationsToStateEvents.length !== 0} />
      </div>
      <div className={classes.noMatchElement}>
        <p>{t('JGraphCailaIntents:noMatchHelper')}</p>
        <JGraphCailaIntentItem
          block={noMatch.block}
          blockIndex={noMatch.blockIndex}
          disableDelete={true}
          onClick={() => {
            setWasFromNewIntentsMenu(true);
            dispatch(
              setEditMenuBlock({
                screen: editMenuScreen,
                jBlockIndex: [TagNames.else, TagNames.elseif, TagNames.if].includes(noMatch.block.tagName)
                  ? undefined
                  : noMatch.blockIndex,
                path: undefined,
              })
            );
          }}
        />
      </div>

      <Modal
        isOpen={typeof openDeleteIndex === 'number'}
        title={t(`RightSideMenu:delete_block_title`)}
        buttonSubmitColor='danger'
        buttonSubmitText={t(`RightSideMenu:delete_block_submit`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={() => setOpenDelete(undefined)}
        onActionClick={deleteHandler}
      >
        <p>{t(`RightSideMenu:delete_block_text`)}</p>
      </Modal>
    </div>
  );
};
