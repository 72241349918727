import React, { useCallback } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Button, useToggle } from '@just-ai/just-ui';

import BottomLeftTriangleWrapper from '../../LabelingToolMenu/components/BottomLeftTriangleWrapper';
import ColorsDropdown from '../../LabelingToolMenu/components/ColorsDropdown';
import StageLabel from '../../LabelingToolMenu/components/StageLabel';

import { stickerColors, StickerColor } from '../colors';

import styles from './styles.module.scss';

const stickerColorsList = Object.values(stickerColors);

type StickerToolMenuProps = {
  selected: StickerColor;
  onChange: (color: StickerColor) => void;
};
const StickerToolMenu = ({ onChange, selected }: StickerToolMenuProps) => {
  const [colorPickerOpened, , closeColorPicker, toggleColorPicker] = useToggle(false);

  const onSelect = useCallback(
    (color: StickerColor) => {
      closeColorPicker();
      onChange(color);
    },
    [closeColorPicker, onChange]
  );

  return (
    <div className={styles.floatingMenu}>
      <Dropdown direction='up' isOpen={colorPickerOpened}>
        <DropdownToggle tag='div' outline withoutPadding compact disabled>
          <DropdownMenu className={styles.floatingMenu__dropdown}>
            <ColorsDropdown selected={selected} colors={stickerColorsList} onSelect={onSelect} />
          </DropdownMenu>
        </DropdownToggle>
        <BottomLeftTriangleWrapper>
          <Button
            data-test-id='JGraph.StickerToolMenu.OpenColorSelector'
            className={styles.floatingMenu__btn}
            onClick={toggleColorPicker}
            size='sm'
            flat
            color='secondary'
          >
            <StageLabel theme={selected} />
          </Button>
        </BottomLeftTriangleWrapper>
      </Dropdown>
    </div>
  );
};

export default React.memo(StickerToolMenu);
