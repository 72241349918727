var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { withRouter } from 'react-router-dom';
import { subMonths } from 'date-fns';
import { Spinner, Button, TranslationContextCustomLocalization, withTranslationContext, } from '@just-ai/just-ui';
import { ProjectStatsService } from '@just-ai/analytic-front/dist/Reporter/service/AnalyticsService';
import { FilterKey } from '@just-ai/analytic-front/dist/Reporter/api/client/api';
import { SkillType } from '@just-ai/api/dist/generated/Caila/';
import { FAQIntentService } from '../../services/FAQIntentService';
import FAQApiService from '../../services/FAQApiService';
import { generateFAQPathByTemplateName } from '../../nlu-related/utils/intents';
import FaqTemplatesTabs from './components/FaqTemplatesTabs';
import InputComponent from './components/TabsCollapsible/TabRenameInput';
import { FaqSkillViewWithTranslation } from './view/FaqSkillView';
import FaqHomeView from './view/FaqHomeView';
import { HOME_TAB_CODE, isKnowledgeBaseBeenVisitedLSKey } from './constants';
import { NLUModuleContext } from '../../context';
import { faqLocalization } from '../../localization/faq.loc';
import classes from './styles.module.scss';
var FAQ = /** @class */ (function (_super) {
    __extends(FAQ, _super);
    function FAQ() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.projectShortName = '';
        _this.state = {
            activeSkillName: '',
            activeTemplateId: '',
            skillViewKey: 0,
            loadingInPage: false,
            skills: [],
            needsTraining: false,
            faqActivated: false,
        };
        _this.hiddenUpdateIntentsData = function () { return _this.updateIntentsData(''); };
        _this.updateIntentsData = function (loadingType, activeTemplate) {
            if (loadingType === void 0) { loadingType = 'loadingInSidebar'; }
            return _this.promiseLoadingHelper(loadingType, _this.skillsApiService.listSkills().then(function (skills) {
                var _a, _b, _c;
                if (((_a = _this.context.currentProject) === null || _a === void 0 ? void 0 : _a.id) && _this.context.setCurrentProject) {
                    _this.context.setCurrentProject(__assign(__assign({}, _this.context.currentProject), { isCdqaCreated: skills.some(function (skill) { return skill.type === SkillType.Cdqa; }), isCdqaEnabled: (_b = skills.find(function (skill) { return skill.type === SkillType.Cdqa; })) === null || _b === void 0 ? void 0 : _b.enabled }));
                }
                if (skills.length > 0) {
                    localStorage.setItem("".concat(isKnowledgeBaseBeenVisitedLSKey, "-").concat(_this.projectShortName), 'true');
                }
                var isKnowledgeBaseNotBeenVisited = localStorage.getItem("".concat(isKnowledgeBaseBeenVisitedLSKey, "-").concat(_this.projectShortName));
                if (!isKnowledgeBaseNotBeenVisited) {
                    _this.context.wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true } });
                    return;
                }
                var skillsSorted = (skills || []).sort(function (a, b) { var _a, _b; return (((_a = b.created) === null || _a === void 0 ? void 0 : _a.timestamp) || 0) - (((_b = a.created) === null || _b === void 0 ? void 0 : _b.timestamp) || 0); });
                var activeSkillName = activeTemplate ||
                    _this.getInitialTemplate(skillsSorted.map(function (skill) { return skill.name; }), HOME_TAB_CODE);
                var activeTemplateId = activeSkillName ? ((_c = skills.find(function (skill) { return skill.name === activeSkillName; })) === null || _c === void 0 ? void 0 : _c.id) || '' : '';
                var newState = {
                    skills: skillsSorted,
                    activeSkillName: activeSkillName,
                    activeTemplateId: activeTemplateId,
                    needsTraining: true,
                };
                _this.setState(newState);
                return newState;
            }));
        };
        _this.getProjectFaqStatus = function () { return __awaiter(_this, void 0, void 0, function () {
            var hasFaqState, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.faqApiService.projectHasFaqState()];
                    case 1:
                        hasFaqState = _a.sent();
                        this.setState({ faqActivated: hasFaqState });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        if (error_1 instanceof Error) {
                            this.context.appLogger.error({
                                message: 'FAQ: Error fetching project tags',
                                exception: error_1,
                            });
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.trainNlu = function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, this.context.TrainingApi.trainNLU(this.context.accountId, this.context.projectShortName)];
        }); }); };
        _this.setTab = function (tagCode) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            activeSkillName: tagCode,
                        });
                        return [4 /*yield*/, this.updateIntentsData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleTabDelete = function (tabValue) { return __awaiter(_this, void 0, void 0, function () {
            var skillToDelete, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        skillToDelete = this.state.skills.find(function (skill) { return skill.name === tabValue; });
                        if (!skillToDelete)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.skillsApiService.deleteSkill(skillToDelete)];
                    case 2:
                        _a.sent();
                        if (this.state.activeTemplateId === skillToDelete.id) {
                            this.setState({
                                activeSkillName: '',
                                activeTemplateId: '',
                            }, function () {
                                _this.props.history.replace(_this.props.location.pathname);
                                _this.updateIntentsData();
                            });
                            return [2 /*return*/];
                        }
                        this.updateIntentsData();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        if (error_2 instanceof Error) {
                            this.context.appLogger.error({
                                message: 'FAQ: Error is FAQ deleting',
                                exception: error_2,
                            });
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleTabRename = function (oldValue, newValue, changeTab) { return __awaiter(_this, void 0, void 0, function () {
            var updatedSkills, updatedSkillIndex, _a, _b, error_3;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        updatedSkills = __spreadArray([], this.state.skills, true);
                        updatedSkillIndex = updatedSkills.findIndex(function (tab) { return tab.name === oldValue; });
                        if (updatedSkillIndex === -1)
                            return [2 /*return*/];
                        updatedSkills[updatedSkillIndex].name = newValue.trim();
                        updatedSkills[updatedSkillIndex].editComponent = undefined;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        if (!(oldValue !== newValue.trim())) return [3 /*break*/, 3];
                        _a = updatedSkills;
                        _b = updatedSkillIndex;
                        return [4 /*yield*/, this.skillsApiService.updateSkill(updatedSkills[updatedSkillIndex])];
                    case 2:
                        _a[_b] = _c.sent();
                        _c.label = 3;
                    case 3:
                        this.setState({ skills: updatedSkills }, function () {
                            if (changeTab)
                                _this.setTab(newValue);
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_3 = _c.sent();
                        if (error_3 instanceof Error) {
                            this.context.appLogger.error({
                                message: 'FAQ: Error is FAQ renaming',
                                exception: error_3,
                            });
                        }
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.handleTabRenameMode = function (tabValue) { return __awaiter(_this, void 0, void 0, function () {
            var updatedSkills, updatedSkillIndex;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedSkills = __spreadArray([], this.state.skills, true);
                        updatedSkillIndex = updatedSkills.findIndex(function (tab) { return tab.name === tabValue; });
                        if (!(updatedSkills[updatedSkillIndex].name !== this.state.activeSkillName)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setTab(tabValue)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        requestAnimationFrame(function () {
                            updatedSkills[updatedSkillIndex].editComponent = (React.createElement(InputComponent, { initialValue: updatedSkills[updatedSkillIndex].name, tabsNames: _this.state.skills.map(function (skill) { return skill.name; }), handleTabBlur: function (oldVal, newVal) { return _this.handleTabRename(oldVal, newVal, true); } }));
                            _this.setState({ skills: updatedSkills });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.switchFullSkill = function (skillName, value) { return __awaiter(_this, void 0, void 0, function () {
            var skillToUpdate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        skillToUpdate = this.state.skills.find(function (skill) { return skill.name === skillName; });
                        if (!skillToUpdate)
                            return [2 /*return*/, Promise.resolve()];
                        if (value === undefined) {
                            value = !skillToUpdate.enabled;
                        }
                        if (skillToUpdate.enabled === value)
                            return [2 /*return*/, Promise.resolve()];
                        return [4 /*yield*/, this.skillsApiService.updateSkill(__assign(__assign({}, skillToUpdate), { enabled: value }))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.hiddenUpdateIntentsData()];
                    case 2:
                        _a.sent();
                        this.setState({ skillViewKey: this.state.skillViewKey + 1 });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.activateFaqInScenario = function () { return __awaiter(_this, void 0, void 0, function () {
            var error_4;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.faqApiService.activateFaqInScenario()];
                    case 1:
                        _a.sent();
                        this.setState({ faqActivated: true }, function () {
                            _this.context.actions.addAlert({
                                showed: true,
                                type: 'info',
                                message: _this.props.t('FAQ:activationNotification:alertText'),
                                title: '',
                                time: Date.now(),
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        if (error_4 instanceof Error) {
                            this.context.appLogger.error({
                                message: 'FAQ: Error activating faq in project scenario',
                                exception: error_4,
                            });
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.goToAnalytic = function (skill) { return __awaiter(_this, void 0, void 0, function () {
            var _a, accountId, projectShortName, FAQApi, skillQuestions, filterSetId;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, FAQApi = _a.FAQApi;
                        return [4 /*yield*/, FAQApi.getFaqQuestionList(accountId, projectShortName, generateFAQPathByTemplateName(skill.name))];
                    case 1:
                        skillQuestions = (_b.sent()).data;
                        return [4 /*yield*/, this.projectStatsService.getFilterSetId({
                                filters: [
                                    {
                                        key: FilterKey.MESSAGETIME,
                                        type: 'DATE_TIME_RANGE',
                                        // @ts-ignore
                                        from: subMonths(new Date(), 1).toISOString(),
                                        to: new Date().toISOString(),
                                    },
                                    {
                                        key: FilterKey.INTENT,
                                        type: 'TREE',
                                        // @ts-ignore
                                        options: skillQuestions.map(function (question) { return question.intent.path; }),
                                    },
                                ],
                            })];
                    case 2:
                        filterSetId = _b.sent();
                        window.open("/".concat(projectShortName, "/statistic/dialogs?activeTab=messages&filterSetId=").concat(filterSetId));
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleSkillUpdate = function (skillId, newSkillData) { return __awaiter(_this, void 0, void 0, function () {
            var updatedSkill, updatedSkillIndex, updateResult, updatedSkills;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!newSkillData.file) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.skillsApiService.updateSkillFile(skillId, SkillType.Cdqa, newSkillData.file)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        if (!(newSkillData.lang || newSkillData.name)) return [3 /*break*/, 4];
                        updatedSkill = this.state.skills.find(function (skill) { return skill.type === SkillType.Cdqa; });
                        updatedSkillIndex = this.state.skills.findIndex(function (skill) { return skill.type === SkillType.Cdqa; });
                        if (!updatedSkill)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.skillsApiService.updateSkill({
                                id: skillId,
                                enabled: updatedSkill.enabled,
                                name: newSkillData.name || updatedSkill.name,
                                type: SkillType.Cdqa,
                                settings: __assign(__assign({}, updatedSkill.settings), { languageCode: newSkillData.lang || ((_a = updatedSkill.settings) === null || _a === void 0 ? void 0 : _a.languageCode) }),
                            })];
                    case 3:
                        updateResult = _b.sent();
                        updatedSkills = __spreadArray([], this.state.skills, true);
                        updatedSkills[updatedSkillIndex] = updateResult;
                        this.setState({ skills: updatedSkills });
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    FAQ.prototype.componentDidMount = function () {
        var _a, _b, _c, _d;
        var _e = this.context, accountId = _e.accountId, projectShortName = _e.projectShortName, IntentsApi = _e.IntentsApi, FAQApi = _e.FAQApi, skillsApiService = _e.skillsApiService, axiosInstance = _e.axiosInstance;
        this.projectShortName = projectShortName;
        this.faqApiService = new FAQApiService(accountId, projectShortName, IntentsApi, FAQApi, axiosInstance);
        this.projectStatsService = new ProjectStatsService(accountId, projectShortName);
        this.skillsApiService = skillsApiService;
        this.getProjectFaqStatus();
        var fromIntentPath = (_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.fromIntent;
        var templateName = (_b = this.props.location.state) === null || _b === void 0 ? void 0 : _b.templateName;
        var returnedFromTemplatePage = sessionStorage.getItem('FAQ_from_root') === 'true';
        var isKnowledgeBaseBeenVisited = localStorage.getItem("".concat(isKnowledgeBaseBeenVisitedLSKey, "-").concat(this.projectShortName));
        // если проектов нет, и человек просто хочет уйти с бз, то его возвращаем на 3 назад - /faq, /faq-template, /faq, -> /target
        // если на эту страницу попали по кнопке "назад" в браузере и это было со страницы с шаблонами, при этом никаких шаблонов не выбрано, возвращаем туда где юзер был до захода на /faq
        if (!isKnowledgeBaseBeenVisited &&
            (((_c = this.props.location.state) === null || _c === void 0 ? void 0 : _c.addSkill) || returnedFromTemplatePage) &&
            !fromIntentPath &&
            !templateName) {
            window.history.replaceState({}, document.title);
            this.props.history.go(((_d = this.props.location.state) === null || _d === void 0 ? void 0 : _d.addSkill) ? -3 : -1);
            return sessionStorage.clear();
        }
        window.history.replaceState({}, document.title);
        var activeTemplate = templateName || (fromIntentPath ? FAQIntentService.getTemplateTitleFromPath(fromIntentPath) : '');
        this.updateIntentsData('loadingInPage', activeTemplate);
    };
    FAQ.prototype.promiseLoadingHelper = function (type, promise) {
        return __awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = true, _a)));
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, promise];
                    case 2:
                        result = _a.sent();
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = false, _a)));
                        });
                        return [2 /*return*/, result];
                    case 3:
                        e_1 = _a.sent();
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = false, _a)));
                        });
                        return [2 /*return*/, Promise.reject(e_1)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FAQ.prototype.getInitialTemplate = function (existedTemplates, defaultValue) {
        var _a, _b, _c;
        if (defaultValue === void 0) { defaultValue = ''; }
        if (this.state.activeSkillName) {
            return this.state.activeSkillName;
        }
        if (existedTemplates.length === 0) {
            return defaultValue;
        }
        if ((_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.fromTab) {
            return (_b = this.props.location.state) === null || _b === void 0 ? void 0 : _b.fromTab;
        }
        if ((_c = this.props.location.state) === null || _c === void 0 ? void 0 : _c.templateTitle) {
            return this.props.location.state.templateTitle;
        }
        return defaultValue;
    };
    FAQ.prototype.render = function () {
        var writeDisabled = !this.context.isAccessFunction('BOT_CONTENT_WRITE');
        var isLoaded = !this.state.loadingInPage;
        if (this.state.loadingInPage) {
            return React.createElement(Spinner, null);
        }
        return (React.createElement("div", { className: classes.faqPage },
            isLoaded && !this.state.faqActivated && !this.context.isZfl && (React.createElement("div", { className: classes.faqPage__notification },
                this.props.t('FAQ:activationNotification'),
                React.createElement("a", { href: this.props.t('FAQ:activationNotification:url'), target: '_blank', rel: 'noreferrer noopener' },
                    React.createElement(Button, { outline: true, size: 'sm', color: 'secondary' }, this.props.t('FAQ:activationNotification:button:details'))),
                React.createElement(Button, { outline: true, size: 'sm', color: 'primary', onClick: this.activateFaqInScenario }, this.props.t('FAQ:activationNotification:button:connect')))),
            this.state.loadingInPage && React.createElement(Spinner, { backgroundColor: 'rgba(255, 255, 255, 0.2)' }),
            React.createElement(FaqTemplatesTabs, { homeTabCode: HOME_TAB_CODE, activeTab: this.state.activeSkillName, onChange: this.setTab, skills: this.state.skills.filter(function (skill) { return skill.type !== SkillType.Cdqa; }), needsTraining: this.state.needsTraining, trainNlu: this.trainNlu, onTabDelete: this.handleTabDelete, onTabRename: this.handleTabRenameMode, onTabToggle: this.switchFullSkill, writeDisabled: writeDisabled }),
            this.state.activeSkillName === HOME_TAB_CODE ? (React.createElement(FaqHomeView, { skills: this.state.skills, loading: this.state.loadingInPage, onSelect: this.setTab, onDelete: this.handleTabDelete, onRename: this.handleTabRename, onToggle: this.switchFullSkill, goToAnalytic: this.goToAnalytic, onUpdate: this.handleSkillUpdate })) : (React.createElement(FaqSkillViewWithTranslation, { key: this.state.skillViewKey, activeSkillName: this.state.activeSkillName, onToggleEnable: this.switchFullSkill, setSkillTab: this.setTab, history: this.props.history }))));
    };
    FAQ.contextType = NLUModuleContext;
    return FAQ;
}(React.PureComponent));
var ConnectedFaq = withRouter(withTranslationContext(FAQ));
var MemoizedFAQ = React.memo(function (_a) {
    var _b = _a.localizationObject, localizationObject = _b === void 0 ? {} : _b;
    return (React.createElement(TranslationContextCustomLocalization, { localizationObject: __assign(__assign({}, faqLocalization), localizationObject) },
        React.createElement(ConnectedFaq, null)));
});
MemoizedFAQ.displayName = 'React.memo(FAQTemplate)';
export default MemoizedFAQ;
