import { createContext, useCallback, useContext, useEffect } from 'react';
import { tap, concatMap, Subject, ObservableInput } from 'rxjs';
import { KonvaEventObject } from 'konva/lib/Node';

import { useAppDispatch } from 'storeHooks';
import { isSystemAccess } from 'isAccessFunction';

import { AppLogger } from 'services/AppLogger';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { saveBlockMovement, saveScreen } from 'reducers/JGraph.reducer';
import { JStateMovementsData } from 'modules/Editor/api/client';
import { isPromise } from 'utils/typeGuards';
import {
  createState,
  TStateToUpdate,
  updateStateInReduxAndApi,
  moveState,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { blockModalSubject$ } from '../view/BlockModal';
import { JGraphLicensed } from '../constants';
import { NeedToRedeployWidgetSubject$ } from '../view/NeedToRedeployWidget';
import { amplitudeInstance } from 'pipes/functions';

type TAction = { type: string; path: string; action: () => ObservableInput<any> };

export const mainSave$ = new Subject<TAction>();
export const actionsPipe$ = mainSave$.pipe(
  // debounce(value => (value.type === 'move' ? interval(500) : false)),
  tap({
    next: value => {
      if (value.type === 'create') {
        amplitudeInstance.logEvent('J-Graph State created');
      }
      if (isSystemAccess([JGraphLicensed]) && value.type !== 'move') {
        NeedToRedeployWidgetSubject$.next(true);
      }
      if (!isSystemAccess([JGraphLicensed])) {
        blockModalSubject$.next(true);
        return { action: () => Promise.resolve() };
      }
    },
  }),
  concatMap((value: TAction) => {
    const res = value.action();
    return isPromise(res)
      ? res.catch(error =>
          AppLogger.error({
            message: 'Error in mainSave$ -> concatMap.',
            exception: error,
          })
        )
      : res;
  })
);

export const useJGraphSavingPipe = () => {
  const dispatch = useAppDispatch();

  const saveMoveHandler = useCallback(
    (event: KonvaEventObject<DragEvent>, screen: JStateWithId) => {
      if (!isSystemAccess([JGraphLicensed])) {
        blockModalSubject$.next(true);
        return;
      }
      const moveJStateData: JStateMovementsData = {
        movementsDataWithFile: [
          {
            filename: screen.filename,
            statesMovementsData: [
              {
                targetState: screen.path,
                x: event.target.attrs.x,
                y: event.target.attrs.y,
              },
            ],
          },
        ],
      };
      const newPositions = {
        x: event.target.attrs.x,
        y: event.target.attrs.y,
      };

      dispatch(saveBlockMovement({ newPositions, blockStatePath: screen.path || '' }));
      mainSave$.next({ type: 'move', path: screen.path, action: () => dispatch(moveState(moveJStateData)) });
    },
    [dispatch]
  );

  const stateCreateHandler = useCallback(
    (stateToUpdate: JStateWithId) => {
      if (!isSystemAccess([JGraphLicensed])) {
        blockModalSubject$.next(true);
        return;
      }
      dispatch(saveScreen(stateToUpdate));
      // dispatch(addNewState({ event, blockStatePath: screen.path || '' }));
      mainSave$.next({ type: 'create', path: stateToUpdate.path, action: () => dispatch(createState(stateToUpdate)) });
    },
    [dispatch]
  );

  const stateUpdateHandler = useCallback(
    (stateToUpdate: TStateToUpdate) => {
      if (!isSystemAccess([JGraphLicensed])) {
        blockModalSubject$.next(true);
        return;
      }
      mainSave$.next({
        type: 'update',
        path: stateToUpdate.path,
        action: () => dispatch(updateStateInReduxAndApi(stateToUpdate)),
      });
      // dispatch(saveScreen(stateToUpdate));
      // mainSave$.next({ type: 'update', path: stateToUpdate.path, action: () => dispatch(updateState(stateToUpdate)) });
    },
    [dispatch]
  );

  useEffect(() => {
    let subscription = actionsPipe$.subscribe(res => {
      console.log(res);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    saveMove: saveMoveHandler,
    stateCreate: stateCreateHandler,
    stateUpdate: stateUpdateHandler,
  };
};

type SavingPipeContext = {
  saveMove: (event: KonvaEventObject<DragEvent>, screen: JStateWithId) => unknown;
  stateCreate: (stateToUpdate: JStateWithId) => unknown;
  stateUpdate: (stateToUpdate: TStateToUpdate) => unknown;
};
export const SavingPipeContext = createContext({} as SavingPipeContext);
export const useSavingPipe = () => useContext(SavingPipeContext);
