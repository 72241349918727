import React, { FC } from 'react';

import { withController } from './Controller';
import { WysiwygEditor } from 'modules/JGraph/components/Wysiwyg';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

const HtmlView: FC<CommonFieldProps> = ({ name, label, value, onChange, isRequired, description }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className='form-group form-group-fix overflow-hidden'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      <WysiwygEditor ref={ref} defaultText={value} onChange={onChange} />
    </div>
  );
};

export const HtmlField = withController(HtmlView);
