import React from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
export var EventClickName;
(function (EventClickName) {
    EventClickName["MouseUp"] = "mouseup";
    EventClickName["MouseDown"] = "mousedown";
})(EventClickName || (EventClickName = {}));
export class ClickAwayListener extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.handler = null;
        this.hasElementIntoRefs = (refs, element) => {
            if (!refs || !refs.length)
                return false;
            return refs.filter(Boolean).some((elem) => {
                return elem.contains(element);
            });
        };
        this.handleClickOut = (event) => {
            const { handleEnabled, handleClickOut, refs } = this.props;
            if (!handleEnabled)
                return;
            const domNode = ReactDOM.findDOMNode(this);
            if (!domNode || !domNode.contains(event.target)) {
                // @ts-ignore
                if (this.hasElementIntoRefs(refs, event.target))
                    return;
                handleClickOut();
            }
            event.stopPropagation();
        };
    }
    componentDidMount() {
        this.handler = this.props.delay ? debounce(this.handleClickOut, this.props.delay) : this.handleClickOut;
        document.addEventListener(this.props.clickEventName || EventClickName.MouseDown, this.handler);
    }
    componentWillUnmount() {
        if (!this.handler)
            return;
        document.removeEventListener(this.props.clickEventName || EventClickName.MouseDown, this.handler);
    }
    render() {
        return this.props.children;
    }
}
ClickAwayListener.defaultProps = {
    handleEnabled: true,
};
