import React, { Component } from 'react';
import classNames from "classnames";
import './Inputs.scss';
import { InputDateAndTime } from "../InputDateAndTime";
import { Icon } from "../Icon";
export class Inputs extends Component {
    constructor() {
        super(...arguments);
        this.state = { timeOptions: [] };
    }
    render() {
        const { columns = 1, range, setTime, onReset, locale, placeholderEnd, placeholderStart, type, minDate, maxDate, dark, rootClassName, onEscape, } = this.props;
        return React.createElement("div", { className: classNames("rdrInputDateMainContainer", "rdrInputDateMainContainerWithTime", rootClassName) },
            React.createElement("div", { style: { position: 'relative' } },
                React.createElement(InputDateAndTime, { value: range === null || range === void 0 ? void 0 : range.startDate, onChange: (value, date) => setTime('startDate', date), onEscape: onEscape, type: type, min: minDate, max: maxDate, placeholder: placeholderStart, dark: dark, locale: locale }),
                React.createElement(Icon, { className: "rdrInputCalendarIcon", name: "farCalendar", color: "secondary" }),
                (range === null || range === void 0 ? void 0 : range.startDate) && React.createElement("button", { tabIndex: -1, className: "rdrInputDateIconContainer", "data-test-id": "JustUI.DatePicker.StartClearInputButton", onClick: onReset },
                    React.createElement(Icon, { name: "faTimesCircle", size: "md", color: "secondary", onClick: onReset }))),
            columns === 2 && React.createElement(React.Fragment, null,
                React.createElement("div", null, "\u00A0\u2013\u00A0"),
                React.createElement("div", { style: { position: 'relative' } },
                    React.createElement(InputDateAndTime, { value: range === null || range === void 0 ? void 0 : range.endDate, type: type, min: minDate, max: maxDate, placeholder: placeholderEnd, dark: dark, onChange: (value, date) => setTime('endDate', date), onEscape: onEscape, locale: locale }),
                    React.createElement(Icon, { className: "rdrInputCalendarIcon", name: "farCalendar", size: "sm", color: "secondary" }),
                    (range === null || range === void 0 ? void 0 : range.endDate) && React.createElement("button", { className: "rdrInputDateIconContainer", tabIndex: -1, "data-test-id": "JustUI.DatePicker.EndClearInputButton", onClick: onReset },
                        React.createElement(Icon, { name: "faTimesCircle", size: "md", color: "secondary" })))));
    }
}
