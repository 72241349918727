import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Subject, Subscription } from 'rxjs';
import { Group } from 'react-konva';
import Konva from 'konva';
import { useBehaviorSubject } from '@just-ai/just-ui';

import { useScreenLabel } from 'modules/JGraph/view/LabelingToolMenu/hooks/useScreenLabel';
import { EditMenuBlock, FromStateTransitions, JStateWithId } from 'reducers/JGraph.reducer/types';

import { getAllStatePathIds, getAllStatePaths, getValidKonvaName } from '../../../reducers/JGraph.reducer/Graph';
import { getStageContextFromRef } from '../utils/stageUtils';
import { ConnectorStore } from '../contexts/types';
import {
  cacheGroup,
  clearCache,
  stateChangedSaveSize,
  stateRemoves,
  updateStatesStore,
} from '../utils/blockLayerUtils';
import ScreenLabelView from '../view/parts/ScreenLabelView';
import { useDraggingZIndex } from 'modules/JGraph/hooks/useDraggingZIndex';
import { FloatingConnectorMenu$ } from './FloatingConnectorMenu';
import { StatesGroup } from './StatesGroup';
import { CurrentScreenFromDebug } from './parts/CurrentScreenFromDebug';
import { ScreenHeader } from './parts/ScreenHeader';
import { ScreenBody } from './parts/ScreenBody';
import { AutosizeRect } from './parts/AutosizeRect';
import { EventsGroup } from './parts/EventsGroup';
import { GroupHighlighter } from './GroupHighlighter';
import { BookMarks } from './parts/BookMarks';
import RenderingBlockWrapper from './RenderingModal/RenderingBlockWrapper';
import { useBlockActiveStatus, useStateCollapse } from './StateScreen.hooks';
import { StatesGroupContext } from './StatesGroup/context';
import { GroupStateItemTransitionCircle } from './StatesGroup/GroupStateItemTransitionCircle';
import { StickerCreationMode$ } from './Sticker/hooks';

type StateScreenPropsType = {
  screen: JStateWithId;
  draggable: boolean;
  isEditModeEnable: boolean;
  saveBlockMovement: (event: Konva.KonvaEventObject<DragEvent>, block: JStateWithId) => unknown;
  recalculateConnections: (event: Konva.KonvaEventObject<DragEvent>) => unknown;
  setEditMenuBlock: (screen: EditMenuBlock | undefined) => unknown;
  fromStateTransitions: FromStateTransitions;
};

const defaultCornerRadius = [8, 8, 0, 0];
const collapsedCornerRadius = 8;
export const StateScreen: FC<StateScreenPropsType> = React.memo(
  ({
    screen,
    draggable,
    saveBlockMovement,
    recalculateConnections,
    setEditMenuBlock,
    fromStateTransitions,
    isEditModeEnable,
  }) => {
    const StateRef = useRef<Konva.Group | null>(null);
    const PrevBlock = useRef<string>('');
    const [canRender, setCanRender] = useState(() => (screen.hasOwnProperty('canRender') ? screen.canRender : true));
    const { screenLabel, hoverProps, onLabelClick } = useScreenLabel(screen, StateRef.current);
    const { dragProps } = useDraggingZIndex();
    const { jGraphContext } = getStageContextFromRef(StateRef);
    const [editMenuBlock, isActiveByConnectorHighLight] = useBlockActiveStatus(screen.pathId, StateRef.current);

    const { isCollapsed } = useStateCollapse(screen.pathId);

    useEffect(() => {
      if (!screen.canRender) return;
      setCanRender(screen.canRender);
    }, [screen.canRender]);

    useEffect(() => {
      return () => {
        stateRemoves(screen.path);
      };
    }, [screen.path]);

    useEffect(() => {
      clearCache(StateRef.current);

      requestAnimationFrame(() => {
        StateRef.current && cacheGroup(StateRef.current);
      });
    }, [isCollapsed]);

    useEffect(() => {
      if (StateRef.current && isCollapsed) {
        const { connectorsFromPipe$ } = getStageContextFromRef({
          current: StateRef.current,
        });
        const statePaths = getAllStatePaths(screen);
        let valueToPipe: { [key: string]: {} } = {};
        statePaths.forEach(statePath => {
          valueToPipe[getValidKonvaName(statePath)] = {
            toRef: StateRef.current,
          };
        });
        connectorsFromPipe$.next(valueToPipe);
      }
    }, [isCollapsed, screen]);

    useEffect(() => {
      canRender && StateRef.current && stateChangedSaveSize(StateRef.current);
      //screen is needed because of what it contains that we draw
    }, [canRender, screen, isCollapsed]);

    useEffect(() => {
      const { renderStorePipe$ } = getStageContextFromRef(StateRef);
      let sub: Subscription | undefined = undefined;
      sub = renderStorePipe$.subscribe(value => {
        if (!canRender && value === screen.pathId) {
          setCanRender(true);
          RenderingBlockWrapper.Percentage$.next({ type: 'tick' });
        }
      });
      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    }, [canRender, screen.pathId]);

    useEffect(() => {
      const { x, y, ...currentBlock } = screen;
      const screenPath = screen.path;
      let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;

      if (StateRef.current && StateRef.current?.getStage() && canRender) {
        const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
        connectorsFromPipeSave$ = connectorsFromPipe$;
        connectorsFromPipe$.next({
          [getValidKonvaName(screenPath)]: {
            toRef: StateRef.current,
          },
        });
      }
      const currentBlockString = JSON.stringify(currentBlock);
      if (!PrevBlock.current) {
        PrevBlock.current = currentBlockString;
      }
      if (PrevBlock.current && currentBlockString !== PrevBlock.current) {
        PrevBlock.current = currentBlockString;
        clearCache(StateRef.current);
      }

      !editMenuBlock &&
        //should cache on next frame
        requestAnimationFrame(() => {
          StateRef.current && cacheGroup(StateRef.current);
        });

      return () => {
        if (connectorsFromPipeSave$) {
          connectorsFromPipeSave$.next({
            [getValidKonvaName(screenPath)]: {
              toRef: undefined,
            },
          });
        }
      };
    }, [screen, canRender, editMenuBlock]);

    const onStateDragEnd = useCallback(
      event => {
        saveBlockMovement(event, screen);
        dragProps.onDragEnd(event);
      },
      [dragProps, saveBlockMovement, screen]
    );

    const stickerCreationModeEnabled = useBehaviorSubject(StickerCreationMode$).stickerModeOpened;

    const onBlockClickHandler = useCallback(
      (evt: KonvaEventObject<MouseEvent>) => {
        if (!stickerCreationModeEnabled) evt.cancelBubble = true;
        FloatingConnectorMenu$.next({ connector: undefined });
        setEditMenuBlock({ screen: screen, jBlockIndex: undefined, path: undefined });
      },
      [screen, setEditMenuBlock, stickerCreationModeEnabled]
    );

    const onDragMove = useCallback(
      (event: Konva.KonvaEventObject<DragEvent>) => {
        recalculateConnections(event);
        updateStatesStore(StateRef.current!);
        dragProps.onDragMove(event);
      },
      [dragProps, recalculateConnections]
    );

    const allSubStatesPathIds = useMemo(() => {
      return getAllStatePathIds(screen);
    }, [screen]);

    return (
      <>
        <Group
          draggable={draggable && !screen.isUnsaved}
          x={screen.x || 0}
          y={screen.y || 0}
          name={screen.pathId}
          nameValue={screen.value}
          path={screen.path}
          isScreen={true}
          type='screen'
          onDragStart={dragProps.onDragStart}
          onDragEnd={onStateDragEnd}
          onDragMove={onDragMove}
          onClick={onBlockClickHandler}
          ref={StateRef}
          recalculateConnections={recalculateConnections}
          substates={allSubStatesPathIds}
          isCollapsed={isCollapsed}
        >
          {canRender && (
            <>
              <Group
                name='ScreenHeaderAndBodyGroup'
                onMouseEnter={hoverProps.onMouseEnter}
                onMouseLeave={hoverProps.onMouseLeave}
                /*we cant use ...spread here because hoverProps is always new object & rerender screen*/
              >
                {screen.debugLastActive ? <CurrentScreenFromDebug extraOffset={screenLabel ? -24 : 0} /> : null}
                {screenLabel ? <ScreenLabelView screenLabel={screenLabel} onLabelClick={onLabelClick} /> : null}
                <BookMarks screen={screen} isCollapsed={isCollapsed} />
                {isCollapsed ? (
                  <Group x={0} y={10} visible={false}>
                    <StatesGroupContext.Provider
                      value={{
                        mainScreen: screen,
                      }}
                    >
                      <GroupStateItemTransitionCircle
                        screen={screen}
                        showConnectionsFromChildren={true}
                        substates={allSubStatesPathIds}
                      />
                    </StatesGroupContext.Provider>
                  </Group>
                ) : null}
                <AutosizeRect
                  active={
                    (editMenuBlock?.path === undefined &&
                      editMenuBlock?.jBlockIndex === undefined &&
                      screen.pathId === editMenuBlock?.screen.pathId) ||
                    isActiveByConnectorHighLight
                  }
                  debugActive={screen.debugActive}
                  width={280}
                  fill='#F4F5F5'
                  stroke='#E0E1E3'
                  cornerRadius={isCollapsed ? collapsedCornerRadius : defaultCornerRadius}
                  bottomPadding={8}
                  name='MainParentAutosizeRect'
                >
                  <ScreenHeader statePath={screen.path} screen={screen} />
                  {!isCollapsed ? (
                    <ScreenBody
                      screen={screen}
                      editMenuBlock={editMenuBlock}
                      onBlockClickHandler={onBlockClickHandler}
                      isJGraphLite={jGraphContext?.isJGraphLite}
                      isEditModeEnable={isEditModeEnable}
                    />
                  ) : null}
                </AutosizeRect>
              </Group>
              {!isCollapsed ? (
                <>
                  <EventsGroup
                    screen={screen}
                    editMenuBlock={editMenuBlock}
                    key={`EventsGroup${(fromStateTransitions[screen.path] || []).length}`}
                    fromStateTransition={fromStateTransitions[screen.path]}
                    onBlockClickHandler={onBlockClickHandler}
                    isEditModeEnable={isEditModeEnable}
                    isActiveByConnectorHighLight={isActiveByConnectorHighLight}
                  />
                  {screen.states && screen.states.length > 0 && (
                    <StatesGroup
                      key={`StatesGroup${screen.states.length}`}
                      parentGroup={StateRef.current}
                      screen={screen}
                      editMenuBlock={editMenuBlock}
                      setEditMenuBlock={setEditMenuBlock}
                      isEditModeEnable={isEditModeEnable}
                      isActiveByConnectorHighLight={isActiveByConnectorHighLight}
                    />
                  )}
                </>
              ) : null}
            </>
          )}
        </Group>
        <GroupHighlighter statePath={screen.path} />
      </>
    );
  }
);
StateScreen.displayName = 'StateScreen';
