export const USER_ADD = 'USER_ADD';
export const USER_ADD_FULFILLED = 'USER_ADD_FULFILLED';
export const USER_ADD_PENDING = 'USER_ADD_PENDING';
export const USER_ADD_REJECTED = 'USER_ADD_REJECTED';
export const USER_EDIT = 'USER_EDIT';
export const USERS_LOAD_FULFILLED = 'USERS_LOAD_FULFILLED';
export const USERS_LOAD_REJECTED = 'USERS_LOAD_REJECTED';
export const ROLES_LOAD_FULFILLED = 'ROLES_LOAD_FULFILLED';
export const ROLES_LOAD_REJECTED = 'ROLES_LOAD_REJECTED';
export const PAGINATE_REJECTED = 'PAGINATE_USERS_REJECTED';
export const DROP_USER_EDIT = 'DROP_USER_EDIT';
export const EDIT_USER_SAVED_FULFILLED = 'EDIT_USER_SAVED_FULFILLED';
export const EDIT_USER_SAVED_PENDING = 'EDIT_USER_SAVED_PENDING';
export const EDIT_USER_SAVED_REJECTED = 'EDIT_USER_SAVED_REJECTED';

export const PAMD_LOAD = 'PAMD_LOAD';
export const PAMD_LOAD_FULFILLED = 'PAMD_LOAD_FULFILLED';
export const PAMD_LOAD_PENDING = 'PAMD_LOAD_PENDING';
export const PAMD_LOAD_REJECTED = 'PAMD_LOAD_REJECTED';

export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';
export const EDIT_USER_PASSWORD_FULFILLED = 'EDIT_USER_PASSWORD_FULFILLED';
export const EDIT_USER_PASSWORD_PENDING = 'EDIT_USER_PASSWORD_PENDING';
export const EDIT_USER_PASSWORD_REJECTED = 'EDIT_USER_PASSWORD_REJECTED';

export const EDIT_USER_ROLES = 'EDIT_USER_ROLES';
export const ADD_NEW_USER_ROLES = 'ADD_NEW_USER_ROLES';
export const SEARCH_USERS_FULFILLED = 'SEARCH_USERS_FULFILLED';
export const DROP_SEARCH_FILTER_FULFILLED = 'DROP_SEARCH_FILTER_FULFILLED';

export const CLEAN_ERROR = 'CLEAN_ERROR';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_FULFILLED = 'USER_DELETE_FULFILLED';
export const USER_DELETE_PENDING = 'USER_DELETE_PENDING';
export const USER_DELETE_REJECTED = 'USER_DELETE_REJECTED';

export const GET_CAPTCHA_AVAILABLE_ATTEMPTS = 'GET_CAPTCHA_AVAILABLE_ATTEMPTS';
export const GET_CAPTCHA_AVAILABLE_ATTEMPTS_PENDING = 'GET_CAPTCHA_AVAILABLE_ATTEMPTS_PENDING';
export const GET_CAPTCHA_AVAILABLE_ATTEMPTS_FULFILLED = 'GET_CAPTCHA_AVAILABLE_ATTEMPTS_FULFILLED';
export const GET_CAPTCHA_AVAILABLE_ATTEMPTS_REJECTED = 'GET_CAPTCHA_AVAILABLE_ATTEMPTS_REJECTED';
