import React, { useState, useEffect, } from "react";
import { Collapse } from "reactstrap";
import { Icon } from "..";
import classNames from "classnames";
import "./style.scss";
import { Theme } from "../Common";
const headerStyle = {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
};
const HeaderWrapper = ({ isOpen, toggleOpen, withWrapper, Header, HeaderContent, style = {}, "data-test-id": dataTestId, arrowPosition, theme, }) => withWrapper ? (React.createElement("div", { className: classNames("justui_spoiler-header-wrap", {
        "dark": theme === Theme["dark"],
    }), onClick: toggleOpen, "data-test-id": dataTestId },
    React.createElement("div", { style: Object.assign(Object.assign({}, headerStyle), style), className: classNames("justui_spoiler-header", {
            "arrow-right": arrowPosition === "right",
        }), "aria-expanded": isOpen },
        React.createElement("span", { className: "spoiler-arrow" },
            React.createElement(Icon, { name: "faAngleDown", size: "md", className: classNames({ "spoiler-open": isOpen }) })),
        React.createElement(Header, { isOpen: isOpen, toggleOpen: toggleOpen })),
    HeaderContent ? (React.createElement("div", { className: "justui_spoiler-header-content" },
        React.createElement(HeaderContent, null))) : null)) : (React.createElement(Header, { isOpen: isOpen, toggleOpen: toggleOpen }));
export const Spoiler = ({ children, withWrapper = false, wrapperStyle = {}, Header, HeaderContent, initiallyOpened = false, "data-test-id": dataTestId, fullWidthCollapse = false, arrowPosition, openProp, toggleProp, theme = Theme["light"], }) => {
    const [isOpen, setOpen] = useState(initiallyOpened);
    useEffect(() => {
        setOpen(initiallyOpened);
    }, [initiallyOpened]);
    const toggleOpen = () => setOpen(!isOpen);
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderWrapper, { arrowPosition: arrowPosition, withWrapper: withWrapper, Header: Header, HeaderContent: HeaderContent, isOpen: openProp || isOpen, toggleOpen: toggleProp || toggleOpen, style: wrapperStyle, "data-test-id": dataTestId, theme: theme }),
        React.createElement(Collapse, { isOpen: openProp || isOpen, style: { width: fullWidthCollapse ? "100%" : "unset" } },
            React.createElement("div", { className: classNames("justui_spoiler-collapse", {
                    "dark": theme === Theme["dark"],
                }) }, children))));
};
