import React, { useCallback, useEffect, useState, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import './AlertNotification.scss';
import { Modal } from '../index';
import { translate } from './localization';
import { AlertNotificationItem } from './AlertNotificationItem';
const MAX_SHOWED_ALERTS = 3;
export function AlertNotification({ alerts, dismissAllAlerts, dark, maxShowedAlerts = MAX_SHOWED_ALERTS, locale = 'ru', type = 'alerts', }) {
    const container = React.createRef();
    const refInput = useRef(null);
    const [alertList, setAlertList] = useState(alerts);
    const [alertsToDisplay, setAlertsToDisplay] = useState(alerts);
    const [stopped, setStopped] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [ModalBodyComponent, setModalBodyComponent] = useState(undefined);
    const [modalContent, setModalContent] = useState({ title: '', message: '' });
    useLayoutEffect(() => {
        window.addEventListener('blur', stopOnHover(true));
        window.addEventListener('focus', stopOnHover(false));
    }, []);
    useEffect(() => {
        setAlertList(alerts);
    }, [alerts]);
    useEffect(() => {
        const showedAlertList = alertList.filter(alert => alert.showed);
        const alertsToDisplayList = showedAlertList.length > maxShowedAlerts
            ? showedAlertList.slice(showedAlertList.length - maxShowedAlerts)
            : [...showedAlertList];
        setAlertsToDisplay(alertsToDisplayList);
    }, [alertList, maxShowedAlerts]);
    useEffect(() => {
        if (dismissAllAlerts &&
            alertsToDisplay.length === 0 &&
            alerts.length !== 0 &&
            alerts.findIndex(alert => alert.showed) === -1) {
            dismissAllAlerts();
        }
    }, [alerts, alertsToDisplay, dismissAllAlerts]);
    const hideMessage = useCallback((message) => {
        const mesAlert = [...alertList];
        const showedIndex = mesAlert.findIndex((mes) => {
            return mes.time === message;
        });
        if (showedIndex !== -1)
            mesAlert[showedIndex]['showed'] = false;
        setAlertList(mesAlert);
    }, [alertList]);
    const stopOnHover = (flag) => () => {
        setStopped(flag);
    };
    const toggleModal = useCallback((time = '') => {
        const selectedAlert = alertsToDisplay.find(alert => alert.time === time);
        if (selectedAlert) {
            setModalContent({ title: selectedAlert.title || '', message: selectedAlert.message.toString() });
            setModalBodyComponent(selectedAlert.modalBodyComponent ? () => selectedAlert.modalBodyComponent : undefined);
        }
        setModalOpen(Boolean(time));
        if (time !== '') {
            hideMessage(time);
            setStopped(true);
        }
        else {
            setStopped(false);
        }
    }, [alertsToDisplay, hideMessage]);
    const copyLog = (message) => {
        let supported = document.queryCommandSupported('copy');
        if (refInput && refInput.current && supported) {
            refInput.current.value = message;
            refInput.current.select();
            refInput.current.focus();
            window.document.execCommand('copy');
        }
    };
    const onClickCopyIcon = useCallback(() => {
        copyLog(modalContent.message);
        toggleModal('');
    }, [modalContent, toggleModal]);
    return (React.createElement("div", { className: classNames('notification-container', {
            hidden: alertsToDisplay.length === 0 && !modalOpen,
            'toasts-container': type === 'toasts',
        }), onMouseEnter: stopOnHover(true), onMouseLeave: stopOnHover(false), ref: container },
        type === 'toasts' ? (React.createElement("div", null, alertsToDisplay.map((item, i) => (React.createElement(AlertNotificationItem, Object.assign({ key: `globalAlert_${item.time}`, hideMessage: hideMessage, stopped: stopped, toggleModal: toggleModal, copyLog: copyLog, alertsToDisplay: alertsToDisplay, dark: dark }, item, { locale: locale, alertType: type })))))) : (React.createElement(React.Fragment, null, alertsToDisplay.map((item, i) => (React.createElement(AlertNotificationItem, Object.assign({ key: `globalAlert_${item.time}`, hideMessage: hideMessage, stopped: stopped, toggleModal: toggleModal, copyLog: copyLog, alertsToDisplay: alertsToDisplay, dark: dark }, item, { locale: locale, alertType: type })))))),
        modalOpen && (React.createElement(Modal, { size: 'lg', isOpen: true, title: modalContent.title ? modalContent.title : ' ', buttonCancelOutline: false, buttonCancelColor: 'primary', onCancelClick: () => toggleModal(''), buttonCancelText: translate('Close', locale), className: 'notification-modal', disableActionButtonAutoFocus: true },
            React.createElement("div", { "data-test-id": 'AlertNotification.Modal.message', className: 'notification-modal-text' }, ModalBodyComponent === undefined ? (modalContent.message) : (React.createElement(ModalBodyComponent, { message: modalContent.message, copy: onClickCopyIcon }))))),
        React.createElement("textarea", { ref: refInput, readOnly: true, className: 'hiddenInput' })));
}
