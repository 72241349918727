import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { ConnectorProps } from './index';
import Konva from 'konva';
import { getStageContextFromRef } from '../../utils/stageUtils';
import { allBlocksSetted, applyIslandConnectorOffsets } from '../../utils/connectionLayerUtils';

import { getIsScreenFromRef } from '../../utils/blockLayerUtils';
import { IslandConnector } from './IslandConnector';
import { rafAppScheduler } from '../../../../utils/sheduler/buildRafScheduler';

export const IslandConnectors: FC<ConnectorProps> = memo(({ connector }) => {
  const { from, to, deferred, fromNode, fromNodeOriginalPath, toNodeOriginalPath } = connector;
  const LabelFromRef = useRef<Konva.Label | null>(null);
  const LabelToRef = useRef<Konva.Label | null>(null);

  const [positions, setPositions] = useState({
    from: {
      x: -10000,
      y: -10000,
    },
    to: {
      x: -10000,
      y: -10000,
    },
  });

  const fromNodeRef = useRef<Konva.Node | null | undefined>(null);
  const toNodeRef = useRef<Konva.Node | null | undefined>(null);
  const isToNodeScreen = useRef(true);
  const isFromScreen = useRef(true);

  const setPositionsHandler = useCallback(() => {
    const { GStage } = getStageContextFromRef(LabelFromRef);
    if (!GStage) return;
    isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
    isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);

    let fromPosition = fromNodeRef.current?.getAbsolutePosition(GStage);
    let toPosition = toNodeRef.current?.getAbsolutePosition(GStage);
    if (!fromPosition || !toPosition) return;
    const { fromPosition: newFromPos, toPosition: newToPos } = applyIslandConnectorOffsets(fromPosition, toPosition, {
      isToNodeScreen: isToNodeScreen.current,
    });
    setPositions({ from: newFromPos, to: newToPos });
  }, []);

  useEffect(() => {
    if (!LabelFromRef.current) return;
    const { connectorsFromStore$, GStage } = getStageContextFromRef(LabelFromRef);

    const sub = connectorsFromStore$.subscribe(store => {
      if (allBlocksSetted(store, from, to, fromNode) && GStage) {
        fromNodeRef.current = store[from].fromRef || store[fromNode].fromRefFallBack;
        toNodeRef.current = store[to!].toRef;
        isToNodeScreen.current = !!toNodeRef.current?.attrs.isScreen;
        isFromScreen.current = getIsScreenFromRef(fromNodeRef.current);
        //should calculate after stage redraw
        setTimeout(() => {
          rafAppScheduler(() => {
            setPositionsHandler();
          });
        }, 0);
      }
    });
    return () => sub.unsubscribe();
  }, [deferred, from, fromNode, setPositionsHandler, to]);

  if (connector.to === '') return null;

  return (
    <>
      <IslandConnector
        isOutgoing={true}
        fromCircleNode={fromNodeRef.current}
        isFromScreen={isFromScreen.current}
        isToNodeScreen={false}
        positionFrom={positions.from}
        positionTo={positions.to}
        ref={LabelFromRef}
        text={toNodeOriginalPath}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
      />
      <IslandConnector
        isOutgoing={false}
        toScreenNode={toNodeRef.current}
        isFromScreen={false}
        isToNodeScreen={isToNodeScreen.current}
        positionFrom={positions.from}
        positionTo={positions.to}
        ref={LabelToRef}
        text={fromNodeOriginalPath}
        setPositionsHandler={setPositionsHandler}
        connector={connector}
      />
    </>
  );
});

IslandConnectors.displayName = 'IslandConnectors';
