import React, { useCallback, useMemo, useState } from 'react';
import { JustSelect, Label, Modal, Spinner } from '@just-ai/just-ui';

import { t } from 'localization';

import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { getAllStates } from 'reducers/JGraph.reducer/Graph';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { getParentPaths } from '../../../../../../reducers/JGraph.reducer/utils';
import { useAppDispatch } from '../../../../../../storeHooks';
import { moveStateInGroup } from '../../../../../../reducers/JGraph.reducer/JGraphAsyncActions';

interface RenameStateModalInterface {
  isOpen: boolean;
  screen: JStateWithId;
  screens: JStateWithId[];
  onClose: () => void;
}
const ChangeGroupModal = function ({ isOpen, onClose, screen, screens }: RenameStateModalInterface) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();

  const allStates = useMemo(() => {
    let allStates = getAllStates(screens).map(statePath => ({
      label: hideRootSlashInPath(statePath),
      value: statePath,
    }));
    allStates = allStates.filter(stateOption => stateOption.value !== screen.path);
    allStates.splice(0, 0, { label: t('ChangeGroupModal:Main_script_level'), value: '/' });
    return allStates;
  }, [screen.path, screens]);

  const parentStatePath = useMemo(() => {
    const { parentStatePath } = getParentPaths(screen.path, screen.theme);
    return parentStatePath;
  }, [screen.path, screen.theme]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    await dispatch(moveStateInGroup({ what: screen.path, where: value }));
    setLoading(false);
    onClose();
  }, [dispatch, onClose, screen.path, value]);

  const onChange = useCallback((value: (React.ReactText | null)[] | null) => {
    if (value && value[0]) {
      setValue(String(value[0]));
    }
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Modal
        isOpen={isOpen}
        title={t(`ChangeGroupModal:Modal:Title`)}
        buttonSubmitColor='primary'
        buttonSubmitText={t(`ChangeGroupModal:Modal:SubmitTitle`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        buttonSubmitDisabled={value === '' || value === parentStatePath || loading}
        buttonCancelDisabled={loading}
        onCancelClick={onClose}
        onActionClick={onSubmit}
        disableActionButtonAutoFocus
      >
        {loading && <Spinner size='sm' />}
        <Label>{t('ChangeGroupModal:Modal:Label')}</Label>
        <JustSelect
          fullWidth
          position='fixed'
          options={allStates}
          onChange={onChange}
          value={value || parentStatePath}
        />
      </Modal>
    </>
  );
};

export default React.memo(ChangeGroupModal);
