import React, { CSSProperties, useRef, useEffect, useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';
import cn from 'classnames';
import { useBehaviorSubject, useToggle } from '@just-ai/just-ui';
import { clamp } from 'lodash';

import { composeSubComponents } from 'utils/helpFunctions';

import styles from './styles.module.scss';

type TextEditLayerUpdateStatePayload = {
  content: string;
  styles: CSSProperties & Required<Pick<CSSProperties, 'fontSize' | 'height'>>;
};
type TextEditLayerOnChangePayload = {
  type: 'onChange';
  content: string;
  styles: { fontSize: number };
};
type TextEditLayerOnSubmitPayload = {
  type: 'onSubmit';
};
type TextEditLayerSenderPayload = TextEditLayerOnChangePayload | TextEditLayerOnSubmitPayload;

const TextEditLayerReceiverSubject$ = new BehaviorSubject<TextEditLayerUpdateStatePayload | undefined>(undefined);
const TextEditLayerSenderSubject$ = new BehaviorSubject<TextEditLayerSenderPayload | undefined>(undefined);

function createCursorPositionRestorer() {
  const selection = window.getSelection();
  const range = !selection || selection.type === 'None' ? undefined : selection?.getRangeAt(0).cloneRange();
  const startOffset = range?.startOffset ?? 0;
  return (newPosition?: number) => {
    if (!range) return;
    const text = range.startContainer.textContent;
    if (!text) return;

    const newRange = document.createRange();
    const sel = window.getSelection();
    if (!sel || sel.type === 'None') return;

    const lastPosition = text.length;

    if (!newPosition) newPosition = startOffset > lastPosition ? lastPosition : startOffset;

    const node =
      range.startContainer.childNodes.length === 0
        ? range.startContainer
        : range.startContainer.childNodes.length === 1
        ? range.startContainer.childNodes[0]
        : null;
    if (!node) {
      console.error('Cannot find correct text node for restoring cursor position', range.startContainer);
      return;
    }
    newRange.setStart(node, newPosition);
    newRange.collapse(false);

    sel.removeAllRanges();
    sel.addRange(newRange);
  };
}

function placeCaretAtEnd(el: HTMLElement) {
  el.focus();
  if (typeof window.getSelection !== undefined) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection()!;
    sel.removeAllRanges();
    sel.addRange(range);
    return;
  }
}

function adjustTextLength(originalText: string) {
  let deltaSize = originalText.length;
  let currentTextSize = originalText.length;
  let newText = originalText;
  return (direction: 'up' | 'down') => {
    deltaSize = clamp(Math.round(deltaSize / 2), 1, originalText.length);
    newText = originalText.substring(0, direction === 'up' ? currentTextSize - deltaSize : currentTextSize + deltaSize);
    currentTextSize = newText.length;
    return newText;
  };
}

function contentIsOverflowed(el: HTMLElement) {
  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
  const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;
  return isOverflowing;
}

const fontSizeSteps = [
  { fontSize: 60 },
  { fontSize: 48 },
  { fontSize: 34 },
  { fontSize: 40 },
  { fontSize: 30 },
  { fontSize: 26 },
  { fontSize: 24 },
  { fontSize: 22 },
  { fontSize: 20 },
  { fontSize: 18 },
  { fontSize: 16 },
  { fontSize: 14 },
  { fontSize: 12 },
  { fontSize: 10 },
  { fontSize: 8 },
  { fontSize: 5 },
];

const TextEditLayer = () => {
  const textEditElement = useBehaviorSubject(TextEditLayerReceiverSubject$);
  const contentEditableNode = useRef<HTMLDivElement>(null);
  const layerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textStyles = useRef<{ fontSize: number } | null>(null);
  const [warningOpened, showWarning, closeWarning] = useToggle(false);
  const currentTooltipMounted = useRef(false);

  const adjustText = useCallback(() => {
    if (!contentEditableNode.current || !wrapperRef.current) return;

    const firstFontSizeStep = fontSizeSteps[0];
    wrapperRef.current.style.fontSize = firstFontSizeStep.fontSize + 'px';

    let warningAlreadyOpened = false;

    while (contentIsOverflowed(contentEditableNode.current)) {
      const fontSize = parseInt(wrapperRef.current.style.fontSize);
      const stepIndex = fontSizeSteps.findIndex(x => x.fontSize === fontSize);
      const nextFontSize = fontSizeSteps[stepIndex + 1];
      if (!nextFontSize) {
        if (!warningAlreadyOpened) {
          showWarning();
          setTimeout(() => closeWarning(), 1000);
          warningAlreadyOpened = true;
        }
        break;
      }
      wrapperRef.current.style.fontSize = nextFontSize.fontSize + 'px';
    }

    let prevText = contentEditableNode.current.textContent;
    if (!prevText) return;

    const restore = createCursorPositionRestorer();
    if (contentIsOverflowed(contentEditableNode.current)) {
      contentEditableNode.current.textContent = prevText.substring(0, prevText.length - 1);
      prevText = contentEditableNode.current.textContent;
    }
    if (contentIsOverflowed(contentEditableNode.current)) {
      const adjustText = adjustTextLength(contentEditableNode.current.innerText);

      contentEditableNode.current.textContent = adjustText('up');

      while (Math.abs(prevText.length - contentEditableNode.current.innerText.length) > 1) {
        prevText = contentEditableNode.current.textContent;
        contentEditableNode.current.textContent = adjustText(
          contentIsOverflowed(contentEditableNode.current) ? 'up' : 'down'
        );
      }
    }
    restore();

    textStyles.current = {
      fontSize: parseInt(wrapperRef.current.style.fontSize),
    };
  }, [closeWarning, showWarning]);

  useEffect(() => {
    if (!textEditElement) {
      currentTooltipMounted.current = false;
      return;
    }
    if (!contentEditableNode.current || currentTooltipMounted.current) return;
    currentTooltipMounted.current = true;

    adjustText();
    TextEditLayerSenderSubject$.next({
      type: 'onChange',
      content: contentEditableNode.current.innerText,
      styles: textStyles.current!,
    });
    requestAnimationFrame(() => {
      if (!contentEditableNode.current) return;
      placeCaretAtEnd(contentEditableNode.current);
    });
  }, [adjustText, textEditElement]);

  const onInput = useCallback(() => {
    adjustText();

    TextEditLayerSenderSubject$.next({
      type: 'onChange',
      content: contentEditableNode.current!.innerText,
      styles: textStyles.current!,
    });
  }, [adjustText]);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      TextEditLayerSenderSubject$.next({ type: 'onSubmit' });
    }
  }, []);

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      // @ts-ignore
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text');

      const cursorPositionRestore = createCursorPositionRestorer();
      const range = window.getSelection()?.getRangeAt(0);
      if (!contentEditableNode.current || !range) return;

      let text = contentEditableNode.current.innerText;
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;
      text = text.substring(0, startOffset) + pastedData + text.substring(endOffset, text.length);

      contentEditableNode.current.textContent = text;

      const newPosition = startOffset + pastedData.length;
      cursorPositionRestore(newPosition);
      onInput();
    },
    [onInput]
  );

  if (!textEditElement) return null;
  textStyles.current = { fontSize: parseInt(textEditElement.styles.fontSize.toString()) };

  return (
    <div id='text-edit-layer' ref={layerRef} className={styles.textAreaLayer}>
      <div style={textEditElement.styles} className={styles.textAreaLayer__text_wrapper} ref={wrapperRef}>
        <div
          className={styles.textAreaLayer__text}
          ref={contentEditableNode}
          contentEditable={true}
          suppressContentEditableWarning={true}
          style={{
            whiteSpace: 'pre-wrap',
            width: textEditElement.styles.width,
            maxWidth: textEditElement.styles.maxWidth,
            maxHeight: textEditElement.styles.maxHeight,
          }}
          onPaste={onPaste}
          onInput={onInput}
          onKeyDown={onKeyDown}
          tabIndex={1}
        >
          {textEditElement.content}
        </div>

        <div
          className={cn(styles.editorNotificationLayout, {
            [styles.editorNotificationLayout_visible]: warningOpened,
          })}
        >
          <div className={styles.editorNotificationLayout__textlimit}></div>
        </div>
      </div>
    </div>
  );
};

export default composeSubComponents(TextEditLayer, {
  TextEditLayerReceiverSubject$,
  TextEditLayerSenderSubject$,
});
