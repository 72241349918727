import React, { useCallback, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { formatNumberToString, Icon, Button, Select, InputText, Popover, SwitchButton } from '@just-ai/just-ui';
import { useToggle } from '../../../../utils/hooks';
import './ActionPanel.scss';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
import classNames from 'classnames';
export var NUMBER_KEYS = {
    sessions: {
        '1': 'Analytics: one session',
        '2': 'Analytics: two sessions',
        '3': 'Analytics: two sessions',
        '4': 'Analytics: two sessions',
    },
    messages: {
        '1': 'Analytics: one message',
        '2': 'Analytics: two messages',
        '3': 'Analytics: two messages',
        '4': 'Analytics: two messages',
    },
    routes: {
        '1': 'Analytics: one route',
        '2': 'Analytics: two routes',
        '3': 'Analytics: two routes',
        '4': 'Analytics: two routes',
    },
    states: {
        '1': 'Analytics: one state',
        '2': 'Analytics: two states',
        '3': 'Analytics: two states',
        '4': 'Analytics: two states',
    },
    clients: {},
};
export default function ActionPanel(_a) {
    var totalCount = _a.totalCount, totalPages = _a.totalPages, page = _a.page, t = _a.t, size = _a.size, downloadReport = _a.downloadReport, position = _a.position, rawChangePageSize = _a.changePageSize, rawChangePage = _a.changePage, withViewControl = _a.withViewControl, showAnswers = _a.showAnswers, compactView = _a.compactView, showAdditionalAction = _a.showAdditionalAction, showMarkup = _a.showMarkup, setShowAnswers = _a.setShowAnswers, setShowAdditionalAction = _a.setShowAdditionalAction, setCompactView = _a.setCompactView, setShowMarkup = _a.setShowMarkup, type = _a.type, totalLabel = _a.totalLabel, downloadReportComponent = _a.downloadReportComponent, hideTotalCount = _a.hideTotalCount, withoutToThePage = _a.withoutToThePage, setHideEmptyMessages = _a.setHideEmptyMessages, hideEmptyMessages = _a.hideEmptyMessages, _b = _a.leftSideOrder, leftSideOrder = _b === void 0 ? ['Counter'] : _b, _c = _a.rightSideOrder, rightSideOrder = _c === void 0 ? ['ViewControl', 'Size', 'Paginator'] : _c, className = _a.className;
    var changePageSize = useCallback(function (x) {
        //@ts-ignore
        rawChangePageSize(Number.parseInt(x.value));
    }, [rawChangePageSize]);
    var changePage = useCallback(function (x) { return rawChangePage(x.selected); }, [rawChangePage]);
    var setPage = useCallback(function (e) {
        if (e.key === 'Enter') {
            var value = Number.parseInt(e.target.value) - 1;
            if (value < 0 || Number.isNaN(value))
                value = 0;
            if (value > totalPages - 1)
                value = totalPages - 1;
            //@ts-ignore
            e.target.value = (value + 1).toString();
            rawChangePage(value);
        }
    }, [rawChangePage, totalPages]);
    var renderComponents = function (component) {
        if (component === 'Paginator' && totalPages > 1) {
            return (React.createElement(Paginator, { key: component, totalPages: totalPages, changePage: changePage, page: page, withoutToThePage: withoutToThePage, setPage: setPage, t: t }));
        }
        if (component === 'Size' && rawChangePageSize) {
            return React.createElement(Size, { key: component, changePageSize: changePageSize, t: t, position: position, size: size });
        }
        if (component === 'ViewControl' && withViewControl) {
            return (React.createElement(ViewControl, { key: component, t: t, showAnswers: showAnswers, setShowAnswers: setShowAnswers, showMarkup: showMarkup, setShowMarkup: setShowMarkup, showAdditionalAction: showAdditionalAction, setShowAdditionalAction: setShowAdditionalAction }));
        }
        if (component === 'Counter' && !hideTotalCount) {
            return React.createElement(Counter, { totalCount: totalCount, t: t, type: type, totalLabel: totalLabel });
        }
        return null;
    };
    var RightSide = rightSideOrder.map(renderComponents);
    var LeftSide = leftSideOrder.map(renderComponents);
    return (React.createElement("div", { className: "".concat(className || '', " AnalyticActionPanel_actionPanel"), id: 'scrollToElement' },
        React.createElement("div", { className: 'AnalyticActionPanel_leftSide' },
            LeftSide,
            setCompactView && (React.createElement(SwitchButton, { id: 'showMarkup', value: !compactView, onLabel: t('Analytics: messages compact view'), offLabel: t('Analytics: messages compact view'), onChange: setCompactView, labelPosition: 'right' })),
            setHideEmptyMessages && (React.createElement("div", { style: { marginLeft: '0.5rem' } },
                React.createElement(SwitchButton, { id: 'hideEmptyMessages', value: hideEmptyMessages, onLabel: t('Analytics: hide empty message label'), offLabel: t('Analytics: hide empty message label'), onChange: setHideEmptyMessages, labelPosition: 'right' }))),
            downloadReport && React.createElement(DownloadReportButton, { t: t, type: type, downloadReport: downloadReport }),
            downloadReportComponent && downloadReportComponent()),
        React.createElement("div", { className: 'AnalyticActionPanel_rightSide' }, RightSide)));
}
function DownloadReportButton(_a) {
    var t = _a.t, type = _a.type, downloadReport = _a.downloadReport;
    return (React.createElement(Button, { "data-test-id": 'dialogLogDownload', flat: true, color: 'secondary', className: 'AnalyticActionPanel_downloadReporter', onClick: downloadReport, withoutPadding: true },
        React.createElement(Icon, { name: 'farDownload' }),
        " ",
        t("Analytics: download reports ".concat(type))));
}
function Counter(_a) {
    var totalLabel = _a.totalLabel, t = _a.t, totalCount = _a.totalCount, type = _a.type;
    //@ts-ignore
    var key = NUMBER_KEYS[type] && NUMBER_KEYS[type][totalCount.toString()];
    if (!key && type)
        key = "Analytics: ".concat(type);
    var number = formatNumberToString({ value: totalCount });
    return (React.createElement("span", { "data-test-id": 'analytic-found-count' }, totalLabel ? totalLabel() : t('Analytics: found', key ? t(key, number) : number)));
}
function Paginator(_a) {
    var totalPages = _a.totalPages, changePage = _a.changePage, page = _a.page, withoutToThePage = _a.withoutToThePage, setPage = _a.setPage, t = _a.t;
    return (React.createElement(React.Fragment, null,
        ' ',
        React.createElement(ReactPaginate, { pageCount: totalPages, pageRangeDisplayed: 2, marginPagesDisplayed: 2, onPageChange: changePage, forcePage: page, containerClassName: 'AnalyticActionPanel_paginator', pageClassName: 'AnalyticActionPanel_page', pageLinkClassName: 'AnalyticActionPanel_page_link', breakClassName: 'AnalyticActionPanel_page', breakLinkClassName: 'AnalyticActionPanel_page_link', activeClassName: 'AnalyticActionPanel_active', previousClassName: 'AnalyticActionPanel_previous', nextClassName: 'AnalyticActionPanel_next', previousLinkClassName: 'AnalyticActionPanel_previous-link', nextLinkClassName: 'AnalyticActionPanel_next-link', nextLabel: React.createElement(Icon, { name: 'farChevronRight', "data-test-id": 'pagination-next', id: 'pagination-next' }), previousLabel: React.createElement(Icon, { name: 'farChevronLeft', "data-test-id": 'pagination-previous', id: 'pagination-previous' }) }),
        React.createElement("div", { className: 'AnalyticActionPanel_setPage_input_container', style: { gridTemplateColumns: "auto calc(2rem + ".concat((page + 1).toString().length, " * 0.75rem)") } }, !withoutToThePage && (React.createElement(React.Fragment, null,
            React.createElement("span", null, t('Analytics: To the page')),
            React.createElement(InputText, { "data-test-id": 'AnalyticActionPanel-page-input', type: 'number', className: 'AnalyticActionPanel_setPage_input', value: (page + 1).toString(), onBlur: setPage, min: 1, onKeyDown: setPage }))))));
}
function Size(_a) {
    var t = _a.t, changePageSize = _a.changePageSize, position = _a.position, size = _a.size;
    return (React.createElement("div", { className: 'AnalyticActionPanel_pageSizeControl', "data-test-id": 'AnalyticActionPanel-page-size-wrapper' },
        t('Analytics: Show'),
        React.createElement(Select, { "data-test-id": 'AnalyticActionPanel-page-size', onChange: changePageSize, menuPlacement: position === 'bottom' ? 'top' : 'bottom', value: { value: (size === null || size === void 0 ? void 0 : size.toString()) || '0', label: (size === null || size === void 0 ? void 0 : size.toString()) || '0' }, options: ['20', '50', '100'].map(function (x) { return ({ value: x, label: x }); }) })));
}
function ViewControl(_a) {
    var _b, _c, _d;
    var showAnswers = _a.showAnswers, t = _a.t, setShowAnswers = _a.setShowAnswers, showMarkup = _a.showMarkup, setShowMarkup = _a.setShowMarkup, showAdditionalAction = _a.showAdditionalAction, setShowAdditionalAction = _a.setShowAdditionalAction;
    var config = useContext(AnalyticsContext).config;
    var _e = useToggle(false), isOpen = _e[0], setClose = _e[2], toggle = _e[3];
    return (React.createElement("div", null,
        React.createElement(Button, { id: 'messagesViewShowControlButton', "data-test-id": 'phrasesViewShowControl', className: classNames('AnalyticActionPanel_showSettingsButton', isOpen && 'AnalyticActionPanel_openSettingsButton'), flat: true, color: 'secondary', onClick: toggle },
            React.createElement(Icon, { name: 'farSlidersH' }),
            " ",
            t('Analytics: messages view settings')),
        React.createElement(Popover, { isOpen: isOpen, target: 'messagesViewShowControlButton', onCancelClick: setClose, hideArrow: true },
            ((_b = config.messages) === null || _b === void 0 ? void 0 : _b.showAnswerViewControl) && (React.createElement(SwitchButton, { id: 'showAnswers', "data-test-id": 'AnalyticActionPanel-showAnswers', value: showAnswers, labelPosition: 'right', onLabel: t('Analytics: messages view settings show answers'), offLabel: t('Analytics: messages view settings show answers'), onChange: setShowAnswers })),
            ((_c = config.messages) === null || _c === void 0 ? void 0 : _c.showMarkupViewControl) && (React.createElement(SwitchButton, { id: 'showMarkup', "data-test-id": 'AnalyticActionPanel-showMarkup', value: showMarkup, onLabel: t('Analytics: messages view settings show markup'), offLabel: t('Analytics: messages view settings show markup'), onChange: setShowMarkup, labelPosition: 'right' })),
            ((_d = config.messages) === null || _d === void 0 ? void 0 : _d.showAdditionalActionsViewControl) && (React.createElement(SwitchButton, { id: 'showAdditionalAction', "data-test-id": 'AnalyticActionPanel-showAdditionalAction', value: showAdditionalAction, onLabel: t('Analytics: messages view settings show additional action'), offLabel: t('Analytics: messages view settings show additional action'), onChange: setShowAdditionalAction, labelPosition: 'right' })))));
}
