import React from 'react';
import cn from 'classnames';
import { useDragLayer, useDrop } from 'react-dnd';
import { DragTypes } from '../../../../../FAQTreePage/DragTypes';
import styles from './styles.module.scss';
var DragArea = function (_a) {
    var onDrop = _a.onDrop;
    var isDragging = useDragLayer(function (monitor) { return ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }); }).isDragging;
    var _b = useDrop({
        accept: DragTypes.LEAF,
        drop: function (item) {
            onDrop(item.node);
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
        }); },
    }), isOver = _b[0].isOver, drop = _b[1];
    if (!isDragging)
        return null;
    return (React.createElement("div", { className: cn(styles.DragArea) },
        React.createElement("div", { ref: drop, className: cn(styles.DragArea__content) }, isOver && React.createElement("div", { className: styles.DragArea__preview }))));
};
export default React.memo(DragArea);
