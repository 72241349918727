import React from 'react';
import { JAICPLogo, TovieLogo } from '../../components/Logo';
import { t } from 'localization';
import { getDefaultProjectDTOValue, ProjectCreationDataKeys } from './types';
import isAccess, { isEuroInstance } from '../../isAccessFunction';
import { Message } from '@just-ai/just-ui';
import { ProjectCreationData } from '../../modules/Botadmin/service/helperClasses';

export enum JAICP_ENVIRONMENT {
  jaicpExternal = 'jaicpExternal',
}

export enum ENVIRONMENT {
  external = 'external',
  jaicpCloud = 'jaicpCloud',
}

export const JAICF_WAYS = (projectList: { jaicfProject: boolean; environment: ENVIRONMENT; [key: string]: any }[]) => {
  return [
    {
      name: t('CreateProjectJAICFWay:JAICP-cloud', t(isEuroInstance() ? 'Tovie Platform' : 'JAICP')),
      icon: isEuroInstance() ? (
        <TovieLogo width={36} height={40} logoUrl={undefined} />
      ) : (
        <JAICPLogo width={36} height={40} />
      ),
      description: t('CreateProjectItem:JAICP-cloud-description', t(isEuroInstance() ? 'Tovie Platform' : 'JAICP')),
      type: ENVIRONMENT.jaicpCloud,
      warning: !isAccess(['framework.cloud_internal']) &&
        projectList.findIndex(project => project.jaicfProject && project.environment === ENVIRONMENT.jaicpCloud) >
          -1 && (
          <Message
            type='warning'
            text={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'CreateProject:JaicfChooseEnv_NO_jaicp_cloud_internal',
                    t(isEuroInstance() ? 'Tovie Platform' : 'JAICP')
                  ),
                }}
              />
            )}
          />
        ),
    },
    {
      name: t('CreateProjectJAICFWay:external'),
      icon: (
        <svg width='36' height='34' viewBox='0 0 36 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M32.5998 4.25C32.5998 1.90269 26.0761 0 18.0285 0C9.98093 0 3.45703 1.90269 3.45703 4.25C3.45703 6.59731 9.98093 8.5 18.0285 8.5C26.0761 8.5 32.5998 6.59731 32.5998 4.25Z'
            fill='#C3E6F3'
          />
          <path
            d='M18.0285 17C26.073 17 32.5998 15.0996 32.5998 12.75V4.25C32.5998 6.59964 26.073 8.5 18.0285 8.5C9.98378 8.5 3.45703 6.59964 3.45703 4.25V12.75C3.45703 15.0996 9.98378 17 18.0285 17Z'
            fill='#83CCE7'
          />
          <path
            d='M18.0285 25.5C26.073 25.5 32.5998 23.5996 32.5998 21.25V12.75C32.5998 15.0996 26.073 17 18.0285 17C9.98378 17 3.45703 15.0996 3.45703 12.75V21.25C3.45703 23.5996 9.98378 25.5 18.0285 25.5Z'
            fill='#51B8DE'
          />
          <path
            d='M32.5998 21.25C32.5998 23.5996 26.073 25.5 18.0285 25.5C9.98378 25.5 3.45703 23.5996 3.45703 21.25V29.75C3.45703 32.0996 9.98378 34 18.0285 34C26.073 34 32.5998 32.0996 32.5998 29.75V21.25Z'
            fill='#605fe3'
          />
        </svg>
      ),
      description: t('CreateProjectJAICFWay:external-description'),
      type: ENVIRONMENT.external,
    },
  ];
};

export const mapLanguageToFlag = (language?: string) => {
  switch (language) {
    case 'ja':
      return 'jp';
    case 'en':
      return 'en-gb';
    case 'eng':
      return 'en-gb';
    case 'zh':
      return 'cn';
    case 'da':
      return 'dk';
    case 'el':
      return 'gr';
    case 'uk':
      return 'ua';
    default:
      return language;
  }
};

export const mapLanguageToTemplateLanguage = (language: string) => {
  switch (language) {
    case 'cn':
      return 'zh';
    case 'eng':
      return 'en';
    default:
      return language;
  }
};

export const cleanDefaults = (projectCreationData: ProjectCreationData): ProjectCreationData => {
  const newProjectCreationData = { ...projectCreationData };
  (Object.keys(newProjectCreationData) as ProjectCreationDataKeys[]).forEach(fieldName => {
    if (
      typeof newProjectCreationData[fieldName] !== 'object' &&
      !['contentDirectory', 'defaultBranch'].includes(fieldName)
    ) {
      if (newProjectCreationData[fieldName] === getDefaultProjectDTOValue(fieldName)) {
        delete newProjectCreationData[fieldName];
      }
    }
  });
  return newProjectCreationData;
};
