import React, { useEffect } from 'react';
import { IconButton, useToggle, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { TIPS_MIN } from '../../../../constants/faqTemplates';
import classes from './styles.module.scss';
var HelpPopup = function (_a) {
    var handleClose = _a.handleClose;
    var _b = useToggle(window.localStorage.getItem(TIPS_MIN) === 'true'), isCollapsed = _b[0], toggleIsCollapsed = _b[3];
    var t = useTranslation().t;
    useEffect(function () {
        window.localStorage.setItem(TIPS_MIN, String(isCollapsed));
    }, [isCollapsed]);
    return (React.createElement("div", { className: classes.helpPopup },
        React.createElement("div", { className: classes.helpPopup__header },
            React.createElement("h4", { className: classes.helpPopup__title }, t('FAQ:help popup title')),
            React.createElement("div", { className: classes.helpPopup__control },
                React.createElement(IconButton, { name: isCollapsed ? 'farWindowMaximize' : 'farWindowMinimize', className: classes.button, size: 'sm', onClick: toggleIsCollapsed }),
                React.createElement(IconButton, { className: classes.button, name: 'farTimes', size: 'sm', onClick: handleClose }))),
        React.createElement("div", { className: cn(classes.helpPopup__content, isCollapsed && classes.helpPopup__collapsed) },
            React.createElement("ul", null,
                React.createElement("li", null, t("FAQ:helpPopupQuestionMassSelection")),
                React.createElement("li", null, t("FAQ:helpPopupQuestionSeparateSelection")),
                React.createElement("li", null, t("FAQ:helpPopupDragQuestion")),
                React.createElement("li", null, t("FAQ:helpPopupUniteQuestionsInGroups")),
                React.createElement("li", null, t("FAQ:helpPopupSeeAvailableActions"))))));
};
export default React.memo(HelpPopup);
