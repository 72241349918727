import {
  CLEAR_EDITABLE_CHANNEL,
  CLEAR_VALIDATE_TOKEN_ERROR,
  CREATE_CHANNEL_FULFILLED,
  CREATE_CHANNEL_PENDING,
  CREATE_CHANNEL_REJECTED,
  FETCH_BRANCHES_FULFILLED,
  FETCH_BRANCHES_PENDING,
  FETCH_BRANCHES_REJECTED,
  FETCH_REFS_FULFILLED,
  FETCH_REFS_PENDING,
  FETCH_REFS_REJECTED,
  FETCH_THEMES_FULFILLED,
  FETCH_THEMES_PENDING,
  FETCH_THEMES_REJECTED,
  GET_AVAILABLE_TRUNKS_FULFILLED,
  GET_AVAILABLE_TRUNKS_PENDING,
  GET_AVAILABLE_TRUNKS_REJECTED,
  HIDE_CHANNEL_VALIDATION_ERRORS,
  REMOVE_EDITABLE_CHANNEL_FULFILLED,
  REMOVE_EDITABLE_CHANNEL_PENDING,
  REMOVE_EDITABLE_CHANNEL_REJECTED,
  SAVE_EDITABLE_CHANNEL_FULFILLED,
  SAVE_EDITABLE_CHANNEL_PENDING,
  SAVE_EDITABLE_CHANNEL_REJECTED,
  SET_EDITABLE_CHANNEL,
  SHOW_CHANNEL_VALIDATION_ERRORS,
  UPDATE_EDITABLE_CHANNEL,
  VALIDATE_TOKEN_FULFILLED,
  VALIDATE_CUSTOM_DATA_FULFILLED,
  VALIDATE_TOKEN_REJECTED,
  ADD_SPEECH_PROVIDER_PENDING,
  UPDATE_SPEECH_PROVIDER_PENDING,
  DELETE_SPEECH_PROVIDER_PENDING,
  GET_SPEECH_PROVIDERS_PENDING,
  ADD_SPEECH_PROVIDER_REJECTED,
  UPDATE_SPEECH_PROVIDER_REJECTED,
  DELETE_SPEECH_PROVIDER_REJECTED,
  GET_SPEECH_PROVIDERS_REJECTED,
  ADD_SPEECH_PROVIDER_FULFILLED,
  UPDATE_SPEECH_PROVIDER_FULFILLED,
  DELETE_SPEECH_PROVIDER_FULFILLED,
  GET_SPEECH_PROVIDERS_FULFILLED,
  VALIDATE_CUSTOM_DATA_REJECTED,
} from '../../constants/channels.actions';
import { merge } from 'lodash/object';
import { union } from 'lodash/array';
import { isArray, isEmpty } from 'lodash';
import { extractErrorText } from '../../utils';

export const blankChannel = {
  id: null,
  channelType: '',
  botLink: '',
  accessToken: '',
  branch: '',
  description: '',
  rollout: 'AUTO',
  status: '',
  checkStatus: false,
  deploymentLogLink: '',
  position: 'right',

  resteriskSecret: '',
  resteriskRecordingEnabled: false,

  customData: JSON.stringify({
    skills: [],
  }),
};
const InitialState = {
  editableChannel: blankChannel,
  branches: [],
  refs: {},
  themes: [],
  implementationScript: null,
  chatApiUrl: null,
  slackApiUrl: null,
  webimApiUrl: null,
  webim2ApiUrl: null,
  iDigitalWebhookUrl: null,
  whatsappWebhookUrl: null,
  incomingJivositeWebhookUrl: null,
  azureWebhookUrl: null,
  nexmoInboundUrl: null,
  nexmoStatusUrl: null,
  isTokenValid: true,
  botName: null,
  isEdit: false,
  fetching: false,
  fetched: false,
  error: [],
  passedClientValidation: true,
  availableTrunks: [],
  extraData: {},
  fetchingSpeech: false,
};

export default function EditChannelReducer(state = InitialState, action) {
  switch (action.type) {
    case SHOW_CHANNEL_VALIDATION_ERRORS:
      let newErrors = isArray(action.errors) ? [...action.errors] : [action.errors];

      newErrors = new Set([...state.error, ...newErrors]);

      return {
        ...state,
        error: Array.from(newErrors),
      };
    case GET_AVAILABLE_TRUNKS_PENDING:
    case FETCH_THEMES_PENDING:
    case FETCH_BRANCHES_PENDING:
    case FETCH_REFS_PENDING:
    case REMOVE_EDITABLE_CHANNEL_PENDING:
    case CREATE_CHANNEL_PENDING:
    case SAVE_EDITABLE_CHANNEL_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case GET_AVAILABLE_TRUNKS_REJECTED:
    case FETCH_BRANCHES_REJECTED:
    case FETCH_REFS_REJECTED:
      return {
        ...state,
        error: action.payload.data || [],
        fetching: false,
      };

    case HIDE_CHANNEL_VALIDATION_ERRORS:
      return {
        ...state,
        error: [],
      };
    case UPDATE_EDITABLE_CHANNEL:
      return {
        ...state,
        editableChannel: merge({}, state.editableChannel, action.editableChannel),
        channelName: action.editableChannel.channelType ? state.channelName : '',
        botId: action.editableChannel.channelType ? state.botId : '',
      };

    case SAVE_EDITABLE_CHANNEL_FULFILLED:
      return {
        ...state,
        fetched: true,
        fetching: false,
        isEdit: false,
      };

    case SAVE_EDITABLE_CHANNEL_REJECTED:
      let saveEditChannelErrors = [];
      if (action.payload.response.status !== 403) {
        saveEditChannelErrors = !!action.payload.response.data.error
          ? [action.payload.response.data.error]
          : action.payload.response.data.errors;
      }

      return {
        ...state,
        fetching: false,
        fetched: false,
        error: union(state.error, saveEditChannelErrors),
      };

    case SET_EDITABLE_CHANNEL:
      return {
        ...state,
        editableChannel: action.channel,
        isTokenValid: true,
        implementationScript: null,
        chatApiUrl: null,
        slackApiUrl: null,
        webimApiUrl: null,
        webim2ApiUrl: null,
        nexmoInboundUrl: null,
        nexmoStatusUrl: null,
        channelName: null,
        botId: null,
        error: [],
        isEdit: true,
      };

    case CLEAR_EDITABLE_CHANNEL:
      return {
        ...state,
        editableChannel: blankChannel,
        implementationScript: null,
        chatApiUrl: null,
        slackApiUrl: null,
        webimApiUrl: null,
        webim2ApiUrl: null,
        nexmoInboundUrl: null,
        nexmoStatusUrl: null,
        isTokenValid: true,
        channelName: null,
        botId: null,
        error: [],
        extraData: {},
        isEdit: false,
      };

    case CREATE_CHANNEL_FULFILLED:
      return {
        ...state,
        editableChannel: action.payload.data,
        isEdit: true,
        fetching: false,
        error: [],
      };

    case CREATE_CHANNEL_REJECTED:
      let errors = [];
      if (action.payload.response.status !== 403) {
        errors = !!action.payload.response.data.error
          ? [extractErrorText(action.payload.response)]
          : action.payload.response.data.errors;
      }

      return {
        ...state,
        fetching: false,
        error: union(state.error, errors),
      };

    case VALIDATE_TOKEN_FULFILLED:
      return {
        ...state,
        isTokenValid: true,
        channelName: action.payload.data.botName,
        botId: action.payload.data.botId,
        error: InitialState.error,
        extraData: Boolean(action.payload.data.extraData) ? action.payload.data.extraData : InitialState.extraData,
      };

    case VALIDATE_CUSTOM_DATA_FULFILLED:
      return {
        ...state,
        extraData: Boolean(action.payload.payload) ? action.payload.payload : InitialState.extraData,
      };
    case VALIDATE_CUSTOM_DATA_REJECTED:
    case VALIDATE_TOKEN_REJECTED:
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          botName: null,
        },
        isTokenValid: false,
        channelName: null,
        botId: null,
        error: InitialState.error,
        extraData: InitialState.extraData,
      };

    case CLEAR_VALIDATE_TOKEN_ERROR:
      return {
        ...state,
        isTokenValid: true,
      };

    case REMOVE_EDITABLE_CHANNEL_FULFILLED:
      return {
        ...state,
        editableChannel: blankChannel,
        isTokenValid: true,
        channelName: null,
        botId: null,
        error: [],
        isEdit: false,
        fetching: false,
      };

    case REMOVE_EDITABLE_CHANNEL_REJECTED:
      return {
        ...state,
        error: action.payload.data,
        fetching: false,
      };

    case FETCH_BRANCHES_FULFILLED:
      return {
        ...state,
        branches: !!action.payload.data && !isEmpty(action.payload.data) ? action.payload.data : [],
        fetching: false,
      };

    case FETCH_REFS_FULFILLED:
      return {
        ...state,
        refs: action.payload.data,
        fetching: false,
      };

    case FETCH_THEMES_FULFILLED:
      return {
        ...state,
        themes: !!action.payload.data && !isEmpty(action.payload.data) ? action.payload.data : [],
        fetching: false,
      };

    case FETCH_THEMES_REJECTED:
      return {
        ...state,
        error: !!action.payload.response?.data?.error
          ? [action.payload.response?.data?.error]
          : action.payload?.data?.errors,
        fetching: false,
      };

    case GET_AVAILABLE_TRUNKS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        availableTrunks: action.payload.data,
      };
    }

    case ADD_SPEECH_PROVIDER_PENDING:
    case UPDATE_SPEECH_PROVIDER_PENDING:
    case DELETE_SPEECH_PROVIDER_PENDING:
    case GET_SPEECH_PROVIDERS_PENDING: {
      return {
        ...state,
        fetchingSpeech: true,
      };
    }

    case ADD_SPEECH_PROVIDER_REJECTED:
    case UPDATE_SPEECH_PROVIDER_REJECTED:
    case DELETE_SPEECH_PROVIDER_REJECTED:
    case GET_SPEECH_PROVIDERS_REJECTED:
    case ADD_SPEECH_PROVIDER_FULFILLED:
    case UPDATE_SPEECH_PROVIDER_FULFILLED:
    case DELETE_SPEECH_PROVIDER_FULFILLED:
    case GET_SPEECH_PROVIDERS_FULFILLED:
      return {
        ...state,
        fetchingSpeech: false,
      };

    default:
      return state;
  }
}
