import React, { FC, useRef, useState, useCallback, useEffect, useMemo } from 'react';

import keyboardjs from 'keyboardjs';
import Konva from 'konva';

import { KonvaEventObject } from 'konva/lib/Node';

import { Group, Image as KonvaImage } from 'react-konva';
import { useToggle } from '@just-ai/just-ui';

import { updateUsingStageProps } from 'modules/JGraph/utils/connectionLayerUtils';
import { StatesStorePipe$, StateStorePositionSize } from 'modules/JGraph/utils/blockLayerUtils';
import { AutoLayoutProgressSubject$ } from 'modules/JGraph/hooks/useAutoplacement';
import { Vector2D } from 'modules/JGraph/utils/2DVector';

import { StickerMoveReceiver$ } from '../StickerLayer';
import { StickerColor, getStickerColorInfo } from './colors';
import { StickerActions } from './hooks';
import { StickerInfo } from './types';
import StickerToolMenu from './StickerToolMenu';
import AuthorLabel from './AuthorLabel';
import KonvaTooltip from './KonvaTooltip';
import EditableText from './EditableText';
import StickerConnectors from './StickerConnectors';
import StickerTransform from './StickerTransform';
import { stickerTextSize, stickerSideSize } from './consts';

type StateScreenPropsType = {
  info: StickerInfo;
  stickerActions: StickerActions;
};
const Sticker: FC<StateScreenPropsType> = React.memo(({ info, stickerActions }: StateScreenPropsType) => {
  const groupRef = useRef<Konva.Group | null>(null);
  const imgRef = useRef<Konva.Image | null>(null);
  const [editModeOpened, setEditMode] = useState(false);
  const [selected, showSelected, closeSelected] = useToggle(false);
  const innerSticker = useRef<StickerInfo | null>(null);
  const stickerColor = useMemo(() => getStickerColorInfo(info.color), [info.color]);

  useEffect(() => {
    innerSticker.current = info;
  }, [info]);

  const onChange = useCallback(() => {
    stickerActions.updateSticker(innerSticker.current!);
  }, [stickerActions]);

  const onChangePosition = useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      const stage = groupRef.current?.getStage();
      if (!stage) return;
      const nodePosition = e.target.getAbsolutePosition();
      let [, toPosition] = updateUsingStageProps(stage, { x: 0, y: 0 }, nodePosition || { x: 0, y: 0 });
      innerSticker.current = {
        ...innerSticker.current!,
        position: toPosition,
      };
      stickerActions.moveStickers([{ stickerId: info.id, position: toPosition }]);
    },
    [info.id, stickerActions]
  );

  const onChangeContent = useCallback(
    (content: string, fontSize: number) => {
      innerSticker.current = {
        ...innerSticker.current!,
        fontSize,
        content,
      };
      onChange();
    },
    [onChange]
  );

  const onChangeColor = useCallback(
    (color: StickerColor) => {
      innerSticker.current = {
        ...innerSticker.current!,
        color: color.name,
      };
      onChange();
    },
    [onChange]
  );

  const image = useMemo(() => {
    const img = new Image();
    img.src = stickerColor.img;
    return img;
  }, [stickerColor.img]);

  const checkClickOutside = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (info.id === e.target.attrs.id || groupRef.current?.findOne((el: any) => el === e.target)) return;
      closeSelected();
    },
    [closeSelected, info.id]
  );

  useEffect(() => {
    if (!groupRef.current || !selected) return;
    const stage = groupRef.current.getStage();
    if (!stage) return;
    stage.on('click', checkClickOutside);
    return () => {
      stage.off('click', checkClickOutside);
    };
  }, [checkClickOutside, selected]);

  useEffect(() => {
    if (editModeOpened) closeSelected();
  }, [closeSelected, editModeOpened]);

  useEffect(() => {
    if (!selected) return;
    const deleteSticker = () => stickerActions.deleteSticker(info.id);
    keyboardjs.on(['backspace', 'del'], deleteSticker);
    return () => keyboardjs.off(['backspace', 'del'], deleteSticker);
  }, [info, selected, stickerActions]);

  const prevStatePositionAndSize = useRef<StateStorePositionSize | null>(null);
  useEffect(() => {
    if (!info.connection) return;
    prevStatePositionAndSize.current = null;
    const sub = StatesStorePipe$.subscribe(store => {
      const stickerNode = groupRef.current;
      if (!info.connection || !stickerNode) return;
      const stateTransform = store[info.connection.statePath];
      if (!stateTransform) return;
      const prevPositionAndSize = prevStatePositionAndSize.current;
      if (!prevPositionAndSize) {
        prevStatePositionAndSize.current = stateTransform;
        return;
      }
      const prevStatePosition = Vector2D.from(prevPositionAndSize.x, prevPositionAndSize.y);
      prevStatePositionAndSize.current = stateTransform;

      const stickerPosition = stickerNode.getPosition();
      const statePosition = Vector2D.from(stateTransform.x, stateTransform.y);
      const stickerNodePosition = Vector2D.from(stickerPosition.x, stickerPosition.y);
      if (!statePosition.isEqual(prevStatePosition)) {
        const diff = statePosition.subtract(prevStatePosition);
        const newStickerPosition = stickerNodePosition.add(diff);
        stickerNode.setPosition(newStickerPosition);
        innerSticker.current = {
          ...innerSticker.current!,
          position: newStickerPosition,
        };
        if (AutoLayoutProgressSubject$.getValue().status === 'pending') return;
        StickerMoveReceiver$.next({ type: 'add', payload: { id: info.id, position: newStickerPosition } });
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [info, onChange]);

  const onConnectionChange = useCallback(
    (statePath: string, side: 'left' | 'right') => {
      innerSticker.current = {
        ...innerSticker.current!,
        connection: statePath
          ? {
              statePath,
              position: side,
            }
          : undefined,
      };
      onChange();
    },
    [onChange]
  );

  return (
    <Group
      ref={groupRef}
      id={info.id}
      draggable={!editModeOpened}
      x={info.position.x}
      y={info.position.y}
      width={stickerSideSize.width}
      height={stickerSideSize.height}
      onDragEnd={onChangePosition}
    >
      <StickerConnectors
        sticker={info}
        onConnectorSelected={closeSelected}
        stickerSelected={selected}
        stickerNode={groupRef.current}
        onConnectionChange={onConnectionChange}
      />
      {selected ? <StickerTransform /> : null}
      <KonvaTooltip
        isOpen={selected}
        closeOnScaleDisable
        target={imgRef.current}
        placement='top-start'
        mainAxisOffset={8}
      >
        <StickerToolMenu selected={stickerColor} onChange={onChangeColor} />
      </KonvaTooltip>
      <Group onClick={showSelected}>
        <KonvaImage
          ref={ref => (imgRef.current = ref)}
          image={image}
          width={stickerSideSize.width}
          height={stickerSideSize.height}
          x={0}
          y={0}
        />
        <EditableText
          text={info.content}
          fontFamily='Roboto'
          fontSize={info.fontSize}
          x={16}
          y={16}
          width={stickerTextSize.width}
          height={stickerTextSize.height}
          verticalAlign='middle'
          align='center'
          lineHeight={1.5}
          fill='#000000'
          onChange={onChangeContent}
          onChangeEditMode={setEditMode}
        />
        <AuthorLabel x={16} y={16 + stickerTextSize.height + 8} sticker={info} />
      </Group>
    </Group>
  );
});

export * from './TextEditLayer';
export default Sticker;
