var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle, useTranslation } from '@just-ai/just-ui';
import { TreeStructure } from '../Tree/TreeStructure';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import classes from './styles.module.scss';
var MAX_HEIGHT = 300;
export var ContextMenuActions;
(function (ContextMenuActions) {
    ContextMenuActions["TurnOff"] = "turnOff";
    ContextMenuActions["TurnOn"] = "turnOn";
    ContextMenuActions["Copy"] = "copy";
    ContextMenuActions["Group"] = "group";
    ContextMenuActions["Ungroup"] = "ungroup";
    ContextMenuActions["Export"] = "export";
    ContextMenuActions["Delete"] = "delete";
    ContextMenuActions["Rename"] = "rename";
    ContextMenuActions["TurnOffGroup"] = "turnOffGroup";
    ContextMenuActions["TurnOnGroup"] = "turnOnGroup";
    ContextMenuActions["CopyGroup"] = "copyGroup";
    ContextMenuActions["ExportGroup"] = "exportGroup";
    ContextMenuActions["DeleteGroup"] = "deleteGroup";
})(ContextMenuActions || (ContextMenuActions = {}));
var useContextMenuActionsMap = function () {
    var _a;
    var t = useTranslation().t;
    var ContextMenuActionsMap = (_a = {},
        _a[ContextMenuActions.TurnOff] = {
            name: ContextMenuActions.TurnOff,
            iconName: "farToggleOff",
            text: t('FAQ:contextMenuTurnOff'),
        },
        _a[ContextMenuActions.TurnOn] = {
            name: ContextMenuActions.TurnOn,
            iconName: "farToggleOn",
            text: t('FAQ:contextMenuTurnOn'),
        },
        _a[ContextMenuActions.Copy] = {
            name: ContextMenuActions.Copy,
            iconName: "farCopy",
            text: t('FAQ:contextMenuCopy'),
        },
        _a[ContextMenuActions.Ungroup] = {
            name: ContextMenuActions.Ungroup,
            iconName: "farObjectUngroup",
            text: t('FAQ:contextMenuUngroup'),
        },
        _a[ContextMenuActions.Group] = {
            name: ContextMenuActions.Group,
            iconName: "farObjectGroup",
            text: t('FAQ:contextMenuGroup'),
        },
        _a[ContextMenuActions.Export] = {
            name: ContextMenuActions.Export,
            iconName: "farArrowToBottom",
            text: t('FAQ:contextMenuExportNode'),
        },
        _a[ContextMenuActions.Delete] = {
            name: ContextMenuActions.Delete,
            iconName: "farTrashAlt",
            className: classes.faqTreeContextMenu__delete,
            text: t('FAQ:contextMenuDelete'),
        },
        _a[ContextMenuActions.Rename] = {
            name: ContextMenuActions.Rename,
            iconName: 'farPen',
            text: t('FAQ:contextMenuRename'),
        },
        _a[ContextMenuActions.TurnOffGroup] = {
            name: ContextMenuActions.TurnOffGroup,
            iconName: "farToggleOff",
            text: t('FAQ:contextMenuTurnOffGroup'),
            textIfMany: t('FAQ:contextMenuTurnOffGroup_many'),
        },
        _a[ContextMenuActions.TurnOnGroup] = {
            name: ContextMenuActions.TurnOnGroup,
            iconName: "farToggleOn",
            text: t('FAQ:contextMenuTurnOnGroup'),
            textIfMany: t('FAQ:contextMenuTurnOnGroup_many'),
        },
        _a[ContextMenuActions.CopyGroup] = {
            name: ContextMenuActions.CopyGroup,
            iconName: "farCopy",
            text: t('FAQ:contextMenuCopyGroup'),
            textIfMany: t('FAQ:contextMenuCopyGroup_many'),
        },
        _a[ContextMenuActions.ExportGroup] = {
            name: ContextMenuActions.ExportGroup,
            iconName: "farArrowToBottom",
            text: t('FAQ:contextMenuExportFolder'),
        },
        _a[ContextMenuActions.DeleteGroup] = {
            name: ContextMenuActions.DeleteGroup,
            iconName: "farTrashAlt",
            className: classes.faqTreeContextMenu__delete,
            text: t('FAQ:contextMenuDeleteGroup'),
            textIfMany: t('FAQ:contextMenuDeleteGroup_many'),
        },
        _a);
    return ContextMenuActionsMap;
};
function modifyMenuItem(menuItem, options) {
    var newMenuItem = Object.assign({}, menuItem);
    if (options.count && options.count > 1) {
        newMenuItem.text = "".concat(menuItem.textIfMany || menuItem.text, " (").concat(options.count, ")");
    }
    if (options.nodes) {
        newMenuItem.nodes = options.nodes;
    }
    if (options.disabled !== undefined) {
        newMenuItem.disabled = options.disabled;
    }
    return newMenuItem;
}
var useFAQTreeContextMenuItems = function (targetNode, nodes, writeDisabled) {
    var ContextMenuActionsMap = useContextMenuActionsMap();
    return useMemo(function () {
        var menu = [];
        var isTargetNodeInSelectionList = nodes.find(function (node) { return node.id === targetNode.id; });
        var selectedNodes = isTargetNodeInSelectionList ? nodes : [targetNode];
        var nodesToSwitch = selectedNodes;
        var areAllNodesDisabled = false;
        if (targetNode.isFolder) {
            var folderNodes = selectedNodes.filter(function (node) { return node.isFolder; });
            var rootNodes = selectedNodes.filter(function (node) { return node.isInRoot; });
            nodesToSwitch = folderNodes
                .map(function (node) { return TreeStructure.getNodeAsPlainList(node); })
                .flat()
                .filter(function (node) { return !node.isFolder; });
            areAllNodesDisabled = !(nodesToSwitch === null || nodesToSwitch === void 0 ? void 0 : nodesToSwitch.find(function (node) { return node.enabled; }));
            var isRenameDisabled = folderNodes.length > 1 || rootNodes.length > 0 || writeDisabled;
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Rename], {
                disabled: isRenameDisabled,
                nodes: folderNodes,
            }));
            menu.push(modifyMenuItem(areAllNodesDisabled
                ? ContextMenuActionsMap[ContextMenuActions.TurnOnGroup]
                : ContextMenuActionsMap[ContextMenuActions.TurnOffGroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.CopyGroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Ungroup], {
                count: folderNodes.length,
                nodes: folderNodes,
                disabled: writeDisabled,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.ExportGroup], {
                count: folderNodes.length,
                nodes: selectedNodes,
            }));
            menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.DeleteGroup], {
                count: folderNodes.length,
                nodes: selectedNodes,
                disabled: writeDisabled,
            }));
            return menu;
        }
        var questionNodes = selectedNodes.filter(function (node) { return !node.isFolder; });
        nodesToSwitch = questionNodes;
        areAllNodesDisabled = !(nodesToSwitch === null || nodesToSwitch === void 0 ? void 0 : nodesToSwitch.find(function (node) { return node.enabled; }));
        menu.push(modifyMenuItem(areAllNodesDisabled
            ? ContextMenuActionsMap[ContextMenuActions.TurnOn]
            : ContextMenuActionsMap[ContextMenuActions.TurnOff], {
            count: questionNodes.length,
            nodes: nodesToSwitch,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Copy], {
            count: questionNodes.length,
            nodes: questionNodes,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Group], {
            count: questionNodes.length,
            nodes: questionNodes,
            disabled: writeDisabled,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Export], {
            count: questionNodes.length,
            nodes: selectedNodes,
        }));
        menu.push(modifyMenuItem(ContextMenuActionsMap[ContextMenuActions.Delete], {
            count: questionNodes.length,
            nodes: selectedNodes,
            disabled: writeDisabled,
        }));
        return menu;
    }, [nodes, targetNode, ContextMenuActionsMap, writeDisabled]);
};
var ContextMenu = function (_a) {
    var targetNode = _a.targetNode, nodes = _a.nodes, position = _a.position, close = _a.close, onSelectAction = _a.onSelectAction, writeDisabled = _a.writeDisabled;
    var direction = useMemo(function () { return ((position === null || position === void 0 ? void 0 : position.y) + MAX_HEIGHT < document.body.offsetHeight ? 'down' : 'up'); }, [position]);
    var menuItems = useFAQTreeContextMenuItems(targetNode, nodes, writeDisabled);
    return (React.createElement(Dropdown, { compact: true, dark: true, isOpen: true, toggle: close, direction: direction, style: __assign({ zIndex: 3002, position: 'fixed', left: position.x }, (direction === 'down'
            ? { top: (position === null || position === void 0 ? void 0 : position.y) - 13 }
            : { bottom: document.body.offsetHeight - (position === null || position === void 0 ? void 0 : position.y) - 13 })) },
        React.createElement(DropdownToggle, { style: { padding: 0, border: 'none' } }),
        React.createElement(DropdownMenu, { className: classes.faqTreeContextMenu }, menuItems.map(function (_a, key) {
            var name = _a.name, nodes = _a.nodes, iconName = _a.iconName, text = _a.text, className = _a.className, disabled = _a.disabled;
            return (React.createElement(DropdownItem, { className: className, onClick: function () { return onSelectAction(name, nodes); }, "data-test-id": "FAQTree.ContextMenu.action.".concat(name), disabled: disabled, key: key },
                React.createElement(Icon, { name: iconName }),
                text));
        }))));
};
export default ContextMenu;
