import {
  getActivationsTagNames,
  ReactionsTagNames,
  ReactionsTagNamesWithElseif,
  TActivationTagNames,
  TagNames,
  TJBlock,
  TReactionsTagNames,
} from '../../../utils/types';
import { TSortableItem } from '../types';
import { JoinConditionsBlock } from '../settings/ConditionSettings';
import { ReactionItem } from '../settings/ScreenBlocksList/ReactionItem';
import { isActivationToState } from '../../../utils/blockLayerUtils';
import { CustomTagData } from '../../../../Editor/api/client';
import { CustomTagsStore$ } from '../../../../../reducers/JGraph.reducer/customTags.store';
import { tagParametersToObj } from 'reducers/JGraph.reducer/utils';

export const getSortableReactions = (blocks: TJBlock[], useConditionBlocks?: boolean) => {
  let result: TSortableItem[] = [];
  blocks.forEach((block, blockIndex) => {
    if (
      (useConditionBlocks ? ReactionsTagNamesWithElseif : ReactionsTagNames()).includes(
        block.tagName as TReactionsTagNames
      )
    ) {
      result.push({
        parentBlocks: blocks,
        block: block,
        blockIndex: blockIndex,
        componentType: useConditionBlocks
          ? [TagNames.if, TagNames.else, TagNames.elseif].includes(block.tagName)
            ? JoinConditionsBlock
            : ReactionItem
          : ReactionItem,
      });
    }
  });
  return result;
};

export const getSortableActivations = (blocks: TJBlock[], isLiteMode = false) => {
  let result: TSortableItem[] = [];
  blocks.forEach((block, blockIndex) => {
    if (
      getActivationsTagNames(isLiteMode).includes(block.tagName as TActivationTagNames) &&
      !isActivationToState(block)
    ) {
      result.push({ block: block, blockIndex: blockIndex, parentBlocks: blocks });
    }
  });
  return result;
};

export const getToStateActivations = (blocks: TJBlock[], isLiteMode = false) => {
  let result: TSortableItem[] = [];
  blocks.forEach((block, blockIndex) => {
    if (
      getActivationsTagNames(isLiteMode).includes(block.tagName as TActivationTagNames) &&
      isActivationToState(block)
    ) {
      result.push({ block: block, blockIndex: blockIndex, parentBlocks: blocks });
    }
  });
  return result;
};

export const validateBlockFulfillment = (
  block?: TJBlock,
  customTagDisabledSave?: boolean,
  customTags?: CustomTagData[]
): boolean => {
  if (!block) return true;
  let simpleBlockCheckDisabled = false;

  const mapTagParameters = tagParametersToObj(block.tagParameters);

  switch (block.tagName) {
    case TagNames.a: {
      const ttsText = mapTagParameters['tts']?.value as string;
      const text = block.tagValue?.trim() ?? '';
      if (!text && !ttsText) {
        simpleBlockCheckDisabled = true;
      }
      break;
    }
    case TagNames.HttpRequest: {
      if (!mapTagParameters.url.value.trim() || !mapTagParameters.method.value.trim()) {
        simpleBlockCheckDisabled = true;
      }
      break;
    }
    default:
      if (
        customTagDisabledSave !== undefined &&
        customTags &&
        customTags.map(tag => tag.tagName).includes(block.tagName)
      ) {
        const descriptor = customTags.find(({ tagName }) => tagName === block.tagName);
        if (descriptor) {
          simpleBlockCheckDisabled = customTagDisabledSave;
        }
      }
      break;
  }
  return simpleBlockCheckDisabled;
};

export const isEditBlockTypeCustomTag = (block?: TJBlock, customTags?: CustomTagData[]): boolean => {
  if (!customTags) customTags = CustomTagsStore$.getValue();
  if (block && customTags && customTags.map(tag => tag.tagName).includes(block.tagName)) {
    const descriptor = customTags.find(({ tagName }) => tagName === block.tagName);
    if (descriptor) {
      return true;
    }
  }
  return false;
};
