var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation, IconButton, Icon } from '@just-ai/just-ui';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { useNLUModuleContext } from '../../../context';
export default function FAQTemplateHeader() {
    var history = useHistory();
    var wrappedHistoryPush = useNLUModuleContext().wrappedHistoryPush;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles.faqAddSkill__header },
        React.createElement("div", { onClick: function () { return wrappedHistoryPush({ url: 'faq', historyState: __assign({}, history.location.state) }); } },
            React.createElement(Icon, { name: 'falArrowLeft' }),
            React.createElement("p", null, t('Back'))),
        React.createElement(IconButton, { name: 'falTimes', onClick: function () { return wrappedHistoryPush({ url: 'faq', historyState: __assign({}, history.location.state) }); }, flat: true, color: 'secondary' })));
}
