var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { mapKeys } from 'lodash';
import { commonLocalization } from './localization/common.loc';
import { analyticLocalization } from './localization/analytic.loc';
import { analyticsLocalization } from './localization/analytics.loc';
import { channeltypeLocalization } from './localization/channeltype.loc';
import { projectoverviewLocalization } from './localization/projectoverview.loc';
import { newprojectoverviewLocalization } from './localization/newprojectoverview.loc';
import { reportsLocalization } from './localization/reports.loc';
var Localize = require('localize');
require('decliner/dist/decliner.min');
var localization = __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, commonLocalization), analyticLocalization), analyticsLocalization), channeltypeLocalization), projectoverviewLocalization), newprojectoverviewLocalization), reportsLocalization);
export default localization;
var radix = 10;
export var localize = new Localize(__assign({}, localization));
localize.addTranslations = function (translate) {
    mapKeys(translate, function (key) {
        // eslint-disable-next-line no-prototype-builtins
        if (!localize.getTranslations().hasOwnProperty(key)) {
            localize.loadTranslations(translate);
        }
    });
};
localize.decliner = function (arr, count) {
    return arr.decline(parseInt(count, radix));
};
localize.checkExistKey = function (key) {
    return key !== localize.translate(key);
};
localize.throwOnMissingTranslation(false);
