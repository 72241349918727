import { IntentItemType } from '../../model';
import React, { forwardRef } from 'react';
import { Icon, InputGroup, Spinner, Tooltip } from '@just-ai/just-ui';
import { t } from '../../../../localization';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';

type AddingPhraseInputProps = {
  switchType: () => void;
  itemType: IntentItemType;
  paraphraseLoading: boolean;
  editDisabled: undefined | boolean;
  addPhrases: (event?: React.SyntheticEvent<HTMLButtonElement, MouseEvent>, softValidate?: boolean) => unknown;
  paraphraseButtonCondition: any;
  paraphraseClick: () => Promise<void>;
  newItemText: string;
  appConfig: any;
  rerollRef: React.MutableRefObject<HTMLElement | null>;
  tooltipContent: any;
  onChange: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent) => void;
  valErrorInNewItem: boolean;
  iconRef: React.MutableRefObject<null>;
  loadingRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const AddingPhraseInput = forwardRef<HTMLDivElement, AddingPhraseInputProps>((props, ref) => {
  return (
    <div ref={ref} className='intents-page__add-phrase-block'>
      <InputGroup
        className='intents-page__edit-phrase-block edit-phrase-block'
        Prepend={
          <button
            type='button'
            className='btn justui_button btn-outline-secondary'
            onClick={props.switchType}
            data-test-id='IntentsPage.PhrasesEdit.AddPhrase.SwitchTypeButton'
            title={t(
              'IntentItemsEdit:' + (props.itemType === IntentItemType.phrase ? 'buttonPhrase' : 'buttonPattern')
            )}
            disabled={props.paraphraseLoading || props.editDisabled}
          >
            <Icon name={props.itemType === IntentItemType.phrase ? 'farText' : 'faAt'} />
          </button>
        }
        Append={
          <button
            className='btn justui_button btn-outline-secondary'
            type='button'
            color='secondary'
            onClick={props.addPhrases}
            data-test-id='IntentsPage.PhrasesEdit.AddPhraseButton'
            disabled={props.paraphraseLoading || props.editDisabled}
          >
            <Icon name='faEnterSymbol' />
          </button>
        }
        AppendInner={
          props.paraphraseButtonCondition
            ? [
                {
                  name: 'farSyncAlt',
                  onClick: props.paraphraseClick,
                  disabled: !props.newItemText || !props.appConfig.paraphrase.enabled,
                  tooltipWrapperId: 'paraphraseButton',
                  'data-test-id': 'IntentsPage.PhrasesEdit.ParaphraseBtn',
                  type: 'button',
                },
              ]
            : undefined
        }
      >
        {props.paraphraseButtonCondition && props.rerollRef.current && (
          <Tooltip className='tooltip--dark' target={props.rerollRef}>
            {props.tooltipContent}
          </Tooltip>
        )}
        <TextAreaLikeElement
          className='textarea-container form-control intent-add-phrase-textarea'
          value={props.newItemText}
          richValue={props.newItemText}
          placeholder={t('IntentItemsEdit:textPlaceholder')}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          dataTestId='IntentsPage.PhrasesEdit.AddPhraseInput'
          disabled={props.paraphraseLoading || props.editDisabled}
          append={
            props.valErrorInNewItem && (
              <>
                <div className='phrase-row__error' ref={props.iconRef}>
                  <Icon name='faExclamationCircle' color='danger' />
                </div>
                <Tooltip target={props.iconRef} placement='top'>
                  {t('PhraseBlocks:validationTooltip')}
                </Tooltip>
              </>
            )
          }
        />
        {props.paraphraseLoading && (
          <div className='loading-wrapper'>
            <div ref={props.loadingRef} id='paraphraseLoadingTooltip'>
              <Spinner size='md' />
            </div>
            <Tooltip className='tooltip--dark' target={props.loadingRef}>
              {t('IntentItemsEdit:tooltipParaphraseLoading')}
            </Tooltip>
          </div>
        )}
      </InputGroup>
    </div>
  );
});
