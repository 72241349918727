import React from 'react';
import { Button, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import styles from './styles.module.scss';
var EmptyFaqContent = function (_a) {
    var onCreate = _a.onCreate;
    var t = useTranslation().t;
    return (React.createElement("div", { className: cn(styles.EmptyFaqContent) },
        React.createElement("h2", { className: styles.EmptyFaqContent__title }, t('FAQ:Home:EmptyContent:Title')),
        React.createElement(Button, { color: 'primary', outline: true, onClick: onCreate }, t('FAQ:AddSkill'))));
};
export default React.memo(EmptyFaqContent);
