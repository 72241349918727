import { CustomTagData, JState } from '@just-ai/api/dist/generated/Editorbe';
import { TagNames, TJBlock } from '../../modules/JGraph/utils/types';

export interface TJState extends JState {
  filename: string;
  theme: string;
}
export interface JStateWithId extends TJState {
  id: string;
  pathId: string;
  blocks: TJBlock[];
  debugActive?: boolean;
  debugLastActive?: boolean;
  states?: JStateWithId[];
  isUnsaved?: boolean;
  canRender?: boolean;
  isDirty?: boolean;
}

export type KeyInCustomTagData = keyof CustomTagData;

export type FromStateTransition = { tagName: TagNames; to: string; connectionRef: TConnector; text: string };

export type FromStateTransitions = Record<string, FromStateTransition[]>;

export class JGraphStateData {
  blocks: JStateWithId[] = [];
  files: Record<string, number> = {};
  fromStateTransitions: FromStateTransitions = {};
  reverting = false;
}

export type TConnector = {
  from: string;
  fromNode: string;
  uuid: string;
  value: string;
  fromNodeOriginalPath: string;
  toNodeOriginalPath: string;
  tagName: string;
  to?: string;
  deferred?: boolean;
  fallback?: string;
  debugActive?: boolean;
};

export type EditMenuBlock = {
  screen: JStateWithId;
  jBlockIndex?: number;
  path?: string;
};
