import React from 'react';
import { Icon } from '../Icon';
import './Announcement.scss';
import classNames from 'classnames';
import { Button } from '../Button';
const IconByAnnouncementType = {
    success: 'faCheckSquare',
    info: 'faInfoCircle',
    danger: 'faExclamationCircle',
    warning: 'faExclamationTriangle',
};
export function Announcement({ AnnouncementText, onClose, type = 'info', action, dark = false, dataTestId, iconName, iconSize, className = '', }) {
    return (React.createElement("div", { "data-test-id": dataTestId || 'Announcement.Container', className: classNames(className, 'justui_announcement', type, { dark: dark }) },
        React.createElement(Icon, { wrapperClassName: 'justui_announcement-icon-type', name: iconName || IconByAnnouncementType[type], size: iconSize, color: type }),
        React.createElement("div", { className: 'justui_announcement-text' },
            React.createElement(AnnouncementText, null)),
        action && (React.createElement("div", { className: 'justui_announcement-buttons' }, Array.isArray(action) ? (action.map(actionItem => (React.createElement(Button, { key: `Announcement-button-${actionItem.text}`, size: 'sm', color: type, onClick: actionItem.callback }, actionItem.text)))) : (React.createElement(Button, { size: 'sm', color: type, onClick: action.callback }, action.text)))),
        onClose && (React.createElement("div", { className: 'justui_announcement-close', "data-test-id": 'Announcement.CloseButton', onClick: onClose },
            React.createElement(Icon, { name: 'faTimes', color: 'secondary', size: 'sm' })))));
}
