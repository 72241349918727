import React, { Component, createContext, useCallback, useContext, useRef } from 'react';
const Localize = require('localize');
export const TranslationContext = createContext({});
export const TranslationContextProvider = ({ children, t, tWithCheck, getLocale }) => {
    return React.createElement(TranslationContext.Provider, { value: { t, tWithCheck, getLocale } }, children);
};
export const TranslationContextCustomLocalization = ({ localizationObject = {}, children }) => {
    const { t, tWithCheck, getLocale } = useTranslation();
    const localizationInstance = useRef(new Localize(localizationObject));
    const tLocal = useCallback((code, ...args) => {
        localizationInstance.current.setLocale(getLocale());
        if (localizationInstance.current.translate(code) !== code) {
            return localizationInstance.current.translate(code, ...args);
        }
        return t(code, ...args);
    }, [getLocale, t]);
    return (React.createElement(TranslationContext.Provider, { value: { t: tLocal, tWithCheck, getLocale } }, children));
};
export const useTranslation = () => useContext(TranslationContext);
export function withTranslation(t) {
    return function (WrappedComponent) {
        var _a;
        return _a = class ComponentWithTranslation extends Component {
                render() {
                    return React.createElement(WrappedComponent, Object.assign({}, this.props, { t: t }));
                }
            },
            _a.displayName = `withTranslation(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`,
            _a;
    };
}
export function withTranslationContext(WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const Component = (props) => {
        const { t } = useTranslation();
        return React.createElement(WrappedComponent, Object.assign({}, props, { t: t }));
    };
    Component.displayName = `withTranslationContext(${displayName})`;
    return Component;
}
