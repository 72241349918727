var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { PureComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import VirtualList from 'react-tiny-virtual-list';
import ResizeObserver from 'react-resize-observer';
import { isEqual } from 'lodash';
import Leaf from './NodesView/Leaf';
import Branch from './NodesView/Branch';
import styles from './styles.module.scss';
import DragArea from './NodesView/DragArea';
import KeyboardService from '../../../../services/KeyboardService';
import { TreeSelectionLogicState } from '../../../../views/FAQTreePage/components/Tree/TreeSelectionLogicState';
import { FAQIntentService } from '../../../../services/FAQIntentService';
import cn from 'classnames';
import { withTranslationContext } from '@just-ai/just-ui';
export var TREE_DEPTH_PADDING = 24;
export var TREE_NODE_PADDING = 16;
VirtualList.prototype.scrollTo = function (value) {
    // @ts-ignore
    this.rootNode.scrollTo({ top: value, behavior: 'smooth' });
};
export var TreeContext = React.createContext({
    expandedMap: {},
    selectedIds: [],
    onExpandToggle: function () { },
    onSelectToggle: function () { },
    maxDepthLevel: Infinity,
    onDragDrop: function () { },
    handleContextMenu: function () { },
    setEditId: function () { },
    editId: undefined,
    onRename: function () { return Promise.resolve(); },
    setError: function (error) { },
    hasError: false,
});
var Tree = /** @class */ (function (_super) {
    __extends(Tree, _super);
    function Tree(props) {
        var _this = _super.call(this, props) || this;
        _this.shiftPressed = false;
        _this.ctrlOrCmdPressed = false;
        _this.lastSelectedId = null;
        _this.lastShiftSelectedIds = [];
        _this.state = {
            selectedIds: [],
            expandedMap: {},
            editId: undefined,
            containerHeight: 0,
            preventSelections: false,
        };
        _this.bindKeys = function () {
            _this.keyboardService.bind('shift', function () {
                _this.shiftPressed = true;
                _this.setState({ preventSelections: _this.shiftPressed });
            }, function () {
                _this.shiftPressed = false;
                _this.setState({ preventSelections: _this.shiftPressed });
            });
            _this.keyboardService.bind(['ctrl', 'command'], function () {
                _this.ctrlOrCmdPressed = true;
            }, function () {
                _this.ctrlOrCmdPressed = false;
            });
        };
        _this.unbindKeys = function () {
            _this.keyboardService.unbindAll();
            _this.shiftPressed = false;
            _this.ctrlOrCmdPressed = false;
        };
        _this.onSelectToggle = function (node) {
            if (!_this.props.treeLogicState)
                return;
            var selectedIds = _this.state.selectedIds;
            if (_this.ctrlOrCmdPressed) {
                var _a = _this.selectionState.calculateSelectWithCtrl(node, selectedIds, _this.lastSelectedId), newSelectedIds_1 = _a.selectedIds, lastSelectedId_1 = _a.lastSelectedId;
                _this.lastSelectedId = lastSelectedId_1;
                _this.setState({
                    selectedIds: newSelectedIds_1,
                });
                return;
            }
            if (_this.shiftPressed) {
                var _b = _this.selectionState.calculateSelectWithShift(node, selectedIds, _this.lastSelectedId, _this.lastShiftSelectedIds), newSelectedIds_2 = _b.selectedIds, lastSelectedId_2 = _b.lastSelectedId, lastShiftSelectedIds = _b.lastShiftSelectedIds;
                _this.lastSelectedId = lastSelectedId_2;
                _this.lastShiftSelectedIds = lastShiftSelectedIds;
                _this.setState({
                    selectedIds: newSelectedIds_2,
                });
                return;
            }
            if (!node.isFolder) {
                _this.props.onSelectOne(node);
            }
            var _c = _this.selectionState.calculateSelect(node), newSelectedIds = _c.selectedIds, lastSelectedId = _c.lastSelectedId;
            _this.lastSelectedId = lastSelectedId;
            _this.setState({
                selectedIds: newSelectedIds,
            });
        };
        _this.onExpandToggle = function (node) {
            var _a;
            var isExpanded = _this.state.expandedMap[node.id];
            _this.setState({
                expandedMap: __assign(__assign({}, _this.state.expandedMap), (_a = {}, _a[node.id] = !isExpanded, _a)),
            });
        };
        _this.onDragDrop = function (draggedNode, dropTargetNode) {
            if (_this.props.isSearchActive)
                return;
            var selectedNodes = _this.getSelectedLeafNodesForDnd(draggedNode);
            if (dropTargetNode.isFolder) {
                _this.props.onMoveQuestionInFolder(dropTargetNode, selectedNodes);
                return;
            }
            if (dropTargetNode.parentId) {
                var parentNode = _this.props.treeLogicState.getElementById(dropTargetNode.parentId);
                if (!parentNode) {
                    console.error("Not found treeNode by id = \"".concat(dropTargetNode.parentId, "\""));
                    return;
                }
                _this.props.onMoveQuestionInFolder(parentNode, selectedNodes);
                return;
            }
            if (draggedNode.id !== dropTargetNode.id) {
                _this.props.onCreateFolderWithNodes(__spreadArray(__spreadArray([], selectedNodes, true), [dropTargetNode], false));
            }
            return;
        };
        _this.onDropInRoot = function (draggedNode) {
            var selectedNodes = _this.getSelectedLeafNodesForDnd(draggedNode);
            _this.props.onDropInRoot(selectedNodes);
        };
        _this.handleContextMenu = function (e, node) {
            var selectedNodes = _this.getSelectedNodesBy(node);
            _this.props.handleContextMenu(e, node, selectedNodes);
        };
        _this.recalculateContainerHeight = function () {
            var _a;
            var clientRect = (_a = _this.containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (!clientRect)
                return;
            _this.setState({
                containerHeight: clientRect.height,
            });
        };
        _this.getStubElement = function (type) {
            return {
                nodeId: type === 'group' ? 'groupTitle' : 'questionTitle',
                name: type === 'group' ? 'FAQ:Search:Group' : 'FAQ:Search:Question',
                id: -1,
                children: [],
                enabled: false,
                path: '/Root',
                isFolder: false,
                isInRoot: true,
                parent: '',
                parentId: 0,
            };
        };
        _this.getSearchCategoryName = function (nodesArr, originalNodes, node) {
            if (!_this.props.isSearchActive || node.isFolder || FAQIntentService.isInRoot(node.path))
                return '';
            var haveDuplicate = nodesArr.find(function (item) { return item.name === node.name && item.id !== node.id; });
            if (haveDuplicate) {
                var originNode = originalNodes.find(function (item) { return item.id === node.id && node.isInRoot; });
                return (originNode === null || originNode === void 0 ? void 0 : originNode.path.split('/')[1]) || '';
            }
            return '';
        };
        _this.getSearchNodes = function (type) {
            var groupNodes = _this.props.searchTrees[type].getViewedTreeAsPlainList(_this.state.expandedMap);
            if (groupNodes.length > 0) {
                return __spreadArray([_this.getStubElement(type)], groupNodes, true);
            }
            return [];
        };
        _this.containerRef = React.createRef();
        _this.keyboardService = new KeyboardService();
        _this.scrollRef = React.createRef();
        _this.selectionState = new TreeSelectionLogicState(_this.props.treeLogicState);
        return _this;
    }
    Tree.prototype.componentDidMount = function () {
        this.bindKeys();
        this.recalculateContainerHeight();
    };
    Tree.prototype.componentWillUnmount = function () {
        this.unbindKeys();
    };
    Tree.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.selectedId && this.props.selectedId !== prevProps.selectedId) {
            this.lastSelectedId = this.props.selectedId;
            var newState = {
                activeId: this.props.selectedId,
                selectedIds: [this.props.selectedId],
                expandedMap: this.state.expandedMap,
            };
            this.setState(newState);
        }
        if (this.props.isSearchActive && !prevProps.isSearchActive)
            this.unbindKeys();
        if (!this.props.isSearchActive && prevProps.isSearchActive)
            this.bindKeys();
        if (isEqual(prevProps.treeLogicState, this.props.treeLogicState)) {
            this.selectionState = new TreeSelectionLogicState(this.props.treeLogicState);
        }
    };
    Tree.prototype.getSelectedLeafNodesForDnd = function (node) {
        var _this = this;
        var isDraggedNodeIsSelected = this.state.selectedIds.includes(node.id);
        if (!isDraggedNodeIsSelected) {
            return [node];
        }
        return this.state.selectedIds
            .map(function (id) { return Object.values(_this.props.treeLogicState.dataset).find(function (node) { return node.id === id; }); })
            .filter(function (node) { return !node || !node.isFolder; });
    };
    Tree.prototype.getSelectedNodesBy = function (node) {
        var _this = this;
        var isDraggedNodeIsSelected = this.state.selectedIds.includes(node.id);
        if (!isDraggedNodeIsSelected) {
            return [node];
        }
        return this.state.selectedIds
            .map(function (id) { return Object.values(_this.props.treeLogicState.getViewedTreeAsPlainList()).find(function (node) { return node.id === id; }); })
            .filter(Boolean);
    };
    Tree.prototype.getItemHeight = function (node) {
        var childrenCount = node.children.length;
        if (childrenCount === 0 && this.state.expandedMap[node.id]) {
            return 45 * 2;
        }
        return 45;
    };
    Tree.prototype.setupScrollActions = function (nodes) {
        var _this = this;
        var indexMap = [];
        nodes.forEach(function (node, index) {
            var containIds = node.containIds;
            if (containIds) {
                indexMap.push([index, containIds]);
            }
        });
        indexMap.sort(function (a, b) { return a[1].size - b[1].size; });
        this.props.treeScroll.scrollToNode = function (id) {
            var _a, _b;
            var mapElement = indexMap.find(function (el) { return el[1].has(id); });
            if (!mapElement)
                return;
            (_a = _this.scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo((_b = _this.scrollRef.current) === null || _b === void 0 ? void 0 : _b.getOffsetForIndex(mapElement[0]));
        };
    };
    Tree.prototype.renderVirtualList = function () {
        var _a;
        var _this = this;
        if (!this.props.treeLogicState)
            return;
        var selectedIdsSet = new Set(this.state.selectedIds);
        var nodes = !!this.props.searchValue
            ? __spreadArray(__spreadArray([], this.getSearchNodes('group'), true), this.getSearchNodes('answer'), true) : this.props.treeLogicState.getViewedTreeAsPlainList(this.state.expandedMap);
        var initialNodesCopy = this.props.treeLogicState.getViewedTreeAsPlainList(this.state.expandedMap);
        this.setupScrollActions(nodes);
        return (React.createElement(VirtualList, { itemCount: nodes.length, height: this.state.containerHeight, className: cn('just-ui-custom-scrollbar just-ui-custom-scrollbar_black', (_a = {},
                _a[styles.noselect] = this.state.preventSelections,
                _a)), itemSize: function (index) { return _this.getItemHeight(nodes[index]); }, scrollToAlignment: 'center', width: '100%', ref: this.scrollRef, renderItem: function (itemInfo) {
                var _a;
                var node = nodes[itemInfo.index];
                var nodeBeforeSearchIndex = initialNodesCopy.findIndex(function (nodeBeforeSearch) { return nodeBeforeSearch.nodeId === node.nodeId; });
                if (node.nodeId === 'groupTitle' || node.nodeId === 'questionTitle') {
                    return (React.createElement("div", { style: itemInfo.style, key: node.nodeId, className: styles.searchTitle, "data-test-id": node.nodeId }, _this.props.t(node.name)));
                }
                return (React.createElement("div", { style: itemInfo.style, key: node.nodeId },
                    node.isFolder ? (React.createElement(React.Fragment, null,
                        React.createElement(Branch, { node: node, isEdit: _this.props.editId === node.id, searchValue: _this.props.searchValue, initialChildrenLength: (_a = initialNodesCopy[nodeBeforeSearchIndex]) === null || _a === void 0 ? void 0 : _a.children.length, toggleSearchMode: _this.props.toggleSearchMode, isSearchActive: _this.props.isSearchActive }))) : (React.createElement(Leaf, { node: node, isParentSelected: !!(node.parentId && selectedIdsSet.has(node.parentId)), categoryName: _this.getSearchCategoryName(nodes, initialNodesCopy, node), isSearchActive: _this.props.isSearchActive, isDndEnabled: !_this.props.isUploadModalOpen && !_this.props.writeDisabled })),
                    !_this.props.writeDisabled && React.createElement(DragArea, { onDrop: _this.onDropInRoot })));
            } }));
    };
    Tree.prototype.render = function () {
        var _a = this.props, setEditId = _a.setEditId, onRename = _a.onRename, setError = _a.setError, hasError = _a.hasError;
        return (React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(TreeContext.Provider, { value: {
                    activeId: this.state.activeId,
                    selectedIds: this.state.selectedIds,
                    expandedMap: this.state.expandedMap,
                    onExpandToggle: this.onExpandToggle,
                    onSelectToggle: this.onSelectToggle,
                    setEditId: setEditId,
                    editId: this.state.editId,
                    maxDepthLevel: this.props.treeLogicState.maxDepthLevel,
                    onDragDrop: this.onDragDrop,
                    onRename: onRename,
                    handleContextMenu: this.handleContextMenu,
                    setError: setError,
                    hasError: hasError,
                } },
                React.createElement("div", { ref: this.containerRef, className: styles.tree },
                    this.renderVirtualList(),
                    React.createElement(ResizeObserver, { onResize: this.recalculateContainerHeight })))));
    };
    return Tree;
}(PureComponent));
export default withTranslationContext(Tree);
