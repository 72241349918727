import { JStateWithId } from '../../../reducers/JGraph.reducer/types';
import { StickerInfo } from '../view/Sticker/types';

export function getMinMaxScreensCoords(screens: JStateWithId[], stickers?: StickerInfo[]) {
  const screensPositionsX = screens.map(screen => screen.x);
  stickers?.forEach(sticker => {
    screensPositionsX.push(sticker.position.x);
  });
  const screensPositionsY = screens.map(screen => screen.y);
  stickers?.forEach(sticker => {
    screensPositionsX.push(sticker.position.y);
  });
  const maxX = Math.max.apply(null, screensPositionsX);
  const minX = Math.min.apply(null, screensPositionsX);
  const minY = Math.min.apply(null, screensPositionsY);
  const maxY = Math.max.apply(null, screensPositionsY);

  return {
    maxX,
    minX,
    minY,
    maxY,
  };
}

export function joinPaths(path1 = '', path2 = '') {
  if (path1.endsWith('/')) {
    path1 = path1.substring(-1, path1.length - 1);
  }
  if (path2.startsWith('/')) {
    path2 = path2.substring(1);
  }
  return path1 + '/' + path2;
}
