// tslint:disable
// @ts-nocheck
/**
 * Botadmin REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var BotCategory;
(function (BotCategory) {
    BotCategory["GAMES"] = "GAMES";
    BotCategory["NEWS"] = "NEWS";
    BotCategory["ECOMMERCE"] = "E_COMMERCE";
    BotCategory["SUPPORT"] = "SUPPORT";
    BotCategory["SMARTHOME"] = "SMART_HOME";
    BotCategory["PRODUCTIVITY"] = "PRODUCTIVITY";
    BotCategory["OTHER"] = "OTHER";
})(BotCategory || (BotCategory = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BotType;
(function (BotType) {
    BotType["CHATBOT"] = "CHAT_BOT";
    BotType["CALLSBOT"] = "CALLS_BOT";
})(BotType || (BotType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var LiveChatType;
(function (LiveChatType) {
    LiveChatType["WEBIM"] = "WEBIM";
    LiveChatType["LIVETEX"] = "LIVETEX";
    LiveChatType["SALESFORCE"] = "SALESFORCE";
    LiveChatType["JIVOSITE"] = "JIVOSITE";
    LiveChatType["OPERATORAPI"] = "OPERATORAPI";
    LiveChatType["CHAT2DESK"] = "CHAT2DESK";
})(LiveChatType || (LiveChatType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ProjectWarning;
(function (ProjectWarning) {
    ProjectWarning["GITHUBWRONGACCOUNTTOKEN"] = "GITHUB_WRONG_ACCOUNT_TOKEN";
})(ProjectWarning || (ProjectWarning = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var RepositoryAuthType;
(function (RepositoryAuthType) {
    RepositoryAuthType["PERSONALUSER"] = "PERSONAL_USER";
    RepositoryAuthType["GUESTUSER"] = "GUEST_USER";
})(RepositoryAuthType || (RepositoryAuthType = {}));
/**
 * BotConfigsApi - axios parameter creator
 * @export
 */
export var BotConfigsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAllLivechatConfigsByAccountId.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/configs/livechat".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BotConfigsApi - functional programming interface
 * @export
 */
export var BotConfigsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId: function (accountId, options) {
            var localVarAxiosArgs = BotConfigsApiAxiosParamCreator(configuration).getAllLivechatConfigsByAccountId(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BotConfigsApi - factory interface
 * @export
 */
export var BotConfigsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId: function (accountId, options) {
            return BotConfigsApiFp(configuration).getAllLivechatConfigsByAccountId(accountId, options)(axios, basePath);
        },
    };
};
/**
 * BotConfigsApi - object-oriented interface
 * @export
 * @class BotConfigsApi
 * @extends {BaseAPI}
 */
var BotConfigsApi = /** @class */ (function (_super) {
    __extends(BotConfigsApi, _super);
    function BotConfigsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get all livechat configs by accountId
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotConfigsApi
     */
    BotConfigsApi.prototype.getAllLivechatConfigsByAccountId = function (accountId, options) {
        return BotConfigsApiFp(this.configuration).getAllLivechatConfigsByAccountId(accountId, options)(this.axios, this.basePath);
    };
    return BotConfigsApi;
}(BaseAPI));
export { BotConfigsApi };
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export var E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.');
            }
            var localVarPath = "/e2e-check";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export var E2ECheckApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, options) {
            var localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export var E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck: function (loggingEnabledE2ECheck, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
var E2ECheckApi = /** @class */ (function (_super) {
    __extends(E2ECheckApi, _super);
    function E2ECheckApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Botadmin request for end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    E2ECheckApi.prototype.e2ECheck = function (loggingEnabledE2ECheck, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
    };
    return E2ECheckApi;
}(BaseAPI));
export { E2ECheckApi };
/**
 * LabelsApi - axios parameter creator
 * @export
 */
export var LabelsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: function (accountId, logLabelCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createLabel.');
            }
            // verify required parameter 'logLabelCreate' is not null or undefined
            if (logLabelCreate === null || logLabelCreate === undefined) {
                throw new RequiredError('logLabelCreate', 'Required parameter logLabelCreate was null or undefined when calling createLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof logLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
                : logLabelCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup: function (accountId, logLabelGroupCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createLabelGroup.');
            }
            // verify required parameter 'logLabelGroupCreate' is not null or undefined
            if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
                throw new RequiredError('logLabelGroupCreate', 'Required parameter logLabelGroupCreate was null or undefined when calling createLabelGroup.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof logLabelGroupCreate !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
                : logLabelGroupCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel: function (accountId, sessionLabelCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createSessionLabel.');
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError('sessionLabelCreate', 'Required parameter sessionLabelCreate was null or undefined when calling createSessionLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof sessionLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteLabelGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteLabelGroup.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteSessionLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteSessionLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getLabelGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLabelGroup.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getLabelGroups.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getLabels.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getSessionLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getSessionLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getSessionLabels.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority: function (accountId, labelPriorityChangeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateGroupsPriority.');
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
                throw new RequiredError('labelPriorityChangeDto', 'Required parameter labelPriorityChangeDto was null or undefined when calling updateGroupsPriority.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups/change-priority".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof labelPriorityChangeDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: function (accountId, id, logLabelCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateLabel.');
            }
            // verify required parameter 'logLabelCreate' is not null or undefined
            if (logLabelCreate === null || logLabelCreate === undefined) {
                throw new RequiredError('logLabelCreate', 'Required parameter logLabelCreate was null or undefined when calling updateLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof logLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
                : logLabelCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup: function (accountId, id, logLabelGroupCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateLabelGroup.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateLabelGroup.');
            }
            // verify required parameter 'logLabelGroupCreate' is not null or undefined
            if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
                throw new RequiredError('logLabelGroupCreate', 'Required parameter logLabelGroupCreate was null or undefined when calling updateLabelGroup.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof logLabelGroupCreate !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
                : logLabelGroupCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority: function (accountId, labelPriorityChangeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateLogLabelsPriority.');
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
                throw new RequiredError('labelPriorityChangeDto', 'Required parameter labelPriorityChangeDto was null or undefined when calling updateLogLabelsPriority.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/change-priority".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof labelPriorityChangeDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel: function (accountId, id, sessionLabelCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateSessionLabel.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateSessionLabel.');
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError('sessionLabelCreate', 'Required parameter sessionLabelCreate was null or undefined when calling updateSessionLabel.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof sessionLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority: function (accountId, labelPriorityChangeDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateSessionsPriority.');
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
                throw new RequiredError('labelPriorityChangeDto', 'Required parameter labelPriorityChangeDto was null or undefined when calling updateSessionsPriority.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/logs/labels/sessions/change-priority".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof labelPriorityChangeDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LabelsApi - functional programming interface
 * @export
 */
export var LabelsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: function (accountId, logLabelCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabel(accountId, logLabelCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup: function (accountId, logLabelGroupCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabelGroup(accountId, logLabelGroupCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel: function (accountId, sessionLabelCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createSessionLabel(accountId, sessionLabelCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabel(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabelGroup(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteSessionLabel(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabel(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroup(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups: function (accountId, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroups(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels: function (accountId, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabels(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel: function (accountId, id, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabel(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels: function (accountId, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabels(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority: function (accountId, labelPriorityChangeDto, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: function (accountId, id, logLabelCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabel(accountId, id, logLabelCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup: function (accountId, id, logLabelGroupCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority: function (accountId, labelPriorityChangeDto, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel: function (accountId, id, sessionLabelCreate, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority: function (accountId, labelPriorityChangeDto, options) {
            var localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LabelsApi - factory interface
 * @export
 */
export var LabelsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: function (accountId, logLabelCreate, options) {
            return LabelsApiFp(configuration).createLabel(accountId, logLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup: function (accountId, logLabelGroupCreate, options) {
            return LabelsApiFp(configuration).createLabelGroup(accountId, logLabelGroupCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel: function (accountId, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).createSessionLabel(accountId, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel: function (accountId, id, options) {
            return LabelsApiFp(configuration).deleteLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup: function (accountId, id, options) {
            return LabelsApiFp(configuration).deleteLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel: function (accountId, id, options) {
            return LabelsApiFp(configuration).deleteSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel: function (accountId, id, options) {
            return LabelsApiFp(configuration).getLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup: function (accountId, id, options) {
            return LabelsApiFp(configuration).getLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups: function (accountId, options) {
            return LabelsApiFp(configuration).getLabelGroups(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels: function (accountId, options) {
            return LabelsApiFp(configuration).getLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel: function (accountId, id, options) {
            return LabelsApiFp(configuration).getSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels: function (accountId, options) {
            return LabelsApiFp(configuration).getSessionLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority: function (accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: function (accountId, id, logLabelCreate, options) {
            return LabelsApiFp(configuration).updateLabel(accountId, id, logLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup: function (accountId, id, logLabelGroupCreate, options) {
            return LabelsApiFp(configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority: function (accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel: function (accountId, id, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority: function (accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
    };
};
/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
var LabelsApi = /** @class */ (function (_super) {
    __extends(LabelsApi, _super);
    function LabelsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create log label
     * @param {number} accountId Account identifier
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.createLabel = function (accountId, logLabelCreate, options) {
        return LabelsApiFp(this.configuration).createLabel(accountId, logLabelCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Create log label group
     * @param {number} accountId Account identifier
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.createLabelGroup = function (accountId, logLabelGroupCreate, options) {
        return LabelsApiFp(this.configuration).createLabelGroup(accountId, logLabelGroupCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.createSessionLabel = function (accountId, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).createSessionLabel(accountId, sessionLabelCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Delete log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.deleteLabel = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteLabel(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.deleteLabelGroup = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteLabelGroup(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.deleteSessionLabel = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteSessionLabel(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get log label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getLabel = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).getLabel(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getLabelGroup = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).getLabelGroup(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getLabelGroups = function (accountId, options) {
        return LabelsApiFp(this.configuration).getLabelGroups(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get log labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getLabels = function (accountId, options) {
        return LabelsApiFp(this.configuration).getLabels(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getSessionLabel = function (accountId, id, options) {
        return LabelsApiFp(this.configuration).getSessionLabel(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.getSessionLabels = function (accountId, options) {
        return LabelsApiFp(this.configuration).getSessionLabels(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateGroupsPriority = function (accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateLabel = function (accountId, id, logLabelCreate, options) {
        return LabelsApiFp(this.configuration).updateLabel(accountId, id, logLabelCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update log label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateLabelGroup = function (accountId, id, logLabelGroupCreate, options) {
        return LabelsApiFp(this.configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update log labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateLogLabelsPriority = function (accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateSessionLabel = function (accountId, id, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    LabelsApi.prototype.updateSessionsPriority = function (accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    };
    return LabelsApi;
}(BaseAPI));
export { LabelsApi };
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export var ProjectsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling _delete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone: function (accountId, projectShortName, name, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling clone.');
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError('projectShortName', 'Required parameter projectShortName was null or undefined when calling clone.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name', 'Required parameter name was null or undefined when calling clone.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject/clone".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter['projectShortName'] = projectShortName;
            }
            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (accountId, botProjectModificationDto, product, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling create.');
            }
            // verify required parameter 'botProjectModificationDto' is not null or undefined
            if (botProjectModificationDto === null || botProjectModificationDto === undefined) {
                throw new RequiredError('botProjectModificationDto', 'Required parameter botProjectModificationDto was null or undefined when calling create.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter['product'] = String(product);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof botProjectModificationDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(botProjectModificationDto !== undefined ? botProjectModificationDto : {})
                : botProjectModificationDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip: function (accountId, product, file, botProjectFile, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createByZip.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject/by-zip".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            var localVarFormParams = new FormData();
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter['product'] = String(product);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (botProjectFile !== undefined) {
                localVarFormParams.append('botProjectFile', botProjectFile);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read: function (accountId, id, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling read.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling read.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll: function (accountId, product, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling readAll.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter['product'] = String(product);
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (accountId, id, botProjectModificationDto, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling update.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'botProjectModificationDto' is not null or undefined
            if (botProjectModificationDto === null || botProjectModificationDto === undefined) {
                throw new RequiredError('botProjectModificationDto', 'Required parameter botProjectModificationDto was null or undefined when calling update.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/botproject/{id}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('id', "}"), encodeURIComponent(String(id)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof botProjectModificationDto !== 'string' ||
                localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(botProjectModificationDto !== undefined ? botProjectModificationDto : {})
                : botProjectModificationDto || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProjectsApi - functional programming interface
 * @export
 */
export var ProjectsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (accountId, id, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration)._delete(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone: function (accountId, projectShortName, name, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).clone(accountId, projectShortName, name, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (accountId, botProjectModificationDto, product, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).create(accountId, botProjectModificationDto, product, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip: function (accountId, product, file, botProjectFile, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createByZip(accountId, product, file, botProjectFile, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read: function (accountId, id, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).read(accountId, id, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll: function (accountId, product, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).readAll(accountId, product, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (accountId, id, botProjectModificationDto, options) {
            var localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).update(accountId, id, botProjectModificationDto, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProjectsApi - factory interface
 * @export
 */
export var ProjectsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: function (accountId, id, options) {
            return ProjectsApiFp(configuration)._delete(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone: function (accountId, projectShortName, name, options) {
            return ProjectsApiFp(configuration).clone(accountId, projectShortName, name, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: function (accountId, botProjectModificationDto, product, options) {
            return ProjectsApiFp(configuration).create(accountId, botProjectModificationDto, product, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip: function (accountId, product, file, botProjectFile, options) {
            return ProjectsApiFp(configuration).createByZip(accountId, product, file, botProjectFile, options)(axios, basePath);
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read: function (accountId, id, options) {
            return ProjectsApiFp(configuration).read(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll: function (accountId, product, options) {
            return ProjectsApiFp(configuration).readAll(accountId, product, options)(axios, basePath);
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: function (accountId, id, botProjectModificationDto, options) {
            return ProjectsApiFp(configuration).update(accountId, id, botProjectModificationDto, options)(axios, basePath);
        },
    };
};
/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
var ProjectsApi = /** @class */ (function (_super) {
    __extends(ProjectsApi, _super);
    function ProjectsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Delete the bot project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype._delete = function (accountId, id, options) {
        return ProjectsApiFp(this.configuration)._delete(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Clone the bot project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} name Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.clone = function (accountId, projectShortName, name, options) {
        return ProjectsApiFp(this.configuration).clone(accountId, projectShortName, name, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Create a project
     * @param {number} accountId Account identifier
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.create = function (accountId, botProjectModificationDto, product, options) {
        return ProjectsApiFp(this.configuration).create(accountId, botProjectModificationDto, product, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Create a bot project from zip
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {any} [file]
     * @param {any} [botProjectFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.createByZip = function (accountId, product, file, botProjectFile, options) {
        return ProjectsApiFp(this.configuration).createByZip(accountId, product, file, botProjectFile, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.read = function (accountId, id, options) {
        return ProjectsApiFp(this.configuration).read(accountId, id, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Get all bot projects
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.readAll = function (accountId, product, options) {
        return ProjectsApiFp(this.configuration).readAll(accountId, product, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    ProjectsApi.prototype.update = function (accountId, id, botProjectModificationDto, options) {
        return ProjectsApiFp(this.configuration).update(accountId, id, botProjectModificationDto, options)(this.axios, this.basePath);
    };
    return ProjectsApi;
}(BaseAPI));
export { ProjectsApi };
/**
 * SystemApi - axios parameter creator
 * @export
 */
export var SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            if (options === void 0) { options = {}; }
            var localVarPath = "/healthCheck";
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export var SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            var localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export var SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: function (options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
var SystemApi = /** @class */ (function (_super) {
    __extends(SystemApi, _super);
    function SystemApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Botadmin healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    SystemApi.prototype.healthCheck = function (options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    };
    return SystemApi;
}(BaseAPI));
export { SystemApi };
/**
 * TagsApi - axios parameter creator
 * @export
 */
export var TagsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount: function (accountId, tagLabelCreate, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling createTagForAccount.');
            }
            // verify required parameter 'tagLabelCreate' is not null or undefined
            if (tagLabelCreate === null || tagLabelCreate === undefined) {
                throw new RequiredError('tagLabelCreate', 'Required parameter tagLabelCreate was null or undefined when calling createTagForAccount.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/tags".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof tagLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tagLabelCreate !== undefined ? tagLabelCreate : {})
                : tagLabelCreate || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount: function (accountId, tagId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling deleteTagForAccount.');
            }
            // verify required parameter 'tagId' is not null or undefined
            if (tagId === null || tagId === undefined) {
                throw new RequiredError('tagId', 'Required parameter tagId was null or undefined when calling deleteTagForAccount.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/tags/{tagId}"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('tagId', "}"), encodeURIComponent(String(tagId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount: function (accountId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAllTagsForAccount.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/tags".replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject: function (accountId, projectShortName, requestBody, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling updateTagsForProject.');
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError('projectShortName', 'Required parameter projectShortName was null or undefined when calling updateTagsForProject.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody', 'Required parameter requestBody was null or undefined when calling updateTagsForProject.');
            }
            var localVarPath = "/api/botadmin/accounts/{accountId}/project/{projectShortName}/tags"
                .replace("{".concat('accountId', "}"), encodeURIComponent(String(accountId)))
                .replace("{".concat('projectShortName', "}"), encodeURIComponent(String(projectShortName)));
            var localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            var baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            var localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                var accessToken = typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
                localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = __assign(__assign(__assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = __assign(__assign({}, localVarHeaderParameter), options.headers);
            var needsSerialization = typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || '';
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TagsApi - functional programming interface
 * @export
 */
export var TagsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount: function (accountId, tagLabelCreate, options) {
            var localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).createTagForAccount(accountId, tagLabelCreate, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount: function (accountId, tagId, options) {
            var localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).deleteTagForAccount(accountId, tagId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount: function (accountId, options) {
            var localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).getAllTagsForAccount(accountId, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject: function (accountId, projectShortName, requestBody, options) {
            var localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).updateTagsForProject(accountId, projectShortName, requestBody, options);
            return function (axios, basePath) {
                if (axios === void 0) { axios = globalAxios; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                var axiosRequestArgs = __assign(__assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TagsApi - factory interface
 * @export
 */
export var TagsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount: function (accountId, tagLabelCreate, options) {
            return TagsApiFp(configuration).createTagForAccount(accountId, tagLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount: function (accountId, tagId, options) {
            return TagsApiFp(configuration).deleteTagForAccount(accountId, tagId, options)(axios, basePath);
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount: function (accountId, options) {
            return TagsApiFp(configuration).getAllTagsForAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject: function (accountId, projectShortName, requestBody, options) {
            return TagsApiFp(configuration).updateTagsForProject(accountId, projectShortName, requestBody, options)(axios, basePath);
        },
    };
};
/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
var TagsApi = /** @class */ (function (_super) {
    __extends(TagsApi, _super);
    function TagsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create tag for account
     * @param {number} accountId Account identifier
     * @param {TagLabelCreate} tagLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    TagsApi.prototype.createTagForAccount = function (accountId, tagLabelCreate, options) {
        return TagsApiFp(this.configuration).createTagForAccount(accountId, tagLabelCreate, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Delete tag for account
     * @param {number} accountId Account identifier
     * @param {number} tagId Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    TagsApi.prototype.deleteTagForAccount = function (accountId, tagId, options) {
        return TagsApiFp(this.configuration).deleteTagForAccount(accountId, tagId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Getting all tags for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    TagsApi.prototype.getAllTagsForAccount = function (accountId, options) {
        return TagsApiFp(this.configuration).getAllTagsForAccount(accountId, options)(this.axios, this.basePath);
    };
    /**
     *
     * @summary Update tags for project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    TagsApi.prototype.updateTagsForProject = function (accountId, projectShortName, requestBody, options) {
        return TagsApiFp(this.configuration).updateTagsForProject(accountId, projectShortName, requestBody, options)(this.axios, this.basePath);
    };
    return TagsApi;
}(BaseAPI));
export { TagsApi };
