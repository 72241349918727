import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Spinner } from '../Spinner';
import { isSelected, shouldShowOptionsList } from './utils';
import { MessagesTypes } from './types';
import { JustSelectListPosition } from './JustSelectListPosition';
export const JustSelectList = React.forwardRef(({ submitTimeout, listStyles, selfStyles, maxSelected, selected, getScrollBar, innerOptions, options, multiple, searchValue, onSelectHandler, onHoverHandler, ComponentSelectItem, focusedElement, messages, opened, dropdownPosition, position, classNameForList, wrapperRef, listAutoPosition, size, dataTestId, isLoading, }, ref) => {
    if (listAutoPosition) {
        return (React.createElement(JustSelectListPosition, { position: position, dropdownPosition: dropdownPosition, selected: selected, maxSelected: maxSelected, wrapperRef: wrapperRef, ref: ref, listStyles: listStyles, opened: opened, size: size, classNameForList: classNameForList },
            React.createElement(SelectListChildren, { ComponentSelectItem: ComponentSelectItem, getScrollBar: getScrollBar, submitTimeout: submitTimeout, innerOptions: innerOptions, options: options, selected: selected, multiple: multiple, searchValue: searchValue, onSelectHandler: onSelectHandler, focusedElement: focusedElement, onHoverHandler: onHoverHandler, messages: messages, isLoading: isLoading, dataTestId: dataTestId })));
    }
    return (React.createElement(WithoutAutoposition, { ref: ref, selected: selected, selfStyles: selfStyles, opened: opened, dropdownPosition: dropdownPosition, position: position, maxSelected: maxSelected, classNameForList: classNameForList, listStyles: listStyles, childrenProps: {
            ComponentSelectItem,
            getScrollBar,
            submitTimeout,
            innerOptions,
            options,
            multiple,
            searchValue,
            onSelectHandler,
            focusedElement,
            onHoverHandler,
            messages,
        }, size: size, isLoading: isLoading, dataTestId: dataTestId }));
});
const WithoutAutoposition = React.forwardRef(({ opened, position, dropdownPosition, maxSelected, selected, classNameForList, listStyles, selfStyles, size, childrenProps, isLoading = false, dataTestId = '', }, ref) => {
    const { ComponentSelectItem, getScrollBar, submitTimeout, innerOptions, options, multiple, searchValue, onSelectHandler, focusedElement, onHoverHandler, messages, } = childrenProps;
    const documentBodyOverflow = useRef(document.body.style.overflow || 'auto');
    useEffect(() => {
        if (opened && position === 'fixed')
            document.body.style.overflow = 'hidden';
    }, [opened, position]);
    useEffect(() => {
        const documentBodyOverflowValue = documentBodyOverflow.current;
        return () => {
            document.body.style.overflow = documentBodyOverflowValue;
        };
    }, [position]);
    return (React.createElement("div", { className: classNames(`just-select-list ${dropdownPosition}`, { xs: size === 'xs' }, { disabled: maxSelected === selected.length, opened: opened }, { fixed: position === 'fixed' }, classNameForList), "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select-list`, style: Object.assign(Object.assign({}, listStyles), selfStyles), tabIndex: 1, ref: ref },
        React.createElement(SelectListChildren, { ComponentSelectItem: ComponentSelectItem, getScrollBar: getScrollBar, submitTimeout: submitTimeout, innerOptions: innerOptions, options: options, selected: selected, multiple: multiple, searchValue: searchValue, onSelectHandler: onSelectHandler, focusedElement: focusedElement, onHoverHandler: onHoverHandler, messages: messages, isLoading: isLoading })));
});
const SelectListChildren = ({ ComponentSelectItem, getScrollBar, submitTimeout, innerOptions, options, selected, multiple, searchValue, onSelectHandler, focusedElement, onHoverHandler, messages, isLoading, dataTestId, }) => (React.createElement("div", { className: 'relativeWrapper' },
    React.createElement(PerfectScrollbar, { options: { maxScrollbarLength: 55 }, containerRef: getScrollBar },
        submitTimeout && !submitTimeout.cleared && !submitTimeout.timeouted && !isLoading && options.length > 0 && (React.createElement(ComponentSelectItem, { key: 'options$Loading', value: '', label: React.createElement(Spinner, { inline: true }) })),
        isLoading && React.createElement(ComponentSelectItem, { key: 'options$Loading', value: '', label: React.createElement(Spinner, { inline: true }) }),
        shouldShowOptionsList(innerOptions, options, selected, multiple, searchValue) &&
            !isLoading &&
            innerOptions.map((option, index) => (React.createElement(ComponentSelectItem, Object.assign({ key: `options${option.value}`, onSelect: onSelectHandler, active: isSelected(selected, option), focused: focusedElement === index, onHover: onHoverHandler(index) }, option)))),
        options.length > 0 &&
            submitTimeout &&
            !submitTimeout.cleared &&
            submitTimeout.timeouted &&
            !shouldShowOptionsList(innerOptions, options, selected, multiple, searchValue) &&
            !isLoading && (React.createElement(ComponentSelectItem, { key: 'options$Nothing found', value: messages[MessagesTypes.NOT_FOUND], label: messages[MessagesTypes.NOT_FOUND] })),
        !isLoading && (options === null || options === void 0 ? void 0 : options.length) === 0 && (React.createElement(ComponentSelectItem, { key: 'options$Empty', value: messages[MessagesTypes.EMPTY], label: messages[MessagesTypes.EMPTY] })))));
