import React from 'react';
import './Checkbox.scss';
export default function Checkbox(_a) {
    var onChange = _a.onChange, onClick = _a.onClick, checked = _a.checked, defaultChecked = _a.defaultChecked, name = _a.name, halfChecked = _a.halfChecked;
    return (React.createElement("div", { className: 'justui_checkbox_container' },
        React.createElement("input", { id: name, checked: checked, name: name, onChange: onChange, onClick: onClick, defaultChecked: defaultChecked, type: 'checkbox' }),
        React.createElement("label", { htmlFor: name }, halfChecked ? (React.createElement("svg", { width: '8', height: '3', viewBox: '0 0 8 3', fill: 'none' },
            React.createElement("rect", { y: '0.5', width: '8', height: '2', fill: 'white' }))) : (React.createElement("svg", { width: '10', height: '9', viewBox: '0 0 10 9', fill: 'none' },
            React.createElement("path", { d: 'M2.7602 8.08379L0.533731 5.20248C0.00985587 3.84041 1.13619 3.36892 1.97439 4.15473L3.41504 5.98829L7.86798 1.01148C8.65379 0.186382 9.96348 0.657869 9.4396 1.92826L4.20085 7.95282C3.63769 8.60767 3.1531 8.4767 2.7602 8.08379Z', fill: 'white' }))))));
}
