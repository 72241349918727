var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
export default function useControlledForm(_a) {
    var defaultValues = _a.defaultValues, onChange = _a.onChange, validationResolver = _a.validationResolver;
    var _b = useState(defaultValues), state = _b[0], setState = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState(false), isTouched = _d[0], setTouched = _d[1];
    var resetTo = useCallback(function (data) {
        setErrors({});
        setTouched(false);
        setState(data);
    }, []);
    var onChangeInner = useCallback(function (value, name) {
        if (!name)
            return;
        setTouched(true);
        setState(function (state) {
            var _a;
            var newState = __assign(__assign({}, state), (_a = {}, _a[name] = value, _a));
            var errors = validationResolver(newState);
            setErrors(errors);
            onChange({
                errors: errors,
                isValid: isEmpty(errors),
                data: newState,
            });
            return newState;
        });
    }, [onChange, validationResolver]);
    return { state: state, onChange: onChangeInner, errors: errors, isTouched: isTouched, isValid: isEmpty(errors), resetTo: resetTo };
}
