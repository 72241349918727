var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { PhraseStatusData } from '@just-ai/api/dist/generated/Caila';
export var toPhraseItems = function (allPhrases, allStatuses) {
    return allPhrases.map(function (text, index) { return ({ id: prefixed(index), text: text, status: allStatuses[index] }); });
};
export var toSortedDataset = function (phrases, allPhrases) {
    return phrases
        .map(function (phrase) {
        return toPhrase(allPhrases[Number(phrase.phraseIdx)], phrase.phraseIdx, phrase.confidence, phrase.sessionId);
    })
        .sort(byStatusAndConfidence)
        .reduce(addNode, {});
};
export var sortDataset = function (phrases) {
    return Object.values(phrases).sort(byStatusAndConfidence).reduce(addNode, {});
};
export var toSortedDatasetWithTopN = function (phrases, allPhrases, groups) {
    return phrases
        .map(function (phrase) { return toPhraseWithTopN(phrase, allPhrases, groups); })
        .sort(byStatusAndConfidence)
        .reduce(addNode, {});
};
var toPhraseWithTopN = function (phrase, allPhrases, groups) { return (__assign(__assign({}, allPhrases[Number(phrase.phraseIdx)]), { confidence: phrase.confidence, topN: getTopN(phrase.phraseIdx, groups), sessionId: phrase.sessionId })); };
var getTopN = function (idx, groups) {
    return Object.values(groups)
        .map(function (group) { return ({ groupId: group.nodeId, phrase: group.phrases.find(function (_a) {
            var phraseIdx = _a.phraseIdx;
            return phraseIdx === idx;
        }) }); })
        .filter(function (group) { return group.phrase !== undefined; })
        .map(function (group) { return ({ groupId: group.groupId, confidence: Number(group.phrase.confidence) }); })
        .sort(byConfidence);
};
var toPhrase = function (phrase, index, confidence, originId) { return ({
    id: String(index),
    text: phrase.text,
    status: phrase.status ? phrase.status : PhraseStatusData.P,
    confidence: confidence ? confidence : 0,
    sessionId: originId,
}); };
var updateStatus = function (phrases, id, newStatus) {
    phrases[clearPrefix(id)].status = newStatus;
    return phrases;
};
export var switchStatuses = function (allPhrases, indexes, newStatus) { return indexes.reduce(function (phrases, index) { return updateStatus(phrases, index, newStatus); }, __spreadArray([], allPhrases, true)); };
var applyIfStaged = function (phrase) {
    return phrase.status === PhraseStatusData.S ? __assign(__assign({}, phrase), { status: PhraseStatusData.A }) : __assign({}, phrase);
};
export var applyStaged = function (allPhrases) { return allPhrases.map(applyIfStaged); };
var statusPriority = (_a = {},
    _a[PhraseStatusData.S] = 0,
    _a[PhraseStatusData.P] = 1,
    _a[PhraseStatusData.D] = 2,
    _a[PhraseStatusData.A] = 3,
    _a);
var byStatus = function (a, b) { return statusPriority[a.status] - statusPriority[b.status]; };
var byConfidence = function (a, b) {
    return typeof a.confidence === 'number' && typeof b.confidence === 'number' ? b.confidence - a.confidence : 0;
};
var byStatusAndConfidence = function (a, b) {
    var statusDiff = byStatus(a, b);
    return statusDiff === 0 ? byConfidence(a, b) : statusDiff;
};
var toPhraseWithTopNFrom = function (groups) { return function (phrase) { return (__assign(__assign({}, phrase), { topN: getTopN(clearPrefix(phrase.id), groups) })); }; };
export var isNotLabeled = function (phrase) { return !phrase.status || phrase.status === PhraseStatusData.P; };
var matchesFilter = function (phrase, filter) { return phrase.text.toLowerCase().includes(filter.toLowerCase()); };
export var filterAndOptionallyAddTopN = function (phrases, filter, showLabeled, withTopN, groups) {
    var resultPhrases = phrases;
    if (!showLabeled) {
        resultPhrases = resultPhrases.filter(isNotLabeled);
    }
    if (filter !== '') {
        resultPhrases = resultPhrases.filter(function (phrase) { return matchesFilter(phrase, filter); });
    }
    if (withTopN && groups) {
        resultPhrases = resultPhrases.map(toPhraseWithTopNFrom(groups));
    }
    return resultPhrases.reduce(addNode, {});
};
var isItemOf = function (phrases) {
    return Object.values(phrases).length > 0
        ? function (groupPhrase) { return phrases[prefixed(groupPhrase.phraseIdx)] !== undefined; }
        : function () { return false; };
};
var hasConfidenceAboveOrEqualTo = function (confidenceThreshold) {
    return (confidenceThreshold === null || confidenceThreshold === void 0 ? void 0 : confidenceThreshold.min) > 0 || confidenceThreshold.max < 1
        ? function (phrase) {
            return phrase.confidence &&
                phrase.confidence >= confidenceThreshold.min &&
                phrase.confidence <= confidenceThreshold.max;
        }
        : function () { return true; };
};
export var filterGroupPhrases = function (groupPhrases, phrases, confidenceThreshold) { return groupPhrases.filter(isItemOf(phrases)).filter(hasConfidenceAboveOrEqualTo(confidenceThreshold)); };
export var filterGroupPhrasesAndHideConflicts = function (groupPhrases, phrases, confidenceThreshold, groupId) { return filterGroupPhrases(groupPhrases, phrases, confidenceThreshold).filter(hasTopConfidenceWith(groupId, phrases)); };
export var hasTopConfidenceOrWithoutTopN = function (phrase, groupId) {
    return !phrase.topN || (phrase.topN[0] && phrase.topN[0].groupId === groupId);
};
var hasTopConfidenceWith = function (groupId, phrases) { return function (groupPhrase) {
    return hasTopConfidenceOrWithoutTopN(phrases[prefixed(groupPhrase.phraseIdx)], groupId);
}; };
export var filterPhrases = function (phrases, showLabeled, hideConflicts, filter, confidenceThreshold, currentGroupId) {
    var phrasesList = Object.values(phrases);
    if (!showLabeled) {
        phrasesList = phrasesList.filter(isNotLabeled);
    }
    if (hideConflicts) {
        phrasesList = phrasesList.filter(function (phrase) { return !hasTopConfidenceOrWithoutTopN(phrase, currentGroupId); });
    }
    if (filter !== '') {
        phrasesList = phrasesList.filter(function (phrase) { return matchesFilter(phrase, filter); });
    }
    if (confidenceThreshold) {
        phrasesList = phrasesList.filter(hasConfidenceAboveOrEqualTo(confidenceThreshold));
    }
    return phrasesList.reduce(addNode, {});
};
export var hasNotLabeled = function (dataset) { return Object.values(dataset).some(isNotLabeled); };
export var intentPhrasesToPhrasesDataset = function (intent, allPhrases) {
    if (!intent.phrases)
        return {};
    return intent.phrases
        .map(function (_a) {
        var stagedPhraseIdx = _a.stagedPhraseIdx;
        return stagedPhraseIdx;
    })
        .filter(function (idx) { return typeof idx === 'number'; })
        .map(function (id) { return (__assign({}, allPhrases[Number(id)])); })
        .reduce(addNode, {});
};
var toPhraseItemFrom = function (allPhrases) { return function (groupPhrase) { return (__assign(__assign({}, allPhrases[prefixed(groupPhrase.phraseIdx)]), { confidence: groupPhrase.confidence, sessionId: groupPhrase.sessionId })); }; };
var isInDataset = function (dataset) { return function (groupPhrase) {
    return Boolean(dataset[prefixed(groupPhrase.phraseIdx)]);
}; };
export var selectGroupPhrases = function (groupPhrases, allPhrases) { return groupPhrases.filter(isInDataset(allPhrases)).map(toPhraseItemFrom(allPhrases)).reduce(addNode, {}); };
export var addNode = function (dataset, phrase) {
    var _a;
    return Object.assign(dataset, (_a = {}, _a[prefixed(phrase.id)] = phrase, _a));
};
var ID_PREFIX = 'phrase_';
var isPrefixed = function (id) { return id.startsWith(ID_PREFIX); };
export var prefixed = function (id) {
    return typeof id === 'number' || !isPrefixed(id) ? "".concat(ID_PREFIX).concat(id) : "".concat(id);
};
export var clearPrefix = function (id) { return (isPrefixed(id) ? Number(id.slice(ID_PREFIX.length)) : Number(id)); };
export var getPhrasesCount = function (phrases) {
    return Object.values(phrases).filter(function (phrase) { return phrase.status !== PhraseStatusData.A; }).length;
};
export var formatConfidence = function (confidence) { return (confidence ? confidence.toFixed(2) : '0.00'); };
