import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { FAQApi, IntentsApi, SettingsApi, TrainingApi } from '@just-ai/api/dist/generated/Caila';
import SkillsApiService from '@just-ai/api/dist/services/SkillsApiService';
export var MAX_NETWORK_TIMEOUT = 30000; // 30sec
var CONFIGURATION = {
    basePath: '/cailapub',
    baseOptions: { timeout: MAX_NETWORK_TIMEOUT },
};
export var NLUModuleContext = createContext({});
var NLUModuleContextProviderComponent = React.memo(function (props) {
    var accountId = props.accountId, axiosInstance = props.axiosInstance, projectShortName = props.projectShortName, isAccessFunction = props.isAccessFunction, actions = props.actions, appLogger = props.appLogger, trainingNlu = props.trainingNlu, trainNlu = props.trainNlu, showNluWidget = props.showNluWidget, currentProject = props.currentProject, setCurrentProject = props.setCurrentProject, isWhiteLabel = props.isWhiteLabel, setCurrentProjectToEdit = props.setCurrentProjectToEdit, isContentManager = props.isContentManager, language = props.language, saveAudioToServer = props.saveAudioToServer, saveFileToServer = props.saveFileToServer, validateFile = props.validateFile, isZfl = props.isZfl;
    var history = useHistory();
    var wrappedHistoryPush = useCallback(function (_a) {
        var url = _a.url, skillName = _a.skillName, _b = _a.historyState, historyState = _b === void 0 ? {} : _b;
        if (isZfl)
            return history.push({
                pathname: "/".concat(url, "/").concat(skillName || projectShortName),
                state: historyState,
            });
        return skillName ? history.push("/".concat(skillName, "/").concat(url), historyState) : history.push(url, historyState);
    }, [history, isZfl, projectShortName]);
    return (React.createElement(NLUModuleContext.Provider, { value: {
            accountId: accountId,
            projectShortName: projectShortName,
            axiosInstance: axiosInstance,
            FAQApi: useMemo(function () { return new FAQApi(CONFIGURATION); }, []),
            IntentsApi: useMemo(function () { return new IntentsApi(CONFIGURATION); }, []),
            TrainingApi: useMemo(function () { return new TrainingApi(CONFIGURATION); }, []),
            SettingsApi: useMemo(function () { return new SettingsApi(CONFIGURATION); }, []),
            skillsApiService: useMemo(function () { return new SkillsApiService(accountId, projectShortName, axiosInstance); }, [accountId, axiosInstance, projectShortName]),
            isAccessFunction: isAccessFunction,
            actions: actions,
            appLogger: appLogger,
            trainingNlu: trainingNlu,
            trainNlu: trainNlu,
            showNluWidget: showNluWidget,
            currentProject: currentProject,
            setCurrentProject: setCurrentProject,
            isWhiteLabel: isWhiteLabel,
            setCurrentProjectToEdit: setCurrentProjectToEdit,
            isContentManager: isContentManager,
            language: language,
            saveAudioToServer: saveAudioToServer,
            saveFileToServer: saveFileToServer,
            validateFile: validateFile,
            isZfl: isZfl,
            wrappedHistoryPush: wrappedHistoryPush,
        } }, props.children));
});
export var useNLUModuleContext = function () { return useContext(NLUModuleContext); };
export var NLUModuleContextProvider = NLUModuleContextProviderComponent;
