import React, { useEffect, useState } from 'react';
import { ProgressBar } from '@just-ai/just-ui';
export default function FakeProgressBar(_a) {
    var done = _a.done;
    var _b = useState(1), progress = _b[0], setProgress = _b[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setProgress(function (prevProgress) { return prevProgress + (100 - prevProgress) / 30; });
        }, 300);
        return function () {
            clearInterval(interval);
        };
    }, []);
    useEffect(function () {
        if (progress > 1)
            setProgress(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [done]);
    return React.createElement(ProgressBar, { progress: done ? 100 : progress });
}
