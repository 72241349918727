import { BaseAbstractStorage, StorageResponse } from './BaseAbstractStorage';

type RuleKey = 'includes';
type PermanentStoredRule = string | { rule: RuleKey; value: string };

const defaultRules: PermanentStoredRule[] = [
  'USER_LANGUAGE',
  'SIDEBAR_MIN',
  {
    rule: 'includes',
    value: 'imputer_request_sended_for_',
  },
];

export class LocalStorageService extends BaseAbstractStorage {
  private static _instance: LocalStorageService = new LocalStorageService();
  private permanentStoredRules: Set<PermanentStoredRule> = new Set(defaultRules);
  private readonly permanentStoredRulesKey = 'LocalStorageService:permanentStoredRules';

  constructor() {
    super();
    if (LocalStorageService._instance) return LocalStorageService._instance;
    LocalStorageService._instance = this;
    this.init();
  }

  private async init() {
    const permanentStoredRulesString = await this.get<PermanentStoredRule[]>(this.permanentStoredRulesKey);
    if (!permanentStoredRulesString.success || !permanentStoredRulesString.payload) {
      this.permanentStoredRules = new Set(defaultRules);
      return;
    }
    this.permanentStoredRules = new Set(permanentStoredRulesString.payload);
  }

  set(key: string, value: any, inPermanentStore = false) {
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value) || '');
    if (inPermanentStore) this.addPermanentStoredRules(key);
    return Promise.resolve(this.createSuccess(null));
  }

  get<ELEMENT>(key: string) {
    return new Promise<StorageResponse<ELEMENT | null>>((resolve, reject) => {
      const storedData = localStorage.getItem(key);
      if (!storedData) return resolve(this.createSuccess(null));
      try {
        resolve(this.createSuccess(JSON.parse(storedData) as ELEMENT));
      } catch {
        resolve(this.createSuccess(storedData as unknown as ELEMENT));
      }
    });
  }

  remove(key: string) {
    localStorage.removeItem(key);
    return Promise.resolve(this.createSuccess(null));
  }

  addPermanentStoredRules(key: string) {
    this.permanentStoredRules.add(key);
    this.savePermanentStoredRules();
  }

  savePermanentStoredRules() {
    localStorage.setItem(this.permanentStoredRulesKey, JSON.stringify(Array.from(this.permanentStoredRules.values())));
  }

  clearStorage() {
    const rules = Array.from(this.permanentStoredRules);
    const keysToSave = Object.keys(localStorage).filter(key =>
      rules.find(rule => {
        if (typeof rule === 'object') {
          switch (rule.rule) {
            case 'includes':
              if (key.includes(rule.value)) return true;
              break;
            default:
              break;
          }
        }
        return rule === key;
      })
    );
    const valuesByKeys = keysToSave
      .map(key => ({
        key,
        value: localStorage.getItem(key) || '',
      }))
      .filter(el => el.value);

    localStorage.clear();

    this.savePermanentStoredRules();
    valuesByKeys.forEach(el => localStorage.setItem(el.key, el.value));
  }
}
