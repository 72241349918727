import { useCallback, useEffect, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import keyboardjs from 'keyboardjs';
import { v4 as uuidv4 } from 'uuid';
import { useToggle } from '@just-ai/just-ui';
import { JGraphStickerPosition } from '@just-ai/api/dist/generated/Editorbe';

import { useAppDispatch } from 'storeHooks';

import { updateUsingStageProps } from 'modules/JGraph/utils/connectionLayerUtils';
import { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';
import { getIntersection } from 'modules/JGraph/utils/blockLayerUtils';
import { useStatesStoreRef } from 'modules/JGraph/hooks/useStatesStore';
import { moveStickers, upsertSticker, deleteSticker } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { StickerColorKey } from './colors';
import { StickerInfo } from './types';
import { BehaviorSubject } from 'rxjs';
import { Vector2D } from 'modules/JGraph/utils/2DVector';
import { stateWidth } from 'modules/JGraph/utils/stageUtils';
import { stickerSideSize } from './consts';

const stickersActionsReducer = {
  upsertSticker,
  deleteSticker,
  moveStickers,
};

export function useStickerActions() {
  const observableProps = useStageObservableContext();
  const dispatch = useAppDispatch();

  const createSticker = useCallback(
    (position: { x: number; y: number }, connectedStatePath?: string) =>
      dispatch(
        stickersActionsReducer.upsertSticker({
          id: uuidv4(),
          color: StickerColorKey.YELLOW,
          content: '',
          fontSize: 60,
          position: {
            x: position.x - stickerSideSize.width / 2,
            y: position.y - stickerSideSize.height / 2,
          },
          stagePath: observableProps.selectedGroupPath ?? '',
          connection: connectedStatePath
            ? {
                statePath: connectedStatePath,
                position: 'left',
              }
            : undefined,
        })
      ),
    [dispatch, observableProps.selectedGroupPath]
  );
  const updateSticker = useCallback(
    (sticker: StickerInfo) => dispatch(stickersActionsReducer.upsertSticker(sticker)),
    [dispatch]
  );
  const moveStickers = useCallback(
    (position: { stickerId: string; position: JGraphStickerPosition }[]) =>
      dispatch(stickersActionsReducer.moveStickers(position)),
    [dispatch]
  );
  const deleteSticker = useCallback((id: string) => dispatch(stickersActionsReducer.deleteSticker(id)), [dispatch]);

  return { createSticker, updateSticker, deleteSticker, moveStickers };
}

export type StickerActions = ReturnType<typeof useStickerActions>;

export const StickerCreationMode$ = new BehaviorSubject<{ stickerModeOpened: boolean }>({
  stickerModeOpened: false,
});

export function useStickerCreationMode(stage?: Konva.Stage | null) {
  const stickerActions = useStickerActions();
  const stageRef = useRef(stage);
  stageRef.current = stage;
  const screenSizesMap = useStatesStoreRef();

  const [stickerModeOpened, , closeStickerMode, toggleStickerMode] = useToggle(false);

  useEffect(() => {
    if (!stickerModeOpened || !stageRef.current) return;
    const onClick = (e: KonvaEventObject<any>) => {
      e.cancelBubble = true;
      closeStickerMode();

      if (!stageRef.current) return;
      const pointerPosition = stageRef.current.getPointerPosition();
      let [, toPosition] = updateUsingStageProps(stageRef.current, { x: 0, y: 0 }, pointerPosition || { x: 0, y: 0 });
      const intersection = getIntersection(toPosition, screenSizesMap.current, e.currentTarget);

      if (!intersection) {
        stickerActions.createSticker(toPosition);
        return;
      }
      const position = Vector2D.fromObj(intersection.coords)
        .addX(stateWidth + stickerSideSize.width / 2 + 104)
        .addY(stickerSideSize.height / 2);
      stickerActions.createSticker(position, intersection.statePath);
    };
    stageRef.current.on('click', onClick);
    keyboardjs.on(['esc'], closeStickerMode);
    return () => {
      stageRef.current?.off('click', onClick);
      keyboardjs.off(['esc'], closeStickerMode);
    };
  }, [closeStickerMode, screenSizesMap, stickerActions, stickerActions.createSticker, stickerModeOpened]);

  useEffect(() => {
    StickerCreationMode$.next({ stickerModeOpened });
  }, [stickerModeOpened]);

  return { stickerModeOpened, closeStickerMode, toggleStickerMode };
}
