import React, { useRef, useCallback, useEffect } from 'react';
import VirtualList from 'react-tiny-virtual-list';
import { sum } from 'lodash';
import { useForceUpdate } from '../utils';
import VirtualScrollElementWrapper from './VirtualScrollElementWrapper';
import clamp from '../utils/clamp';
const VirtualListWithDynamicHeight = function ({ itemRender, itemId, itemCount, paddingsHeight = 0, defaultElementHeight, className, initialContainerHeight = 0, containerMaxHeight = Infinity, }, ref) {
    const containerHeight = useRef(initialContainerHeight);
    const forceUpdate = useForceUpdate();
    const sizesMap = useRef([]);
    useEffect(() => {
        if (sizesMap.current.length > itemCount) {
            sizesMap.current = sizesMap.current.slice(0, itemCount);
        }
    }, [itemCount]);
    const onSizeChanged = useCallback((sizes, index) => {
        if (sizesMap.current[index] === sizes.height)
            return;
        sizesMap.current[index] = sizes.height;
        forceUpdate();
    }, [forceUpdate]);
    const render = useCallback(itemInfo => {
        return (React.createElement(VirtualScrollElementWrapper, { key: itemId(itemInfo.index), index: itemInfo.index, style: Object.assign(Object.assign({}, itemInfo.style), { top: itemInfo.style.top - itemInfo.index }), onSizeChanged: onSizeChanged }, itemRender(itemInfo.index)));
    }, [itemId, itemRender, onSizeChanged]);
    containerHeight.current = paddingsHeight + clamp(0, containerMaxHeight)(sum(Object.values(sizesMap.current)));
    return (React.createElement(VirtualList, { ref: ref, itemCount: itemCount, height: containerHeight.current, className: className, itemSize: index => { var _a; return (_a = sizesMap.current[index]) !== null && _a !== void 0 ? _a : defaultElementHeight; }, width: '100%', renderItem: render }));
};
export default React.memo(React.forwardRef(VirtualListWithDynamicHeight));
