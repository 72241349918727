import React, { useState, useCallback } from 'react';
import { Label } from 'reactstrap';
import { InputText, Modal } from '@just-ai/just-ui';

import { t } from 'localization';

import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';

interface CreateStartStateModalInterface {
  isModalOpen: boolean;
  onCreate: (title: string) => void;
  onCancel: () => void;
}
const CreateStartStateModal = function ({ onCreate, isModalOpen, onCancel }: CreateStartStateModalInterface) {
  const [title, setTitle] = useState('');
  const { isInvalid, errorText } = useNewStateNameValidation(title);

  const onCreateInner = useCallback(() => {
    onCreate(title.trim());
  }, [onCreate, title]);

  return (
    <Modal
      isOpen={isModalOpen}
      title={t(`EmptyMode:CreateForm:Title`)}
      buttonSubmitText={t(`EmptyMode:CreateForm:Submit`)}
      buttonSubmitColor='primary'
      buttonCancelOutline
      disableActionButtonAutoFocus
      buttonCancelText={t('EmptyMode:CreateForm:Cancel')}
      buttonCancelColor='secondary'
      buttonSubmitDisabled={isInvalid || !title}
      onCancelClick={onCancel}
      onActionClick={onCreateInner}
      data-test-id='JGraph:NewStartNameModal'
      buttonCancelTestId='JGraph:NewStartNameModal.CancelButton'
      buttonSubmitTestId='JGraph:NewStartNameModal.SubmitButton'
    >
      <div className='flex-column' style={{ color: 'var(--grey-800)' }}>
        <Label for='stateName'>{t('EmptyMode:CreateForm:StartName')}</Label>
        <InputText
          id='stateName'
          bsSize='default'
          errorText={errorText}
          value={title}
          data-test-id='JGraph:Fields:NewStartName'
          placeholder={t('EmptyMode:CreateForm:StartName:Placeholder')}
          onChange={setTitle}
        />
      </div>
      <div className='margin-top-4x'>{t('EmptyMode:CreateForm:Description')}</div>
    </Modal>
  );
};

export default React.memo(CreateStartStateModal);
