var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { generateUniqId } from '../views/FAQTreePage/components/PhrasesBlock/utils';
import { t } from '../localization';
export function getPlural(pluralNumber, plurals) {
    if (pluralNumber === 1)
        return plurals[0];
    if (pluralNumber > 1 && pluralNumber <= 4)
        return plurals[1];
    if (pluralNumber === 0 || pluralNumber > 4)
        return plurals[2];
    return plurals[0];
}
export function downloadTxtFile(text, fileName) {
    var element = document.createElement('a');
    var file = new Blob([text], {
        type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = "".concat(fileName, ".txt");
    document.body.appendChild(element);
    element.click();
}
export var saveFileToServer = function (file, _a, axiosInstance) {
    var signal = _a.signal;
    return __awaiter(void 0, void 0, void 0, function () {
        var fd, payload, payloadUrl;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    fd = new FormData();
                    fd.append('file', file);
                    return [4 /*yield*/, axiosInstance.post('/restapi/file/upload', fd, { signal: signal })];
                case 1:
                    payload = _b.sent();
                    payloadUrl = payload.data;
                    return [2 /*return*/, payloadUrl];
            }
        });
    });
};
export var repliesToAnswerParts = function (replies) {
    var getMainFieldByType = function (reply) {
        switch (reply.type) {
            case 'text':
                return reply.text;
            case 'file':
                return reply.fileUrl;
            case 'audio':
                return reply.audioUrl;
            case 'image':
                return reply.imageUrl;
            default:
                return reply.text;
        }
    };
    return replies.map(function (reply) { return ({
        type: reply.type,
        value: getMainFieldByType(reply) || '',
        audioName: reply.audioName,
        fileName: reply.fileName,
        markup: reply.markup,
        mimeType: reply.mimeType,
        uploadDate: reply.uploadDate,
        id: reply.key || generateUniqId(),
        error: reply.error,
    }); });
};
export var answerPartsToReplies = function (answerParts) {
    var getReplyByAnswerType = function (part) {
        switch (part.type) {
            case 'text':
                return {
                    type: part.type,
                    text: part.value,
                    markup: part.markup,
                    key: part.id,
                };
            case 'file':
                return {
                    type: part.type,
                    fileUrl: part.value,
                    fileName: part.fileName,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            case 'audio':
                return {
                    type: part.type,
                    audioUrl: part.value,
                    audioName: part.audioName || part.fileName,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            case 'image':
                return {
                    type: part.type,
                    imageUrl: part.value,
                    uploadDate: part.uploadDate,
                    key: part.id,
                };
            default:
                return {
                    type: part.type,
                    text: part.value,
                    key: part.id,
                };
        }
    };
    return answerParts.map(function (part) { return getReplyByAnswerType(part); });
};
export var validateUrl = function (value) {
    try {
        new URL(value);
        return true;
    }
    catch (error) {
        return false;
    }
};
var tenMbInBites = 10485760;
export var validateFile = function (type, file) {
    switch (type) {
        case 'image':
            if (file.size > tenMbInBites) {
                throw new Error(t('AudioUploadField:Error:FileSize'));
            }
            if (!file.type.includes('image')) {
                throw new Error(t('AudioUploadField:Error:InvalidImageExtension'));
            }
            return;
        case 'file':
            if (file.size > tenMbInBites) {
                throw new Error(t('AudioUploadField:Error:FileSize'));
            }
            return;
        default:
            break;
    }
};
