var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner, TranslationContextCustomLocalization, useToggle, useTranslation, } from '@just-ai/just-ui';
import { SkillType } from '@just-ai/api/dist/generated/Caila';
import cn from 'classnames';
import { useHistory } from 'react-router';
import FAQApiService from '../../services/FAQApiService';
import { useNLUModuleContext } from '../../context';
import { FAQIntentService } from '../../services/FAQIntentService';
import { useLoading } from '../../nlu-related/utils';
import FAQModal from '../FAQModal';
import { isKnowledgeBaseBeenVisitedLSKey } from '../FAQTreePage/constants';
import FAQTemplateCard from './components/FAQTemplateCard';
import FAQTemplateHeader from './components/FAQTemplateHeader';
import { useToggleSelect, useErrorDirty } from './hooks';
import styles from './styles.module.scss';
import { faqLocalization } from '../../localization/faq.loc';
function FAQTemplate() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useLoading(), isLoading = _h[0], load = _h[1];
    var _j = useNLUModuleContext(), accountId = _j.accountId, projectShortName = _j.projectShortName, IntentsApi = _j.IntentsApi, FAQApi = _j.FAQApi, language = _j.language, skillsApiService = _j.skillsApiService, currentProject = _j.currentProject, axiosInstance = _j.axiosInstance, isAccess = _j.isAccessFunction, wrappedHistoryPush = _j.wrappedHistoryPush, isZfl = _j.isZfl;
    var t = useTranslation().t;
    var faqApiService = useMemo(function () {
        return new FAQApiService(accountId, projectShortName, IntentsApi, FAQApi, axiosInstance);
    }, [accountId, projectShortName, IntentsApi, FAQApi, axiosInstance]);
    var history = useHistory();
    var _k = useState(undefined), templates = _k[0], setTemplates = _k[1];
    var _l = useToggleSelect(undefined), selectedTemplate = _l[0], selectTemplate = _l[1], toggleSelectedTemplate = _l[2];
    var _m = useToggle(false), isModalOpened = _m[0], toggleModalOpened = _m[3];
    var _o = useErrorDirty(selectedTemplate), error = _o[0], setError = _o[1];
    var fullscreenSpinnerOffset = isZfl
        ? '0'
        : window.localStorage.getItem('SIDEBAR_MIN') === 'true'
            ? '-48px'
            : '-250px';
    useEffect(function () {
        load(faqApiService.loadFAQTemplates(language === 'eng' ? 'en' : language).then(function (templates) {
            var _a;
            if (!isAccess('mlp') || isZfl)
                return setTemplates(templates);
            var cdqaTemplate = {
                lang: language,
                name: 'CDQA',
                type: 'cdqa',
                sampleQuestions: [],
                description: (currentProject === null || currentProject === void 0 ? void 0 : currentProject.isCdqaCreated) ? t('FAQ:cdqa:alreadyCreated') : t('FAQ:cdqa:description'),
            };
            sessionStorage.setItem('FAQ_from_root', String((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.addSkill));
            setTemplates(__spreadArray(__spreadArray([], templates, true), [cdqaTemplate], false));
        }));
    }, [
        currentProject === null || currentProject === void 0 ? void 0 : currentProject.isCdqaCreated,
        faqApiService,
        (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.addSkill,
        isAccess,
        isZfl,
        language,
        load,
        t,
    ]);
    var handleSelectFAQ = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var templateName_1;
        return __generator(this, function (_a) {
            if (!selectedTemplate) {
                setError(t('FAQ:SelectTemplate'));
                return [2 /*return*/];
            }
            try {
                if (!selectedTemplate.name)
                    return [2 /*return*/];
                if (selectedTemplate.type === 'faq_from_user_file') {
                    return [2 /*return*/, toggleModalOpened()];
                }
                templateName_1 = selectedTemplate.type === 'empty_template'
                    ? FAQIntentService.generateFAQTemplateName(selectedTemplate.name)
                    : selectedTemplate.name;
                return [2 /*return*/, load(faqApiService
                        .createFAQIntent(templateName_1, "".concat(selectedTemplate.type, "_").concat(selectedTemplate.lang))
                        .then(function (response) {
                        if (!Array.isArray(response)) {
                            return wrappedHistoryPush({
                                url: "faq",
                                historyState: { templateName: response.data },
                                skillName: projectShortName,
                            });
                        }
                        wrappedHistoryPush({
                            url: "faq",
                            historyState: { templateName: templateName_1 },
                            skillName: projectShortName,
                        });
                    })
                        .catch(function (error) {
                        console.error(error);
                        setError(t('FAQ:CreateError'));
                    }))];
            }
            catch (e) {
                console.error(e);
            }
            return [2 /*return*/];
        });
    }); }, [selectedTemplate, setError, t, load, faqApiService, toggleModalOpened, wrappedHistoryPush, projectShortName]);
    var handleUploadFAQ = useCallback(function (file, error, name) { return __awaiter(_this, void 0, void 0, function () {
        var faqName;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, faqApiService.uploadFAQTemplateFile(file, (error === null || error === void 0 ? void 0 : error.type) === 'allowable')];
                case 1:
                    faqName = _a.sent();
                    return [2 /*return*/, faqName];
            }
        });
    }); }, [faqApiService]);
    var handleCreateCDQA = useCallback(function (newData) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!newData.name || !newData.file)
                        return [2 /*return*/];
                    return [4 /*yield*/, skillsApiService.createCdqaSkill(newData.name, newData.file, newData.lang)];
                case 1:
                    _a.sent();
                    toggleModalOpened();
                    localStorage.setItem("".concat(isKnowledgeBaseBeenVisitedLSKey, "-").concat(projectShortName), 'true');
                    wrappedHistoryPush({ url: 'faq' });
                    return [2 /*return*/];
            }
        });
    }); }, [projectShortName, skillsApiService, toggleModalOpened, wrappedHistoryPush]);
    return (React.createElement(React.Fragment, null,
        isLoading && (React.createElement(Spinner, { style: { left: ((_b = history.location.state) === null || _b === void 0 ? void 0 : _b.addSkill) ? fullscreenSpinnerOffset : '0' }, size: '4x' })),
        React.createElement("div", { className: cn(styles.faqPage, 'faq-page'), "data-class-id": 'faqPage' },
            React.createElement("div", { className: cn(styles.faqPage__header, 'faq-page__header') },
                React.createElement("h1", null, t('FAQ:FAQCreation')),
                React.createElement("p", { className: cn(styles.faqPage__description, 'faq-page__description') }, t('FAQ:CreateFAQDescription'))),
            React.createElement("div", { style: { left: ((_c = history.location.state) === null || _c === void 0 ? void 0 : _c.addSkill) ? fullscreenSpinnerOffset : '0' }, className: cn(styles.faqContent, ((_d = history.location.state) === null || _d === void 0 ? void 0 : _d.addSkill) && !isZfl && styles['faqContent--fullscreen']) },
                ((_e = history.location.state) === null || _e === void 0 ? void 0 : _e.addSkill) && !isZfl && React.createElement(FAQTemplateHeader, null),
                React.createElement("div", { className: cn(styles.faqContent__content, ((_f = history.location.state) === null || _f === void 0 ? void 0 : _f.addSkill) && !isZfl && styles['faqContent__content--fullscreen']) },
                    !isZfl && React.createElement("h1", null, t('FAQ:AddNewSkill')),
                    !((_g = history.location.state) === null || _g === void 0 ? void 0 : _g.addSkill) && (React.createElement("p", { className: styles.faqPage__description }, t('FAQ:AddNewSkillDescription'))),
                    React.createElement("div", { className: styles.faqContent__list }, templates === null || templates === void 0 ? void 0 : templates.map(function (template, key) { return (React.createElement(FAQTemplateCard, { className: cn({ 'faq-template-card--active': template === selectedTemplate }), key: key, template: template, selectFAQTemplate: selectTemplate, toggleFAQTemplate: toggleSelectedTemplate, createSkill: handleSelectFAQ, toggleFileImportModal: toggleModalOpened, disabled: template.type === SkillType.Cdqa && (currentProject === null || currentProject === void 0 ? void 0 : currentProject.isCdqaCreated) })); })),
                    React.createElement("div", { className: styles.faqContent__footer }, error && (React.createElement("span", { "data-test-id": 'FAQTemplatePage.SelectTemplate.Error', className: styles.faqContent__error }, error))))),
            React.createElement(FAQModal, { isOpen: isModalOpened, type: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.type) || 'faq_from_user_file', onSubmit: handleUploadFAQ, toggle: function () {
                    toggleModalOpened();
                    toggleSelectedTemplate(undefined);
                }, onCdqaSubmit: handleCreateCDQA }))));
}
var MemoizedFAQTemplate = React.memo(function (_a) {
    var localizationObject = _a.localizationObject;
    return (React.createElement(TranslationContextCustomLocalization, { localizationObject: __assign(__assign({}, faqLocalization), localizationObject) },
        React.createElement(FAQTemplate, null)));
});
MemoizedFAQTemplate.displayName = 'React.memo(FAQTemplate)';
export default MemoizedFAQTemplate;
