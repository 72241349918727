var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import Message from './Message';
import './MessagesList.scss';
import { AnalyticsContext } from '../../../context/AnalyticsContext';
import { labelsReducer } from '../MassAddingLabels';
import MassAddingLabels from '../MassAddingLabels/MassAddingLabels';
import { Checkbox } from '../../Checkbox';
function MessagesList(_a) {
    var _this = this;
    var _b;
    var messages = _a.messages, t = _a.t, history = _a.history, showAdditionalAction = _a.showAdditionalAction, showAnswers = _a.showAnswers, showMarkup = _a.showMarkup, isLoading = _a.isLoading, isSessionMessages = _a.isSessionMessages, compactView = _a.compactView, downloadReportComponent = _a.downloadReportComponent, page = _a.page, questionIdForScroll = _a.questionIdForScroll, totalCount = _a.totalCount, filters = _a.filters, onMassLabelsAddingCallback = _a.onMassLabelsAddingCallback;
    var questionIdForScrollRef = useRef(null);
    var _c = useContext(AnalyticsContext), projectShortName = _c.projectShortName, setLabelToMessages = _c.labelsContext.setLabelToMessages;
    var _d = useReducer(labelsReducer, {
        key: 'SESSION_ID',
        options: [],
        comparisonType: 'none',
    }), labelsState = _d[0], setLabelsDispatcher = _d[1];
    var _e = useState(true), scrollToMessagePage = _e[0], setScrollToMessagePage = _e[1];
    var options = labelsState.options, comparisonType = labelsState.comparisonType;
    var onSubmit = useCallback(function (labels) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setLabelToMessages({
                        //@ts-ignore
                        filters: { filters: filters },
                        labels: labels.map(function (x) { return x.id; }),
                        //@ts-ignore
                        comparisonType: labelsState.comparisonType === 'include' ? 'CONTAINS' : 'NOT_CONTAINS',
                        sessionIds: labelsState.options,
                    })];
                case 1:
                    _a.sent();
                    setLabelsDispatcher({ type: 'reset' });
                    onMassLabelsAddingCallback && onMassLabelsAddingCallback();
                    return [2 /*return*/];
            }
        });
    }); }, [labelsState]);
    useEffect(function () {
        var _a;
        if (!isLoading && questionIdForScrollRef.current && scrollToMessagePage) {
            questionIdForScrollRef.current.style.background = '#F9FAFC';
            (_a = questionIdForScrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            setScrollToMessagePage(false);
        }
    }, [questionIdForScrollRef.current, isLoading]);
    var startTimeDialog = (_b = messages[0]) === null || _b === void 0 ? void 0 : _b.timestamp;
    return (React.createElement("div", { className: 'PhraseList_container' },
        comparisonType !== 'none' && !isSessionMessages && messages.length > 0 && (React.createElement(MassAddingLabels, { labelsState: labelsState, setLabelsDispatcher: setLabelsDispatcher, t: t, totalCount: totalCount, onSubmit: onSubmit })),
        comparisonType === 'none' && !isSessionMessages && messages.length > 0 && (React.createElement("div", { className: 'PhraseList_massAddingCheckbox' },
            React.createElement(Checkbox, { name: 'choose_all_sessions', onChange: function () { return setLabelsDispatcher({ type: 'exclude' }); } }),
            React.createElement("span", null, t('Analytics: choose all labels')))),
        messages.length === 0 && !isLoading ? (React.createElement("div", { style: { padding: 24, paddingBottom: 0 } }, t('Analytics: No messages found'))) : (messages.map(function (item, key) { return (React.createElement(Message, __assign({ questionIdForScrollRef: item.questionId === questionIdForScroll ? questionIdForScrollRef : null, "data-test-id": 'phraseListItem', key: "PhraseListItem_".concat(key), index: page !== undefined ? page * 20 + key + 1 : undefined, startTimeDialog: startTimeDialog }, item, { projectShortName: projectShortName, showAnswers: showAnswers, showAdditionalAction: showAdditionalAction, showMarkup: showMarkup, history: history, isSessionMessages: Boolean(isSessionMessages), downloadReportComponent: downloadReportComponent, compactView: compactView, onChooseForLabels: setLabelsDispatcher, isSelected: options.includes(item.questionId) === (comparisonType === 'exclude' ? false : true), t: t }))); }))));
}
export default MessagesList;
