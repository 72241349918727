var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import './ProgressBar.scss';
const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'wait',
    backgroundColor: "#EDEDED",
    height: '4px',
    borderRadius: '3px',
    overflow: 'hidden'
};
function proceedeProgress(progress) {
    if (typeof progress === 'number') {
        return progress + '%';
    }
    else if (typeof progress === 'string' && progress.includes('%')) {
        return progress;
    }
    else if (typeof progress === 'string') {
        return Number.parseInt(progress) + '%';
    }
    return false;
}
export const ProgressBar = (_a) => {
    var { style, hidden = false, unknownProgress = false, color = getComputedStyle(document.body).getPropertyValue('--primary'), progress } = _a, restProps = __rest(_a, ["style", "hidden", "unknownProgress", "color", "progress"]);
    if (hidden)
        return null;
    const percentage = progress && proceedeProgress(progress);
    return React.createElement("div", Object.assign({ className: "just-ui-progressBar-container", style: Object.assign(Object.assign({}, containerStyle), style), "data-test-id": "progressBar" }, restProps), unknownProgress || !percentage ?
        React.createElement("div", { className: "just-ui-progressBar" })
        : React.createElement("div", { style: { backgroundColor: color, width: percentage, height: '100%', transition: '100ms' } }));
};
