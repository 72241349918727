import React, { Component } from 'react';
import { Modal, Icon, InputText, Textarea, FormFeedback, Spinner, PhoneNumber } from '@just-ai/just-ui';
import { Alert } from 'reactstrap';
import { t } from 'localization';

import { bindActionCreators } from 'redux';
import * as salesRequestActions from '../../actions/salesRequest.actions';
import { connect } from 'react-redux';
import { addMessage } from '../../actions/globalAlert.actions';
import localize from '../../localization';
import { salesrequestmodalLocalization } from './localization/salesrequestmodal.loc';
import { getCountryList } from '../../pipes/functions';
import { isEuroInstance } from '../../isAccessFunction';

localize.addTranslations(salesrequestmodalLocalization);

class SalesRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: { code: props.currentUser?.language === 'RU' ? '7' : '1', number: '' },
      nameValidError: false,
      name: '',
      formLoaded: false,
    };
  }

  form = {
    fullName: React.createRef(),
    email: React.createRef(),
    requestText: React.createRef(),
  };

  componentDidMount() {
    this.props.actions.getSalesRequestData().then(
      ({
        value: {
          data: { requstData },
        },
      }) => {
        const code = requstData.phoneCountryCode?.replace('+', '');

        this.setState({
          name: requstData.fullName,
          phoneNumber: {
            code: code ? code : this.props.currentUser?.language === 'RU' ? '7' : '1',
            number: requstData.phoneNumber || '',
          },
          formLoaded: true,
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentUser?.language !== this.props.currentUser?.language) {
      this.setState({
        phoneNumber: {
          code: this.props.currentUser?.language === 'RU' ? '7' : '1',
          number: prevState.phoneNumber.number,
        },
      });
    }
  }

  nameChange = text => {
    this.setState({
      name: text,
      nameValidError: text === '',
    });
  };

  onChangePhoneNumber = phoneNumber => {
    this.setState({ phoneNumber });
  };

  sendRequest = () => {
    if (this.state.nameValidError) return;

    const dataObj = {
      fullName: this.state.name,
      email: this.form.email.current.value,
      phoneCountryCode: this.state.phoneNumber.code,
      phoneNumber: this.state.phoneNumber.number,
      requestText: this.form.requestText.current.value,
    };
    this.props.actions.sendSalesRequest(dataObj).then(() => this.props.onClose());
  };

  render() {
    const { onClose, fetching, salesRequestData, currentUser, tariffName } = this.props;
    const { phoneNumber, nameValidError, name, formLoaded } = this.state;
    let { type } = this.props;
    type = type ? type : 'default';

    const countryList = getCountryList(currentUser.language, isEuroInstance);

    return (
      <Modal
        isOpen={true}
        size='lg'
        title={t(`SalesRequestModal title ${type}`, t(isEuroInstance() ? 'Tovie AI' : 'Just AI'))}
        onCancelClick={onClose}
        onActionClick={this.sendRequest}
        buttonCancelText={t('Cancel')}
        buttonSubmitText={t('SalesRequestModal ok btn text')}
        buttonCancelOutline
        buttonCancelColor='danger'
        buttonSubmitColor='success'
        disableActionButtonAutoFocus
      >
        {fetching ? <Spinner size='4x' /> : null}
        <Alert color='primary' className='sales-request-modal_description'>
          <Icon name='faInfoCircle' color='primary' />
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                `SalesRequestModal description ${type}`,
                salesRequestData.mailTo,
                t(isEuroInstance() ? 'Tovie AI' : 'Just AI')
              ),
            }}
          />
        </Alert>
        <input type='hidden' value='autocomletename' />
        <input type='hidden' value='autocomleteemail' />
        {formLoaded ? (
          <div className='sales-request-modal_form'>
            <div className='form-group row'>
              <label className='col-md-4 form-control-label' htmlFor='fullName'>
                {t(
                  type === 'developerTariff'
                    ? 'SalesRequestModal label fullName'
                    : 'SalesRequestModal label fullName or IE'
                )}
              </label>
              <div className='col-md-8'>
                <InputText
                  type='text'
                  name='fullName'
                  maxLength='200'
                  clearable
                  value={name}
                  innerRef={this.form.fullName}
                  autoFocus
                  invalid={nameValidError}
                  onChange={this.nameChange}
                  className='skip-readonly'
                  readOnly={true}
                  onClick={e => (e.target.readOnly = false)}
                  onBlur={e => (e.target.readOnly = true)}
                  autoComplete='off'
                />
                {nameValidError ? (
                  <FormFeedback tag='div' valid={false}>
                    {t('SalesRequestModal required text')}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-md-4 form-control-label' htmlFor='email'>
                {t('SalesRequestModal label email')}
              </label>
              <div className='col-md-8'>
                <InputText
                  type='text'
                  name='email'
                  maxLength='200'
                  clearable
                  defaultValue={Boolean(salesRequestData) ? salesRequestData.email : ''}
                  innerRef={this.form.email}
                  className='skip-readonly'
                  readOnly={true}
                  onClick={e => (e.target.readOnly = false)}
                  onBlur={e => (e.target.readOnly = true)}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-md-4 form-control-label' htmlFor='phoneNumber'>
                {t('SalesRequestModal label phoneNumber')}
              </label>
              <div className='col-md-8'>
                <PhoneNumber
                  countryList={countryList}
                  focused
                  onChange={this.onChangePhoneNumber}
                  phoneNumber={phoneNumber}
                  selectFixedPosition
                />
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-md-4 form-control-label' htmlFor='requestText'>
                {t('SalesRequestModal label requestText')}
              </label>
              <div className='col-md-8'>
                <Textarea
                  type='text'
                  name='requestText'
                  maxLength='200'
                  clearable
                  defaultValue={
                    type !== 'default'
                      ? t(
                          `SalesRequestModal query ${type}`,
                          type === 'developerTariff' ? currentUser.account?.id : currentUser.email,
                          tariffName
                        )
                      : ''
                  }
                  innerRef={this.form.requestText}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.SalesRequestReducer.fetching,
    salesRequestData: state.SalesRequestReducer.salesRequestData,
    currentUser: state.CurrentUserReducer.currentUser,
    tariffName: state.AccountManagerReducer.tariffName,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...salesRequestActions, addMessage }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesRequestModal);
