import React from 'react';
import { DefaultList } from './components';
const branchOrLeaf = ({ Leaf, Head, Edit, InnerContainer }, { root, dataset, expanded, selected, depth, itemHeight, editId, onDragndrop, dragndrop }) => {
    const node = dataset[root];
    if (!node)
        return null;
    return node.children ? (React.createElement(Branch, Object.assign({ key: node.nodeId }, {
        Leaf,
        Head,
        Edit,
        InnerContainer,
        dataset,
        root,
        depth,
        expanded,
        selected,
        itemHeight,
        editId,
        dragndrop,
        onDragndrop,
    }))) : editId && editId === node.nodeId ? (React.createElement(Edit, { key: node.nodeId, node: node, depth: depth, isSelected: selected[node.nodeId], itemHeight: itemHeight })) : (React.createElement(Leaf, { key: node.nodeId, node: node, depth: depth, isSelected: selected[node.nodeId], itemHeight: itemHeight }));
};
export const Branch = props => {
    const { style, root, depth, itemHeight, dataset, expanded, selected, Leaf, Head, Edit, InnerContainer, editId } = props;
    const node = dataset[root] || {};
    const children = node.children || [];
    const mapNode = (i, incrementDepth) => branchOrLeaf({ Leaf, Head, Edit, InnerContainer }, { dataset, selected, expanded, root: i, depth: incrementDepth ? depth + 1 : depth, itemHeight, editId });
    switch (true) {
        case !!(children.length && node.name): {
            return (React.createElement(InnerContainer, { style: style, node: node },
                editId && node.nodeId === editId ? (React.createElement(Edit, { node: node, depth: depth, itemHeight: itemHeight, isExpanded: expanded[node.nodeId], isSelected: selected[node.nodeId] })) : (React.createElement(Head, { node: node, depth: depth, itemHeight: itemHeight, isExpanded: expanded[node.nodeId], isSelected: selected[node.nodeId] })),
                children && expanded[node.nodeId] ? (React.createElement(DefaultList, null, children.map(nodeId => mapNode(nodeId, true)))) : null));
        }
        case !!children.length: {
            return (React.createElement(InnerContainer, { style: style, node: node },
                React.createElement(DefaultList, null, children.map(nodeId => mapNode(nodeId)))));
        }
        case !!node.name: {
            return (React.createElement(InnerContainer, { style: style, node: node }, editId && node.nodeId === editId ? (React.createElement(Edit, { node: node, depth: depth, itemHeight: itemHeight, isSelected: selected[node.nodeId] })) : (React.createElement(Leaf, { node: node, depth: depth, itemHeight: itemHeight, isSelected: selected[node.nodeId] }))));
        }
        default: {
            return null;
        }
    }
};
