var AnalyticsActiveFilters = /** @class */ (function () {
    function AnalyticsActiveFilters() {
        var _this = this;
        this.filters = [];
        this.set = function (values) {
            _this.filters = values;
        };
        this.get = function () {
            return _this.filters;
        };
    }
    return AnalyticsActiveFilters;
}());
export { AnalyticsActiveFilters };
