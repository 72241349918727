var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState, useCallback, useEffect } from 'react';
export var useToggle = function (initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    var setTrue = useCallback(function () { return setValue(true); }, []);
    var setFalse = useCallback(function () { return setValue(false); }, []);
    var toggle = useCallback(function () { return setValue(function (value) { return !value; }); }, []);
    return [value, setTrue, setFalse, toggle, setValue];
};
export var useLoading = function () {
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var load = useCallback(function (promise) {
        setLoading(true);
        return promise.finally(function () { return setLoading(false); });
    }, []);
    return [isLoading, load];
};
export var useDebounce = function (state, delay, callback) {
    var _a = useState(state), debouncedState = _a[0], setDebouncedState = _a[1];
    useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedState(state);
            if (callback) {
                callback(state);
            }
        }, delay);
        return function () { return clearTimeout(handler); };
    }, [state, delay, callback]);
    return debouncedState;
};
export function useDeParametrizedFunction(func) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(function () { return func.apply(void 0, args); }, __spreadArray([func], args, true));
}
