var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, IconButton } from '@just-ai/just-ui';
import { LabelsGroups } from './LabelsGroups';
export function LabelsForm(_a) {
    var _this = this;
    var allLabels = _a.allLabels, currentLabels = _a.currentLabels, isOpen = _a.isOpen, t = _a.t, toggle = _a.toggle, open = _a.open, createNewLabelAndAddToMessage = _a.createNewLabelAndAddToMessage, saveLabels = _a.saveLabels, compactView = _a.compactView;
    var _b = useState(false), openLabels = _b[0], setOpenLabels = _b[1];
    var onChangeLabels = useCallback(function (label) {
        var newState;
        if (currentLabels.find(function (x) { return x.id === label.id; })) {
            newState = currentLabels.filter(function (x) { return x.id != label.id; });
        }
        else {
            newState = __spreadArray(__spreadArray([], currentLabels, true), [label], false);
        }
        saveLabels(newState);
    }, [saveLabels, currentLabels]);
    var onCreateNewLabel = useCallback(function (labelName, groupId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createNewLabelAndAddToMessage(labelName, groupId, currentLabels)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [currentLabels, createNewLabelAndAddToMessage]);
    var toggleFullView = useCallback(function () {
        setOpenLabels(function (x) { return !x; });
    }, [setOpenLabels]);
    useEffect(function () {
        setOpenLabels(!compactView);
    }, [compactView]);
    return (React.createElement(React.Fragment, null,
        (allLabels === null || allLabels === void 0 ? void 0 : allLabels.length) > 0 && ((currentLabels && currentLabels.length > 0) || !compactView) && (React.createElement("div", { className: 'Message_form_item_container' },
            React.createElement("span", null, t('Analytics: current labels label')),
            React.createElement("div", { className: 'Message_form_item_labels_short' },
                React.createElement("div", null, openLabels ? (React.createElement(LabelsGroups, { groups: allLabels, currentLabels: currentLabels, onChange: onChangeLabels, t: t, createNewLabelAndAddToMessage: onCreateNewLabel })) : (currentLabels.map(function (x) { return (React.createElement("div", { key: x.id, className: 'Message_form_green' }, "".concat(x.group.name, ": ").concat(x.name))); }))),
                React.createElement(IconButton, { "data-test-id": 'qaTaskTaskIcon', name: compactView ? 'farEdit' : openLabels ? 'farChevronUp' : 'farChevronDown', size: 'sm', onClick: compactView ? open : toggleFullView })))),
        (allLabels === null || allLabels === void 0 ? void 0 : allLabels.length) > 0 && isOpen && (React.createElement(EditLabelModal, { isOpen: isOpen, currentLabels: currentLabels || [], allLabels: allLabels, t: t, toggle: toggle, createNewLabelAndAddToMessage: createNewLabelAndAddToMessage, saveLabels: saveLabels }))));
}
function EditLabelModal(_a) {
    var _this = this;
    var isOpen = _a.isOpen, toggle = _a.toggle, t = _a.t, saveLabels = _a.saveLabels, currentLabels = _a.currentLabels, allLabels = _a.allLabels, createNewLabelAndAddToMessage = _a.createNewLabelAndAddToMessage;
    var _b = useState(currentLabels), choosedLabels = _b[0], setChoosedLabels = _b[1];
    useEffect(function () {
        setChoosedLabels(currentLabels);
    }, [currentLabels]);
    var onChangeLabels = useCallback(function (label) {
        var newState;
        if (choosedLabels.find(function (x) { return x.id === label.id; })) {
            newState = choosedLabels.filter(function (x) { return x.id != label.id; });
        }
        else {
            newState = __spreadArray(__spreadArray([], choosedLabels, true), [label], false);
        }
        setChoosedLabels(newState);
    }, [setChoosedLabels, choosedLabels]);
    var onCreateNewLabel = useCallback(function (labelName, groupId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            createNewLabelAndAddToMessage(labelName, groupId, choosedLabels);
            return [2 /*return*/];
        });
    }); }, [choosedLabels]);
    return (React.createElement(Modal, { isOpen: isOpen, onCancelClick: toggle, onActionClick: function () { return saveLabels(choosedLabels); }, title: t('Tags'), buttonCancelText: t('Cancel'), buttonCancelColor: 'secondary', buttonSubmitText: t('Analytics: Save changes'), buttonSubmitColor: 'info' },
        React.createElement(LabelsGroups, { groups: allLabels, currentLabels: choosedLabels, createNewLabelAndAddToMessage: onCreateNewLabel, t: t, onChange: onChangeLabels })));
}
