import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useForceUpdate } from '../utils';
export const JustSelectListPosition = React.forwardRef(({ children, dropdownPosition, position, maxSelected, selected, opened, listStyles, wrapperRef, size, classNameForList, }, ref) => {
    const forceUpdate = useForceUpdate();
    const listSelfStyles = useRef({});
    useEffect(() => {
        if (opened && position === 'fixed')
            document.body.classList.add('force-overflow-hidden');
    }, [opened, position]);
    useEffect(() => {
        return () => {
            document.body.classList.remove('force-overflow-hidden');
        };
    }, [position]);
    useEffect(() => {
        var _a, _b, _c;
        const tRef = ref;
        const dropDownMenu = (_a = tRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const clientRectWrapper = (_b = wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        const height = (_c = ref.current) === null || _c === void 0 ? void 0 : _c.clientHeight;
        if (position !== 'fixed' || !height || !dropDownMenu || !clientRectWrapper)
            return;
        let newStyles = {};
        if (dropdownPosition === 'bottom') {
            if (height + clientRectWrapper.top > window.innerHeight) {
                newStyles = {
                    position: 'fixed',
                    top: clientRectWrapper.top,
                    width: clientRectWrapper.width,
                    left: clientRectWrapper.left,
                    transform: 'translateY(calc(-100% - 4px))',
                };
            }
            else {
                newStyles = {
                    position: 'fixed',
                    top: clientRectWrapper.top + clientRectWrapper.height,
                    width: clientRectWrapper.width,
                    left: clientRectWrapper.left,
                };
            }
        }
        else {
            if (dropDownMenu.top > document.body.offsetTop) {
                newStyles = {
                    position: 'fixed',
                    top: clientRectWrapper.top + clientRectWrapper.height,
                    width: clientRectWrapper.width,
                    left: clientRectWrapper.left,
                };
            }
            else {
                newStyles = {
                    position: 'fixed',
                    top: clientRectWrapper.top,
                    width: clientRectWrapper.width,
                    left: clientRectWrapper.left,
                    transform: 'translateY(calc(-100% - 4px))',
                };
            }
        }
        if (JSON.stringify(listSelfStyles.current) !== JSON.stringify(newStyles)) {
            listSelfStyles.current = newStyles;
            forceUpdate();
        }
    }, [ref, dropdownPosition, position, wrapperRef, forceUpdate]);
    return (React.createElement("div", { className: classNames(`just-select-list ${dropdownPosition}`, { xs: size === 'xs' }, { disabled: maxSelected === selected.length, opened: opened }, { fixed: position === 'fixed' }, classNameForList), "data-test-id": 'just-select-list', style: Object.assign(Object.assign({}, listStyles), listSelfStyles.current), tabIndex: 1, ref: ref }, children));
});
