import ELK from 'elkjs/lib/elk.bundled';

import { BaseAutoLayout } from './BaseAutoLayout';

export class ElkAutoLayout extends BaseAutoLayout {
  private nodes: {
    id: string;
    width: number;
    height: number;
    labels: { text: string }[];
  }[] = [];

  private existedNodes = new Set<string>([]);
  private edges: { id: string; sources: string[]; targets: string[] }[] = [];
  private existedEdges = new Set<string>([]);

  addNode(node: { id: string; width: number; height: number; label: string }) {
    this.existedNodes.add(node.id);
    this.nodes.push({
      ...node,
      labels: [{ text: node.label }],
    });
  }

  getCountOfNode() {
    return this.nodes.length;
  }

  addEdge(from: string, to: string) {
    const name = from + '_' + to;
    if (!this.existedEdges.has(name) && this.existedNodes.has(from) && this.existedNodes.has(to)) {
      this.existedEdges.add(name);
      this.edges.push({
        id: name,
        sources: [from],
        targets: [to],
      });
    }
  }

  async calculate() {
    const elk = new ELK({
      workerFactory: () => new Worker('/elk-worker.js'),
    });
    const graph = {
      id: 'root',
      layoutOptions: {
        'elk.algorithm': 'layered',
        'elk.spacing.nodeNode': '120',
        'elk.spacing.edgeNode': '120',
        'elk.layered.spacing.baseValue': '500',
        'elk.layered.nodePlacement.strategy': 'NETWORK_SIMPLEX',
        'elk.layered.layering.strategy': 'MIN_WIDTH',
        'elk.layered.layering.minWidth.upperBoundOnWidth': '4',
      },
      children: this.nodes,
      edges: this.edges,
    };

    const layout = await elk.layout(graph);

    if (!layout.children) return;

    return layout.children.map(node => ({
      id: node.id,
      height: node.height || 0,
      width: node.width || 0,
      label: node.labels?.[0].text || '',
      x: node.x || 0,
      y: node.y || 0,
    }));
  }
}
