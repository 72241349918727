import React, { useState, useCallback } from 'react';
import { Label } from 'reactstrap';
import { InputText, Modal } from '@just-ai/just-ui';

import { t } from 'localization';

import useNewStateNameValidation from 'modules/JGraph/utils/useNewStateNameValidation';

interface CreateNoMatchStateModalInterface {
  isModalOpen: boolean;
  onCreate: (title: string) => void;
  onCancel: () => void;
}
const CreateNoMatchStateModal = function ({ onCreate, isModalOpen, onCancel }: CreateNoMatchStateModalInterface) {
  const [title, setTitle] = useState('');

  const { isInvalid, errorText } = useNewStateNameValidation(title);

  const onCreateInner = useCallback(() => {
    onCreate(title.trim());
  }, [onCreate, title]);

  return (
    <Modal
      isOpen={isModalOpen}
      title={t(`EmptyMode:CreateNoMatchForm:Title`)}
      buttonSubmitText={t(`EmptyMode:CreateForm:Submit`)}
      buttonSubmitColor='primary'
      buttonCancelOutline
      disableActionButtonAutoFocus
      buttonCancelText={t('EmptyMode:CreateForm:Cancel')}
      buttonCancelColor='secondary'
      buttonSubmitDisabled={isInvalid || !title}
      onCancelClick={onCancel}
      onActionClick={onCreateInner}
      data-test-id='JGraph:CreateNoMatchFormModal'
      buttonCancelTestId='JGraph:CreateNoMatchFormModal.CancelButton'
      buttonSubmitTestId='JGraph:CreateNoMatchFormModal.SubmitButton'
    >
      <div className='flex-column' style={{ color: 'var(--grey-800)' }}>
        <Label for='stateName'>{t('EmptyMode:CreateForm:StartName')}</Label>
        <InputText
          id='stateName'
          errorText={errorText}
          bsSize='default'
          value={title}
          hint={t('CreationScreenMenu:title:hint')}
          data-test-id='JGraph:CreateNoMatchFormModal:Fields:NoMatchName'
          placeholder={t('EmptyMode:CreateNoMatchForm:Placeholder')}
          onChange={setTitle}
        />
      </div>
      <div className='margin-top-4x'>{t('EmptyMode:CreateNoMatchForm:Description')}</div>
    </Modal>
  );
};

export default React.memo(CreateNoMatchStateModal);
