import React, { useCallback, useState } from 'react';
import { Icon, useTranslation } from '@just-ai/just-ui';
import { downloadTxtFile } from '../../../utils';
import styles from './style.module.scss';
export default function FileImportError(props) {
    var error = props.error, fileName = props.fileName;
    var _a = useState(false), blockScrolled = _a[0], setBlockScrolled = _a[1];
    var t = useTranslation().t;
    var copyError = function () {
        navigator.clipboard.writeText(error.text);
    };
    var downloadError = function () {
        var errorsFileName = fileName ? "Errors_file_".concat(fileName) : 'errors';
        downloadTxtFile(error.text, errorsFileName);
    };
    var handleBlockScroll = useCallback(function (event) {
        if (event.currentTarget.scrollTop > 0 && !blockScrolled) {
            return setBlockScrolled(true);
        }
        if (event.currentTarget.scrollTop === 0 && blockScrolled) {
            return setBlockScrolled(false);
        }
    }, [blockScrolled]);
    if (error.type === 'local')
        return React.createElement("p", { className: 'error font-size-12 mb-0' }, t(error.text));
    return (React.createElement("div", null,
        React.createElement("div", { style: {
                borderBottom: '1px solid transparent',
                borderBottomColor: blockScrolled ? '#D6E2F2' : 'transparent',
            }, className: styles.faqImportModal__fileErrorBlock__errorHeader },
            React.createElement("p", { className: 'font-size-12 font-bold' }, t('FAQ:importModal:error:area:title')),
            React.createElement("div", null,
                React.createElement("div", { onClick: copyError },
                    React.createElement(Icon, { name: 'farCopy', size: 'sm', color: 'primary' }),
                    React.createElement("p", null, t('FAQ:importModal:button:copy'))),
                React.createElement("div", { onClick: downloadError },
                    React.createElement(Icon, { name: 'farArrowToBottom', size: 'sm', color: 'primary' }),
                    React.createElement("p", null, t('Download'))))),
        React.createElement("div", { onScroll: handleBlockScroll, className: "".concat(styles.faqImportModal__fileErrorBlock__errorBody, " just-ui-custom-scrollbar just-ui-custom-scrollbar_indigo-light") }, error.text)));
}
