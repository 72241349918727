import keyboardjs from 'keyboardjs';
var KeyboardService = /** @class */ (function () {
    function KeyboardService() {
        this.listeners = new Map();
    }
    KeyboardService.prototype.bind = function (keysPattern, keyDownCallback, keyUpCallback) {
        keyboardjs.on(keysPattern, keyDownCallback, keyUpCallback);
        var key = Array.isArray(keysPattern) ? keysPattern.join(',') : keysPattern;
        this.listeners.set(key, [keyDownCallback, keyUpCallback]);
    };
    KeyboardService.prototype.unbindAll = function () {
        var _this = this;
        Array.from(this.listeners.keys()).forEach(function (key) {
            // @ts-ignore
            var _a = _this.listeners.get(key), onPress = _a[0], onRelease = _a[1];
            keyboardjs.unbind(key, onPress, onRelease);
        });
    };
    return KeyboardService;
}());
export default KeyboardService;
