import React, { FC, useCallback, useMemo } from 'react';

import { EditMenuBlock, JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { AutopositionGroup } from './AutopositionGroup';

import { ScreenMark } from './ScenarioStart';
import { Activations } from './Activations';

import { t } from 'localization';

import { RenderBlocks } from './RenderBlocks';
import { ScreenContext } from 'modules/JGraph/hooks';
import { ScreenMainCollapsibleGroup } from './ScreenMainCollapsibleGroup';

import { activationBlocks, TagNames } from 'modules/JGraph/utils/types';
import { TSortableItem } from 'modules/JGraph/view/RightSideMenu/types';
import { getSortableActivations } from 'modules/JGraph/view/RightSideMenu/utils/common';
import AddButton from './AddReaction';
import { RightSideMenuExternalEventsPipe$ } from '../RightSideMenu/hooks/EventsHook';
import { knowledgeBase } from './BookMarks';

type ScreenBodyPropsType = {
  screen: JStateWithId;
  onBlockClickHandler: (evt: any) => unknown;
  editMenuBlock?: EditMenuBlock;
  isJGraphLite?: boolean;
  isEditModeEnable?: boolean;
};

export const scenarioStart = ['$regex</start>', '*', '*start'];

export const ScreenBody: FC<ScreenBodyPropsType> = ({
  screen,
  editMenuBlock,
  isJGraphLite,
  onBlockClickHandler,
  isEditModeEnable,
}) => {
  const onlyActivations: TSortableItem[] = useMemo(() => {
    return getSortableActivations(screen.blocks, isJGraphLite);
  }, [screen.blocks, isJGraphLite]);

  const isStartScreen = useMemo(() => {
    return getSortableActivations(screen.blocks).some(
      activationBlock =>
        activationBlock.block.tagName === TagNames.q_ && scenarioStart.includes(activationBlock.block.tagValue || '')
    );
  }, [screen.blocks]);

  const isKnowledgeBaseScreen = useMemo(() => {
    return onlyActivations.some(
      activationBlock =>
        [TagNames.intentGroup, TagNames.intentGroup_].includes(activationBlock.block.tagName) &&
        activationBlock.block.tagValue?.startsWith(knowledgeBase)
    );
  }, [onlyActivations]);

  const onClickAddingReactions = useCallback(
    (evt: any) => {
      onBlockClickHandler(evt);
      window.requestAnimationFrame(() => {
        RightSideMenuExternalEventsPipe$.next({
          setAddingMenuType: {
            menuType: 'reactions',
          },
        });
      });
    },
    [onBlockClickHandler]
  );

  return (
    <ScreenContext.Provider value={{ screen, editMenuBlock, isEditModeEnable }}>
      <AutopositionGroup xOffset={0} yOffset={0} name='body'>
        {isStartScreen ? <ScreenMark x={12} text={t('ScenarioStart:placeholder')} /> : null}
        {isKnowledgeBaseScreen ? <ScreenMark x={12} text={t('KnowledgeBase:html_title')} /> : null}
        {onlyActivations.length > 0 && (
          <ScreenMainCollapsibleGroup groupTitle={t('ScreenBody:CollapseGroup_ActivationTitle')} yOffset={4}>
            <Activations
              jstateBlocks={onlyActivations}
              screen={screen}
              active={
                editMenuBlock?.screen &&
                editMenuBlock.screen.pathId === screen.pathId &&
                editMenuBlock.jBlockIndex !== undefined &&
                onlyActivations.map(descr => descr.blockIndex).includes(editMenuBlock.jBlockIndex) &&
                !editMenuBlock.path &&
                activationBlocks.includes(editMenuBlock?.screen.blocks[editMenuBlock.jBlockIndex].tagName)
              }
            />
          </ScreenMainCollapsibleGroup>
        )}
        <ScreenMainCollapsibleGroup
          groupTitle={t('ScreenBody:CollapseGroup_ReactionsTitle')}
          yOffset={4}
          name={`${screen.pathId}_reactions`}
          pathId={screen.pathId}
          isFallbackFrom
        >
          <RenderBlocks blocks={screen.blocks} />
          <AddButton text={t('JGraphActivations:AddReaction')} onClick={onClickAddingReactions} />
        </ScreenMainCollapsibleGroup>

        {/*<ScreenMainCollapsibleGroup groupTitle='Способы активации' block={block} />*/}
      </AutopositionGroup>
    </ScreenContext.Provider>
  );
};
