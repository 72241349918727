var HTMLService = /** @class */ (function () {
    function HTMLService() {
    }
    HTMLService.prototype.downloadLink = function (href, fileName) {
        var linkElement = document.createElement('a');
        linkElement.href = href;
        linkElement.setAttribute('download', fileName);
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    };
    return HTMLService;
}());
export default HTMLService;
