var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { PureComponent } from 'react';
import * as queryString from 'query-string';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
import './Message.scss';
import moment from 'moment';
import MessageForm from './MessageForm';
import classNames from 'classnames';
import Question from './Question';
import { Icon, Button } from '@just-ai/just-ui';
import { Checkbox } from '../../../Checkbox';
import { checkChannelForSystemMessage, MessageStatusSession } from '../../../StatusMessage';
var Message = /** @class */ (function (_super) {
    __extends(Message, _super);
    function Message() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saveComment = function (comment) {
            return _this.context.saveMessageComment(_this.props.questionId, comment, _this.props.isSessionMessages);
        };
        _this.createNewLabelAndAddToMessage = function (value, groupId, labels) {
            if (!value)
                return null;
            return _this.context.createNewLabelAndAddToMessage(_this.props.questionId, _this.props.sessionId, value, groupId, labels, _this.props.isSessionMessages);
        };
        _this.goToSession = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, projectShortName, history, sessionId, questionId, isSessionMessages, sessionStartTime, botId, _b, config, getMessagePageInSession, pageNumber, parsedSearch;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props, projectShortName = _a.projectShortName, history = _a.history, sessionId = _a.sessionId, questionId = _a.questionId, isSessionMessages = _a.isSessionMessages, sessionStartTime = _a.sessionStartTime, botId = _a.botId;
                        _b = this.context, config = _b.config, getMessagePageInSession = _b.getMessagePageInSession;
                        if (isSessionMessages)
                            return [2 /*return*/];
                        if (!((_c = config.messages) === null || _c === void 0 ? void 0 : _c.redirectToSession)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getMessagePageInSession(sessionId, questionId, botId)];
                    case 1:
                        pageNumber = (_d.sent()).pageNumber;
                        parsedSearch = {};
                        parsedSearch.scrollTo = questionId;
                        parsedSearch.page = pageNumber;
                        parsedSearch.startTime = sessionStartTime;
                        history.push({
                            pathname: "".concat(config.urlPrefix || '', "/").concat(projectShortName, "/statistic/dialogs/").concat(encodeURIComponent(sessionId)),
                            search: queryString.stringify(parsedSearch),
                            state: { prevPath: "".concat(history.location.pathname, "?").concat(history.location.search) },
                        });
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.stopPropagation = function (e) { return e.stopPropagation(); };
        _this.chooseForLabels = function () { return _this.props.onChooseForLabels({ type: 'change', value: _this.props.questionId }); };
        return _this;
    }
    Message.prototype.render = function () {
        var _a, _b;
        var _c = this.props, question = _c.question, showAdditionalAction = _c.showAdditionalAction, showMarkup = _c.showMarkup, event = _c.event, nlpInfo = _c.nlpInfo, textAnswer = _c.textAnswer, showAnswers = _c.showAnswers, t = _c.t, timestamp = _c.timestamp, processingTime = _c.processingTime, questionId = _c.questionId, comment = _c.comment, labels = _c.labels, issues = _c.issues, isSessionMessages = _c.isSessionMessages, _d = _c.compactView, compactView = _d === void 0 ? true : _d, downloadReportComponent = _c.downloadReportComponent, index = _c.index, questionIdForScrollRef = _c.questionIdForScrollRef, sessionId = _c.sessionId, onChooseForLabels = _c.onChooseForLabels, isSelected = _c.isSelected, startTimeDialog = _c.startTimeDialog, status = _c.status;
        var _e = this.context, config = _e.config, projectShortName = _e.projectShortName, session = _e.session;
        var rowCount = [showAnswers, showAdditionalAction].reduce(function (result, x) { return result + (x ? 1 : 0); }, 0) + 2;
        var mainElems = (React.createElement("div", { ref: questionIdForScrollRef, className: classNames('Message_container', "Message_container_row_".concat(rowCount), !showMarkup && 'Message_container_noMarkup') },
            showMarkup && (React.createElement("div", { className: 'Message_pattern_class' },
                (nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.ruleType) && (React.createElement("div", { "data-test-id": 'phraseReason', className: 'Message_markup' },
                    React.createElement("span", { className: 'Message_label' },
                        nlpInfo.ruleType === 'event' ? t('Analytics: messages event') : nlpInfo.ruleType,
                        ":"),
                    React.createElement("span", { title: nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.rule, className: 'Message_pattern' }, nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.rule))),
                event && (React.createElement("div", { "data-test-id": 'phraseClass' },
                    React.createElement("span", { className: 'Message_label' },
                        t('Analytics: messages event'),
                        ":"),
                    React.createElement("span", null, event))),
                (nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.nlpClass) && (React.createElement("div", { "data-test-id": 'phraseClass' },
                    React.createElement("span", { className: 'Message_label' }, t('Analytics: messages classLabel')),
                    (nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.effectivePattern) || nlpInfo.nlpClass)),
                React.createElement("div", { "data-test-id": 'phraseState' },
                    React.createElement("span", { className: 'Message_label' }, t('Analytics: messages stateLabel')),
                    React.createElement("span", null, nlpInfo === null || nlpInfo === void 0 ? void 0 : nlpInfo.nlpState)),
                isSessionMessages && checkChannelForSystemMessage(session === null || session === void 0 ? void 0 : session.channelType) && (React.createElement(MessageStatusSession, { status: status, t: t })))),
            React.createElement("div", { className: classNames('Message_time text-muted') },
                index !== undefined && React.createElement("span", { style: { marginRight: '1rem' } },
                    "#",
                    index),
                React.createElement("span", null,
                    moment(timestamp).format(this.context.locale === 'eng' ? 'MM.DD.YYYY, HH:mm:ss' : 'DD.MM.YYYY, HH:mm:ss'),
                    ' '),
                React.createElement("div", { className: 'Message_time_splitter' }),
                React.createElement("span", null, t('Analytics: messages time and processing time', processingTime))),
            React.createElement("div", { className: 'Message_client', "data-test-id": 'phraseQuestion' },
                React.createElement(Question, __assign({}, this.props, { showAnswers: showAnswers, startTimeDialog: startTimeDialog }))),
            showAdditionalAction && Object.values(this.context.config.messageItem).length > 0 && (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); }, className: 'Message_form' },
                React.createElement(MessageForm, { createNewLabelAndAddToMessage: this.createNewLabelAndAddToMessage, questionId: questionId, comment: comment, sessionId: sessionId, t: t, issue: issues ? issues[0] : null, labels: labels, question: question, textAnswer: textAnswer, saveComment: this.saveComment, isSessionMessages: isSessionMessages, compactView: compactView, downloadReportComponent: downloadReportComponent, buttonsWithMinHeight: ((_a = config.messages) === null || _a === void 0 ? void 0 : _a.redirectToSession) && !isSessionMessages }))),
            ((_b = config.messages) === null || _b === void 0 ? void 0 : _b.redirectToSession) && !isSessionMessages && (React.createElement("div", { className: 'Message_openInNewTab' },
                React.createElement(Button, { flat: true, color: 'primary', withoutPadding: true, onClick: this.goToSession }, t('Analytics: to session')),
                React.createElement("a", { onClick: this.stopPropagation, href: "/".concat(projectShortName, "/statistic/dialogs/").concat(encodeURIComponent(sessionId)), target: '_blank', rel: 'noreferrer noopenner' },
                    React.createElement(Icon, { name: 'farExternalLink', size: 'sm', color: 'primary' }))))));
        if (isSessionMessages)
            return mainElems;
        return (React.createElement("div", { className: 'Message_Checkbox_container' },
            onChooseForLabels && (React.createElement("div", { className: 'Message_labels_checkbox_container' },
                React.createElement(Checkbox, { name: "".concat(questionId, "_massLabelsCheckbox"), checked: isSelected, onChange: this.chooseForLabels }))),
            mainElems));
    };
    Message.contextType = AnalyticsContext;
    return Message;
}(PureComponent));
export default Message;
