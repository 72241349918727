import React, { useLayoutEffect, useRef } from 'react';
import { provideRef } from '../utils/provideRef';
const VirtualScrollElementWrapper = function ({ children, onSizeChanged, onUnmount, index, style, }) {
    const ref = useRef();
    useLayoutEffect(() => {
        if (!ref.current)
            return;
        const resizeObserver = new ResizeObserver(() => {
            var _a;
            const sizes = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (!sizes)
                return;
            onSizeChanged(sizes, index);
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
            onUnmount && onUnmount(index);
        };
    }, [index, onSizeChanged, onUnmount]);
    children = React.cloneElement(children, {
        // @ts-ignore
        ref: provideRef(ref, children.ref),
    });
    return React.createElement("div", { style: style }, children);
};
export default React.memo(VirtualScrollElementWrapper);
