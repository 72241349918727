import React, { useEffect, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { translate } from './localization';
import { Collapse, Fade } from 'reactstrap';
import { Icon } from '../Icon';
import './AlertNotification.scss';
import { Button } from '../index';
export function AlertNotificationItem({ type, title = '', message, messageComponent, hideMessage, time, buttons, stopped, toggleModal, copyLog, alertsToDisplay, animationType, dark, disableCopyButton, className, temporal, locale = 'ru', alertType, duration, alertsStackCount, }) {
    const ref = React.useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    const [isTextOverflow, setIsTextOverflow] = useState(false);
    useEffect(() => {
        const slideOutTimer = setTimeout(() => {
            if (ref && ref.current) {
                ref.current.classList.remove('slideOutLeft');
            }
        }, 500);
        return () => clearTimeout(slideOutTimer);
    }, []);
    useEffect(() => {
        if (!stopped) {
            const timer = setTimeout(() => {
                if ((type !== 'error' && type !== 'warning') || temporal)
                    dismissAlert();
            }, duration || 3500);
            return () => clearTimeout(timer);
        }
    }, [stopped, alertsToDisplay, duration]);
    const dismissAlert = () => {
        ref && ref.current && ref.current.classList.add('notification-hidden');
        setIsOpen(false);
        setTimeout(() => {
            hideMessage && hideMessage(time);
        }, 250);
    };
    const checkTextOverflow = (flag) => {
        setIsTextOverflow(flag);
    };
    const copyLogAndHide = () => () => {
        copyLog && typeof message === 'string' && copyLog(message);
        dismissAlert();
    };
    if (alertType === 'toasts')
        return (React.createElement(AnimationComponent, { isOpen: isOpen, animationType: animationType },
            React.createElement("div", { ref: ref, "data-test-id": 'AlertNotification.NotificationContainer', className: classNames('notification', 'slideOutTop', { 'dark-theme': dark }, className) },
                renderIcon(type, alertType),
                React.createElement("div", { className: classNames('notification-body', {
                        'dark-theme': dark,
                    }) },
                    React.createElement(NotificationMessage, { message: message, MessageComponent: messageComponent, checkTextOverflow: checkTextOverflow })),
                React.createElement("div", { className: 'notification-close', "data-test-id": 'AlertNotification.DeleteButton', onClick: dismissAlert },
                    React.createElement(Icon, { name: 'falTimes', color: 'secondary', style: dark ? { color: '#E4E5E6' } : undefined })))));
    return (React.createElement(AnimationComponent, { isOpen: isOpen, animationType: animationType },
        React.createElement("div", { ref: ref, "data-test-id": 'AlertNotification.NotificationContainer', className: classNames('notification', animationType === 'slideIn' ? 'slideOutLeft' : '', { 'dark-theme': dark }, className) },
            React.createElement("div", { className: 'notification-close', "data-test-id": 'AlertNotification.DeleteButton', onClick: dismissAlert },
                React.createElement(Icon, { name: 'faTimes', color: 'secondary', size: 'sm', style: dark ? { color: '#E4E5E6' } : undefined })),
            React.createElement("div", { className: classNames('notification-header', {
                    'no-title': !title,
                    'dark-theme': dark,
                }) },
                renderIcon(type),
                title && React.createElement("div", { className: 'title' }, title)),
            React.createElement("div", { className: classNames('notification-body', {
                    'no-title': !title,
                    'dark-theme': dark,
                }) },
                React.createElement(NotificationMessage, { message: message, MessageComponent: messageComponent, checkTextOverflow: checkTextOverflow }),
                (buttons || isTextOverflow || type === 'error') && (React.createElement("div", { className: 'notification-buttons' },
                    isTextOverflow &&
                        !buttons &&
                        renderButton(type, time, {
                            buttonText: translate('Show log', locale),
                            buttonAction: () => (toggleModal ? toggleModal(time) : null),
                            'data-test-id': 'Notification.ViewLog',
                        }),
                    type === 'error' && !disableCopyButton && (React.createElement(Button, { color: 'danger', size: 'sm', "data-test-id": 'Notification.Copy', outline: isTextOverflow, onClick: copyLogAndHide() }, translate('Copy', locale))), buttons === null || buttons === void 0 ? void 0 :
                    buttons.map((button, index) => renderButton(type, time, button, index))))),
            alertsStackCount && alertsStackCount > 1 && (React.createElement("span", { className: 'notification-stack-count' }, alertsStackCount)))));
}
const AnimationComponent = ({ children, isOpen, animationType, }) => animationType === 'slideIn' ? React.createElement(Collapse, { isOpen: isOpen }, children) : React.createElement(Fade, { in: isOpen }, children);
export const NotificationMessage = ({ message, MessageComponent, checkTextOverflow }) => {
    const ref = React.createRef();
    useLayoutEffect(() => {
        if (ref && ref.current) {
            const notificationMessage = ref.current;
            if (notificationMessage.scrollHeight > notificationMessage.clientHeight) {
                checkTextOverflow(true);
            }
        }
    }, []);
    if (MessageComponent) {
        return (React.createElement("div", { ref: ref, className: 'notification-message' },
            React.createElement(MessageComponent, null)));
    }
    return (React.createElement("div", { ref: ref, title: message, className: 'notification-message' }, message));
};
function renderButton(type, time, button, index = 0) {
    let color;
    switch (type) {
        case 'success':
            color = 'success';
            break;
        case 'info':
            color = 'primary';
            break;
        case 'error':
            color = 'danger';
            break;
        case 'warning':
            color = 'warning';
            break;
        default:
            color = 'primary';
            break;
    }
    return (React.createElement(Button, { key: `actionButton_${button.buttonText}_${time}`, onClick: button.buttonAction, color: color, size: 'sm', outline: !button.buttonFill && (index !== 0 || type === 'error'), "data-test-id": button['data-test-id'] }, button.buttonText));
}
function renderIcon(type, alertType) {
    let name;
    let color;
    switch (type) {
        case 'success':
            name = alertType === 'alerts' ? 'faCheckSquare' : 'faCheckCircle';
            color = 'success';
            break;
        case 'info':
            name = 'faInfoCircle';
            color = 'primary';
            break;
        case 'error':
            name = 'faExclamationCircle';
            color = 'danger';
            break;
        case 'warning':
            name = 'faExclamationTriangle';
            color = 'warning';
            break;
        default:
            name = 'faInfoCircle';
            color = 'primary';
            break;
    }
    return React.createElement(Icon, { name: name, color: color });
}
