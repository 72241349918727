import { useAppDispatch } from '../../../storeHooks';
import { useCallback, useEffect, useRef } from 'react';
import Konva from 'konva';
import { getTargetAndHeightByPathId, scrollToTargetGlobal$ } from '../utils/stageUtils';

import { Vector2d } from 'konva/lib/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { getColNumber } from '../../Editor/context/contextUtils';
import { mainSave$ } from './savingPipe';
import { batchMove, moveState, syncStickers } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { JStateMovementsData, MovementData, MovementsDataWithFile } from '../../Editor/api/client';
import { setBlockPositions, updateConnectedStickersAfterStateUpdate } from 'reducers/JGraph.reducer';
import { findParentScreenName } from './index';
import { JStateWithId, TConnector } from '../../../reducers/JGraph.reducer/types';
import useOnlineStatus from '../../../utils/hooks/useOnlineStatus';
import { StageObservablesContextType } from '../contexts/StageObservablesProvider';
import { ConnectorStore } from '../contexts/types';

import { ElkAutoLayout } from '../services/AutoLayout/ElkAutoLayout';
import { PlacedNode, BaseAutoLayout } from '../services/AutoLayout/BaseAutoLayout';
import { DagreAutoLayout } from '../services/AutoLayout/DagreAutoLayout';
import { AppLogger } from 'services/AppLogger';
import { findScreenByPathId } from 'reducers/JGraph.reducer/Graph';
import { StickerInfo } from '../view/Sticker/types';

export type AutoLayoutType = 'elk' | 'dagree';

type Constructor<CLASS> = { new (): CLASS };
const autoLayoutStrategies: Record<AutoLayoutType, Constructor<BaseAutoLayout>> = {
  dagree: DagreAutoLayout,
  elk: ElkAutoLayout,
};

export const AutoLayoutStartSubject$ = new Subject<{ type: AutoLayoutType }>();
export const AutoLayoutProgressSubject$ = new BehaviorSubject<{
  type: AutoLayoutType | undefined;
  status: undefined | 'pending' | 'error' | 'done';
  error?: Error;
}>({
  status: undefined,
  type: undefined,
});

type MapScreenPathIdToSize = Record<
  string,
  {
    height: number;
    path: string;
    filename: string;
  }
>;
export type BlocksPositions = Record<string, Vector2d>;

export const useAutoplacement = (
  isInGroup: boolean,
  isInActiveGroup: boolean,
  stage: Konva.Stage | null,
  canAutoplace$: Observable<any>,
  screens: JStateWithId[],
  stickers: StickerInfo[],
  renderStore$: BehaviorSubject<string[]>,
  startState?: JStateWithId
) => {
  const dispatch = useAppDispatch();
  const isOnline = useOnlineStatus();

  const autoPositioningStarted = useRef(false);

  const autoposition = useCallback(
    async (type: AutoLayoutType) => {
      if (!stage) return;
      let mapScreenPathIdToSize = screens.reduce((prevValue, currentItem) => {
        const { targetHeight } = getTargetAndHeightByPathId(currentItem.pathId, stage);
        prevValue[currentItem.pathId] = {
          height: targetHeight,
          path: currentItem.path,
          filename: currentItem.filename,
        };
        return prevValue;
      }, {} as MapScreenPathIdToSize);

      const strategy = new autoLayoutStrategies[type]();

      Object.keys(mapScreenPathIdToSize).forEach((blockId, index) => {
        strategy.addNode({
          id: blockId,
          width: 280,
          height: mapScreenPathIdToSize[blockId].height,
          label: mapScreenPathIdToSize[blockId].path,
        });
      });

      if (strategy.getCountOfNode() === 0) return;
      const screensPathIds = screens.map(screen => screen.pathId);

      const { connectorsFromStore$, ConnectorsStore$ } = stage.attrs.ctx as StageObservablesContextType;
      let fromToNodes: ConnectorStore = {};
      const sub = connectorsFromStore$.subscribe(storedValues => {
        fromToNodes = storedValues;
      });
      sub.unsubscribe();
      let connections: TConnector[] = [];
      const sub2 = ConnectorsStore$.subscribe(storedConnections => {
        connections = storedConnections;
      });
      sub2.unsubscribe();

      const replaceMapFromScreenConnections = new Map();
      const replaceMapToScreenConnections = new Map();

      screensPathIds.forEach(screenPathId => {
        replaceMapFromScreenConnections.set(screenPathId, screenPathId);
        replaceMapToScreenConnections.set(screenPathId, screenPathId);
      });

      for (let connection of connections) {
        if (!replaceMapFromScreenConnections.has(connection.fromNode)) {
          const screenGroupTarget =
            fromToNodes[connection.from]?.fromRef || fromToNodes[connection.fromNode]?.fromRefFallBack;
          if (!screenGroupTarget) continue;
          const [parentName] = findParentScreenName(screenGroupTarget);
          replaceMapFromScreenConnections.set(connection.fromNode, parentName);
        }
        if (!replaceMapToScreenConnections.has(connection.to!)) {
          const screenGroupTarget = fromToNodes[connection.to!]?.toRef;
          if (!screenGroupTarget) continue;
          const [parentName] = findParentScreenName(screenGroupTarget);
          replaceMapToScreenConnections.set(connection.to!, parentName);
        }

        if (
          replaceMapFromScreenConnections.has(connection.fromNode) &&
          replaceMapToScreenConnections.has(connection.to!)
        ) {
          strategy.addEdge(connection.fromNode, connection.to!);
        }
      }

      AutoLayoutProgressSubject$.next({ type, status: 'pending' });
      let placedNodes: PlacedNode[] | undefined;
      try {
        placedNodes = await strategy.calculate();
      } catch (error) {
        if (error instanceof Error) {
          AppLogger.error({
            message: '',
            exception: error,
          });
          AutoLayoutProgressSubject$.next({ type, status: 'error', error });
        }
        console.error(error);
      }

      if (!placedNodes) return;

      const padY: { [x: number]: number } = {};
      let positionsToSave = {} as Record<string, MovementsDataWithFile['statesMovementsData']>;
      const positions = placedNodes.reduce((ds, node) => {
        const nodeData = node;
        if (!nodeData || !nodeData.x || !nodeData.y) return ds;
        const x = nodeData.x || 0;
        let y = nodeData.y;
        //@ts-ignore
        if (!positionsToSave[mapScreenPathIdToSize[nodeData.id].filename]) {
          //@ts-ignore
          positionsToSave[mapScreenPathIdToSize[nodeData.id].filename] = [];
        }

        if (typeof y !== 'number' || isNaN(y)) {
          if (padY[nodeData.x]) {
            y = padY[nodeData.x];
            padY[nodeData.x] = y + nodeData.height! + 100;
          } else {
            y = 0;
            padY[nodeData.x] = nodeData.height! + 100;
          }
        }
        //@ts-ignore
        positionsToSave[mapScreenPathIdToSize[nodeData.id].filename].push({
          targetState: nodeData.label,
          x: x,
          y: y,
        });
        return Object.assign(ds, { [nodeData.id]: { x, y } });
      }, {} as BlocksPositions);

      await dispatch(batchMove({ positions, positionsForApi: positionsToSave, strategy: type }));
    },
    [dispatch, screens, stage]
  );

  const placeScreensLikeAimylogic = useCallback(async () => {
    const blocks = screens;
    if (!stage) return;

    if (!blocks || blocks.length === 0) return;

    const colOffsets: number[] = [];
    let positionsToStore = {} as BlocksPositions;

    const positions = blocks.reduce((positions, screen) => {
      if (!positions[screen.filename]) {
        positions[screen.filename] = [] as MovementData[];
      }
      const col = getColNumber(String(screen.aimyPosition));
      if (!colOffsets[col]) colOffsets[col] = 50;

      const { targetHeight } = getTargetAndHeightByPathId(screen.pathId, stage);

      positions[screen.filename].push({
        targetState: screen.path,
        x: 50 + col * 450,
        y: colOffsets[col],
      });
      positionsToStore[screen.pathId] = {
        x: 50 + col * 450,
        y: colOffsets[col],
      };

      colOffsets[col] += targetHeight + 50;

      return positions;
    }, {} as { [key: string]: MovementsDataWithFile['statesMovementsData'] });

    await dispatch(setBlockPositions(positionsToStore));

    // stickers move
    for (let [pathId, position] of Object.entries(positionsToStore)) {
      let screen = findScreenByPathId(pathId, screens);
      if (!screen) continue;
      const stateAfterUpdate = {
        path: screen.path,
        x: position.x,
        y: position.y,
      };
      await dispatch(updateConnectedStickersAfterStateUpdate({ stateBeforeUpdate: screen, stateAfterUpdate }));
    }
    await dispatch(syncStickers(stickers));

    Object.keys(positions).forEach(filename => {
      const moveJStateData: JStateMovementsData = {
        movementsDataWithFile: [
          {
            filename: filename,
            statesMovementsData: positions[filename],
          },
        ],
      };
      mainSave$.next({
        type: 'move',
        path: 'batch save move',
        action: () => dispatch(moveState(moveJStateData)),
      });
    });
  }, [dispatch, screens, stage, stickers]);

  useEffect(() => {
    const sub = canAutoplace$.subscribe({
      next: value => {
        if (value !== '__END__') return;
        if (!stage || !isOnline) return;

        if (
          screens.length > 0 &&
          screens.every(screen => screen.x === 0 && screen.y === 0) &&
          !autoPositioningStarted.current
        ) {
          autoPositioningStarted.current = true;
          if (screens.every(screen => screen.value.startsWith('newNode') && !!screen.aimyPosition)) {
            // noinspection JSIgnoredPromiseFromCall
            placeScreensLikeAimylogic();
          } else {
            console.log('Autoposition');
            // noinspection JSIgnoredPromiseFromCall
            autoposition('dagree');
          }
        }
      },
    });

    const autoLayoutSub = AutoLayoutStartSubject$.subscribe(autoLayout => {
      if (!isInActiveGroup || AutoLayoutProgressSubject$.getValue().status === 'pending') return;
      autoposition(autoLayout.type).then(() => {
        if (startState) {
          scrollToTargetGlobal$.next({
            targetPathId: startState.pathId,
            isSideMenuOpen: true,
          });
        }
      });
    });

    return () => {
      sub?.unsubscribe();
      autoLayoutSub?.unsubscribe();
    };
  }, [
    autoposition,
    canAutoplace$,
    placeScreensLikeAimylogic,
    renderStore$,
    screens,
    stage,
    isOnline,
    isInActiveGroup,
    startState,
  ]);
};
