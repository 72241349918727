import { FileData } from '../api/client';
import FileNode from './FileNode';
import FolderNode from './FolderNode';
import { TreeNode } from '@just-ai/just-ui';

export const KEEP_FILE_NAME = '.keep';

export type FileTreeNode = FileNode | FolderNode;

export type FileTreeDataset = {
  [key: string]: FileTreeNode;
};

type FileTreeDataSetter = (data: FileTreeDataset) => void;

type ExtendedFileData = FileData & {
  updatedLastModificationDate?: number;
};

export default class FileTree {
  data: FileTreeDataset = {};
  private dataSetter: FileTreeDataSetter;
  private showHiddenFiles: boolean;
  private prefix: string;
  tempFile?: FileTreeNode;

  constructor(
    files: ExtendedFileData[] = [],
    dataSetter: FileTreeDataSetter = () => {},
    showHiddenFiles: boolean = false,
    prefix: string = ''
  ) {
    this.dataSetter = dataSetter;
    this.showHiddenFiles = showHiddenFiles;
    this.prefix = prefix;
    files.map(fileDto => FileNode.fromDto(fileDto, prefix)).forEach(file => this.addTreeNodeWithoutSetter(file));
    this.dataSetter(this.data);
  }

  setTempFile(file: FileTreeNode) {
    this.tempFile = file;
  }

  clearTempFile() {
    if (this.tempFile) {
      this.deleteFileOrFolder(this.tempFile);
    }
    this.tempFile = undefined;
  }

  getFileById(fileId: string): FileNode | undefined {
    const optionalFile = this.data[fileId];
    return optionalFile && optionalFile instanceof FileNode ? optionalFile : undefined;
  }

  getFolderById(folderId: string): FolderNode | undefined {
    const optionalFolder = this.data[folderId];
    return optionalFolder && optionalFolder instanceof FolderNode ? optionalFolder : undefined;
  }

  getFileOrFolder(fileOrFolderId?: string): FileTreeNode | undefined {
    if (!fileOrFolderId) return undefined;
    if (fileOrFolderId.startsWith(FolderNode.PREFIX)) return this.getFolderById(fileOrFolderId);
    return this.getFileById(fileOrFolderId);
  }

  hasFileWithId(fileId: string): boolean {
    return this.getFileById(fileId) !== undefined;
  }

  hasKeepFileInFolder(folderId: string): boolean {
    return this.hasFileWithId(`${FileNode.prefixed(FolderNode.deprefixed(folderId))}/${KEEP_FILE_NAME}`);
  }

  hasFolderWithId(folderId: string): boolean {
    return this.getFolderById(folderId) !== undefined;
  }

  addTreeNode(file: FileTreeNode) {
    this.addTreeNodeWithoutSetter(file);
    this.dataSetter(this.data);
  }

  addTreeNodeWithoutSetter(file: FileTreeNode) {
    this.resolveRelations(file);
    this.addNodeToDataset(file);
  }

  replaceFile(oldFile: FileNode, newFile: FileNode) {
    this.deleteFileOrFolder(oldFile);
    this.addNodeToDataset(newFile);
    this.dataSetter(this.data);
  }

  listNodeAndAllChildren(node: FileTreeNode): Array<FileTreeNode> {
    const result: FileTreeNode[] = [node];
    if (node instanceof FileNode) return result;

    const optionalKeepFile = this.getFileById(`${node.path}/${KEEP_FILE_NAME}`);
    if (optionalKeepFile) result.push(optionalKeepFile);

    return [...result, ...node.childrenNodes.flatMap(childNode => this.listNodeAndAllChildren(childNode))];
  }

  createTempFileInFolder(parent: FolderNode) {
    const path = parent.path === '/' ? '/ ' : parent.path + '/ ';
    const newFile = new FileNode(path, undefined, undefined, this.prefix);
    this.addTreeNode(newFile);
    this.tempFile = newFile;
    return newFile;
  }

  createTempFolderInFolder(parent: FolderNode) {
    const path = parent.path === '/' ? '/ ' : parent.path + '/ ';
    const newFolder = new FolderNode(path, this.prefix);
    this.addTreeNode(newFolder);
    this.tempFile = newFolder;
    return newFolder;
  }

  static isFileTreeNode(node: TreeNode): node is FileTreeNode {
    return node instanceof FileNode || node instanceof FolderNode;
  }

  deleteFileOrFolder(file: FileTreeNode) {
    file.parentNode?.removeChildNode(file);
    this.deleteNodeFromDataset(file);
    this.dataSetter(this.data);
  }

  getChangedFilesList(): FileNode[] {
    return Object.values(this.data)
      .filter(file => file instanceof FileNode)
      .filter(file => (file as FileNode).hasLocalChanges) as FileNode[];
  }

  resetHasLocalChanges() {
    Object.values(this.data).forEach(file => {
      if (file instanceof FileNode) file.resetHasLocalChanges();
    });
    this.dataSetter(this.data);
  }

  private resolveRelations(file: FileTreeNode) {
    if (file.path === '/') return;

    const parentPath = FileTree.getParentPathFromPath(file.path);

    const folder = this.getOrCreateFolderWithPath(parentPath);
    if (this.showHiddenFiles || !FileTree.isBlacklisted(file.name)) folder.addChildNode(file);
    file.setParentNode(folder);
  }

  static isBlacklisted(fileName: string) {
    return (
      fileName === KEEP_FILE_NAME ||
      fileName === '.empty' ||
      fileName === 'caila_import.json' ||
      fileName === 'nlu.json' ||
      fileName === '.guestaccesskey' ||
      fileName === '.justaikey' ||
      fileName.endsWith('.bp') ||
      fileName.endsWith('.backup') ||
      fileName.startsWith('.jgraph')
    );
  }

  private getOrCreateFolderWithPath(path: string) {
    const optionalFolder = this.getFolderById(FolderNode.prefixed(path, this.prefix));
    if (optionalFolder) return optionalFolder;

    const newFolder = new FolderNode(path, this.prefix);
    this.addTreeNodeWithoutSetter(newFolder);

    return newFolder;
  }

  private addNodeToDataset(node: FileTreeNode) {
    this.data = { ...this.data, [node.nodeId]: node };
  }

  private deleteNodeFromDataset(node: FileTreeNode) {
    const { [node.nodeId]: _oldValue, ...newData } = this.data;
    this.data = newData;
  }

  private static getParentPathFromPath(path: string) {
    const pathSubstring = path.substring(0, path.lastIndexOf('/'));
    return pathSubstring !== '' ? pathSubstring : '/';
  }
}
