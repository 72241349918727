import React, { FC, useCallback } from 'react';
import { InputText } from '@just-ai/just-ui';

import { IconButton } from '@just-ai/just-ui/dist';
import { CommonFieldProps } from './types';

interface NameValueItemProps extends Omit<CommonFieldProps, 'onChange' | 'name' | 'isRequired' | 'onBlur' | 'invalid'> {
  index: number;
  value: {
    name: string;
    value: string;
  };
  onChange: (index: number, field: 'name' | 'value', value: string) => void;
  onRemove: (index: number) => void;
}

export const NameValueItem: FC<NameValueItemProps> = ({ index, value, onChange, onRemove }) => {
  const onChangeNameIndexHandler = useCallback((value: string) => onChange(index, 'name', value), [onChange, index]);
  const onChangeValueIndexHandler = useCallback((value: string) => onChange(index, 'value', value), [onChange, index]);
  const onRemoveByIndexHandler = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <div className='d-flex gap-8 mb-2'>
      <div className='d-flex flex-fill'>
        <InputText onChange={onChangeNameIndexHandler} value={value.name} autoFocus={!value.name} />
      </div>
      <div className='d-flex flex-fill'>
        <InputText onChange={onChangeValueIndexHandler} value={value.value} />
      </div>
      <div>
        <IconButton color='primary' name='farTrashAlt' flat onClick={onRemoveByIndexHandler} size='md' />
      </div>
    </div>
  );
};
