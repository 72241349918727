import React, { Fragment, useState } from 'react';
import localize, { t } from '../../../localization';
import { FormText, Icon, InputGroup, InputText, RadioButton, Select } from '@just-ai/just-ui';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { ChangePassword } from './helpers/ChangePassword';

import isAccess, { isEuroInstance, isWhiteLabel } from '../../../isAccessFunction';

const GITHUB_DOMAIN = 'github.com';
export const isGithubLink = value => value?.includes(GITHUB_DOMAIN);

const GITHUB_ACCESS_WARNING = 'GITHUB_WRONG_ACCOUNT_TOKEN';
export const hasGithubTokenWarning = project => project.warnings?.includes(GITHUB_ACCESS_WARNING);

export function RepoLocation({
  isEdit,
  toggleExternalRepository,
  externalRepository,
  editableProject,
  hidden,
  repositoryUrlRef,
  contentDirectoryRef,
  defaultBranchRef,
  defaultRepositoryAuthTypes,
  repositoryAuthTypes,
  customStyles,
  changeAccess,
  appConfig,
  editingDisabled,
  showPasswordJustAiKey,
  switchPasswordJustAiKeyShow,
  copyKey,
  createPassword,
  repositoryLoginRef,
  onValidateForm,
  repositoryPasswordRef,
  justAiKeyRef,
  justAiKey,
  changePassword,
  changePersonalUser,
  personalRepo,
  initialCode,
}) {
  const [branchName, setBranchName] = useState(
    Boolean(editableProject.defaultBranch)
      ? editableProject.defaultBranch
      : defaultBranchRef?.current?.value || 'master'
  );

  const isExternalSelected =
    externalRepository === 'external' ||
    (editableProject && editableProject.repositoryUrl) ||
    initialCode === 'connect';

  const [isGithub, setIsGithub] = useState(isGithubLink(editableProject.repositoryUrl));

  return (
    <Fragment>
      {(!isEdit || (isEdit && isAccess('projects.hide_advanced_settings'))) && (
        <div className='form-group row externalRepository-toggle-custom-mb'>
          <label
            className='col-md-4 form-control-label'
            htmlFor='externalRepository'
            data-test-id='projectsPage.createProjectModal.isExternalStorageLabel'
            style={{ alignItems: 'flex-start' }}
          >
            {localize.translate('Project location')}
          </label>
          <div className='col-md-8'>
            <RadioButton
              name='internal'
              checked={!isExternalSelected}
              label={
                isWhiteLabel()
                  ? localize.translate(isEuroInstance() ? 'Inside tovie platform' : 'Inside bot platform')
                  : localize.translate('Inside jaicp', t(isEuroInstance() ? 'Tovie Platform' : 'JAICP'))
              }
              disabled={
                editingDisabled || (isEdit && isAccess('projects.hide_advanced_settings')) || initialCode === 'connect'
              }
              onChange={() => toggleExternalRepository()}
            />
            <RadioButton
              name='external'
              rootClassName='mt-3'
              checked={isExternalSelected}
              disabled={
                editingDisabled || (isEdit && isAccess('projects.hide_advanced_settings')) || initialCode === 'connect'
              }
              label={localize.translate('External git')}
              onChange={() => toggleExternalRepository()}
            />
          </div>
        </div>
      )}
      {externalRepository === 'external' && initialCode === 'system' && (
        <div className='external-repo-warning justui_announcement warning'>
          <Icon name='faExclamationTriangle' color='warning' />
          <span>{localize.translate('External repo warning', branchName)}</span>
        </div>
      )}
      <div>
        {!(isEdit && editableProject.repositoryUrl === null) && (
          <div>
            <div className='form-group row'>
              <label className='col-md-4 form-control-label' htmlFor='repositoryUrl'>
                {localize.translate('Repository link')}
                <span className='red-dot'>*</span>
              </label>
              <div className='col-md-8'>
                <InputText
                  name='repositoryUrl'
                  maxLength='200'
                  id='repositoryUrl'
                  required={hidden}
                  disabled={editingDisabled || !hidden}
                  defaultValue={Boolean(editableProject.repositoryUrl) ? editableProject.repositoryUrl : ''}
                  placeholder={localize.translate('Repository link placeholder')}
                  innerRef={repositoryUrlRef}
                  onChange={value => {
                    onValidateForm();
                    setIsGithub(isGithubLink(value));
                  }}
                  data-test-id='projectsPage.createProjectModal.repositoryUrlInputText'
                />
              </div>
            </div>
          </div>
        )}
        {!(isEdit && isAccess('projects.hide_advanced_settings')) && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label' htmlFor='contentDirectory'>
              {localize.translate('Repository content folder')}
              <span className='red-dot'>*</span>
            </label>
            <div className='col-md-8'>
              <InputText
                name='contentDirectory'
                maxLength='100'
                id='contentDirectory'
                required={hidden}
                disabled={editingDisabled || !hidden}
                placeholder={localize.translate('Repository content folder placeholder')}
                defaultValue={Boolean(editableProject.contentDirectory) ? editableProject.contentDirectory : '.'}
                innerRef={contentDirectoryRef}
                data-test-id='projectsPage.createProjectModal.contentDirectoryInputText'
              />
            </div>
          </div>
        )}
        {/*'ветка'*/}
        {!(isEdit && isAccess('projects.hide_advanced_settings')) && (
          <div className='form-group row'>
            <label className='col-md-4 form-control-label' htmlFor='branch'>
              {localize.translate('Branch')}
              <span className='red-dot'>*</span>
            </label>
            <div className='col-md-8'>
              <InputText
                name='defaultBranch'
                id='defaultBranch'
                disabled={editingDisabled || !hidden}
                required={hidden}
                defaultValue={Boolean(editableProject.defaultBranch) ? editableProject.defaultBranch : 'master'}
                onChange={value => setBranchName(value)}
                innerRef={defaultBranchRef}
                data-test-id='projectsPage.createProjectModal.defaultBranchInputText'
              />
            </div>
          </div>
        )}

        {/* Доступ простой*/}
        {(!editableProject.shortName || editableProject.repositoryAuthType === 'GUEST_USER') && (
          <div
            className={classNames('form-group row select-access', {
              'has-form-text': defaultRepositoryAuthTypes.value === 'GUEST_USER',
            })}
          >
            <label className='col-md-4 form-control-label' htmlFor='botTemplate'>
              {localize.translate('Access')}
            </label>
            <div className='col-md-8'>
              <Select
                options={repositoryAuthTypes}
                isMulti={false}
                isClearable={false}
                styles={customStyles}
                onChange={changeAccess}
                value={defaultRepositoryAuthTypes}
                data-test-id='projectsPage.createProjectModal.repositoryAccessSelect'
                isDisabled={editingDisabled || !hidden || isAccess('projects.hide_advanced_settings')}
                menuPosition='fixed'
                noOptionsMessage={() => localize.translate('BotControls:not_found')}
              />
              {defaultRepositoryAuthTypes?.value === 'GUEST_USER' && (
                <FormText color='muted' tag='small'>
                  {localize.translate(`${defaultRepositoryAuthTypes.value} access sub text`, appConfig.getLogin)}
                </FormText>
              )}
            </div>
          </div>
        )}

        {defaultRepositoryAuthTypes?.value === 'GUEST_USER' &&
          !(isEdit && isAccess('projects.hide_advanced_settings')) && (
            <div className='form-group has-form-text row'>
              <div className='col-md-4 form-control-label'>{localize.translate('ProjectEditForm Secret key')}</div>
              <div className='col-md-8'>
                <InputGroup
                  AppendInner={[
                    {
                      name: showPasswordJustAiKey ? 'faEye' : 'faEyeSlash',
                      onClick: switchPasswordJustAiKeyShow,
                      disabled: editingDisabled,
                    },
                    {
                      name: 'faCopy',
                      onClick: copyKey,
                      disabled: editingDisabled,
                    },
                  ]}
                  Append={
                    <Button
                      color='primary'
                      outline
                      onClick={createPassword}
                      disabled={editingDisabled || !hidden}
                      data-test-id='projectsPage.createProjectModal.geterateKeyButton'
                    >
                      {localize.translate('ProjectEditForm Generate password')}
                    </Button>
                  }
                >
                  <InputText
                    className='form-control justAiKey_input skip-readonly'
                    placeholder={localize.translate('ProjectEditForm Secret key')}
                    innerRef={justAiKeyRef}
                    disabled={editingDisabled || !hidden}
                    value={justAiKey}
                    type={showPasswordJustAiKey ? 'text' : 'password'}
                    defaultValue={Boolean(editableProject.justAiKey) ? editableProject.justAiKey : ''}
                    data-test-id='projectsPage.createProjectModal.justAiKeyInput'
                  />
                </InputGroup>
                <FormText color='muted' tag='small'>
                  <a
                    href={localize.translate('ProjectEditForm instruction link')}
                    target='_blank'
                    rel='noopener noreferrer'
                    data-test-id='projectsPage.createProjectModal.keyInstructionLink'
                  >
                    {localize.translate('ProjectEditForm instruction')}
                  </a>
                </FormText>
              </div>
            </div>
          )}

        {/*Доступ с логином и кнопками сменить пароль*/}
        {editableProject.shortName &&
          editableProject.repositoryUrl &&
          editableProject.repositoryAuthType !== 'GUEST_USER' && (
            <div
              className={classNames('form-group row select-access', {
                'has-form-text':
                  defaultRepositoryAuthTypes &&
                  (defaultRepositoryAuthTypes.value === 'GUEST_USER' ||
                    defaultRepositoryAuthTypes.value === 'PERSONAL_USER'),
              })}
            >
              <label className='col-md-4 form-control-label' htmlFor='botTemplate'>
                {localize.translate('Access')}
              </label>
              <div className='col-md-8'>
                <Select
                  options={repositoryAuthTypes}
                  isMulti={false}
                  isClearable={false}
                  styles={customStyles}
                  onChange={changeAccess}
                  value={defaultRepositoryAuthTypes}
                  isDisabled={editingDisabled || isAccess('projects.hide_advanced_settings')}
                  data-test-id='projectsPage.createProjectModal.repositoryAccessSelect'
                  menuPosition='fixed'
                  noOptionsMessage={() => localize.translate('BotControls:not_found')}
                />
                {defaultRepositoryAuthTypes && defaultRepositoryAuthTypes.value === 'PERSONAL_USER' && (
                  <FormText color='muted' tag='small'>
                    {localize.translate(`${defaultRepositoryAuthTypes.value} access sub text`)}{' '}
                    {localize.translate('Current User')}
                    <span className='color-danger'> {editableProject.repositoryLogin}.</span>
                    <a href='#!' onClick={changePassword} className='color-primary'>
                      {localize.translate(changePersonalUser ? 'Cancel' : 'Change')}
                    </a>
                  </FormText>
                )}
                {defaultRepositoryAuthTypes && defaultRepositoryAuthTypes.value === 'GUEST_USER' && (
                  <FormText color='muted' tag='small'>
                    {localize.translate(`${defaultRepositoryAuthTypes.value} access sub text`, appConfig.getLogin)}
                  </FormText>
                )}
              </div>
            </div>
          )}
        {personalRepo &&
          (changePersonalUser || !editableProject.shortName || editableProject.repositoryAuthType === 'GUEST_USER') && (
            <ChangePassword
              editableProject={editableProject}
              repositoryLoginRef={repositoryLoginRef}
              repositoryPasswordRef={repositoryPasswordRef}
              editingDisabled={editingDisabled}
              showGithubWarning={isGithub}
              hidden={hidden}
            />
          )}
      </div>
    </Fragment>
  );
}
