import { useState, useCallback, useEffect } from 'react';
import Konva from 'konva';

import { LabelingService } from 'modules/JGraph/view/LabelingToolMenu/LabelingService';
import useHover from 'modules/JGraph/hooks/useHover';
import { useDebounce } from 'modules/Caila/utils';
import { LabelingToolMenu$ } from '../index';

import { JStateWithId } from 'reducers/JGraph.reducer/types';

export function useScreenLabel(screen: JStateWithId, stageNode: Konva.Group | null) {
  const [screenLabel, setScreenLabel] = useState(LabelingService.getLabelByScreen(screen));

  const { hoverProps, isHovered } = useHover();
  useDebounce(
    isHovered,
    300, //designer choice
    useCallback(
      isHovered => {
        LabelingToolMenu$.next({
          stageNode,
          screen,
          isHovered,
        });
      },
      [screen, stageNode]
    )
  );

  useEffect(() => {
    setScreenLabel(LabelingService.getLabelByScreen(screen));
  }, [screen]);

  const onLabelClick = useCallback(() => {
    LabelingToolMenu$.next({
      stageNode: stageNode,
      screen,
      forceShow: true,
    });
  }, [screen, stageNode]);

  return { screenLabel, hoverProps, onLabelClick };
}
