import React from 'react';
import Diagram from './views/Dialogs/Diagrams';
import { EditCommentModalForReporter } from './views/SystemDialogDetail/DialogPhraseOptions/EditCommentModal';
import { EditIntentsModalForReporter } from './views/SystemDialogDetail/DialogPhraseOptions/EditIntentsModal';
import { AnalyticsConfigType } from '@just-ai/analytic-front';
import isAccess, { isConfigEnabled, isEuroInstance } from 'isAccessFunction';
import { hasFeatures } from 'isAccessFunction';

import { WrappedTaskEditModalForReporter } from './views/Tasks/TaskEditModal';
import {
  GetLogModalWithReporter,
  GetMessageLoglWithReporter,
} from 'views/SystemDialogDetail/SessionDetailWithReporter';
import { axios } from './pipes/functions';
// @ts-ignore
import localize from './localization';

const { translate: t } = localize;

// @ts-ignore
export const AnalyticConfig: () => AnalyticsConfigType = () => ({
  projectOverview: {
    showLinks: {
      channels: true,
      help: isConfigEnabled('showHelp'),
      clients: true,
      sessions: true,
      messages: true,
      resultLinks: true,
    },
    links: {
      resultLinks: {
        link: t('ProjectOverview: JAICP link'),
        text: t('ProjectOverview: JAICP docs', t(isEuroInstance() ? 'Tovie Platform' : 'JAICP')),
      },
    },
  },
  sessions: {
    downloadReport: true,
    redirectToSession: true,
  },

  messages: {
    downloadReport: true,
    redirectToSession: true,
    showAnswerViewControl: true,
    showMarkupViewControl: true,
    showAdditionalActionsViewControl: true,

    //@ts-ignore
    downloadIssue: ({ projectShortName, sessionId, uniqQuestionIdsInSession }) => {
      return axios.get(
        `/restapi/logs/issues/${projectShortName}/session-issues/byQuestionIdList?questionIds=${
          //@ts-ignore
          Array.from(uniqQuestionIdsInSession[sessionId]).join(',')
        }&sessionId=${encodeURIComponent(sessionId)}`
      );
    },
  },
  routes: {
    downloadReport: true,
  },

  session: {
    downloadReport: hasFeatures('export_system_log') ? () => <GetLogModalWithReporter /> : undefined,
    downloadMessageReport: hasFeatures('export_system_log') ? GetMessageLoglWithReporter : undefined,
    showBackButton: true,
    downloadIssue: ({ projectShortName, sessionId }: { projectShortName: string; sessionId: string }) => {
      return axios.get(`/restapi/logs/issues/${projectShortName}/session-issues/byNaturalId?sessionId=${sessionId}`);
    },
    //@ts-ignore
    tasksEditComponent: WrappedTaskEditModalForReporter,
    labelsEditComponent: true,
  },

  //@ts-ignore
  diagrams: ({ filters, withReporter }) => <Diagram filters={filters} withReporter={withReporter} />,

  messageItem: {
    //@ts-ignore
    commentEditComponent: EditCommentModalForReporter,
    //@ts-ignore
    intentEditComponent: EditIntentsModalForReporter,
    //@ts-ignore
    tasksEditComponent: WrappedTaskEditModalForReporter,
    //@ts-ignore
    labelsEditComponent: true,
    //@ts-ignore
  },
  client: {
    hasWriteRight: isAccess('CLIENTS_WRITE'),
    hasReadRight: isAccess('CLIENT_ANALYTICS_READ'),
  },
  tags: {
    hasWriteRight: isAccess('TAGS_WRITE'),
    hasReadRight: isAccess('TAGS_READ'),
  },
  clients: {
    redirectToClients: true,
    downloadReport: true,
    withoutTopLabel: true,
    useOldClientsFilters: true,
  },

  showOverviewSection: ['volumes', 'results'],
  showSessionsSection: ['sessions', 'messages', 'diagrams', 'routes'],
});
