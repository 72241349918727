var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getRandomString, uniquify, findDuplicates } from './common';
export var IntentItemType;
(function (IntentItemType) {
    IntentItemType[IntentItemType["phrase"] = 0] = "phrase";
    IntentItemType[IntentItemType["pattern"] = 1] = "pattern";
})(IntentItemType || (IntentItemType = {}));
export var FAQBaseIntentName = 'KnowledgeBase';
export function generateFAQPathByTemplateName(name) {
    return "/".concat(FAQBaseIntentName, "/").concat(name);
}
export var getFAQTemplateName = function (path) {
    var parts = path.split('/').filter(Boolean);
    if (parts.length > 1 && parts[0] === FAQBaseIntentName) {
        return parts[1];
    }
    return '';
};
export var mapIntentsToTree = function (intents) {
    return intents.reduce(addIntentToDataset, {});
};
export var addIntentToDataset = function (dataset, intent) {
    var node = toTreeNode(intent);
    getAllParentPathsReverse(node.path).forEach(function (element, i, array) {
        return addChildToNode(dataset, element, array[i - 1] || node.path, node.itemsCount);
    });
    var optionalNode = dataset[node.path];
    if (optionalNode) {
        node.children = optionalNode.children;
        node.childrenItemsCount = optionalNode.childrenItemsCount;
    }
    return addNode(dataset, node);
};
export var getAllParentPathsReverse = function (path) {
    var parents = [];
    for (var i = path.length - 1; i > 0; i--) {
        if (path[i] === '/')
            parents.push(path.slice(0, i));
    }
    return parents;
};
var addChildToNode = function (dataset, path, childPath, itemsCount) {
    var optionalNode = dataset[path];
    var node = optionalNode ? optionalNode : createGhostTreeNode(path);
    node.children = uniquify(__spreadArray(__spreadArray([], node.children, true), [childPath], false));
    node.childrenItemsCount = node.childrenItemsCount + itemsCount;
    return addNode(dataset, node);
};
var replaceChildInNode = function (dataset, node, oldChild, newChild) {
    var oldChildIndex = node.children.findIndex(function (child) { return child === oldChild; });
    node.children[oldChildIndex] = newChild;
    return addNode(dataset, node);
};
export var toTreeNode = function (intent) { return ({
    id: Number(intent.id),
    nodeId: intent.path ? intent.path : '',
    path: intent.path ? intent.path : '',
    enabled: Boolean(intent.enabled),
    name: getNameFromPath(intent.path),
    parent: getParentPathFromPath(intent.path),
    itemsCount: intent.itemsCount ? intent.itemsCount : 0,
    children: [],
    childrenItemsCount: 0,
    isGhostNode: false,
}); };
var createGhostTreeNode = function (path) { return ({
    id: NaN,
    nodeId: path,
    path: path,
    enabled: false,
    name: getNameFromPath(path),
    parent: getParentPathFromPath(path),
    itemsCount: 0,
    children: [],
    childrenItemsCount: 0,
    isGhostNode: true,
}); };
export var getNameFromPath = function (path) { return (path ? path.substring(path.lastIndexOf('/') + 1) : ''); };
export var getParentPathFromPath = function (path) { return (path ? path.substring(0, path.lastIndexOf('/')) : ''); };
export var getItemsCountString = function (node) {
    return node.childrenItemsCount ? "".concat(node.itemsCount, " / ").concat(node.childrenItemsCount) : "".concat(node.itemsCount);
};
export var getNewUniquePath = function (defaultNewIntentName, parentPath, tree) {
    var simplePath = "".concat(parentPath, "/").concat(defaultNewIntentName);
    if (!tree) {
        return "".concat(simplePath, "_").concat((Math.random() * 10000).toFixed());
    }
    if (!isIntentWithPathExists(simplePath, tree))
        return simplePath;
    for (var index = 1; index <= Infinity; index++) {
        var path = "".concat(parentPath, "/").concat(defaultNewIntentName, " ").concat(index);
        if (!isIntentWithPathExists(path, tree))
            return path;
    }
    return "".concat(parentPath, "/").concat(defaultNewIntentName, " ").concat(getRandomString());
};
var isIntentWithPathExists = function (path, tree) {
    return Object.values(tree).findIndex(function (node) { return node.path === path; }) > -1;
};
export var updateIntentPathInTree = function (dataset, oldPath, newPath) {
    var newDataset = __assign({}, dataset);
    var parentPath = newDataset[oldPath].parent;
    if (parentPath)
        replaceChildInNode(newDataset, newDataset[parentPath], oldPath, newPath);
    return updatePathInTree(newDataset, oldPath, newPath);
};
var updatePathInTree = function (dataset, oldPath, newPath) {
    var node = dataset[oldPath];
    node.path = newPath;
    node.nodeId = newPath;
    node.name = getNameFromPath(newPath);
    var oldChildren = node.children;
    node.children = node.children.map(function (child) { return newPath + child.slice(oldPath.length); });
    if (node.parent)
        node.parent = getParentPathFromPath(newPath);
    delete dataset[oldPath];
    addNode(dataset, node);
    return oldChildren.length
        ? oldChildren.reduce(function (acc, child, childIndex) { return updatePathInTree(acc, child, node.children[childIndex]); }, dataset)
        : dataset;
};
export var updateIntentItemsCountInTree = function (dataset, path, newItemsCount) {
    var newDataset = __assign({}, dataset);
    var node = newDataset[path];
    addNode(newDataset, __assign(__assign({}, node), { itemsCount: newItemsCount }));
    var diff = newItemsCount - node.itemsCount;
    return updateChildrenItemsCountUpToRoot(newDataset, node.parent, diff);
};
var updateChildrenItemsCountUpToRoot = function (dataset, path, diff) {
    if (!path)
        return dataset;
    var node = dataset[path];
    addNode(dataset, __assign(__assign({}, node), { childrenItemsCount: node.childrenItemsCount + diff }));
    return updateChildrenItemsCountUpToRoot(dataset, node.parent, diff);
};
export var updateIntentEnabledInTree = function (tree, path, enabled) {
    var updatedNodes = {};
    updateEnabled(tree, path, enabled, updatedNodes);
    return __assign(__assign({}, tree), updatedNodes);
};
var updateEnabled = function (dataset, path, enabled, updatedNodes) {
    updatedNodes[path] = __assign(__assign({}, dataset[path]), { enabled: enabled });
    dataset[path].children.forEach(function (childPath) { return updateEnabled(dataset, childPath, enabled, updatedNodes); });
};
export var mergeNewIntentIntoTree = function (intent, dataset) {
    var newDataset = __assign({}, dataset);
    var node = toTreeNode(toSummaryData(intent));
    addNode(newDataset, node);
    if (!node.parent)
        return newDataset;
    var parent = dataset[node.parent];
    //TODO: Add logic to create ghost nodes
    if (!parent)
        return newDataset;
    return addChildToNode(newDataset, node.parent, node.path, 0);
};
export var replaceGhostNodeWithRealNode = function (intent, path, dataset) {
    var newDataset = __assign({}, dataset);
    var node = toTreeNode(toSummaryData(intent));
    var ghostNode = dataset[path];
    node.children = ghostNode.children;
    node.childrenItemsCount = ghostNode.childrenItemsCount;
    return addNode(newDataset, node);
};
export var removeIntentsFromTree = function (removedIds, dataset) {
    return Object.values(dataset)
        .filter(function (_a) {
        var id = _a.id;
        return !removedIds.includes(id);
    })
        .map(function (node) {
        return node.children
            ? __assign(__assign({}, node), { children: node.children.filter(function (childPath) { return dataset[childPath] && !removedIds.includes(dataset[childPath].id); }) }) : node;
    })
        .reduce(addNode, {});
};
var addNode = function (dataset, node) {
    var _a;
    return Object.assign(dataset, (_a = {}, _a[node.nodeId] = node, _a));
};
export var getRealChildrenIds = function (dataset, selectedIntentPaths) {
    var realChildrenIds = [];
    selectedIntentPaths.forEach(function (path) { return updateRealChildrenIds(dataset, path, realChildrenIds); });
    return uniquify(realChildrenIds);
};
var updateRealChildrenIds = function (dataset, path, realChildrenIds) {
    if (!dataset[path].isGhostNode) {
        realChildrenIds.push(dataset[path].id);
    }
    dataset[path].children.forEach(function (childPath) { return updateRealChildrenIds(dataset, childPath, realChildrenIds); });
};
export var getDuplicateSlotNames = function (slots) {
    var slotsNames = slots ? slots.map(function (slot) { return String(slot.name); }) : [];
    return findDuplicates(slotsNames);
};
export var getEmptySlotNames = function (slots) {
    return slots
        ? slots.filter(function (slot) { return !slot.name || !/\S/.test(String(slot.name)) || !slot.entity; }).map(function (slot) { return String(slot.name); })
        : [];
};
export var toIntentItem = function (text, type) { return ({
    type: type,
    text: text,
}); };
export var phraseToIntentItem = function (phrase) {
    return toIntentItem(phrase.text || '', IntentItemType.phrase);
};
export var patternToIntentItem = function (pattern) {
    return toIntentItem(pattern || '', IntentItemType.pattern);
};
var intentItemToPhrase = function (item) { return ({
    text: item.text,
}); };
var intentItemToPattern = function (item) { return item.text; };
var splitItemsByType = function (items) {
    var _a;
    return items.reduce(function (acc, item) {
        var _a;
        return Object.assign(acc, (_a = {},
            _a[item.type] = __spreadArray(__spreadArray([], acc[item.type], true), [
                item.type === IntentItemType.phrase ? intentItemToPhrase(item) : intentItemToPattern(item),
            ], false),
            _a));
    }, (_a = {}, _a[IntentItemType.phrase] = [], _a[IntentItemType.pattern] = [], _a));
};
export var updateItems = function (intent, items) {
    var phrasesAndPatterns = splitItemsByType(items);
    return __assign(__assign({}, intent), { phrases: phrasesAndPatterns[IntentItemType.phrase], patterns: phrasesAndPatterns[IntentItemType.pattern] });
};
export var convertToIntentWithItems = function (intent) {
    var denulledIntent = __assign(__assign({}, intent), { phrases: (intent === null || intent === void 0 ? void 0 : intent.phrases) ? intent === null || intent === void 0 ? void 0 : intent.phrases : [], patterns: (intent === null || intent === void 0 ? void 0 : intent.patterns) ? intent === null || intent === void 0 ? void 0 : intent.patterns : [] });
    return {
        intent: denulledIntent,
        items: __spreadArray(__spreadArray([], denulledIntent.phrases.map(phraseToIntentItem), true), denulledIntent.patterns.map(patternToIntentItem), true),
    };
};
export var toGroup = function (intent) { return ({
    intentId: intent.id,
    name: intent.path,
    size: intent.stagedPhrases ? intent.stagedPhrases.length : 0,
    phrasesIndexes: intent.stagedPhrases ? intent.stagedPhrases.map(function (phraseIdx) { return ({ phraseIdx: phraseIdx }); }) : [],
}); };
var toSummaryData = function (intent) { return ({
    id: Number(intent.id),
    path: String(intent.path),
    enabled: Boolean(intent.enabled),
    itemsCount: intent.phrases ? intent.phrases.length : 0,
}); };
export var isValidPath = function (path) { return path && !path.endsWith('/') && !path.match(/\/[\s]+$/); };
