import React, { ContextType, PureComponent, useState } from 'react';
import { getBasePathIntentsUrl, Intents } from '../../utils';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  SearchInput,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  TreeState,
  InputText,
  Tooltip,
  Dropdown,
} from '@just-ai/just-ui/dist';
import classNames from 'classnames';
import { AppContext } from '../AppContext';
import { i18nTranslation } from '../../locale/i18nToLocalize';
import { LinkComponentType, MessageComponentType, Theme } from '@just-ai/just-ui/dist/Tree/components';
import { getRealChildrenIds, IntentDataset, IntentTreeNode } from '../../utils/intents';
import './IntentsTree.scss';

const MAX_NESTING = 30;

interface IntentsTreeProps {
  tree: IntentDataset;
  openImportModal?: () => unknown;
  selectedIntentPaths: string[];
  handleSelect: (selectedPaths: string[]) => unknown;
  createIntent: (path: string) => unknown;
  handleSearch: (value: string) => unknown;
  confirmDelete?: (idsToDelete: number[]) => unknown;
  whiteBackground?: boolean;
  onChangeName?: (name: string, nodeId: string) => unknown;
  exportIntents?: () => unknown;
  writeDisabled?: boolean;
}

class IntentsTreeState {
  isActionPanelShown: boolean = true;
  treeFilter: string = '';
}

class IntentsTree extends PureComponent<IntentsTreeProps, IntentsTreeState> {
  static contextType = AppContext;
  context!: ContextType<typeof AppContext>;

  state = new IntentsTreeState();
  hideActionPanel = () => this.setState({ isActionPanelShown: false });
  showActionPanel = () => this.setState({ isActionPanelShown: true });
  createTopLevelIntent = () => this.props.createIntent('');
  translation = i18nTranslation('IntentsTree');

  TreeMessage: MessageComponentType = ({ type }) => {
    const treeMessages = {
      nothingFound: this.translation.t('treeSearchNotFound'),
      isEmpty: this.translation.t('treeEmptyList'),
    };
    return <div className='tree-message'>{treeMessages[type]}</div>;
  };

  render() {
    const { isActionPanelShown, treeFilter } = this.state;
    const {
      openImportModal,
      tree,
      handleSelect,
      selectedIntentPaths,
      whiteBackground,
      exportIntents,
      handleSearch,
      confirmDelete,
      createIntent,
      writeDisabled,
      ...restProps
    } = this.props;
    const { t } = this.translation;
    const { projectShortName } = this.context;
    return (
      <div {...restProps} className='intents-tree-wrapper'>
        <Toolbar>
          {isActionPanelShown && (
            <ToolbarLeft>
              <Button
                onClick={this.createTopLevelIntent}
                size='sm'
                color='primary'
                flat
                data-test-id='IntentsPage.IntentsTree.ButtonCreate'
                disabled={writeDisabled}
              >
                <Icon name='faPlusCircle' size='sm' color='primary' /> {t('toolbarButtonCreateIntent')}
              </Button>{' '}
              {openImportModal ? (
                <Button
                  onClick={openImportModal}
                  size='sm'
                  color='primary'
                  flat
                  data-test-id='IntentsPage.IntentsTree.ButtonImport'
                  disabled={writeDisabled}
                >
                  <Icon name='faUpload' size='sm' color='primary' /> {t('toolbarButtonImport')}
                </Button>
              ) : null}
              {exportIntents ? (
                <Button
                  onClick={exportIntents}
                  size='sm'
                  color='primary'
                  id='export-intents'
                  flat
                  data-test-id='IntentsPage.IntentsTree.ButtonExport'
                >
                  <Icon name='faFileExport' size='sm' color='primary' />{' '}
                  <Tooltip placement='bottom' target='export-intents'>
                    {t('exportButtonTooltip')}
                  </Tooltip>
                </Button>
              ) : null}
            </ToolbarLeft>
          )}
          <ToolbarRight style={{ flexGrow: !isActionPanelShown ? 1 : undefined }}>
            <SearchInput
              onOpen={this.hideActionPanel}
              onClear={this.showActionPanel}
              onChange={handleSearch}
              data-test-id='IntentsPage.IntentsTree.SearchField'
            />
          </ToolbarRight>
        </Toolbar>
        <div className='tree-content'>
          <TreeState
            dataset={tree}
            className='intents-tree'
            selectedIds={selectedIntentPaths}
            prefix={`${getBasePathIntentsUrl('/' + projectShortName)}/`}
            filter={treeFilter}
            Leaf={leaf(this.props, this.translation.t)}
            Head={leaf(this.props, this.translation.t)}
            Message={this.TreeMessage}
            onSelect={handleSelect}
            theme={whiteBackground ? Theme.light : Theme.dark}
            saveToLocalStorageName={`intents-tree__${projectShortName}`}
            searchField='path'
          />
        </div>
      </div>
    );
  }
}

function leaf(parentProps: IntentsTreeProps, t: (key: string) => string): LinkComponentType {
  return ({ node, depth, isSelected }) => {
    const [nodeName, setNodeName] = useState(node.name);
    const [isEdit, setEdit] = useState(false);
    const validateName = (name: string) => !name.includes('/');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setDropdownOpen(prevState => !prevState);
    };
    const onKeyUp = (e: KeyboardEvent) => {
      e.stopPropagation();
      e.key === 'Enter' && changeName();
      if (e.key === 'Escape') {
        setEdit(false);
        setNodeName(node.name);
      }
    };
    const changeName = () => {
      setEdit(false);
      const newName = nodeName.replace(/^([\s]+)/, '').replace(/([\s]+)$/, '');
      setNodeName(newName);
      if (newName !== node.name) {
        parentProps.onChangeName && parentProps.onChangeName(newName, node.nodeId);
      }
    };
    return (
      <div
        className={classNames('tree-link__leaf', {
          selected: isSelected,
          white: parentProps.whiteBackground,
          disabled: !node.enabled && !isSelected,
        })}
        data-test-id='IntentsPage.IntentsTree.Node'
        data-test-path={`IntentsPage.IntentsTree.Path-${node.path?.trim()}`}
      >
        <span className='intents-tree__name'>
          {isEdit ? (
            <InputText
              value={nodeName}
              onBlur={changeName}
              onChange={name => validateName(name) && setNodeName(name)}
              onKeyUp={onKeyUp}
              autoFocus
            />
          ) : (
            nodeName
          )}
        </span>
        <span>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='down'>
            <DropdownToggle
              color='link'
              size='sm'
              className={classNames('intents-tree__dropdown-toggle', { selected: isSelected })}
              data-test-id='IntentsPage.IntentsTree.Node.DropdownButton'
            >
              <Icon className='intents-tree__icon' name='faEllipsisV' size='sm' />
              <span className='intents-tree__counter'>{Intents.getItemsCountString(node as IntentTreeNode)}</span>
            </DropdownToggle>
            <DropdownMenu>
              {depth < MAX_NESTING ? (
                <DropdownItem
                  onClick={() => parentProps.createIntent(node.path)}
                  disabled={parentProps.writeDisabled}
                  data-test-id='IntentsPage.IntentsTree.Node.CreateNestedIntent'
                >
                  {t('dropdownCreateNestedIntent')}
                </DropdownItem>
              ) : null}
              {parentProps.onChangeName ? (
                <DropdownItem
                  disabled={parentProps.writeDisabled}
                  data-test-id='IntentsPage.IntentsTree.Node.RenameIntent'
                  onClick={() => setEdit(true)}
                >
                  {t('dropdownRenameIntent')}
                </DropdownItem>
              ) : null}
              {parentProps.confirmDelete ? (
                <DropdownItem
                  style={{ color: 'red' }}
                  onClick={() =>
                    parentProps.confirmDelete &&
                    parentProps.confirmDelete(getRealChildrenIds(parentProps.tree, [node.nodeId]))
                  }
                  disabled={parentProps.writeDisabled}
                  data-test-id='IntentsPage.IntentsTree.Node.DeleteIntent'
                >
                  {t('dropdownDeleteIntent')}
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </Dropdown>
        </span>
      </div>
    );
  };
}

export default IntentsTree;
