var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _this = this;
import { useCallback, useState } from 'react';
import { t } from '../../localization';
import { isExistKey } from '../locale/i18nToLocalize';
export var CONNECTION_ABORTED_CODE = 'ECONNABORTED';
export var UNKNOWN_ERROR_MESSAGE_CODE = 'UnknownError';
var ERRORS_TRANSLATION_NAMESPACE = 'Errors';
var isArgsCoords = function (args) { return Boolean(args.coordinates); };
var isArgsCell = function (args) { return Boolean(args.cellValue); };
var isArgsSize = function (args) { return Boolean(args.maxSizeInMB); };
var isArgsMaxRows = function (args) { return Boolean(args.maxNumberOfRows); };
var isArgsHeaders = function (args) { return Boolean(args.headers); };
var isArgsHeader = function (args) { return Boolean(args.header); };
var isArgsExtension = function (args) { return Boolean(args.extension); };
var isArgsPattern = function (args) { return Boolean(args.pattern); };
var isArgsMaxReplies = function (args) {
    return Boolean(args.maxRepliesNumber);
};
export var isCommonError = function (error) {
    if (error.message && typeof error.error === 'string')
        return true;
    return false;
};
export var useError = function () {
    var _a = useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var setErrorCallback = useCallback(function (reason) {
        setErrorMessage(getErrorMessageFromReason(reason));
    }, []);
    var clearErrorCallback = useCallback(function () { return setErrorMessage(null); }, []);
    return [errorMessage, setErrorCallback, clearErrorCallback];
};
export var addErrorNamespace = function (code) { return "".concat(ERRORS_TRANSLATION_NAMESPACE, ":").concat(code); };
export var getErrorCodeFromReason = function (reason) {
    var _a, _b, _c, _d, _e;
    if (typeof reason === 'string')
        return reason;
    if (typeof reason !== 'object' || reason === null)
        return UNKNOWN_ERROR_MESSAGE_CODE;
    if (reason.code === CONNECTION_ABORTED_CODE)
        return CONNECTION_ABORTED_CODE;
    if (typeof ((_b = (_a = reason === null || reason === void 0 ? void 0 : reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === 'string')
        return reason.response.data.error;
    if (typeof ((_e = (_d = (_c = reason === null || reason === void 0 ? void 0 : reason.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.errorCode) === 'string')
        return reason.response.data.error.errorCode;
    return UNKNOWN_ERROR_MESSAGE_CODE;
};
//TODO remove parameter after caila error fix ZB-20236
export var getErrorMessageFromReason = function (reason, translate, cailaError) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var translateFunc = translate || t;
    var errorCode = getErrorCodeFromReason(reason);
    var errorMessage = cailaError ? (_c = (_b = (_a = reason === null || reason === void 0 ? void 0 : reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message : (_e = (_d = reason === null || reason === void 0 ? void 0 : reason.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message;
    if (((_g = (_f = reason === null || reason === void 0 ? void 0 : reason.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.errors) && ((_j = (_h = reason === null || reason === void 0 ? void 0 : reason.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.errors.length) > 0 && !errorMessage) {
        if (!errorMessage) {
            errorMessage = '';
        }
        reason.response.data.errors.forEach(function (error) {
            if (typeof error === 'string') {
                errorMessage += error + '</br>';
            }
        });
    }
    if (errorCode || errorMessage) {
        if (errorMessage === 'No message available' && isExistKey(addErrorNamespace(errorCode)))
            return translateFunc(addErrorNamespace(errorCode));
        if (errorMessage && errorCode === UNKNOWN_ERROR_MESSAGE_CODE)
            return errorMessage;
        if (isExistKey(addErrorNamespace(errorCode))) {
            try {
                //@ts-ignore
                return translateFunc.apply(_this, __spreadArray([
                    addErrorNamespace(errorCode)
                ], Object.values(getErrorArgsFromReason(reason)), true));
            }
            catch (e) {
                return translateFunc(addErrorNamespace(errorCode));
            }
        }
        return errorMessage || errorCode || translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
    }
    return translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
};
export var getErrorArgsFromReason = function (reason) {
    var _a, _b, _c, _d, _e;
    if (!reason)
        return;
    return ((_c = (_b = (_a = reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.args) || ((_e = (_d = reason.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.args);
};
export var getNLUErrorFromResponse = function (payload) {
    var data = { error: '' };
    var lastError = payload.data.lastError;
    if (lastError) {
        try {
            data = typeof lastError === 'string' ? JSON.parse(lastError) : { error: lastError };
        }
        catch (e) {
            // @ts-ignore
            data = { error: e.toString() };
        }
    }
    return { response: { data: data } };
};
export var processErrorsArray = function (errorsArr) {
    if (errorsArr === void 0) { errorsArr = []; }
    var translate = t;
    var errorArgNames = ['settings', 'extSettingsAlgorithm', 'classifier', 'tokenizer', 'analyzer', 'id'];
    return errorsArr.map(function (error) {
        return isExistKey("Errors:".concat(error.errorCode))
            ? translate("Errors:".concat(error.errorCode), {
                error: error.args[Object.keys(error.args).find(function (key) { return errorArgNames.includes(key); }) || ''],
            })
            : translate('caila set language error');
    });
};
var getMessageFromError = function (args, errorCode) {
    if (!args)
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode);
    if (isArgsPattern(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.coordinates.row, args.coordinates.column, args.lineNumber, args.message);
    }
    if (isArgsMaxReplies(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxRepliesNumber, args.coordinates.row);
    }
    if (isArgsCoords(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.coordinates.row, args.coordinates.column);
    }
    if (isArgsCell(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.cellValue);
    }
    if (isArgsSize(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxSizeInMB);
    if (isArgsMaxRows(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxNumberOfRows);
    if (isArgsHeaders(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.headers);
    if (isArgsHeader(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.header);
    if (isArgsExtension(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.extension);
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode);
};
export var getMessageTranslationFromImportError = function (reason) {
    if (!reason.response)
        return t('Unexpected error');
    var errorBody = reason.response.data;
    if (isCommonError(errorBody)) {
        return typeof errorBody.error === 'string' && isExistKey(errorBody.error) ? t(errorBody.error) : errorBody.message;
    }
    var _a = errorBody.error, args = _a.args, errorCode = _a.errorCode;
    if (errorBody.errors.length === 1) {
        return getMessageFromError(args, errorCode);
    }
    return errorBody.errors.map(function (error) { return getMessageFromError(error.args, error.errorCode); }).join('\n\n');
};
export var getDeletedPhrasesMessage = function (count, language) {
    var intl = new Intl.PluralRules(language);
    var phrasesDeclination = intl.select(count);
    if (count === 1)
        return t('LogLabelingPage:deletedPhrasesInfo_one');
    return phrasesDeclination === 'one'
        ? t('LogLabelingPage:deletedPhrasesInfo_one--with-number', count, t("LogLabelingPage:deletedPhrasesCount_".concat(phrasesDeclination)))
        : t('LogLabelingPage:deletedPhrasesInfo_other', count, t("LogLabelingPage:deletedPhrasesCount_".concat(phrasesDeclination)));
};
export var assignValidationErrorToReply = function (errorData, question) {
    if (!errorData || !errorData.length)
        return question;
    errorData.forEach(function (answer) {
        var replyToUpdateIndex = question.replies.findIndex(function (reply) { return reply.text === answer.origin; });
        if (question.replies[replyToUpdateIndex]) {
            question.replies[replyToUpdateIndex].error = 'validation';
        }
    });
    return question;
};
export var assignValidationErrorToPhrase = function (errorData, phrases) {
    if (!errorData || !errorData.length)
        return phrases;
    var newPhrases = __spreadArray([], phrases, true);
    errorData.forEach(function (error) {
        var phraseToUpdateIndex = newPhrases.findIndex(function (phrase) { return phrase.text === error.origin; });
        if (newPhrases[phraseToUpdateIndex]) {
            newPhrases[phraseToUpdateIndex].error = 'validation';
        }
    });
    return newPhrases;
};
/**
 * @param {number} deletedCount число удаленных фраз
 * @param {number} updatedCount число измененных фраз
 * @param {string} language язык для склонения
 *
 * Конструктор фразы о результатах предобработки фраз.
 * Если оба параметра - 0, то просто строка-уведомление о завершении предобработки.
 *
 * Если есть оба параметра, то строка вида
 *
 * Предобработка завершена: X фраз изменены, Y — удалены.
 * Preprocessing completed: X phrases changed, Y phrases deleted.
 *
 * Если есть что то одно - то часть строки выше
 *
 * */
export var getProcessedPhrasesMessage = function (deletedCount, updatedCount, language) {
    if (!deletedCount && !updatedCount)
        return t('LogLabelingPage:cleanDataCompleted') + '.';
    var intl = new Intl.PluralRules(language);
    var deletedDeclination = intl.select(deletedCount);
    var changedDeclination = intl.select(updatedCount);
    if (deletedCount && !updatedCount) {
        return "".concat(t('LogLabelingPage:cleanDataCompleted'), ": ").concat(deletedCount, " ").concat(t("LogLabelingPage:deletedPhrasesCount_".concat(deletedDeclination)), " ").concat(t("LogLabelingPage:deletedCount_".concat(deletedDeclination)), ".");
    }
    if (updatedCount && !deletedCount) {
        return "".concat(t('LogLabelingPage:cleanDataCompleted'), ": ").concat(updatedCount, " ").concat(t("LogLabelingPage:deletedPhrasesCount_".concat(changedDeclination)), " ").concat(t("LogLabelingPage:changedCount_".concat(changedDeclination)), ".");
    }
    if (language.toLowerCase() === 'ru') {
        return "".concat(t('LogLabelingPage:cleanDataCompleted'), ": ").concat(updatedCount, " ").concat(t("LogLabelingPage:deletedPhrasesCount_".concat(changedDeclination)), " ").concat(t("LogLabelingPage:changedCount_".concat(changedDeclination)), ", ").concat(deletedCount, " \u2014 ").concat(t("LogLabelingPage:deletedCount_".concat(deletedDeclination)));
    }
    return "".concat(t('LogLabelingPage:cleanDataCompleted'), ": ").concat(updatedCount, " ").concat(t("LogLabelingPage:deletedPhrasesCount_".concat(changedDeclination)), " ").concat(t("LogLabelingPage:changedCount_".concat(changedDeclination)), ", ").concat(deletedCount, " ").concat(t("LogLabelingPage:deletedPhrasesCount_".concat(changedDeclination)), " ").concat(t("LogLabelingPage:deletedCount_".concat(deletedDeclination)));
};
