import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  animationFrameScheduler,
  Observable,
  scan,
  scheduled,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  BehaviorSubject,
  endWith,
} from 'rxjs';
import { ConnectorStore, ScrollToTarget, TConnectorSubj } from './types';
import { JStateWithId, TConnector } from '../../../reducers/JGraph.reducer/types';
import { useScrollToTarget, useScrollToTargetGlobal } from '../utils/stageUtils';
import Konva from 'konva';

import { useAutoplacement } from '../hooks/useAutoplacement';
import { recalculateConnectionsOnBlockMove } from '../utils/connectionLayerUtils';
import { getConnectionsFromBlocks, getValidKonvaName } from '../../../reducers/JGraph.reducer/Graph';
import { JGraphContextType, useJGraphContext } from './JGraphContext';
import { StickerInfo } from '../view/Sticker/types';

export type StageObservablesContextType = {
  ConnectorsSubject$: Subject<TConnectorSubj>;
  ConnectorsStore$: Observable<TConnector[]>;
  connectorsFromPipe$: BehaviorSubject<ConnectorStore>;
  connectorsFromStore$: Observable<ConnectorStore>;
  scrollToTarget$: Subject<ScrollToTarget>;
  getConnectors: () => TConnector[];
  getAboveConnectors: () => TConnector[];
  getStage: (stage: Konva.Stage | null) => unknown;
  GStage: Konva.Stage | null;
  recalculateConnections: (event: Konva.KonvaEventObject<DragEvent>) => unknown;
  canRenderState$: Subject<string>;
  renderStorePipe$: Observable<string>;

  selectedGroupPath: string | undefined;
  upComingSelectedGroup?: { path: string; pathId: string };
  selectedGroupPathId: string | undefined;
  setSelectedGroup: (value: { path?: string; pathId?: string }) => unknown;
  setPreviousGroup: () => void;
  isActive: boolean;
  jGraphContext?: JGraphContextType;
  // Provider: ({ children }: PropsWithChildren<object>) => JSX.Element;
};
export const StageObservablesContext = createContext({} as StageObservablesContextType);
export const useStageObservableContext = () => useContext(StageObservablesContext);

export const RerenderScreensEvent$ = new Subject();

type StageObservablesProviderProps = {
  screens: JStateWithId[];
  startState?: JStateWithId;
  stickers: StickerInfo[];
  upComingSelectedGroup?: { path: string; pathId: string };
};

const StageObservablesProvider = React.memo<PropsWithChildren<StageObservablesProviderProps>>(
  ({ children, screens, stickers, startState, upComingSelectedGroup }) => {
    const { getConnectors: getAboveContextConnectors, setSelectedGroup: setSelectedGroupAbove } =
      useStageObservableContext();
    const jGraphContext = useJGraphContext();

    const [selectedGroup, setSelectedGroup] = useState<{ path?: string; pathId?: string }>({});
    const [, setStageIsSetted] = useState<boolean>(false);
    const renderStorePipeSubscription$ = useRef<Subscription | null>(null);
    const GStage = useRef<Konva.Stage | null>(null);

    const isActive = useMemo(() => {
      return !!upComingSelectedGroup || (!selectedGroup.path && !upComingSelectedGroup);
    }, [selectedGroup.path, upComingSelectedGroup]);

    const ConnectorsSubject$ = useMemo(() => {
      return new Subject<TConnectorSubj>();
    }, []);
    const ConnectorsStore$ = useMemo(() => {
      return ConnectorsSubject$.pipe(
        scan((prevValue, newValue) => {
          let returnValue = [...prevValue];
          switch (newValue.type) {
            case 'add': {
              returnValue.push(newValue.connector);
              break;
            }
            case 'remove': {
              const valueIndex = returnValue.findIndex(connector => connector === newValue.connector);
              if (valueIndex > -1) {
                returnValue.splice(valueIndex, 1);
              }
              break;
            }
          }
          return returnValue;
        }, [] as TConnector[]),
        shareReplay(1)
      );
    }, [ConnectorsSubject$]);

    const connectorsFromPipe$ = useMemo(() => {
      return new BehaviorSubject<ConnectorStore>({});
    }, []);

    const connectorsFromStore$ = useMemo(() => {
      return connectorsFromPipe$.pipe(
        scan((store: ConnectorStore, newValue) => {
          const name = Object.keys(newValue);
          name.forEach(name => {
            store[name] = {
              ...store[name],
              ...newValue[name],
            };
          });
          return store;
        }, {} as ConnectorStore)
      );
    }, [connectorsFromPipe$]);

    const renderStore$ = useMemo(() => {
      return new BehaviorSubject<string[]>([]);
    }, []);

    const canRenderState$ = useMemo(() => {
      return new Subject<string>();
    }, []);
    const renderStorePipeFilled = useRef<boolean>(false);
    const renderStorePipe$ = useMemo(() => {
      return renderStore$.pipe(
        switchMap(stored => scheduled(stored, animationFrameScheduler).pipe(endWith('__END__')))
      );
    }, [renderStore$]);

    const scrollToTarget$ = useMemo(() => {
      return new Subject<ScrollToTarget>();
    }, []);

    useEffect(() => {
      if (!renderStorePipeFilled.current) {
        const renderOrder = screens.map(screen => screen.pathId);
        renderStorePipeSubscription$.current = renderStorePipe$.subscribe();
        renderStore$.next(renderOrder);
        renderStorePipeFilled.current = true;
      }
    }, [renderStore$, renderStorePipe$, screens]);

    useEffect(() => {
      const sub = RerenderScreensEvent$.subscribe(() => {
        renderStorePipeFilled.current = false;
      });
      return () => sub.unsubscribe();
    }, []);

    const savedConnectors = useMemo(() => {
      return getConnectionsFromBlocks(screens);
    }, [screens]);

    const getConnectors = useCallback(() => {
      return savedConnectors.connections;
    }, [savedConnectors]);

    const getAboveConnectors = useCallback(() => {
      if (getAboveContextConnectors) {
        return getAboveContextConnectors();
      }
      return [];
    }, [getAboveContextConnectors]);

    useEffect(() => {
      const csSub = ConnectorsStore$.subscribe();

      return () => {
        csSub.unsubscribe();
        renderStorePipeSubscription$.current?.unsubscribe();
      };
    }, [ConnectorsStore$, renderStorePipe$]);

    const getStage = useCallback(
      (Stage: Konva.Stage | null) => {
        GStage.current = Stage;
        setStageIsSetted(isStageAlreadySetted => {
          if (!isStageAlreadySetted) return Boolean(Stage);
          return isStageAlreadySetted;
        });
      },
      [GStage]
    );

    const recalculateConnections = useCallback(
      (event: Konva.KonvaEventObject<DragEvent>) => {
        const nodeId = event.target.getAttr('name');
        recalculateConnectionsOnBlockMove(nodeId, GStage.current, ConnectorsStore$, connectorsFromStore$);
      },
      [ConnectorsStore$, GStage, connectorsFromStore$]
    );

    useScrollToTarget(scrollToTarget$, renderStorePipe$, GStage.current);
    useAutoplacement(
      !!upComingSelectedGroup,
      isActive,
      GStage.current,
      renderStorePipe$,
      screens,
      stickers,
      renderStore$,
      startState
    );

    const setSelectedGroupToTop = useCallback(
      (group: { path?: string; pathId?: string }) => {
        if (!setSelectedGroupAbove) {
          setSelectedGroup(group);
          return;
        }
        setSelectedGroupAbove(group);
      },
      [setSelectedGroupAbove]
    );

    const setPreviousGroup = useCallback(() => {
      if (!selectedGroup.path) return;
      const pathParts = selectedGroup.path.split('/');
      const prevGroupPath = pathParts.length >= 2 ? pathParts.slice(0, -1).join('/') : '';
      setSelectedGroupToTop({
        path: prevGroupPath,
        pathId: getValidKonvaName(prevGroupPath),
      });
    }, [selectedGroup.path, setSelectedGroupToTop]);

    useScrollToTargetGlobal(
      screens,
      scrollToTarget$,
      setSelectedGroupToTop,
      renderStorePipe$,
      {
        path: upComingSelectedGroup?.path,
        pathId: upComingSelectedGroup?.pathId,
      },
      selectedGroup
    );

    return (
      <StageObservablesContext.Provider
        value={{
          isActive,
          jGraphContext,
          upComingSelectedGroup,
          ConnectorsSubject$: ConnectorsSubject$,
          ConnectorsStore$: ConnectorsStore$,
          connectorsFromPipe$: connectorsFromPipe$,
          connectorsFromStore$: connectorsFromStore$,
          scrollToTarget$: scrollToTarget$,
          getConnectors: getConnectors,
          getAboveConnectors: getAboveConnectors,
          getStage: getStage,
          GStage: GStage.current,
          recalculateConnections: recalculateConnections,

          canRenderState$: canRenderState$,
          renderStorePipe$: renderStorePipe$,
          selectedGroupPath: selectedGroup.path || upComingSelectedGroup?.path,
          selectedGroupPathId: selectedGroup.pathId || upComingSelectedGroup?.pathId,
          setSelectedGroup: setSelectedGroupToTop,
          setPreviousGroup,
        }}
      >
        {children}
      </StageObservablesContext.Provider>
    );
  }
);
StageObservablesProvider.displayName = 'StageObservablesProvider';
export default StageObservablesProvider;
