import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
export const CustomRxTooltip = props => {
    const [show, setShow] = useState(props.isOpen);
    const selfRef = useRef(null);
    const [style, setStyle] = useState({});
    const [arrowOffsets, setArrowOffsets] = useState({});
    const targetRef = useRef(null);
    const setShowHandler = useCallback(() => {
        setShow(props.isOpen || true);
    }, [props.isOpen]);
    const setHideHandler = useCallback(() => {
        setShow(props.isOpen || false);
    }, [props.isOpen]);
    const calculatePositions = useCallback(() => {
        var _a, _b, _c, _d;
        const targetPosition = (_a = targetRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const selfSize = {
            width: ((_b = selfRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth) || 0,
            height: ((_c = selfRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) || 0,
        };
        let style = {
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            transform: '',
        };
        if (!targetPosition || !selfSize || !selfRef.current)
            return;
        const padding = (_d = props.padding) !== null && _d !== void 0 ? _d : 8;
        switch (props.placement) {
            case 'bottom': {
                style.transform = `translate(${targetPosition.left + targetPosition.width / 2 - selfSize.width / 2}px, ${targetPosition.top + targetPosition.height + padding}px)`;
                setArrowOffsets({
                    top: 0,
                    transform: 'translate(-50%, 0)',
                    left: '50%',
                });
                break;
            }
            case 'left': {
                style.transform = `translate(${targetPosition.left - selfSize.width - padding}px, ${targetPosition.top + targetPosition.height / 2 - selfSize.height / 2}px)`;
                setArrowOffsets({
                    top: selfSize.height / 2,
                    transform: 'translate(0, -50%)',
                    right: 0,
                });
                break;
            }
            case 'right': {
                style.transform = `translate(${targetPosition.left + targetPosition.width + 8}px, ${targetPosition.top + targetPosition.height / 2 - selfSize.height / 2}px)`;
                setArrowOffsets({
                    top: selfSize.height / 2,
                    transform: 'translate(0, -50%)',
                    left: 0,
                });
                break;
            }
            case 'top': {
                style.transform = `translate(${targetPosition.left + targetPosition.width / 2 - selfSize.width / 2}px, ${targetPosition.top - selfSize.height - padding}px)`;
                setArrowOffsets({
                    top: selfSize.height - 1,
                    transform: 'translate(-50%, -100%)',
                    left: '50%',
                });
                break;
            }
        }
        //@ts-ignore
        setStyle(style);
    }, [props.padding, props.placement]);
    useEffect(() => {
        var _a;
        let target = null;
        if (typeof props.target === 'string') {
            const targets = document.querySelectorAll(props.target);
            const targetsArray = Array.from(targets);
            if (targetsArray.length === 0 || targetsArray.length > 1) {
                throw new Error(targetsArray.length === 0 ? 'No target found' : `Too much targets found: ${targetsArray.length}`);
            }
            else {
                target = targetsArray[0];
                target.addEventListener('mouseenter', setShowHandler);
                target.addEventListener('mouseleave', setHideHandler);
                target.addEventListener('focusin', setShowHandler);
                target.addEventListener('focusout', setHideHandler);
                (_a = target.parentNode) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', calculatePositions);
                targetRef.current = target;
            }
        }
        return () => {
            var _a;
            target === null || target === void 0 ? void 0 : target.removeEventListener('mouseenter', setShowHandler);
            target === null || target === void 0 ? void 0 : target.removeEventListener('mouseleave', setHideHandler);
            target === null || target === void 0 ? void 0 : target.removeEventListener('focusin', setShowHandler);
            target === null || target === void 0 ? void 0 : target.removeEventListener('focusout', setHideHandler);
            (_a = target === null || target === void 0 ? void 0 : target.parentNode) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', calculatePositions);
            targetRef.current = null;
        };
    }, [calculatePositions, props.target, setHideHandler, setShowHandler]);
    useLayoutEffect(() => {
        if (show) {
            calculatePositions();
        }
        //props.children needed because we need to recalculate sizes and offsets
    }, [calculatePositions, props.children, props.placement, show]);
    if (!show)
        return null;
    return createPortal(React.createElement("div", null,
        React.createElement("div", { className: 'fade show' },
            React.createElement("div", { className: `tooltip show bs-tooltip-auto ${props.placement}`, ref: selfRef, style: style, "x-placement": props.placement },
                React.createElement("div", { className: cn('tooltip-inner', props.innerClassName), role: 'tooltip', style: { textAlign: props.textAlign } }, props.children),
                React.createElement("span", { className: 'arrow', style: arrowOffsets })))), document.body);
};
