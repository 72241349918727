import React from 'react';
import localize from '../../localization';

const AlertBanner = ({ appStatus }) => {
  let status;
  if (appStatus.activatedMoreThenHalfOfAdditionPackages) {
    status = 'activatedMoreThenHalfOfAdditionPackages';
  }
  if (appStatus.usedAllAdditionPackages) {
    status = 'usedAllAdditionPackages';
  }
  if (appStatus.noConnectionWithServerTooLong) {
    status = 'noConnectionWithServerTooLong';
  }
  if (appStatus.platformBlocked) {
    status = 'platformBlocked';
  }
  if (appStatus.noActualKey) {
    status = 'noActualKey';
  }
  if (appStatus.noConnectionWithServerNow) {
    status = 'noConnectionWithServerNow';
  }
  return <div className='limit-banner'>{localize.translate(`LimitBanner ${status}`)}</div>;
};

export default AlertBanner;
