var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FAQIntentRootGroup } from '../../../../constants/faqTemplates';
var TreeStructure = /** @class */ (function () {
    function TreeStructure(dataset, maxDepthLevel) {
        var _this = this;
        if (maxDepthLevel === void 0) { maxDepthLevel = 1; }
        this.dataset = dataset;
        this.maxDepthLevel = maxDepthLevel;
        this.buildBranch = function (branch, depthLevel, parentId) {
            if (depthLevel === void 0) { depthLevel = 0; }
            var isOutOfDepthLevel = depthLevel === _this.maxDepthLevel - 1;
            var children = Object.values(_this.dataset).filter(function (el) { return el.parent === branch.nodeId; });
            var branches = isOutOfDepthLevel
                ? []
                : children
                    .filter(function (el) { return el.children.length > 0; })
                    .sort(function (a, b) { return a.name.localeCompare(b.name); })
                    .map(function (branch) { return _this.buildBranch(branch, depthLevel + 1, branch.id); });
            var leafs = children
                .filter(function (el) { return el.children.length === 0; })
                .map(function (leaf) { return (__assign(__assign({}, leaf), { depthLevel: depthLevel + 1, parentId: branch.id })); });
            return __assign(__assign({}, branch), { parentId: parentId, depthLevel: depthLevel, branches: branches, leafs: leafs });
        };
        var branches = Object.values(dataset)
            .filter(function (el) { return el.isFolder; })
            .sort(function (a, b) { return a.name.localeCompare(b.name); })
            .map(function (branch) { return _this.buildBranch(branch, 0); });
        var RootLeafs = Object.values(dataset)
            .filter(function (el) { var _a; return (_a = el.path) === null || _a === void 0 ? void 0 : _a.startsWith("/".concat(FAQIntentRootGroup, "/")); })
            .map(function (node) { return (__assign(__assign({}, node), { depthLevel: 0 })); })
            .sort(function (a, b) { return a.name.localeCompare(b.name); });
        this.tree = {
            branches: branches,
            leafs: RootLeafs,
        };
    }
    TreeStructure.prototype.getElementById = function (id) {
        return Object.values(this.dataset).find(function (el) { return el.id === id; });
    };
    TreeStructure.getIdsInDepth = function (node, _result) {
        var _this = this;
        if (_result === void 0) { _result = []; }
        _result.push(node.id);
        if (node.leafs && node.leafs.length > 0) {
            _result = node.leafs.reduce(function (acc, node) { return _this.getIdsInDepth(node, acc); }, _result);
        }
        if (node.branches && node.branches.length > 0) {
            _result = node.branches.reduce(function (acc, node) { return _this.getIdsInDepth(node, acc); }, _result);
        }
        return _result;
    };
    TreeStructure.getNodeAsPlainList = function (node, extendedMap, _result) {
        var _this = this;
        if (_result === void 0) { _result = []; }
        node.containIds = new Set(TreeStructure.getIdsInDepth(node));
        _result.push(node);
        if (!node.isFolder || (extendedMap !== undefined && !extendedMap[node.id]))
            return _result;
        if (node.leafs && node.leafs.length > 0) {
            _result = node.leafs.reduce(function (acc, node) { return _this.getNodeAsPlainList(node, extendedMap, acc); }, _result);
        }
        if (node.branches && node.branches.length > 0) {
            _result = node.branches.reduce(function (acc, node) { return _this.getNodeAsPlainList(node, extendedMap, acc); }, _result);
        }
        return _result;
    };
    TreeStructure.prototype.getViewedTreeAsPlainList = function (extendedMap) {
        return __spreadArray(__spreadArray([], this.tree.branches, true), this.tree.leafs, true).map(function (node) { return TreeStructure.getNodeAsPlainList(node, extendedMap); })
            .flat();
    };
    TreeStructure.prototype.getTreeFlat = function () {
        return __spreadArray(__spreadArray([], this.getTreeLeafs(this.tree.branches), true), this.tree.leafs, true);
    };
    TreeStructure.prototype.getTreeLeafs = function (nodes) {
        var _this = this;
        return __spreadArray([], nodes === null || nodes === void 0 ? void 0 : nodes.map(function (node) {
            var _a, _b;
            var branches = ((_a = node === null || node === void 0 ? void 0 : node.branches) === null || _a === void 0 ? void 0 : _a.filter(Boolean)) || [];
            var leafs = ((_b = node === null || node === void 0 ? void 0 : node.leafs) === null || _b === void 0 ? void 0 : _b.filter(Boolean)) || [];
            return __spreadArray(__spreadArray([], _this.getTreeLeafs(branches), true), leafs, true);
        }).flat(), true);
    };
    TreeStructure.prototype.getViewedElementsCount = function (node, extendedMap, _count) {
        var _this = this;
        if (_count === void 0) { _count = 0; }
        _count++;
        if (!node.isFolder || !extendedMap[node.id])
            return _count;
        if (node.leafs && node.leafs.length > 0) {
            _count = node.leafs.reduce(function (acc, node) { return _this.getViewedElementsCount(node, extendedMap, acc); }, _count);
        }
        if (node.branches && node.branches.length > 0) {
            _count = node.branches.reduce(function (acc, node) { return _this.getViewedElementsCount(node, extendedMap, acc); }, _count);
        }
        return _count;
    };
    TreeStructure.prototype.getIdsBranchesInPathToId = function (id) {
        for (var _i = 0, _a = this.tree.branches; _i < _a.length; _i++) {
            var branch = _a[_i];
            var idsPath = this.getIdsBranchesInPathBranchToId(branch, id);
            if (idsPath.length > 0) {
                return idsPath;
            }
        }
        return [];
    };
    TreeStructure.prototype.getIdsBranchesInPathBranchToId = function (node, targetId, _extendedIds) {
        var _this = this;
        if (_extendedIds === void 0) { _extendedIds = []; }
        if (node.leafs && node.leafs.length > 0) {
            for (var _i = 0, _a = node.leafs; _i < _a.length; _i++) {
                var leaf = _a[_i];
                if (leaf.id === targetId) {
                    return [node.id];
                }
            }
        }
        if (node.branches && node.branches.length > 0) {
            _extendedIds = node.branches.reduce(function (acc, node) { return _this.getIdsBranchesInPathBranchToId(node, targetId, acc); }, _extendedIds);
        }
        if (_extendedIds.length > 0) {
            _extendedIds.push(node.id);
        }
        return _extendedIds;
    };
    return TreeStructure;
}());
export { TreeStructure };
