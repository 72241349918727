import React, { FC, useCallback, useEffect } from 'react';

import keyboardJS from 'keyboardjs';
import { Toolbar, ToolbarLeft, ToolbarRight } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist/Button';

import { t } from 'localization';
import localize from 'localization';
import { UiMode } from 'types';

import '../../Editor/pages/EditorPage/style.scss';
import history from '../../../appHistory';
import { editorLocalization } from 'modules/Editor/localization/editor.loc';
import { JGLocalization } from 'modules/JGraph/localization/JG.loc';
import IntentsService from 'modules/Caila/service/IntentsService';
import { useAppContext } from '../../Caila/components/AppContext';
import JGraphRollbackService from '../services/JGraphRollbackService';
import JGraphStatusIndicator from '../components/JGraphStatusIndicator';

import SearchState from './SearchState';
import { BlockModal } from './BlockModal';

import { JGraphView } from './index';
import SettingsService from '../services/SettingsService';
import JGraphGuideTour from './JGraphGuideTour';

import { RenderingBlockWrapper } from './RenderingModal/RenderingBlockWrapper';
import { JGraphContextProvider } from '../contexts/JGraphContext';
import { callIfAbleToApplyHotKey } from 'utils/hotkeys';

localize.addTranslations(editorLocalization);
localize.addTranslations(JGLocalization);

type JGraphProps = {
  IntentsService: IntentsService;
  SettingsService: SettingsService;
  projectName: string;
  uiMode: UiMode;
};

export const JGraph: FC<JGraphProps> = ({ IntentsService, SettingsService, projectName, uiMode }) => {
  const { projectShortName } = useAppContext();
  const setEditorMode = useCallback(() => {
    history.push(`/${projectShortName}/editor`);
  }, [projectShortName]);

  useEffect(() => {
    const sendRevertEvent = () => callIfAbleToApplyHotKey(JGraphRollbackService.rollback);

    JGraphRollbackService.init();
    keyboardJS.bind(['ctrl + z', 'command + z'], sendRevertEvent);

    return () => {
      keyboardJS.unbind(['ctrl + z', 'command + z'], sendRevertEvent);
      JGraphRollbackService.unsubscribeAll();
    };
  }, []);

  return (
    <JGraphContextProvider
      SettingsService={SettingsService}
      IntentsService={IntentsService}
      projectName={projectName}
      uiMode={uiMode}
    >
      <div className='editor__editor-page'>
        <JGraphGuideTour />
        <Toolbar className='editor__main-toolbar visual'>
          <ToolbarLeft>
            <div className='editor__main-toolbar_section editor__main-toolbar_mode-buttons'>
              <IconButton
                className='editorModeBtn'
                title={t('Editor:modeCode')}
                size='md'
                name='faCode'
                withoutPadding
                square
                onClick={setEditorMode}
                data-test-id='Editor.MainToolbar.modeCode'
              />
              <IconButton
                className='editorModeBtn'
                title={t('Editor:modeGraph')}
                size='md'
                name='faProjectDiagram'
                withoutPadding
                active
                square
                data-test-id='Editor.MainToolbar.modeGraph'
              />
            </div>
            <JGraphStatusIndicator className='margin-left-2x' />
          </ToolbarLeft>
          <div style={{ display: 'flex' }} id='JGraphBoundingPlace' />
          <ToolbarRight />
        </Toolbar>
        <div className='editor__editor'>
          <SearchState />
          <JGraphView />
        </div>
        <BlockModal />
        <RenderingBlockWrapper />
      </div>
    </JGraphContextProvider>
  );
};

export default JGraph;
