import { Vector2d } from 'konva/lib/types';
import { Vector2D } from 'modules/JGraph/utils/2DVector';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { StickerInfo } from './types';

export function lerp(start: number, stop: number, amt: number): number {
  return start + (stop - start) * amt;
}
export function invertLerp(start: number, stop: number, value: number): number {
  return (value - start) / (stop - start);
}
export const remap = (v: number, oMin: number, oMax: number, rMin: number, rMax: number): number => {
  return lerp(rMin, rMax, invertLerp(oMin, oMax, v));
};
export function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export function makeHorizontalSplinePoints(
  from: Vector2d,
  to: Vector2d,
  startDirection?: 'left' | 'right',
  endDirection?: 'left' | 'right',
  outerNodeWidth?: number
): Vector2d[] {
  const splineWidth = (Math.max(from.y, to.y) - Math.min(from.y, to.y)) / 4;
  if (startDirection !== endDirection) {
    const x = startDirection === 'left' ? Math.min(from.x, to.x) - splineWidth : Math.max(from.x, to.x) + splineWidth;
    return [from, { x, y: from.y }, { x, y: to.y }, to];
  }

  let xDiff = Math.abs(from.x - to.x);
  if (startDirection === 'left' && (from.x < to.x || xDiff < splineWidth)) {
    return [from, { x: from.x - splineWidth, y: from.y }, { x: to.x + splineWidth, y: to.y }, to];
  }
  xDiff = Math.abs(to.x - from.x);
  if (startDirection === 'right' && (to.x < from.x || xDiff < splineWidth)) {
    return [from, { x: from.x + splineWidth, y: from.y }, { x: to.x - splineWidth, y: to.y }, to];
  }

  return [from, { x: to.x, y: from.y }, { x: from.x, y: to.y }, to];
}

type StatePathWithPosition = { x: number; y: number; path: string };

export function getStickerAfterChangeStateLevel(
  stateBeforeUpdate: StatePathWithPosition,
  stateAfterUpdate: StatePathWithPosition,
  stickers: StickerInfo[]
) {
  const stickersConnectedToMovedState = getConnectedStickers(stateBeforeUpdate.path, stickers);
  if (stickersConnectedToMovedState.length === 0) return [];

  return stickersConnectedToMovedState.map(sticker => {
    const relativePositionByState = Vector2D.fromObj(sticker.position).subtract(stateBeforeUpdate);
    const position = Vector2D.fromObj(stateAfterUpdate).add(relativePositionByState);
    return { stickerId: sticker.id, position };
  });
}

export function getConnectedStickers(statePath: string, stickers: StickerInfo[]) {
  return stickers.filter(sticker => sticker.connection?.statePath === statePath);
}

export function insertStickersIdsToStates(state: JStateWithId, stickers: StickerInfo[]) {
  state.stickerIds = getConnectedStickers(state.path, stickers).map(sticker => sticker.id);
  state.states = state.states?.map(state => insertStickersIdsToStates(state, stickers));
  return state;
}
