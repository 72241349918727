var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { SwitchButton, InputText, Icon, Tooltip, InputGroup, RichTextEditor, useTranslation, withTranslationContext, } from '@just-ai/just-ui';
import cn from 'classnames';
import { debounce } from 'lodash';
import { getErrorMessageFromReason, Intents } from '../../../../nlu-related/utils';
import { answerPartsToReplies } from '../../../../utils';
import { useNLUModuleContext } from '../../../../context';
import { convertToIntentPhrases } from '../PhrasesBlock/utils';
import PhrasesBlock from '../PhrasesBlock';
import useControlledForm from './useControlledForm';
import ValidationErrorPanel from './components/ValidationErrorPanel';
import styles from './styles.module.scss';
import './styles.scss';
var validationResolver = function (values) {
    var errors = {};
    var name = values.name.replaceAll(/\s/g, '');
    if (!name || name.includes('/'))
        errors['name'] = true;
    return errors;
};
var IntentFaqEditView = function (_a) {
    var _this = this;
    var onUpdate = _a.onUpdate, error = _a.error, question = _a.question, savingReplies = _a.savingReplies, writeDisabled = _a.writeDisabled, validationError = _a.validationError, handleLinkToAnswer = _a.handleLinkToAnswer;
    var t = useTranslation().t;
    var _b = useNLUModuleContext(), isWhiteLabel = _b.isWhiteLabel, saveAudioToServer = _b.saveAudioToServer, saveFileToServer = _b.saveFileToServer, validateFile = _b.validateFile;
    var _c = useState(function () {
        var repliesMarkups = question.replies.map(function (reply) { return reply.markup; });
        if (repliesMarkups.includes('html')) {
            return true;
        }
        return false;
    }), markupMode = _c[0], setMarkupMode = _c[1];
    var _d = useState(false), savingVisible = _d[0], setSavingVisible = _d[1];
    var savingTimeoutRef = useRef();
    var AnswerBlockWithTranslation = useMemo(function () { return withTranslationContext(RichTextEditor); }, []);
    useEffect(function () {
        if (savingReplies) {
            return setSavingVisible(true);
        }
        if (savingVisible && !savingReplies) {
            savingTimeoutRef.current = setTimeout(function () {
                setSavingVisible(false);
            }, 5000);
        }
        return function () {
            savingTimeoutRef.current && clearTimeout(savingTimeoutRef.current);
        };
    }, [savingReplies, savingVisible]);
    useEffect(function () {
        var repliesMarkups = question.replies.map(function (reply) { return reply.markup; });
        if (repliesMarkups.includes('html')) {
            setMarkupMode(true);
        }
    }, [question.replies]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var debouncedOnUpdate = useCallback(debounce(onUpdate, 300), []);
    var intent = question.intent;
    var _e = useControlledForm({
        defaultValues: {
            enabled: !!intent.enabled,
            answer: intent.answer || '',
            name: Intents.getNameFromPath(intent.path),
            items: convertToIntentPhrases(intent),
        },
        validationResolver: validationResolver,
        onChange: useCallback(function (_a) {
            var data = _a.data, isValid = _a.isValid;
            if (!isValid)
                return;
            var newIntent = __assign(__assign({}, intent), { enabled: data.enabled, path: "".concat(Intents.getParentPathFromPath(intent.path), "/").concat(data.name) });
            newIntent = Intents.updateItems(newIntent, data.items);
            var newQuestion = {
                id: question.id,
                intent: newIntent,
                replies: question.replies,
            };
            debouncedOnUpdate(newQuestion);
        }, [debouncedOnUpdate, intent, question.id, question.replies]),
    }), state = _e.state, errors = _e.errors, onChange = _e.onChange;
    var onRepliesUpdate = useCallback(function (newReplies, signal, softValidate) { return __awaiter(_this, void 0, void 0, function () {
        var formattedReplies, newQuestion;
        return __generator(this, function (_a) {
            formattedReplies = answerPartsToReplies(newReplies);
            newQuestion = {
                id: question.id,
                intent: question.intent,
                replies: formattedReplies,
            };
            onUpdate(newQuestion, true, softValidate, signal);
            return [2 /*return*/];
        });
    }); }, [onUpdate, question.id, question.intent]);
    var answersValError = validationError === null || validationError === void 0 ? void 0 : validationError.answers;
    var phrasesValError = validationError === null || validationError === void 0 ? void 0 : validationError.phrases;
    return (React.createElement("div", { className: cn('intentFaqEditView', styles.intentFaqEditView) },
        React.createElement("div", { className: styles.panel },
            React.createElement("div", { className: styles.panel__header },
                React.createElement("h3", { className: styles.label }, t('FAQ:Intent:Field:Question')),
                !isWhiteLabel() && (React.createElement(React.Fragment, null,
                    React.createElement(Icon, { name: 'faQuestionCircle', id: 'questionTooltip', color: 'secondary' }),
                    React.createElement(Tooltip, { autohide: false, className: 'tooltip--dark faq-answer__tooltip faq-answer__tooltip--top-fix', placement: 'top', target: 'questionTooltip', flip: false, offset: 20 },
                        React.createElement("p", { className: cn({ 'links-disabled': isWhiteLabel() }), dangerouslySetInnerHTML: {
                                __html: t('AnswerBlocks:question_tooltip', t('AnswerBlocks:question_tooltip_link')),
                            } })))),
                React.createElement(SwitchButton, { name: 'enabled', id: 'switch-intent-header', label: '', onLabel: t('Intent:switchActivityLabelEnable'), offLabel: t('Intent:switchActivityLabelDisable'), labelPosition: 'left', onChange: onChange, value: state.enabled, "data-test-id": 'FAQPage.IntentEdit.EnabledSwitch', disabled: writeDisabled })),
            React.createElement("div", null,
                React.createElement(InputGroup, { className: 'faq-edit__question-input', AppendInner: !!phrasesValError && phrasesValError.length
                        ? [{ name: 'faExclamationCircle', color: 'danger' }]
                        : undefined },
                    React.createElement(InputText, { name: 'name', value: state.name, onChange: onChange, placeholder: t('FAQPage:Field:Question:Placeholder'), invalid: !!errors.name || !!error, "data-test-id": 'FAQPage.IntentEdit.Question', autoFocus: true, readOnly: writeDisabled })),
                error && (React.createElement("div", { className: 'error font-size-12', "data-test-id": 'FAQPage.IntentEdit.Error' }, error))),
            React.createElement(PhrasesBlock, { name: 'items', intentName: state.name, items: state.items, onChange: onChange, writeDisabled: writeDisabled, valErrors: phrasesValError }),
            !!phrasesValError && !!phrasesValError.length && (React.createElement(ValidationErrorPanel, { updateWithoutValidation: function () { return onUpdate(question, false, false); }, validationErrorData: phrasesValError, type: 'questions', usageArea: 'FAQ', handleLinkToAnswer: handleLinkToAnswer }))),
        React.createElement("div", { className: styles.panel },
            React.createElement("div", { className: cn(styles.panel__header, styles['panel__header--answers']) },
                React.createElement("h3", { className: styles.label }, t('FAQ:Intent:Field:Answer')),
                !isWhiteLabel() && (React.createElement(React.Fragment, null,
                    React.createElement(Icon, { name: 'faQuestionCircle', color: 'secondary', id: 'answerTooltip' }),
                    React.createElement(Tooltip, { autohide: false, className: 'tooltip--dark faq-answer__tooltip', placement: 'top', target: 'answerTooltip', flip: false, offset: 20 },
                        React.createElement("p", { dangerouslySetInnerHTML: {
                                __html: t('AnswerBlocks:answer_tooltip', t('AnswerBlocks:answer_tooltip_link')),
                            } })))),
                savingVisible && (React.createElement("div", { className: 'faq-answers__saving-indicator' },
                    React.createElement(Icon, { size: 'sm', name: !!answersValError && !!answersValError.length ? 'falTimes' : savingReplies ? 'farSync' : 'farCheck', color: 'secondary' }),
                    t(!!answersValError && !!answersValError.length
                        ? 'IntentEdit:titleNotSaved'
                        : savingReplies
                            ? 'AnswerBlocks:saving'
                            : 'IntentEdit:titleSaved'))),
                React.createElement("div", { className: styles.panel__switch },
                    React.createElement("p", null, t('AnswerSettings:HtmlEnabler')),
                    React.createElement(SwitchButton, { id: 'markupSwitch', value: markupMode, onChange: setMarkupMode, disabled: writeDisabled, "data-test-id": 'FAQPage.AnswerBlock.MarkupSwitch' }))),
            React.createElement(AnswerBlockWithTranslation, { replies: question.replies, onUpdate: onRepliesUpdate, markupMode: markupMode, writeDisabled: writeDisabled, MAX_ANSWERS_COUNT: 20, INITIAL_REPLIES: [{ type: 'text', markup: 'plain', text: '' }], isWhiteLabel: isWhiteLabel, getErrorMessageFromReason: getErrorMessageFromReason, saveAudioToServer: saveAudioToServer, saveFileToServer: saveFileToServer, validateFile: validateFile, ValErrorComponent: answersValError && answersValError.length
                    ? function (_a) {
                        var updateWithoutValidation = _a.updateWithoutValidation;
                        return (React.createElement("div", { className: 'faq-answer__validation-err' },
                            React.createElement(ValidationErrorPanel, { updateWithoutValidation: updateWithoutValidation, validationErrorData: answersValError, type: 'answers', usageArea: 'FAQ', handleLinkToAnswer: handleLinkToAnswer })));
                    }
                    : undefined }))));
};
export default React.memo(IntentFaqEditView);
