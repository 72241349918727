import React, { useState, useCallback, useMemo } from 'react';
import { Modal, InputText } from '@just-ai/just-ui';

import { t } from 'localization';

import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { isTextHasSlash } from 'modules/JGraph/utils/stageUtils';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { getAllStates } from 'reducers/JGraph.reducer/Graph';
import { getParentPaths } from 'reducers/JGraph.reducer/utils';
import { composeCustomValidationResolver } from 'utils/validator/customValidationResolver';
import {
  checkEmptyStateName,
  checkSlashInStateName,
  checkStateNameIsBusy,
  checkStateNameNotTheSame,
} from 'modules/JGraph/utils/validators/stateName';

const stateNameValidation = composeCustomValidationResolver(
  checkEmptyStateName,
  checkStateNameNotTheSame,
  checkSlashInStateName,
  checkStateNameIsBusy
);

interface RenameStateModalInterface {
  isOpen: boolean;
  screen: JStateWithId;
  screens: JStateWithId[];
  onClose: () => void;
  onRename: (name: string) => void;
}
const RenameStateModal = function ({ isOpen, onClose, screen, screens, onRename }: RenameStateModalInterface) {
  const [name, setName] = useState(screen.value);
  const [isValid, setIsValid] = useState(false);
  const [errorText, setErrorText] = useState('');

  const currentNameSupportRename = useMemo(() => !isTextHasSlash(screen.value), [screen.value]);

  const allStates = useMemo(() => getAllStates(screens), [screens]);

  const onChange = useCallback(
    async (title: string) => {
      setName(title);

      title = title.trim();
      const { parentStatePath } = getParentPaths(screen.path, screen.theme);
      const validationResult = await stateNameValidation(
        { name: title },
        { allStates, parentPath: parentStatePath, currentName: screen.value }
      );

      setErrorText(validationResult.errors?.name?.message || '');
      setIsValid(validationResult.isValid);
    },
    [allStates, screen.path, screen.theme, screen.value]
  );

  const onSubmit = useCallback(() => onRename(name.trim()), [name, onRename]);

  return (
    <Modal
      isOpen={isOpen}
      title={t(`RenameState:Modal:Title`)}
      buttonSubmitColor='primary'
      buttonSubmitText={t(`RenameState:Modal:SubmitTitle`)}
      buttonCancelColor='secondary'
      buttonCancelOutline
      buttonCancelText={t('Cancel')}
      buttonSubmitDisabled={!isValid || !currentNameSupportRename}
      onCancelClick={onClose}
      onActionClick={onSubmit}
      disableActionButtonAutoFocus
    >
      <div>
        <InputText
          id='stateName'
          name='stateName'
          defaultValue={screen.value}
          placeholder={t('RenameState:Modal:Name:Placeholder')}
          value={name}
          hint={t('CreationScreenMenu:title:hint')}
          errorText={errorText}
          disabled={!currentNameSupportRename}
          onChange={onChange}
        />
      </div>
      {!currentNameSupportRename && (
        <Banner type={BannerType.DANGER} content={t('RenameState:Modal:InvalidName:Hint')} className='margin-top-4x' />
      )}
    </Modal>
  );
};

export default React.memo(RenameStateModal);
