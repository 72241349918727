import React from 'react';
import { Icon, IconButton, useTranslation } from '@just-ai/just-ui';
export default function FileUploaderContent(props) {
    var clearFile = props.clearFile, file = props.file, fileError = props.fileError, existingFileName = props.existingFileName, downloadFile = props.downloadFile;
    var t = useTranslation().t;
    if (fileError && fileError === 'invalid')
        return (React.createElement("div", { className: 'upload-error' },
            React.createElement("div", null,
                React.createElement(Icon, { name: 'farExclamationTriangle', color: 'danger', size: 'lg' })),
            React.createElement("div", { className: 'upload-error__text' },
                React.createElement("p", { className: 'text-secondary font-size-12' }, t('FAQ:importModal:error')),
                file && (React.createElement("p", null,
                    React.createElement("b", null, file.name)))),
            React.createElement("div", { className: 'ml-auto' },
                React.createElement(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', size: 'sm', type: 'button' })),
            React.createElement("div", null,
                React.createElement(IconButton, { flat: true, name: 'farTrashAlt', color: 'none', size: 'sm', onClick: clearFile }))));
    if (file)
        return (React.createElement("div", { className: 'upload-success' },
            React.createElement("div", null,
                React.createElement(Icon, { name: 'farCheckCircle', color: 'success', size: 'lg' })),
            React.createElement("div", { className: 'upload-success__text' },
                React.createElement("p", { className: 'text-secondary font-size-12' }, t('loaded')),
                React.createElement("p", null,
                    React.createElement("b", null, file.name))),
            React.createElement("div", { className: 'ml-auto' },
                React.createElement(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', size: 'sm', type: 'button' })),
            React.createElement("div", null,
                React.createElement(IconButton, { flat: true, name: 'farTrashAlt', color: 'none', size: 'sm', onClick: clearFile }))));
    if (existingFileName)
        return (React.createElement("div", { className: 'upload-success' },
            React.createElement("p", null,
                React.createElement("b", null, existingFileName)),
            React.createElement("div", { className: 'ml-auto' },
                React.createElement(IconButton, { flat: true, name: 'farArrowToBottom', color: 'none', size: 'sm', type: 'button', onClick: downloadFile })),
            React.createElement("div", null,
                React.createElement(IconButton, { flat: true, name: 'farUndoAlt', color: 'none', type: 'button', size: 'sm' }))));
    return (React.createElement("div", { className: 'cdqa-file-upload__info' },
        React.createElement("p", null,
            React.createElement("span", { className: 'pseudoLink' }, t('FAQ:cdqa:documentAttach')),
            " ",
            t('or drop it here')),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("span", null, t('FAQ:cdqa:uploadDialogInfoItem1'))),
            React.createElement("li", null,
                React.createElement("span", null, t('FAQ:cdqa:uploadDialogInfoItem2'))))));
}
