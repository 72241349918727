import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import keyboardjs from 'keyboardjs';
import { Group, Arrow, Circle, Rect, Path } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { useToggle, useThrottleFn } from '@just-ai/just-ui';

import { Vector2D } from 'modules/JGraph/utils/2DVector';
import { StateStorePositionSize, StatesStorePipe$, getIntersection } from 'modules/JGraph/utils/blockLayerUtils';
import { absolutePositionToRelative } from 'modules/JGraph/utils/connectionLayerUtils';
import { stateWidth } from 'modules/JGraph/utils/stageUtils';
import useHover from 'modules/JGraph/hooks/useHover';
import { useStatesStoreRef } from 'modules/JGraph/hooks/useStatesStore';

import { GroupHighlighterSubject$ } from '../../GroupHighlighter';
import { StickerInfo } from '../types';
import { makeHorizontalSplinePoints } from '../utils';
import { transformRectInfo, stickerSideSize } from '../consts';

type MagnetIconProps = {
  x: number;
  y: number;
};
const MagnetIcon = React.memo(({ x, y }: MagnetIconProps) => {
  return (
    <Path
      x={x}
      y={y}
      width={24}
      height={24}
      data='M13.7071 3.80769C13.3166 3.41717 12.6834 3.41717 12.2929 3.80769L10.1716 5.92901C9.78105 6.31954 9.78105 6.9527 10.1716 7.34323L14.4142 11.5859C15.1953 12.3669 15.1953 13.6332 14.4142 14.4143C13.6332 15.1953 12.3668 15.1953 11.5858 14.4143L7.34315 10.1717C6.95262 9.78113 6.31946 9.78113 5.92893 10.1717L3.80761 12.293C3.41709 12.6835 3.41709 13.3167 3.80761 13.7072L8.05025 17.9498C10.7841 20.6837 15.2161 20.6835 17.9497 17.9498C20.6834 15.2162 20.6836 10.7842 17.9497 8.05033L13.7071 3.80769ZM15.1213 10.8788L13.7071 9.46455L15.8284 7.34323L17.2426 8.75744C19.5859 11.1007 19.5858 14.8996 17.2426 17.2427C14.8995 19.5859 11.1006 19.586 8.75736 17.2427L7.34315 15.8285L9.46447 13.7072L10.8787 15.1214C12.0503 16.293 13.9497 16.293 15.1213 15.1214C16.2929 13.9498 16.2929 12.0503 15.1213 10.8788Z'
      fill='#2296F5'
    />
  );
});

type StickerConnectorProps = {
  x: number;
  y: number;
  side: 'left' | 'right';
  onConnectionChange: (statePath: string, side: StickerConnectorProps['side']) => void;
};
const StickerConnector = React.memo(({ x, y, side, onConnectionChange }: StickerConnectorProps) => {
  const connectorRef = useRef<Konva.Circle | null>(null);
  const { hoverProps, isHovered } = useHover();
  const [isDragging, showDragging, closeDragging] = useToggle(false);
  const [connectorPoints, setConnectorPoints] = useState<number[]>([]);
  const pointerPosition = useRef({ x: 0, y: 0 });
  const screenSizesMap = useStatesStoreRef();

  const onMouseDown = useCallback(
    (evt: KonvaEventObject<MouseEvent>) => {
      evt.cancelBubble = true;
      setConnectorPoints([]);
      isDraggingRef.current = true;
      showDragging();
    },
    [showDragging]
  );

  const calculate = useCallback(() => {
    const stage = connectorRef.current?.getStage();
    const connectorNode = connectorRef.current;
    if (!stage || !connectorNode) return;
    const pointerAbsolutePosition = stage.getPointerPosition();
    if (!pointerAbsolutePosition) return;

    const connectorAbsolutePosition = connectorNode.getAbsolutePosition();

    let relativeConnectorPosition = Vector2D.from(x, y);

    let relativePointerPosition = Vector2D.fromObj(pointerAbsolutePosition)
      .subtract(connectorAbsolutePosition)
      .divide(stage.scale())
      .add(relativeConnectorPosition);

    pointerPosition.current = relativePointerPosition;

    const linePadding = 10;

    if (relativeConnectorPosition.x > relativePointerPosition.x) {
      relativePointerPosition = relativePointerPosition.addX(linePadding);
    } else {
      relativePointerPosition = relativePointerPosition.addX(-linePadding);
    }

    if (side === 'left') {
      relativeConnectorPosition = relativeConnectorPosition.addX(-linePadding);
    } else {
      relativeConnectorPosition = relativeConnectorPosition.addX(linePadding);
    }

    const points = makeHorizontalSplinePoints(relativeConnectorPosition, relativePointerPosition).flatMap(el => [
      el.x,
      el.y,
    ]);
    setConnectorPoints(points);
  }, [side, x, y]);

  const isDraggingRef = useRef(false);

  const checkStateIntersection = useThrottleFn(
    useCallback(
      (e: KonvaEventObject<MouseEvent>) => {
        const stage = connectorRef.current?.getStage();
        if (!stage) return;
        const pointerAbsolutePosition = stage.getPointerPosition();
        if (!pointerAbsolutePosition) return;
        const pointerPos = absolutePositionToRelative(stage, pointerAbsolutePosition);

        const intersection = getIntersection(pointerPos, screenSizesMap.current, e.currentTarget);

        if (!isDraggingRef.current) return;
        GroupHighlighterSubject$.next(intersection ? { ...intersection, type: 'simple' } : undefined);
      },
      [screenSizesMap]
    ),
    16
  );

  useEffect(() => {
    const stage = connectorRef.current?.getStage();
    if (!isDragging || !stage) return;

    const mousemove = (e: KonvaEventObject<MouseEvent>) => {
      checkStateIntersection(e);
      calculate();
    };
    const mouseup = (e: KonvaEventObject<MouseEvent>) => {
      isDraggingRef.current = false;
      e.cancelBubble = true;

      const stateIntersected = GroupHighlighterSubject$.getValue();

      GroupHighlighterSubject$.next(undefined);
      onConnectionChange(stateIntersected?.statePath || '', side);

      closeDragging();
    };

    stage.on('mousemove', mousemove);
    stage.on('mouseup', mouseup);
    return () => {
      stage.off('mousemove', mousemove);
      stage.off('mouseup', mouseup);
    };
  }, [calculate, checkStateIntersection, closeDragging, isDragging, onConnectionChange, side]);

  return (
    <>
      <Circle ref={connectorRef} x={x} y={y} radius={1} name={`StickerConnector-${side}`} />
      {isHovered ? (
        <>
          <Circle x={x} y={y} radius={6} fill='#2296F5' stroke='#225680' />
          <MagnetIcon x={side === 'left' ? x - 24 - 8 : x + 8} y={y - 12} />
        </>
      ) : (
        <Circle x={x} y={y} radius={3} fill='#2296F5' stroke='#225680' />
      )}
      <Rect onMouseDown={onMouseDown} {...hoverProps} x={x - 8} y={y - 8} width={16} height={16} />
      {isDragging && connectorPoints ? (
        <>
          <Circle x={x} y={y} radius={6} fill='white' stroke='#225680' />
          <Circle
            x={pointerPosition.current.x}
            y={pointerPosition.current.y}
            radius={6}
            fill='white'
            stroke='#225680'
          />
          <Arrow
            x={0}
            y={0}
            points={connectorPoints}
            bezier={true}
            stroke='#2296F5'
            fill='#2296F5'
            strokeWidth={1}
            lineJoin='round'
            lineCap='round'
            dash={[2, 2]}
          />
        </>
      ) : null}
    </>
  );
});

function preventKonvaDrag(e: KonvaEventObject<DragEvent>) {
  e.cancelBubble = true;
  e.target.stopDrag(e);
}

interface StickerConnectorsProps {
  stickerSelected: boolean;
  sticker: StickerInfo;
  stickerNode: Konva.Group | null;
  onConnectionChange: (statePath: string, side: 'left' | 'right') => void;
  onConnectorSelected: () => void;
}
export const StickerConnectors = ({
  sticker,
  stickerNode,
  onConnectionChange,
  onConnectorSelected,
  stickerSelected,
}: StickerConnectorsProps) => {
  const statePath = sticker.connection?.statePath;
  const stateTransform = useRef<StateStorePositionSize | null>(null);
  const groupRef = useRef<Konva.Group | null>(null);
  const connectLineRef = useRef<Konva.Arrow | null>(null);
  const [connectorPoints, setConnectorPoints] = useState<number[]>([]);
  const [edgePoints, setEdgePoints] = useState<[Vector2D, Vector2D] | null>(null);
  const [selected, showSelected, closeSelected] = useToggle(false);

  useEffect(() => {
    if (connectorPoints.length && !sticker.connection) {
      setConnectorPoints([]);
    }
  }, [sticker.connection, connectorPoints]);

  const leftConnector = useMemo(() => Vector2D.from(transformRectInfo.x - 14, transformRectInfo.height / 2), []);
  const rightConnector = useMemo(
    () => Vector2D.from(transformRectInfo.x + transformRectInfo.width + 14, transformRectInfo.height / 2),
    []
  );

  const calculate = useCallback(() => {
    const stage = groupRef.current?.getStage();
    if (!stateTransform.current || !stage || !sticker.connection) return;

    const statePositionAndSize = stateTransform.current;

    const statePositionLeftSide = Vector2D.from(
      statePositionAndSize.x,
      statePositionAndSize.y + statePositionAndSize.height / 2
    );
    const statePositionRightSide = Vector2D.from(
      statePositionAndSize.x + stateWidth,
      statePositionAndSize.y + statePositionAndSize.height / 2
    );

    let connectorPosition =
      sticker.connection.position === 'left' ? Vector2D.fromObj(leftConnector) : Vector2D.fromObj(rightConnector);

    const groupPosition = absolutePositionToRelative(stage, groupRef.current!.getAbsolutePosition());

    const leftStateConnector = statePositionLeftSide.subtract(groupPosition);
    const rightStateConnector = statePositionRightSide.subtract(groupPosition);

    let startDirection = sticker.connection.position;

    const linePadding = 10;
    let endDirection: 'left' | 'right';
    let targetPoint: Vector2D;
    if (leftStateConnector.magnitude() > rightStateConnector.magnitude()) {
      endDirection = 'left';
      targetPoint = Vector2D.fromObj(rightStateConnector).addX(linePadding);
    } else {
      endDirection = 'right';
      targetPoint = Vector2D.fromObj(leftStateConnector).addX(-linePadding);
    }
    setEdgePoints([connectorPosition, targetPoint]);

    if (selected) {
      const selectedOffset = 15;
      connectorPosition = connectorPosition.addX(
        sticker.connection.position === 'left' ? -selectedOffset : selectedOffset
      );
      targetPoint = targetPoint.addX(endDirection === 'left' ? selectedOffset : -selectedOffset);
    }

    const points = makeHorizontalSplinePoints(
      connectorPosition,
      targetPoint,
      startDirection,
      endDirection,
      stickerSideSize.width
    ).flatMap(el => [el.x, el.y]);

    setConnectorPoints(points);
  }, [leftConnector, rightConnector, sticker.connection, selected]);

  useEffect(() => {
    if (!statePath) return;
    const sub = StatesStorePipe$.subscribe(store => {
      const screen = store[statePath];
      if (!screen || isEqual(stateTransform.current, screen)) return;
      if (!stateTransform.current) {
        stateTransform.current = screen;
        calculate();
        return;
      }

      const prevStateSize = Vector2D.from(stateTransform.current.width, stateTransform.current.height);
      const stateSize = Vector2D.from(screen.width, screen.height);
      if (!stateSize.isEqual(prevStateSize)) {
        stateTransform.current = screen;
        calculate();
        return;
      }

      stateTransform.current = screen;
      return () => {
        sub.unsubscribe();
      };
    });
  }, [calculate, statePath]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  useEffect(() => {
    if (!stickerNode) return;
    stickerNode.on('absoluteTransformChange', calculate);
    return () => {
      stickerNode.off('absoluteTransformChange', calculate);
    };
  }, [calculate, stickerNode]);

  useEffect(() => {
    const stage = connectLineRef.current?.getStage();
    if (!selected || !stage) return;
    const clickOutside = (e: KonvaEventObject<MouseEvent>) => {
      if (connectLineRef.current?.attrs.id === e.target.attrs.id) return;
      closeSelected();
    };
    const deleteConnection = () => {
      onConnectionChange('', 'left');
      setEdgePoints(null);
      closeSelected();
    };

    stage.on('click', clickOutside);
    keyboardjs.on(['backspace', 'del'], deleteConnection);
    return () => {
      stage.off('click', clickOutside);
      keyboardjs.off(['backspace', 'del'], deleteConnection);
    };
  }, [closeSelected, onConnectionChange, selected]);

  useEffect(() => {
    closeSelected();
    // eslint-disable-next-line
  }, [stickerSelected]);

  const onArrowClick = useCallback(() => {
    onConnectorSelected();
    showSelected();
  }, [onConnectorSelected, showSelected]);

  return (
    <Group ref={groupRef} draggable={true} onDragStart={preventKonvaDrag}>
      {connectorPoints.length ? (
        <Arrow
          ref={connectLineRef}
          onClick={onArrowClick}
          x={0}
          y={0}
          points={connectorPoints}
          bezier={true}
          stroke='#2296F5'
          hitStrokeWidth={15}
          fill='#2296F5'
          strokeWidth={1}
          lineJoin='round'
          lineCap='round'
          dash={[2, 2]}
        />
      ) : null}

      {selected && edgePoints && (
        <>
          <Circle x={edgePoints[0].x} y={edgePoints[0].y} radius={6} fill='white' stroke='#225680' />
          <Circle x={edgePoints[1].x} y={edgePoints[1].y} radius={6} fill='white' stroke='#225680' />
        </>
      )}

      {stickerSelected ? (
        <>
          <StickerConnector
            x={leftConnector.x}
            y={leftConnector.y}
            side='left'
            onConnectionChange={onConnectionChange}
          />
          <StickerConnector
            x={rightConnector.x}
            y={rightConnector.y}
            side='right'
            onConnectionChange={onConnectionChange}
          />
        </>
      ) : null}
    </Group>
  );
};

export default React.memo(StickerConnectors);
