import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { TJBlock } from 'modules/JGraph/utils/types';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';

import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { addNewState } from 'reducers/JGraph.reducer/index';
import { createState } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { getUniqPathNameForState } from 'modules/JGraph/utils/stageBlock';

const addNewStateWithSave = createAsyncThunk(
  'JGraph/addNewStateWithSave',
  async (
    data: {
      screenPath: string;
      setEdit?: boolean;
      addingBlock?: TJBlock['tagName'];
      saveWithoutSetEdit?: boolean;
      parentPath?: string;
      blocks?: TJBlock[];
      position?: { x: number; y: number };
    },
    thunkAPI
  ) => {
    const { saveWithoutSetEdit, ...innerData } = data;

    const oldState = thunkAPI.getState() as RootState;
    innerData.screenPath = getUniqPathNameForState(
      oldState.JGraphReducer.graph.blocks,
      innerData.screenPath,
      innerData.parentPath
    );

    thunkAPI.dispatch(addNewState(innerData));
    const state = thunkAPI.getState() as RootState;
    if (data.setEdit) {
      const justAddedBlock = state.JGraphReducer.editMenuBlock;
      if (justAddedBlock) {
        mainSave$.next({
          type: 'create',
          path: justAddedBlock.screen.path,
          action: () => thunkAPI.dispatch(createState({ ...justAddedBlock.screen, parentPath: innerData.parentPath })),
        });
        setTimeout(() => {
          scrollToTargetGlobal$.next({ targetPathId: justAddedBlock.screen.pathId, isSideMenuOpen: true });
        }, 0);
      }
    }
    if (saveWithoutSetEdit) {
      const screenToSave = findScreenByPath(data.screenPath, state.JGraphReducer.graph.blocks);
      if (screenToSave) {
        mainSave$.next({
          type: 'create',
          path: screenToSave.path,
          action: () => thunkAPI.dispatch(createState(screenToSave)),
        });
      }
    }
  }
);

export default addNewStateWithSave;
