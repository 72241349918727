import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Icon, InputText, JustSelect, Modal, SwitchButton } from '@just-ai/just-ui';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { ComputePositionConfig } from '@floating-ui/dom/src/types';
import KeyboardService from '@just-ai/nlu-modules/dist/services/KeyboardService';

import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import useFloaterPosition from 'utils/hooks/useFloaterPosition';
import { closeScreenCreationMenu, openScreenCreationMenu } from 'reducers/JGraph.reducer';

import {
  findScreenByPath,
  getAllStates,
  getValidKonvaName,
  transformStateNameToPath,
} from 'reducers/JGraph.reducer/Graph';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

import { addNewStateWithSave, makeNewConnectorAsync } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { TagNames, TReactionsTagNames } from '../../utils/types';
import { initialNewConnectorState, newConnectorSubject$ } from '../../hooks';
import { useStageObservableContext } from '../../contexts/StageObservablesProvider';
import classes from './CreationScreenMenu.module.scss';
import { AddingSimpleBlock } from './AddingSimpleBlock';
import { composeCustomValidationResolver } from 'utils/validator/customValidationResolver';
import {
  checkEmptyStateName,
  checkSlashInStateName,
  checkStateNameIsBusy,
} from 'modules/JGraph/utils/validators/stateName';

const stateNameValidation = composeCustomValidationResolver(
  checkEmptyStateName,
  checkSlashInStateName,
  checkStateNameIsBusy
);

const getNewScreenName = (prefix: string, iterator: number, existsPaths: string[]): string => {
  let name = prefix;
  if (existsPaths.includes(name) || existsPaths.includes('/' + name)) {
    name = `${prefix}_${iterator}`;
    if (existsPaths.includes(name) || existsPaths.includes('/' + name)) {
      return getNewScreenName(prefix, iterator + 1, existsPaths);
    }
  }

  return name;
};

const floaterOptions: Partial<ComputePositionConfig> = {
  strategy: 'fixed',
  placement: 'right-start',
  middleware: [
    offset({
      mainAxis: -10,
      alignmentAxis: -30,
    }),
    flip(),
    shift(),
  ],
};

export const CreationScreenMenu: FC = () => {
  const { screenCreationMenu, blocks } = useAppSelector(state => ({
    blocks: state.JGraphReducer.graph.blocks,
    screenCreationMenu: state.JGraphReducer.screenCreationMenu,
  }));

  const { selectedGroupPath } = useStageObservableContext();

  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpen] = useState(false);
  const [showParentSelection, setOpenParentSelection] = useState(false);
  const [nameExistError, setError] = useState(false);
  const storeSelectedValue = useRef<TagNames | undefined>(undefined);
  const menuWrapper = useRef<HTMLDivElement>(null);
  const keyboardService = useRef(new KeyboardService());
  const [errorText, setErrorText] = useState('');
  const allStates = useMemo(() => getAllStates(blocks), [blocks]);

  const [screenName, setScreenName] = useState(() => {
    return getNewScreenName(
      screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/NewState` : `NewState`,
      blocks.length,
      allStates
    );
  });
  const [showScreenNameValue, setShowScreenNameValue] = useState(screenName);
  const touched = useRef(false);
  const debouncedScreenName = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!screenCreationMenu.open) return;

    const keyboardServiceFromRef = keyboardService.current;
    keyboardServiceFromRef.bind('esc', () => {
      newConnectorSubject$.next(initialNewConnectorState);
      dispatch(closeScreenCreationMenu());
    });
    return () => keyboardServiceFromRef.unbindAll();
  }, [dispatch, screenCreationMenu.open]);

  const setStateName = useCallback(
    async value => {
      const stateValue = value.replace(/\/+/g, '/') as string;
      setScreenName(stateValue);

      const validationResult = await stateNameValidation(
        { name: stateValue.trim() },
        { allStates, parentPath: screenCreationMenu.parentPath }
      );
      setErrorText(validationResult.errors?.name?.message || '');
      setError(!validationResult.isValid);

      touched.current = true;
    },
    [allStates, screenCreationMenu.parentPath]
  );

  useEffect(() => {
    if (!screenCreationMenu.open) {
      const name = getNewScreenName(
        screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/NewState` : `NewState`,
        blocks.length,
        allStates
      );
      setScreenName(name.replace(screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/` : '', ''));
      touched.current = false;
      setOpenParentSelection(false);
      setError(false);
      setErrorText('');
    }
  }, [allStates, blocks.length, screenCreationMenu.open, screenCreationMenu.parentPath]);

  useEffect(() => {
    if (touched.current) {
      setScreenName(prevScreenName => {
        const name = getNewScreenName(
          screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/${prevScreenName}` : prevScreenName,
          blocks.length,
          allStates
        );
        const pureName = name.replace(screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/` : '', '');
        setError(allStates.includes(name.startsWith('/') ? name : `/${name}`));
        return pureName;
      });
    } else {
      const name = getNewScreenName(
        screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/NewState` : `NewState`,
        blocks.length,
        allStates
      );
      setScreenName(name.replace(screenCreationMenu.parentPath ? `${screenCreationMenu.parentPath}/` : '', ''));
      setError(allStates.includes(name.startsWith('/') ? name : `/${name}`));
    }
  }, [allStates, blocks.length, screenCreationMenu.parentPath]);

  useEffect(() => {
    debouncedScreenName.current && clearTimeout(debouncedScreenName.current);
    debouncedScreenName.current = setTimeout(() => {
      setShowScreenNameValue(screenName);
    }, 60);
    let debouncedScreenNameRef = debouncedScreenName.current;
    return () => {
      clearTimeout(debouncedScreenNameRef);
    };
  }, [screenName]);

  const createScreen = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (screenCreationMenu.from && screenCreationMenu.toPath && screenCreationMenu.toPath !== './') {
        setOpen(true);
        return;
      }
      if (screenName) {
        const screenPath = transformStateNameToPath(screenName.trim());
        await dispatch(addNewStateWithSave({ screenPath, setEdit: true, parentPath: screenCreationMenu.parentPath }));
        if (screenCreationMenu.from) {
          await dispatch(
            makeNewConnectorAsync({
              from: screenCreationMenu.from,
              to: getValidKonvaName(
                screenCreationMenu.parentPath ? screenCreationMenu.parentPath + screenPath : screenPath
              ),
            })
          );
          newConnectorSubject$.next(initialNewConnectorState);
        }
      }
    },
    [dispatch, screenCreationMenu.from, screenCreationMenu.parentPath, screenCreationMenu.toPath, screenName]
  );

  const onSelectReaction = useCallback(
    async (value: TReactionsTagNames) => {
      if (nameExistError) {
        return;
      }
      if (screenCreationMenu.from && screenCreationMenu.toPath) {
        storeSelectedValue.current = value;
        setOpen(true);
        return;
      }
      if (screenName) {
        const screenPath = transformStateNameToPath(screenName.trim());
        await dispatch(
          addNewStateWithSave({
            screenPath,
            addingBlock: value,
            setEdit: true,
            parentPath: screenCreationMenu.parentPath,
          })
        );
        if (screenCreationMenu.from) {
          await dispatch(
            makeNewConnectorAsync({
              from: screenCreationMenu.from,
              to: getValidKonvaName(
                screenCreationMenu.parentPath ? screenCreationMenu.parentPath + screenPath : screenPath
              ),
            })
          );
          newConnectorSubject$.next(initialNewConnectorState);
        }
      }
    },
    [
      dispatch,
      screenCreationMenu.from,
      screenCreationMenu.parentPath,
      screenCreationMenu.toPath,
      screenName,
      nameExistError,
    ]
  );

  const saveAfterConfirm = useCallback(async () => {
    if (screenName && screenCreationMenu.from) {
      const screenPath = transformStateNameToPath(screenName.trim());
      await dispatch(
        addNewStateWithSave({
          screenPath,
          addingBlock: storeSelectedValue.current,
          setEdit: true,
          parentPath: screenCreationMenu.parentPath,
        })
      );
      storeSelectedValue.current = undefined;
      await dispatch(
        makeNewConnectorAsync({
          from: screenCreationMenu.from,
          to: getValidKonvaName(
            screenCreationMenu.parentPath ? screenCreationMenu.parentPath + screenPath : screenPath
          ),
        })
      );
      newConnectorSubject$.next(initialNewConnectorState);
    }
  }, [dispatch, screenCreationMenu.from, screenCreationMenu.parentPath, screenName]);

  const cancelAdding = useCallback(() => {
    setOpen(false);
    dispatch(closeScreenCreationMenu());
    // @ts-ignore
    newConnectorSubject$.next(initialNewConnectorState);
  }, [dispatch]);

  const onChangeParentSelect = useCallback(
    (value: (string | number)[] | null) => {
      if (value && value[0]) {
        dispatch(
          openScreenCreationMenu({
            ...screenCreationMenu,
            parentPath: value[0] as string,
          })
        );
      }
    },
    [dispatch, screenCreationMenu]
  );

  const optionsToSelect = useMemo(() => {
    if (selectedGroupPath) {
      const screenGroup = findScreenByPath(selectedGroupPath, blocks);
      if (screenGroup && screenGroup.states) {
        return screenGroup.states.map(screen => ({
          value: screen.path,
          label: hideRootSlashInPath(screen.path),
        }));
      }
    }
    return blocks.map(screen => ({
      value: screen.path,
      label: hideRootSlashInPath(screen.path),
    }));
  }, [selectedGroupPath, blocks]);

  const toggleParentSelection = useCallback(() => {
    setOpenParentSelection(prevShown => {
      if (prevShown) {
        dispatch(
          openScreenCreationMenu({
            ...screenCreationMenu,
            parentPath: selectedGroupPath,
          })
        );
      }
      return !prevShown;
    });
  }, [dispatch, screenCreationMenu, selectedGroupPath]);

  useFloaterPosition({
    enable: screenCreationMenu.open,
    floaterElement: menuWrapper,
    target: useMemo(
      () => ({
        width: 0,
        height: 0,
        x: screenCreationMenu.pointerPosition.x,
        y: screenCreationMenu.pointerPosition.y,
      }),
      [screenCreationMenu.pointerPosition]
    ),
    options: floaterOptions,
  });

  if (!screenCreationMenu.open) return null;
  return (
    <>
      <form onSubmit={createScreen}>
        <div ref={menuWrapper} className={classes.CreationScreenMenu}>
          <div className={classes.header}>
            <div className={classes.title}>{t('CreationScreenMenu:title')}</div>
            <div>
              <InputText
                data-test-id='ScreenCreationMenu:screenName'
                value={screenName}
                errorText={errorText}
                onChange={setStateName}
                hint={t('CreationScreenMenu:title:hint')}
                autoFocus
              />
            </div>
          </div>
          <div className={classes.parentSelection}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className={classes.title}>{t('CreationScreenMenu:parentSelectionTitle')}</div>
              <div className='d-flex'>
                <SwitchButton
                  id='switch-parentSelection'
                  onChange={toggleParentSelection}
                  size='md'
                  value={showParentSelection}
                />
              </div>
            </div>
            {showParentSelection && (
              <div className='mt-3'>
                <JustSelect
                  inputPlaceholder={t('CreationScreenMenu:parentSelectionPlaceholder')}
                  fullWidth
                  options={optionsToSelect}
                  value={screenCreationMenu.parentPath}
                  onChange={onChangeParentSelect}
                />
              </div>
            )}
          </div>
          {screenCreationMenu.parentPath && (
            <div className={classes.inGroupInfo}>
              <div className={classes.inGroupInfoTitle}>{t('CreationScreenMenu:inGroupInfoTitle')}</div>
              <div className={classes.inGroupInfoBody}>
                {[hideRootSlashInPath(screenCreationMenu.parentPath), showScreenNameValue]
                  .join('/')
                  .replace(/\/+/g, '/')}
              </div>
            </div>
          )}
          <div className={classes.body}>
            <div className={classes.item} data-test-id='CreationScreenMenu:addBlock'>
              <div className={classes.itemTitle}>{t('CreationScreenMenu:addBlock')}</div>
              <Icon name='farChevronRight' />
              <AddingSimpleBlock type='reactions' onSelectReaction={onSelectReaction} />
            </div>
            <div className={classes.item} data-test-id='CreationScreenMenu:selectAction'>
              <div className={classes.itemTitle}>{t('CreationScreenMenu:readyBlock')}</div>
              <Icon name='farChevronRight' />
              <AddingSimpleBlock type='meta' onSelectReaction={onSelectReaction} />
            </div>
          </div>
          <div className={classes.footer}>
            <Button
              color='primary'
              type='submit'
              disabled={screenName.trim().length === 0 || nameExistError}
              data-test-id='CreationScreenMenu:CreateState:submit'
            >
              {t('CreationScreenMenu:CreateState')}
            </Button>
          </div>
        </div>
      </form>
      <Modal
        isOpen={openConfirmModal}
        title={t(`CreationScreenMenu:delete_exist_connection_title`)}
        buttonSubmitColor='danger'
        buttonSubmitText={t(`CreationScreenMenu:delete_exist_connection_submit`)}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        onCancelClick={cancelAdding}
        onActionClick={saveAfterConfirm}
      >
        <p>
          {t(`CreationScreenMenu:delete_exist_connection_text`, screenCreationMenu.fromPath, screenCreationMenu.toPath)}
        </p>
      </Modal>
    </>
  );
};
