import { createAsyncThunk } from '@reduxjs/toolkit';
import { KonvaEventObject } from 'konva/lib/Node';

import { RootState } from 'storeTypes';

import {
  toggleAddingActionsMenu,
  setEditMenuBlock,
  closeScreenCreationMenu,
  closeContextMenu,
} from 'reducers/JGraph.reducer/index';

const onStageClickAsync = createAsyncThunk(
  'JGraph/onStageClick',
  async (
    data: {
      event: KonvaEventObject<MouseEvent>;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const jGState = state.JGraphReducer;

    const { isAddingMenuOpen, editMenuBlock, screenCreationMenu, contextMenu } = jGState;

    isAddingMenuOpen && thunkAPI.dispatch(toggleAddingActionsMenu(data));
    editMenuBlock && thunkAPI.dispatch(setEditMenuBlock(undefined));
    screenCreationMenu.open && thunkAPI.dispatch(closeScreenCreationMenu());
    contextMenu.open && thunkAPI.dispatch(closeContextMenu());
  }
);

export default onStageClickAsync;
