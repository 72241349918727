var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from 'react';
import { InputTextBase } from './InputTextBase';
export function InputTextUncontrolled(_a) {
    var { type = 'text', name, value, onChange, clearButtonProps, blurInactive } = _a, restProps = __rest(_a, ["type", "name", "value", "onChange", "clearButtonProps", "blurInactive"]);
    const [inputValue, setInputValue] = useState(value || restProps.defaultValue);
    useEffect(() => {
        if (typeof value !== "undefined" && value !== inputValue) {
            setInputValue(value);
        }
    }, [value]);
    return (React.createElement(InputTextBase, Object.assign({ type: type, name: name, value: inputValue, onChange: value => setInputValue(value), clearButtonProps: clearButtonProps, blurInactive: blurInactive }, restProps)));
}
;
