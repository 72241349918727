import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Group, Text } from 'react-konva';
import { IconNames, KIcon } from '../parts/KIcons';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import Konva from 'konva';
import { Subject } from 'rxjs';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { ConnectorStore } from '../../contexts/types';
import { getStageContextFromRef, scrollToTargetGlobal$ } from '../../utils/stageUtils';
import { getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { AutosizeRect } from '../parts/AutosizeRect';
import { EditMenuBlock$ } from '../StateScreen.hooks';

type OutgoingItemProps = {
  path: string;
};

export const OutgoingItem: FC<OutgoingItemProps> = ({ path }) => {
  const StateRef = useRef<Konva.Group | null>(null);
  const goToState = useCallback(
    event => {
      event.cancelBubble = true;
      let isSideMenuOpen = false;
      const sub = EditMenuBlock$.subscribe(value => {
        isSideMenuOpen = !!value;
      });
      sub.unsubscribe();
      scrollToTargetGlobal$.next({
        targetPathId: getValidKonvaName(path),
        isSideMenuOpen,
      });
    },
    [path]
  );

  useEffect(() => {
    let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;
    if (StateRef.current && path) {
      const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
      connectorsFromPipeSave$ = connectorsFromPipe$;
      connectorsFromPipe$.next({
        [getValidKonvaName(path)]: {
          toRef: StateRef.current,
        },
      });
    }
    return () => {
      if (connectorsFromPipeSave$) {
        connectorsFromPipeSave$.next({
          [getValidKonvaName(path)]: {
            toRef: undefined,
          },
        });
      }
    };
  }, [path]);

  return (
    <AutopositionGroup offsetY={0} padding={0} name={getValidKonvaName(path)} isScreen={true} type='OutgoingItem'>
      <AutosizeRect width={212} name={path} stroke='rgba(0,0,0,0)' cornerRadius={8} defaultHeight={32}>
        <Group ref={StateRef}>
          <Text
            y={0}
            x={0}
            text={hideRootSlashInPath(path)}
            fill='#0D1822'
            fontSize={12}
            lineHeight={18 / 12}
            letterSpacing={0.01}
            width={174}
            wrap='none'
            ellipsis
          />
          <KIcon width={16} height={16} x={174 + 8} y={0} icon={IconNames.stateLocation} onClick={goToState} />
        </Group>
      </AutosizeRect>
    </AutopositionGroup>
  );
};
