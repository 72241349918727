import React, { useCallback } from 'react';
import { uniqueId } from 'lodash';
import { RangeAlt } from '../Range';
import InputGroup from '../InputGroup';
import { InputText } from '../InputText';
import './SimilarsRange.scss';
export default function SimilarsRange(props) {
    const { value, onChange, max = 1, min = 0, step = 0.01, inputTestId } = props;
    const handleTextChange = useCallback((value) => {
        if (isNaN(+value) || +value > 100)
            return;
        onChange(+value / 100);
    }, [onChange]);
    return (React.createElement("div", { className: 'similars-range' },
        React.createElement("div", { className: 'similars-range__wrapper' },
            React.createElement("p", null, min),
            React.createElement(RangeAlt, { mode: 1, min: min, max: max, step: step, value: [value], onChange: value => onChange(value[0]), handleIds: [`Similars.Handle_${uniqueId()}`] }),
            React.createElement("p", null, max * 100)),
        React.createElement(InputGroup, { Append: React.createElement("div", null, "%") },
            React.createElement(InputText, { "data-test-id": inputTestId, value: String(Math.round(value * 100)), type: 'number', size: 'sm', onChange: handleTextChange }))));
}
