var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { IconButton, Tooltip, useToggle, InputText, InputGroup, defaultPreventer, useTranslation } from '@just-ai/just-ui';
import Tree from '../Tree';
import FAQModal from '../../../../views/FAQModal';
import FaqTreeContextMenu, { ContextMenuActions } from '../FaqTreeContextMenu/FaqTreeContextMenu';
import DeleteModal from '../DeleteModal';
import { useDeleteModal } from '../FaqTreeContextMenu/hooks';
import { TIPS_CLOSED } from '../../../../constants/faqTemplates';
import HelpPopup from '../HelpPopup';
import classes from './styles.module.scss';
import './styles.scss';
var FaqTree = function (_a) {
    var _this = this;
    var treeLogicState = _a.treeLogicState, onSelectOne = _a.onSelectOne, createNewQuestion = _a.createNewQuestion, onRenameProp = _a.onRename, createNewGroup = _a.createNewGroup, onCreateFolderWithNodes = _a.onCreateFolderWithNodes, onMoveQuestionInFolder = _a.onMoveQuestionInFolder, onDelete = _a.onDelete, onDropInRoot = _a.onDropInRoot, onCopy = _a.onCopy, onGroup = _a.onGroup, onUngroup = _a.onUngroup, onSwitchEnablement = _a.onSwitchEnablement, onError = _a.onError, errorMessage = _a.errorMessage, selectedId = _a.selectedId, editIdProp = _a.editId, treeScroll = _a.treeScroll, handleSearch = _a.handleSearch, searchValue = _a.searchValue, isSearchActive = _a.isSearchActive, toggleSearchMode = _a.toggleSearchMode, searchTrees = _a.searchTrees, onExport = _a.onExport, activeTemplateName = _a.activeTemplateName, updateIntentsData = _a.updateIntentsData, faqApiService = _a.faqApiService, checkLastItemToToggle = _a.checkLastItemToToggle, writeDisabled = _a.writeDisabled;
    var _b = useState(), contextMenu = _b[0], setContextMenu = _b[1];
    var _c = useState(window.localStorage.getItem(TIPS_CLOSED) !== 'true'), tipsShown = _c[0], setTipsShown = _c[1];
    var _d = useDeleteModal(false, onDelete), isDeleteModalOpen = _d[0], openDeleteModal = _d[1], closeDeleteModal = _d[2], handleDelete = _d[3], nodesToDelete = _d[4];
    var _e = useToggle(false), isUploadModalOpen = _e[0], toggleUploadModal = _e[3];
    var _f = useState(undefined), editId = _f[0], setEditId = _f[1];
    var t = useTranslation().t;
    var onRename = useCallback(function (text, node) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, onRenameProp(text, node)
                    .then(function () {
                    setEditId(undefined);
                })
                    .catch(function (error) {
                    throw error;
                })];
        });
    }); }, [onRenameProp]);
    useEffect(function () {
        setEditId(editIdProp);
    }, [editIdProp]);
    var handleContextMenu = useCallback(function (event, targetNode, nodes) {
        if (isSearchActive)
            return;
        setContextMenu({ nodes: nodes, targetNode: targetNode, position: { x: event.clientX, y: event.clientY } });
    }, [isSearchActive]);
    var closeContextMenu = useCallback(function () { return setContextMenu(undefined); }, []);
    var onSelectAction = useCallback(function (action, nodes) {
        if (action === ContextMenuActions.Delete || action === ContextMenuActions.DeleteGroup) {
            openDeleteModal(nodes !== null && nodes !== void 0 ? nodes : []);
            return;
        }
        if (action === ContextMenuActions.TurnOff || action === ContextMenuActions.TurnOffGroup) {
            onSwitchEnablement(nodes !== null && nodes !== void 0 ? nodes : [], false).then(function () {
                checkLastItemToToggle && checkLastItemToToggle();
            });
            return;
        }
        if (action === ContextMenuActions.TurnOn || action === ContextMenuActions.TurnOnGroup) {
            onSwitchEnablement(nodes !== null && nodes !== void 0 ? nodes : [], true).then(function () {
                checkLastItemToToggle && checkLastItemToToggle();
            });
            return;
        }
        if (action === ContextMenuActions.Copy || action === ContextMenuActions.CopyGroup) {
            onCopy(nodes !== null && nodes !== void 0 ? nodes : []);
            return;
        }
        if (action === ContextMenuActions.Rename) {
            var nodeId = (nodes === null || nodes === void 0 ? void 0 : nodes.length) === 1 ? nodes[0].id : undefined;
            nodeId && setEditId(nodeId);
        }
        if (action === ContextMenuActions.Group) {
            onGroup(nodes !== null && nodes !== void 0 ? nodes : []);
        }
        if (action === ContextMenuActions.Ungroup) {
            onUngroup(nodes !== null && nodes !== void 0 ? nodes : []);
        }
        if (action === ContextMenuActions.Export || action === ContextMenuActions.ExportGroup) {
            onExport(nodes !== null && nodes !== void 0 ? nodes : []);
        }
    }, [openDeleteModal, onSwitchEnablement, checkLastItemToToggle, onCopy, onGroup, onUngroup, onExport]);
    var handleImport = useCallback(function (file, error) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, faqApiService.importFAQ(activeTemplateName, file, (error === null || error === void 0 ? void 0 : error.type) === 'allowable')];
                case 1:
                    _a.sent();
                    updateIntentsData();
                    return [2 /*return*/, ''];
            }
        });
    }); }, [faqApiService, updateIntentsData, activeTemplateName]);
    var handleTipsClose = function () {
        setTipsShown(false);
        window.localStorage.setItem(TIPS_CLOSED, 'true');
    };
    return (React.createElement("div", { className: cn(classes.faqTree) },
        isSearchActive ? (React.createElement("div", { className: cn('faqTree__input', classes.faqTree__input) },
            React.createElement(InputGroup, { PrependInner: [{ name: 'farSearch' }], AppendInner: [
                    { name: 'farTimesCircle', onClick: toggleSearchMode, 'data-test-id': 'FAQ.Questions.search.close' },
                ] },
                React.createElement(InputText, { "data-test-id": 'FAQ.Questions.search', autoFocus: true, placeholder: t('FAQ:Header:Search'), value: searchValue, onChange: handleSearch })))) : (React.createElement("div", { className: cn('faqTree__header', classes.faqTree__header) },
            React.createElement("span", null, t('FAQ:TreeHeader')),
            React.createElement("div", null,
                React.createElement(IconButton, { id: 'faqCreateQuestion', name: 'farPlus', size: 'md', withoutPadding: true, color: 'secondary', outline: true, onClick: createNewQuestion, disabled: writeDisabled }),
                React.createElement(Tooltip, { target: 'faqCreateQuestion', className: cn('tooltip--white', classes.faqTree__tooltip), placement: 'top', flip: false }, t('FAQ:Header:AddQuestion')),
                React.createElement(IconButton, { id: 'faqCreateGroup', name: 'farFolderPlus', withoutPadding: true, size: 'md', color: 'secondary', outline: true, onClick: createNewGroup, disabled: writeDisabled }),
                React.createElement(Tooltip, { target: 'faqCreateGroup', className: cn('tooltip--white', classes.faqTree__tooltip), placement: 'top', flip: false }, t('FAQ:Header:AddGroup')),
                React.createElement(IconButton, { id: 'faqExport', name: 'farArrowToBottom', withoutPadding: true, size: 'md', color: 'secondary', outline: true, onClick: function () { return onExport(treeLogicState.getViewedTreeAsPlainList()); } }),
                React.createElement(Tooltip, { target: 'faqExport', className: cn('tooltip--white', classes.faqTree__tooltip), placement: 'top', flip: false }, t('FAQ:Header:Export')),
                React.createElement(IconButton, { id: 'faqImport', name: 'farArrowToTop', withoutPadding: true, size: 'md', color: 'secondary', outline: true, onMouseDown: defaultPreventer, onClick: toggleUploadModal, disabled: writeDisabled }),
                React.createElement(Tooltip, { target: 'faqImport', className: cn('tooltip--white', classes.faqTree__tooltip), placement: 'top', flip: false }, t('FAQ:Header:Import')),
                React.createElement(IconButton, { id: 'faqSearch', name: 'farSearch', withoutPadding: true, size: 'md', color: 'secondary', outline: true, onClick: toggleSearchMode }),
                React.createElement(Tooltip, { target: 'faqSearch', className: cn('tooltip--white', classes.faqTree__tooltip), placement: 'top', flip: false }, t('FAQ:Header:Search'))))),
        React.createElement("div", { className: classes.faqTree__content },
            React.createElement(Tree, { treeLogicState: treeLogicState, selectedId: selectedId, onSelectOne: onSelectOne, onRename: onRename, editId: editId, setEditId: setEditId, onDropInRoot: onDropInRoot, handleContextMenu: handleContextMenu, onCreateFolderWithNodes: onCreateFolderWithNodes, onMoveQuestionInFolder: onMoveQuestionInFolder, treeScroll: treeScroll, setError: onError, hasError: !!errorMessage, searchValue: searchValue, isSearchActive: isSearchActive, toggleSearchMode: toggleSearchMode, searchTrees: searchTrees, isUploadModalOpen: isUploadModalOpen, writeDisabled: writeDisabled })),
        tipsShown && (React.createElement("div", { className: classes.faqTree__tips },
            React.createElement(HelpPopup, { handleClose: handleTipsClose }))),
        contextMenu && (React.createElement(FaqTreeContextMenu, { position: contextMenu.position, targetNode: contextMenu.targetNode, nodes: contextMenu.nodes, close: closeContextMenu, onSelectAction: onSelectAction, writeDisabled: writeDisabled })),
        React.createElement(DeleteModal, { isOpen: isDeleteModalOpen, close: closeDeleteModal, handleDelete: handleDelete, deleteItem: nodesToDelete }),
        React.createElement(FAQModal, { isOpen: isUploadModalOpen, type: 'faq_from_existing', onSubmit: handleImport, toggle: toggleUploadModal })));
};
export default React.memo(FaqTree);
