var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { omit } from 'lodash';
import VirtualListWithDynamicHeight from '@just-ai/just-ui/dist/VirtualListWithDynamicHeight';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toIntentPhrase } from '../../../../../views/FAQTreePage/components/PhrasesBlock/utils';
import PhraseRow from '../PhraseRow';
import PhraseRowForInsertion from '../PhraseRow/PhraseRowForInsertion';
import styles from './styles.module.scss';
export var DEFAULT_PHRASE_HEIGHT = 38;
var DefaultItemForInsertion = {
    id: 'ForInsertion',
    text: '',
    type: IntentItemType.phrase,
};
var PhraseList = function (_a) {
    var items = _a.items, filteredItems = _a.filteredItems, isSearching = _a.isSearching, onChange = _a.onChange, writeDisabled = _a.writeDisabled;
    var _b = useState(items), innerItems = _b[0], setInnerItems = _b[1];
    var _c = useState(DefaultItemForInsertion), itemForInsertion = _c[0], setItemForInsertion = _c[1];
    useEffect(function () {
        setInnerItems(isSearching
            ? filteredItems
            : __spreadArray([itemForInsertion], items, true).map(function (el) { return omit(el, ['searchMatches']); }));
    }, [filteredItems, isSearching, itemForInsertion, items]);
    var onRowChange = useCallback(function (item) {
        if (item.id === 'ForInsertion') {
            setItemForInsertion(item);
            return;
        }
        var newItems = items.concat();
        var index = newItems.findIndex(function (el) { return el.id === item.id; });
        newItems[index] = item;
        onChange(newItems);
    }, [onChange, items]);
    var onDelete = useCallback(function (item) {
        var newItems = items.concat();
        var index = newItems.findIndex(function (el) { return el.id === item.id; });
        newItems.splice(index, 1);
        onChange(newItems);
    }, [items, onChange]);
    var onSubmit = useCallback(function (item) {
        var _a, _b;
        var index = innerItems.findIndex(function (el) { return el.id === item.id; });
        if (index === -1)
            return;
        var nextElement = innerItems[index + 1];
        if (!nextElement) {
            (_a = document.getElementById("PhraseRow-".concat(item.id))) === null || _a === void 0 ? void 0 : _a.blur();
            return;
        }
        (_b = document.getElementById("PhraseRow-".concat(nextElement.id))) === null || _b === void 0 ? void 0 : _b.focus();
    }, [innerItems]);
    var onInsert = useCallback(function () {
        var _a;
        if (itemForInsertion.text === '')
            return;
        var newItems = items.concat();
        var newIntentItem = toIntentPhrase(itemForInsertion.text, itemForInsertion.type);
        onChange(__spreadArray([newIntentItem], newItems, true));
        setItemForInsertion(__assign(__assign({}, DefaultItemForInsertion), { type: itemForInsertion.type }));
        (_a = document.getElementById("PhraseRow-".concat(DefaultItemForInsertion.id))) === null || _a === void 0 ? void 0 : _a.focus();
    }, [itemForInsertion, items, onChange]);
    return (React.createElement(VirtualListWithDynamicHeight, { itemCount: innerItems.length, containerMaxHeight: 440, initialContainerHeight: 200, paddingsHeight: 4, className: cn('just-ui-custom-scrollbar just-ui-custom-scrollbar_pink with-gutter question-phrases-list', styles.phraseList), itemId: function (index) { return innerItems[index].id; }, defaultElementHeight: DEFAULT_PHRASE_HEIGHT, itemRender: function (index) {
            var item = innerItems[index];
            return item.id === 'ForInsertion' ? (React.createElement(PhraseRowForInsertion, { index: index, item: item, onChange: onRowChange, onInsert: onInsert, writeDisabled: writeDisabled && !item.error })) : (React.createElement(PhraseRow, { index: index, item: item, onChange: onRowChange, onDelete: onDelete, onSubmit: onSubmit, writeDisabled: writeDisabled && !item.error }));
        } }));
};
export default React.memo(PhraseList);
