const packInfo = {
    lib: 'src/lib/Icon/CustomIconsPack',
    prefix: 'fa',
    iconsNameMap: {
        faCloudOffline: () => import('../CustomIconsPack/faCloudOffline'),
        faCloudOk: () => import('../CustomIconsPack/faCloudOk'),
        faCrosshair: () => import('../CustomIconsPack/faCrosshair'),
        faCurrentBranch: () => import('../CustomIconsPack/faCurrentBranch'),
        faEnterSymbol: () => import('../CustomIconsPack/faEnterSymbol'),
        faIncomingIsland: () => import('../CustomIconsPack/faIncomingIsland'),
        faIncomingRightArrow: () => import('../CustomIconsPack/faIncomingRightArrow'),
        faJustSelect: () => import('../CustomIconsPack/faJustSelect'),
        faOutgoingIsland: () => import('../CustomIconsPack/faOutgoingIsland'),
        faOutgoingRightArrow: () => import('../CustomIconsPack/faOutgoingRightArrow'),
        faVisibilityBlock: () => import('../CustomIconsPack/faVisibilityBlock'),
        faVisibilityBlockCollapsed: () => import('../CustomIconsPack/faVisibilityBlockCollapsed'),
        falQuestionCustom: () => import('../CustomIconsPack/falQuestionCustom'),
        farCog: () => import('../CustomIconsPack/farCog'),
        farDrag: () => import('../CustomIconsPack/farDrag'),
        fasTimes: () => import('../CustomIconsPack/fasTimes'),
        fasPaperPlane: () => import('../CustomIconsPack/fasPaperPlane'),
    },
};
export default packInfo;
