import React, { FC, useCallback, useMemo, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { Button, CustomCollapse, Icon, Modal } from '@just-ai/just-ui';
import classNames from 'classnames';

import { useAppSelector } from 'storeHooks';
import { t } from 'localization';

import {
  addNewBlockInReduxAndApi,
  deleteBlockInScreenAsync,
  resortBlocksInScreenAsync,
} from 'reducers/JGraph.reducer/JGraphAsyncActions';
import EmptyBlocksPlaceholder from 'modules/JGraph/view/RightSideMenu/settings/ScreenBlocksList/EmptyBlocksPlaceholder';
import { SortableReactionsContainer } from 'modules/JGraph/components/SortableBlocks';

import { CommonOpenAddingButton } from '../../../../components/CommonOpenAddingButton';
import { TActivationTagNames, TagNames, TReactionsTagNames } from '../../../../utils/types';
import { ActivationsMenuType } from '../../hooks/EventsHook';
import { getSortableActivations, getSortableReactions, getToStateActivations } from '../../utils/common';
import { TSortableItem } from '../../types';
import { useRightSideMenuContext } from '../../index';
import { buildIcon, mapIconToTagName, ReactionItem } from './ReactionItem';
import classes from './ReactionItem.module.scss';

import { FromStateTransition } from 'reducers/JGraph.reducer/types';
import addNewStartState from 'reducers/JGraph.reducer/AsyncActions/addNewStartState';
import Banner, { BannerType } from 'modules/JGraph/components/Banner';
import { tWithCheck } from '../../../../../../localization';
import { useJGraphContext } from 'modules/JGraph/contexts/JGraphContext';

const useFastActivationItems = (): TActivationTagNames[] => {
  const { isJGraphLite } = useJGraphContext();
  const initialActivationsTypes: TActivationTagNames[] = [];
  initialActivationsTypes.push(TagNames.intent, TagNames.event);
  if (!isJGraphLite) initialActivationsTypes.push(TagNames.q);
  return initialActivationsTypes;
};

const fastActivationItemsMap: Record<string, ActivationsMenuType> = {
  [TagNames.q]: 'pattern',
  [TagNames.intent]: 'intent',
  [TagNames.event]: 'event',
};
export const fastReactionItems: TReactionsTagNames[] = [TagNames.a, TagNames.buttons, TagNames.go_];

export function useFilterDeniedFromStateTransitions(transitions?: FromStateTransition[], isJGraphLite?: boolean) {
  if (!transitions) return transitions;
  return transitions.filter(transition => {
    if (!isJGraphLite) return true;
    return ![TagNames.q].includes(transition.tagName);
  });
}

export const ScreenBlocksList: FC<{}> = () => {
  const { fromStateTransitions } = useAppSelector(state => ({
    fromStateTransitions: state.JGraphReducer.graph.fromStateTransitions,
  }));
  const { isJGraphLite } = useJGraphContext();
  const { editMenuScreen, setAddingMenuType, setActivationsMenuType, dispatch, isExistStartState } =
    useRightSideMenuContext();
  const currentFromStateTransition = useFilterDeniedFromStateTransitions(
    fromStateTransitions[editMenuScreen.path],
    isJGraphLite
  );
  const [openDeleteIndex, setOpenDelete] = useState<number | undefined>(undefined);
  const fastActivationItems = useFastActivationItems();

  const sortableReactions: TSortableItem[] = useMemo(() => {
    return getSortableReactions(editMenuScreen.blocks);
  }, [editMenuScreen.blocks]);

  const sortableActivations: TSortableItem[] = useMemo(() => {
    return getSortableActivations(editMenuScreen.blocks, isJGraphLite);
  }, [editMenuScreen.blocks, isJGraphLite]);

  const toStateActivations: TSortableItem[] = useMemo(() => {
    return getToStateActivations(editMenuScreen.blocks, isJGraphLite);
  }, [editMenuScreen.blocks, isJGraphLite]);

  const onSortReactionsEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = sortableReactions[oldIndex]?.blockIndex;
        const newGlobalIndex = sortableReactions[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(editMenuScreen.blocks, oldGlobalIndex, newGlobalIndex);
          dispatch(resortBlocksInScreenAsync({ newBlocks }));
        }
      }
    },
    [sortableReactions, editMenuScreen.blocks, dispatch]
  );
  const onSortActivationsEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = sortableActivations[oldIndex]?.blockIndex;
        const newGlobalIndex = sortableActivations[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(editMenuScreen.blocks, oldGlobalIndex, newGlobalIndex);
          dispatch(resortBlocksInScreenAsync({ newBlocks }));
        }
      }
    },
    [sortableActivations, editMenuScreen.blocks, dispatch]
  );

  const onSortActivationsToStateEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const oldGlobalIndex = toStateActivations[oldIndex]?.blockIndex;
        const newGlobalIndex = toStateActivations[newIndex]?.blockIndex;
        if (Number.isInteger(oldGlobalIndex) && Number.isInteger(newGlobalIndex)) {
          const newBlocks = arrayMove(editMenuScreen.blocks, oldGlobalIndex, newGlobalIndex);
          dispatch(resortBlocksInScreenAsync({ newBlocks }));
        }
      }
    },
    [toStateActivations, editMenuScreen.blocks, dispatch]
  );

  const setOpenDeleteHandler = useCallback((index: number | undefined) => {
    setOpenDelete(index);
  }, []);
  const deleteHandler = useCallback(() => {
    setOpenDelete(undefined);
    dispatch(deleteBlockInScreenAsync({ screenId: editMenuScreen.pathId, blockIndex: openDeleteIndex || 0 }));
  }, [dispatch, editMenuScreen.pathId, openDeleteIndex]);

  const openAddingMenu = useCallback(() => {
    setAddingMenuType('reactions');
  }, [setAddingMenuType]);

  const onMakeStartScreen = useCallback(() => {
    return dispatch(addNewStartState({ screenPath: editMenuScreen.path, updateExisted: true }));
  }, [dispatch, editMenuScreen.path]);

  const isStageInRoot = editMenuScreen.theme === '/';

  return (
    <>
      {!isExistStartState && isStageInRoot && (
        <>
          <Banner
            type={BannerType.INFO}
            content={
              <>
                <div className='margin-bottom-2x'>{t('EmptyMode:ScreenBanner:Description')}</div>
                <Button type='button' color='info' size='sm' outline onClick={onMakeStartScreen}>
                  {t('EmptyMode:ScreenBanner:ActionButton')}
                </Button>
              </>
            }
          />
          <div style={{ background: 'var(--grey-200)', height: 1 }} />
        </>
      )}
      {sortableActivations.length > 0 && (
        <CustomCollapse title={t('ScreenBlocksList activations')} open={true}>
          <div className='ScreenBlocksList-inner'>
            <SortableReactionsContainer
              setOpenDelete={setOpenDeleteHandler}
              sortableBlocks={sortableActivations}
              useDragHandle
              axis='y'
              lockAxis='y'
              lockOffset={['0%', '100%']}
              onSortEnd={onSortActivationsEnd}
              lockToContainerEdges={true}
              helperClass='draggingHelper'
            />
          </div>
        </CustomCollapse>
      )}
      <CustomCollapse
        title={t('ScreenBlocksList reactions')}
        open={true}
        dataTestId='RightSideMenu:ScreenBlocksList:ReactionsBlock'
      >
        <div className='ScreenBlocksList-inner' data-test-id='ScreenBlocksList:reactions'>
          {sortableReactions.length > 0 ? (
            <SortableReactionsContainer
              sortableBlocks={sortableReactions}
              setOpenDelete={setOpenDeleteHandler}
              useDragHandle
              axis='y'
              lockAxis='y'
              lockOffset={['0%', '100%']}
              onSortEnd={onSortReactionsEnd}
              lockToContainerEdges={true}
              helperClass='draggingHelper'
            />
          ) : (
            <EmptyBlocksPlaceholder
              title={t('Reactions:EmptyPlaceholder:Title')}
              description={t('Reactions:EmptyPlaceholder:Description')}
              className='margin-top-4x'
            />
          )}

          <div className='ScreenBlocksList-fastAdding'>
            <div className='ScreenBlocksList-fastAdding-buttons'>
              {fastReactionItems.map(tagName => (
                <Button
                  key={`fastAddingReactions${tagName}`}
                  outline
                  data-test-id={`RightSideMenu:FastReactionItems:${tagName}`}
                  color='secondary'
                  onClick={() => {
                    setAddingMenuType(undefined);
                    dispatch(addNewBlockInReduxAndApi({ tagName: tagName }));
                  }}
                >
                  <Icon
                    color='secondary'
                    className={classNames(classes.reactionIcon, classes[tagName] || '')}
                    name={mapIconToTagName[tagName as TReactionsTagNames]}
                  />
                  {tWithCheck(`FastReactionItem ${tagName}`) || tWithCheck(`ReactionItem ${tagName}`)}
                </Button>
              ))}
            </div>
          </div>
          <CommonOpenAddingButton onClick={openAddingMenu} />
        </div>
      </CustomCollapse>
      <CustomCollapse
        title={t('ScreenBlocksList:PhrasesAndEvents')}
        open={true}
        dataTestId='RightSideMenu:ScreenBlocksList:PhrasesAndEvents'
      >
        <div className='ScreenBlocksList-inner withoutAdding' data-test-id='ScreenBlocksList:PhrasesAndEvents'>
          {toStateActivations.length || currentFromStateTransition?.length ? (
            <>
              <SortableReactionsContainer
                setOpenDelete={setOpenDeleteHandler}
                sortableBlocks={toStateActivations}
                useDragHandle
                axis='y'
                lockAxis='y'
                lockOffset={['0%', '100%']}
                onSortEnd={onSortActivationsToStateEnd}
                lockToContainerEdges={true}
                helperClass='draggingHelper'
              />
              {currentFromStateTransition && (
                <div className='ScreenBlocksList-sortable-container'>
                  {currentFromStateTransition.map(event => (
                    <ReactionItem
                      key={`fromState${event.tagName}${event.text}`}
                      block={{ tagName: event.tagName, tagValue: event.text, tagParameters: [], jblocks: [] }}
                      disableDelete={true}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <EmptyBlocksPlaceholder
              title={t('PhrasesAndEvents:EmptyPlaceholder:Title')}
              description={t('PhrasesAndEvents:EmptyPlaceholder:Description')}
              className='margin-top-4x'
            />
          )}

          <div className='ScreenBlocksList-fastAdding'>
            <div className='ScreenBlocksList-fastAdding-buttons'>
              {fastActivationItems.map(tagName => (
                <Button
                  outline
                  key={`fastActivationItems${tagName}`}
                  data-test-id={`RightSideMenu:FastActivationItems:${tagName}`}
                  color='secondary'
                  onClick={() => {
                    setAddingMenuType('toStateEvents');
                    setActivationsMenuType(fastActivationItemsMap[tagName]);
                  }}
                >
                  {buildIcon(tagName as TActivationTagNames, 'border-radius-1x')}
                  {t(`ActivationItem ${tagName}`)}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </CustomCollapse>

      <Modal
        isOpen={typeof openDeleteIndex === 'number'}
        title={t(`RightSideMenu:delete_block_title`)}
        data-test-id='RightSideMenu:DeleteBlockModal'
        buttonSubmitColor='danger'
        buttonSubmitText={t(`RightSideMenu:delete_block_submit`)}
        buttonSubmitTestId='RightSideMenu:DeleteBlockModal:Submit'
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonCancelText={t('Cancel')}
        buttonCancelTestId='RightSideMenu:DeleteBlockModal:Cancel'
        onCancelClick={() => setOpenDelete(undefined)}
        onActionClick={deleteHandler}
      >
        <p>{t(`RightSideMenu:delete_block_text`)}</p>
      </Modal>
    </>
  );
};
