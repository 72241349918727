var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { FileUpload, InputText, JustSelect, Modal, Spinner, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { downloadTextFileWithContent, useLoading } from '../../../nlu-related/utils';
import { useNLUModuleContext } from '../../../context';
import FileUploaderContent from './FileContent';
import './style.scss';
var MAX_FILE_SIZE_LIMIT = 1048576;
export default function CDQAModal(props) {
    var _this = this;
    var t = useTranslation().t;
    var isOpen = props.isOpen, toggle = props.toggle, onSubmit = props.onSubmit, existingData = props.existingData;
    var _a = useLoading(), isLoading = _a[0], load = _a[1];
    var skillsApiService = useNLUModuleContext().skillsApiService;
    var langOptions = useMemo(function () { return [
        { label: t('FAQ:cdqaModal:langOptionRuEn'), value: 'enRU' },
        { label: t('FAQ:cdqaModal:langOptionOther'), value: 'multi' },
    ]; }, [t]);
    var isEditing = !!(existingData === null || existingData === void 0 ? void 0 : existingData.name);
    var _b = useReducer(function (current, update) { return (__assign(__assign({}, current), update)); }, {
        name: '',
        lang: langOptions[0].value,
        fileError: '',
        nameError: '',
        existingFileName: '',
        file: undefined,
    }), state = _b[0], setState = _b[1];
    var file = state.file, name = state.name, lang = state.lang, fileError = state.fileError, nameError = state.nameError, existingFileName = state.existingFileName;
    useEffect(function () {
        if (!existingData || !isOpen)
            return;
        var getSkillData = function () { return __awaiter(_this, void 0, void 0, function () {
            var updatedSkillFileName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, skillsApiService.getSkillFileName(existingData.skillId)];
                    case 1:
                        updatedSkillFileName = _a.sent();
                        setState({
                            name: existingData.name,
                            lang: existingData.settings.languageCode || 'enRu',
                            existingFileName: updatedSkillFileName || '',
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        if (isEditing) {
            load(getSkillData());
        }
    }, [existingData, isEditing, isOpen, load, skillsApiService]);
    var clear = useCallback(function (e) {
        e && e.stopPropagation();
        setState({
            file: undefined,
            name: '',
            lang: langOptions[0].value,
            fileError: '',
            nameError: '',
        });
    }, [langOptions]);
    var closeHandler = useCallback(function () {
        clear();
        toggle();
    }, [clear, toggle]);
    var localSubmit = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var updateObj;
        return __generator(this, function (_a) {
            if (fileError || nameError)
                return [2 /*return*/];
            if ((!file && !existingFileName) || !name || !onSubmit) {
                if (!name)
                    setState({ nameError: t('This field is required') });
                if (!file)
                    setState({ fileError: t('This field is required') });
                return [2 /*return*/];
            }
            if (!existingData) {
                return [2 /*return*/, load(onSubmit({ name: name, lang: lang, file: file }))];
            }
            updateObj = {
                name: existingData.name !== name ? name : '',
                lang: existingData.settings.languageCode !== lang ? lang : '',
            };
            if (file && !existingFileName) {
                updateObj.file = file;
            }
            return [2 /*return*/, load(onSubmit(updateObj)).finally(function () { return closeHandler(); })];
        });
    }); }, [closeHandler, existingData, existingFileName, file, fileError, lang, load, name, nameError, onSubmit, t]);
    var clearFile = useCallback(function (e) {
        e && e.stopPropagation();
        setState({
            file: undefined,
            fileError: '',
        });
    }, []);
    var onFileSelect = function (files) {
        setState({
            fileError: '',
        });
        if (files.length !== 1 && !existingFileName) {
            setState({
                fileError: t(files.length === 0 ? 'Errors:uploadDialogNoFileError' : 'Errors:uploadDialogMultipleFilesError'),
            });
            return;
        }
        setState({
            file: files[0],
        });
        if (existingFileName)
            setState({
                existingFileName: '',
            });
        if (files[0].size > MAX_FILE_SIZE_LIMIT || files[0].type !== 'text/plain') {
            setState({
                fileError: 'invalid',
            });
        }
    };
    var downloadFileHandler = useCallback(function (e) { return __awaiter(_this, void 0, void 0, function () {
        var fileContent;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e === null || e === void 0 ? void 0 : e.stopPropagation();
                    if (!existingData)
                        return [2 /*return*/];
                    return [4 /*yield*/, skillsApiService.downloadSkillFile(existingData.skillId)];
                case 1:
                    fileContent = _a.sent();
                    downloadTextFileWithContent(fileContent, existingFileName);
                    return [2 /*return*/];
            }
        });
    }); }, [existingData, existingFileName, skillsApiService]);
    var handleNameInput = useCallback(function (value) {
        setState({
            nameError: '',
            name: value,
        });
    }, []);
    return (React.createElement(Modal, { className: 'cdqa-modal', isOpen: isOpen, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: isEditing ? t('Save') : t('FAQ:AddSkill'), onClickCloseIcon: closeHandler, onCancelClick: closeHandler, onActionClick: localSubmit, title: t('FAQ:cdqaModal:title'), centered: true, disableActionButtonAutoFocus: true },
        isLoading && (React.createElement("div", null,
            React.createElement(Spinner, null))),
        React.createElement("p", { className: 'cdqa-modal__header' }, t('FAQ:cdqaModal:subtitle')),
        !isEditing && (React.createElement("a", { className: 'cdqa-modal__docs-link', href: t('FAQ:cdqaModal:linkUrl'), target: '_blank', rel: 'noreferrer noopenner' }, t('FAQ:cdqaModal:linkText'))),
        React.createElement("label", { htmlFor: 'name' },
            t('FAQ:cdqaModal:nameLabel'),
            " ",
            React.createElement("span", { style: { color: 'var(--danger)' } }, "*")),
        React.createElement("div", null,
            React.createElement(InputText, { name: 'name', value: name, onChange: handleNameInput, blurInactive: true, errorText: nameError })),
        React.createElement("label", { className: 'mt-5', htmlFor: 'lang' }, t('Language')),
        React.createElement(JustSelect, { options: langOptions, fullWidth: true, position: 'fixed', value: lang, className: 'blur_inactive', onChange: function (value) { return value && value[0] && setState({ lang: value[0] }); } }),
        React.createElement("label", { htmlFor: 'file', className: 'mt-5' },
            t('FAQ:cdqa:document'),
            " ",
            React.createElement("span", { style: { color: 'var(--danger)' } }, "*")),
        React.createElement(FileUpload, { required: true, accept: '.txt', onChange: onFileSelect, className: 'cdqa-file-upload', loadingLabel: "".concat(t('FAQ:cdqa:loadingLabel'), "...") },
            React.createElement(FileUploaderContent, { clearFile: clearFile, file: file, fileError: fileError, existingFileName: existingFileName, downloadFile: downloadFileHandler })),
        isEditing && (React.createElement("ul", { className: cn('file-req-list font-size-12 mt-3', { 'text-danger': fileError }) },
            React.createElement("li", null,
                React.createElement("span", null, t('FAQ:cdqa:uploadDialogInfoItem1'))),
            React.createElement("li", null,
                React.createElement("span", null, t('FAQ:cdqa:uploadDialogInfoItem2'))))),
        fileError && (React.createElement("p", { className: 'text-danger mt-2 font-size-12' }, fileError === 'invalid' ? t('FAQ:cdqa:fileValError') : fileError))));
}
