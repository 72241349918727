var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { filterSessionIdController } from '../../index';
var OFFSET_TO_REQUEST_NEXT_LIST = 5;
var PAGE_SIZE = 20;
var SessionIdsController = /** @class */ (function () {
    function SessionIdsController(_a) {
        var filterSetId = _a.filterSetId, accountId = _a.accountId, initialPage = _a.initialPage, projectShortName = _a.projectShortName, getSessionsByFilter = _a.getSessionsByFilter;
        this.list = [];
        this.idIndex = 0;
        this.listIndex = 0;
        this.maxListIndex = 0;
        this.normalizeFilters = function (filters) {
            return filters.map(function (filter) {
                if (filter.key === 'MESSAGE_TIME') {
                    //This is necessary to prevent adding new sessions to the start of the list and shift all sessions
                    //@ts-ignore
                    filter.to = new Date().toISOString();
                }
                return __assign({}, filter);
            });
        };
        this.filterSetId = filterSetId;
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.getSessions = getSessionsByFilter;
        this.initialPage = initialPage;
        this.totalCount = 0;
    }
    SessionIdsController.prototype.init = function (sessionId) {
        return __awaiter(this, void 0, void 0, function () {
            var session;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.requestSessionsList(this.initialPage)];
                    case 1:
                        _a.sent();
                        this.listIndex = this.initialPage;
                        if (this.list[this.listIndex]) {
                            session = this.list[this.listIndex].find(function (session) { return sessionId === session.sessionId; });
                            if (!session)
                                return [2 /*return*/, { sessionId: sessionId, hasBack: false, hasForward: false, totalCount: 0, currentPosition: 0 }];
                            this.idIndex = this.list[this.listIndex].indexOf(session);
                        }
                        return [2 /*return*/, {
                                sessionId: sessionId,
                                hasBack: this.initialPage > 0 || this.idIndex > 0,
                                hasForward: this.maxListIndex > this.initialPage || this.idIndex < this.list[this.listIndex].length - 1,
                                totalCount: this.totalCount,
                                currentPosition: this.initialPage * PAGE_SIZE + this.idIndex,
                            }];
                }
            });
        });
    };
    SessionIdsController.prototype.requestSessionsList = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, sessions, totalCount, normalisedSessions;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.filters === undefined)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, filterSessionIdController.get(this.accountId, this.projectShortName, undefined, this.filterSetId)];
                    case 1:
                        _a.filters = _c.sent();
                        this.filters = this.normalizeFilters(this.filters);
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.getSessions(page, PAGE_SIZE, { filters: this.filters })];
                    case 3:
                        _b = _c.sent(), sessions = _b.sessions, totalCount = _b.paging.totalCount;
                        this.totalCount = totalCount;
                        normalisedSessions = sessions.map(function (_a) {
                            var sessionId = _a.sessionId, startTime = _a.startTime;
                            return ({ sessionId: sessionId, startTime: startTime });
                        });
                        this.maxListIndex = Math.ceil(totalCount / PAGE_SIZE) - 1;
                        this.list[page] = normalisedSessions;
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionIdsController.prototype.goBack = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.idIndex === 0 && this.listIndex === 0) {
                            return [2 /*return*/, this.buildResults()];
                        }
                        if (!(this.idIndex < OFFSET_TO_REQUEST_NEXT_LIST &&
                            !this.list[this.listIndex - 1] &&
                            this.listIndex > 0 &&
                            !this.backRequest)) return [3 /*break*/, 2];
                        this.backRequest = this.requestSessionsList(this.listIndex - 1);
                        if (!(this.idIndex === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.backRequest];
                    case 1:
                        _a.sent();
                        this.forwardRequest = undefined;
                        _a.label = 2;
                    case 2:
                        if (!(this.idIndex < OFFSET_TO_REQUEST_NEXT_LIST && !this.list[this.listIndex - 1] && this.backRequest)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.backRequest];
                    case 3:
                        _a.sent();
                        this.backRequest = undefined;
                        _a.label = 4;
                    case 4:
                        if (this.idIndex === 0 && this.listIndex > 0) {
                            this.listIndex -= 1;
                            this.idIndex = PAGE_SIZE - 1;
                        }
                        else {
                            this.idIndex -= 1;
                        }
                        return [2 /*return*/, this.buildResults()];
                }
            });
        });
    };
    SessionIdsController.prototype.goForward = function () {
        return __awaiter(this, void 0, void 0, function () {
            var maxIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        maxIndex = this.list[this.listIndex].length - 1;
                        if (this.listIndex === this.maxListIndex && this.idIndex === maxIndex) {
                            return [2 /*return*/, this.buildResults()];
                        }
                        if (!(this.idIndex > maxIndex - OFFSET_TO_REQUEST_NEXT_LIST &&
                            !this.list[this.listIndex + 1] &&
                            !this.forwardRequest)) return [3 /*break*/, 2];
                        this.forwardRequest = this.requestSessionsList(this.listIndex + 1);
                        if (!(this.idIndex === maxIndex)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.forwardRequest];
                    case 1:
                        _a.sent();
                        this.forwardRequest = undefined;
                        _a.label = 2;
                    case 2:
                        if (!(this.idIndex > maxIndex - OFFSET_TO_REQUEST_NEXT_LIST &&
                            !this.list[this.listIndex + 1] &&
                            this.forwardRequest)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.forwardRequest];
                    case 3:
                        _a.sent();
                        this.forwardRequest = undefined;
                        _a.label = 4;
                    case 4:
                        if (this.idIndex === maxIndex) {
                            this.listIndex += 1;
                            this.idIndex = 0;
                        }
                        else {
                            this.idIndex += 1;
                        }
                        return [2 /*return*/, this.buildResults()];
                }
            });
        });
    };
    SessionIdsController.prototype.buildResults = function () {
        var session = this.list[this.listIndex][this.idIndex];
        var currentPosition = this.listIndex * PAGE_SIZE + this.idIndex;
        return {
            sessionId: session.sessionId,
            startTime: session.startTime,
            hasForward: currentPosition < this.totalCount - 1,
            hasBack: currentPosition > 0,
            totalCount: this.totalCount,
            currentPosition: currentPosition,
        };
    };
    return SessionIdsController;
}());
export { SessionIdsController };
