export const eventactivationLocalization = {
  'EventActivationItem:title_Custom_events': {
    eng: 'Custom events',
    ru: 'Пользовательские события',
  },
  'EventActivationItem:title_System_events': {
    eng: 'System events',
    ru: 'Системные события',
  },
  'EventActivationItem:title_STS_classifier_events': {
    eng: 'STS classifier events',
    ru: 'События STS классификатора',
  },
  'EventActivationItem:title_Bitrix24': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
  },
  'EventActivationItem:title_edna_WhatsApp': {
    eng: 'edna WhatsApp',
    ru: 'edna WhatsApp',
  },
  'EventActivationItem:title_Facebook': {
    eng: 'Facebook',
    ru: 'Facebook',
  },
  'EventActivationItem:title_Telegram': {
    eng: 'Telegram',
    ru: 'Telegram',
  },
  'EventActivationItem:title_Viber': {
    eng: 'Viber',
    ru: 'Viber',
  },
  'EventActivationItem:title_Webim__External_Bot_API_2_0_': {
    eng: 'Webim (External Bot API 2.0)',
    ru: 'Webim (External Bot API 2.0)',
  },
  'EventActivationItem:title_WeChat': {
    eng: 'WeChat',
    ru: 'WeChat',
  },
  'EventActivationItem:title_Customer_engagement_platforms': {
    eng: 'Customer engagement platforms',
    ru: 'Операторские каналы',
  },
  'EventActivationItem:title_Telephony': {
    eng: 'Telephony',
    ru: 'Телефония',
  },
  'RSAddingMenu:Activations:ListHeaderHint': {
    eng: 'Events can occur in the channel, account, or project during bot runtime.',
    ru: 'Событие происходит в канале, аккаунте или проекте во время работы бота.',
  },
  'EventActivationItem:title_Custom event': {
    eng: 'Custom event',
    ru: 'Пользовательское событие',
  },
  'EventActivation:CustomEvent:Title': {
    eng: 'Event',
    ru: 'Событие',
  },
  'EventActivation:CustomEvent:Title:Placeholder': {
    eng: 'Your event name',
    ru: 'Название вашего события',
  },
  'EventActivationItem:title_Custom_events_Custom event': {
    eng: 'Custom event',
    ru: 'Пользовательское событие',
  },
  'EventActivationItem:description_Custom_events_Custom event': {
    eng: 'An event which can have an arbitrary name assigned to it. Such events can be triggered by external systems or by JAICP itself: for example, when a text campaign is launched.',
    ru: 'Событие, которому можно задать произвольное имя. Такие события могут отправлять внешние системы или сама JAICP — например, при запуске рассылок.',
  },
  'EventActivationItem:title_System_events_tariffBlocked': {
    eng: 'Account blocked due to subscription plan limits',
    ru: 'Аккаунт заблокирован по тарифным ограничениям',
  },
  'EventActivationItem:title_System_events_sessionDataSoftLimitExceeded': {
    eng: 'Soft limit on data stored in the $session object reached',
    ru: 'Достигнуто нестрогое ограничение на объем данных, сохраняемых в объект $session',
  },
  'EventActivationItem:title_System_events_clientDataSoftLimitExceeded': {
    eng: 'Soft limit on data stored in the $client object reached',
    ru: 'Достигнуто нестрогое ограничение на объем данных, сохраняемых в объект $client',
  },
  'EventActivationItem:title_System_events_sessionDataHardLimitExceeded': {
    eng: 'Hard limit on data stored in the $session object reached',
    ru: 'Достигнуто строгое ограничение на объем данных, сохраняемых в объект $session',
  },
  'EventActivationItem:title_System_events_clientDataHardLimitExceeded': {
    eng: 'Hard limit on data stored in the $client object reached',
    ru: 'Достигнуто строгое ограничение на объем данных, сохраняемых в объект $client',
  },
  'EventActivationItem:title_System_events_smsFailedEvent': {
    eng: 'SMS message failed to send',
    ru: 'SMS-сообщение не отправлено',
  },
  'EventActivationItem:title_System_events_smsSuccessEvent': {
    eng: 'SMS message sent successfully',
    ru: 'SMS-сообщение успешно отправлено',
  },
  'EventActivationItem:title_STS_classifier_events_match': {
    eng: 'Query recognized',
    ru: 'Отправленный текст распознан',
  },
  'EventActivationItem:title_STS_classifier_events_noMatch': {
    eng: 'Query not recognized',
    ru: 'Отправленный текст не распознан',
  },
  'EventActivationItem:title_STS_classifier_events_timeLimit': {
    eng: 'Request processing time limit exceeded',
    ru: 'Превышено ограничение на время обработки запроса',
  },
  'EventActivationItem:title_STS_classifier_events_lengthLimit': {
    eng: 'Request character limit exceeded',
    ru: 'Превышено ограничение на количество символов в запросе',
  },
  'EventActivationItem:title_Bitrix24_ONAPPINSTALL': {
    eng: 'Application installed',
    ru: 'Приложение установлено',
  },
  'EventActivationItem:title_Bitrix24_ONAPPUPDATE': {
    eng: 'Application updated',
    ru: 'Приложение обновлено',
  },
  'EventActivationItem:title_Bitrix24_ONIMBOTMESSAGEADD': {
    eng: 'The bot received a message',
    ru: 'Бот получил сообщение',
  },
  'EventActivationItem:title_Bitrix24_ONIMBOTMESSAGEUPDATE': {
    eng: 'The bot updated the message',
    ru: 'Бот обновил сообщение',
  },
  'EventActivationItem:title_Bitrix24_ONIMBOTMESSAGEDELETE': {
    eng: 'The bot deleted the message',
    ru: 'Бот удалил сообщение',
  },
  'EventActivationItem:title_Bitrix24_ONIMCOMMANDADD': {
    eng: 'The bot received a command',
    ru: 'Бот получил команду',
  },
  'EventActivationItem:title_Bitrix24_ONIMJOINCHAT': {
    eng: 'Bot notified about being added to a public or private chat',
    ru: 'Бот получил информацию о включении его в чат или личную переписку',
  },
  'EventActivationItem:title_Bitrix24_ONIMBOTDELETE': {
    eng: 'Bot removed',
    ru: 'Бот удален',
  },
  'EventActivationItem:title_Bitrix24_CRM_SUCCESS_EVENT': {
    eng: 'Bitrix24 CRM event updated',
    ru: 'Событие CRM Битрикс24 обновлено',
  },
  'EventActivationItem:title_Bitrix24_CRM_FAILED_EVENT': {
    eng: 'Bitrix24 CRM event not updated',
    ru: 'Событие CRM Битрикс24 не обновлено',
  },
  'EventActivationItem:title_edna_WhatsApp_locationEvent': {
    eng: 'The client sent a location',
    ru: 'Клиент отправил локацию',
  },
  'EventActivationItem:title_Facebook_facebookSendLocation': {
    eng: 'The client sent a location',
    ru: 'Клиент отправил локацию',
  },
  'EventActivationItem:title_Facebook_rawRequestEvent': {
    eng: 'The request failed to trigger any other event. Message text is empty',
    ru: 'На запрос не сработало ни одно другое событие. Текст сообщения отсутствует',
  },
  'EventActivationItem:title_Telegram_telegramSendContact': {
    eng: 'The client sent a contact',
    ru: 'Клиент отправил контакт',
  },
  'EventActivationItem:title_Telegram_telegramSendLocation': {
    eng: 'The client sent a location',
    ru: 'Клиент отправил локацию',
  },
  'EventActivationItem:title_Telegram_telegramApiRequestFailed': {
    eng: 'Failed to upload a file',
    ru: 'Не удалось загрузить файл',
  },
  'EventActivationItem:title_Telegram_telegramAnyMessage': {
    eng: 'Could not identify the message type',
    ru: 'Не удалось распознать тип сообщения',
  },
  'EventActivationItem:title_Telegram_telegramCallbackQuery': {
    eng: 'Button with callback data pressed',
    ru: 'Нажатие кнопки c данными обратного вызова',
  },
  'EventActivationItem:title_Telegram_telegramAnyRequest': {
    eng: 'Request failed to trigger any other event',
    ru: 'На запрос не сработало ни одно другое событие',
  },
  'EventActivationItem:title_Viber_viberSendLocation': {
    eng: 'The client sent a location',
    ru: 'Клиент отправил локацию',
  },
  'EventActivationItem:title_Viber_viberSendSticker': {
    eng: 'The client sent a sticker',
    ru: 'Клиент отправил стикер',
  },
  'EventActivationItem:title_Viber_viberSendContact': {
    eng: 'The client sent a contact',
    ru: 'Клиент отправил контакт',
  },
  'EventActivationItem:title_Viber_viberUnsubscribe': {
    eng: 'The client unsubscribed from the bot. The bot is unable to send any new messages',
    ru: 'Клиент отписался от бота. Бот не может посылать новые сообщения',
  },
  'EventActivationItem:title_Viber_conversationStarted': {
    eng: 'The bot sent a greeting message in the conversation before the session started',
    ru: 'Бот отправил приветственное сообщение до начала сессии',
  },
  'EventActivationItem:title_Webim__External_Bot_API_2_0__noLivechatOperatorsOnline': {
    eng: 'No agents are online',
    ru: 'Отсутствуют операторы онлайн',
  },
  'EventActivationItem:title_WeChat_weChatSendLocation': {
    eng: 'The client sent a location',
    ru: 'Клиент отправил локацию',
  },
  'EventActivationItem:title_WeChat_weChatAnyMessage': {
    eng: 'Could not identify the message type',
    ru: 'Не удалось распознать тип сообщения',
  },
  'EventActivationItem:title_Customer_engagement_platforms_livechatFinished': {
    eng: 'Conversation ended by the agent',
    ru: 'Оператор закрыл чат с клиентом',
  },
  'EventActivationItem:title_Customer_engagement_platforms_noLivechatOperatorsOnline': {
    eng: 'No agents are online',
    ru: 'Отсутствуют операторы онлайн',
  },
  'EventActivationItem:title_Telephony_hangup': {
    eng: 'The client hung up',
    ru: 'Клиент повесил трубку',
  },
  'EventActivationItem:title_Telephony_transfer': {
    eng: 'Call transferred to the agent',
    ru: 'Совершен перевод на оператора',
  },
  'EventActivationItem:title_Telephony_botHangup': {
    eng: 'Conversation ended by the bot',
    ru: 'Разговор окончен по инициативе бота',
  },
  'EventActivationItem:title_Telephony_noDtmfAnswerEvent': {
    eng: 'The client failed to enter a DTMF message in tone dialing mode',
    ru: 'Абонент не ввел DTMF-сообщение, находясь в режиме тонального набора',
  },
  'EventActivationItem:title_Telephony_speechNotRecognized': {
    eng: 'The client request could not be recognized within 5 seconds',
    ru: 'Запрос клиента не был распознан в течение 5 секунд',
  },
  'EventActivationItem:title_Telephony_onCallNotConnected': {
    eng: 'The bot could not reach the client',
    ru: 'Бот не смог дозвониться до клиента',
  },
  'EventActivationItem:title_Telephony_bargeInIntent': {
    eng: 'The bot made a conditional barge-in attempt',
    ru: 'Клиент сделал попытку перебить бота в режиме перебивания по условию',
  },
};
