import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { AutoLayoutProgressSubject$, AutoLayoutType, BlocksPositions } from 'modules/JGraph/hooks/useAutoplacement';
import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { MovementData } from 'modules/Editor/api/client';
import { moveState } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { restoreStickers, setBlockPositions, updateConnectedStickersAfterStateUpdate } from '../index';
import { findScreenByPathId } from '../Graph';
import { JStateWithId } from '../types';
import syncStickers from './syncStickers';

type BatchMovePayload = {
  strategy: AutoLayoutType | undefined;
  positions: BlocksPositions;
  positionsForApi: Record<string, MovementData[]>;
};
const batchMove = createAsyncThunk('JGraph/batchMove', async (payload: BatchMovePayload, thunkAPI) => {
  const stateBeforeUpdate = thunkAPI.getState() as RootState;
  await thunkAPI.dispatch(setBlockPositions(payload.positions));

  const batchedMovePromise = Promise.all(
    Object.keys(payload.positionsForApi).map(filename => {
      return thunkAPI.dispatch(
        moveState({
          movementsDataWithFile: [
            {
              filename: filename,
              statesMovementsData: payload.positionsForApi[filename],
            },
          ],
        })
      );
    })
  );

  mainSave$.next({
    type: 'move',
    path: 'batch save move',
    action: () =>
      batchedMovePromise
        .then(async result => {
          AutoLayoutProgressSubject$.next({ type: payload.strategy, status: 'done' });

          // stickers move
          for (let [pathId, position] of Object.entries(payload.positions)) {
            let screen = findScreenByPathId(pathId, stateBeforeUpdate.JGraphReducer.graph.blocks);
            if (!screen) continue;
            const stateAfterUpdate = {
              path: screen.path,
              x: position.x,
              y: position.y,
            };
            await thunkAPI.dispatch(
              updateConnectedStickersAfterStateUpdate({ stateBeforeUpdate: screen, stateAfterUpdate })
            );
          }
          await thunkAPI.dispatch(syncStickers(stateBeforeUpdate.JGraphReducer.stickers));

          return result;
        })
        .catch(error => {
          AutoLayoutProgressSubject$.next({ type: payload.strategy, status: 'error', error });
        }),
  });
});

type StringToFileMap = Record<string, { path: string; filename: string }>;
function buildScreenPathToFileMap(states: JStateWithId[], res: StringToFileMap = {}): StringToFileMap {
  return states.reduce((prevValue, currentItem) => {
    prevValue[currentItem.pathId] = {
      path: currentItem.path,
      filename: currentItem.filename,
    };
    if (currentItem.states?.length) {
      prevValue = buildScreenPathToFileMap(currentItem.states, prevValue);
    }
    return prevValue;
  }, res);
}

export const revertBatchMove = createAsyncThunk(
  'JGraph/revertBatchMove',
  async (revertEvent: RevertEvent<BatchMovePayload>, thunkAPI) => {
    AutoLayoutProgressSubject$.next({ type: undefined, status: 'pending' });

    const state = thunkAPI.getState() as RootState;
    const mapScreenPathIdFiles = buildScreenPathToFileMap(state.JGraphReducer.graph.blocks);

    const positionsToSave: Record<string, MovementData[]> = {};
    const positions: BlocksPositions = {};

    Object.keys(revertEvent.payload.positions).forEach(pathId => {
      const screen = findScreenByPathId(pathId, revertEvent.prevState.JGraphReducer.graph.blocks);
      if (!screen) return;
      positions[pathId] = { x: screen.x, y: screen.y };

      if (!positionsToSave[mapScreenPathIdFiles[pathId].filename]) {
        positionsToSave[mapScreenPathIdFiles[pathId].filename] = [];
      }
      positionsToSave[mapScreenPathIdFiles[pathId].filename].push({
        targetState: screen.path,
        x: screen.x,
        y: screen.y,
      });
    }, [] as { pathId: string; x: number; y: number }[]);

    await thunkAPI.dispatch(setBlockPositions(positions));

    const batchedMovePromise = Promise.all(
      Object.keys(positionsToSave).map(filename => {
        return thunkAPI.dispatch(
          moveState({
            movementsDataWithFile: [
              {
                filename: filename,
                statesMovementsData: positionsToSave[filename],
              },
            ],
          })
        );
      })
    );

    mainSave$.next({
      type: 'move',
      path: 'batch save move',
      action: () =>
        batchedMovePromise
          .then(async result => {
            await thunkAPI.dispatch(restoreStickers(revertEvent.prevState.JGraphReducer.stickers));
            await thunkAPI.dispatch(syncStickers(state.JGraphReducer.stickers));

            AutoLayoutProgressSubject$.next({ type: undefined, status: 'done' });
            return result;
          })
          .catch(error => {
            AutoLayoutProgressSubject$.next({ type: undefined, status: 'error', error });
          }),
    });
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertBatchMove, batchMove);
