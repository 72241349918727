import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { TagNames } from 'modules/JGraph/utils/types';

type StateType = 'start' | 'noMatch' | 'noMatchGlobal';
export function isStateType(screen: JStateWithId, type: StateType) {
  switch (type) {
    case 'start':
      return screen.blocks.some(block => block.tagName === TagNames.q_ && block.tagValue === '$regex</start>');
    case 'noMatch':
      return screen.blocks.some(
        block => [TagNames.event_, TagNames.event].includes(block.tagName) && block.tagValue === 'noMatch'
      );
    case 'noMatchGlobal':
      return screen.blocks.some(block => block.tagName === TagNames.event_ && block.tagValue === 'noMatch');
    default:
      return false;
  }
}
