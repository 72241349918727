var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import cn from 'classnames';
import { Tooltip } from '@just-ai/just-ui/dist/Tooltip';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import ApplyChangesButton from './ApplyChangesButton';
import { FAQ_MAX_SKILLS } from '../../constants';
import { TabsCollapsible } from '../TabsCollapsible';
import { useDeleteModal } from '../FaqTreeContextMenu/hooks';
import { DeleteSkillModal } from '../DeleteModal';
import classes from './styles.module.scss';
import './styles.scss';
import { useNLUModuleContext } from '../../../../context';
import { useTranslation } from '@just-ai/just-ui';
var FaqTemplatesTabs = function (_a) {
    var skills = _a.skills, activeTab = _a.activeTab, onChange = _a.onChange, needsTraining = _a.needsTraining, trainNlu = _a.trainNlu, onTabDelete = _a.onTabDelete, onTabRename = _a.onTabRename, onTabToggle = _a.onTabToggle, writeDisabled = _a.writeDisabled, homeTabCode = _a.homeTabCode;
    var wrappedHistoryPush = useNLUModuleContext().wrappedHistoryPush;
    var t = useTranslation().t;
    var _b = useDeleteModal(false, onTabDelete), isDeleteModalOpen = _b[0], openDeleteModal = _b[1], closeDeleteModal = _b[2], handleDelete = _b[3], skillToDelete = _b[4];
    var maxSkillsReached = skills.length >= FAQ_MAX_SKILLS;
    var tabsWithHomeTab = useMemo(function () {
        return __spreadArray([
            { dataTestId: 'FAQHomeTab', withoutContextMenu: true, value: homeTabCode, name: React.createElement(Icon, { name: 'farHomeLgAlt' }) }
        ], skills.map(function (skill) { return ({
            name: skill.editComponent || skill.name,
            value: skill.name,
            dataTestId: skill.name,
            skillDisabled: !skill.enabled,
        }); }), true);
    }, [homeTabCode, skills]);
    return (React.createElement("div", { className: cn('faqTemplatesTabs', classes.faqTemplatesTabs) },
        React.createElement(TabsCollapsible, { activeTab: activeTab, onChange: onChange, className: classes.faqTemplatesTabs__tabs, tabs: tabsWithHomeTab, onTabDelete: openDeleteModal, onTabRename: onTabRename, onTabDisable: onTabToggle }),
        React.createElement("span", { onClick: function () {
                return !maxSkillsReached &&
                    !writeDisabled &&
                    wrappedHistoryPush({ url: 'faq-template', historyState: { addSkill: true, fromTab: activeTab } });
            }, className: cn(classes.faqTemplatesTabs__actionBtn, (maxSkillsReached || writeDisabled) && classes['faqTemplatesTabs__actionBtn--disabled']), id: 'addSkillBtn' },
            React.createElement(Icon, { name: 'farPlus', size: 'sm' }),
            t('FAQ:AddSkill')),
        maxSkillsReached && React.createElement(Tooltip, { target: 'addSkillBtn' }, t('FAQ:AddSkills:MaxTooltip')),
        React.createElement(ApplyChangesButton, { needsTraining: needsTraining, trainNlu: trainNlu }),
        React.createElement(DeleteSkillModal, { isOpen: isDeleteModalOpen, close: closeDeleteModal, handleDelete: handleDelete, deleteItem: skillToDelete })));
};
export default React.memo(FaqTemplatesTabs);
