import React, { useCallback } from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle } from '../Dropdown';
import classNames from "classnames";
const SPEEDS = [4, 3, 2, 1.5, 1];
const SpeedAudio = ({ onChangeSpeed, currentSpeed }) => {
    const onDropdownItemClick = useCallback((speed) => () => {
        onChangeSpeed(speed);
    }, [onChangeSpeed]);
    return (React.createElement(DropdownButton, { direction: "up", "data-test-id": "dropdown-wrapper", className: "audio-speed" },
        React.createElement(DropdownToggle, { color: "none", "data-test-id": "dropdown-toggle", className: "audio-speed__button audio-speed__value audio-speed__value_current" }, `${currentSpeed}x`),
        React.createElement(DropdownMenu, { "data-test-id": "dropdown-menu", className: "audio-speed__menu" }, SPEEDS.map((speed) => React.createElement(DropdownItem, { key: `speed-${speed}`, className: classNames("audio-speed__value", { "audio-speed__value_current": currentSpeed === speed }), onClick: onDropdownItemClick(speed), "data-test-id": "dropdown-item" }, `${speed}x`)))));
};
export default React.memo(SpeedAudio);
