import React from 'react';
import { Nav } from 'reactstrap';
import classNames from 'classnames';
import './Tabs.scss';
import { Tab } from './Tab';
export const Tabs = ({ tabs, onChange = () => { }, fullWidth = false, activeTab, name, blackBackground, closable, compact, bordered, bold, closePlace = 'right', onClose = () => { }, className, tabIndex, }) => {
    const getTabClickHandler = (value) => () => {
        onChange(value, name);
    };
    const getCloseHandler = (value) => (event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onClose(value);
    };
    return (React.createElement(Nav, { style: { cursor: 'pointer' }, className: classNames('justui_tabs', {
            'justui_tabs-closable': closable,
            'justui_tabs-dark': blackBackground,
            'justui_tabs-compact': compact,
            'justui_tabs-bordered': bordered,
            'justui_tabs-bold': bold,
            'justui_tabs-fullwidth': fullWidth,
        }, className) }, tabs.map(tabItem => (React.createElement(Tab, { item: tabItem, key: tabItem.value, keyId: tabItem.value, width: fullWidth ? `${100 / tabs.length}%` : 'inherit', isActive: activeTab === tabItem.value, closable: closable, closePlace: closePlace, onClick: getTabClickHandler(tabItem.value), onClose: getCloseHandler(tabItem.value), disabled: tabItem.disabled, tabIndex: tabIndex })))));
};
export * from './TabsScrollable';
export * from './Tab';
