import { Vector2d } from 'konva/lib/types';
import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Rect, Text } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { IconNames, KIcon } from '../parts/KIcons';
import { AutosizeRect } from '../parts/AutosizeRect';
import { t } from 'localization';
import { IncomingItem } from './IncomingItem';
import { OutgoingItem } from './OutgoingItem';

import { recalculateAffectedConnections } from '../../utils/connectionLayerUtils';
import { getStageContextFromRef, getStageFromEvent, getStageFromRef } from '../../utils/stageUtils';
import { Observable, Subject } from 'rxjs';
import { ConnectorStore } from '../../contexts/types';
import { getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import Konva from 'konva';
import { TagNames } from '../../utils/types';
import { TransitionCircle } from '../parts/TransitionCircle';

type IncomingOutgoingConnectionProps = {
  type: 'incoming' | 'outgoing';
  position: Vector2d;
  connections: TConnector[];
  connectorsFromStore$: Observable<ConnectorStore>;
  listening: boolean;
};

type PathContextType = {
  path: string;
};
const PathContext = createContext({} as PathContextType);
export const usePathContext = () => useContext(PathContext);

export const IncomingOutgoingConnections: FC<IncomingOutgoingConnectionProps> = React.memo(
  ({ type, position, connections, connectorsFromStore$, listening }) => {
    const [collapsed, setCollapsed] = useState(false);
    const StateRef = useRef<Konva.Group | null>(null);

    const collapse = useCallback(() => {
      setCollapsed(prev => !prev);
    }, []);

    const connectionsToView = useMemo(() => {
      const returnMap: Record<string, TConnector[]> = {};
      if (type === 'incoming') {
        connections.forEach(connector => {
          if (!returnMap[connector.fromNodeOriginalPath]) {
            returnMap[connector.fromNodeOriginalPath] = [];
          }
          returnMap[connector.fromNodeOriginalPath].push(connector);
        });
      }
      if (type === 'outgoing') {
        connections.forEach(connector => {
          if (connector.to) {
            if (!returnMap[connector.toNodeOriginalPath!]) {
              returnMap[connector.toNodeOriginalPath!] = [];
            }
            returnMap[connector.toNodeOriginalPath!].push(connector);
          }
        });
      }
      return returnMap;
    }, [connections, type]);

    const recalculateConnections = useCallback(
      (event: KonvaEventObject<DragEvent>) => {
        const stage = getStageFromEvent(event);
        if (!stage) return;
        const connectionsLayer = stage.children && stage.children[0];
        if (!connectionsLayer) return;
        const connections = connectionsLayer.children
          ?.map(child => child.attrs?.connector)
          .filter(v => v) as TConnector[];
        const actionNodeIds = connections.map(connection =>
          type === 'incoming' ? connection.fromNode : String(connection.to)
        );
        recalculateAffectedConnections(connections, connectorsFromStore$, stage, actionNodeIds);
      },
      [connectorsFromStore$, type]
    );

    useEffect(() => {
      let connectorsFromPipeSave$: Subject<ConnectorStore> | null = null;
      const uniqStatePaths = Array.from(
        new Set(connections.map(c => (type === 'incoming' ? c.fromNodeOriginalPath : c.toNodeOriginalPath)))
      ).filter(Boolean);
      let valueToStore: Record<
        string,
        {
          fromRef?: Konva.Circle | Konva.Group;
          fromRefFallBack?: Konva.Circle;
          toRef?: Konva.Group | null;
        }
      > = {};
      uniqStatePaths.forEach(statePath => {
        valueToStore = {
          ...valueToStore,
          [getValidKonvaName(statePath)]: {
            toRef: StateRef.current,
          },
        };
      });

      if (StateRef.current && collapsed) {
        const { connectorsFromPipe$ } = getStageContextFromRef(StateRef);
        connectorsFromPipeSave$ = connectorsFromPipe$;
        if (type === 'outgoing') {
          connectorsFromPipe$.next(valueToStore);
        }
      }
      return () => {
        if (connectorsFromPipeSave$ && type === 'outgoing') {
          uniqStatePaths.forEach(statePath => {
            valueToStore = {
              ...valueToStore,
              [getValidKonvaName(statePath)]: {
                toRef: null,
              },
            };
          });
          connectorsFromPipeSave$!.next(valueToStore);
        }
      };
    }, [collapsed, connections, type]);

    useEffect(() => {
      const stage = getStageFromRef(StateRef);
      if (!stage) return;
      recalculateAffectedConnections(connections, connectorsFromStore$, stage);
    }, [connections, connectorsFromStore$, position]);

    return (
      <Group
        draggable={listening}
        id={`IncomingOutgoing_${type}`}
        x={position.x || 0}
        y={position.y || 0}
        isScreen={false}
        onDragMove={recalculateConnections}
      >
        <AutosizeRect width={226} fill='#F4F5F5' stroke='#E0E1E3' cornerRadius={8} bottomPadding={collapsed ? 4 : 0}>
          <Group x={12} y={8} ref={StateRef}>
            <KIcon width={16} height={16} x={0} y={0} icon={IconNames[type]} />
            <Text
              x={24}
              width={154}
              ellipsis
              wrap='none'
              text={t(`IncomingOutgoingConnections:${type}Title`)}
              fill='#0D1822'
              fontSize={12}
              lineHeight={18 / 12}
              letterSpacing={0.01}
            />
            {collapsed &&
              type === 'incoming' &&
              Object.keys(connectionsToView).map(connectionFrom => (
                <Group key={connectionFrom}>
                  <PathContext.Provider value={{ path: connectionFrom }}>
                    {connectionsToView[connectionFrom].map(connection => (
                      <TransitionCircle
                        key={connection.from}
                        x={150 + 8 + 16 + 8 + 12}
                        y={8}
                        name={connection.from}
                        tagName={connection.tagName as TagNames}
                        transitionTo={connection.toNodeOriginalPath}
                        hasTransition={true}
                        canStartConnection={false}
                        debugActive={connection.debugActive}
                      />
                    ))}
                  </PathContext.Provider>
                  <Rect width={16} height={16} x={150 + 8 + 16 + 8 + 4} y={0} fill='#F4F5F5' />
                </Group>
              ))}
            <KIcon
              width={16}
              height={16}
              x={198 - 12}
              y={0}
              icon={collapsed ? IconNames.collapsible_open : IconNames.collapsible_close}
              onClick={collapse}
            />
          </Group>
          {!collapsed && (
            <Group y={32} key={Object.keys(connectionsToView).length}>
              {type === 'incoming' &&
                Object.keys(connectionsToView).map(connectionFrom => (
                  <PathContext.Provider key={connectionFrom} value={{ path: connectionFrom }}>
                    <IncomingItem path={connectionFrom} connections={connectionsToView[connectionFrom]} />
                  </PathContext.Provider>
                ))}
              {type === 'outgoing' &&
                Object.keys(connectionsToView).map(path => <OutgoingItem key={path} path={path} />)}
            </Group>
          )}
        </AutosizeRect>
      </Group>
    );
  }
);
IncomingOutgoingConnections.displayName = 'IncomingOutgoingConnections';
