import { useState, useMemo, useEffect } from 'react';

import { useAppSelector } from 'storeHooks';

import { getAllStates } from 'reducers/JGraph.reducer/Graph';

import { composeCustomValidationResolver } from 'utils/validator/customValidationResolver';
import { checkEmptyStateName, checkSlashInStateName, checkStateNameIsBusy } from './validators/stateName';

const stateNameValidation = composeCustomValidationResolver(
  checkEmptyStateName,
  checkSlashInStateName,
  checkStateNameIsBusy
);

export default function useNewStateNameValidation(name: string) {
  const { blocks } = useAppSelector(state => ({
    blocks: state.JGraphReducer.graph.blocks,
  }));
  const [errorText, setErrorText] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const allStates = useMemo(() => getAllStates(blocks), [blocks]);

  useEffect(() => {
    const stateValue = name.replace(/\/+/g, '/').trim();
    stateNameValidation({ name: stateValue }, { allStates, parentPath: '' }).then(validationResult => {
      setErrorText(validationResult.errors?.name?.message || '');
      setIsInvalid(!validationResult.isValid);
    });
  }, [allStates, name]);

  return { isInvalid, errorText };
}
