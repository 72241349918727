var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LabelsApi, } from '../api/client';
import { LabelsApi as LabelsReporterApi, } from '../../Reporter/api/client';
import { axios } from '../../Reporter/utils/pipes/functions';
var LabelsService = /** @class */ (function () {
    function LabelsService(accountId, useReporter) {
        var _this = this;
        this.createLabel = function (logLabelCreate, group) {
            if (_this.useReporter) {
                var label = { name: logLabelCreate.name, priority: logLabelCreate.priority, group: group };
                return _this.labelsReporterService.createMessageLabel(_this.accountId, label);
            }
            return _this.labelsService.createLabel(_this.accountId, logLabelCreate);
        };
        this.createLabels = function (labels) {
            return _this.labelsReporterService.createMessageLabels(_this.accountId, labels);
        };
        this.getLabels = function (options) {
            return _this.useReporter
                ? _this.labelsReporterService.getMessageLabels(_this.accountId)
                : _this.labelsService.getLabels(_this.accountId);
        };
        this.deleteLabel = function (labelId, options) {
            return _this.useReporter
                ? _this.labelsReporterService.deleteMessageLabel(_this.accountId, labelId)
                : _this.labelsService.deleteLabel(_this.accountId, labelId);
        };
        this.updateLabel = function (labelId, logLabelCreate, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateMessageLabel(_this.accountId, labelId, logLabelCreate)
                : _this.labelsService.updateLabel(_this.accountId, labelId, logLabelCreate);
        };
        this.getLabelGroups = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (!this.useReporter) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getReporterLabelsGroups()];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.labelsService.getLabelGroups(this.accountId)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/, _a];
                    case 5:
                        e_1 = _b.sent();
                        return [2 /*return*/, { data: [] }];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getReporterLabelsGroups = function () { return __awaiter(_this, void 0, void 0, function () {
            var labels, groups, returnGroups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.labelsReporterService.getMessageLabels(this.accountId)];
                    case 1:
                        labels = (_a.sent()).data;
                        groups = {};
                        labels.map(function (label) {
                            //@ts-ignore
                            var group = label.group;
                            if (!groups[group.id])
                                groups[group.id] = __assign(__assign({}, group), { labels: [] });
                            //@ts-ignore
                            groups[group.id].labels.push(__assign({}, label));
                        });
                        returnGroups = Object.values(groups);
                        returnGroups.sort(function (aGroup, bGroup) { return (aGroup.priority || 0) - (bGroup.priority || 0); });
                        return [2 /*return*/, { data: returnGroups }];
                }
            });
        }); };
        this.getLabelGroup = function (labelGroupId, options) {
            return _this.useReporter
                ? _this.labelsReporterService.getLabelGroup(_this.accountId, labelGroupId)
                : _this.labelsService.getLabelGroup(_this.accountId, labelGroupId);
        };
        this.createLabelGroup = function (logLabelGroupCreate, labels, options) { return __awaiter(_this, void 0, void 0, function () {
            var group_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.useReporter) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createLabel({ name: labels[0], priority: 1 }, logLabelGroupCreate)];
                    case 1:
                        group_1 = (_a.sent()).data.group;
                        if (labels.length > 1) {
                            return [2 /*return*/, this.createLabels(
                                //@ts-ignore
                                labels.slice(1, labels.length).map(function (x, index) { return ({ name: x, priority: index + 2, group: group_1 }); }))];
                        }
                        else {
                            return [2 /*return*/, { data: { group: group_1 } }];
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/, this.labelsService.createLabelGroup(this.accountId, logLabelGroupCreate)];
                }
            });
        }); };
        this.deleteLabelGroup = function (labelGroupId, options) {
            return _this.useReporter
                ? _this.labelsReporterService.deleteMessageLabelGroup(_this.accountId, labelGroupId)
                : _this.labelsService.deleteLabelGroup(_this.accountId, labelGroupId);
        };
        this.updateLabelGroup = function (labelGroupId, logLabelGroupCreate, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateMessageLabelGroup(_this.accountId, labelGroupId, logLabelGroupCreate)
                : _this.labelsService.updateLabelGroup(_this.accountId, labelGroupId, logLabelGroupCreate);
        };
        this.createSessionLabel = function (sessionLabelCreate, options) {
            return _this.useReporter
                ? _this.labelsReporterService.createSessionLabel(_this.accountId, sessionLabelCreate)
                : _this.labelsService.createSessionLabel(_this.accountId, sessionLabelCreate);
        };
        this.getSessionLabels = function (options) { return __awaiter(_this, void 0, void 0, function () {
            var _a, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        if (!this.useReporter) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.labelsReporterService.getSessionLabels(this.accountId)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.labelsService.getSessionLabels(this.accountId)];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/, _a];
                    case 5:
                        e_2 = _b.sent();
                        return [2 /*return*/, { data: [] }];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.getSessionLabel = function (sessionLabelId, options) {
            return _this.useReporter
                ? _this.labelsReporterService.getSessionLabel(_this.accountId, sessionLabelId)
                : _this.labelsService.getSessionLabel(_this.accountId, sessionLabelId);
        };
        this.deleteSessionLabel = function (sessionLabelId, options) {
            return _this.useReporter
                ? _this.labelsReporterService.deleteSessionLabel(_this.accountId, sessionLabelId)
                : _this.labelsService.deleteSessionLabel(_this.accountId, sessionLabelId);
        };
        this.updateSessionLabel = function (sessionLabelId, sessionLabelCreate, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateSessionLabel(_this.accountId, sessionLabelId, sessionLabelCreate)
                : _this.labelsService.updateSessionLabel(_this.accountId, sessionLabelId, sessionLabelCreate);
        };
        this.updateGroupsPriority = function (labelPriorityChangeDto, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateGroupsPriority(_this.accountId, labelPriorityChangeDto, options)
                : _this.labelsService.updateGroupsPriority(_this.accountId, labelPriorityChangeDto, options);
        };
        this.updateSessionsPriority = function (labelPriorityChangeDto, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateSessionsPriority(_this.accountId, labelPriorityChangeDto, options)
                : _this.labelsService.updateSessionsPriority(_this.accountId, labelPriorityChangeDto, options);
        };
        this.updateLogLabelsPriority = function (labelPriorityChangeDto, options) {
            return _this.useReporter
                ? _this.labelsReporterService.updateMessageLabelsPriority(_this.accountId, labelPriorityChangeDto, options)
                : _this.labelsService.updateLogLabelsPriority(_this.accountId, labelPriorityChangeDto, options);
        };
        this.getIssuesRequestedType = function (reqType, options) {
            return axios.get("/restapi/logs/issues/".concat(reqType));
        };
        this.createIssuesRequestedType = function (reqType, value, options) {
            return axios.post("/restapi/logs/issues/".concat(reqType), value, options);
        };
        this.updateIssueRequestedType = function (reqType, id, value, options) {
            return axios.put("/restapi/logs/issues/".concat(reqType, "/").concat(id), value, options);
        };
        this.updateIssuesRequestedTypePriority = function (reqType, value, options) {
            return axios.put("/restapi/logs/issues/".concat(reqType, "/change-priority"), value, options);
        };
        this.deleteIssueRequestedType = function (reqType, id, options) {
            return axios.delete("/restapi/logs/issues/".concat(reqType, "/").concat(id), options);
        };
        this.setLabelToSessions = function (bulkLabelingDto) {
            return _this.labelsReporterService.setLabelToSessions(_this.accountId, bulkLabelingDto);
        };
        this.setLabelToMessages = function (bulkLabelingDto) {
            return _this.labelsReporterService.setLabelToMessages(_this.accountId, bulkLabelingDto);
        };
        this.accountId = accountId;
        this.useReporter = Boolean(useReporter);
        this.labelsService = new LabelsApi({}, '', axios);
        this.labelsReporterService = new LabelsReporterApi({}, '', axios);
    }
    return LabelsService;
}());
export default LabelsService;
