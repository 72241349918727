import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
var getPositionForDirection = function (domRect, direction) {
    switch (direction) {
        case 'left': {
            return { top: domRect.top + domRect.height / 2, left: domRect.left + domRect.width + 7 };
        }
        default: {
            return { top: domRect.top + domRect.height / 2, left: domRect.left + domRect.width + 7 };
        }
    }
};
var ErrorMessageTooltip = function (_a) {
    var _b = _a.text, text = _b === void 0 ? 'Error' : _b, show = _a.show, _c = _a.direction, direction = _c === void 0 ? 'left' : _c, node = _a.node, positionProp = _a.position, dataTestId = _a.dataTestId;
    var _d = useState(undefined), position = _d[0], setPosition = _d[1];
    useEffect(function () {
        if (positionProp) {
            setPosition(positionProp);
        }
        else if (node) {
            var domRect = node === null || node === void 0 ? void 0 : node.getBoundingClientRect();
            if (!domRect)
                return;
            var newPosition = getPositionForDirection(domRect, direction);
            setPosition(newPosition);
        }
        return function () {
            setPosition(undefined);
        };
    }, [direction, node, positionProp]);
    return ReactDOM.createPortal(React.createElement("div", { className: cn(styles.errorTooltip, show && styles['errorTooltip--active'], styles["arrow-".concat(direction)]), style: { position: 'fixed', top: position === null || position === void 0 ? void 0 : position.top, left: position === null || position === void 0 ? void 0 : position.left }, "data-test-id": dataTestId }, text), document.body);
};
export default React.memo(ErrorMessageTooltip);
