import { last } from 'lodash';

export function hideRootSlashInPath(statePath: string) {
  return statePath.startsWith('/') ? statePath.substring(1) : statePath;
}

export function simplifyStateName(statePath: string) {
  const pathParts = statePath.split('/');
  return last(pathParts);
}
