import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { withController } from './Controller';
import { Button } from '@just-ai/just-ui';
import { StringArrayItem } from './StringArrayItem.field';
import { v4 as uuid } from 'uuid';
import { t } from 'localization';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

const prepareValues = (value: string | string[]) => {
  return (
    typeof value === 'string'
      ? JSON.parse(value).map((str: string) => ({
          key: uuid(),
          value: str || '',
        }))
      : value.map(val => ({
          key: uuid(),
          value: val || '',
        }))
  ) as { key: string; value: string }[];
};

const StringArrayView: FC<CommonFieldProps> = ({
  name,
  label,
  isRequired,
  value,
  onChange,
  onBlur,
  invalid,
  description,
}) => {
  const [innerValues, setInnerValues] = useState(prepareValues(value));
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const onClickAdd = useCallback(() => {
    setInnerValues(innerValues => {
      innerValues.push({
        key: uuid(),
        value: '',
      });
      return [...innerValues];
    });
  }, []);

  const onChangeItem = useCallback((index: number, value: string) => {
    setInnerValues(innerValues => {
      innerValues[index].value = value || '';
      return [...innerValues];
    });
  }, []);

  const onRemoveItem = useCallback((index: number) => {
    setInnerValues(innerValues => {
      innerValues.splice(index, 1);
      return [...innerValues];
    });
  }, []);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(innerValues.map(value => value.value || ''));
    }, 100);
  }, [innerValues, onChange]);

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      {innerValues.map((innerValue, index) => (
        <StringArrayItem
          key={innerValue.key}
          onRemove={onRemoveItem}
          index={index}
          onChange={onChangeItem}
          value={innerValue.value}
          invalid={invalid}
          onBlur={onBlur}
        />
      ))}
      <div>
        <Button color='primary' iconLeft='farPlus' iconRight='' flat onClick={onClickAdd} size='md'>
          {t('CustomTags:StringArray:AddField')}
        </Button>
      </div>
    </div>
  );
};

export const StringArray = withController(StringArrayView);
