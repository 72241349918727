import React from 'react';
import { FormText, InputText } from '../../index';
import cn from 'classnames';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function LinkTab(props) {
    const { type, localUrl, setLocalUrl, error } = props;
    const { t } = useTranslation();
    return (React.createElement("div", { className: 'ImageSettings-text-input' },
        React.createElement(InputText, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.LinkText', value: localUrl, onChange: setLocalUrl }),
        React.createElement(FormText, { className: 'mb-0' }, t('AudioSettings:urlHint')),
        type === AnswerReplyType.audio && React.createElement(FormText, { className: 'mt-0' }, t(`AnswerModal:subtext_${type}`)),
        React.createElement("p", { className: cn({ 'links-disabled': props.isWhiteLabel() }), dangerouslySetInnerHTML: {
                __html: t(`AnswerModal:info_${type}`, t(`AnswerModal:info_link_${type}`)),
            } }),
        error && (React.createElement(FormText, { color: 'danger', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.Error' }, error)),
        type === AnswerReplyType.image && localUrl && (React.createElement("div", { className: 'faq-anwer-modal__img-preview margin-top-3x', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.ImgPreview' },
            React.createElement("img", { src: localUrl, alt: '' })))));
}
