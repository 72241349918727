import React from 'react';
import cn from 'classnames';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import styles from './styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
export default function QuestionsModal(props) {
    var _a;
    var t = useTranslation().t;
    var isOpen = props.isOpen, onSubmit = props.onSubmit, toggle = props.toggle, name = props.name, _b = props.questions, questions = _b === void 0 ? [] : _b, type = props.type;
    var isEmptyTemplate = type === 'empty_template';
    return (React.createElement(Modal, { className: styles.faqQuestionsModal, isOpen: isOpen, buttonSubmitText: t('FAQ:AddSkill'), onClickCloseIcon: toggle, onCancelClick: toggle, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), onActionClick: onSubmit, title: isEmptyTemplate ? name : t('sampleQuestions', name), formClassName: cn((_a = {}, _a[styles['empty-template']] = isEmptyTemplate, _a)), centered: true },
        React.createElement("ul", null, isEmptyTemplate
            ? t('FAQEmptyTemplateDesc')
            : questions === null || questions === void 0 ? void 0 : questions.map(function (question, index) { return React.createElement("li", { key: index }, question); }))));
}
