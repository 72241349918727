var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component, createRef } from 'react';
import { AnalyticsContext } from '../../../index';
import './Session.css';
import MessagesList from '../Sessions/MessagesList';
import ActionPanel from '../Sessions/SessionsList/ActionPanel/ActionPanel';
import SessionInfo from './SessionInfo';
import SessionForm from './SessionForm';
import AudioBlock from '../Sessions/MessagesList/Message/AudioBlock';
import queryString from 'query-string';
import { Button, Icon } from '@just-ai/just-ui';
import { SessionIdsController } from '../../utils/SessionIdsController';
var Session = /** @class */ (function (_super) {
    __extends(Session, _super);
    function Session(props) {
        var _a, _b;
        var _this = _super.call(this, props) || this;
        _this.contextIsReady = false;
        _this.setCompactView = function (compactView) { return _this.setState({ compactView: compactView }); };
        _this.setHideEmptyMessages = function () {
            _this.setState(function (oldState) { return (__assign(__assign({}, oldState), { hideEmptyMessages: !oldState.hideEmptyMessages, page: 0 })); }, _this.fetchData);
        };
        _this.initSessionController = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, accountId, projectShortName, getSessionsByFilter, _b, hasBack, hasForward, totalCount, currentPosition;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, getSessionsByFilter = _a.getSessionsByFilter;
                        if (!(!this.sessionIdsController && this.filterSetId && this.sessionPage !== undefined)) return [3 /*break*/, 2];
                        this.sessionIdsController = new SessionIdsController({
                            filterSetId: this.filterSetId,
                            accountId: accountId,
                            initialPage: this.sessionPage,
                            projectShortName: projectShortName,
                            getSessionsByFilter: getSessionsByFilter,
                        });
                        return [4 /*yield*/, this.sessionIdsController.init(this.state.sessionId)];
                    case 1:
                        _b = _c.sent(), hasBack = _b.hasBack, hasForward = _b.hasForward, totalCount = _b.totalCount, currentPosition = _b.currentPosition;
                        this.setState({ showNextSession: hasForward, showPreviousSession: hasBack, totalCount: totalCount, currentPosition: currentPosition });
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, page, responseDataJsonPath, sessionId, stateStartTime, hideEmptyMessages, _b, match, location, history, startTime, totalCount;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.state, page = _a.page, responseDataJsonPath = _a.responseDataJsonPath, sessionId = _a.sessionId, stateStartTime = _a.startTime, hideEmptyMessages = _a.hideEmptyMessages;
                        _b = this.props, match = _b.match, location = _b.location, history = _b.history;
                        startTime = stateStartTime || new Date(queryString.parse(location.search).startTime);
                        if (isNaN(startTime))
                            startTime = undefined;
                        return [4 /*yield*/, this.context.getMessagesForSession(sessionId, page, 
                            //@ts-ignore
                            responseDataJsonPath, match.params.botId, startTime ? new Date(startTime) : undefined, hideEmptyMessages)];
                    case 1:
                        totalCount = (_d.sent()).paging.totalCount;
                        if ((_c = this.contentRef) === null || _c === void 0 ? void 0 : _c.current) {
                            this.contentRef.current.scrollTop = 0;
                        }
                        this.context.loadIssues('sessionMessages');
                        this.context.loadSessionIssue();
                        history.push({
                            pathname: location.pathname,
                            search: this.getNewSearch(),
                        });
                        this.setState({ total: totalCount, totalPages: Math.ceil(totalCount / 20) });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.getNewSearch = function () {
            var hideEmptyMessages = _this.state.hideEmptyMessages;
            var search = _this.props.location.search;
            var searchMap = queryString.parse(search);
            searchMap['hideEmptyMessages'] = "".concat(hideEmptyMessages);
            return queryString.stringify(searchMap);
        };
        _this.createReport = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };
        _this.changePage = function (page) {
            if (page === _this.state.page)
                return;
            _this.setState({ page: page }, _this.fetchData);
        };
        _this.goBack = function () { return _this.props.history.push(_this.prevPath); };
        _this.goPreviousSession = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, hasBack, sessionId, startTime, totalCount, currentPosition, params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.sessionIdsController.goBack()];
                    case 1:
                        _a = _b.sent(), hasBack = _a.hasBack, sessionId = _a.sessionId, startTime = _a.startTime, totalCount = _a.totalCount, currentPosition = _a.currentPosition;
                        params = new URLSearchParams();
                        //@ts-ignore
                        params.sessionId = sessionId;
                        this.setState({
                            showPreviousSession: hasBack,
                            startTime: startTime,
                            showNextSession: true,
                            sessionId: sessionId,
                            page: 0,
                            totalCount: totalCount,
                            currentPosition: currentPosition,
                        }, this.fetchData);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goNextSession = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, hasForward, sessionId, startTime, totalCount, currentPosition, params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.sessionIdsController.goForward()];
                    case 1:
                        _a = _b.sent(), hasForward = _a.hasForward, sessionId = _a.sessionId, startTime = _a.startTime, totalCount = _a.totalCount, currentPosition = _a.currentPosition;
                        params = new URLSearchParams();
                        //@ts-ignore
                        params.sessionId = sessionId;
                        this.setState({
                            showPreviousSession: true,
                            startTime: startTime,
                            showNextSession: hasForward,
                            sessionId: sessionId,
                            page: 0,
                            totalCount: totalCount,
                            currentPosition: currentPosition,
                        }, this.fetchData);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.contentRef = createRef();
        var searchParams = queryString.parse(_this.props.location.search);
        _this.state = {
            total: 0,
            page: 0,
            questionIdForScroll: null,
            totalPages: 0,
            compactView: false,
            responseDataJsonPath: false,
            sessionId: decodeURIComponent((_b = (_a = _this.props.match) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.id),
            showNextSession: false,
            showPreviousSession: false,
            totalCount: 0,
            currentPosition: 0,
            hideEmptyMessages: searchParams.hideEmptyMessages === 'true',
        };
        var sessionPage = Number.parseInt(searchParams.sessionPage);
        if (!Number.isNaN(sessionPage) && searchParams.filterSetId) {
            _this.filterSetId = searchParams.filterSetId;
            _this.sessionPage = sessionPage;
            _this.state = __assign({}, _this.state);
        }
        return _this;
    }
    Session.prototype.componentDidMount = function () {
        var _this = this;
        var location = this.props.location;
        var params = new URLSearchParams(location.search);
        var responseDataJsonPath = params.get('responseDataJsonPath');
        var pageURLparam = params.get('page') || '0';
        var questionIdURLparam = params.get('questionId') || null;
        var analyticsContextIsReady = this.context.analyticsContextIsReady;
        this.setState({
            page: Number.parseInt(pageURLparam) || 0,
            responseDataJsonPath: Boolean(responseDataJsonPath),
            questionIdForScroll: questionIdURLparam,
        }, function () {
            var _a, _b;
            if (analyticsContextIsReady) {
                _this.fetchData();
                _this.contextIsReady = true;
                _this.initSessionController();
            }
            _this.prevPath = (_b = (_a = _this.props.history.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.prevPath;
        });
    };
    Session.prototype.componentDidUpdate = function () {
        if (!this.contextIsReady && this.context.analyticsContextIsReady) {
            this.fetchData();
            this.contextIsReady = true;
            this.initSessionController();
        }
    };
    Session.prototype.render = function () {
        var _a, _b, _c, _d;
        var _e = this.context, sessionMessages = _e.sessionMessages, isLoading = _e.isLoading, config = _e.config, session = _e.session;
        var _f = this.props, history = _f.history, location = _f.location, translate = _f.translate;
        var _g = this.state, total = _g.total, page = _g.page, questionIdForScroll = _g.questionIdForScroll, totalPages = _g.totalPages, compactView = _g.compactView, showNextSession = _g.showNextSession, showPreviousSession = _g.showPreviousSession, totalCount = _g.totalCount, hideEmptyMessages = _g.hideEmptyMessages, currentPosition = _g.currentPosition;
        return (React.createElement("div", { ref: this.contentRef, className: 'animated fadeIn Session_container' },
            React.createElement("div", { className: 'Session_head' },
                ((((_a = config.session) === null || _a === void 0 ? void 0 : _a.showBackButton) && this.prevPath) || showNextSession || showPreviousSession) && (React.createElement("div", { className: 'Session_backButton' },
                    React.createElement("div", null, ((_b = config.session) === null || _b === void 0 ? void 0 : _b.showBackButton) && this.prevPath && (React.createElement(Button, { "data-test-id": 'backButton', size: 'md', color: 'secondary', flat: true, onClick: this.goBack, withoutPadding: true },
                        React.createElement(Icon, { name: 'farChevronLeft', size: 'sm' }),
                        translate('Analytics: back button')))),
                    React.createElement("div", null,
                        showPreviousSession && (React.createElement(Button, { onClick: this.goPreviousSession, withoutPadding: true, size: 'md', flat: true, color: 'secondary' },
                            React.createElement(Icon, { name: 'farChevronLeft', size: 'sm' }),
                            translate('Analytic: previous session'))),
                        showPreviousSession && React.createElement("div", { className: 'splitter' }),
                        Boolean(totalCount && totalCount > 1) && (React.createElement("div", null, translate('Analytic: current session position', currentPosition + 1, totalCount))),
                        showNextSession && React.createElement("div", { className: 'splitter' }),
                        showNextSession && (React.createElement(Button, { onClick: this.goNextSession, withoutPadding: true, flat: true, size: 'md', color: 'secondary' },
                            translate('Analytic: next session'),
                            React.createElement(Icon, { name: 'farChevronRight', size: 'sm' })))))),
                React.createElement(SessionInfo, { t: translate }),
                React.createElement(SessionForm, { t: translate })),
            React.createElement(ActionPanel, { hideTotalCount: true, totalCount: total, totalPages: totalPages, page: page, type: 'messages', t: translate, compactView: !compactView, setCompactView: this.setCompactView, hideEmptyMessages: hideEmptyMessages, setHideEmptyMessages: this.setHideEmptyMessages, downloadReportComponent: (_c = config.session) === null || _c === void 0 ? void 0 : _c.downloadReport, changePage: this.changePage }),
            (session === null || session === void 0 ? void 0 : session.audioFileUrl) && (React.createElement("div", { "data-test-id": 'Session.FullCallRecord', className: 'Session_Audio', style: { zIndex: 3 } },
                React.createElement("span", null, translate('Analytic: session audio record')),
                React.createElement(AudioBlock, { url: session === null || session === void 0 ? void 0 : session.audioFileUrl, t: translate, meta: session, withoutTitle: true, dataTestId: 'Session.FullCallRecordMenu' }))),
            React.createElement(MessagesList, { history: history, location: location, t: translate, showAdditionalAction: true, showAnswers: true, showMarkup: true, isSessionMessages: true, messages: sessionMessages, page: page, questionIdForScroll: questionIdForScroll, compactView: compactView, isLoading: isLoading, downloadReportComponent: (_d = config.session) === null || _d === void 0 ? void 0 : _d.downloadMessageReport })));
    };
    Session.contextType = AnalyticsContext;
    return Session;
}(Component));
export { Session };
export default Session;
