import React, { Fragment, useState, useRef } from 'react';
import { AudioPlayer, DropdownMenu, DropdownItem, Modal } from '@just-ai/just-ui';
import './AudioBlock.scss';
import { useToggle } from '../../../../utils/hooks';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { useSpeedAudioContext } from '../../../../context/SpeedAudioContext';
var getSecondsToMinutesAndSeconds = function (time) {
    if (time === 0) {
        return '0:00';
    }
    var minutes = Math.floor(time / 60);
    var seconds = String(time - minutes * 60);
    return "".concat(minutes, ":").concat(seconds.length === 1 ? '0' + seconds : seconds);
};
var AudioBlock = function (_a) {
    var title = _a.title, url = _a.url, _b = _a.meta, meta = _b === void 0 ? {} : _b, _c = _a.startTime, startTime = _c === void 0 ? null : _c, t = _a.t, withoutTitle = _a.withoutTitle, dataTestId = _a.dataTestId;
    var _d = useToggle(false), isOpen = _d[0], open = _d[1], close = _d[2];
    var id = useState(Date.now())[0];
    var _e = useState(0), duration = _e[0], setDuration = _e[1];
    var startTimeTimestamp = startTime ? new Date(startTime).getTime() : null;
    var hiddenInput = useRef(null);
    var _f = useSpeedAudioContext(), currentAudioSpeed = _f.currentAudioSpeed, setCurrentAudioSpeed = _f.setCurrentAudioSpeed;
    var copyMetaInfo = function () {
        if (hiddenInput && hiddenInput.current && document.queryCommandSupported('copy')) {
            hiddenInput.current.value = JSON.stringify(meta);
            hiddenInput.current.select();
            hiddenInput.current.focus();
            window.document.execCommand('copy');
        }
        close();
    };
    var createdAt = null;
    if (startTimeTimestamp && meta.createdAt) {
        try {
            createdAt = meta.createdAt.epochSecond ? meta.createdAt.epochSecond : meta.createdAt;
        }
        catch (e) {
            console.error(e);
        }
    }
    var calculatedTime = 0;
    if (startTimeTimestamp) {
        calculatedTime = Math.floor((createdAt - startTimeTimestamp) / 1000);
    }
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'audio-block', "data-test-id": dataTestId },
            !withoutTitle && (React.createElement("div", { className: classNames('audio-block__title', { 'audio-block__no-title': !title }) },
                startTimeTimestamp && createdAt ? (React.createElement("span", { className: 'audio-block__duration', id: "audio-block-tooltip-".concat(id) },
                    React.createElement(UncontrolledTooltip, { innerClassName: 'audio-block__tooltip', target: "audio-block-tooltip-".concat(id), placement: 'top' },
                        React.createElement("span", null, t('details audio interval tooltip'))),
                    React.createElement("span", null,
                        getSecondsToMinutesAndSeconds(calculatedTime),
                        "\u2013",
                        getSecondsToMinutesAndSeconds(calculatedTime + duration)))) : null,
                React.createElement("span", null, title || t('details phrase not recognized')))),
            React.createElement("div", null,
                React.createElement(AudioPlayer, { url: url, DropdownMenu: function () { return (React.createElement(DropdownMenu, null,
                        React.createElement(DropdownItem, { "data-test-id": "AudioBlock.downloadLink.".concat(dataTestId), style: { padding: '0' } },
                            React.createElement("a", { className: 'audio-block__download-link', href: url }, t('details download audio'))),
                        Object.keys(meta).length > 0 && (React.createElement(DropdownItem, { "data-test-id": "AudioBlock.metaInformation.".concat(dataTestId), onClick: open }, t('details meta inforamtion'))))); }, onChangeDuration: function (value) { return setDuration(value); }, setCurrentSpeed: setCurrentAudioSpeed, currentSpeed: currentAudioSpeed, dataTestId: { menuButton: "AudioMenu.".concat(dataTestId) } }))),
        React.createElement(Modal, { isOpen: isOpen, title: t('details meta inforamtion'), buttonCancelText: t('Cancel'), buttonCancelOutline: true, buttonCancelTestId: 'MetaModal.CloseButton', onCancelClick: close, buttonSubmitText: t('details copy meta'), onActionClick: copyMetaInfo, buttonSubmitTestId: 'MetaModal.CopyButton' },
            React.createElement("div", null,
                React.createElement("code", { className: 'audio-block__meta' }, JSON.stringify(meta)),
                React.createElement("input", { type: 'text', ref: hiddenInput, readOnly: true, className: 'hiddenInput' })))));
};
export default AudioBlock;
