import { TJBlock, TTagParameters } from 'modules/JGraph/utils/types';
import { findScreenByCondition } from 'reducers/JGraph.reducer/Graph';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

import { joinPaths } from './common';

export function setTagParameterInParametersList(
  tagParameter: TTagParameters,
  tagParameters: TTagParameters[]
): TJBlock['tagParameters'] {
  const paramsWithoutKey = tagParameters.filter(tagParam => tagParam.name !== tagParameter.name);
  return [...paramsWithoutKey, tagParameter];
}

export function getUniqPathNameForState(existedStates: JStateWithId[], suggestedPath: string, parentPath?: string) {
  const newStatePathname = joinPaths(parentPath, suggestedPath);
  let copyIndex = 1;
  let newPath = joinPaths(parentPath, suggestedPath);
  while (findScreenByCondition(existedStates, screen => screen.path === newPath)) {
    copyIndex++;
    newPath = newStatePathname + '_' + copyIndex;
  }
  if (parentPath) {
    newPath = newPath.substring(parentPath.length);
  }
  return newPath;
}
