import React, { FC, useCallback } from 'react';
import { useRightSideMenuContext } from '../index';
import { TagNames, TJBlock } from '../../../utils/types';
import { Label } from '@just-ai/just-ui';

import { t } from 'localization';
import { DEFAULT_EDITOR_PROPS } from '../../../../Editor/components/CodeEditor';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

export const ScriptSettings: FC<{}> = () => {
  const { getEditBlockTag, onChangeCommonTag } = useRightSideMenuContext();
  const scriptToEdit = getEditBlockTag() as TJBlock<TagNames.script>;
  const changeScript = useCallback(
    (value: string) => {
      onChangeCommonTag({
        tagValue: value,
      });
    },
    [onChangeCommonTag]
  );

  return (
    <div className='JGraph-RightSideMenu-commonContainer d-flex flex-grow-1 flex-column h-100'>
      <div className='d-flex justify-content-between'>
        <Label for='answerToEditText'>{t('ScriptSettings:ScriptAnswer')}</Label>
      </div>
      <div className='d-flex flex-column flex-grow-1'>
        <AceEditor
          {...DEFAULT_EDITOR_PROPS}
          value={scriptToEdit.tagValue}
          mode='javascript'
          theme='github'
          onChange={changeScript}
          wrapEnabled={true}
        />
      </div>
    </div>
  );
};
