import moment from 'moment';
import axios from 'axios';
import { dropCurrentUser } from '../actions/currentUser.actions';
import { dropCurrentLoginAccount } from '../actions/accounts.actions';
import { reduxStateReference } from '../services/ReduxStateReference';
import history from '../appHistory';
import queryString from 'query-string';
import { pick, isArray } from 'lodash';
import { analyticsArrayFilter } from './constants';
import { getAmplitudeKey, getUserLanguage, isDev } from './pureFunctions';
import amplitude from 'amplitude-js';
import Cookies from 'universal-cookie';
import countries from 'countries-list/dist/countries.min.json';
const russianCountriesName = require('i18n-iso-countries/langs/ru.json');

export * from './pureFunctions';
export * from './constants';

const cookies = new Cookies();

export function formatDate(date, format = 'DD.MM.YYYY HH:mm:ss') {
  return moment(date).format(format);
}

const timezone = new Date().getTimezoneOffset();
let headers = {
  language: getUserLanguage().substr(0, 2).toUpperCase(),
  Product: 'botadmin',
  Timezone: timezone,
};

let axiosWithSessionCheck = axios.create({
  headers,
  validateStatus: status => {
    if (status === 401) {
      reduxStateReference.getStore().dispatch(dropCurrentUser());
      reduxStateReference.getStore().dispatch(dropCurrentLoginAccount());

      if (!isDev()) window.location.href = `/c/login?redirectUrl=${window.location.href}`;

      return false;
    } else if (status === 403) {
      history.push({
        pathname: '/pages/403',
      });
      return false;
    } else if (status === 503) {
      history.push({
        pathname: '/503',
      });
    }
    return status >= 100 && status < 300;
  },
});

if (isDev() && process.env.REACT_APP_USE_REFERER !== '') {
  try {
    axiosWithSessionCheck.defaults.headers.referrer = process.env.REACT_APP_USE_REFERER;
    axios.defaults.headers.referrer = process.env.REACT_APP_USE_REFERER;
    //@ts-ignore
    headers.referrer = process.env.REACT_APP_USE_REFERER;
  } catch (e) {
    console.error(e);
  }
}

axiosWithSessionCheck.interceptors.request.use(request => {
  let modifiedRequest = { ...request };
  modifiedRequest.url = modifiedRequest.url.replace(/\.\.\//g, '');
  return modifiedRequest;
});

axiosWithSessionCheck.interceptors.response.use(
  response => {
    const { data, config } = response;
    if (typeof data === 'string' && data.endsWith('</html>')) {
      console.log(
        '%cBACKEND / NGINX ERROR!',
        'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: var(--gray-950) 1px 1px;'
      );
      console.log(
        `%crequest ${config.method.toUpperCase()} to ${config.url} returns html`,
        'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: var(--gray-950) 1px 1px;'
      );
      return Promise.reject(
        `BACKEND/NGINX ERROR: request ${config.method.toUpperCase()} to ${config.url} returns html`
      );
    }
    return response;
  },
  error => {
    // ошибка 403 account.access.denied случается, если юзера удаляют из группы проектов
    // при этом у него остается кука, с которой все запросы будут падать и редиректить на 403
    // таким образом без очистки куки юзер будет всегда получать 403
    // поэтому при появлении 403 с данным кодом удаляем куку и редиректим на главную с перезагрузкой страницы
    if (error?.response?.data?.error === 'account.access.denied') {
      cookies.remove('SELECTED_ACCOUNT', { path: '/' });
      window.location.pathname = '/';
    }
    throw error;
  }
);

axiosWithSessionCheck._get = (url, options) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck
      .get(url, options)
      .then(response => {
        response.config = { ...response.config, ...options };
        resolve(response);
      })
      .catch(reject);
  });
};
const postPutDeleteInterceptor = method => (url, data, config) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck[method](url, data, config)
      .then(response => {
        response.config = { ...response.config, ...config };
        resolve(response);
      })
      .catch(reject);
  });
};
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');

export function updateLanguage(language) {
  const MOMENT_LOCALE_MAP = {
    ru: 'ru',
    eng: 'en',
    cn: 'en-gb',
  };

  setTimeout(() => moment.locale(MOMENT_LOCALE_MAP[language] || 'en'), 0);
  setAxiosHeadersLanguage(language.substr(0, 2).toUpperCase());
}

export function setAxiosHeadersLanguage(val) {
  axiosWithSessionCheck.defaults.headers.language = val;
}

export { axiosWithSessionCheck as axios };

export const getFilterFromLocation = (search = '', array = analyticsArrayFilter) => {
  let parsedLocationSearch = queryString.parse(search);
  let filterParams = pick(parsedLocationSearch, array);

  if (filterParams.hasOwnProperty('dateFrom')) {
    filterParams.dateFrom = moment(filterParams.dateFrom, 'x');
  }

  if (filterParams.hasOwnProperty('dateTo')) {
    filterParams.dateTo = moment(filterParams.dateTo, 'x');
  }

  return filterParams;
};

export const parseErrors = response => {
  let errors;
  //todo check response status, don't save error with 401 status
  if (response && response.status !== 401) {
    if (response.data.hasOwnProperty('errors')) {
      errors = response.data.errors;
    } else if (response.data.hasOwnProperty('error')) {
      errors = [response.data.error];
    } else if (isArray(response.data)) {
      errors = response.data;
    } else {
      errors = [response.data];
    }
  } else {
    errors = [];
  }

  return errors;
};

export const getCurrentDate = () => {
  let defaultDateFrom = new Date();
  let defaultDateTo = new Date();
  defaultDateFrom.setDate(defaultDateFrom.getDate() - 1);
  defaultDateFrom = moment(defaultDateFrom, 'DD.MM.YYYY HH:mm:ss');
  defaultDateTo = moment(defaultDateTo, 'DD.MM.YYYY HH:mm:ss');
  return [defaultDateFrom, defaultDateTo];
};

export const setDefaultTimezone = str => {
  moment.tz.setDefault(str);
  axiosWithSessionCheck.defaults.headers.Timezone = -moment().utcOffset();
};

export const amplitudeInstance = (function () {
  if (getAmplitudeKey()) {
    return amplitude.getInstance();
  }
  return {
    logEvent: (event, data, callback) => {
      if (isDev()) console.log('%c Amplitude log:', 'color: green;', event, data);
      if (typeof callback === 'function') callback();
    },
    setUserId: () => {},
    setUserProperties: () => {},
  };
})();

export function getCountryList(lang, isEuroInstance) {
  const mainList = [];
  const sngList = {};
  Object.keys(countries).forEach(countryKey => {
    const { name, phone } = countries[countryKey];
    const localizeCountryName = lang === 'ru' ? russianCountriesName.countries[countryKey] : name;
    let phoneCode = phone.split(',')[0];
    if (countryKey === 'KZ') {
      phoneCode = '7';
    }
    const value = [countryKey, localizeCountryName, localizeCountryName.toLowerCase(), phoneCode];

    if (['KZ', 'RU', 'UA', 'BY'].includes(countryKey) && !isEuroInstance) {
      sngList[countryKey] = value;
    } else {
      mainList.push(value);
    }
  });

  return isEuroInstance
    ? [...mainList.sort((a, b) => (a[1] > b[1] ? 1 : -1))]
    : [sngList['RU'], sngList['KZ'], sngList['UA'], sngList['BY'], ...mainList.sort((a, b) => (a[1] > b[1] ? 1 : -1))];
}

export const saveAudioToServer = async (file, { signal }) => {
  const fd = new FormData();
  fd.append('files', file);

  const payload = await axiosWithSessionCheck.post('/restapi/file/upload/audios', fd, { signal });
  const payloadAudios = payload.data;
  const key = Object.keys(payloadAudios)[0];
  return payloadAudios[key];
};

export const saveFileToServer = async (file, { signal }) => {
  const fd = new FormData();
  fd.append('file', file);

  const payload = await axiosWithSessionCheck.post('/restapi/file/upload', fd, { signal });
  const payloadUrl = payload.data;
  return payloadUrl;
};
