import React, { useEffect, useCallback } from 'react';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { Placement } from '@floating-ui/react-dom';
import { useToggle, useDebounceFn, useId } from '@just-ai/just-ui';

import { KonvaTooltipArea$ } from 'modules/JGraph/components/KonvaTooltipArea';
import { Vector2D } from 'modules/JGraph/utils/2DVector';

interface KonvaTooltipProps {
  target: Node | undefined | null;
  children: React.ReactNode;
  isOpen?: boolean;
  closeOnScaleDisable?: boolean;
  trigger?: 'click';
  mainAxisOffset?: number;
  crossAxisOffset?: number;
  alignmentAxisOffset?: number | null;
  placement?: Placement;
}
const KonvaTooltip = ({
  target,
  children,
  isOpen,
  closeOnScaleDisable,
  trigger,
  crossAxisOffset,
  mainAxisOffset,
  alignmentAxisOffset,
  placement,
}: KonvaTooltipProps) => {
  const tooltipId = useId();
  const [tooltipOpened, showTooltip, closeTooltip] = useToggle(false);

  const open = useCallback(() => {
    if (!target) return;
    const stage = target._getStage();
    if (!stage) return;

    showTooltip();
    const attrs = target.getClientRect();

    const jgraphPosition = document.getElementById('jgraph-wrapper')!.getBoundingClientRect();
    const screenPos = Vector2D.fromObj(attrs).add(jgraphPosition);

    KonvaTooltipArea$.next({
      id: tooltipId,
      content: children,
      target: {
        x: screenPos.x,
        y: screenPos.y,
        width: attrs.width,
        height: attrs.height,
      },
      crossAxisOffset,
      mainAxisOffset,
      alignmentAxisOffset,
      placement,
    });
  }, [alignmentAxisOffset, children, crossAxisOffset, mainAxisOffset, placement, showTooltip, target, tooltipId]);

  const hideTooltip = useCallback(() => {
    const val = KonvaTooltipArea$.getValue();
    if (val?.id !== tooltipId) return;
    KonvaTooltipArea$.next(null);
  }, [tooltipId]);

  const close = useCallback(() => {
    closeTooltip();
    hideTooltip();
  }, [closeTooltip, hideTooltip]);

  const debouncedOpen = useDebounceFn(open, 300);

  const absoluteTransformChange = useCallback(() => {
    if (!tooltipOpened) return;
    if (!closeOnScaleDisable) {
      close();
      return;
    }
    hideTooltip();
    debouncedOpen();
  }, [close, closeOnScaleDisable, debouncedOpen, hideTooltip, tooltipOpened]);

  useEffect(() => {
    if (!target) return;
    target.on('absoluteTransformChange', absoluteTransformChange);
    return () => {
      target.off('absoluteTransformChange', absoluteTransformChange);
    };
  }, [absoluteTransformChange, target]);

  useEffect(() => {
    if (!target || trigger !== 'click') return;
    const onClick = (e: KonvaEventObject<MouseEvent>) => {
      e.cancelBubble = true;
      open();
    };
    target.on('click', onClick);
    return () => {
      target.off('click', onClick);
    };
  }, [open, target, trigger]);

  useEffect(() => {
    if (!target || trigger !== undefined) return;
    isOpen ? open() : close();
  }, [open, target, trigger, isOpen, close]);

  return null;
};

export default React.memo(KonvaTooltip);
