import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Announcement } from '@just-ai/just-ui';
import { NavLink } from 'react-router-dom';
import localize, { t } from '../../localization';
import isAccess from '../../isAccessFunction';
import SalesRequestModal from '../SalesRequestModal';
import { axios, isDev } from '../../pipes/functions';
import { redirectToPaymentSystem } from '../../pipes/paymentFunction';
import history from 'appHistory';
import { packageNameByAnnouncementType } from './packageNameByAnnouncementType';
import { tarifflimitannouncementLocalization } from './localization/tarifflimitannouncement.loc';

localize.addTranslations(tarifflimitannouncementLocalization);

type TariffLimitsStatusProps = { [key: string]: { [key: string]: boolean | number | null | any } };

interface TariffLimitAnnouncementProps {
  tariffLimitsStatus?: TariffLimitsStatusProps;
  tariffUniqueName: string;
  addMessage: any;
}

const TariffLimitAnnouncement = ({ tariffUniqueName, addMessage }: TariffLimitAnnouncementProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [tariffLimitsStatus, setTariffLimitsStatus] = useState<TariffLimitsStatusProps>({});

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // let timer: NodeJS.Timeout | null = null;

  const getTariffLimitsStatus = useCallback(() => axios.get(`/restapi/billing/tariff/stretching`), []);

  const getTariffLimitsStatusByInterval = useCallback(() => {
    getTariffLimitsStatus().then(payload => {
      setTariffLimitsStatus(payload.data);
      timerRef.current = setTimeout(() => {
        if (!isDev()) getTariffLimitsStatusByInterval();
      }, 5000);
    });
  }, [getTariffLimitsStatus]);

  useLayoutEffect(() => {
    setTariffLimitsStatus({});
    getTariffLimitsStatusByInterval();
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [getTariffLimitsStatusByInterval, tariffUniqueName]);

  let priorityStatus: string = '';
  let type = 'yellow';
  Object.keys(tariffLimitsStatus).forEach(color => {
    const findStatus: string | undefined = Object.keys(tariffLimitsStatus[color]).find(
      status => tariffLimitsStatus[color][status]
    );
    if (findStatus) {
      priorityStatus = findStatus;
      type = color;
    }
  });

  const announcementCallback = useCallback(async () => {
    const packageToBuy = packageNameByAnnouncementType[priorityStatus];

    if (tariffUniqueName?.includes('jacp_developer') && packageToBuy) {
      try {
        const { data } = await axios.post(`/restapi/billing/additionalPocket/pay`, {
          uniqueNames: [packageToBuy],
          redirectUrl: window.location.origin + '/accountmanager',
        });

        if (data.changed && !data.paymentId && !data.redirectUrl) {
          if (data.changed) {
            history.push('/accountmanager');
          }
        } else {
          redirectToPaymentSystem(data);
        }
      } catch (e) {
        const error = (e as any)?.response?.data?.error?.errorCode;
        if (error) {
          addMessage({
            showed: true,
            type: 'error',
            message: t(`TariffLimitAnnouncement:BE-error ${error}`),
            time: Date.now(),
          });
        }
      }
    } else {
      setOpenModal(true);
    }
  }, [priorityStatus, tariffUniqueName, addMessage]);

  const getAnnouncementButtonText = useCallback(() => {
    const packageToBuy = packageNameByAnnouncementType[priorityStatus];
    return tariffUniqueName?.includes('jacp_developer') && packageToBuy
      ? t('TariffLimitAnnouncement btn text buy')
      : t('TariffLimitAnnouncement btn text request');
  }, [priorityStatus, tariffUniqueName]);

  const getPersonalPageLink = useCallback(() => {
    const packageToBuy = packageNameByAnnouncementType[priorityStatus];
    return !(tariffUniqueName?.includes('jacp_developer') && packageToBuy) &&
      (isAccess(['SPECIFIC_ACCOUNT_MANAGEMENT']) || isAccess(['ACCOUNTS_TARIFF_READ'])) ? (
      <>
        {' ' + t('TariffLimitAnnouncement in') + ' '}
        <NavLink to='/accountmanager'>{t('Account page link text')}</NavLink>
      </>
    ) : (
      '.'
    );
  }, [priorityStatus, tariffUniqueName]);

  let announcementText = Boolean(priorityStatus)
    ? () => (
        <>
          {/* @ts-ignore */}
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                `TariffLimitAnnouncement ${
                  priorityStatus +
                  (priorityStatus === 'asrMinutesExceed' && tariffUniqueName?.includes('jacp_developer')
                    ? '_developer'
                    : '')
                }`,
                tariffLimitsStatus[type]?.[priorityStatus]?.['days'] &&
                  tariffLimitsStatus[type]?.[priorityStatus]?.['days'] +
                    ' ' +
                    localize.decliner(
                      [t('1 day'), t('2 days'), t('5 days')],
                      tariffLimitsStatus[type]?.[priorityStatus]?.['days'] &&
                        parseInt(tariffLimitsStatus[type]?.[priorityStatus]?.['days'], 10)
                    )
              ),
            }}
          />
          {getPersonalPageLink()}
        </>
      )
    : '';

  return announcementText ? (
    <>
      <Announcement
        AnnouncementText={announcementText}
        type={type === 'red' ? 'danger' : 'warning'}
        action={{ callback: announcementCallback, text: getAnnouncementButtonText() }}
      />
      {openModal ? <SalesRequestModal type={priorityStatus} onClose={() => setOpenModal(false)} /> : null}
    </>
  ) : null;
};

export default TariffLimitAnnouncement;
