import { LabelComponentSelectState } from '../../../../../../../components/NewCommonActivationsParams/LabelComponentForSelectAndCreate';
import { JustSelect } from '@just-ai/just-ui';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { t } from 'localization';
import { backToNewCailaIntentsCreation } from '../index';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { useJGraphContext } from '../../../../../../../contexts/JGraphContext';
import { BehaviorSubject } from 'rxjs';
import keyboardJS from 'keyboardjs';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';

export const shouldGoDetail$ = new BehaviorSubject(false);

export const NewSelectIntent: FC<{
  onSelectIntent: (value: string, shouldCreateNew?: boolean) => Promise<void>;
  intent?: string;
  inlineCreation?: boolean;
}> = ({ onSelectIntent, inlineCreation = false, intent }) => {
  const [inputValue, setInputValue] = useState('');
  const [isSelectOpen, setSelectOpen] = useState(false);
  const { IntentsService, currentBotLanguage, SettingsService } = useJGraphContext();
  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);

  const existIntents = useMemo(() => {
    return IntentsService.intents.map(intent => ({
      value: intent.path || '',
      label: hideRootSlashInPath(intent.path || ''),
    }));
  }, [IntentsService.intents]);

  const intentsOptions = useMemo(() => {
    const hardCodeIntents = IntentsService.SystemIntents[currentBotLanguage].map(value => ({
      label: t(`ChooseReadyIntent ${value}`),
      value,
    }));
    return [...hardCodeIntents, ...existIntents];
  }, [IntentsService.SystemIntents, currentBotLanguage, existIntents]);

  useEffect(() => {
    setSelectOptions(intentsOptions);
  }, [intentsOptions]);

  const searchValue = useCallback(
    inputValue => {
      let pureValue = inputValue.trim();
      if (pureValue.startsWith('/')) {
        pureValue = pureValue.substring(1);
      }
      const lowerPureValue = pureValue.toLowerCase();

      const option =
        lowerPureValue.length >= 3 &&
        intentsOptions.find(
          option =>
            option.value.toLowerCase().includes(lowerPureValue) || option.label.toLowerCase().includes(lowerPureValue)
        );
      setSelectOpen(!!option);

      let newOptions = selectOptions.filter(option => option.value !== 'new_value');

      if (inlineCreation) {
        const match = selectOptions.filter(
          option =>
            (option.label as string).toLowerCase() === lowerPureValue ||
            (option.label as string).toLowerCase() === `/${lowerPureValue}`
        );

        if (pureValue && match.length === 0) {
          newOptions.splice(0, 0, {
            label: pureValue,
            value: 'new_value',
          });
          setSelectOpen(true);
        }
      }

      setSelectOptions(newOptions);
      setInputValue(pureValue);
    },
    [inlineCreation, intentsOptions, selectOptions]
  );

  const onChooseIntent = useCallback(
    async (value: (string | number)[] | null) => {
      setSelectOpen(false);
      if (value && value[0]) {
        if (value[0] === 'new_value') {
          await onSelectIntent(inputValue, true);
        } else {
          if (
            IntentsService.isSystemIntent(currentBotLanguage, String(value[0])) &&
            SettingsService.projectSettingsData &&
            (!SettingsService.projectSettingsData.extendedSettings?.useShared ||
              !SettingsService.projectSettingsData.extendedSettings?.zflPatternsEnabled)
          ) {
            const systemIntentToCreate = String(value[0]);
            await SettingsService.setProjectSettings(
              {
                extendedSettings: {
                  zflPatternsEnabled: true,
                  useShared: true,
                },
              },
              true
            );
            await onSelectIntent(systemIntentToCreate);
            backToNewCailaIntentsCreation();
            return;
          }
          await onSelectIntent(String(value[0]));
        }
        if (!shouldGoDetail$.getValue()) {
          backToNewCailaIntentsCreation();
        }
        shouldGoDetail$.next(false);
      }
    },
    [onSelectIntent, inputValue, IntentsService, currentBotLanguage, SettingsService]
  );

  const createIntentAndGoDetailHandler = useCallback(async e => {
    e.stopPropagation();
    shouldGoDetail$.next(true);
  }, []);

  useEffect(() => {
    keyboardJS.bind(['ctrl + enter', 'cmd + enter'], createIntentAndGoDetailHandler);

    return () => {
      keyboardJS.unbind(['ctrl + enter', 'cmd + enter'], createIntentAndGoDetailHandler);
    };
  }, [createIntentAndGoDetailHandler]);
  return (
    <JustSelect
      autoFocus
      iconArrowShow={false}
      open={isSelectOpen}
      data-test-id='IntentActivation:SelectIntent'
      inputPlaceholder={t('JGraphCailaIntentChooseCreation:EnterPhrasePlaceholder')}
      fullWidth
      onInput={searchValue}
      options={selectOptions}
      onChange={onChooseIntent}
      position='fixed'
      optionComponentProps={{
        rightSideText: t('NewSelectIntent:create intent'),
      }}
      optionComponent={LabelComponentSelectState}
      value={intent}
    />
  );
};
