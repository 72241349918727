import React from 'react';
import cn from 'classnames';
import { ButtonGroup } from 'reactstrap';
import { Button } from '@just-ai/just-ui/dist/Button';
import { Spinner } from '@just-ai/just-ui/dist/Spinner';
import { DropdownButton, DropdownToggle, DropdownMenu, DropdownItem } from '@just-ai/just-ui/dist/Dropdown';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { useNLUModuleContext } from '../../../../context';
import classes from './styles.module.scss';
import { useTranslation } from '@just-ai/just-ui';
export default function ApplyChangesButton(_a) {
    var needsTraining = _a.needsTraining;
    var _b = useNLUModuleContext(), showNluWidget = _b.showNluWidget, trainNlu = _b.trainNlu, trainingNlu = _b.trainingNlu;
    var t = useTranslation().t;
    var buttonText = t(trainingNlu ? 'FAQ:Train:ApplyingChanges' : 'FAQ:Train:ApplyTest');
    if (!trainNlu)
        return null;
    return (React.createElement("div", { className: cn('faqtemplate__apply-changes', classes.faqTemplatesTabs__applyBtn) },
        React.createElement(ButtonGroup, { className: 'combo-btn', "aria-disabled": trainingNlu },
            React.createElement(Button, { size: 'sm', color: 'primary', disabled: trainingNlu, outline: !needsTraining, onClick: function () { return trainNlu(true); } },
                trainingNlu && React.createElement(Spinner, { size: 'sm', color: 'primary', style: { position: 'static' } }),
                buttonText),
            React.createElement(DropdownButton, { direction: 'down', className: cn({ outline: !needsTraining }), tabIndex: -1, size: 'sm', disabled: trainingNlu },
                React.createElement(DropdownToggle, { color: 'primary', iconButton: true, size: 'sm', disabled: trainingNlu, outline: !needsTraining },
                    React.createElement(Icon, { className: 'btn-icon', name: 'faCaretDown', size: 'sm' })),
                React.createElement(DropdownMenu, null,
                    React.createElement(DropdownItem, { onClick: function () { return trainNlu(); } }, t('FAQ:Train:ApplyChanges')),
                    React.createElement(DropdownItem, { onClick: showNluWidget },
                        " ",
                        t('FAQ:Train:OpenWidget')))))));
}
