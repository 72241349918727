var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { FileUpload, Icon, Modal, useTranslation } from '@just-ai/just-ui';
import classNames from 'classnames';
import { MAX_FILE_SIZE_MB } from '../../../constants/faqTemplates';
import { getErrorArgsFromReason, getMessageTranslationFromImportError } from '../../../nlu-related/utils';
import { useNLUModuleContext } from '../../../context';
import FileImportError from './FileImportError';
import FakeProgressBar from './FakeProgressBar';
import styles from './style.module.scss';
import './style.scss';
export default function FileImportModal(props) {
    var _a;
    var _this = this;
    var isOpen = props.isOpen, fromFaq = props.fromFaq, onSubmit = props.onSubmit, toggle = props.toggle;
    var t = useTranslation().t;
    var _b = useNLUModuleContext(), currentProject = _b.currentProject, wrappedHistoryPush = _b.wrappedHistoryPush;
    var _c = useState({ type: 'allowable', text: '' }), error = _c[0], setError = _c[1];
    var _d = useState(), file = _d[0], setFile = _d[1];
    var _e = useState(false), fileLoading = _e[0], setFileLoading = _e[1];
    var _f = useState(false), requestDone = _f[0], setRequestDone = _f[1];
    var progressRenderTimeout = useRef();
    useEffect(function () {
        return function () {
            clearTimeout(progressRenderTimeout.current);
        };
    }, []);
    useEffect(function () {
        setError({ text: '' });
        setFile(undefined);
        setFileLoading(false);
        setRequestDone(false);
    }, [isOpen]);
    var uploadFile = function (fileList) {
        if (fileLoading)
            return;
        var localFile = fileList[0];
        setFile(localFile);
        setError({ text: '' });
        if (localFile.size > MAX_FILE_SIZE_MB) {
            return setError({ text: t('FAQ:importModal:FileUpload:maxSize'), type: 'local' });
        }
        if (localFile.type !== 'application/vnd.ms-excel' &&
            localFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
            !localFile.name.split('.')[localFile.name.split('.').length - 1].includes('xls')) {
            return setError({ text: t('FAQ:importModal:FileUpload:formats:error'), type: 'local' });
        }
    };
    var uploadTemplate = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var faqName_1, reqError_1, errorArgs, errrorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (error.text && error.type === 'local')
                        return [2 /*return*/];
                    if (!file)
                        return [2 /*return*/, setError({ type: 'local', text: t('FAQ:importModal:FileUpload:upload') })];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setRequestDone(false);
                    setFileLoading(true);
                    return [4 /*yield*/, onSubmit(file, error)];
                case 2:
                    faqName_1 = _a.sent();
                    setRequestDone(true);
                    progressRenderTimeout.current = setTimeout(function () {
                        if (error.type === 'allowable' || !error.text) {
                            faqName_1 &&
                                wrappedHistoryPush({
                                    url: 'faq',
                                    historyState: { templateTitle: faqName_1 },
                                });
                            toggle();
                        }
                    }, 200);
                    return [3 /*break*/, 4];
                case 3:
                    reqError_1 = _a.sent();
                    errorArgs = getErrorArgsFromReason(reqError_1);
                    errrorMessage = getMessageTranslationFromImportError(reqError_1);
                    setError({
                        text: errrorMessage,
                        type: (errorArgs === null || errorArgs === void 0 ? void 0 : errorArgs.critical) ? 'critical' : 'allowable',
                    });
                    setRequestDone(true);
                    progressRenderTimeout.current = setTimeout(function () {
                        setFileLoading(false);
                    }, 200);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [error, file, onSubmit, wrappedHistoryPush, toggle]);
    var handleRedoClick = function (event) {
        event && event.stopPropagation();
        setFile(undefined);
        setError({ text: '' });
        var fileUploadWrapper = document.getElementById('fileUploadWrapper');
        fileUploadWrapper && fileUploadWrapper.click();
    };
    var handleTrashClick = function (event) {
        event.stopPropagation();
        setFile(undefined);
        setError({ text: '' });
    };
    var templateName = useMemo(function () {
        var _a;
        var isRu = ((_a = currentProject === null || currentProject === void 0 ? void 0 : currentProject.language) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'ru' || false;
        return isRu ? 'template-ru' : 'template-en';
    }, [currentProject]);
    var modalTitle = useCallback(function () {
        if (fromFaq)
            return t('FAQ:importModal:Title:ImportFAQ');
        if (fileLoading)
            return t('FAQ:importModal:Title:process');
        return t('FAQ:importModal:Title:ImportFile');
    }, [fromFaq, fileLoading]);
    return (React.createElement(Modal, { className: styles.faqImportModal, isOpen: isOpen, buttonCancelText: file && error.text && error.type !== 'local'
            ? t('FAQ:importModal:button:replaceFile')
            : t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: file && error.text && error.type !== 'local'
            ? t('FAQ:importModal:button:toSkill')
            : t('ImportModal:actionButton'), onClickCloseIcon: toggle, onCancelClick: file && error.text && error.type !== 'local' ? handleRedoClick : toggle, onActionClick: uploadTemplate, buttonSubmitDisabled: error.type === 'critical', title: modalTitle(), centered: true, disableActionButtonAutoFocus: true },
        fileLoading ? (React.createElement("p", { className: 'margin-bottom-2x font-color-normal' }, t('FAQ:importModal:process:pending'))) : (React.createElement(React.Fragment, null,
            !fromFaq && React.createElement("p", { className: 'margin-bottom-2x font-color-normal' }, t('FAQ:importModal:ImportInfo')),
            React.createElement("p", { className: 'margin-bottom-4x font-color-normal' },
                t('FAQ:importModal:ImportExampleInfo'),
                React.createElement("a", { href: "".concat(window.origin, "/cailapub/static/").concat(templateName, ".xlsx"), className: 'no-underline' },
                    ' ',
                    t('FAQ:importModal:ImportExampleInfo:link'))))),
        fromFaq && (React.createElement("div", { className: styles.faqImportModal__infoBlock },
            React.createElement(Icon, { name: 'faInfoCircle', color: 'info' }),
            React.createElement("p", null, t('FAQ:importModal:infoBlock')))),
        React.createElement(FileUpload, { className: classNames(styles.faqImportModal__fileUploadContainer, 'FAQ__file-upload', (_a = {},
                _a["".concat(styles.faqImportModal__fileUploadContainer__progress)] = fileLoading || (error.text && error.type !== 'local'),
                _a)), onChange: uploadFile, accept: '.xls,.xlsx', iconSize: '4x', id: 'fileUploadWrapper' }, file ? (fileLoading || (error.text && error.type !== 'local') ? (React.createElement("div", { className: styles.faqImportModal__fileStatusBlock },
            React.createElement("div", { style: { flex: 1 } },
                React.createElement("p", { style: { maxWidth: '330px' }, className: 'font-bold' }, file.name),
                React.createElement(FakeProgressBar, { done: requestDone })),
            React.createElement("div", null,
                React.createElement(Icon, { onClick: handleTrashClick, size: 'sm', name: 'farTimes', color: 'secondary' })))) : (React.createElement("div", { className: styles.faqImportModal__fileStatusBlock },
            React.createElement("div", null, error.text && error.type === 'local' ? (React.createElement(Icon, { name: 'farTimesCircle', size: 'lg', color: 'danger' })) : (React.createElement(Icon, { name: 'farCheckCircle', size: 'lg', color: 'success' }))),
            React.createElement("div", { style: { maxWidth: '330px' } },
                React.createElement("span", { className: 'font-color-light-gray font-size-12' }, t(error.text && error.type === 'local' ? 'FAQ:importModal:error' : 'FAQ:importModal:success')),
                React.createElement("p", { className: 'font-bold' }, file.name)),
            React.createElement("div", null,
                error.text && error.type === 'local' && (React.createElement(Icon, { name: 'farUndoAlt', onClick: handleRedoClick, color: 'secondary' })),
                React.createElement(Icon, { onClick: handleTrashClick, name: 'farTrashAlt', color: 'secondary' }))))) : (React.createElement(React.Fragment, null,
            React.createElement("p", { className: 'font-color-normal mb-3' },
                React.createElement("a", { href: '#!', onClick: function (e) { return e.preventDefault(); } }, t('FAQ:importModal:FileUpload:upload')),
                ' ',
                t('or drop it here')),
            React.createElement("span", { className: 'font-color-light-gray font-size-12' }, t('FAQ:importModal:FileUpload:formats')),
            React.createElement("span", { className: 'font-color-light-gray font-size-12' }, t('FAQ:importModal:FileUpload:maxRows'))))),
        React.createElement("div", { className: styles.faqImportModal__fileErrorBlock }, error.text && React.createElement(FileImportError, { error: error, fileName: file === null || file === void 0 ? void 0 : file.name }))));
}
