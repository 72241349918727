import React, { useState, useEffect, useCallback } from 'react';
import { useToggle, Modal } from '@just-ai/just-ui';

import { t } from 'localization';

import useService from 'utils/hooks/useSevice';
import { LocalStorageService } from 'services/Storage/LocalStorageService';

type SkipStatusType = 'skippedOnce' | 'skippedForever' | 'none';
const GUIDE_TOUR_SKIP_STATUS_KEY = 'GUIDE_TOUR_SKIP_STATUS_KEY';

export function useSkipGuideTour() {
  const [skipStatus, setSkipStatus] = useState<SkipStatusType | null>(null);
  const [isShowSkipForeverModal, openSkipForeverModal, closeSkipForeverModal] = useToggle(false);
  const localStorageService = useService(LocalStorageService);

  useEffect(() => {
    localStorageService.get<SkipStatusType>(GUIDE_TOUR_SKIP_STATUS_KEY).then(status => {
      setSkipStatus(status.success && status.payload ? status.payload : 'none');
    });
  }, [localStorageService]);

  const skip = useCallback(() => {
    if (!skipStatus || skipStatus === 'none') {
      localStorageService.set(GUIDE_TOUR_SKIP_STATUS_KEY, 'skippedOnce', true);
    }
    if (skipStatus === 'skippedOnce') {
      openSkipForeverModal();
    }
    return;
  }, [localStorageService, openSkipForeverModal, skipStatus]);

  const skipForever = useCallback(() => {
    localStorageService.set(GUIDE_TOUR_SKIP_STATUS_KEY, 'skippedForever', true);
    setSkipStatus('skippedForever');
    closeSkipForeverModal();
  }, [closeSkipForeverModal, localStorageService]);

  return {
    skipStatus,
    skip,
    skipForever,
    isShowSkipForeverModal,
    closeSkipForeverModal,
  };
}

interface ISkipGuideTourForeverModal {
  isModalOpen: boolean;
  onAgree: () => void;
  onDisagree: () => void;
}
export const SkipGuideTourForeverModal = ({ isModalOpen, onAgree, onDisagree }: ISkipGuideTourForeverModal) => (
  <Modal
    isOpen={isModalOpen}
    title={t('JGraphGuideTour:SkipForeverModal:Title')}
    buttonSubmitText={t('JGraphGuideTour:SkipForeverModal:SubmitText')}
    onActionClick={onAgree}
    buttonSubmitColor='primary'
    buttonCancelText={t('JGraphGuideTour:SkipForeverModal:CancelText')}
    onCancelClick={onDisagree}
    buttonCancelColor='secondary'
    buttonCancelOutline
  />
);
