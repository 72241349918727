import { JBlock } from '../../Editor/api/client';
import { CustomTagsStore$ } from '../../../reducers/JGraph.reducer/customTags.store';
import { JStateWithId } from 'reducers/JGraph.reducer/types';

export enum TagNames {
  'q' = 'q',
  'q_' = 'q!',
  'event' = 'event',
  'event_' = 'event!',
  'intent' = 'intent',
  'intent_' = 'intent!',
  'intentGroup' = 'intentGroup',
  'intentGroup_' = 'intentGroup!',
  'e' = 'e',
  'e_' = 'e!',
  'eg' = 'eg',
  'eg_' = 'eg!',
  'audio' = 'audio',
  'a' = 'a',
  'random' = 'random',
  'buttons' = 'buttons',
  'inlineButtons' = 'inlineButtons',
  'image' = 'image',
  'video' = 'video',
  'script' = 'script',
  'if' = 'if',
  'else' = 'else',
  'elseif' = 'elseif',
  'go' = 'go',
  'go_' = 'go!',

  'Email' = 'Email',
  'HttpRequest' = 'HttpRequest',
  'InputNumber' = 'InputNumber',
}

export type TReactionsTagNames =
  | TagNames.a
  | TagNames.audio
  | TagNames.random
  | TagNames.buttons
  | TagNames.inlineButtons
  | TagNames.image
  | TagNames.video
  | TagNames.script
  | TagNames.go
  | TagNames.go_
  | TagNames.if
  | TagNames.Email
  | TagNames.HttpRequest;

export type TActivationTagNames =
  | TagNames.q
  | TagNames.q_
  | TagNames.event
  | TagNames.event_
  | TagNames.intent
  | TagNames.intentGroup
  | TagNames.intentGroup_
  | TagNames.intent_
  | TagNames.e
  | TagNames.e_
  | TagNames.eg
  | TagNames.eg_;

export const ReactionsTagNames = (): TReactionsTagNames[] => [
  TagNames.audio,
  TagNames.a,
  TagNames.random,
  TagNames.buttons,
  TagNames.inlineButtons,
  TagNames.image,
  TagNames.video,
  TagNames.script,
  TagNames.go,
  TagNames.go_,
  TagNames.if,
  ...(CustomTagsStore$.getValue().map(tag => tag.tagName) as TReactionsTagNames[]),
];

export const ReactionsTagNamesWithElseif = [...ReactionsTagNames(), TagNames.else, TagNames.elseif];
const fullModeActivations: TActivationTagNames[] = [
  TagNames.q,
  TagNames.q_,
  TagNames.event,
  TagNames.event_,
  TagNames.intent,
  TagNames.intentGroup,
  TagNames.intentGroup_,
  TagNames.intent_,
  TagNames.e,
  TagNames.e_,
  TagNames.eg,
  TagNames.eg_,
];
const liteModeActivations: TActivationTagNames[] = [
  TagNames.event,
  TagNames.event_,
  TagNames.intent,
  TagNames.intent_,
  TagNames.intentGroup,
  TagNames.intentGroup_,
  TagNames.e,
  TagNames.e_,
  TagNames.eg,
  TagNames.eg_,
];
export const getActivationsTagNames = (isLiteMode = false): TagNames[] =>
  isLiteMode ? liteModeActivations : fullModeActivations;

export type MetaTypes = TagNames.Email | TagNames.HttpRequest;

/*type MapTTagNameToValue = {
  htmlEnabled: boolean;
  ttsEnabled: boolean;
}

export type GetTTagParameterValue<ValueType extends string = string> = ValueType extends keyof MapTagParamOnTagName
  ? MapTTagNameToValue[ValueType]
  : string;*/

export interface TTagParameters<NameType extends string = string, ValueType = string> extends Object {
  name: NameType;
  required: boolean;
  jsonObjects?: string | null;
  value?: ValueType | null;
  [key: string]: any;
}

export interface TButtonParameters extends TTagParameters {
  transition?: string | null;
  url?: string | null;
}

export type TActivationNames = 'fromState' | 'toState' | 'onlyThisState';
export type TActivationParameters = (
  | TTagParameters<'fromState'>
  | TTagParameters<'onlyThisState', boolean>
  | TTagParameters<'toState'>
)[];

export type MapTagParamOnTagName = {
  [TagNames.q]: TActivationParameters;
  [TagNames.event]: TActivationParameters;
  [TagNames.eg]: TActivationParameters;
  [TagNames.intent]: TActivationParameters;
  [TagNames.e]: TActivationParameters;

  [TagNames.a]: (
    | TTagParameters<'html'>
    | TTagParameters<'htmlEnabled', boolean>
    | TTagParameters<'tts'>
    | TTagParameters<'ttsEnabled', boolean>
  )[];
  [TagNames.buttons]: TButtonParameters[];
  [TagNames.inlineButtons]: TButtonParameters[];
  [TagNames.Email]: (
    | TTagParameters<'destination'>
    | TTagParameters<'subject'>
    | TTagParameters<'text'>
    | TTagParameters<'files'>
    | TTagParameters<'html'>
    | TTagParameters<'htmlEnabled', boolean>
    | TTagParameters<'okState'>
    | TTagParameters<'errorState'>
  )[];
  [TagNames.HttpRequest]: (
    | TTagParameters<'url'>
    | TTagParameters<'method'>
    | TTagParameters<'dataType'>
    | TTagParameters<'body'>
    | TTagParameters<'okState'>
    | TTagParameters<'errorState'>
    | TTagParameters<'timeout'>
    | TTagParameters<'headers', { name: string; value: string }[]>
    | TTagParameters<'vars', { name: string; value: string }[]>
  )[];
};

export type GetTagParametersType<TagName> = TagName extends keyof MapTagParamOnTagName
  ? MapTagParamOnTagName[TagName]
  : TTagParameters[];

export interface TJBlock<TagName = TagNames> extends Omit<JBlock, 'tagName' | 'tagParameters' | 'jblocks'> {
  tagName: TagName;
  jblocks: TJBlock[];
  tagParameters: GetTagParametersType<TagName>;
  debugActive?: boolean;
  debugTransition?: boolean;
}

export function checkObjectHaveFields<OBJ extends object>(props: string[]) {
  return (obj: any): obj is OBJ => {
    if (!obj || !obj.hasOwnProperty) return false;
    return props.every(fieldName => obj.hasOwnProperty(fieldName));
  };
}

export const checkObjectIsTJBlock = checkObjectHaveFields<TJBlock>(['tagName', 'jblocks', 'tagParameters']);
export const checkObjectIsJGState = checkObjectHaveFields<JStateWithId>([
  'value',
  'path',
  'filename',
  'theme',
  'blocks',
  'states',
]);

export const mapStringTagNameToEnum: Record<string, TagNames> = {
  a: TagNames.a,
  q: TagNames.q,
  'q!': TagNames.q_,
  event: TagNames.event,
  'event!': TagNames.event_,
  intent: TagNames.intent,
  'intent!': TagNames.intent_,
  intentGroup: TagNames.intentGroup,
  'intentGroup!': TagNames.intentGroup_,
  e: TagNames.e,
  'e!': TagNames.e_,
  eg: TagNames.eg,
  'eg!': TagNames.eg_,
  audio: TagNames.audio,
  random: TagNames.random,
  buttons: TagNames.buttons,
  inlineButtons: TagNames.inlineButtons,
  image: TagNames.image,
  video: TagNames.video,
  script: TagNames.script,
  go: TagNames.go,
  'go!': TagNames.go_,

  if: TagNames.if,
  else: TagNames.else,
  elseif: TagNames.elseif,

  Email: TagNames.Email,
  HttpRequest: TagNames.HttpRequest,
  InputNumber: TagNames.InputNumber,
};

export const activationBlocks: string[] = [
  'q',
  'q!',
  'event',
  'event!',
  'intent',
  'intent!',
  'intentGroup',
  'intentGroup!',
  'e',
  'e!',
  'eg',
  'eg!',
];
