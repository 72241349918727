import React from 'react';
import { IconButton } from '..';
import { Toolbar, ToolbarLeft, ToolbarRight } from '../Toolbar';
import classNames from 'classnames';
import './style.scss';
import { useToggle } from '../utils';
export class WindowControl {
    constructor() {
        this.isShown = false;
        this.show = () => { };
        this.hide = () => { };
        this.toggle = () => { };
        this.isMaximized = false;
        this.toggleMaximize = () => { };
    }
}
export const useWindowControl = (initialShown, initialMaximized) => {
    const [isShown, show, hide, toggle] = useToggle(initialShown);
    const [isMaximized, , , toggleMaximize] = useToggle(initialMaximized);
    return { isShown, show, hide, toggle, isMaximized, toggleMaximize };
};
export const WindowPane = ({ control, title = '', maximizable, minimizable, onMinimize = () => { }, onMaximize = () => { }, className, children, style, dark, controlsPlacement = 'up', }) => {
    const onMaximizeClick = () => {
        control === null || control === void 0 ? void 0 : control.toggleMaximize();
        onMaximize();
    };
    const onMinimizeClick = () => {
        control === null || control === void 0 ? void 0 : control.hide();
        onMinimize();
    };
    if (control && !control.isShown)
        return null;
    return (React.createElement("div", { className: classNames('justui_window', { dark: dark, reverse: controlsPlacement === 'down' }, className), style: style },
        React.createElement(Toolbar, { className: 'justui_window__title-bar' },
            React.createElement(ToolbarLeft, { className: 'justui_window__title' }, title),
            React.createElement(ToolbarRight, { className: 'justui_window__buttons' },
                maximizable ? (React.createElement(IconButton, { "data-test-id": `WindowPane.rightToolbar.${(control === null || control === void 0 ? void 0 : control.isMaximized) ? 'minimize' : 'maximize'}`, name: (control === null || control === void 0 ? void 0 : control.isMaximized) ? 'faCompress' : 'faExpand', size: 'sm', onClick: onMaximizeClick })) : null,
                minimizable ? (React.createElement(IconButton, { "data-test-id": 'WindowPane.rightToolbar.hide', name: 'faWindowMinimize', size: 'sm', onClick: onMinimizeClick })) : null)),
        React.createElement("div", { className: 'justui_window__content' }, children)));
};
