import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'storeTypes';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';

import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { saveScreen } from 'reducers/JGraph.reducer/index';
import { updateState, addNewStateWithSave } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { TagNames } from 'modules/JGraph/utils/types';
import { JStateWithId } from 'reducers/JGraph.reducer/types';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';

const addNewStartState = createAsyncThunk(
  'JGraph/addNewStartState',
  async (
    data: {
      screenPath: string;
      updateExisted?: boolean;
    },
    thunkAPI
  ) => {
    if (!data.screenPath.startsWith('/')) {
      data.screenPath = '/' + data.screenPath;
    }
    let state = thunkAPI.getState() as RootState;
    if (data.updateExisted) {
      const screenToUpdate = findScreenByPath(data.screenPath, state.JGraphReducer.graph.blocks);
      if (!screenToUpdate) return;
      const newState: JStateWithId = {
        ...screenToUpdate,
        blocks: [
          {
            tagName: TagNames.q_,
            tagValue: '$regex</start>',
            tagParameters: [],
            comment: [],
            jblocks: [],
          },
          ...(screenToUpdate?.blocks || []),
        ],
      };
      thunkAPI.dispatch(saveScreen(newState));
      mainSave$.next({
        type: 'update',
        path: screenToUpdate.path,
        action: () => thunkAPI.dispatch(updateState(newState)),
      });
      window.requestAnimationFrame(() => {
        scrollToTargetGlobal$.next({
          targetPathId: screenToUpdate.pathId,
          isSideMenuOpen: false,
        });
      });
      return;
    }
    const localState = {
      screenPath: data.screenPath,
      blocks: [
        {
          tagName: TagNames.q_,
          tagValue: '$regex</start>',
          tagParameters: [],
          comment: [],
          jblocks: [],
        },
      ],
    };
    thunkAPI.dispatch(addNewStateWithSave({ ...localState, setEdit: true }));
  }
);

export default addNewStartState;
