import React from 'react';
import { isInternal, isSystemAccess } from 'isAccessFunction';
import TariffLimitAnnouncement from 'views/TariffLimitAnnouncement';
import AlertBanner from 'views/AlertBanner';

import SadminPanel from 'components/SadminPanel';
import Header from 'components/Header';
import { GithubTokenBanner } from 'components/GithubTokenBanner';

export const HeaderArea = ({ isLoggedInOrNotInternal, showGithubWarning, ...fullProps }) => (
  <div className='global-header'>
    {Boolean(fullProps.appStatus) && Object.values(fullProps.appStatus).indexOf(true) > -1 && (
      <AlertBanner appStatus={fullProps.appStatus} />
    )}

    {isSystemAccess(['botadmin_billing_feature']) && !isInternal() && fullProps.currentUser && (
      <TariffLimitAnnouncement
        key='TariffLimitAnnouncement'
        tariffUniqueName={fullProps.tariffData.tariffUniqueName}
        addMessage={fullProps.addMessage}
      />
    )}

    {showGithubWarning && <GithubTokenBanner />}

    {isInternal() && (
      <>
        <SadminPanel {...fullProps} />
        <div id='accountsFilter' />
      </>
    )}

    {isLoggedInOrNotInternal && <Header {...fullProps} />}
  </div>
);
