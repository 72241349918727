var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import { Button, Tooltip } from '../index';
export default function ButtonWithTooltip(props) {
    const { iconLeft, outline, color, className, onClick, disabled, children, tooltipText, target, id } = props, restProps = __rest(props, ["iconLeft", "outline", "color", "className", "onClick", "disabled", "children", "tooltipText", "target", "id"]);
    const removeFocusWrapper = useCallback(event => {
        onClick && onClick(event);
        event.currentTarget.blur();
    }, [onClick]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, Object.assign({ iconLeft: iconLeft, outline: outline, color: color, className: className, onClick: removeFocusWrapper, disabled: disabled, tooltipWrapperId: target, id: id }, restProps), children),
        target && (React.createElement(Tooltip, { className: 'tooltip--dark faq-answer__tooltip faq-answer__tooltip--top-fix', placement: 'top', target: target }, tooltipText))));
}
