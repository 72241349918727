import { AlertNotification } from '@just-ai/just-ui';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { useAppContext } from 'modules/Caila/components/AppContext';
import React, { FunctionComponent, useEffect } from 'react';
import { isAccountManager } from '../../isAccessFunction';

type Props = {
  alerts: AlertNotificationItemProps[];
  dismissAllAlerts: () => void;
  locale: 'ru' | 'en';
};

export const GlobalAlerts: FunctionComponent<Props> = ({ alerts, dismissAllAlerts, locale }) => {
  const { accountId } = useAppContext();

  useEffect(() => {
    if ((!accountId || accountId === -1) && !isAccountManager() && alerts.length > 0) {
      dismissAllAlerts();
    }
  }, [accountId, alerts.length, dismissAllAlerts]);

  return (
    <AlertNotification
      alerts={alerts}
      locale={locale}
      //@ts-ignore
      dismissAlert={() => {}}
      dismissAllAlerts={dismissAllAlerts}
      animationType='fadeIn'
    />
  );
};
