import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'storeHooks';
import { usePrevious } from '@just-ai/just-ui/dist/utils';

import { getGlobalConnectors } from 'modules/JGraph/contexts/globalConnectors';

import { EditMenuBlock, JStateWithId } from 'reducers/JGraph.reducer/types';
import { makeNewConnector, setEditMenuBlock } from 'reducers/JGraph.reducer';
import { addNewStateWithSave, updateState } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';

import { TagNames, TJBlock, TTagParameters } from '../../../utils/types';
import { mainSave$, useSavingPipe } from '../../../hooks/savingPipe';
import { BehaviorSubject } from 'rxjs';
import { wasFromNewIntentsMenu$ } from './EventsHook';
import { backToNewCailaIntentsCreation } from '../RSAddingMenu/ActivationsAddingMenu/JGraphCailaIntents/JGraphCailaIntentChooseCreation';
import { guideTourEvent$ } from '../../JGraphGuideTour/guideTourEvents';

export type TCommonBlockProps = {
  tagName?: TJBlock['tagName'];
  tagValue?: TJBlock['tagValue'] | null;
  tagParameters?: TJBlock['tagParameters'];
};

export const ShouldCreateStateOnSave$ = new BehaviorSubject<{
  value: string;
  callback?: () => Promise<void | unknown>;
}>({
  value: '',
  callback: () => Promise.resolve(),
});

export const dropShouldCreateStateOnSave = () =>
  ShouldCreateStateOnSave$.next({ value: '', callback: () => Promise.resolve() });

export const useEdit = (editMenuBlock: EditMenuBlock) => {
  const { removedConnectorOnNewBlock, screens } = useAppSelector(state => ({
    removedConnectorOnNewBlock: state.JGraphReducer.removedConnectorOnNewBlock,
    screens: state.JGraphReducer.graph.blocks,
  }));
  const previousReduxEditMenuScreen = usePrevious((editMenuBlock || {}).screen || null);
  const dispatch = useAppDispatch();
  const { stateUpdate, stateCreate } = useSavingPipe();
  const [newEditMenuScreen, setScreenValue] = useState<JStateWithId>(cloneDeep(editMenuBlock!.screen));
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { jBlockIndex, path } = editMenuBlock!;

  useEffect(() => {
    if (!previousReduxEditMenuScreen || previousReduxEditMenuScreen !== editMenuBlock!.screen) {
      setScreenValue(cloneDeep(editMenuBlock!.screen));
    }
    const sub = ShouldCreateStateOnSave$.subscribe(value => {
      setIsDirty(!!value);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [editMenuBlock, previousReduxEditMenuScreen]);

  useEffect(() => {
    const newIsDirty =
      JSON.stringify(editMenuBlock!.screen) !== JSON.stringify(newEditMenuScreen) ||
      !!editMenuBlock!.screen.isDirty ||
      !!ShouldCreateStateOnSave$.getValue();
    if (newIsDirty !== isDirty) {
      setIsDirty(newIsDirty);
    }
  }, [editMenuBlock, newEditMenuScreen, isDirty, jBlockIndex]);

  const save = useCallback(async () => {
    if (newEditMenuScreen.isUnsaved) {
      stateCreate(newEditMenuScreen);
      const connections = getGlobalConnectors();
      const newScreenExistConnection = connections.find(connection => connection.to === newEditMenuScreen.pathId);
      if (newScreenExistConnection) {
        const fromScreenConnector = screens.find(screen => screen.pathId === newScreenExistConnection.fromNode);
        if (fromScreenConnector) {
          mainSave$.next({
            type: 'update',
            path: fromScreenConnector.path,
            action: () => dispatch(updateState({ ...fromScreenConnector, useSelfPathValue: true })),
          });
        }
      }
    } else {
      const stateNameToCreate = ShouldCreateStateOnSave$.getValue();
      if (stateNameToCreate.value !== '') {
        const path = stateNameToCreate.value.startsWith('/')
          ? stateNameToCreate.value.trim()
          : '/' + stateNameToCreate.value.trim();
        dispatch(addNewStateWithSave({ screenPath: path, saveWithoutSetEdit: true }));
        if (stateNameToCreate.callback) await stateNameToCreate.callback();
        dropShouldCreateStateOnSave();
      }
      await stateUpdate(cloneDeep(newEditMenuScreen));
      if (wasFromNewIntentsMenu$.getValue()) {
        window.requestAnimationFrame(() => {
          backToNewCailaIntentsCreation();
        });
      }
    }
  }, [dispatch, newEditMenuScreen, screens, stateCreate, stateUpdate]);

  const cancel = () => {
    dropShouldCreateStateOnSave();
    if (isDirty) {
      setScreenValue(cloneDeep(editMenuBlock!.screen));
    }
    if (removedConnectorOnNewBlock) {
      dispatch(makeNewConnector({ from: removedConnectorOnNewBlock.from, to: removedConnectorOnNewBlock.to! }));
    }

    if (editMenuBlock!.path && ScreenBlockPath.isPathRecursive(editMenuBlock!.path) && jBlockIndex !== undefined) {
      const [, mainIfIndex] = ScreenBlockPath.getMainIfBlockAndIndex(
        editMenuBlock!.path,
        jBlockIndex,
        editMenuBlock!.screen.blocks
      );
      dispatch(setEditMenuBlock({ screen: editMenuBlock!.screen, jBlockIndex: undefined, path: `${mainIfIndex}_if_` }));
      return;
    }

    dispatch(setEditMenuBlock({ screen: editMenuBlock!.screen, jBlockIndex: undefined, path: undefined }));
    if (wasFromNewIntentsMenu$.getValue()) {
      backToNewCailaIntentsCreation();
    }
    guideTourEvent$.next('RightSideMenu::Cancel');
  };

  const resetModifications = () => {
    if (isDirty) {
      setScreenValue(cloneDeep(editMenuBlock!.screen));
    }
  };

  const getBlockInDepth = useCallback(
    (blocks: TJBlock[]) => {
      if (path && ScreenBlockPath.isPathRecursive(path)) {
        return ScreenBlockPath.getBlockByPath(blocks, path, jBlockIndex);
      }
      return blocks[jBlockIndex!];
    },
    [jBlockIndex, path]
  );

  const onChangeCommonTag = useCallback(
    ({ tagName, tagValue, tagParameters }: TCommonBlockProps) => {
      setScreenValue(prevScreenSettings => {
        let newBlocks = [...prevScreenSettings.blocks];
        const currentBlock = getBlockInDepth(newBlocks);
        if (!currentBlock) return prevScreenSettings;
        if (tagName !== undefined) {
          currentBlock.tagName = tagName;
        }
        if (tagValue !== undefined) {
          //@ts-ignore wrong typing! can be null
          currentBlock.tagValue = tagValue;
        }
        if (tagParameters !== undefined) {
          currentBlock.tagParameters = tagParameters;
        }
        return { ...prevScreenSettings, blocks: newBlocks };
      });
    },
    [getBlockInDepth]
  );

  const onChangeButtons = (tagData: TTagParameters, elementIndex: number) => {
    if (editMenuBlock?.jBlockIndex !== undefined) {
      let newBlocks = [...newEditMenuScreen.blocks];
      const currentBlock: TJBlock<TagNames.buttons> = getBlockInDepth(newBlocks) as TJBlock<TagNames.buttons>;
      currentBlock.tagParameters[elementIndex] = tagData;
      setScreenValue({ ...newEditMenuScreen, blocks: newBlocks });
    }
  };
  const onAddNewButton = () => {
    if (editMenuBlock?.jBlockIndex !== undefined) {
      let newBlocks = [...newEditMenuScreen.blocks];
      const currentBlock: TJBlock<TagNames.buttons> = getBlockInDepth(newBlocks) as TJBlock<TagNames.buttons>;
      if (currentBlock.tagParameters.length < 8) {
        currentBlock.tagParameters.push({
          name: '',
          transition: '',
          url: '',
          required: false,
        });
        setScreenValue({ ...newEditMenuScreen, blocks: newBlocks });
      }
    }
  };

  const deleteButton = (elementIndex: number) => {
    if (editMenuBlock?.jBlockIndex !== undefined) {
      let newBlocks = [...newEditMenuScreen.blocks];
      const currentBlock: TJBlock<TagNames.buttons> = getBlockInDepth(newBlocks) as TJBlock<TagNames.buttons>;
      currentBlock.tagParameters.splice(elementIndex, 1);
      setScreenValue({ ...newEditMenuScreen, blocks: newBlocks });
    }
  };

  return {
    isDirty,
    setScreenValue,
    onChangeButtons,
    onAddNewButton,
    deleteButton,
    newEditMenuScreen,
    jBlockIndex: editMenuBlock!.jBlockIndex,
    path: editMenuBlock!.path,
    save,
    cancel,
    onChangeCommonTag,
    resetModifications,
  };
};
