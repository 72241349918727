import React, { FC, useCallback } from 'react';
import { Group, Text } from 'react-konva';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { TConnector } from '../../../../reducers/JGraph.reducer/types';
import { AutopositionGroup } from '../parts/AutopositionGroup';
import { IconNames, KIcon } from '../parts/KIcons';
import { TransitionCircle } from '../parts/TransitionCircle';
import { getConnectorPropsFromName, getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { TagNames } from '../../utils/types';
import { AutosizeRect } from '../parts/AutosizeRect';
import { scrollToTargetGlobal$ } from '../../utils/stageUtils';
import { EditMenuBlock$ } from '../StateScreen.hooks';

type IncomingOutgoingItemProps = {
  path: string;
  connections: TConnector[];
};

export const IncomingItem: FC<IncomingOutgoingItemProps> = ({ path, connections }) => {
  const goToState = useCallback(
    event => {
      event.cancelBubble = true;
      let isSideMenuOpen = false;
      const sub = EditMenuBlock$.subscribe(value => {
        isSideMenuOpen = !!value;
      });
      sub.unsubscribe();
      scrollToTargetGlobal$.next({ targetPathId: getValidKonvaName(path), isSideMenuOpen });
    },
    [path]
  );
  return (
    <AutopositionGroup offsetY={0} padding={0} name={path} isScreen={true} type='IncomingItem'>
      <AutosizeRect width={212} name={path} stroke='rgba(0,0,0,0)' cornerRadius={8}>
        <Group>
          <Text
            y={0}
            x={0}
            text={hideRootSlashInPath(path)}
            fill='#0D1822'
            fontSize={12}
            lineHeight={18 / 12}
            letterSpacing={0.01}
            width={150}
            wrap='none'
            ellipsis
          />
          <KIcon width={16} height={16} x={150 + 8} y={0} icon={IconNames.stateLocation} onClick={goToState} />
          {connections.map(connection => {
            const parseConnectionFrom = getConnectorPropsFromName(connection.from);
            return (
              <TransitionCircle
                key={connection.from}
                x={150 + 8 + 16 + 8 + 12}
                y={8}
                name={connection.from}
                tagName={parseConnectionFrom.tagName as TagNames}
                transitionTo={connection.toNodeOriginalPath}
                hasTransition={true}
                canStartConnection={false}
                debugActive={connection.debugActive}
              />
            );
          })}
        </Group>
      </AutosizeRect>
    </AutopositionGroup>
  );
};
