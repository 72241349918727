var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import memoize from 'memoizerific';
import React, { useCallback } from 'react';
const DefaultJustSelectItem = ({ value, onSelect, onHover, children, focused, active, }) => {
    const onSelectHandler = useCallback(() => {
        onSelect && onSelect(value);
    }, [onSelect, value]);
    return (React.createElement("div", { className: classNames('just-select-item', { focused: focused }, { active: active }), onClick: onSelectHandler, onMouseEnter: onHover, "data-test-id": `just-select-item__${value}`, role: 'menuitem' }, children));
};
const createJustSelectItem = (Component, _a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const SelectItem = React.memo((_a) => {
        var { value, onSelect, active, focused, onHover, label } = _a, restProps = __rest(_a, ["value", "onSelect", "active", "focused", "onHover", "label"]);
        return (React.createElement(DefaultJustSelectItem, { onSelect: onSelect, value: value, active: active, focused: focused, onHover: onHover },
            React.createElement(Component, Object.assign({}, rest, restProps, { label: label, value: value }))));
    });
    SelectItem.displayName = `JustSelectChild_${Component.displayName || 'DefaultJustSelectItem'}`;
    return SelectItem;
};
export const DefaultListItem = (_a) => {
    var { label, LabelComponent } = _a, rest = __rest(_a, ["label", "LabelComponent"]);
    if (LabelComponent) {
        return React.createElement(LabelComponent, Object.assign({ label: label }, rest));
    }
    return typeof label === 'string' ? React.createElement("span", { dangerouslySetInnerHTML: { __html: label } }) : label;
};
export const getSelectListItem = memoize(1)((ListElement = DefaultListItem, onSelect = () => { }, props = {}) => {
    return createJustSelectItem(ListElement, props);
});
