var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { isEqual, uniq, debounce } from 'lodash';
import { Spinner, ScrollArea, withTranslationContext } from '@just-ai/just-ui';
import { getErrorCodeFromReason, Intents, getErrorMessageFromReason, addErrorNamespace, assignValidationErrorToReply, } from '../../../../nlu-related/utils';
import { NLUModuleContext } from '../../../../context';
import { FAQIntentService, FAQIntentPathType, FAQIntentPathBuilder, FAQIntentPathParser, } from '../../../../services/FAQIntentService';
import { INITIAL_REPLIES, FAQIntentRootGroup } from '../../../../constants/faqTemplates';
import HTMLService from '../../../../services/HTMLService';
import FAQApiService from '../../../../services/FAQApiService';
import { TreeStructure } from '../../components/Tree/TreeStructure';
import FaqTree from '../../components/FaqTree';
import IntentFaqEditView from '../../components/IntentFaqEditView';
import ErrorMessageTooltip from '../../components/ErrorMessageTooltip';
import classes from '../../styles.module.scss';
import { AppLogger } from '@just-ai/logger';
var FaqSkillView = /** @class */ (function (_super) {
    __extends(FaqSkillView, _super);
    function FaqSkillView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            intentsList: [],
            questionsList: [],
            maxDepthLevel: 1,
            treeLogicState: new TreeStructure({}, 1),
            selectedIntent: null,
            selectedQuestion: null,
            editError: '',
            validationError: undefined,
            errorMessage: undefined,
            searchActive: false,
            search: '',
            searchTrees: {
                group: new TreeStructure({}, 1),
                answer: new TreeStructure({}, 1),
            },
            savingReplies: false,
            loadingInContent: false,
            loadingInSidebar: false,
            loadingInPage: false,
        };
        _this.getActiveSkillIntentId = function (intents, activeSkill) { var _a; return ((_a = FAQIntentService.getFAQIntentByName(intents, activeSkill, true)) === null || _a === void 0 ? void 0 : _a.id) || NaN; };
        _this.updateIntentsData = function (loadingType, fromIntentPath) {
            if (loadingType === void 0) { loadingType = 'loadingInSidebar'; }
            return _this.promiseLoadingHelper(loadingType, _this.getIntents(loadingType).then(function (_a) {
                var intents = _a.data;
                var fromIntentType = fromIntentPath ? FAQIntentService.getIntentType(fromIntentPath) : undefined;
                var filteredIntents = FAQIntentService.getTreeByIntentName(intents, _this.props.activeSkillName);
                //if we came from specific faq intent we set it as selected one
                var newSelectedIntent = filteredIntents.find(function (intent) {
                    return fromIntentPath
                        ? intent.path === fromIntentPath.replace("/KnowledgeBase/".concat(_this.props.activeSkillName), '')
                        : intent.id === _this.state.selectedIntentId;
                }) || null;
                var treeLogicState = new TreeStructure(FAQIntentService.transformToFAQTree(filteredIntents), _this.state.maxDepthLevel);
                var newState = {
                    intentsList: intents,
                    treeLogicState: treeLogicState,
                    selectedIntent: newSelectedIntent,
                    selectedIntentId: newSelectedIntent === null || newSelectedIntent === void 0 ? void 0 : newSelectedIntent.id,
                    selectedQuestion: _this.state.selectedQuestion,
                };
                if (_this.state.selectedQuestion) {
                    var selectedQuestionIntent = intents.find(function (intent) { var _a; return intent.id === ((_a = _this.state.selectedQuestion) === null || _a === void 0 ? void 0 : _a.intent.id); });
                    newState.selectedQuestion = selectedQuestionIntent
                        ? __assign(__assign({}, _this.state.selectedQuestion), { intent: selectedQuestionIntent }) : null;
                }
                var treeFlat = treeLogicState.getTreeFlat();
                _this.setState(newState, function () {
                    if (!newState.selectedIntent && treeFlat.length > 0) {
                        _this.getIntent(treeFlat[0].id, treeFlat[0].path);
                    }
                });
                _this.getQuestions('loadingInContent')
                    .then(function (_a) {
                    var questions = _a.data;
                    var selectedGroupQuestion = fromIntentPath && fromIntentType === FAQIntentPathType['Group']
                        ? questions.find(function (question) { var _a, _b; return (_a = question.intent.path) === null || _a === void 0 ? void 0 : _a.includes(((_b = _this.state.selectedIntent) === null || _b === void 0 ? void 0 : _b.path) || ''); }) ||
                            null
                        : null;
                    _this.setState(function (state) { return ({
                        questionsList: questions,
                        selectedQuestion: questions.find(function (question) {
                            var _a;
                            return fromIntentPath && fromIntentType === FAQIntentPathType['Question']
                                ? question.intent.path === fromIntentPath
                                : question.id === ((_a = state.selectedQuestion) === null || _a === void 0 ? void 0 : _a.id);
                        }) || selectedGroupQuestion,
                    }); });
                })
                    .catch(function (error) { return console.error('Error when fetching questions for skill', error); });
                return newState;
            }));
        };
        _this.getIntents = function (loadingType) {
            if (loadingType === void 0) { loadingType = 'loadingInSidebar'; }
            var _a = _this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, IntentsApi = _a.IntentsApi;
            return _this.promiseLoadingHelper(loadingType, IntentsApi.listIntentsFullData(accountId, projectShortName));
        };
        _this.getQuestions = function (loadingType) {
            if (loadingType === void 0) { loadingType = 'loadingInSidebar'; }
            var _a = _this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, FAQApi = _a.FAQApi;
            var activeSkillName = _this.props.activeSkillName;
            var prefixedSkillName = Intents.generateFAQPathByTemplateName(activeSkillName);
            return _this.promiseLoadingHelper(loadingType, FAQApi.getFaqQuestionList(accountId, projectShortName, prefixedSkillName));
        };
        _this.selectNode = function (node) {
            if (_this.state.selectedIntentId !== node.id) {
                return _this.getIntent(node.id, node.path);
            }
        };
        _this.getIntent = function (id, path) {
            var _a = _this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, IntentsApi = _a.IntentsApi, FAQApi = _a.FAQApi;
            //if path argument wasn't provided look up for intent by its ID
            if (!path) {
                return _this.promiseLoadingHelper('loadingInContent', IntentsApi.getIntent(accountId, projectShortName, id)
                    .then(function (data) {
                    var intent = data.data;
                    if (!FAQIntentService.isNormalizedFAQQuestionIntent(intent)) {
                        intent = FAQIntentService.normalizeIntentLikeFAQQuestion(intent);
                        _this.faqApiService.updateFAQIntent(intent).then(function () { return _this.syncIntentInState(intent); });
                    }
                    return intent;
                })
                    .then(function (intent) {
                    _this.setState({ selectedIntentId: intent.id, selectedIntent: intent, editError: '' });
                }));
            }
            //if path was provided, construct full intent path to look up a question for it
            var fullPath = FAQIntentPathBuilder.initWithTemplateName(_this.props.activeSkillName)
                .addQuestion(path === null || path === void 0 ? void 0 : path.slice(1))
                .getPath();
            return _this.promiseLoadingHelper('loadingInContent', FAQApi.getFaqQuestionByIntent(accountId, projectShortName, fullPath)
                .then(function (data) {
                var question = data.data;
                var intent = question.intent;
                if (!FAQIntentService.isNormalizedFAQQuestionIntent(intent)) {
                    intent = FAQIntentService.normalizeIntentLikeFAQQuestion(intent);
                    _this.faqApiService.updateFAQIntent(intent).then(function () { return _this.syncIntentInState(intent); });
                }
                return question;
            })
                .then(function (question) {
                _this.setState({
                    selectedIntentId: question.intent.id,
                    selectedIntent: question.intent,
                    editError: '',
                    selectedQuestion: question,
                    validationError: undefined,
                    savingReplies: false,
                });
            })
                .catch(function (error) {
                //if question by this intent path was not found, we create it
                var errorCode = getErrorCodeFromReason(error);
                if (errorCode !== 'caila.faq.question_with_provided_path_not_found') {
                    return console.error('Error creating or fetching question', error);
                }
                var newQuestion = {
                    replies: INITIAL_REPLIES,
                    intent: {
                        path: fullPath,
                        phrases: [{ text: Intents.getNameFromPath(fullPath) }],
                    },
                };
                FAQApi.createFaqQuestion(accountId, projectShortName, newQuestion).then(function (_a) {
                    var question = _a.data;
                    _this.setState({
                        selectedQuestion: question,
                        selectedIntent: question.intent,
                        selectedIntentId: question.intent.id,
                    });
                });
            }));
        };
        _this.createNewQuestion = function () {
            var _a = _this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, FAQApi = _a.FAQApi;
            var newIntent = {
                path: "".concat(Intents.generateFAQPathByTemplateName(_this.props.activeSkillName), "/Root/").concat(_this.generateNewName(_this.props.t('FAQ:Intent:BaseQuestionName'))),
            };
            newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent);
            var newQuestion = {
                replies: INITIAL_REPLIES,
                intent: newIntent,
            };
            _this.promiseLoadingHelper('loadingInContent', _this.promiseLoadingHelper('loadingInSidebar', FAQApi.createFaqQuestion(accountId, projectShortName, newQuestion).then(function (_a) {
                var createdQuestion = _a.data;
                return _this.updateIntentsData()
                    .then(function () {
                    return _this.setState({
                        selectedIntentId: createdQuestion.intent.id,
                        selectedIntent: createdQuestion.intent,
                        selectedQuestion: createdQuestion,
                        editError: '',
                    });
                })
                    .then(function () { return _this.scrollToTreeNode(newIntent.id, 300); });
            })));
        };
        _this.onCreateFolderWithNodes = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var intents, newGroupIntent;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (nodes.length === 0)
                            return [2 /*return*/];
                        intents = nodes.map(function (node) { return _this.findIntentInDataset(node.id); }).filter(Boolean);
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.onCreateFolderWithNodes(this.props.activeSkillName, this.generateNewName(this.props.t('FAQ:Intent:BaseGroupName')), intents))];
                    case 1:
                        newGroupIntent = _a.sent();
                        return [4 /*yield*/, this.updateIntentsData('loadingInPage')];
                    case 2:
                        _a.sent();
                        if (!newGroupIntent)
                            return [2 /*return*/];
                        this.setState({ selectedIntentId: newGroupIntent.id, editError: '', editId: newGroupIntent.id });
                        this.scrollToTreeNode(newGroupIntent.id, 300);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onMoveQuestionInFolder = function (folder, nodes) { return __awaiter(_this, void 0, void 0, function () {
            var folderIntent, questionsIntents, existedChildrenNames;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (nodes.length === 0)
                            return [2 /*return*/];
                        folderIntent = this.findIntentInDataset(folder.id);
                        questionsIntents = nodes
                            .map(function (node) { return _this.findIntentInDataset(node.id); })
                            .filter(Boolean);
                        if (!folderIntent || questionsIntents.length === 0)
                            return [2 /*return*/, null];
                        existedChildrenNames = folder.children.map(Intents.getNameFromPath);
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.moveQuestionInFolder(folderIntent, existedChildrenNames, questionsIntents))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateIntentsData()];
                    case 2:
                        _a.sent();
                        this.setState({ selectedIntentId: folderIntent.id, editError: '' });
                        this.scrollToTreeNode(folderIntent.id, 300);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.createNewGroup = function () { return __awaiter(_this, void 0, void 0, function () {
            var newGroupIntent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.createFAQGroup(this.state.intentsList, this.props.activeSkillName, this.props.t('FAQ:Intent:BaseGroupName')))];
                    case 1:
                        newGroupIntent = _a.sent();
                        return [4 /*yield*/, this.updateIntentsData()];
                    case 2:
                        _a.sent();
                        if (!newGroupIntent)
                            return [2 /*return*/];
                        this.setState({ selectedIntentId: newGroupIntent.id, editError: '', editId: newGroupIntent.id });
                        this.scrollToTreeNode(newGroupIntent.id, 300);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.deleteIntentRecursively = function (nodesToDelete) { return __awaiter(_this, void 0, void 0, function () {
            var _a, selectedIntent, treeLogicState, activeSkillName, allSoonDeletedNodes, isRemoved, newSelectedNode, nodes, idsToDelete, newState, existedLeafs, filteredIntents, newSelectedIntent;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.state, selectedIntent = _a.selectedIntent, treeLogicState = _a.treeLogicState;
                        activeSkillName = this.props.activeSkillName;
                        allSoonDeletedNodes = nodesToDelete.map(function (node) { var _a; return Array.from((_a = node.containIds) !== null && _a !== void 0 ? _a : []); }).flat();
                        isRemoved = selectedIntent ? !!allSoonDeletedNodes.find(function (id) { return id === selectedIntent.id; }) : false;
                        newSelectedNode = undefined;
                        if (isRemoved) {
                            nodes = treeLogicState.getTreeFlat();
                            newSelectedNode = FAQIntentService.findClosestNode(nodes, allSoonDeletedNodes);
                        }
                        idsToDelete = uniq(nodesToDelete.map(function (node) { return TreeStructure.getIdsInDepth(node); }).flat());
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.deleteQuestion(idsToDelete, activeSkillName))];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.updateIntentsData()];
                    case 2:
                        newState = _b.sent();
                        if (!newState)
                            return [2 /*return*/];
                        existedLeafs = newState.treeLogicState.getTreeFlat();
                        if (!(existedLeafs === null || existedLeafs === void 0 ? void 0 : existedLeafs.length)) {
                            this.createNewQuestion();
                            return [2 /*return*/];
                        }
                        if (!isRemoved || !newSelectedNode)
                            return [2 /*return*/];
                        filteredIntents = FAQIntentService.filterIntentsByTemplateName(newState.intentsList, activeSkillName, true);
                        newSelectedIntent = filteredIntents.find(function (intent) { return intent.id === (newSelectedNode === null || newSelectedNode === void 0 ? void 0 : newSelectedNode.id); }) || filteredIntents[0];
                        if (newSelectedIntent.id) {
                            this.getIntent(newSelectedIntent.id);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onMoveInRoot = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var intents, rootChildren, existedChildrenNames, folderPath;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (nodes.length === 0)
                            return [2 /*return*/];
                        intents = nodes.map(function (node) { return _this.findIntentInDataset(node.id); }).filter(Boolean);
                        if (intents.length === 0)
                            return [2 /*return*/, null];
                        rootChildren = ((_a = this.state.treeLogicState.dataset['/Root']) === null || _a === void 0 ? void 0 : _a.children) || [];
                        existedChildrenNames = rootChildren.map(Intents.getNameFromPath);
                        folderPath = FAQIntentService.generateRootPath(this.props.activeSkillName);
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInSidebar', this.faqApiService.moveQuestionInRoot(folderPath, existedChildrenNames, intents))];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.updateIntentsData()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.onRename = function (text, node) { return __awaiter(_this, void 0, void 0, function () {
            var intent;
            var _this = this;
            return __generator(this, function (_a) {
                intent = this.findIntentInDataset(node.id);
                if (!text || !(intent === null || intent === void 0 ? void 0 : intent.id))
                    return [2 /*return*/, Promise.reject()];
                intent = {
                    id: intent.id,
                    path: "".concat(Intents.getParentPathFromPath(intent.path), "/").concat(text.trim()),
                };
                return [2 /*return*/, this.faqApiService
                        .updateFAQIntent(intent, false)
                        .then(function () { return _this.updateIntentsData(); })
                        .then(function () { return _this.scrollToTreeNode(node.id, 300); })
                        .catch(function (error) {
                        throw error;
                    })];
            });
        }); };
        _this.checkLastItemToToggle = function () { return __awaiter(_this, void 0, void 0, function () {
            var intentsFromTemplates, treeToCheck, intentsArrToDisable, questionNodes, allQuestionsEnabled, allQuestionsDisabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        intentsFromTemplates = FAQIntentService.getTreeByIntentName(this.state.intentsList, this.props.activeSkillName);
                        treeToCheck = new TreeStructure(FAQIntentService.transformToFAQTree(intentsFromTemplates), this.state.maxDepthLevel);
                        intentsArrToDisable = Object.values(treeToCheck.dataset);
                        questionNodes = Object.values(treeToCheck.dataset).filter(function (intent) { return !intent.isFolder && !intent.isGhostNode; });
                        allQuestionsEnabled = questionNodes.every(function (node) { return node.enabled; });
                        if (!allQuestionsEnabled) return [3 /*break*/, 2];
                        this.props.onToggleEnable(this.props.activeSkillName, true);
                        return [4 /*yield*/, this.onSwitchEnablement(intentsArrToDisable, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        allQuestionsDisabled = questionNodes.every(function (node) { return !node.enabled; });
                        if (allQuestionsDisabled) {
                            this.props.onToggleEnable(this.props.activeSkillName, false);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.updateQuestion = function (question, updatingReplies, softValidate, signal) {
            if (softValidate === void 0) { softValidate = true; }
            if (updatingReplies)
                _this.setState({ savingReplies: true });
            var intent = question.intent;
            if (!intent.id)
                return;
            var originalIntent = _this.findIntentInDataset(intent.id);
            if (!(originalIntent === null || originalIntent === void 0 ? void 0 : originalIntent.id))
                return;
            intent = FAQIntentService.normalizeIntentLikeFAQQuestion(intent, originalIntent);
            question.intent = intent;
            if (!intent.id)
                return;
            return _this.faqApiService
                .updateFAQIntent(intent, false, question, softValidate, { signal: signal })
                .then(function () { return _this.syncQuestionInState(question); })
                .then(function () { return _this.syncIntentInState(question.intent); })
                .then(function () { return _this.scrollToTreeNode(intent.id, 300); })
                .then(function () {
                intent.enabled !== originalIntent.enabled && _this.checkLastItemToToggle();
            })
                .then(function () {
                _this.setState({ savingReplies: false, validationError: undefined });
            })
                .catch(function (error) {
                var _a;
                AppLogger.info({
                    message: 'error updating question',
                    exception: error,
                });
                if (error instanceof Error) {
                    _this.context.appLogger.error({
                        message: 'FAQ: Error while updated question',
                        exception: error,
                    });
                }
                if (error.response && error.response.status === 406) {
                    var updatedQuestion = assignValidationErrorToReply((_a = error.response.data) === null || _a === void 0 ? void 0 : _a.answers, question);
                    _this.syncQuestionInState(updatedQuestion);
                    _this.setState({
                        validationError: error.response.data,
                    });
                    return;
                }
                if (error.code === 'ERR_CANCELED') {
                    _this.setState({
                        savingReplies: false,
                    });
                }
                else {
                    _this.setState({
                        editError: _this.getFAQError(error, false),
                    });
                }
            });
        };
        _this.scrollToTreeNode = function (nodeId, delay) {
            if (delay === void 0) { delay = 200; }
            return new Promise(function (resolve, reject) {
                if (!nodeId) {
                    reject();
                    return;
                }
                setTimeout(function () {
                    var _a;
                    (_a = _this.treeScroll) === null || _a === void 0 ? void 0 : _a.scrollToNode(nodeId);
                    resolve(null);
                }, delay);
            });
        };
        _this.onSwitchEnablement = function (nodes, enabled) {
            if (enabled === void 0) { enabled = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var intents, newState, selectedIntent, newSelectedIntent;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (nodes.length === 0)
                                return [2 /*return*/];
                            intents = nodes.map(function (node) { return _this.findIntentInDataset(node.id); }).filter(Boolean);
                            intents = FAQIntentService.switchIntentsEnablement(intents, enabled);
                            return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.updateFAQIntents(intents))];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.updateIntentsData('loadingInPage')];
                        case 2:
                            newState = _a.sent();
                            selectedIntent = this.state.selectedIntent;
                            if (!selectedIntent || !(newState === null || newState === void 0 ? void 0 : newState.intentsList))
                                return [2 /*return*/];
                            newSelectedIntent = newState.intentsList.find(function (intent) { return intent.id === selectedIntent.id; });
                            if (newSelectedIntent === null || newSelectedIntent === void 0 ? void 0 : newSelectedIntent.id) {
                                this.getIntent(newSelectedIntent.id);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        _this.getQuestionCopy = function (node) {
            var _a;
            var parentNode = (_a = _this.state.treeLogicState) === null || _a === void 0 ? void 0 : _a.dataset[node.parent];
            var childrenNames = parentNode.children.map(Intents.getNameFromPath);
            var newName = FAQIntentService.generateNewUniqNameWithIndex(childrenNames, node.name);
            var intent = _this.findIntentInDataset(node.id);
            var newIntent = __assign(__assign({}, intent), { path: "".concat(FAQIntentService.getTemplatePath(_this.props.activeSkillName)).concat(parentNode.path, "/").concat(newName) });
            newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
            return newIntent;
        };
        _this.getFolderCopyFn = function (node) {
            return function () { return __awaiter(_this, void 0, void 0, function () {
                var newGroup, childrenIntents, childrenQuestions;
                var _this = this;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.faqApiService.createFAQGroup(this.state.intentsList, this.props.activeSkillName, node.name, node.enabled)];
                        case 1:
                            newGroup = _b.sent();
                            childrenIntents = (_a = node.children) === null || _a === void 0 ? void 0 : _a.map(function (childPath) {
                                var childNode = _this.state.treeLogicState.dataset[childPath];
                                var intent = _this.findIntentInDataset(childNode.id);
                                var newIntent = __assign(__assign({}, intent), { path: "".concat(newGroup === null || newGroup === void 0 ? void 0 : newGroup.path, "/").concat(childNode.name) });
                                return FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                            });
                            childrenQuestions = [];
                            childrenIntents.forEach(function (intent) {
                                var respectiveQuestion = _this.state.questionsList.find(function (question) { return question.intent.id === intent.id; });
                                if (respectiveQuestion)
                                    childrenQuestions.push(__assign(__assign({}, respectiveQuestion), { id: undefined, intent: __assign(__assign({}, intent), { id: undefined }) }));
                            });
                            if (!childrenQuestions) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.faqApiService.createFaqQuestions(childrenQuestions)];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
        };
        _this.onCopy = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var isAllNodesQuestions, newIntents, childrenQuestions_1, isAllNodesFolders, newFoldersFn;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isAllNodesQuestions = nodes.every(function (node) { return !node.isFolder; });
                        if (!isAllNodesQuestions) return [3 /*break*/, 3];
                        newIntents = nodes.map(this.getQuestionCopy);
                        childrenQuestions_1 = [];
                        newIntents.forEach(function (intent) {
                            var respectiveQuestion = _this.state.questionsList.find(function (question) { return question.intent.id === intent.id; });
                            if (respectiveQuestion)
                                childrenQuestions_1.push(__assign(__assign({}, respectiveQuestion), { id: undefined, intent: __assign(__assign({}, intent), { id: undefined }) }));
                        });
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', this.faqApiService.createFaqQuestions(childrenQuestions_1))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.updateIntentsData('loadingInPage')];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3:
                        isAllNodesFolders = nodes.every(function (node) { return node.isFolder; });
                        if (!isAllNodesFolders) return [3 /*break*/, 6];
                        newFoldersFn = nodes.map(this.getFolderCopyFn);
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInPage', Promise.allSettled(newFoldersFn.map(function (p) { return p(); })))];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.updateIntentsData('loadingInPage')];
                    case 5:
                        _a.sent();
                        return [2 /*return*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.onGroup = function (nodes) {
            var isAllNodesQuestions = nodes.every(function (node) { return !node.isFolder; });
            if (isAllNodesQuestions) {
                return _this.onCreateFolderWithNodes(nodes);
            }
        };
        _this.onUngroup = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var isAllNodesFolders, allChildren, ids;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isAllNodesFolders = nodes.every(function (node) { return node.isFolder; });
                        if (!isAllNodesFolders) return [3 /*break*/, 4];
                        allChildren = nodes
                            .map(function (node) { return node.children; })
                            .flat()
                            .map(function (path) { return _this.state.treeLogicState.dataset[path]; })
                            .filter(Boolean);
                        return [4 /*yield*/, this.onMoveInRoot(allChildren)];
                    case 1:
                        _a.sent();
                        ids = nodes.map(function (el) { return el.id; });
                        return [4 /*yield*/, this.promiseLoadingHelper('loadingInSidebar', this.faqApiService.deleteQuestion(ids, this.props.activeSkillName))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.updateIntentsData('loadingInPage')];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.onError = function (errorMessage) {
            _this.setState({
                errorMessage: errorMessage,
            });
        };
        _this.toggleSearchMode = function () {
            return _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { searchActive: !prevState.searchActive, search: '' })); });
        };
        _this.handleSearch = function (value) {
            var filteredIntents = FAQIntentService.getTreeByIntentName(_this.state.intentsList, _this.props.activeSkillName);
            var searchByQuestion = filteredIntents.reduce(function (acc, node) {
                var nodeName = Intents.getNameFromPath(node.path).toLowerCase();
                var isInRoot = FAQIntentService.isInRoot(node.path);
                if (nodeName.includes(value) && !isInRoot) {
                    var parentNodeName = FAQIntentPathParser.getBase(node.path) || '';
                    var parent_1 = FAQIntentService.findFAQIntent(filteredIntents, "/".concat(parentNodeName));
                    parent_1 ? acc.push(node, parent_1) : acc.push(node);
                }
                if (nodeName.includes(value) && isInRoot) {
                    acc.push(node);
                }
                return acc;
            }, []);
            var searchByPhrases = filteredIntents.reduce(function (acc, node) {
                var _a, _b, _c;
                var nodeName = Intents.getNameFromPath(node.path).toLowerCase();
                if (nodeName.includes(value))
                    return acc;
                var containsInPhrases = ((_a = node.phrases) === null || _a === void 0 ? void 0 : _a.find(function (phrase) { var _a; return (_a = phrase.text) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value); })) || false;
                var containsInPatterns = ((_b = node.patterns) === null || _b === void 0 ? void 0 : _b.find(function (pattern) { return pattern.toLowerCase().includes(value); })) || false;
                var nodeAnswer = (_c = _this.state.questionsList.find(function (question) { return question.intent.id === node.id; })) === null || _c === void 0 ? void 0 : _c.replies;
                var containsInAnswer = (nodeAnswer && nodeAnswer.find(function (reply) { var _a; return reply.type === 'text' && ((_a = reply.text) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value)); })) ||
                    false;
                if (containsInPhrases || containsInPatterns || containsInAnswer)
                    acc.push(node);
                return acc;
            }, []);
            var changedSearchByPhrases = searchByPhrases.map(function (searchResultItem) {
                var isInRoot = FAQIntentService.isInRoot(searchResultItem.path);
                var nodeName = Intents.getNameFromPath(searchResultItem.path);
                if (!isInRoot) {
                    return __assign(__assign({}, searchResultItem), { path: "/".concat(FAQIntentRootGroup, "/").concat(nodeName) });
                }
                return searchResultItem;
            });
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { searchTrees: {
                    group: new TreeStructure(FAQIntentService.transformToFAQTree(searchByQuestion), _this.state.maxDepthLevel),
                    answer: new TreeStructure(FAQIntentService.transformToFAQTree(changedSearchByPhrases), _this.state.maxDepthLevel),
                } })); });
        };
        _this.debounceSearch = debounce(_this.handleSearch, 500);
        _this.handleSearchInput = function (value) {
            var lowerCaseValue = value.toLowerCase();
            _this.debounceSearch(lowerCaseValue);
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { search: value })); });
        };
        _this.onExport = function (nodes) { return __awaiter(_this, void 0, void 0, function () {
            var fullFAQ, nodesIds, fileUrl, htmlService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullFAQ = this.state.treeLogicState.getViewedTreeAsPlainList().length === nodes.length;
                        nodesIds = nodes
                            .map(function (node) {
                            var _a;
                            if (node.isFolder && node.containIds) {
                                var leafsIds = ((_a = node.leafs) === null || _a === void 0 ? void 0 : _a.map(function (leaf) { return leaf.id; })) || [];
                                return __spreadArray([node.id], leafsIds, true);
                            }
                            return node.id;
                        })
                            .flat();
                        if (nodesIds.length < 1) {
                            nodesIds.push(this.getActiveSkillIntentId(this.state.intentsList, this.props.activeSkillName));
                        }
                        return [4 /*yield*/, this.faqApiService.exportFAQ(nodesIds)];
                    case 1:
                        fileUrl = _a.sent();
                        htmlService = new HTMLService();
                        htmlService.downloadLink(fileUrl, fullFAQ ? "".concat(this.props.activeSkillName, ".xlsx") : "".concat(this.props.activeSkillName, "_questions.xlsx"));
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleLinkToAnswer = function (path, id) { return __awaiter(_this, void 0, void 0, function () {
            var pathParts, templateName, intentPath, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!path.includes('/KnowledgeBase/FAQ')) return [3 /*break*/, 4];
                        pathParts = path.split('/');
                        templateName = pathParts[2];
                        intentPath = "/".concat(pathParts[3], "/").concat(pathParts[4]);
                        if (!(this.props.activeSkillName !== templateName)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.props.setSkillTab(templateName)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.selectNode({ id: id, path: intentPath })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        url = "".concat(window.origin, "/").concat(this.context.projectShortName, "/intents/").concat(id);
                        window.open(url, '_blank', 'noreferrer');
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.treeScroll = { scrollToNode: function () { } };
        return _this;
    }
    FaqSkillView.prototype.componentDidMount = function () {
        var _a, _b, _c;
        var _d = this.context, accountId = _d.accountId, projectShortName = _d.projectShortName, IntentsApi = _d.IntentsApi, FAQApi = _d.FAQApi, axiosInstance = _d.axiosInstance;
        this.faqApiService = new FAQApiService(accountId, projectShortName, IntentsApi, FAQApi, axiosInstance);
        this.updateIntentsData('loadingInPage', (_c = (_b = (_a = this.props.history) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.fromIntent);
    };
    FaqSkillView.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _this = this;
        var _a = this.context, accountId = _a.accountId, projectShortName = _a.projectShortName, FAQApi = _a.FAQApi;
        if (this.state.intentsList !== prevState.intentsList) {
            var dataset = FAQIntentService.transformToFAQTree(FAQIntentService.getTreeByIntentName(this.state.intentsList, this.props.activeSkillName));
            this.setState({
                treeLogicState: new TreeStructure(dataset, this.state.maxDepthLevel),
            });
        }
        if (this.props.activeSkillName && this.props.activeSkillName !== prevProps.activeSkillName) {
            this.updateIntentsData('loadingInPage');
        }
        if (!isEqual(this.state.selectedQuestion, prevState.selectedQuestion) &&
            this.state.selectedQuestion &&
            !this.state.selectedQuestion.id) {
            this.promiseLoadingHelper('loadingInContent', FAQApi.createFaqQuestion(accountId, projectShortName, this.state.selectedQuestion).then(function (_a) {
                var question = _a.data;
                _this.setState({
                    selectedQuestion: question,
                    selectedIntent: question.intent,
                    selectedIntentId: question.intent.id,
                    validationError: undefined,
                });
            }));
        }
    };
    FaqSkillView.prototype.promiseLoadingHelper = function (type, promise) {
        return __awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = true, _a)));
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, promise];
                    case 2:
                        result = _a.sent();
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = false, _a)));
                        });
                        return [2 /*return*/, result];
                    case 3:
                        e_1 = _a.sent();
                        this.setState(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[type] = false, _a)));
                        });
                        return [2 /*return*/, Promise.reject(e_1)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FaqSkillView.prototype.generateNewName = function (baseName) {
        var startTemplatePathForChildren = Intents.generateFAQPathByTemplateName(this.props.activeSkillName);
        var topChildren = this.state.intentsList
            .filter(function (intent) {
            var parentPath = Intents.getParentPathFromPath(intent.path);
            return parentPath === startTemplatePathForChildren || parentPath === "".concat(startTemplatePathForChildren, "/Root");
        })
            .map(function (intent) { return Intents.getNameFromPath(intent.path); });
        return FAQIntentService.generateNewUniqNameWithIndex(topChildren, baseName);
    };
    FaqSkillView.prototype.findIntentInDataset = function (id) {
        return this.state.intentsList.find(function (el) { return el.id === id; });
    };
    FaqSkillView.prototype.getFAQError = function (reason, isFolder) {
        var errorCode = getErrorCodeFromReason(reason);
        if (isFolder === undefined)
            return getErrorMessageFromReason(reason);
        if (errorCode === 'caila.intents.intent_with_provided_path_already_exists') {
            var code = addErrorNamespace(errorCode);
            return this.props.t("".concat(code, "_").concat(isFolder ? 'group' : 'question'));
        }
        return getErrorMessageFromReason(reason);
    };
    FaqSkillView.prototype.syncIntentInState = function (intent) {
        var _a;
        if (((_a = this.state.selectedIntent) === null || _a === void 0 ? void 0 : _a.id) === intent.id) {
            this.setState({
                selectedIntent: intent,
            });
        }
        var intentIndex = this.state.intentsList.findIndex(function (el) { return el.id === intent.id; });
        if (intentIndex === -1)
            return;
        var updatedIntents = __spreadArray([], this.state.intentsList, true);
        updatedIntents[intentIndex] = intent;
        this.setState({
            editError: '',
            intentsList: updatedIntents,
        });
    };
    FaqSkillView.prototype.syncQuestionInState = function (question) {
        var _a;
        if (((_a = this.state.selectedQuestion) === null || _a === void 0 ? void 0 : _a.id) === question.id) {
            this.setState({
                selectedQuestion: question,
                selectedIntent: question.intent,
                selectedIntentId: question.intent.id,
            });
        }
        var questionIndex = this.state.questionsList.findIndex(function (el) { return el.id === question.id; });
        if (questionIndex === -1)
            return;
        var updatedQuestions = __spreadArray([], this.state.questionsList, true);
        updatedQuestions[questionIndex] = question;
        this.setState({
            editError: '',
            questionsList: updatedQuestions,
        });
    };
    FaqSkillView.prototype.render = function () {
        var _a, _b, _c, _d;
        var writeDisabled = !this.context.isAccessFunction('BOT_CONTENT_WRITE');
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.faqPage__content },
                this.state.loadingInPage && React.createElement(Spinner, null),
                React.createElement("div", { className: classes.faqPage__sidebar },
                    this.state.loadingInSidebar && React.createElement(Spinner, { backgroundColor: 'rgba(255, 255, 255, 0.2)' }),
                    React.createElement(FaqTree, { treeLogicState: this.state.treeLogicState, selectedId: this.state.selectedIntentId, editId: this.state.editId, onSelectOne: this.selectNode, onRename: this.onRename, createNewQuestion: this.createNewQuestion, onCreateFolderWithNodes: this.onCreateFolderWithNodes, onMoveQuestionInFolder: this.onMoveQuestionInFolder, onDelete: this.deleteIntentRecursively, onDropInRoot: this.onMoveInRoot, createNewGroup: this.createNewGroup, onSwitchEnablement: this.onSwitchEnablement, checkLastItemToToggle: this.checkLastItemToToggle, onCopy: this.onCopy, onGroup: this.onGroup, onUngroup: this.onUngroup, onError: this.onError, errorMessage: this.state.errorMessage, treeScroll: this.treeScroll, searchValue: this.state.search, handleSearch: this.handleSearchInput, toggleSearchMode: this.toggleSearchMode, isSearchActive: this.state.searchActive, searchTrees: this.state.searchTrees, onExport: this.onExport, updateIntentsData: this.updateIntentsData, activeTemplateName: this.props.activeSkillName, faqApiService: this.faqApiService, writeDisabled: writeDisabled })),
                React.createElement("div", { className: classes.faqPage__detailPanel }, this.state.loadingInContent ? (React.createElement(Spinner, { size: '2x' })) : (React.createElement(ScrollArea, { vertical: true, className: 'just-ui-custom-scrollbar with-gutter' }, this.state.selectedIntent && this.state.selectedQuestion && (React.createElement(IntentFaqEditView, { question: this.state.selectedQuestion, error: this.state.editError, onUpdate: this.updateQuestion, savingReplies: this.state.savingReplies, writeDisabled: writeDisabled, validationError: this.state.validationError, handleLinkToAnswer: this.handleLinkToAnswer })))))),
            React.createElement(ErrorMessageTooltip, { show: !!this.state.errorMessage, text: (_a = this.state.errorMessage) === null || _a === void 0 ? void 0 : _a.text, position: (_b = this.state.errorMessage) === null || _b === void 0 ? void 0 : _b.position, direction: (_c = this.state.errorMessage) === null || _c === void 0 ? void 0 : _c.direction, node: (_d = this.state.errorMessage) === null || _d === void 0 ? void 0 : _d.node, dataTestId: 'FAQ.Tree.ErrorTooltip' })));
    };
    FaqSkillView.contextType = NLUModuleContext;
    return FaqSkillView;
}(React.PureComponent));
export var FaqSkillViewWithTranslation = withTranslationContext(FaqSkillView);
