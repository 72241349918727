var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import axios from 'axios';
import queryString from 'query-string';
import { pick, isArray } from 'lodash';
import { analyticsArrayFilter } from './constants';
import { getUserLanguage } from './pureFunctions';
export * from './pureFunctions';
export * from './constants';
export function formatDate(date, format) {
    if (format === void 0) { format = 'DD.MM.YYYY HH:mm:ss'; }
    return moment(date).format(format);
}
var timezone = new Date().getTimezoneOffset();
var headers = {
    language: getUserLanguage().substring(0, 2).toUpperCase(),
    Product: 'botadmin',
    Timezone: timezone,
};
var axiosWithSessionCheck = axios.create({
    headers: headers,
    validateStatus: function (status) {
        if (status === 401) {
            return false;
        }
        else if (status === 403) {
            return false;
        }
        else if (status === 503) {
        }
        return status >= 200 && status < 300;
    },
});
//@ts-ignore
axiosWithSessionCheck._get = function (url, options) {
    return new Promise(function (resolve, reject) {
        axiosWithSessionCheck
            .get(url, options)
            .then(function (response) {
            response.config = __assign(__assign({}, response.config), options);
            resolve(response);
        })
            .catch(reject);
    });
};
var postPutDeleteInterceptor = function (method) { return function (url, data, config) {
    return new Promise(function (resolve, reject) {
        // @ts-ignore
        axiosWithSessionCheck[method](url, data, config)
            //@ts-ignore
            .then(function (response) {
            response.config = __assign(__assign({}, response.config), config);
            resolve(response);
        })
            .catch(reject);
    });
}; };
//@ts-ignore
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
//@ts-ignore
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
//@ts-ignore
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');
//@ts-ignore
export function setAxiosHeadersLanguage(val) {
    axiosWithSessionCheck.defaults.headers.language = val;
}
axiosWithSessionCheck.interceptors.response.use(function (response) {
    var _a, _b;
    var data = response.data, config = response.config;
    if (typeof data === 'string' && data.endsWith('</html>')) {
        console.log('%cBACKEND / NGINX ERROR!', 'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;');
        console.log("%crequest ".concat((_a = config === null || config === void 0 ? void 0 : config.method) === null || _a === void 0 ? void 0 : _a.toUpperCase(), " to ").concat(config.url, " returns html"), 'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: #000 1px 1px;');
        return Promise.reject("BACKEND/NGINX ERROR: request ".concat((_b = config === null || config === void 0 ? void 0 : config.method) === null || _b === void 0 ? void 0 : _b.toUpperCase(), " to ").concat(config.url, " returns html"));
    }
    return response;
});
//@ts-ignore
export function setAxiosHeadersAcessToken(val) {
    axiosWithSessionCheck.defaults.headers['ACCESS-TOKEN'] = val;
}
//@ts-ignore
export function setAxiosHeadersTarget(val) {
    try {
        axiosWithSessionCheck.defaults.headers.referrer = val;
        axios.defaults.headers.referrer = val;
        //@ts-ignore
        headers.referrer = val;
    }
    catch (e) { }
}
export { axiosWithSessionCheck as axios };
export var getFilterFromLocation = function (search, array) {
    if (search === void 0) { search = ''; }
    if (array === void 0) { array = analyticsArrayFilter; }
    var parsedLocationSearch = queryString.parse(search);
    var filterParams = pick(parsedLocationSearch, array);
    if (filterParams.hasOwnProperty('dateFrom')) {
        // @ts-ignore
        filterParams.dateFrom = moment(filterParams.dateFrom, 'x');
    }
    if (filterParams.hasOwnProperty('dateTo')) {
        // @ts-ignore
        filterParams.dateTo = moment(filterParams.dateTo, 'x');
    }
    return filterParams;
};
export var parseErrors = function (response) {
    var errors;
    //todo check response status, don't save error with 401 status
    if (response && response.status !== 401) {
        if (response.data.hasOwnProperty('errors')) {
            errors = response.data.errors;
        }
        else if (response.data.hasOwnProperty('error')) {
            errors = [response.data.error];
        }
        else if (isArray(response.data)) {
            errors = response.data;
        }
        else {
            errors = [response.data];
        }
    }
    else {
        errors = [];
    }
    return errors;
};
export var getCurrentDate = function () {
    var defaultDateFrom = new Date();
    var defaultDateTo = new Date();
    defaultDateFrom.setDate(defaultDateFrom.getDate() - 1);
    return [moment(defaultDateFrom, 'DD.MM.YYYY HH:mm:ss'), moment(defaultDateTo, 'DD.MM.YYYY HH:mm:ss')];
};
export var setDefaultTimezone = function (str) {
    moment.tz.setDefault(str);
};
export var withOverflowDots = function (text, limit) {
    if (text.length <= limit)
        return text;
    return text.slice(0, limit) + '...';
};
