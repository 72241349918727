export var isDev = function () { return process.env.NODE_ENV === 'development'; };
export var getUserLanguage = function (userCountryIsoCode) {
    if (userCountryIsoCode) {
        return userCountryIsoCode.toLowerCase();
    }
    var userLanguage = localStorage.getItem('USER_LANGUAGE');
    if (userLanguage) {
        return userLanguage;
    }
    var lang = window.navigator.language.split('-')[0];
    switch (lang) {
        case 'ru': {
            return 'ru';
        }
        case 'cn':
        case 'zh': {
            return 'cn';
        }
        default:
            return 'eng';
    }
};
export var timeoutPromise = function (timeout, err, promise) {
    return new Promise(function (resolve, reject) {
        promise.then(resolve, reject);
        setTimeout(reject.bind(null, err), timeout);
    });
};
export function flattenObject(ob) {
    var toReturn = {};
    for (var i in ob) {
        if (!ob.hasOwnProperty(i))
            continue;
        if (typeof ob[i] === 'object') {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x))
                    continue;
                toReturn[i + '.' + x] = flatObject[x];
            }
        }
        else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
export var detectSelection = function () {
    return window.getSelection ? window.getSelection().toString() : document.selection.createRange().text;
};
export var sort = function (arr, field, dir) {
    if (dir === void 0) { dir = 'asc'; }
    var newArr = arr.slice();
    newArr.sort(function (a, b) {
        switch (dir) {
            case 'desc': {
                if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
                    return 1;
                }
                if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
                    return -1;
                }
                return 0;
            }
            default: {
                if (parseInt(a[field], 10) > parseInt(b[field], 10)) {
                    return 1;
                }
                if (parseInt(a[field], 10) < parseInt(b[field], 10)) {
                    return -1;
                }
                return 0;
            }
        }
    });
    return newArr;
};
export var removeWidgetCode = function () {
    var justwidgetScriptElement = document.querySelector('script#justwidget');
    var firstJustwidgetElement = document.getElementsByClassName('justwidget')[0];
    var widgetJsElement = document.querySelector('script[src*="s/widget.js"]');
    var themesElement = document.querySelector('script[src*="s/themes/"]');
    if (justwidgetScriptElement) {
        justwidgetScriptElement.remove();
    }
    if (firstJustwidgetElement) {
        firstJustwidgetElement.remove();
    }
    if (widgetJsElement) {
        widgetJsElement.remove();
    }
    if (themesElement) {
        themesElement.remove();
    }
    var styleList = document.querySelectorAll('style[class="justwidget-styles"]');
    for (var i = 0; i < styleList.length; i++) {
        styleList[i].remove();
    }
    window.JustWidget = undefined;
};
export var delay = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
export var capitalizeFirstLetter = function (string) { return string.charAt(0).toUpperCase() + string.slice(1); };
export var getIntFromPageNumber = function (number, fallBackNumber) {
    return Number.isInteger(number) ? number : fallBackNumber;
};
export var normalizeNexmoName = function (name) { return name.replace('Nexmo whatsapp', 'Vonage').replace(/##\d*$/, ''); };
export var getUserValidName = function (firstName, lastName, t, id) {
    if (firstName === void 0) { firstName = ''; }
    if (lastName === void 0) { lastName = ''; }
    return "".concat(firstName || '', " ").concat(lastName || '').trim() || id || t('Analytics: No data specified');
};
