import React, { useState, useCallback, useEffect } from 'react';
import { InputText, Button } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';
import { composeCustomValidationResolver } from 'utils/validator/customValidationResolver';
import { checkEmptyStateName, checkSlashInStateName } from 'modules/JGraph/utils/validators/stateName';

const stateNameValidation = composeCustomValidationResolver(checkEmptyStateName, checkSlashInStateName);

interface StartStateCreationMenuInterface {
  onCreate: (title: string) => void;
}
const StartStateCreationMenu = function ({ onCreate }: StartStateCreationMenuInterface) {
  const [title, setTitle] = useState('');
  const [isValidName, setIsValidName] = useState(true);
  const [error, setError] = useState('');

  const onCreateInner = useCallback(() => {
    onCreate(title.trim());
  }, [onCreate, title]);

  useEffect(() => {
    stateNameValidation({ name: title.trim() }).then(validationResult => {
      setError(validationResult.errors?.name?.message || '');
      setIsValidName(validationResult.isValid);
    });
  }, [title]);

  return (
    <form className={styles.StartStateCreationMenu}>
      <div className={styles.header}>
        <div className={styles.title}>{t('CreationScreenMenu:title')}</div>
        <div>
          <InputText value={title} onChange={setTitle} autoFocus />
        </div>
        {error && <span style={{ color: 'var(--red-500)' }}>{error}</span>}
      </div>
      <div className={styles.footer}>
        <Button color='primary' type='submit' disabled={!title.trim() || !isValidName} onClick={onCreateInner}>
          {t('CreationScreenMenu:CreateState')}
        </Button>
      </div>
    </form>
  );
};

export default React.memo(StartStateCreationMenu);
