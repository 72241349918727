import React, { useMemo } from 'react';
import { Button, Icon, useTranslation } from '@just-ai/just-ui';
import './style.scss';
import { useNLUModuleContext } from '../../../../../../context';
export default function ValidationErrorPanel(props) {
    var validationErrorData = props.validationErrorData, type = props.type, updateWithoutValidation = props.updateWithoutValidation, handleLinkToAnswer = props.handleLinkToAnswer, usageArea = props.usageArea;
    var _a = useNLUModuleContext(), setCurrentProjectToEdit = _a.setCurrentProjectToEdit, isContentManager = _a.isContentManager;
    var t = useTranslation().t;
    var LinksArr = useMemo(function () {
        if (!validationErrorData)
            return [];
        return validationErrorData.map(function (answerData) {
            var _a;
            var stringsArr = ((_a = answerData.intentPath) === null || _a === void 0 ? void 0 : _a.split('/')) || [];
            return {
                skillName: (stringsArr === null || stringsArr === void 0 ? void 0 : stringsArr.find(function (string) { return string.includes('FAQ.'); })) || '',
                questionName: stringsArr[stringsArr.length - 1] || '',
                intentPath: answerData.intentPath || '',
                intentId: answerData.intentId || 0,
                score: answerData.score || 0,
            };
        });
    }, [validationErrorData]);
    return (React.createElement("div", { className: 'validation-error-panel' },
        React.createElement("div", { className: 'validation-error-panel__icon-block' },
            React.createElement(Icon, { name: 'faExclamationCircle', color: 'primary' })),
        React.createElement("div", { className: 'validation-error-panel__info-block' },
            React.createElement("h3", { className: 'mb-2' }, t("".concat(usageArea, "ValErrorPanel.").concat(type, ":header"))),
            React.createElement("p", null, t("".concat(usageArea, "ValErrorPanel.").concat(type, ":info"))),
            React.createElement("div", { className: 'validation-error-panel__links-block mb-4' }, LinksArr.map(function (link) { return (React.createElement("div", { className: 'validation-error-panel__link', key: link.questionName },
                usageArea === 'FAQ' && React.createElement("p", null, link.skillName),
                React.createElement("p", { className: 'pseudoLink', onClick: function () { return handleLinkToAnswer === null || handleLinkToAnswer === void 0 ? void 0 : handleLinkToAnswer(link.intentPath, link.intentId); }, "data-test-id": 'ValErr.Link' },
                    usageArea === 'intent' ? link.intentPath : link.questionName,
                    React.createElement(Icon, { name: 'farArrowUp', color: 'primary', size: 'sm' })),
                usageArea === 'intent' && (React.createElement("p", null,
                    "\u2014 ",
                    Math.round(link.score * 100),
                    "% ",
                    t('intentValErrorPanel.similarity'))))); })),
            React.createElement("div", { className: 'validation-error-panel__btn-block' },
                React.createElement(Button, { outline: true, color: 'secondary', onClick: updateWithoutValidation }, t('saveAnyway')),
                isContentManager && !isContentManager() && setCurrentProjectToEdit && (React.createElement(Button, { flat: true, color: 'secondary', onClick: setCurrentProjectToEdit }, t('similarsSettingBtn')))))));
}
