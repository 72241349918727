import React, { useCallback, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AnswerPart from './AnswerPartBlock';
const SortableItem = SortableElement(({ onChange, componentType = AnswerPart, type, value, setOpenDelete, setOpenEdit, markupMode, selected, setSelected, clearSelection, lastRemaining, fileName, onBlur, uploadDate, audioName, dataTestId, writeDisabled, saveAudioToServer, maxTextSize, }) => {
    const Component = componentType;
    return (React.createElement(Component, { saveAudioToServer: saveAudioToServer, onChange: onChange, type: type, value: value, setOpenDelete: setOpenDelete, markupMode: markupMode, selected: selected, setSelected: setSelected, clearSelection: clearSelection, lastRemaining: lastRemaining, setOpenEdit: setOpenEdit, fileName: fileName, onBlur: onBlur, uploadDate: uploadDate, audioName: audioName, dataTestId: dataTestId, writeDisabled: writeDisabled, maxTextSize: maxTextSize }));
});
export const SortableAnswerPartsContainer = SortableContainer(({ sortableBlocks, onChange, setOpenDelete, setOpenEdit, markupMode, onBlur, writeDisabled, saveAudioToServer, maxTextSize, }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const clearSelection = useCallback(() => {
        setSelectedIndex(-1);
    }, []);
    if (sortableBlocks.length === 0)
        return null;
    return (React.createElement("div", { className: 'faq-answer__sortable-container' }, sortableBlocks.map((block, index) => (React.createElement(SortableItem, { saveAudioToServer: saveAudioToServer, index: index, onChange: value => onChange(value, index), setOpenDelete: () => setOpenDelete(index), setOpenEdit: () => setOpenEdit(index), type: block.type, maxTextSize: maxTextSize, value: block.value, key: `anwer-part_${block.type}_${block.id || index}`, markupMode: markupMode, selected: selectedIndex === index, setSelected: () => setSelectedIndex(index), clearSelection: clearSelection, lastRemaining: sortableBlocks.length === 1, fileName: block.fileName, onBlur: onBlur, audioName: block.audioName, uploadDate: block.uploadDate, dataTestId: `FAQPage.AnswerBlock.ReplyContainer_${block.type}_${index}`, writeDisabled: writeDisabled, disabled: writeDisabled })))));
});
