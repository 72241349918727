import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon, IconButton } from '../index';
import cn from 'classnames';
import { formatTime } from './audioHelpers';
import styles from './styles.module.scss';
function FileInfo({ isValid, loading, containerRef, duration: defaultDuration, uploadError, fileName, url, undoDisabled, undoHandler, }) {
    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(defaultDuration !== null && defaultDuration !== void 0 ? defaultDuration : 0);
    const [time, setTime] = useState(0);
    const audioRef = useRef(null);
    useEffect(() => {
        if (!audioRef.current)
            return;
        if (playing) {
            audioRef.current.play();
        }
        else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }, [playing]);
    const isSuccess = (isValid && !uploadError) || (url && !fileName);
    const onClickReload = useCallback(() => {
        var _a, _b;
        if (undoHandler) {
            return undoHandler();
        }
        // @ts-ignore
        (_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.FileUploadContainer input[type="file"]')) === null || _b === void 0 ? void 0 : _b.click();
    }, [containerRef, undoHandler]);
    return (React.createElement("div", { "data-test-id": 'AudioField.File.Loaded', className: cn(styles.AudioUploader__infoContainer, 'audio-uploader__info-container') },
        React.createElement("div", { "data-test-id": 'AudioField.File.Name', className: styles.AudioUploader__title },
            !loading && (React.createElement(Icon, { wrapperClassName: styles.AudioUploader__titleIcon, name: isSuccess ? 'farCheckCircle' : 'farTimesCircle', color: isSuccess ? 'success' : 'danger', "data-test-id": `JGraph.AudioUpload.${isSuccess ? 'Success' : 'Error'}` })),
            React.createElement("span", null, fileName)),
        React.createElement("div", { className: styles.AudioUploader__tools },
            url && isValid && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(IconButton, { size: 'sm', name: playing ? 'faStop' : 'faPlay', onClick: () => setPlaying(oldState => !oldState), "data-test-id": 'AudioField.PlayButton' }),
                    React.createElement("audio", { ref: audioRef, src: url, autoPlay: false, onTimeUpdate: () => { var _a; return setTime(((_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.currentTime) || 0); }, 
                        //@ts-ignore
                        onCanPlay: e => setDuration(e.target.duration), onEnded: () => setTime(0), onPause: () => setPlaying(false) })),
                React.createElement("span", { className: cn('ImputerPage_audioTime', playing ? 'ImputerPage_audioTime_playing' : 'ImputerPage_audioTime_stopped') }, formatTime(duration, time)))),
            React.createElement(IconButton, { className: 'audio-uploader__undo-btn', name: 'farUndoAlt', onClick: onClickReload, "data-test-id": 'FAQPage:Answer:Media:EditBtn', disabled: undoDisabled, size: 'sm' }))));
}
export default React.memo(FileInfo);
