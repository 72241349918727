import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Icon } from '../Icon';
import { DropdownMenu, DropdownItem, Dropdown } from '../Dropdown';
import { formatNumberValue } from './utils/utils';
import classNames from 'classnames';
import 'flag-icon-css/css/flag-icon.css';
import './style/phoneNumber.scss';
import { useOnClickOutside } from '../utils';
import parsePhoneNumberFromString from 'libphonenumber-js';
import InputMask from 'react-input-mask';
const PhoneNumber = ({ onChange, phoneNumber, focused, disabled = false, countryList, placeholderSearch, selectFixedPosition, error, }) => {
    const [code, setCode] = useState(phoneNumber.code);
    const [number, setNumber] = useState(phoneNumber.number);
    const [country, setCountry] = useState(phoneNumber.country);
    const countryListRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const onChangeNumber = useCallback(event => {
        setNumber(event.target.value);
    }, [code, country]);
    const onNumberPaste = useCallback(e => {
        const number = parsePhoneNumberFromString(e.clipboardData.getData('Text'));
        if (number === null || number === void 0 ? void 0 : number.country) {
            e.preventDefault();
            const countryByParsedIsoCode = countryList.find(country => number.country === country[0]);
            const phoneCode = countryByParsedIsoCode === null || countryByParsedIsoCode === void 0 ? void 0 : countryByParsedIsoCode[3];
            const phoneNumber = number.number.replace(phoneCode, '').replace('+', '');
            setCountry(number.country.toLowerCase());
            setCode(phoneCode);
            setNumber(phoneNumber);
        }
    }, [code, country]);
    useEffect(() => {
        if (selectFixedPosition) {
            const setPositionTimeout = setTimeout(() => {
                var _a;
                const phoneNumberContainerCoords = (_a = phoneNumberRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (countryListRef.current && phoneNumberContainerCoords) {
                    countryListRef.current.style.position = 'fixed';
                    countryListRef.current.style.top = phoneNumberContainerCoords.y + phoneNumberContainerCoords.height + 'px';
                    countryListRef.current.style.left = phoneNumberContainerCoords.x + 'px';
                    countryListRef.current.style.width = phoneNumberContainerCoords.width + 'px';
                }
            }, 1000);
            return () => clearTimeout(setPositionTimeout);
        }
    }, [selectFixedPosition]);
    useEffect(() => {
        onChange && onChange({ code, number, country });
        return;
    }, [code, number, country, onChange]);
    useEffect(() => {
        const formattedNumberValue = formatNumberValue(code, number, country);
        setNumber(formattedNumberValue);
    }, [code, country]);
    useEffect(() => {
        if (phoneNumber.number === '')
            setNumber('');
    }, [phoneNumber]);
    useEffect(() => {
        setCountry(phoneNumber.country);
    }, [phoneNumber.country]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'phone-container' },
            React.createElement("div", { className: classNames('phone-wrapper', { focused, error }), ref: phoneNumberRef }, code === '7' && country !== 'kz' ? (React.createElement(InputMask, { mask: '(999) 999-99-99', 
                // @ts-ignore
                maskChar: null, onChange: onChangeNumber, onPaste: onNumberPaste, value: number, className: 'phone-input', type: 'tel', disabled: disabled })) : (React.createElement("input", { className: 'phone-input', type: 'tel', id: 'phoneNumber', name: 'phoneNumber', defaultValue: number, value: number, autoFocus: true, onChange: onChangeNumber, onPaste: onNumberPaste, disabled: disabled }))),
            React.createElement(PhoneNavigation, { code: phoneNumber.code, country: phoneNumber.country, countryList: countryList, setNumberCode: setCode, placeholderSearch: placeholderSearch, countryListRef: countryListRef, setCountry: setCountry, disabled: disabled }))));
};
const PhoneNavigation = ({ code, country, countryList, setNumberCode, placeholderSearch, countryListRef, setCountry, disabled = false, }) => {
    const [showChooseCountry, setShowChooseCountry] = useState(false);
    const [isoCode, setIsoCode] = useState('ru');
    useEffect(() => {
        const choosedCountryItem = countryList.find(countryItem => country ? countryItem[0].toLowerCase() === country : countryItem[3] === code);
        if (choosedCountryItem) {
            setIsoCode(choosedCountryItem[0].toLowerCase());
            setCountry(choosedCountryItem[0].toLowerCase());
            setNumberCode(choosedCountryItem[3].toLowerCase());
        }
    }, [code, country]);
    const onToggleShowChooseCountry = useCallback(() => setShowChooseCountry(!showChooseCountry), [showChooseCountry]);
    useOnClickOutside(countryListRef, () => setShowChooseCountry(false));
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: 'phone-nav', onClick: onToggleShowChooseCountry, disabled: disabled, type: 'button' },
            React.createElement("div", { className: 'phone-nav__flag' },
                React.createElement("span", { className: `flag flag-icon flag-icon-${isoCode}` })),
            React.createElement("div", { className: 'phone-nav__btn' },
                React.createElement(Icon, { name: 'faJustSelect' })),
            React.createElement("div", { className: 'phone-nav__code-number' },
                "+",
                code)),
        React.createElement(CountryList, { countryList: countryList, isOpen: showChooseCountry, setIsoCode: setIsoCode, setNumberCode: setNumberCode, placeholderSearch: placeholderSearch, countryListRef: countryListRef, onToggleShowChooseCountry: onToggleShowChooseCountry, setCountry: setCountry })));
};
const CountryList = ({ countryList, isOpen, setIsoCode, setNumberCode, placeholderSearch, countryListRef, onToggleShowChooseCountry, setCountry, }) => {
    const [search, setSearch] = useState('');
    const onSearch = useCallback(event => setSearch(event.target.value), []);
    const filteredCountryList = useMemo(() => {
        if (search.trim().length === 0)
            return countryList;
        const donwcaseSearch = search.toLowerCase();
        return countryList.filter((country) => country[2].includes(donwcaseSearch) || country[3].includes(donwcaseSearch));
    }, [countryList, search]);
    return (React.createElement("div", { ref: countryListRef },
        React.createElement(Dropdown, { isOpen: isOpen, toggle: () => { } },
            React.createElement(DropdownMenu, { className: 'country-list-menu' },
                React.createElement("div", { className: 'country-list-search' },
                    React.createElement("input", { className: 'country-list-search__input', placeholder: placeholderSearch, value: search, onChange: onSearch }),
                    React.createElement("div", { className: 'country-list-search__icon' },
                        React.createElement(Icon, { name: 'faSearch' }))),
                React.createElement("div", { className: 'country-list' }, filteredCountryList.map(country => (React.createElement(CountryItem, { key: `country_${country[0]}`, countryName: country[1], countryISOCode: country[0], countryNumberCode: country[3], setNumberCode: setNumberCode, setIsoCode: setIsoCode, onToggleShowChooseCountry: onToggleShowChooseCountry, setCountry: setCountry }))))))));
};
const CountryItem = ({ countryName, countryISOCode, countryNumberCode, setIsoCode, setNumberCode, onToggleShowChooseCountry, setCountry, }) => {
    return (React.createElement(DropdownItem, { onClick: () => {
            setIsoCode(countryISOCode.toLowerCase());
            setCountry(countryISOCode.toLowerCase());
            setNumberCode(countryNumberCode);
            onToggleShowChooseCountry();
        } },
        React.createElement("div", { className: 'country' },
            React.createElement("div", { className: 'country__flag' },
                React.createElement("span", { className: `flag flag-icon flag-icon-${countryISOCode.toLowerCase()}` })),
            React.createElement("div", { className: 'country__name' }, countryName),
            React.createElement("div", { className: 'country__code' },
                "+",
                countryNumberCode))));
};
export default React.memo(PhoneNumber);
