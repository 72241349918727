import React, { useMemo } from 'react';
import { Modal, useTranslation } from '@just-ai/just-ui';
import classes from './styles.module.scss';
var DeleteModal = function (_a) {
    var isOpen = _a.isOpen, close = _a.close, handleDelete = _a.handleDelete, deleteItem = _a.deleteItem;
    var t = useTranslation().t;
    var _b = useMemo(function () {
        var _a;
        var folderNodes = deleteItem === null || deleteItem === void 0 ? void 0 : deleteItem.filter(function (item) { return item === null || item === void 0 ? void 0 : item.isFolder; });
        var groupCount = (folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.length) || 0;
        var groupsQuestionsCount = (folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.reduce(function (acc, node) { return acc + node.children.length; }, 0)) || 0;
        var questionsCount = ((_a = deleteItem === null || deleteItem === void 0 ? void 0 : deleteItem.filter(function (item) { return !(item === null || item === void 0 ? void 0 : item.isFolder) && ((item === null || item === void 0 ? void 0 : item.isInRoot) || !(folderNodes === null || folderNodes === void 0 ? void 0 : folderNodes.find(function (folderNode) { return folderNode.path === item.parent; }))); })) === null || _a === void 0 ? void 0 : _a.length) || 0;
        var messages = [];
        if (questionsCount === 1 && !groupCount) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteQuestion');
        }
        else if (questionsCount > 1 && !groupCount) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteQuestions');
            messages[1] = t('FAQ:deleteModal:Message:DeleteQuestionsSelected $[1]', questionsCount) + '.';
        }
        else if (!questionsCount && groupCount === 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroup');
            messages[1] = t('FAQ:deleteModal:Message:DeleteQuestionsInGroupsSelected $[1]', groupsQuestionsCount) + '.';
        }
        else if (!questionsCount && groupCount > 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroups');
            messages[1] = t('FAQ:deleteModal:Message:DeleteGroupsSelected $[1]', groupCount) + '.';
            messages[2] = t('FAQ:deleteModal:Message:DeleteQuestionsInGroupSelected $[1]', groupsQuestionsCount) + '.';
        }
        else if (questionsCount >= 1 && groupCount >= 1) {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroupsAndQuestions');
            messages[1] = t('FAQ:deleteModal:Message:DeleteGroupsSelected $[1]', groupCount);
            messages[1] += " (".concat(t('FAQ:deleteModal:Message:DeleteQuestionsInGroupSelected $[1]', groupsQuestionsCount).toLowerCase(), ").");
            messages[2] = t('FAQ:deleteModal:Message:DeleteUngroupedQuestionsSelected $[1]', questionsCount) + '.';
        }
        else {
            messages[0] = t('FAQ:deleteModal:Message:DeleteGroupsAndQuestions');
        }
        var title = t(groupCount > 0 ? 'FAQ:deleteModal:Title:DeleteGroupsAndQuestions' : 'FAQ:deleteModal:Title:DeleteQuestions');
        return [messages, title];
    }, [deleteItem, t]), modalContent = _b[0], modalTitle = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, title: modalTitle, onCancelClick: close, onActionClick: handleDelete, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: t('FAQ:deleteModal:Button:Delete'), buttonSubmitColor: 'danger', centered: true },
            React.createElement("div", { className: classes.deleteModal__content }, modalContent.map(function (content, index) { return (React.createElement("div", { key: index }, content)); })))));
};
export function DeleteSkillModal(_a) {
    var isOpen = _a.isOpen, close = _a.close, handleDelete = _a.handleDelete, deleteItem = _a.deleteItem;
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isOpen, title: t('FAQ:DeleteSkill'), onCancelClick: close, onActionClick: handleDelete, buttonCancelText: t('FAQ:deleteModal:Button:Cancel'), buttonSubmitText: t('FAQ:deleteModal:Button:Delete'), buttonSubmitColor: 'danger', centered: true },
            React.createElement("div", { className: classes.deleteModal__content }, t('FAQ:DeleteSkill:prompt', deleteItem)))));
}
export default React.memo(DeleteModal);
