var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { useFileLoad, botAudioFileValidation, getFileInfo } from './audioHelpers';
import { FileUpload } from '../FileUpload';
import { useTranslation } from '../contexts/transtationContext';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import SupportAudioChannels from './SupportAudioChannels';
import FileInfo from './FileInfo';
import styles from './styles.module.scss';
import './styles.scss';
function AudioField({ onChange, hasError, fileName: originalFileName, url: originalUrl, saveAudioToServer, onPartEdit, disabled, }) {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const abortController = useRef(null);
    const [{ loading, file, fileName, errors, url, duration, uploadError }, stateDispatch] = useFileLoad({
        loading: false,
        file: null,
        fileName: originalFileName,
        url: originalUrl,
        duration: 0,
    });
    useEffect(() => () => { var _a; return (_a = abortController.current) === null || _a === void 0 ? void 0 : _a.abort(); }, []);
    useEffect(() => {
        if (originalUrl) {
            stateDispatch({ type: 'setUploadedUrlAction', url: originalUrl, fileName: originalFileName });
        }
    }, [originalUrl, originalFileName, stateDispatch]);
    const onDragEnter = useCallback(() => {
        var _a;
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.classList.add('onDrag');
    }, []);
    const onDragExit = useCallback(() => {
        var _a;
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove('onDrag');
    }, []);
    const handleFile = useCallback((file) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const fileInfo = yield getFileInfo(file);
        const { errors } = yield botAudioFileValidation({ file, fileInfo });
        const isValid = isEmpty(errors);
        stateDispatch({
            type: 'setErrorsAction',
            errors,
            duration,
            isValid,
        });
        if (!isValid && onChange) {
            onChange();
            return;
        }
        (_a = abortController.current) === null || _a === void 0 ? void 0 : _a.abort();
        abortController.current = new AbortController();
        try {
            const url = yield saveAudioToServer(file, { signal: abortController.current.signal });
            stateDispatch({ type: 'setUploadedUrlAction', url });
            onChange && onChange({ url, name: file.name });
        }
        catch (e) {
            console.error(e);
            stateDispatch({ type: 'setUploadError' });
            onChange && onChange();
        }
    }), [duration, onChange, saveAudioToServer, stateDispatch]);
    const onSetFile = useCallback((files) => {
        onDragExit();
        stateDispatch({ type: 'setFileAction', file: files[0] });
        handleFile(files[0]);
    }, [onDragExit, stateDispatch, handleFile]);
    const isValid = useMemo(() => isEmpty(errors), [errors]);
    const fileIsLoaded = (file || url) && !loading;
    return (React.createElement("div", { className: styles.AudioUploader, ref: containerRef, onDragOverCapture: onDragEnter, onDragLeaveCapture: onDragExit, onDragEndCapture: onDragExit, onDragExitCapture: onDragExit, style: { position: 'relative' } },
        fileIsLoaded && (React.createElement(React.Fragment, null,
            React.createElement(FileInfo, { fileName: fileName, url: url, isValid: isValid, uploadError: uploadError, duration: duration, loading: loading, containerRef: containerRef, undoHandler: onPartEdit, undoDisabled: disabled }),
            errors && !isEmpty(errors) && (React.createElement("div", { className: 'margin-top-2x flex-column gap-1x', style: { color: 'var(--red-600)' } }, Object.entries(errors).map(([, err]) => (React.createElement("span", { key: err.message }, t(err.message)))))),
            isValid && url && React.createElement(SupportAudioChannels, { className: 'margin-top-2x', fileUrl: url }))),
        React.createElement(FileUpload, { className: cn(styles.uploadBox, {
                [styles.hasError]: hasError,
                [styles.hideLoader]: fileIsLoaded,
            }), loadingType: 'progressBar', iconSize: '2x', onChange: onSetFile, loading: loading, accept: '.wav', "data-test-id": 'AudioField.FileUploader' },
            React.createElement("span", null,
                React.createElement("span", { className: styles.uploadLink }, t('AudioSettings:UploadLabelFirst')),
                t('AudioSettings:UploadLabelSecond')))));
}
export const AudioFieldMemo = React.memo(AudioField);
