import React from 'react';
import { Icon } from "../Icon";
import classNames from "classnames";
import './Message.scss';
const IconByMessageType = {
    'success': 'faCheckSquare',
    'info': 'faInfoCircle',
    'danger': 'faExclamationCircle',
    'warning': 'faExclamationTriangle',
};
export const Message = ({ type, text = null }) => {
    const TextComponent = typeof text === 'string' ? () => React.createElement(React.Fragment, null, text) : text;
    return React.createElement("div", { className: classNames('Message', type) },
        React.createElement("div", { className: 'Message-icon' },
            React.createElement(Icon, { className: 'justui_announcement-icon-type', name: IconByMessageType[type], color: type })),
        React.createElement("div", { className: 'Message-text' },
            React.createElement(TextComponent, null)));
};
