import React from 'react';
import { Modal, Spinner, useTranslation } from '@just-ai/just-ui';
import { useLoading } from '../../../../nlu-related/utils';
var DeleteSkillModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onDelete = _a.onDelete, skill = _a.skill;
    var _b = useLoading(), isLoading = _b[0], load = _b[1];
    var t = useTranslation().t;
    return (React.createElement(Modal, { isOpen: isOpen, title: t("FAQ:Home:DeleteModal:Title"), buttonSubmitColor: 'danger', buttonSubmitText: t("Delete"), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: onClose, onActionClick: function () { return load(onDelete()).finally(function () { return onClose(); }); } },
        isLoading && React.createElement(Spinner, { size: 'sm' }),
        React.createElement("div", { className: 'margin-bottom-2x', style: { color: 'var(--gray-800)' } }, t('FAQ:Home:DeleteModal:Content $[1]', skill.name))));
};
export default React.memo(DeleteSkillModal);
