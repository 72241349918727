var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useReducer } from 'react';
import { parseBlob } from 'music-metadata-browser';
import { last } from 'lodash';
export function getFileInfo(file) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { format } = yield parseBlob(file);
            return format;
        }
        catch (e) {
            return;
        }
    });
}
const tenMbInBites = 10485760;
const acceptedImageTypes = ['audio/mpeg', 'audio/wave', 'audio/wav', 'audio/x-wav', 'audio/x-pn-wav'];
export function buildCustomValidationResolver(fn) {
    return function validationResolver(values, context) {
        return __awaiter(this, void 0, void 0, function* () {
            const errors = yield fn(values, context);
            if (Object.keys(errors).length === 0)
                return { values, errors: {}, isValid: true };
            return {
                values: {},
                isValid: false,
                errors: Object.fromEntries(Object.entries(errors).map(([key, error]) => {
                    return [key, { message: error, type: 'custom' }];
                })),
            };
        });
    };
}
export const botAudioFileValidation = buildCustomValidationResolver(({ fileInfo, file }) => __awaiter(void 0, void 0, void 0, function* () {
    let errors = {};
    const fileExt = last(file.name.split('.'));
    if (!fileExt || acceptedImageTypes.indexOf(file.type) === -1) {
        errors.fileExt = 'AudioUploadField:Error:InvalidFileExtension';
        return errors;
    }
    if (file.size > tenMbInBites) {
        errors.file = 'AudioUploadField:Error:FileSize';
    }
    if (!fileInfo) {
        errors.file = 'AudioUploadField:Error:UnexpectedError';
        return errors;
    }
    return errors;
}));
function stateReducer(state, action) {
    switch (action.type) {
        case 'clearAction':
            return { file: null, duration: 0, loading: false, uploadError: false };
        case 'setFileAction':
            return Object.assign(Object.assign({}, state), { file: action.file, fileName: action.file.name, loading: true });
        case 'setErrorsAction':
            return Object.assign(Object.assign({}, state), { errors: action.errors, duration: action.duration, loading: action.isValid });
        case 'setUploadedUrlAction':
            return Object.assign(Object.assign({}, state), { url: action.url, fileName: action.fileName || '', loading: false, uploadError: false });
        case 'setUploadError':
            return Object.assign(Object.assign({}, state), { loading: false, uploadError: true });
        default:
            return state;
    }
}
export function useFileLoad(initState) {
    return useReducer(stateReducer, initState);
}
export function formatTime(duration = 0, time = 0) {
    let diff = duration - time;
    if (diff < 0)
        diff = 0;
    const left = Math.floor(diff / 60);
    const right = Math.round(diff % 60);
    return `${left > 9 ? left : '0' + left}:${right > 9 ? right : '0' + right}`;
}
