import React, { useCallback, useRef, useState } from 'react';
import { Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
export default function InputComponent(props) {
    var initialValue = props.initialValue, tabsNames = props.tabsNames, handleTabBlur = props.handleTabBlur, className = props.className;
    var _a = useState(function () { return initialValue.split('.').splice(1).join('.'); }), skillNameNoPrefix = _a[0], setSkillNameNoPrefix = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var inputRef = useRef(null);
    var t = useTranslation().t;
    var handleBlur = useCallback(function (e) {
        var trimmedNameValue = skillNameNoPrefix.trim();
        if (tabsNames.includes("FAQ.".concat(trimmedNameValue)) && initialValue !== "FAQ.".concat(trimmedNameValue)) {
            var newContextMenu = document.getElementById('tabContextMenu');
            if (!newContextMenu || !e.relatedTarget || !newContextMenu.contains(e.relatedTarget)) {
                setError(t('FAQ:RenameSkill:existingName'));
                return e.target.focus();
            }
            handleTabBlur(initialValue, initialValue);
            return e.relatedTarget.click();
        }
        return handleTabBlur(initialValue, 'FAQ.' + (trimmedNameValue || initialValue.split('.').splice(1).join('.')));
    }, [handleTabBlur, initialValue, tabsNames, skillNameNoPrefix]);
    var handleInput = useCallback(function (e) {
        setError('');
        setSkillNameNoPrefix(e.target.value);
    }, []);
    var handleEnterPress = useCallback(function (e) {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);
    var stopPropagator = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'tabNameInputWrapper', className: cn('faq-tab-name-input__wrapper', className), onContextMenu: stopPropagator, onClick: stopPropagator },
            React.createElement("p", null, "FAQ."),
            React.createElement("input", { onContextMenu: stopPropagator, onKeyDown: handleEnterPress, autoFocus: true, placeholder: t('Description'), ref: inputRef, onBlur: handleBlur, value: skillNameNoPrefix, onChange: handleInput })),
        error && (React.createElement(Tooltip, { isOpen: true, 
            //@ts-ignore
            onContextMenu: stopPropagator, onClick: stopPropagator, className: 'tooltip--error', autohide: false, target: 'tabNameInputWrapper' }, error))));
}
