import React from 'react';
import memoize from 'memoizerific';
import ResizeObserver from 'react-resize-observer';
import VirtualList from 'react-tiny-virtual-list';
import { Branch } from './Branch';
const getItemHeight = (item, expanded, dataset, itemHeight) => {
    if (!expanded[item.nodeId] || !item.children)
        return itemHeight;
    return itemHeight + item.children.reduce((acc, childId) => acc + getItemHeight(dataset[childId], expanded, dataset, itemHeight), 0);
};
export const getMainBranches = memoize(1)((disableVirtualList) => {
    return disableVirtualList
        ? props => React.createElement(SimpleMainBranches, Object.assign({}, props))
        : props => React.createElement(VirtualMainBranches, Object.assign({}, props));
});
const SimpleMainBranches = (props) => (React.createElement(React.Fragment, null, props.mains.map(node => (React.createElement(Branch, { key: node.nodeId, depth: 0, root: node.nodeId, itemHeight: props.itemHeight, dataset: props.filteredDataset, selected: props.selected, expanded: props.expanded, editId: props.editId, Head: props.Head, Leaf: props.Leaf, Edit: props.Edit, InnerContainer: props.InnerContainer })))));
const VirtualMainBranches = (props) => (React.createElement(React.Fragment, null,
    React.createElement(VirtualList, { height: props.treeHeight, width: '100%', itemCount: props.mains.length, itemSize: index => getItemHeight(props.mains[index], props.expanded, props.filteredDataset, props.itemHeight), renderItem: ({ index, style }) => React.createElement(Branch, { key: props.mains[index].nodeId, style: style, depth: 0, itemHeight: props.itemHeight, dataset: props.filteredDataset, selected: props.selected, expanded: props.expanded, root: props.mains[index].nodeId, editId: props.editId, Head: props.Head, Leaf: props.Leaf, Edit: props.Edit, InnerContainer: props.InnerContainer }) }),
    React.createElement(ResizeObserver, { onResize: props.onResize })));
