import React, { createContext, useContext, Component } from 'react';
import { History } from 'history';
import { forOwn } from 'lodash';
import keyboardJS from 'keyboardjs';
import { Ace } from 'ace-builds';

import { AppContext, AppContextType } from '../../Caila/components/AppContext';
import StorageService, { EditorValidationResult } from '../service/StorageService';
import FileTree, { FileTreeDataset } from '../model/FileTree';
import { getErrorCodeFromReason, getErrorMessageFromReason, UNKNOWN_ERROR_MESSAGE_CODE } from '../../Caila/utils';
import { Spinner, Tooltip } from '@just-ai/just-ui';
import FileContent from '../model/FileContent';
import LocalStorageService, { EditorSettings } from '../service/LocalStorageService';
import ReactAce from 'react-ace/lib/ace';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { i18nTranslation } from '../../Caila/locale/i18nToLocalize';
import GraphService from '../service/GraphService';
import ProjectService from '../service/ProjectService';
import {
  ConflictResolutionType,
  FileData,
  CustomTagData,
  GraphV2UpdateItemData,
  GraphV2UpdateActionType,
  ParseError,
  State,
  ReplaceInfo,
} from '../api/client';
import VisualLocalStorageService, {
  VisualEditorSettings,
  VisualEditorStageFileSettings,
} from '../service/VisualLocalStorageService';
import FileNode from '../model/FileNode';
import FolderNode from '../model/FolderNode';
import { FileTabs, FileTabsHelper, EditorCursorPosition } from '../model/FileTabs';
import * as routes from './routes';
import { Graph } from '../model/Graph';
import { TestWidgetContextType } from 'modules/TestWidget/context/TestWidgetContext';
import IntentsService from 'modules/Caila/service/IntentsService';
import { GutterMarkerWidget } from '../widgets/GutterMarkerWidget';
import SearchService from '../service/SearchService';
import { Range } from 'ace-builds/src-noconflict/ace';
import { FilesRanges } from '../model/FilesRanges';
import { WSContextType } from '../../Notifications/context/WSContext';
import { t } from 'localization';
import { IAceEditor } from 'react-ace/lib/types';
import isAccess from 'isAccessFunction';
import { AppLogger } from 'services/AppLogger';

import { AceFormatBehaviour } from '../service/AceFormatBehaviour';
import { FormattedLanguages } from '../../../services/Formatter';

export type EditorMode = 'editor' | 'visual-editor';

const SC_FILE_TEMPLATE = 'theme: /\n';
export const DEPENDENCY_FILE_PREFIX = 'DEPENDENCY';
export const SYSTEM_DEPENDENCY_FOLDER = '/sys';

export const CHANGE_FILE_EVENT_CODE = 'FILE_CHANGED';
export const CHANGE_PROJECT_EVENT_CODE = 'PROJECT_CHANGED';

type PromiseListener = () => Promise<unknown>;

const DEBOUNCE = 1000;
const TRY_TO_SYNC_INTERVAL = 3000;
const SAVING_INDICATOR_TIMEOUT = 5000;

const isString = (value?: string): value is string => {
  return typeof value === 'string';
};

export type EditorContextType = {
  fileTreeData: FileTreeDataset;
  fileTree: FileTree;
  depsTreeData: FileTreeDataset;
  currentFilePath?: string;
  selectedFileId?: string;
  setSelectedFileId: (fileId: string) => void;
  selectFileId: (fileId?: string, preventOpen?: boolean, position?: EditorCursorPosition) => Promise<void>;
  temporaryFileToSave: FileContent | null;

  fileTabs: FileTabs;
  fileTabsData: string[];
  closeFileTab: (fileId: string) => void;

  renameFile: (fileId: string, newFileId: string, isFolder: boolean) => Promise<unknown>;
  deleteFile: (fileId: string) => Promise<unknown>;
  deleteFolder: (folderId: string) => Promise<unknown>;
  deleteTemporaryFile: () => void;
  createFile: (fileId: string) => Promise<FileNode>;
  createFolder: (fileId: string) => Promise<FileNode>;
  copyFile: (file: FileNode, newFileId: string) => Promise<unknown>;
  copyFolder: (folder: FolderNode, newFolderId: string) => Promise<unknown>;

  validate: () => void;
  format: () => void;

  currentSettings: EditorSettings;
  saveSettings: (settings: EditorSettings) => void;

  save: () => Promise<unknown>;
  saveTemporaryFile: () => Promise<unknown>;
  debounceSaveFile: () => Promise<void>;

  openSearch: () => void;
  openFileWithoutSave: (fileId: string, mode: EditorMode) => Promise<unknown>;

  editorRef: React.MutableRefObject<ReactAce | null>;
  commit: (message: string) => Promise<unknown>;
  discard: () => Promise<unknown>;
  pull: () => Promise<unknown>;
  resolve: (message: string, resolution: ConflictResolutionType) => Promise<unknown>;
  resizeEditor: () => void;

  editorMode: EditorMode;

  setEditorMode: (mode: EditorMode) => void;
  showError: (reason: any) => void;

  saveVisualSettings: (settings: VisualEditorSettings) => void;
  currentVisualSettings: VisualEditorSettings;

  saveVisualStageFileSettings: (settings: VisualEditorStageFileSettings) => void;
  currentVisualStageFileSettings: VisualEditorStageFileSettings;

  currentFile?: FileContent;
  currentGraph?: Graph;

  load: LoadCallback;

  hasChanges: boolean;
  setHasChanges: () => void;

  customTags: CustomTagData[];
  graphService: GraphService;
  searchService: SearchService;
  localStorageService: LocalStorageService;
  savingPromise?: Promise<unknown>;

  setGraph: (graph: Graph) => void;
  addActions: (actions: GraphV2UpdateItemData[]) => Promise<unknown>;
  refreshTree: () => void;

  isFileModifiedInProgress: boolean;

  parseErrors: ParseError[];

  updateDeps: () => void;
  refreshEditorAfterReplaceAll: (replaceInfo: ReplaceInfo[]) => void;

  setOutlinedRanges: (ranges: FilesRanges) => void;
  clearCurrentFileRanges: () => void;
  highlightPosition: (position: [EditorCursorPosition, EditorCursorPosition]) => void;

  replace: (position: [EditorCursorPosition, EditorCursorPosition], replaceValue: string) => void;

  isFilesLoading: boolean;
  isDepsLoading: boolean;
  isOnline: boolean;

  states: State[];
  intentNames: string[];
  intentGroups: string[];
};

export const EditorContext = createContext({} as EditorContextType);

class EditorContextState {
  isLoading: boolean = false;
  isOnline: boolean = true;
  isFilesLoading: boolean = false;
  isDepsLoading: boolean = false;
  fileTreeData: FileTreeDataset = {};
  depsTreeData: FileTreeDataset = {};
  systemDepsTreeData: FileTreeDataset = {};
  selectedFileId?: string;

  currentFilePath?: string;
  currentFile?: FileContent;
  currentGraph?: Graph;
  editorMode: EditorMode = 'editor';

  currentSettings: EditorSettings = {};
  currentVisualSettings: VisualEditorSettings = new VisualEditorSettings();
  currentVisualStageFileSettings: VisualEditorStageFileSettings = new VisualEditorStageFileSettings();
  fileTabs: FileTabs = {};

  hasChanges: boolean = false;
  isFileModifiedInProgress: boolean = false;

  parseErrors: ParseError[] = [];
  states: State[] = [];
  intentNames: string[] = [];
  intentGroups: string[] = [];

  customTags: CustomTagData[] = [];
  temporaryFileToSave: FileContent | null = null;

  gutterMarkerTooltipText?: string;
}

export type LoadCallback = <TResult>(promise: Promise<TResult>) => Promise<TResult>;

type Props = {
  location?: History<{ deploy?: boolean; isNewProject?: boolean }>['location'];
  widgetContext: TestWidgetContextType;
  addAlert: (notification: AlertNotificationItemProps) => void;
  dismissAlert: (time: number) => void;
  WSContext: WSContextType;
  fileEventDispatcher: { subscriber?: (message: any) => void };
};

const GUTTER_TOOLTIP_TARGET_ID = 'tooltipTargetId';

const ACE_FORMAT_ERROR_MARKER_CLASS = 'ace_format_error-marker';
const FORMAT_CODE_KEY_COMBO = ['ctrl + alt > r', 'command + alt > r'];

export class EditorContextProvider extends Component<Props, EditorContextState> {
  static contextType = AppContext;
  context!: AppContextType;

  private aceFormatBehaviour = new AceFormatBehaviour();

  gutterMarkerWidget?: GutterMarkerWidget;
  storageService: StorageService = new StorageService(NaN, '');
  graphService: GraphService = new GraphService();
  projectService: ProjectService = new ProjectService(NaN, '');
  intentsService: IntentsService = new IntentsService(NaN, '');
  searchService: SearchService = new SearchService(NaN, '');
  localStorageService: LocalStorageService = new LocalStorageService(NaN, '');
  visualLocalStorageService: VisualLocalStorageService = new VisualLocalStorageService(NaN, '');
  fileTabsHelper: FileTabsHelper = new FileTabsHelper(this.localStorageService);
  eventsSubscriptionId?: string;

  editorRef = React.createRef<ReactAce>();
  actions: GraphV2UpdateItemData[] = [];

  saveTimeout?: NodeJS.Timeout;
  syncInterval?: NodeJS.Timer;
  savingIndicatorTimeout?: NodeJS.Timeout;
  save: PromiseListener = () => Promise.resolve();
  savingPromise?: Promise<unknown>;

  cursorPositions: { [key: string]: EditorCursorPosition } = {};
  outlinedRanges: FilesRanges = {};
  silentOnChangeEvent: boolean = true;
  mounted: boolean = true;

  state = new EditorContextState();

  setFileTreeData = (fileTreeData: FileTreeDataset) => {
    this.setState({ fileTreeData });
  };

  setDepsTreeData = (depsTreeData: FileTreeDataset) => {
    this.setState({ depsTreeData });
  };

  setCustomTags = (customTags: CustomTagData[]) => {
    this.setState({ customTags });
  };

  setIntentNames = ({ intentNames, intentGroups }: { intentNames: string[]; intentGroups: string[] }) => {
    this.setState({ intentNames, intentGroups });
  };

  fileTree: FileTree = new FileTree();
  depsTree: FileTree = new FileTree();
  systemDepsTree: FileTree = new FileTree();

  componentDidMount() {
    const { accountId, projectShortName } = this.context;

    this.storageService = new StorageService(accountId, projectShortName);
    this.projectService = new ProjectService(accountId, projectShortName);
    this.intentsService = new IntentsService(accountId, projectShortName);
    this.localStorageService = new LocalStorageService(accountId, projectShortName);
    this.searchService = new SearchService(accountId, projectShortName);
    this.visualLocalStorageService = new VisualLocalStorageService(
      this.context.accountId,
      this.context.projectShortName
    );
    this.fileTabsHelper = new FileTabsHelper(this.localStorageService);
    this.cursorPositions = this.localStorageService.getCursorPositions() || {};
    const settings = this.localStorageService.getSettings();
    if (settings) this.setState({ currentSettings: settings });

    const visualSettings = this.visualLocalStorageService.getSettings();
    if (visualSettings) this.setState({ currentVisualSettings: visualSettings });

    this.projectService.getCustomTags().then(this.setCustomTags);
    isAccess(['nlu', 'NLU_READ']) && this.intentsService.getIntentNamesAndGroups().then(this.setIntentNames);

    this.initializeEditor();
    if (this.props.location?.state?.deploy) {
      this.save().then(() => this.props.widgetContext.showTestWidget());
    }
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (this.state.hasChanges && this.state.isOnline) {
        const modalText = t('Editor:onPageClose');
        e.returnValue = modalText;
        return modalText;
      }
    };
    //TODO: delete shitty code when Editor Team fix signal and add handling of subscription id on unsubscribe event
    this.props.fileEventDispatcher.subscriber = this.handleUpdateFileEvent;

    keyboardJS.bind(FORMAT_CODE_KEY_COMBO, this.format);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.savingPromise) this.savingPromise = undefined;
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    window.onbeforeunload = null;
    //TODO: delete shitty code when Editor Team fixs signal and add handling of subscription id on unsubscribe event
    if (this.props.fileEventDispatcher.subscriber) this.props.fileEventDispatcher.subscriber = undefined;
    if (this.syncInterval) clearInterval(this.syncInterval);
    keyboardJS.unbind(FORMAT_CODE_KEY_COMBO, this.format);
    this.editorRef.current?.editor.off('change', this.onCodeChange);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const fileIdFromUrl = routes.getFileId();
    const mode = routes.getMode();
    if (routes.isRefreshEditor()) {
      this.initializeEditor();
      return;
    }

    if (!fileIdFromUrl) {
      if (this.state.currentFilePath) this.setEmptyFile();
      return;
    }

    if (
      this.props.widgetContext?.isTestWidgetShown !== prevProps.widgetContext?.isTestWidgetShown ||
      this.props.widgetContext?.isTestTtsWidgetShown !== prevProps.widgetContext?.isTestTtsWidgetShown
    ) {
      this.resizeEditor();
    }

    if (mode === this.state.editorMode && fileIdFromUrl === this.state.currentFilePath) return;
    this.setCurrentFile(fileIdFromUrl, mode);
  }

  initCodeEditor = (file: FileContent, position?: EditorCursorPosition) => {
    const editor = this.editorRef.current?.editor;
    if (!editor) return;

    this.silentOnChangeEvent = true;
    editor?.setValue(file.content);
    this.silentOnChangeEvent = false;
    if (file.path && this.outlinedRanges[file.path]) this.outlinePositions(this.outlinedRanges[file.path]);
    const newPosition = position || this.cursorPositions[file.path];
    if (newPosition) {
      this.scrollToPosition(newPosition);
    } else {
      editor?.navigateFileStart();
    }
    editor?.focus();
    editor?.session.getUndoManager().reset();

    this.enableGutterMarkers(editor);

    editor.on('change', this.onCodeChange);

    this.aceFormatBehaviour.init(editor);
  };

  enableGutterMarkers(editor: IAceEditor) {
    if (this.gutterMarkerWidget) {
      this.gutterMarkerWidget.deactivate();
    }
    this.gutterMarkerWidget = new GutterMarkerWidget(editor, GUTTER_TOOLTIP_TARGET_ID);

    const hotkey = navigator.platform.includes('Mac') ? 'cmd' : 'ctrl';

    this.gutterMarkerWidget.addMarker({
      className: 'adjustVoicePlayButton',
      condition: lineText => /(\s|)a:/.test(lineText),
      onClick: this.props.widgetContext.showTestTtsWidget,
      tooltipHandler: ({ isOpen }) => {
        this.setState({
          gutterMarkerTooltipText: isOpen ? t(`EditorContext:hotkey for tts widget ${hotkey}`) : undefined,
        });
      },
    });

    this.gutterMarkerWidget.apply();
  }

  load: LoadCallback = promise => {
    this.setState({ isLoading: true });
    return promise.finally(() => this.setState({ isLoading: false }));
  };

  loadFiles: LoadCallback = promise => {
    this.setState({ isFilesLoading: true });
    return promise.finally(() => this.setState({ isFilesLoading: false }));
  };

  loadDeps: LoadCallback = promise => {
    this.setState({ isDepsLoading: true });
    return promise.finally(() => this.setState({ isDepsLoading: false }));
  };

  showError = (reason: any) => {
    if (!reason) return;
    const { t } = i18nTranslation();
    this.props.addAlert({
      type: 'error',
      title: t('Errors:error'),
      message: getErrorMessageFromReason(reason, t),
      time: Date.now(),
      showed: true,
    });
  };

  showOfflineError = () => {
    const { t } = i18nTranslation();
    this.props.addAlert({
      type: 'error',
      title: t('Editor:connectionErrorTitle'),
      message: t('Editor:connectionErrorMessage'),
      time: Date.now(),
      showed: true,
    });
  };

  initializeEditor = () => {
    routes.clearRefresh(this.props.location?.state);
    const savedFiles = this.localStorageService.getOpenFiles();

    if (savedFiles?.fileTabs) {
      this.setState({ fileTabs: savedFiles.fileTabs });
    }

    this.fetchFiles()
      .then(([files, mainFile]) => {
        const fileIdFromUrl = routes.getFileId();
        const mode = routes.getMode();

        if (fileIdFromUrl) {
          this.openFile(fileIdFromUrl, mode);
          return;
        }

        if (files && files.length > 0 && this.props.location?.state?.isNewProject) {
          const fileName = this.findFileToOpenInNewProj(files, mainFile);
          if (fileName) {
            this.openFile(fileName);
            return;
          }
        }
        if (mainFile) {
          this.openFile(mainFile);
          return;
        }
        if (savedFiles?.currentFile) {
          this.openFile(savedFiles.currentFile);
          return;
        }
      })
      .then(() => {
        this.syncFileChanges(true);
      })
      .catch(this.showError);
  };

  findFileToOpenInNewProj = (files: FileData[], mainFile?: string) => {
    const readmeFile = files.find(
      file => file.name === '/README.md' && !FileTree.isBlacklisted(file.name.split('/')[1])
    );
    return readmeFile?.name || mainFile || this.getFirstFileInRoot(files)?.name;
  };

  getFirstFileInRoot = (files: FileData[]) =>
    files.find(
      file => file.name && file.name.split('/').length === 2 && !FileTree.isBlacklisted(file.name.split('/')[1])
    );

  hasSomeNonLayoutActions = () => this.actions.some(action => action.action !== GraphV2UpdateActionType.UpdateLayout);

  addActions = (actions: GraphV2UpdateItemData[]) => {
    if (actions.length === 0) return Promise.resolve();
    this.setHasChanges();
    this.actions.push(...actions);
    if (this.hasSomeNonLayoutActions()) {
      return this.saveGraph();
    } else {
      return this.debounceSaveGraph();
    }
  };

  resizeEditor = () => {
    this.editorRef.current?.editor?.resize();
  };

  debounceSaveGraph = () => {
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    this.saveTimeout = setTimeout(() => this.saveGraph(), DEBOUNCE);
    return Promise.reject();
  };

  debounceSaveFile = () => {
    if (this.silentOnChangeEvent) return Promise.resolve();
    if (this.saveTimeout) clearTimeout(this.saveTimeout);
    if (this.isFileChanged()) this.setHasChanges();
    this.saveTimeout = setTimeout(() => this.checkAndSaveFile(), DEBOUNCE);
    return Promise.reject();
  };

  saveTemporaryFile = () => {
    const { temporaryFileToSave } = this.state;
    if (!temporaryFileToSave) return Promise.resolve();
    return this.saveFile(temporaryFileToSave, true);
  };

  showSaveError = (reason: any, filePath: string) => {
    if (reason?.response?.data?.error !== 'editorbe.storage.file_modified_on_server') {
      if (getErrorCodeFromReason(reason) === UNKNOWN_ERROR_MESSAGE_CODE) {
        this.showError('editorbe.storage.cannot_save_file');
      } else this.showError(reason);

      return Promise.reject();
    }

    const alert = this.createFileModifiedAlert(filePath);
    this.props.addAlert(alert);
    this.setState({ isFileModifiedInProgress: true });

    return Promise.reject();
  };

  createFileModifiedAlert = (filePath: string): AlertNotificationItemProps => {
    const { t } = i18nTranslation();
    const date = Date.now();
    return {
      type: 'error',
      title: t('Editor:conflictResolutionNotificationTitle', {
        name: filePath.substring(filePath.lastIndexOf('/') + 1),
      }),
      message: t('Editor:conflictResolutionNotificationMessage'),
      time: date,
      showed: true,
      disableCopyButton: true,
      buttons: [
        {
          buttonAction: () => this.fileModifiedOverwriteAction(filePath, date),
          buttonText: t('Editor:conflictResolutionOverwrite'),
          buttonFill: true,
        },
        {
          buttonAction: () => this.fileModifiedResetAction(filePath, date),
          buttonText: t('Editor:conflictResolutionReset'),
        },
      ],
    };
  };

  fileModifiedResetAction = (filePath: string, date: number) => {
    if (filePath !== this.state.currentFilePath) {
      this.props.dismissAlert(date);
      this.setState({ isFileModifiedInProgress: false });
      return;
    }

    this.openFileWithoutSave(this.state.currentFilePath, this.state.editorMode).then(() => {
      this.props.dismissAlert(date);
      this.setState({ isFileModifiedInProgress: false });
    });
  };

  fileModifiedOverwriteAction = (filePath: string, date: number) => {
    if (filePath !== this.state.currentFilePath) {
      this.props.dismissAlert(date);
      this.setState({ isFileModifiedInProgress: false });
      return;
    }

    this.forceSaveCurrentFile()
      .then(() => {
        this.setState({ isFileModifiedInProgress: false });
      })
      .finally(() => {
        this.props.dismissAlert(date);
      });
  };

  forceSaveCurrentFile = () => {
    if (!this.state.currentFilePath) return Promise.resolve();
    if (this.state.editorMode === 'editor') {
      if (!this.state.currentFile) return Promise.resolve();
      return this.load(this.checkAndSaveFile(true));
    } else {
      if (!this.state.currentGraph) return Promise.resolve();
      return this.load(this.saveGraph(true));
    }
  };

  getLastModifiedNN = (fileId: string) => {
    return this.fileTree.getFileById(fileId)?.lastModified || 0;
  };

  handleUpdateFileEvent = (
    data: {
      accountId: number;
      code: { code: string };
      data: { file: string };
      projectId: string;
      file: string;
      timestamp: number;
    }[]
  ) => {
    if (!Array.isArray(data)) return;
    try {
      const fileChangeEvents = data.filter(({ code: { code } }) => code === CHANGE_FILE_EVENT_CODE);
      let needToRefetchTree = false;

      fileChangeEvents.forEach(({ data: { file }, code: { code }, timestamp, accountId, projectId }) => {
        if (this.context.accountId === accountId && this.context.projectShortName === projectId) {
          const isFileExist = this.updateTemporaryModified(file, timestamp);
          if (!isFileExist) needToRefetchTree = true;
        }
      });

      if (
        !needToRefetchTree &&
        data.find(
          ({ code: { code }, accountId, projectId }) =>
            code === CHANGE_PROJECT_EVENT_CODE &&
            this.context.accountId === accountId &&
            this.context.projectShortName === projectId
        )
      ) {
        needToRefetchTree = true;
      }

      if (needToRefetchTree) {
        this.fetchFiles();
      }
    } catch (e) {
      AppLogger.error({
        message: `Error in Editor:WebSockets:handleUpdateFileEvent.`,
        exception: e as Error,
      });
    }
  };

  updateLastModifiedInTree = (fileId: string, lastModified: number, shouldSetDirty?: boolean) => {
    const fileNode = this.fileTree.getFileById(fileId);
    if (!fileNode) return;
    fileNode.setLastModified(lastModified);
    if (shouldSetDirty) fileNode.setHasLocalChanges();
  };

  updateTemporaryModified = (fileId: string, lastModified: number) => {
    const fileNode = this.fileTree.getFileById(fileId);
    if (!fileNode) return false;
    fileNode.setTemporaryModified(lastModified);
    return true;
  };

  getStageFileSettings = (fileId: string) => {
    const settings = this.visualLocalStorageService.getStageFileSettings(fileId);
    return settings ? settings : new VisualEditorStageFileSettings();
  };

  getModeForFile = (fileId?: string): EditorMode => {
    if (!fileId?.endsWith('.sc')) return 'editor';
    return this.state.fileTabs[fileId]?.editorMode || 'editor';
  };

  openFile = async (fileId?: string, forceMode?: EditorMode, position?: EditorCursorPosition) => {
    const mode = forceMode ? forceMode : this.getModeForFile(fileId);

    try {
      if (this.state.currentFilePath) await this.save();

      if (!fileId) {
        routes.setFile();
        return;
      }

      await this.openFileWithoutSave(fileId, mode, position);
    } catch (error) {
      this.showError(error);
    }
  };

  openDep = async (fileId?: string) => {
    try {
      if (this.state.currentFilePath) await this.save();

      if (!fileId) {
        routes.setFile();
        return;
      }

      await this.openFileWithoutSave(fileId, 'editor');
    } catch (error) {
      this.showError(error);
    }
  };

  scrollToPosition = (position: EditorCursorPosition) => {
    const editor = this.editorRef.current?.editor;
    editor?.getSession().unfold(position.row, true);
    editor?.navigateTo(position.row, position.column);
    editor?.scrollToLine(position.row, true, true, () => {});
  };

  openFileWithoutSave = async (fileId: string, mode: EditorMode, position?: EditorCursorPosition) => {
    try {
      if (mode === 'editor') {
        const file =
          fileId.startsWith(DEPENDENCY_FILE_PREFIX) || fileId.startsWith(SYSTEM_DEPENDENCY_FOLDER)
            ? await this.fetchDep(fileId)
            : await this.fetchFile(fileId);
        if (file) {
          Promise.resolve().then(() => this.initCodeEditor(file, position));
        }
      } else {
        await this.fetchGraph(fileId);
      }

      this.setState(
        prevState => ({
          fileTabs: this.fileTabsHelper.for(prevState.fileTabs).setMode(fileId, mode),
        }),
        () => {
          routes.setFile(fileId, mode);
        }
      );
    } catch (error) {
      this.showError(error);
      if (mode === 'visual-editor') this.openFileWithoutSave(fileId, 'editor');
    }
  };

  setEmptyFile = () => {
    this.setState({
      currentFilePath: undefined,
      selectedFileId: undefined,
    });
  };

  setCurrentFile = (fileId: string, mode: EditorMode) => {
    this.setState(prevState => ({
      fileTabs: this.fileTabsHelper.for(prevState.fileTabs).openTab(fileId, mode),
      currentFilePath: fileId,
      selectedFileId: fileId,
      editorMode: mode,
      currentVisualStageFileSettings: this.getStageFileSettings(fileId),
    }));
  };

  validate = () =>
    this.storageService.validate(({ parseErrors, states }: EditorValidationResult) => {
      this.setState({ parseErrors, states });
    });

  closeFileTab = async (fileId: string, withoutSaving?: boolean) => {
    if (this.state.currentFilePath === fileId && !withoutSaving) await this.save();

    this.setState(prevState => {
      const helper = this.fileTabsHelper.for(prevState.fileTabs);
      const fileTabs = helper.closeTab(fileId);

      if (prevState.currentFilePath && prevState.currentFilePath === fileId) {
        const nextFileId = helper.findNextTabId(fileId);
        this.openFile(nextFileId);
      }

      return { fileTabs };
    });
  };

  closeFolderTabs = (folderId: string) => {
    this.setState(prevState => {
      const helper = this.fileTabsHelper.for(prevState.fileTabs);
      let nextFileId = prevState.currentFilePath;
      let fileTabs = prevState.fileTabs;
      const filePaths = Object.keys(prevState.fileTabs);
      filePaths.forEach(key => {
        if (!key.startsWith(folderId + '/')) return;
        fileTabs = helper.closeTab(key);
        if (nextFileId === key) nextFileId = helper.findNextTabId(key);
      });

      if (prevState.currentFilePath !== nextFileId) {
        this.openFile(nextFileId);
      }

      return { fileTabs };
    });
  };

  setFileTree = (files: FileData[]) => {
    this.fileTree = new FileTree(files, this.setFileTreeData, this.state.currentSettings.showHiddenFiles);
  };

  setDepsTree = (files: FileData[]) => {
    this.depsTree = new FileTree(
      files,
      this.setDepsTreeData,
      this.state.currentSettings.showHiddenFiles,
      DEPENDENCY_FILE_PREFIX
    );
  };

  fetchFiles = async () => {
    const [files, mainFile, externalDeps, systemDeps] = await this.loadFiles(this.storageService.getFileTree());
    this.setFileTree(files);
    this.setDepsTree([...systemDeps, ...externalDeps]);
    return [files, mainFile] as [FileData[], string | undefined];
  };

  getRemoteOrLocalFile = async (fileId: string, temporaryModified?: number) =>
    !this.state.isOnline && this.getFileFromLocalStorage(fileId)
      ? (this.getFileFromLocalStorage(fileId) as FileContent)
      : await this.storageService.getFile(fileId, temporaryModified);

  fetchFile = (fileId: string) => {
    if (!this.mounted) return;
    const fileNode = this.fileTree.data[fileId] as FileNode;
    return this.load(this.getRemoteOrLocalFile(fileId, fileNode?.temporaryModified)).then(file => {
      if (!this.mounted) return;
      this.save = () => this.checkAndSaveFile();
      this.updateLastModifiedInTree(file.path, file.lastModified);
      this.setState({ currentFile: file });
      this.validate();
      return file;
    });
  };

  fetchDep = (fileId: string) => {
    return this.load(this.storageService.getDependency(fileId)).then(file => {
      this.save = () => Promise.resolve();
      this.setState({ currentFile: file });
      return file;
    });
  };

  fetchGraph = (fileId: string) => {
    return this.load(this.storageService.getGraph(fileId, this.state.customTags)).then(graph => {
      this.save = () => this.saveGraph();
      this.updateLastModifiedInTree(graph.path, graph.lastModified);
      this.setState({ currentGraph: graph });
    });
  };

  selectFileId = (fileId?: string, preventOpen?: boolean, position?: EditorCursorPosition) => {
    if (this.state.selectedFileId === fileId) {
      if (position && !fileId?.startsWith(DEPENDENCY_FILE_PREFIX)) this.scrollToPosition(position);
      return Promise.resolve();
    }
    this.setSelectedFileId(fileId);
    if (!fileId) {
      this.setState({ currentFilePath: undefined });
      return Promise.resolve();
    }
    if (!preventOpen && !fileId.startsWith(FolderNode.PREFIX))
      return fileId?.startsWith(DEPENDENCY_FILE_PREFIX)
        ? this.openDep(fileId)
        : this.openFile(fileId, 'editor', position);
    return Promise.resolve();
  };

  saveSettings = (settings: EditorSettings) => {
    this.setState({ currentSettings: settings });
    this.localStorageService.saveSettings(settings);
  };

  openSearch = () => {
    const editor = this.editorRef.current?.editor;
    if (!editor) return;
    editor.execCommand('find');
  };

  clearSavingPromise = () => {
    if (this.savingIndicatorTimeout) clearTimeout(this.savingIndicatorTimeout);

    if (this.savingPromise || this.state.hasChanges || this.state.temporaryFileToSave) {
      this.savingPromise = undefined;
      this.setState({ hasChanges: false, temporaryFileToSave: null });
    }
  };

  saveCursorPosition = () => {
    const fileId = this.state.currentFile?.path;
    const cursorPosition = this.editorRef.current?.editor.getCursorPosition();
    if (!fileId || !cursorPosition) return;

    this.cursorPositions[fileId] = cursorPosition;
    this.localStorageService.saveCursorPositions(this.cursorPositions);
  };

  getEditorValue = () => this.editorRef.current?.editor.getValue();
  isFileChanged = () => this.state.currentFile?.content !== this.getEditorValue();

  checkIsOnline = async () => {
    const isOnline = navigator.onLine;
    if (!isOnline) return false;

    try {
      const { status } = await this.storageService.healthCheck();
      return status === 200;
    } catch (e) {
      return false;
    }
  };

  tryToSyncFiles = async () => {
    const isOnline = await this.checkIsOnline();

    if (isOnline) {
      this.setState({ isOnline });
      const files = this.localStorageService.getTempChanges() as { [x: string]: FileContent };

      forOwn(files, (file, path) => {
        if (this.state.currentFilePath === path) {
          this.setState({ currentFile: file });
          this.initCodeEditor(file);
        }
        this.checkAndSaveFile(true, file);
        this.localStorageService.clearTempChanges(path);
      });

      if (this.syncInterval) clearInterval(this.syncInterval);
      return true;
    }

    const currentFile = this.state.currentFile?.clone();
    if (currentFile && this.state.currentFilePath) {
      currentFile.setContent(this.getEditorValue() || '');
      this.localStorageService.saveTempChanges(currentFile);
    }

    return false;
  };

  getFileFromLocalStorage = (fileId: string) => {
    const files = this.localStorageService.getTempChanges();
    if (files) return files[fileId] as FileContent;
  };

  syncFileChanges = async (force?: boolean) => {
    const localChanges = this.localStorageService.getTempChanges();
    const isOnline = await this.checkIsOnline();

    if (!localChanges || (!force && isOnline)) return;
    if (this.syncInterval) clearInterval(this.syncInterval);

    const isSynced = await this.tryToSyncFiles();

    if (!isSynced) this.syncInterval = setInterval(() => this.tryToSyncFiles(), TRY_TO_SYNC_INTERVAL);
  };

  checkAndSaveFile = (force?: boolean, file?: FileContent): Promise<unknown> => {
    const currentFile = file || this.state.currentFile;
    if (!currentFile) return Promise.resolve();
    if (!this.mounted) return Promise.resolve();

    if (this.editorRef.current) this.saveCursorPosition();

    const isAdjacentFile = file && this.state.currentFilePath !== file.path;

    if (!force && currentFile.path === this.state.temporaryFileToSave?.path) return Promise.resolve();

    if (this.savingPromise) {
      this.savingPromise = this.savingPromise.then(() => this.checkAndSaveFile(force, currentFile));
      return this.savingPromise;
    }

    const isExist = this.fileTree.getFileById(currentFile.path);

    if (!isExist && !force) {
      const updatedFile = currentFile.clone();
      updatedFile.setContent(this.getEditorValue() || '');

      this.setState({ temporaryFileToSave: updatedFile });
      return Promise.resolve();
    }

    if (!force && !this.isFileChanged()) {
      this.clearSavingPromise();
      return Promise.resolve();
    }

    const updatedFile = currentFile.clone();
    if (!isAdjacentFile && this.state.editorMode === 'editor') {
      updatedFile.setContent(this.getEditorValue() || '');
    }

    if (!this.state.isOnline) {
      this.localStorageService.saveTempChanges(updatedFile);
      return Promise.resolve();
    }

    if (!force && this.storageService.checkFileVersionWasSaved(updatedFile.path, updatedFile.content)) {
      return Promise.resolve();
    }
    return this.saveFile(updatedFile, force, isAdjacentFile);
  };

  saveFile = async (updatedFile: FileContent, force?: boolean, isAdjacentFile?: boolean) => {
    if (updatedFile.content === '') {
      const answer = window.confirm(t('EditorContext:attempt to save empty file'));
      if (!answer) {
        this.initializeEditor();
        return;
      }
    }
    this.savingPromise = this.storageService
      .saveFile(updatedFile, this.getLastModifiedNN(updatedFile.path), force)
      .then(fileFromApi => {
        this.updateLastModifiedInTree(fileFromApi.path, fileFromApi.lastModified, true);
        if (!isAdjacentFile) this.setState({ currentFile: fileFromApi });
        this.setState({ temporaryFileToSave: null });
      })
      .catch(error => {
        this.checkIsOnline().then(isOnline => {
          if (!isOnline) {
            this.setState({ isOnline: false });
            this.localStorageService.saveTempChanges(updatedFile);
            this.syncFileChanges();
            this.showOfflineError();
          } else {
            this.showSaveError(error, updatedFile.path);
          }
        });
        return Promise.reject(error);
      })
      .then(() => this.validate())
      .finally(this.clearSavingPromise);

    this.savingIndicatorTimeout = setTimeout(() => {
      if (this.savingPromise) {
        this.savingPromise = this.load(this.savingPromise);
      }
    }, SAVING_INDICATOR_TIMEOUT);

    return this.savingPromise;
  };

  saveGraph = (force?: boolean): Promise<unknown> => {
    const currentGraph = this.state.currentGraph;
    if (!currentGraph || this.actions.length === 0) return Promise.resolve();

    if (this.savingPromise) {
      this.savingPromise = this.savingPromise.then(() => this.saveGraph(force));
      return this.savingPromise;
    }
    if (this.saveTimeout) clearTimeout(this.saveTimeout);

    const actionsToSave = [...this.actions];

    this.savingPromise = this.storageService
      .saveGraph(currentGraph, this.getLastModifiedNN(currentGraph.path), actionsToSave, this.state.customTags, force)
      .then(graph => {
        this.updateLastModifiedInTree(graph.path, graph.lastModified, true);
        this.actions = this.actions.filter(action => !actionsToSave.includes(action));

        if (this.actions.length === 0) this.setState({ currentGraph: graph }, () => this.forceUpdate());
      })
      .catch(error => {
        this.showSaveError(error, currentGraph.path);
        return Promise.reject(error);
      })
      .finally(this.clearSavingPromise);
    return this.savingPromise;
  };

  setHasChanges = () => {
    if (this.state.hasChanges) return;
    this.setState({ hasChanges: true });
  };

  commit = (message: string) => {
    return this.load(
      this.save()
        .then(() => this.storageService.commit(message))
        .then(() => this.fileTree.resetHasLocalChanges())
    );
  };

  filterFileTabsForNewData = ([files, mainFile]: [FileData[], string | undefined]) => {
    const fileToOpen =
      files.findIndex(file => file.name === this.state.currentFilePath) > -1 ? this.state.currentFilePath : mainFile;
    if (fileToOpen) this.openFileWithoutSave(fileToOpen, this.state.fileTabs[fileToOpen].editorMode);
    this.setState(prevState => ({
      fileTabs: this.fileTabsHelper
        .for(prevState.fileTabs)
        .filterInArray(files.map(file => file.name).filter(isString)),
    }));
  };

  pull = (): Promise<unknown> => {
    if (this.savingPromise) return this.savingPromise.then(this.pull);
    return this.load(this.storageService.pull())
      .then(this.fetchFiles)
      .then(this.filterFileTabsForNewData)
      .catch(this.showError);
  };

  resolve = (message: string, conflictResolution: ConflictResolutionType): Promise<unknown> => {
    if (this.savingPromise) return this.savingPromise.then(() => this.resolve(message, conflictResolution));
    return this.load(this.storageService.commit(message, conflictResolution))
      .catch(this.showError)
      .then(this.fetchFiles)
      .then(this.filterFileTabsForNewData)
      .catch(this.showError);
  };

  discard = (): Promise<unknown> => {
    if (this.savingPromise) return this.savingPromise.then(this.discard);
    return this.load(this.storageService.discard())
      .catch(this.showError)
      .then(this.fetchFiles)
      .then(this.filterFileTabsForNewData)
      .then(() => this.fileTree.resetHasLocalChanges())
      .catch(this.showError);
  };

  needToCreateKeepFile = (fileId: string) => {
    const parentFolder = fileId.substring(0, fileId.lastIndexOf('/'));
    return !this.fileTree.hasKeepFileInFolder(parentFolder);
  };

  renameFile = (fileId: string, newFileId: string, isFolder: boolean) => {
    return this.load(
      isFolder
        ? this.storageService.renameFolder(fileId, newFileId, this.needToCreateKeepFile(fileId))
        : this.storageService.renameFile(fileId, newFileId, this.needToCreateKeepFile(fileId))
    ).then(files => {
      this.setFileTree(files);
      this.setState(
        prevState => ({
          fileTabs: isFolder
            ? this.fileTabsHelper.for(prevState.fileTabs).renameFolder(fileId, newFileId)
            : this.fileTabsHelper.for(prevState.fileTabs).renameFile(fileId, newFileId),
        }),
        () => {
          if (this.state.currentFilePath === fileId) {
            this.openFileWithoutSave(newFileId, this.state.editorMode);
            return;
          }
          if (this.state.currentFilePath?.startsWith(`${fileId}/`)) {
            this.openFileWithoutSave(
              newFileId + this.state.currentFilePath.substring(fileId.length),
              this.state.editorMode
            );
          }
        }
      );
    });
  };

  deleteFile = async (
    fileId: string,
    skipLoading?: boolean,
    needToKeepFolder: boolean = this.needToCreateKeepFile(fileId)
  ) => {
    await this.closeFileTab(fileId, true);

    const deletePromise = this.storageService
      .deleteFile(fileId, needToKeepFolder)
      .then(data => {
        this.validate();
        return data;
      })
      .then(data => {
        this.localStorageService.deleteFile(fileTabs => {
          delete fileTabs[fileId];
          return fileTabs;
        });
        return data;
      });

    return skipLoading ? deletePromise : this.load(deletePromise);
  };

  deleteTemporaryFile = async () => {
    const { temporaryFileToSave } = this.state;
    if (!temporaryFileToSave) return;
    const fileInTree = this.fileTree.getFileOrFolder(temporaryFileToSave.path);
    if (fileInTree) {
      this.fileTree.deleteFileOrFolder(fileInTree);
    }
    await this.closeFileTab(temporaryFileToSave.path, true);
    this.setState({ temporaryFileToSave: null });
  };

  deleteFolder = async (folderId: string) => {
    const folder = this.fileTree.getFolderById(folderId);
    if (!folder) return Promise.reject();
    await this.closeFolderTabs(folder.path);
    return this.load(this.storageService.deleteFolder(folder.path));
  };

  copyFile = (file: FileNode, newFilePath: string) => {
    return this.load(this.storageService.copyFile(file.path, newFilePath).then(this.setFileTree));
  };

  copyFolder = (folder: FolderNode, newFolderPath: string) => {
    return this.load(this.storageService.copyFolder(folder.path, newFolderPath).then(this.setFileTree));
  };

  createFile = (fileId: string) => {
    const content = fileId.endsWith('.sc') ? SC_FILE_TEMPLATE : '';
    return this.load(this.storageService.createFile(fileId, content)).then(newFile => {
      newFile.setHasLocalChanges();
      this.setSelectedFileId(fileId);
      this.openFile(fileId);
      return newFile;
    });
  };

  createFolder = (fileId: string) => {
    return this.load(this.storageService.createKeepFile(fileId)).then(newFile => {
      this.setSelectedFileId(FolderNode.prefixed(fileId));
      return newFile;
    });
  };

  setSelectedFileId = (fileId?: string) => this.setState({ selectedFileId: fileId });

  setEditorMode = (mode: EditorMode) => {
    if (this.state.editorMode === mode) return;
    this.openFile(this.state.currentFilePath, mode);
  };

  saveVisualSettings = (settings: VisualEditorSettings) => {
    this.setState({ currentVisualSettings: settings });
    this.visualLocalStorageService.saveSettings(settings);
  };

  saveVisualStageFileSettings = (settings: VisualEditorStageFileSettings) => {
    if (this.state.currentFilePath) {
      this.visualLocalStorageService.saveStageFileSettings(settings, this.state.currentFilePath);
    }
  };

  updateDeps = async () => {
    try {
      await this.loadDeps(this.storageService.updateDependencies());
    } catch (error) {
      this.showError(error);
    }
    await this.fetchFiles();
  };

  refreshEditorAfterReplaceAll = (replaceInfo: ReplaceInfo[]) => {
    replaceInfo.forEach(replaceInfo => {
      this.updateTemporaryModified(replaceInfo.fileName, Math.floor(Date.now() / 1000));
    });
    if (!this.state.currentFilePath) return;
    this.openFileWithoutSave(this.state.currentFilePath, this.state.editorMode);
  };

  HIGHLIGHTED_CLASS_NAME = 'ace_highlighted_range';

  highlightPosition = (range: [EditorCursorPosition, EditorCursorPosition]) => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    this.clearHighlight();
    session.addMarker(Range.fromPoints(range[0], range[1]), this.HIGHLIGHTED_CLASS_NAME, 'text');
    this.scrollHorizontal(range);
  };

  private scrollHorizontal = (range: [EditorCursorPosition, EditorCursorPosition]) => {
    const renderer = this.editorRef.current?.editor.renderer;
    if (!renderer) return;

    renderer.scrollCursorIntoView({
      row: range[0].row,
      column: 0,
    });
    renderer.scrollCursorIntoView({
      row: range[0].row,
      column: range[1].column + 10,
    });
  };

  clearHighlight = () => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    Object.values(session.getMarkers())
      .filter(marker => marker.clazz === this.HIGHLIGHTED_CLASS_NAME)
      .forEach(marker => session.removeMarker(marker.id));
  };

  OUTLINED_CLASS_NAME = 'ace_outlined_range';

  outlinePositions = (positions: Array<[EditorCursorPosition, EditorCursorPosition]>) => {
    this.clearOutlines();
    positions.forEach(this.outlinePosition);
  };

  clearOutlines = () => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    Object.values(session.getMarkers())
      .filter(marker => marker.clazz === this.OUTLINED_CLASS_NAME)
      .forEach(marker => session.removeMarker(marker.id));
  };

  outlinePosition = (range: [EditorCursorPosition, EditorCursorPosition]) => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    session.addMarker(Range.fromPoints(range[0], range[1]), this.OUTLINED_CLASS_NAME, 'text');
  };

  setOutlinedRanges = (fileRanges: FilesRanges) => {
    this.outlinedRanges = fileRanges;
    this.clearCurrentFileRanges();
    if (this.state.currentFilePath && fileRanges[this.state.currentFilePath])
      this.outlinePositions(fileRanges[this.state.currentFilePath]);
  };

  clearCurrentFileRanges = () => {
    this.clearOutlines();
    this.clearHighlight();
  };

  replace = (position: [EditorCursorPosition, EditorCursorPosition], replaceValue: string) => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;

    session.replace(Range.fromPoints(position[0], position[1]), replaceValue);
  };

  removeMarkersWithClass(className: string) {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    const markers: any[] = Object.values(session.getMarkers() as Record<string, any>);
    for (let marker of markers) {
      if (marker.clazz === className) session.removeMarker(marker.id);
    }
  }

  format = async () => {
    if (!this.editorRef.current?.editor || !this.state.currentFilePath) return;
    const session = this.editorRef.current?.editor.getSession();
    this.removeMarkersWithClass(ACE_FORMAT_ERROR_MARKER_CLASS);

    const fileExt = this.state.currentFilePath.substring(this.state.currentFilePath.lastIndexOf('.') + 1);

    const [, error] = await this.aceFormatBehaviour.format(fileExt as FormattedLanguages);
    if (error) {
      const row = error.loc ? error.loc.start.line - 1 : 0;
      const column = error.loc ? error.loc.start.column - 1 : 0;

      session.setAnnotations([
        {
          row,
          column,
          type: 'error',
          text: error.message,
        },
      ]);

      session.addMarker(new Range(row, column - 1, row, column + 1), ACE_FORMAT_ERROR_MARKER_CLASS, 'text');
    }
  };

  onCodeChange = (delta: Ace.Delta) => {
    const session = this.editorRef.current?.editor.getSession();
    if (!session) return;
    session.clearAnnotations();
    this.removeMarkersWithClass(ACE_FORMAT_ERROR_MARKER_CLASS);
  };

  render() {
    return (
      <EditorContext.Provider
        value={{
          fileTreeData: this.state.fileTreeData,
          fileTree: this.fileTree,
          depsTreeData: { ...this.state.systemDepsTreeData, ...this.state.depsTreeData },
          currentFilePath: this.state.currentFilePath,
          currentFile: this.state.currentFile,
          temporaryFileToSave: this.state.temporaryFileToSave,
          currentGraph: this.state.currentGraph,
          fileTabs: this.state.fileTabs,
          fileTabsData: this.fileTabsHelper.for(this.state.fileTabs).toTabsData(),
          closeFileTab: this.closeFileTab,
          renameFile: this.renameFile,
          createFile: this.createFile,
          createFolder: this.createFolder,
          deleteFile: this.deleteFile,
          deleteTemporaryFile: this.deleteTemporaryFile,
          deleteFolder: this.deleteFolder,
          copyFile: this.copyFile,
          format: this.format,
          copyFolder: this.copyFolder,
          selectFileId: this.selectFileId,
          setSelectedFileId: this.setSelectedFileId,
          selectedFileId: this.state.selectedFileId,
          saveSettings: this.saveSettings,
          currentSettings: this.state.currentSettings,
          save: this.save,
          debounceSaveFile: this.debounceSaveFile,
          saveTemporaryFile: this.saveTemporaryFile,
          openSearch: this.openSearch,
          openFileWithoutSave: this.openFileWithoutSave,
          editorRef: this.editorRef,
          commit: this.commit,
          discard: this.discard,
          pull: this.pull,
          resolve: this.resolve,
          resizeEditor: this.resizeEditor,
          editorMode: this.state.editorMode,
          setEditorMode: this.setEditorMode,
          showError: this.showError,
          load: this.load,
          currentVisualSettings: this.state.currentVisualSettings,
          saveVisualSettings: this.saveVisualSettings,
          currentVisualStageFileSettings: this.state.currentVisualStageFileSettings,
          saveVisualStageFileSettings: this.saveVisualStageFileSettings,
          setHasChanges: this.setHasChanges,
          hasChanges: this.state.hasChanges,
          customTags: this.state.customTags,
          graphService: this.graphService,
          savingPromise: this.savingPromise,
          setGraph: graph => this.setState({ currentGraph: graph }),
          addActions: this.addActions,
          refreshTree: this.fetchFiles,
          isFileModifiedInProgress: this.state.isFileModifiedInProgress,
          parseErrors: this.state.parseErrors,
          updateDeps: this.updateDeps,
          refreshEditorAfterReplaceAll: this.refreshEditorAfterReplaceAll,
          highlightPosition: this.highlightPosition,
          setOutlinedRanges: this.setOutlinedRanges,
          clearCurrentFileRanges: this.clearCurrentFileRanges,
          replace: this.replace,
          isFilesLoading: this.state.isFilesLoading,
          isDepsLoading: this.state.isDepsLoading,
          isOnline: this.state.isOnline,
          states: this.state.states,
          intentNames: this.state.intentNames,
          intentGroups: this.state.intentGroups,
          searchService: this.searchService,
          localStorageService: this.localStorageService,
          validate: this.validate,
        }}
      >
        {this.props.children}
        {this.state.isLoading ? <Spinner size='4x' backgroundColor='rgba(255,255,255, 0.1)' /> : null}
        {this.state.gutterMarkerTooltipText && (
          <Tooltip isOpen target={GUTTER_TOOLTIP_TARGET_ID} placement='right'>
            {this.state.gutterMarkerTooltipText}
          </Tooltip>
        )}
      </EditorContext.Provider>
    );
  }
}

export const useEditorContext = () => useContext(EditorContext);
