import '../localization';
import { t } from '../localization';
export var mapTemplateIdToIconName = {
    empty_template: 'farExpand',
    faq_from_user_file: 'farFileUpload',
    faq_template_contacts: 'farPhone',
    faq_template_retail: 'farShoppingCart',
    faq_template_education: 'farUserGraduate',
    faq_template_hr: 'farUserCircle',
    faq_template_banking: 'farMoneyBill',
    cdqa: 'farText',
};
export var FAQBaseIntentName = 'KnowledgeBase';
export var FAQBaseIntentNamePrefix = 'FAQ';
export var FAQIntentBasePath = "/".concat(FAQBaseIntentName, "/");
export var FAQIntentBaseQuestion = t('FAQ:Intent:BaseQuestionName');
export var FAQIntentBaseGroup = t('FAQ:Intent:BaseGroupName');
export var FAQIntentRootGroup = 'Root';
export var MAX_FILE_SIZE_MB = 3 * 1024 * 1024;
export var TIPS_CLOSED = 'TIPS_CLOSED';
export var TIPS_MIN = 'TIPS_MIN';
export var INITIAL_REPLIES = [{ type: 'text', text: '', markup: 'plain' }];
export var MAX_ANSWERS_COUNT = 20;
