import React from 'react';
import { Icon } from '@just-ai/just-ui';
import { Collapse as RSCollapse } from 'reactstrap';
import styles from './styles.module.scss';
var Collapse = function (_a) {
    var text = _a.text, isOpened = _a.isOpened, children = _a.children, onToggle = _a.onToggle, dataTestId = _a.dataTestId, onEntered = _a.onEntered;
    return (React.createElement("div", { className: 'flex-column' },
        React.createElement("div", { className: 'flex-row gap-1x cursor-pointer', onClick: onToggle, "data-test-id": dataTestId },
            React.createElement("span", { className: styles.collapse__text }, text),
            React.createElement("div", null, isOpened ? React.createElement(Icon, { name: 'farChevronUp', size: 'sm' }) : React.createElement(Icon, { name: 'farChevronDown', size: 'sm' }))),
        React.createElement(RSCollapse, { onEntered: onEntered, isOpen: isOpened }, children)));
};
export default React.memo(Collapse);
