var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { Button, IconButton } from '@just-ai/just-ui';
import './Message.css';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
import { LabelsForm } from './EditLabelModal';
var MessageForm = /** @class */ (function (_super) {
    __extends(MessageForm, _super);
    function MessageForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            editTaskModal: false,
            editCommentModal: false,
            editLabelsModal: false,
            editIntentsModal: false,
        };
        _this.togglePopup = function (modalName, value) {
            _this.setState(function (state) {
                var newStateModals = __assign({}, state);
                newStateModals[modalName] = value !== undefined ? value : !newStateModals[modalName];
                return newStateModals;
            });
        };
        _this.toggleAndSaveCommentModal = function (comment) {
            _this.props.saveComment(comment);
            _this.hideCommentModal();
        };
        _this.toggleCommentModal = function () { return _this.togglePopup('editCommentModal'); };
        _this.toggleIntentsModal = function () { return _this.togglePopup('editIntentsModal'); };
        _this.toggleLabelsModal = function () { return _this.togglePopup('editLabelsModal'); };
        _this.toggleTasksModal = function () { return _this.togglePopup('editTaskModal'); };
        _this.hideCommentModal = function () { return _this.togglePopup('editCommentModal', false); };
        _this.hideLabelsModal = function () { return _this.togglePopup('editLabelsModal', false); };
        _this.hideIntentModal = function () { return _this.togglePopup('editIntentsModal', false); };
        _this.hideTaskModal = function () {
            _this.togglePopup('editTaskModal', false);
            _this.context.loadIssues(_this.props.isSessionMessages ? 'sessionMessages' : 'messages', _this.props.questionId);
        };
        _this.saveLabels = function (labels) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.context.saveMessageLabels(this.props.questionId, this.props.sessionId, labels, this.props.isSessionMessages);
                this.hideLabelsModal();
                return [2 /*return*/];
            });
        }); };
        return _this;
    }
    MessageForm.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var _q = this.props, questionId = _q.questionId, sessionId = _q.sessionId, comment = _q.comment, t = _q.t, question = _q.question, issue = _q.issue, currentLabels = _q.labels, createNewLabelAndAddToMessage = _q.createNewLabelAndAddToMessage, compactView = _q.compactView, DownloadReportComponent = _q.downloadReportComponent, buttonsWithMinHeight = _q.buttonsWithMinHeight;
        var _r = this.context, config = _r.config, projectShortName = _r.projectShortName, labelsContext = _r.labelsContext, isAccess = _r.isAccess;
        return (React.createElement(React.Fragment, null,
            ((_a = config.messageItem) === null || _a === void 0 ? void 0 : _a.commentEditComponent) && comment && (React.createElement("div", { className: 'Message_form_item_container' },
                React.createElement("span", null, t('Analytics: messages comment')),
                React.createElement("div", null,
                    React.createElement("span", null, comment),
                    React.createElement(IconButton, { "data-test-id": 'qaTaskCommentIcon', name: 'farEdit', size: 'sm', onClick: this.toggleCommentModal })))),
            ((_b = config.messageItem) === null || _b === void 0 ? void 0 : _b.labelsEditComponent) && (React.createElement(LabelsForm, { allLabels: labelsContext === null || labelsContext === void 0 ? void 0 : labelsContext.groups, toggle: this.hideLabelsModal, open: this.toggleLabelsModal, currentLabels: currentLabels || [], isOpen: this.state.editLabelsModal, createNewLabelAndAddToMessage: createNewLabelAndAddToMessage, compactView: compactView, saveLabels: this.saveLabels, t: t })),
            ((_c = config.messageItem) === null || _c === void 0 ? void 0 : _c.tasksEditComponent) && issue && (React.createElement("div", { className: 'Message_form_item_container' },
                React.createElement("span", null, t('Analytics: current task label')),
                React.createElement("div", null,
                    React.createElement("span", null,
                        issue.name,
                        issue.priority && React.createElement("div", { className: 'Message_form_item_green' }, issue.priority.name)),
                    React.createElement("div", null,
                        issue.state && React.createElement("div", { className: 'Message_form_item_blue' }, issue.state.name),
                        React.createElement(IconButton, { "data-test-id": 'qaTaskTaskIcon', name: 'farEdit', size: 'sm', onClick: this.toggleTasksModal }))))),
            React.createElement("div", { className: 'Message_form_buttons', style: { minHeight: buttonsWithMinHeight ? 32 : 'initial' } },
                React.createElement("div", null,
                    compactView && ((_d = config.messageItem) === null || _d === void 0 ? void 0 : _d.labelsEditComponent) && (!currentLabels || (currentLabels === null || currentLabels === void 0 ? void 0 : currentLabels.length) === 0) && (React.createElement(Button, { "data-test-id": 'qaTaskTag', size: 'sm', color: 'link', onClick: this.toggleLabelsModal, disabled: !((_e = config.tags) === null || _e === void 0 ? void 0 : _e.hasWriteRight) }, t('Analytics: messages add labels'))),
                    ((_f = config.messageItem) === null || _f === void 0 ? void 0 : _f.commentEditComponent) && !comment && (React.createElement(Button, { "data-test-id": 'qaTaskComment', size: 'sm', color: 'link', onClick: this.toggleCommentModal }, t('Analytics: messages add comment'))),
                    ((_g = config.messageItem) === null || _g === void 0 ? void 0 : _g.tasksEditComponent) && !issue && (React.createElement(Button, { "data-test-id": 'qaTaskTask', size: 'sm', color: 'link', onClick: this.toggleTasksModal }, t('Analytics: messages add tasks'))),
                    question && ((_h = config.messageItem) === null || _h === void 0 ? void 0 : _h.intentEditComponent) && isAccess('NLU_WRITE') && (React.createElement(Button, { "data-test-id": 'qaTaskIntents', size: 'sm', color: 'link', onClick: this.toggleIntentsModal }, t('Analytics: messages intents')))),
                DownloadReportComponent && React.createElement(DownloadReportComponent, { questionId: questionId })),
            ((_j = config.messageItem) === null || _j === void 0 ? void 0 : _j.tasksEditComponent) &&
                this.state.editTaskModal &&
                ((_k = config.messageItem) === null || _k === void 0 ? void 0 : _k.tasksEditComponent({
                    isShown: this.state.editTaskModal,
                    questionId: questionId,
                    issue: issue,
                    toggle: this.hideTaskModal,
                    sessionId: sessionId,
                })),
            ((_l = config.messageItem) === null || _l === void 0 ? void 0 : _l.commentEditComponent) &&
                ((_m = config.messageItem) === null || _m === void 0 ? void 0 : _m.commentEditComponent({
                    isShown: this.state.editCommentModal,
                    comment: comment,
                    questionId: questionId,
                    projectShortName: projectShortName,
                    toggle: this.hideCommentModal,
                    saveComment: this.toggleAndSaveCommentModal,
                })),
            question &&
                ((_o = config.messageItem) === null || _o === void 0 ? void 0 : _o.intentEditComponent) &&
                ((_p = config.messageItem) === null || _p === void 0 ? void 0 : _p.intentEditComponent({
                    isShown: this.state.editIntentsModal,
                    toggle: this.hideIntentModal,
                    phrase: question,
                }))));
    };
    MessageForm.contextType = AnalyticsContext;
    return MessageForm;
}(Component));
export default MessageForm;
