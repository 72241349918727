var StatsData = /** @class */ (function () {
    function StatsData() {
        this.labels = [];
        this.dataset = [];
        this.active = true;
        this.ts = [];
    }
    return StatsData;
}());
export { StatsData };
var AnalyticsChartData = /** @class */ (function () {
    function AnalyticsChartData() {
        var _this = this;
        this.setMessagesAndSessionData = function (sessionData, messagesData) {
            _this.messagesStats.ts = messagesData.map(function (statDataItem) { return statDataItem.ts; });
            _this.messagesStats.labels = messagesData.map(function (statDataItem) { return new Date(statDataItem.ts).getDate(); });
            _this.messagesStats.dataset = messagesData.map(function (statDataItem) { return statDataItem.value; });
            _this.sessionStats.ts = sessionData.map(function (statDataItem) { return statDataItem.ts; });
            _this.sessionStats.labels = sessionData.map(function (statDataItem) { return new Date(statDataItem.ts).getDate(); });
            _this.sessionStats.dataset = sessionData.map(function (statDataItem) { return statDataItem.value; });
        };
        this.toggleActivity = function (type) {
            _this[type].active = !_this[type].active;
        };
        this.prepareMessagesAndSessionsGraphData = function (t) {
            return [
                {
                    label: t('Analytics: Sessions'),
                    data: _this.sessionStats.dataset,
                    fill: false,
                    borderColor: 'rgb(255, 127, 80)',
                    lineTension: 0.1,
                    active: _this.sessionStats.active,
                },
                {
                    label: t('Analytics: Messages'),
                    data: _this.messagesStats.dataset,
                    fill: false,
                    borderColor: 'rgb(255, 206, 86)',
                    lineTension: 0.1,
                    active: _this.messagesStats.active,
                },
            ];
        };
        this.messagesStats = new StatsData();
        this.sessionStats = new StatsData();
    }
    return AnalyticsChartData;
}());
export default AnalyticsChartData;
