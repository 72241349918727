var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { uniq, without } from 'lodash';
import { TreeStructure } from './TreeStructure';
var TreeSelectionLogicState = /** @class */ (function () {
    function TreeSelectionLogicState(treeStructure) {
        this.treeStructure = treeStructure;
        this.tree = treeStructure;
    }
    TreeSelectionLogicState.prototype.getElementIdBetween = function (from, to, options, resultIds) {
        var _this = this;
        if (options === void 0) { options = { needIncludeNextId: false }; }
        if (resultIds === void 0) { resultIds = []; }
        return __spreadArray(__spreadArray([], this.tree.tree.branches, true), this.tree.tree.leafs, true).reduce(function (acc, node) { return _this.getNodeElementIdsBetween(from, to, node, options, acc); }, resultIds);
    };
    TreeSelectionLogicState.prototype.calculateSelectWithShift = function (node, selectedIds, lastSelectedId, lastShiftSelectedIds) {
        var nodeInSelectedGroup = node.parentId && selectedIds.includes(node.parentId);
        if (nodeInSelectedGroup || lastSelectedId === node.id) {
            return {
                selectedIds: selectedIds,
                lastSelectedId: lastSelectedId,
                lastShiftSelectedIds: lastShiftSelectedIds,
            };
        }
        if (!lastSelectedId) {
            lastSelectedId = node.id;
            return {
                selectedIds: [node.id],
                lastSelectedId: lastSelectedId,
                lastShiftSelectedIds: lastShiftSelectedIds,
            };
        }
        var from = lastSelectedId;
        var to = node.id;
        var shiftSelectedIds = this.getElementIdBetween(from, to);
        selectedIds = uniq(__spreadArray(__spreadArray([], without.apply(void 0, __spreadArray([selectedIds], lastShiftSelectedIds, false)), true), shiftSelectedIds, true));
        lastShiftSelectedIds = shiftSelectedIds;
        return {
            selectedIds: selectedIds,
            lastSelectedId: lastSelectedId,
            lastShiftSelectedIds: lastShiftSelectedIds,
        };
    };
    TreeSelectionLogicState.prototype.calculateSelectWithCtrl = function (node, selectedIds, lastSelectedId) {
        var nodeInSelectedGroup = node.parentId && selectedIds.includes(node.parentId);
        if (nodeInSelectedGroup) {
            return {
                selectedIds: selectedIds,
                lastSelectedId: lastSelectedId,
            };
        }
        var recursiveIds = TreeStructure.getIdsInDepth(node);
        if (selectedIds.includes(node.id)) {
            selectedIds = without.apply(void 0, __spreadArray([selectedIds], recursiveIds, false));
        }
        else {
            selectedIds = uniq(__spreadArray(__spreadArray([], selectedIds, true), recursiveIds, true));
        }
        var newLastSelectedId = selectedIds.length > 0 ? selectedIds[selectedIds.length - 1] : null;
        return {
            selectedIds: selectedIds,
            lastSelectedId: newLastSelectedId,
        };
    };
    TreeSelectionLogicState.prototype.calculateSelect = function (node) {
        var recursiveIds = TreeStructure.getIdsInDepth(node);
        var lastSelectedId = node.id;
        return {
            selectedIds: recursiveIds,
            lastSelectedId: lastSelectedId,
        };
    };
    TreeSelectionLogicState.prototype.getNodeElementIdsBetween = function (from, to, node, options, resultIds) {
        var _this = this;
        if (options === void 0) { options = { needIncludeNextId: false }; }
        if (resultIds === void 0) { resultIds = []; }
        var isOnRangeEdges = [from, to].includes(node.id);
        var initialNeedIncludeNextId = options.needIncludeNextId;
        options.needIncludeNextId = options.needIncludeNextId || isOnRangeEdges;
        if (node.branches && node.branches.length > 0) {
            resultIds = node.branches.reduce(function (acc, node) { return _this.getNodeElementIdsBetween(from, to, node, options, acc); }, resultIds);
        }
        if (node.leafs && node.leafs.length > 0) {
            resultIds = node.leafs.reduce(function (acc, node) { return _this.getNodeElementIdsBetween(from, to, node, options, acc); }, resultIds);
        }
        if (isOnRangeEdges || (initialNeedIncludeNextId && options.needIncludeNextId)) {
            resultIds.push(node.id);
        }
        if (isOnRangeEdges) {
            options.needIncludeNextId = !initialNeedIncludeNextId;
        }
        return resultIds;
    };
    return TreeSelectionLogicState;
}());
export { TreeSelectionLogicState };
