var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Icon, Tooltip, usePrevious, useTranslation } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import usePressKey from '@just-ai/just-ui/dist/utils/usePressKey';
import insetHTMLInRawText from '@just-ai/just-ui/dist/utils/insetHTMLInRawText';
import { IntentItemType } from '../../../../../nlu-related/utils/intents';
import { toggleIntentItemType } from '../utils';
import styles from './styles.module.scss';
import './styles.scss';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';
var PhraseRow = function (_a, ref) {
    var _b;
    var item = _a.item, onChange = _a.onChange, onDelete = _a.onDelete, onSubmit = _a.onSubmit, index = _a.index, writeDisabled = _a.writeDisabled;
    var iconRef = useRef(null);
    var t = useTranslation().t;
    var onPress = usePressKey({
        key: 'Enter',
    }, function () { return onSubmit && onSubmit(item); });
    var prevError = usePrevious(item.error);
    useEffect(function () {
        if (!prevError && item.error) {
            var elemToFocus = document.getElementById("PhraseRow-".concat(item.id));
            if (elemToFocus)
                elemToFocus.focus();
        }
    }, [prevError, item.error, item.id]);
    var richValue = useMemo(function () {
        var _a;
        return insetHTMLInRawText(item.text, ((_a = item.searchMatches) !== null && _a !== void 0 ? _a : []).map(function (match) { return ({
            start: match.from,
            end: match.to,
            renderHtml: function (text) { return "<span class=\"searchMark\">".concat(text, "</span>"); },
        }); }));
    }, [item]);
    return (React.createElement("div", { "data-test-id": "AlternativeQuestion.Row.id-".concat(index), ref: ref, className: cn(styles.phraseRow, 'edit-phrase-block', 'phraseRow') },
        React.createElement("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--prepend input-group-prepend') },
            React.createElement(IconButton, { id: "changePhraseType-".concat(item.id), "data-test-id": 'changePhraseType', tabIndex: 0, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray'), name: item.type === IntentItemType.phrase ? 'farText' : 'faAt', disabled: writeDisabled, onClick: function () {
                    onChange(toggleIntentItemType(item));
                } }),
            React.createElement(Tooltip, { target: "changePhraseType-".concat(item.id), className: styles.phraseRow__tooltip, placement: 'top', boundariesElement: 'window', "data-test-id": 'changePhraseType.tooltip' }, item.type === IntentItemType.phrase ? t('FAQ:Phrases:ChangeTypeText') : t('FAQ:Phrases:ChangeTypePhrase'))),
        React.createElement("div", { className: cn(styles.phraseRow__part, (_b = {}, _b[styles['phraseRow__part--error']] = !!item.error, _b)) },
            React.createElement(TextAreaLikeElement, { id: "PhraseRow-".concat(item.id), dataTestId: 'PhraseRow.input', value: item.text, richValue: richValue, onKeyDown: onPress, placeholder: item.type === IntentItemType.phrase ? t('FAQ:Phrase:Placeholder') : t('FAQ:Text:Placeholder'), readOnly: writeDisabled, onChange: function (text) { return onChange(__assign(__assign({}, item), { text: text })); }, append: item.error && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.phraseRow__errorWrapper, ref: iconRef },
                        React.createElement(Icon, { name: 'faExclamationCircle', color: 'danger' })),
                    React.createElement(Tooltip, { target: iconRef, placement: 'top' }, t('PhraseBlocks:validationTooltip')))) })),
        React.createElement("div", { className: cn(styles.phraseRow__part, 'phraseRow__part--append input-group-append') },
            React.createElement(IconButton, { tabIndex: 0, "data-test-id": 'deletePhrase', name: 'farTrashAlt', onClick: function () { return onDelete && onDelete(item); }, disabled: writeDisabled, className: cn(styles.phraseRow__part_action, 'flex-center fullSize font-color-light-gray') }))));
};
export default React.memo(React.forwardRef(PhraseRow));
