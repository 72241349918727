import React, { FC, useMemo } from 'react';
import { activationBlocks, TagNames, TJBlock } from '../../utils/types';
import { isActivationToState } from '../../utils/blockLayerUtils';
import { scenarioStart } from './ScreenBody';
import { JStateWithId } from '../../../../reducers/JGraph.reducer/types';
import { Bookmark } from './Bookmark';
import { Group, Path } from 'react-konva';
import { t } from 'localization';

const StartIcon = React.memo(() => {
  return (
    <Path
      width={16}
      height={16}
      data='M0.75 -0.00055795C0.335156 -0.00055795 0 0.334598 0 0.749442V11.6244C0 11.8307 0.16875 11.9994 0.375 11.9994H1.125C1.33125 11.9994 1.5 11.8307 1.5 11.6244V0.749442C1.5 0.334598 1.16484 -0.00055795 0.75 -0.00055795ZM10.8422 0.0978795C6.82734 2.14397 7.15781 -1.45837 2.25 0.758817V8.99944C6.60234 6.8385 7.44609 10.2487 11.5664 8.45804C11.8336 8.34085 12 8.07132 12 7.78304V0.721317C12 0.133036 11.3719 -0.171652 10.8422 0.0978795Z'
      fill='white'
    />
  );
});

const KnowledgeBaseIcon = React.memo(() => {
  return (
    <Path
      width={12}
      height={12}
      data='M11.25 8.4V0.6C11.25 0.225 11.025 0 10.65 0H3C1.725 0 0.75 0.975 0.75 2.25V9.75C0.75 11.025 1.725 12 3 12H10.65C10.95 12 11.25 11.775 11.25 11.4V11.025C11.25 10.875 11.175 10.725 11.025 10.575C10.95 10.2 10.95 9.15 11.025 8.85C11.175 8.775 11.25 8.625 11.25 8.4ZM7.125 3.75L7.74984 4.99992L9 5.625L7.74984 6.25008L7.125 7.5L6.50016 6.25008L5.25 5.625L6.50016 4.99992L7.125 3.75ZM4.5 2.25L4.875 1.5L5.25 2.25L6 2.625L5.25 3L4.875 3.75L4.5 3L3.75 2.625L4.5 2.25ZM9.675 10.5H3C2.55 10.5 2.25 10.2 2.25 9.75C2.25 9.3 2.625 9 3 9H9.675V10.5Z'
      fill='white'
    />
  );
});
export const knowledgeBase = '/KnowledgeBase';

export const BookMarks: FC<{ screen: JStateWithId; isCollapsed: boolean }> = ({ screen, isCollapsed }) => {
  const onlyActivations = useMemo(() => {
    return screen.blocks?.reduce((acc, block, index) => {
      if (activationBlocks.includes(block.tagName) && !isActivationToState(block)) {
        acc.push({
          globalIndex: index,
          block,
        });
      }
      return acc;
    }, [] as { globalIndex: number; block: TJBlock }[]);
  }, [screen.blocks]);

  const isStartScreen = useMemo(() => {
    return onlyActivations.some(
      activationBlock =>
        activationBlock.block.tagName === TagNames.q_ && scenarioStart.includes(activationBlock.block.tagValue || '')
    );
  }, [onlyActivations]);

  const isKnowledgeBaseScreen = useMemo(() => {
    return onlyActivations.some(
      activationBlock =>
        [TagNames.intentGroup, TagNames.intentGroup_].includes(activationBlock.block.tagName) &&
        activationBlock.block.tagValue?.startsWith(knowledgeBase)
    );
  }, [onlyActivations]);

  if (!isStartScreen && !isKnowledgeBaseScreen) return null;

  return (
    <Group x={isCollapsed ? 0 : -32} y={isCollapsed ? 44 : 8}>
      {isStartScreen && (
        <Bookmark fill='#5B9C59' stroke='#B3CDB2' isCollapsed={isCollapsed}>
          <StartIcon />
        </Bookmark>
      )}
      {isKnowledgeBaseScreen && (
        <Bookmark
          fill='#1F2832'
          stroke='#6A7178'
          isCollapsed={isCollapsed}
          title={t('KnowledgeBase:html_title')}
          y={isStartScreen && !isCollapsed ? 32 : undefined}
          x={isCollapsed && isStartScreen ? 28 + 4 : 0}
        >
          <KnowledgeBaseIcon />
        </Bookmark>
      )}
    </Group>
  );
};
