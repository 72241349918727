var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button } from '@just-ai/just-ui';
import MassLabelsModal from './MassLabelsModal';
import { Checkbox } from '../../Checkbox';
import classnames from 'classnames';
import { useLabelsContext } from '../../../../Labels/context/LabelsContext';
export var labelsReducer = function (state, action) {
    var _a;
    var result;
    switch (action.type) {
        case 'exclude':
            result = { key: 'SESSION_ID', options: [], comparisonType: 'exclude' };
            break;
        case 'include':
            result = { key: 'SESSION_ID', options: [], comparisonType: 'include' };
            break;
        case 'change':
            if ((_a = state.options) === null || _a === void 0 ? void 0 : _a.includes(action.value)) {
                var newOptions = __spreadArray([], state.options.filter(function (x) { return x !== action.value; }), true);
                result = {
                    key: 'SESSION_ID',
                    options: newOptions,
                    comparisonType: newOptions.length === 0 && state.comparisonType === 'include' ? 'none' : state.comparisonType,
                };
            }
            else {
                result = {
                    key: 'SESSION_ID',
                    options: __spreadArray(__spreadArray([], state.options, true), [action.value], false),
                    comparisonType: state.comparisonType === 'none' ? 'include' : state.comparisonType,
                };
            }
            break;
        case 'reset':
        default:
            result = { key: 'SESSION_ID', options: [], comparisonType: 'none' };
    }
    return result;
};
function MassAddingLabels(_a) {
    var labelsState = _a.labelsState, setLabelsDispatcher = _a.setLabelsDispatcher, t = _a.t, totalCount = _a.totalCount, onSubmit = _a.onSubmit, _b = _a.isSession, isSession = _b === void 0 ? false : _b;
    var options = labelsState.options, comparisonType = labelsState.comparisonType;
    var _c = useState(false), openModal = _c[0], setOpenModal = _c[1];
    var hasTagCreatePermission = useLabelsContext().hasTagCreatePermission;
    return (React.createElement("div", { className: classnames('Sessions_massAddingLabels_container', isSession && 'Sessions_massAddingLabels_container_sessionsList') },
        React.createElement(Checkbox, { checked: true, halfChecked: comparisonType === 'include' ? totalCount !== options.length : options.length > 0, name: 'choose_labels', onClick: function () { return setLabelsDispatcher({ type: 'reset' }); } }),
        React.createElement("span", null,
            comparisonType === 'include' ? options.length : totalCount - options.length,
            ' ',
            t('Analytics: choose label founded')),
        ((comparisonType === 'include' && options.length < totalCount) ||
            (comparisonType === 'exclude' && options.length > 0)) && (React.createElement(Button, { color: 'primary', flat: true, onClick: function () { return setLabelsDispatcher({ type: 'exclude' }); } }, t('Analytics: choose all labels'))),
        React.createElement("div", { className: 'Sessions_massAddingLabels_delimiter' }),
        React.createElement(Button, { color: 'primary', flat: true, onClick: function () { return setLabelsDispatcher({ type: 'reset' }); } }, t('Analytics: reset choosed labels')),
        React.createElement("div", { className: 'Sessions_massAddingLabels_delimiter' }),
        React.createElement(Button, { color: 'primary', flat: true, onClick: function () { return setOpenModal(true); }, disabled: !hasTagCreatePermission }, t('Analytics: messages add labels')),
        openModal && (React.createElement(MassLabelsModal, { onSubmit: onSubmit, isSession: isSession, onClose: function () { return setOpenModal(false); }, t: t }))));
}
export default React.memo(MassAddingLabels);
