import Konva from 'konva';
import React, { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useForceUpdate } from '@just-ai/just-ui';
import { t } from '../../../../../localization';
import {
  scrollToTargetGlobal$,
  setCursorOnMouseEnterAndLeave,
  setTitleOnMouseEnterAndLeave,
} from '../../../utils/stageUtils';
import { KonvaEventObject } from 'konva/lib/Node';
import { EditMenuBlock$ } from '../../StateScreen.hooks';
import { Circle, Group, Label, Line, Path, Rect, Text } from 'react-konva';
import { STATIC_TRIANGLE } from '../../../utils/connectionLayerUtils';
import { IconNames, KIcon } from '../../parts/KIcons';
import { CollapsedConnectorsMenuClose, CollapsedConnectorsMenuSubject$ } from '../CollapsedConnectorsMenu';
import { TConnector } from '../../../../../reducers/JGraph.reducer/types';
import { hideRootSlashInPath } from '../../../utils/state';
import { IncomingCurvedLine } from './IncomingCurvedLine';
import { IncomingStraightLine } from './IncomingStraightLine';
import { rafAppScheduler } from '../../../../../utils/sheduler/buildRafScheduler';
import { MemoOutgoingCurvedLine } from './OutgoingCurvedLine';

type IslandConnectorProps = {
  text: any;
  isOutgoing: boolean;
  isToNodeScreen: boolean;
  isFromScreen: boolean;
  setPositionsHandler: () => unknown;
  connector: TConnector;
  positionFrom?: { x: number; y: number };
  positionTo?: { x: number; y: number };
  fromCircleNode?: Konva.Node | null;
  toScreenNode?: Konva.Node | null;
};

const IslandConnectorTextProps: Konva.TextConfig = {
  padding: 6,
  fontSize: 12,
  lineHeight: 16 / 12,
  ellipsis: true,
  wrap: 'none',
};

export const gray_100 = '#F4F5F5';
export const gray_300 = '#C3C6C9';
export const gray_400 = '#A6ABAF';
export const gray_600 = '#6A7178';

export const text_active = '#2375B0';
export const border_active = '#5492CD';
export const bg_active = '#5492CD';
export const bg_light_active = '#F2F5FB';

export const STATIC_TOP_OFFSET = 32;
const TEXT_WIDTH = 160;

export const BUTTON_WIDTH = 28;

export const IslandConnector = memo(
  forwardRef<Konva.Label | null, IslandConnectorProps>(
    (
      {
        positionFrom,
        positionTo,
        text,
        isOutgoing,
        setPositionsHandler,
        isToNodeScreen,
        isFromScreen,
        connector,
        fromCircleNode,
        toScreenNode,
      },
      LabelRef
    ) => {
      const mainGroup = useRef<Konva.Group | null>(null);
      const textFromRef = useRef<Konva.Text | null>(null);
      const labelInnerRef = useRef<Konva.Label | null>(null);
      const otherConnectorsRef = useRef<Konva.Node[] | null | undefined>(null);
      const shouldBeGroupedWithLadderConnectors = useRef<Konva.Node[] | null | undefined>(null);
      const textSize = useRef(0);
      const showedText = useRef(text);
      const topOffset = useRef(STATIC_TOP_OFFSET);
      const forceUpdate = useForceUpdate();
      const [all, setAllIntoCurrentState] = useState(0);
      const [active, setIsActive] = useState(false);

      const { to, fromNode, debugActive } = connector;
      const from = fromNode;
      const shouldBeOutgoingLadder = (isFromScreen && fromCircleNode?.name() === fromNode) || false;
      const shouldBeIncomingLadder = (isToNodeScreen && toScreenNode?.attrs?.isCollapsed) || false;
      const shouldGroupWithLadder =
        (isFromScreen && fromCircleNode?.attrs?.substates?.includes(fromNode)) ||
        (isToNodeScreen && toScreenNode?.attrs?.substates?.includes(to)) ||
        false;

      const groupFlag = useMemo(
        () =>
          !shouldBeOutgoingLadder &&
          ((!isOutgoing && !isToNodeScreen) || (isOutgoing && !isFromScreen) || shouldGroupWithLadder),
        [isFromScreen, isOutgoing, isToNodeScreen, shouldBeOutgoingLadder, shouldGroupWithLadder]
      );

      const calcOriginalText = useCallback(() => {
        showedText.current = text;
        let pureTextRenderMain = new Konva.Text({ text: text, ...IslandConnectorTextProps });
        const pureTextSize = pureTextRenderMain?.getWidth();
        if (pureTextSize > TEXT_WIDTH) {
          const letterSize = pureTextSize / text.length;
          const newTextLength = Math.floor(TEXT_WIDTH / letterSize) - 3;
          showedText.current = `...${text.slice(-newTextLength)}`;
          textSize.current = new Konva.Text({ text: showedText.current, ...IslandConnectorTextProps }).getWidth();
        } else {
          textSize.current = pureTextSize;
        }
      }, [text]);

      const onMouseEnterOrLeave = useCallback(event => {
        setCursorOnMouseEnterAndLeave()(event);
      }, []);

      const scrollToTarget = useCallback(
        (event: KonvaEventObject<MouseEvent>) => {
          event.cancelBubble = true;
          scrollToTargetGlobal$.next({
            targetPathId: isOutgoing ? to : from,
            isSideMenuOpen: Boolean(EditMenuBlock$.getValue()),
          });
        },
        [from, isOutgoing, to]
      );

      const fillRefs = useCallback(
        (element: Konva.Label) => {
          labelInnerRef.current = element;
          if (LabelRef) {
            if (typeof LabelRef === 'function') {
              LabelRef(element);
            } else {
              LabelRef.current = element;
            }
          }
        },
        [LabelRef]
      );

      const isTargetNotToOriginal = useCallback(
        (node: Konva.Node) => {
          if (!toScreenNode) return false;
          return toScreenNode.attrs.substates.includes(node.attrs?.to) || toScreenNode.name() === node.attrs?.to;
        },
        [toScreenNode]
      );

      const isFromRepresentsChildren = useCallback(
        (node: Konva.Node) => {
          if (!fromCircleNode) return false;
          return (
            fromCircleNode.attrs.substates?.includes(node.attrs?.from) || fromCircleNode.name() === node.attrs?.from
          );
        },
        [fromCircleNode]
      );

      const calcTopOffset = useCallback(
        (shouldCalcOthers: boolean = false, shouldUseForceUpdate: boolean = false) => {
          const parentLayer = mainGroup.current?.getParent();

          const otherConnectors = parentLayer?.children?.filter(islandConnector => {
            if (islandConnector.nodeType !== 'Group') return false;
            if (isOutgoing) {
              if (islandConnector.name() !== 'OutgoingConnector') return false;
              return (
                (!isFromScreen || shouldBeOutgoingLadder || shouldGroupWithLadder) &&
                (islandConnector.attrs?.from === from ||
                  (fromCircleNode?.attrs.isCollapsed && isFromRepresentsChildren(islandConnector)))
              );
            }
            if (islandConnector.name() !== 'IncomingConnector') return false;
            return (
              islandConnector.attrs?.to === to ||
              (toScreenNode?.attrs.isCollapsed && isTargetNotToOriginal(islandConnector))
            );
          });

          const additionalFilterForGroupsInLadder = otherConnectors?.filter(
            islandConnector => islandConnector.attrs.shouldGroupWithLadder === shouldGroupWithLadder
          );

          const selfIndex = additionalFilterForGroupsInLadder?.findIndex(node => node._id === mainGroup.current?._id);

          topOffset.current = STATIC_TOP_OFFSET * (selfIndex || 0) + STATIC_TOP_OFFSET;
          otherConnectorsRef.current = otherConnectors;

          if (!shouldGroupWithLadder) setAllIntoCurrentState(otherConnectors?.length || 0);
          if (shouldGroupWithLadder) {
            const { normalConnectors, onLadderGroupConnectors } = (otherConnectors || []).reduce(
              (currentState, currentItem) => {
                if (currentItem.attrs?.shouldGroupWithLadder) {
                  currentState.onLadderGroupConnectors.push(currentItem);
                } else {
                  currentState.normalConnectors.push(currentItem);
                }
                return currentState;
              },
              { normalConnectors: [], onLadderGroupConnectors: [] } as {
                normalConnectors: Konva.Node[];
                onLadderGroupConnectors: Konva.Node[];
              }
            );
            shouldBeGroupedWithLadderConnectors.current = onLadderGroupConnectors;
            topOffset.current = STATIC_TOP_OFFSET * (normalConnectors.length || 0) + STATIC_TOP_OFFSET;

            setAllIntoCurrentState(onLadderGroupConnectors.length);
          }

          if (shouldCalcOthers) {
            const others = otherConnectors?.filter(node => node._id !== mainGroup.current?._id);
            others?.forEach(node => node?.attrs?.calcTopOffset());
          }
          if (shouldUseForceUpdate) {
            requestAnimationFrame(forceUpdate);
          }
        },
        [
          shouldGroupWithLadder,
          isOutgoing,
          to,
          toScreenNode?.attrs.isCollapsed,
          isTargetNotToOriginal,
          isFromScreen,
          shouldBeOutgoingLadder,
          from,
          fromCircleNode?.attrs.isCollapsed,
          isFromRepresentsChildren,
          forceUpdate,
        ]
      );

      const calcTopOffsetForce = useCallback(() => {
        calcTopOffset(false, true);
      }, [calcTopOffset]);

      useLayoutEffect(() => {
        calcTopOffset(true);
      }, [calcTopOffset]);

      useEffect(() => {
        return () => {
          otherConnectorsRef.current?.forEach(node => rafAppScheduler(() => node?.attrs?.calcTopOffset()));
        };
      }, []);

      useEffect(() => {
        const sub = CollapsedConnectorsMenuSubject$.subscribe(value => {
          if (value) {
            setIsActive(value.target._id === labelInnerRef.current?._id);
          } else {
            setIsActive(false);
          }
        });

        return () => {
          sub.unsubscribe();
        };
      }, []);

      const showTooltipMenu = useCallback(
        (event: KonvaEventObject<MouseEvent>) => {
          event.cancelBubble = true;
          const clientRect = labelInnerRef.current?.getClientRect();
          if (!clientRect) return;
          CollapsedConnectorsMenuClose();
          CollapsedConnectorsMenuSubject$.next({
            target: labelInnerRef.current!,
            clientRect,
            values:
              (shouldGroupWithLadder ? shouldBeGroupedWithLadderConnectors.current : otherConnectorsRef.current)?.map(
                value => ({
                  fromPath: value.attrs.connector.fromNodeOriginalPath,
                  fromPathId: value.attrs.connector.fromNode,
                  toPath: value.attrs.connector.toNodeOriginalPath,
                  toPathId: value.attrs.connector.to,
                })
              ) || [],
          });
        },
        [shouldGroupWithLadder]
      );

      useLayoutEffect(() => {
        if (groupFlag) {
          switch (true) {
            case shouldGroupWithLadder:
              let textRenderCollapsedWidth = new Konva.Text({
                text: t('JGraph:IslandConnector:OutgoingChildrenHiddenConnections', all),
                ...IslandConnectorTextProps,
              })?.getWidth();
              const newText = t('JGraph:IslandConnector:OutgoingChildrenHiddenConnections', all);

              if (textRenderCollapsedWidth !== textSize.current || newText !== showedText.current) {
                textSize.current = textRenderCollapsedWidth;
                showedText.current = newText;

                forceUpdate();
              }
              break;
            case all <= 1:
              const prevShowedText = showedText.current;
              calcOriginalText();
              if (showedText.current !== prevShowedText) forceUpdate();
              break;
            case all > 1:
              let pureTextRenderCollapsedWidth = new Konva.Text({
                text: t('JGraph:IslandConnector:HiddenConnections', all),
                ...IslandConnectorTextProps,
              })?.getWidth();
              showedText.current = t('JGraph:IslandConnector:HiddenConnections', all);
              if (pureTextRenderCollapsedWidth !== textSize.current) {
                textSize.current = pureTextRenderCollapsedWidth;
                forceUpdate();
              }
              break;
          }
        } else {
          calcOriginalText();
        }
      }, [all, calcOriginalText, forceUpdate, groupFlag, shouldGroupWithLadder]);

      if ((groupFlag && all > 1) || shouldGroupWithLadder)
        return (
          <Group
            ref={mainGroup}
            x={
              isOutgoing
                ? shouldGroupWithLadder
                  ? (positionFrom?.x || 0) + 50
                  : positionFrom?.x
                : (positionTo?.x || 0) - (textSize.current + 65 + 7)
            }
            y={
              isOutgoing
                ? shouldGroupWithLadder
                  ? (positionFrom?.y || 0) + topOffset.current
                  : positionFrom?.y
                : shouldGroupWithLadder || shouldBeIncomingLadder
                ? (positionTo?.y || 0) + topOffset.current
                : positionTo?.y || 0
            }
            name={isOutgoing ? 'OutgoingConnector' : 'IncomingConnector'}
            from={from}
            to={to}
            calcTopOffset={calcTopOffsetForce}
            setPositionsHandler={setPositionsHandler}
            onClick={showTooltipMenu}
            text={text}
            connector={connector}
            konvaOriginFrom={fromCircleNode}
            konvaOriginTo={toScreenNode}
            shouldGroupWithLadder={shouldGroupWithLadder}
          >
            {!isOutgoing && !shouldGroupWithLadder && (
              <>
                <Line
                  points={[textSize.current + 7, 0, textSize.current + 7 + 60, 0]}
                  strokeWidth={2}
                  stroke={debugActive ? border_active : gray_400}
                />
                <Path
                  x={textSize.current + 7 + 60}
                  stroke={debugActive ? border_active : gray_400}
                  y={0}
                  data={STATIC_TRIANGLE()}
                  strokeWidth={2}
                  hitStrokeWidth={10}
                />
              </>
            )}
            {!isOutgoing && (shouldGroupWithLadder || shouldBeIncomingLadder) && (
              <Group x={-27}>
                <IncomingCurvedLine
                  textSize={textSize.current}
                  topIncomingOffset={topOffset.current}
                  debugActive={debugActive}
                />
              </Group>
            )}
            {isOutgoing && !shouldGroupWithLadder && (
              <Line points={[0, 0, 60, 0]} strokeWidth={2} stroke={debugActive ? border_active : gray_400} />
            )}
            {isOutgoing && shouldGroupWithLadder && (
              <MemoOutgoingCurvedLine baseX={40} topIncomingOffset={topOffset.current} debugActive={debugActive} />
            )}

            <Group x={isOutgoing ? 42 : 0} y={0}>
              {!isOutgoing && (
                <Circle
                  x={textSize.current + 6}
                  width={8}
                  height={8}
                  fill={active || debugActive ? bg_active : gray_600}
                />
              )}
              {isOutgoing && <Circle x={0} width={8} height={8} fill={active || debugActive ? bg_active : gray_600} />}
              <Label ref={fillRefs} y={-14}>
                <Rect
                  width={textSize.current + 6}
                  height={BUTTON_WIDTH}
                  fill={active || debugActive ? bg_light_active : gray_100}
                  cornerRadius={8}
                  strokeWidth={1}
                  stroke={active || debugActive ? border_active : gray_300}
                />
                <Text
                  x={2}
                  ref={textFromRef}
                  text={showedText.current}
                  fill={active || debugActive ? text_active : gray_600}
                  {...IslandConnectorTextProps}
                />
              </Label>
            </Group>
          </Group>
        );

      return (
        <Group
          ref={mainGroup}
          x={
            isOutgoing
              ? (positionFrom?.x || 0) + (shouldBeOutgoingLadder || shouldGroupWithLadder ? 34 : 0)
              : (positionTo?.x || 0) - (textSize.current + 6 + BUTTON_WIDTH + 65)
          }
          y={
            isOutgoing
              ? (positionFrom?.y || 0) + (shouldBeOutgoingLadder || shouldGroupWithLadder ? topOffset.current : 0)
              : (positionTo?.y || 0) + (isToNodeScreen ? topOffset.current : 0)
          }
          name={isOutgoing ? 'OutgoingConnector' : 'IncomingConnector'}
          from={from}
          to={to}
          calcTopOffset={calcTopOffsetForce}
          setPositionsHandler={setPositionsHandler}
          text={text}
          connector={connector}
          shouldGroupWithLadder={shouldGroupWithLadder}
        >
          {isOutgoing && !shouldBeOutgoingLadder && !shouldGroupWithLadder && (
            <Line points={[0, 0, 60, 0]} strokeWidth={2} stroke={debugActive ? border_active : gray_400} />
          )}
          {isOutgoing && (shouldBeOutgoingLadder || shouldGroupWithLadder) && (
            <MemoOutgoingCurvedLine baseX={56} debugActive={debugActive} topIncomingOffset={topOffset.current} />
          )}
          {!isOutgoing && isToNodeScreen && (
            <IncomingCurvedLine
              textSize={textSize.current}
              debugActive={debugActive}
              topIncomingOffset={topOffset.current}
            />
          )}
          {!isOutgoing && !isToNodeScreen && (
            <IncomingStraightLine debugActive={debugActive} textSize={textSize.current} />
          )}
          <Group x={isOutgoing ? 42 + (isFromScreen ? 16 : 0) : 0} y={0}>
            {isOutgoing && <Circle x={0} width={8} height={8} fill={debugActive ? bg_active : gray_600} />}
            {!isOutgoing && (
              <Circle x={textSize.current + 6 + 27} width={8} height={8} fill={debugActive ? bg_active : gray_600} />
            )}
            <Label ref={fillRefs} y={-14} x={0}>
              <Rect
                width={textSize.current + 6}
                height={BUTTON_WIDTH}
                fill={debugActive ? bg_light_active : gray_100}
                cornerRadius={[8, 0, 0, 8]}
                strokeWidth={1}
                stroke={debugActive ? border_active : gray_300}
              />
              <Text
                x={2}
                ref={textFromRef}
                text={hideRootSlashInPath(showedText.current)}
                onMouseEnter={setTitleOnMouseEnterAndLeave(text)}
                onMouseLeave={setTitleOnMouseEnterAndLeave()}
                fill={debugActive ? text_active : gray_600}
                {...IslandConnectorTextProps}
              />
              <Group
                x={textSize.current + 5}
                onMouseEnter={onMouseEnterOrLeave}
                onMouseLeave={onMouseEnterOrLeave}
                onClick={scrollToTarget}
              >
                <Rect
                  fill='white'
                  width={BUTTON_WIDTH}
                  height={BUTTON_WIDTH}
                  cornerRadius={[0, 8, 8, 0]}
                  strokeWidth={1}
                  stroke={debugActive ? border_active : gray_300}
                />
                <KIcon x={6} y={6} icon={IconNames.stateLocation} />
              </Group>
            </Label>
          </Group>
        </Group>
      );
    }
  )
);
IslandConnector.displayName = 'IslandConnector';
