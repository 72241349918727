import { t } from 'localization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './style.module.scss';
import { useAppSelector } from '../../../../storeHooks';
import cn from 'classnames';
import { Icon } from '@just-ai/just-ui';
import { debugDataPipe$ } from '../../pipes/debugDataPipe';
import { scrollToTargetGlobal$ } from '../../utils/stageUtils';
import { getValidKonvaName } from '../../../../reducers/JGraph.reducer/Graph';
import { NeedToRedeployWidgetSubject$ } from '../NeedToRedeployWidget';
import { useTestWidgetContext } from '../../../TestWidget';
import { useJGraphContext } from '../../contexts/JGraphContext';

export const DebugIsRunning = React.memo(() => {
  const { tagLocatorsLoaded, editMenuBlock } = useAppSelector(state => ({
    tagLocatorsLoaded: state.JGraphReducer.tagLocatorsLoaded,
    editMenuBlock: state.JGraphReducer.editMenuBlock,
  }));
  const { showTestWidget, hideTestWidget } = useTestWidgetContext();
  const { isJGraphLite } = useJGraphContext();

  const [lastActive, setLastIndex] = useState<number>();
  const [actionsAvailable, setActionsAvailable] = useState<string[]>([]);

  useEffect(() => {
    const sub = debugDataPipe$.subscribe(next => {
      if (next.storedLocators.length > 0) {
        setActionsAvailable(next.storedLocators);
        setLastIndex(next.storedLocators.length - 1);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const isPrevDisabled = useMemo(() => {
    return lastActive === undefined || lastActive === 0;
  }, [lastActive]);
  const isNextDisabled = useMemo(() => {
    return lastActive === undefined || lastActive === actionsAvailable.length - 1;
  }, [lastActive, actionsAvailable]);

  const onClickPrev = useCallback(() => {
    if (isPrevDisabled) return;
    setLastIndex(prevIndex => {
      if (prevIndex) {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      }
      return actionsAvailable.length - 2;
    });
  }, [actionsAvailable.length, isPrevDisabled]);

  const onClickNext = useCallback(() => {
    if (lastActive === actionsAvailable.length - 1) return;
    setLastIndex(prevIndex => {
      if (prevIndex === undefined) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  }, [actionsAvailable.length, lastActive]);

  const goToTarget = useCallback(() => {
    setLastIndex(prevLastIndex => {
      if (prevLastIndex !== undefined && prevLastIndex === actionsAvailable.length - 1) {
        scrollToTargetGlobal$.next({
          targetPathId: getValidKonvaName(actionsAvailable[prevLastIndex]),
        });
      }
      return actionsAvailable.length - 1;
    });
  }, [actionsAvailable]);

  const redeployWidget = useCallback(() => {
    hideTestWidget();
    window.requestAnimationFrame(() => {
      showTestWidget(true);
      NeedToRedeployWidgetSubject$.next(false);
    });
  }, [hideTestWidget, showTestWidget]);

  useEffect(() => {
    if (lastActive !== undefined) {
      scrollToTargetGlobal$.next({
        targetPathId: getValidKonvaName(actionsAvailable[lastActive]),
      });
    }
  }, [actionsAvailable, lastActive]);

  if (!tagLocatorsLoaded || isJGraphLite) {
    return null;
  }
  return (
    <div className={cn(classes.debugContainer, { [classes.sidebarActive]: !!editMenuBlock })}>
      <div className={cn(classes.debugIsRunning, { [classes.sidebarActive]: !!editMenuBlock })}>
        <span className={classes.bg} />
        <span className={classes.text}>{t('JGraph:DebugIsRunning')}</span>
      </div>
      <div className={classes.debugControls}>
        <div className={cn(classes.control, { [classes.controlDisabled]: isPrevDisabled })} onClick={onClickPrev}>
          <Icon size='sm' name='faStepBackward' />
        </div>
        <div className={cn(classes.control, { [classes.controlDisabled]: isNextDisabled })} onClick={onClickNext}>
          <Icon size='sm' name='faStepForward' />
        </div>
        <div className={classes.control} onClick={goToTarget}>
          <Icon size='sm' name='farLocation' />
        </div>
        <div className={classes.control} onClick={redeployWidget}>
          <Icon size='sm' name='farSyncAlt' />
        </div>
        <div className={classes.control} onClick={hideTestWidget}>
          <Icon size='sm' name='faStop' />
        </div>
      </div>
    </div>
  );
});
