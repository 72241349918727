var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useLayoutEffect, useReducer } from 'react';
import { Modal, Spinner, SwitchButton, useTranslation } from '@just-ai/just-ui';
import SimilarsRange from '@just-ai/just-ui/dist/SimilarsRange';
import { useLoading } from '../../../../nlu-related/utils';
import { useNLUModuleContext } from '../../../../context';
var defaultValues = {
    answerValidationEnabled: false,
    answerValidationThreshold: 0.5,
    phraseValidationEnabled: false,
    phraseValidationThreshold: 0.5,
};
var SimilarsModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var _b = useLoading(), isLoading = _b[0], load = _b[1];
    var _c = useNLUModuleContext(), currentProject = _c.currentProject, SettingsApi = _c.SettingsApi, accountId = _c.accountId, setCurrentProject = _c.setCurrentProject;
    var t = useTranslation().t;
    var _d = useReducer(function (current, update) { return (__assign(__assign({}, current), update)); }, (currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation) || defaultValues), validationData = _d[0], setValidationData = _d[1];
    useLayoutEffect(function () {
        setValidationData((currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation) || defaultValues);
    }, [currentProject === null || currentProject === void 0 ? void 0 : currentProject.validation, isOpen]);
    var handleSimilarPhrasesToggle = useCallback(function (value) {
        setValidationData({ phraseValidationEnabled: value });
    }, [setValidationData]);
    var handleSimilarAnswersToggle = useCallback(function (value) {
        setValidationData({ answerValidationEnabled: value });
    }, [setValidationData]);
    var handleSimilarPhrasesRange = useCallback(function (value) {
        setValidationData({ phraseValidationThreshold: value });
    }, [setValidationData]);
    var handleSimilarAnswersRange = useCallback(function (value) {
        setValidationData({ answerValidationThreshold: value });
    }, [setValidationData]);
    var onValSettingsSave = useCallback(function (validationData) {
        if (!(currentProject === null || currentProject === void 0 ? void 0 : currentProject.shortName))
            return Promise.reject('No currentProject');
        var updatedSettings = {
            classificationAlgorithm: currentProject.classificationAlgorithm,
            language: currentProject.language,
            shared: currentProject.shared,
            extendedSettings: currentProject.extendedSettings,
            spellingCorrection: currentProject.spellingCorrection,
            timezone: currentProject.timezone,
            validation: validationData,
        };
        return SettingsApi.setProjectSettings(accountId, currentProject.shortName, updatedSettings).then(function () {
            if (setCurrentProject) {
                setCurrentProject(__assign(__assign({}, currentProject), { validation: validationData }));
            }
            onClose();
        });
    }, [SettingsApi, accountId, currentProject, onClose, setCurrentProject]);
    return (React.createElement(Modal, { isOpen: isOpen, title: t("FAQ:Home:SimilarsModal:Title"), buttonSubmitColor: 'primary', buttonSubmitText: t("Save"), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), onCancelClick: onClose, onActionClick: function () { return load(onValSettingsSave(validationData)); }, disableActionButtonAutoFocus: true },
        isLoading && React.createElement(Spinner, { size: 'sm' }),
        React.createElement("div", { className: 'margin-bottom-2x', style: { color: 'var(--gray-800)', whiteSpace: 'pre-line' } }, t('FAQ:Home:SimilarsModal:Content')),
        React.createElement("div", { className: 'mt-5 ml-1 mb-2' },
            React.createElement("div", { className: 'mb-5' },
                React.createElement(SwitchButton, { id: 'similarsPhrasesToggle', label: t('FAQ:Home:SimilarsModal:QuestionsLabel'), onChange: handleSimilarPhrasesToggle, value: validationData.phraseValidationEnabled }),
                validationData.phraseValidationEnabled && (React.createElement(SimilarsRange, { value: validationData.phraseValidationThreshold || 0, onChange: handleSimilarPhrasesRange }))),
            React.createElement("div", null,
                React.createElement(SwitchButton, { id: 'similarsAnswersToggle', label: t('FAQ:Home:SimilarsModal:AnswersLabel'), onChange: handleSimilarAnswersToggle, value: validationData.answerValidationEnabled }),
                validationData.answerValidationEnabled && (React.createElement(SimilarsRange, { value: validationData.answerValidationThreshold || 0, onChange: handleSimilarAnswersRange }))))));
};
export default React.memo(SimilarsModal);
