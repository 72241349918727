var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spinner, Icon } from '../';
import './FileUpload.scss';
import classNames from 'classnames';
import { ProgressBar } from '../ProgressBar';
const inputStyles = {
    display: 'block',
    opacity: 0,
    width: 0,
    height: 0,
    overflow: 'hidden',
    border: 'none',
};
const LoadingComponent = ({ loadingLabel, loadingType }) => {
    return (React.createElement("div", { className: 'file-upload__loading', onClick: event => event.stopPropagation() }, loadingType === 'spinner' ? (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { position: 'relative', height: 50 } },
            React.createElement(Spinner, { size: '3x' })),
        React.createElement("span", null, loadingLabel))) : (React.createElement("div", { className: 'file-upload__loading--progress' },
        React.createElement("p", null, loadingLabel),
        React.createElement(ProgressBar, null)))));
};
export const FileUpload = (_a) => {
    var { loading = false, loadingLabel, children, name, onChange, className, loadingType = 'spinner', accept, iconSize, disabled } = _a, restProps = __rest(_a, ["loading", "loadingLabel", "children", "name", "onChange", "className", "loadingType", "accept", "iconSize", "disabled"]);
    const onDrop = useCallback(acceptedFiles => {
        if (disabled)
            return;
        onChange && onChange(acceptedFiles, name);
    }, [disabled, name, onChange]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    return (React.createElement("div", Object.assign({}, getRootProps(), { className: classNames(className, 'FileUploadContainer', {
            FileUploadContainer_DnD: isDragActive && !disabled,
            'FileUploadContainer--disabled': disabled,
        }) }, restProps),
        loading && React.createElement(LoadingComponent, { loadingLabel: loadingLabel, loadingType: loadingType }),
        React.createElement("input", Object.assign({}, getInputProps({ style: inputStyles, accept }), { disabled: disabled })),
        isDragActive && !disabled && (React.createElement("div", { className: 'upload-icon-wrapper' },
            React.createElement(Icon, { name: 'farCloudUpload', color: 'primary', size: iconSize || '5x' }))),
        children));
};
