var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Nav } from 'reactstrap';
import { DropdownItem, DropdownToggle, DropdownButton, DropdownMenu } from '@just-ai/just-ui/dist/Dropdown';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import { IntersectionObserverRootType, ProviderObserverScroll, } from '@just-ai/just-ui/dist/ProviderObserverScroll';
import './style.scss';
import cn from 'classnames';
import Scrollbar from 'react-perfect-scrollbar';
import '@just-ai/just-ui/dist/Tabs/Tabs.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import TabWithContextMenu from './TabWithContextMenu';
var TabsView = function TabView(_a) {
    var tabs = _a.tabs, onChange = _a.onChange, activeTab = _a.activeTab, blackBackground = _a.blackBackground, onClose = _a.onClose, className = _a.className, collapseLimit = _a.collapseLimit, dropdownClass = _a.dropdownClass, _b = _a.wrapperId, wrapperId = _b === void 0 ? 'tabsWrapper' : _b, onVisibilityChange = _a.onVisibilityChange, contextKey = _a.contextKey, tabSelectProp = _a.tabSelectProp, renameTab = _a.renameTab, onTabDisable = _a.onTabDisable, restProps = __rest(_a, ["tabs", "onChange", "activeTab", "blackBackground", "onClose", "className", "collapseLimit", "dropdownClass", "wrapperId", "onVisibilityChange", "contextKey", "tabSelectProp", "renameTab", "onTabDisable"]);
    var _c = useState(function () { return tabs.slice(collapseLimit); }), collapsedTabs = _c[0], setCollapsedTabs = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState(window.innerWidth / 1.6), maxWidth = _e[0], setMaxWidth = _e[1];
    var timeout = useRef();
    useEffect(function () {
        var resizer = function () { return setMaxWidth(window.innerWidth / 1.6); };
        window.addEventListener('resize', resizer);
        return function () { return window.removeEventListener('resize', resizer); };
    }, []);
    useLayoutEffect(function () {
        if (tabs.length - 1 <= collapseLimit)
            setLoading(true);
        setCollapsedTabs(tabs.slice(collapseLimit));
        timeout.current = setTimeout(function () {
            setLoading(false);
        }, 200);
        return function () {
            clearTimeout(timeout.current);
        };
    }, [collapseLimit, tabs]);
    var onTabSelect = useCallback(function (tab) {
        tabSelectProp(tab.value)(true);
    }, [tabSelectProp]);
    return (React.createElement("div", { className: 'tabs-collapsible__wrapper', id: wrapperId, style: restProps.style },
        React.createElement(Nav, { className: cn('justui_tabs', {
                'justui_tabs-closable': restProps.closable,
                'justui_tabs-dark': blackBackground,
                'justui_tabs-compact': restProps.compact,
                'justui_tabs-bordered': restProps.bordered,
                'justui_tabs-bold': restProps.bold,
                'justui_tabs-fullwidth': restProps.fullWidth,
            }, className) },
            React.createElement(ProviderObserverScroll, { contextKey: contextKey, debounceTime: 100, threshold: 1, onChange: onVisibilityChange, intersectionObserverRootType: IntersectionObserverRootType.CHILDREN, childrenRefPropName: 'containerRef' },
                React.createElement(Scrollbar, { options: { suppressScrollX: true, suppressScrollY: true }, style: { display: 'flex', overflow: 'hidden', maxWidth: maxWidth, minWidth: '50px' } }, tabs.map(function (tabItem, index) { return (React.createElement(TabWithContextMenu, __assign({ tabItem: tabItem, contextKey: contextKey, index: index, collapseLimit: collapseLimit, activeTab: activeTab, tabSelectProp: tabSelectProp, onClose: onClose, renameTab: renameTab, key: tabItem.value, onTabDisable: onTabDisable }, restProps))); }))),
            !loading && collapsedTabs.length > 0 && (React.createElement(DropdownButton, { direction: 'down', dark: blackBackground, "data-test-id": 'more-button', className: dropdownClass },
                React.createElement(DropdownToggle, { iconButton: true, compact: true },
                    React.createElement(Icon, { name: 'faChevronDown' })),
                React.createElement(DropdownMenu, { className: 'faq-tabs-dropdown-menu' }, collapsedTabs.map(function (tab) { return (React.createElement(DropdownItem, { key: tab.value, onClick: function () { return onTabSelect(tab); }, "data-test-id": 'dropdown-item', className: cn({ disabled: tab.skillDisabled }) }, tab.name)); })))))));
};
export var TabsCollapsible = function TabsCollapsible(_a) {
    var scrollIntoView = _a.scrollIntoView, tabsProp = _a.tabs, name = _a.name, onChange = _a.onChange, onClose = _a.onClose, onTabDelete = _a.onTabDelete, onTabRename = _a.onTabRename, onTabDisable = _a.onTabDisable, restProps = __rest(_a, ["scrollIntoView", "tabs", "name", "onChange", "onClose", "onTabDelete", "onTabRename", "onTabDisable"]);
    var _b = useState(tabsProp), tabs = _b[0], setTabs = _b[1];
    var _c = useState(tabs.length), limit = _c[0], setLimit = _c[1];
    var contextKey = useRef((Math.random() + 1).toString(36).substring(7));
    useEffect(function () {
        setTabs(tabsProp);
        setLimit(tabsProp.length);
    }, [tabsProp]);
    var onVisibilityChange = useCallback(function (visibleList) {
        if (tabs.length > 0 && visibleList.length < 1)
            return;
        setLimit(visibleList.length);
    }, [tabs]);
    var getTabClickHandler = useCallback(function (value) { return function (fromCollapsed) {
        if (fromCollapsed === true) {
            var updatedTabs = __spreadArray([], tabs, true);
            var targetElemIndex = updatedTabs.findIndex(function (tab) { return tab.value === value; });
            updatedTabs.unshift(updatedTabs.splice(targetElemIndex, 1)[0]);
            setTabs(updatedTabs);
        }
        onChange && onChange(value, name);
    }; }, [name, onChange, tabs]);
    return (React.createElement(TabsView, __assign({ contextKey: contextKey.current, tabs: tabs, collapseLimit: limit, onVisibilityChange: onVisibilityChange, tabSelectProp: getTabClickHandler, onClose: onTabDelete, renameTab: onTabRename, onTabDisable: onTabDisable }, restProps)));
};
