import { createAsyncThunk } from '@reduxjs/toolkit';
import { JStateMovementsData } from '@just-ai/api/dist/generated/Editorbe';

import { RootState } from 'storeTypes';

import { OnStateMoved$ } from 'modules/JGraph/view/StickerLayer';

import { GraphApi } from '../utils';

const moveState = createAsyncThunk('JGraph/moveState', async (moveJStateData: JStateMovementsData, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  // @ts-ignore
  const userAccountId = state.CurrentUserReducer.account?.id;
  const accountId = state.CurrentAccountReducer.account?.id;
  const projectShortName = state.CurrentProjectsReducer.currentProject;

  const { data: graphModel } = await GraphApi.moveStates(userAccountId || accountId, projectShortName, moveJStateData);

  OnStateMoved$.next(undefined);

  return graphModel;
});

export default moveState;
