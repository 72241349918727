import { GuideStep } from './types';

export async function beforeStepHandler(
  step: GuideStep,
  allSteps: GuideStep[]
): Promise<[GuideStep, number | undefined]> {
  let nextStepIndex: number | undefined;
  let nextStepWaitEvent: string | null | undefined;
  if (step.beforeStep) {
    await step.beforeStep({
      goToStep: (stepIndex, config) => {
        nextStepIndex = stepIndex;
        if (config?.hasOwnProperty('waitEvent')) {
          nextStepWaitEvent = config.waitEvent;
        }
      },
    });
  }
  if (nextStepIndex !== undefined) {
    const nextStep = allSteps[nextStepIndex];
    if (nextStepWaitEvent !== undefined) {
      nextStep.waitEvent = nextStepWaitEvent;
    }
    return [nextStep, nextStepIndex];
  }
  return [step, undefined];
}
