import React, { FC, useCallback } from 'react';
import { withController } from './Controller';

import { CommonActivationSelectState } from '../../RSAddingMenu/ActivationsAddingMenu/CommonActivationSelectState';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

const StateFieldView: FC<CommonFieldProps> = ({ name, label, onChange, isRequired, value, description }) => {
  const onChangeWithCreationHandler = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className='form-group form-group-fix'>
      <CommonActivationSelectState
        fromStateValue=''
        toStateValue={value}
        isGlobal={false}
        isFromState={false}
        isToState={true}
        onChangeStateValue={onChangeWithCreationHandler}
        label={<CommonLabel name={name} label={label} description={description} isRequired={isRequired} />}
      />
    </div>
  );
};

export const StateField = withController(StateFieldView);
