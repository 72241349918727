import React, { ContextType, createRef, PureComponent, SyntheticEvent } from 'react';
import { Icon, Modal, Textarea, InputGroup, InputText, ScrollArea, Spinner } from '@just-ai/just-ui';
import history from 'appHistory';
import { AppContext, MAX_NETWORK_TIMEOUT_FOR_IMPORT } from '../../../Caila/components/AppContext';
import localize from 'localization';

import { i18nTranslation } from '../../../Caila/locale/i18nToLocalize';
import { TestWidgetContextType, withTestWidgetContext } from 'modules/TestWidget/context/TestWidgetContext';
import {
  EntityMarkupData,
  InferenceResultData,
  SpelledWordsData,
  ProjectSettingsData,
  SkillType,
  SkillData,
} from '../../../Caila/api/client';

import {
  emphasizeMostWeightedText,
  formatJsonOrReturnOriginalValue,
  getEntityUrl,
  getErrorArgsFromReason,
  getErrorCodeFromReason,
  getIntentUrl,
  getMessageFromError,
} from '../../../Caila/utils';
import { EntityType } from '../../../Caila/model';

import CdqaModalContent from './CdqaModalContent';

import SpelledWords from './SpelledWords';
import WidgetEntity from './Entity';
import Intent from './Intent';

import './style.scss';
import classes from './style.module.scss';
import { AppLogger } from 'services/AppLogger';
import { CDQAResponseData } from '@just-ai/api/dist/services/SkillsApiService';

const NBEST_VALUE = 20;

type WidgetProps = {
  close: () => unknown;
  retraining?: boolean;
  widgetContext: TestWidgetContextType;
};

type CdqaStateData = {
  raw: CDQAResponseData;
  mostWeighted: {
    text: string;
    confidence: number;
  };
  restAnswers: {
    text: string;
    confidence: number;
  }[];
};

class WidgetState {
  error: string | null = null;
  loading: boolean = false;
  showValue: string = '';
  entities: Array<EntityMarkupData> = [];
  variants: Array<InferenceResultData> = [];
  spelledWords: Array<SpelledWordsData> = [];
  phraseText: string = '';
  projectSettingsData: ProjectSettingsData = {};
  isFirstOpen: boolean = true;
  cdqaModalOpen: boolean = false;
  cdqaSkill?: SkillData;
  cdqaData?: CdqaStateData;
}

class NluWidgetComponent extends PureComponent<WidgetProps, WidgetState> {
  static contextType = AppContext;
  context!: ContextType<typeof AppContext>;

  timeout = 0;

  input = createRef<HTMLInputElement>();
  translation = i18nTranslation('Widget');

  defaultState: WidgetState = new WidgetState();

  state = { ...this.defaultState };

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  componentDidMount() {
    this.updateProjectSettingsData();
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  componentDidUpdate() {
    if (!this.context.currentProject || !this.input.current) return;
    if (!this.context.currentProject.spellingCorrection && this.state.phraseText !== this.input.current.value) {
      this.setState({ phraseText: this.input.current?.value });
    }
  }

  updateProjectSettingsData = async () => {
    const { accountId, projectShortName, SettingsApi, currentProject, setCurrentProject, skillsApiService } =
      this.context;
    const projectSettingsRes = await SettingsApi.getProjectSettings(accountId, projectShortName);
    const skillsList = await skillsApiService.listSkills();
    const cdqaSkill = skillsList.find(skill => skill.type === SkillType.Cdqa);
    this.setState({
      projectSettingsData: projectSettingsRes.data,
      cdqaSkill: cdqaSkill ? { ...cdqaSkill, name: cdqaSkill.name.split('.').splice(1).join('.') || '' } : undefined,
    });
    if (currentProject?.id) {
      setCurrentProject({
        ...currentProject,
        spellingCorrection: projectSettingsRes.data.spellingCorrection,
        isCdqaCreated: Boolean(cdqaSkill),
        isCdqaEnabled: cdqaSkill?.enabled,
      });
    }
  };

  checkPhrase = async () => {
    const { accountId, projectShortName, InferenceApi, skillsApiService } = this.context;
    if (!this.input.current) return;
    this.input.current.setSelectionRange(0, this.input.current.value.length);
    this.setState({
      loading: true,
      isFirstOpen: false,
    });
    try {
      const responseInference = await InferenceApi.inference(
        accountId,
        projectShortName,
        {
          phrase: { text: this.input.current.value },
          nBest: NBEST_VALUE,
          showDebugInfo: true,
        },
        undefined,
        undefined,
        { timeout: MAX_NETWORK_TIMEOUT_FOR_IMPORT }
      );
      let cdqaData: CdqaStateData | undefined;
      if (this.state.cdqaSkill && this.state.cdqaSkill.enabled && this.context.currentProject?.isCdqaEnabled) {
        try {
          const cdqaDataRawResponse = await skillsApiService.simpleCDQAInference(
            this.state.cdqaSkill.id,
            this.input.current.value
          );
          const cdqaDataRaw = cdqaDataRawResponse.output[0].extracted_texts_list[0];
          const [mostWeightedAnswer, ...restAnswers] = cdqaDataRaw.texts.sort(
            (text1, text2) => text2.confidence - text1.confidence
          );
          const mostWeightedTextElementString = emphasizeMostWeightedText(
            mostWeightedAnswer.text,
            cdqaDataRaw.source_text
          );
          cdqaData = {
            raw: cdqaDataRaw,
            mostWeighted: { ...mostWeightedAnswer, text: mostWeightedTextElementString },
            restAnswers,
          };
        } catch (error) {
          AppLogger.error({
            message: 'error processing cdqa data',
            exception: error as Error,
          });
        }
      }
      const { data } = responseInference;
      const newState = {
        entities: data.phrase && data.phrase.entities ? data.phrase.entities.filter(entity => !entity.hidden) : [],
        variants: data.variants ? data.variants : [],
        spelledWords: data.spelledWords ? data.spelledWords : [],
        phraseText: data.phrase && data.phrase.text ? data.phrase.text : '',
        loading: false,
        cdqaData,
      };
      this.setState(newState);
    } catch (e) {
      const errorArgs = getErrorArgsFromReason(e);
      const errorCode = getErrorCodeFromReason(e);
      this.setState({
        loading: false,
        isFirstOpen: true,
        error: errorArgs || errorCode ? getMessageFromError(errorArgs, errorCode) : undefined,
      });
    }
  };

  close = (e: SyntheticEvent<any, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const { close } = this.props;
    if (close) {
      close();
    }
  };

  clear = () => {
    if (this.input.current) {
      this.input.current.value = '';
      this.input.current.focus();

      this.setState({
        ...this.defaultState,
      });
    }
  };

  intentClick = (variant?: InferenceResultData) => () => {
    if (!variant || !variant.intent || !variant.intent.id || variant?.intent?.shared) return;
    history.push(`/${this.context.projectShortName}${getIntentUrl(variant.intent.id)}`);
  };

  entityClick = (entity?: EntityMarkupData) => () => {
    if (!entity || entity.shared) return;
    if (entity.system)
      history.push(`/${this.context.projectShortName}${getEntityUrl(EntityType.system, entity.entity)}`);
    else history.push(`/${this.context.projectShortName}${getEntityUrl(EntityType.named, entity.entityId)}`);
  };

  showJson = (json?: string) => () => {
    if (!json) return;

    this.setState({
      showValue: formatJsonOrReturnOriginalValue(json),
    });
  };

  hideModal = () => {
    this.setState({
      showValue: '',
    });
  };

  hideCdqaModal = () => {
    this.setState({
      cdqaModalOpen: false,
    });
  };

  enableSpellingCorrection = () => {
    const { accountId, projectShortName, SettingsApi, setCurrentProject, currentProject } = this.context;
    const projectSettingsData = {
      ...this.state.projectSettingsData,
      spellingCorrection: true,
    };
    SettingsApi.setProjectSettings(accountId, projectShortName, projectSettingsData).then(() => {
      if (currentProject) setCurrentProject({ ...currentProject, spellingCorrection: true });
      this.checkPhrase();
    });
  };

  render() {
    const { showValue, variants, entities, error, spelledWords, phraseText, loading } = this.state;
    const { retraining, widgetContext } = this.props;
    const training = widgetContext.trainingNlu;
    const { t } = this.translation;
    const isVariantsExist = variants.length && variants.find(variant => variant.confidence && variant.intent);
    const hasEntities = entities.length > 0;

    return (
      <div className='widget' data-test-id='Widget'>
        <div className='widget-header' data-test-id='Widget.Header'>
          <div className='textes justify-content-between'>
            <div className='title' data-test-id='Widget.Header.Title'>
              {t('widgetTitle')}
            </div>
            <div className='curp' onClick={this.clear} data-test-id='Widget.Header.ClearButton'>
              {t('clearButton')}
            </div>
          </div>
          <div className='iconClose' onClick={this.close} data-test-id='Widget.Header.CloseButton'>
            <Icon size='1x' name='faTimesCircle' />
          </div>
        </div>
        <div className='widget-body'>
          {error ? (
            <div className='error' data-test-id='Widget.Error'>
              {error}
            </div>
          ) : null}
          <div>
            {(training || retraining) && (
              <div className='training' data-test-id='Widget.Training'>
                <div className='flex-grow'>{t('learningStatusText')}</div>
              </div>
            )}
            <InputGroup
              className='phrase'
              AppendInner={[
                {
                  name: 'faPaperPlane',
                  color: 'primary',
                  size: 'sm',
                  onClick: this.checkPhrase,
                  'data-test-id': 'Widget.SendPhraseButton',
                },
              ]}
            >
              <InputText
                innerRef={this.input}
                onKeyPress={({ key }: React.KeyboardEvent) => key === 'Enter' && this.checkPhrase()}
                data-test-id='Widget.InputPhrase'
              />
            </InputGroup>
          </div>
          <div className='details'>
            {(training || retraining) && (
              <div className='training' data-test-id='Widget.Training'>
                <div>
                  <Spinner size='2x' />
                </div>
              </div>
            )}
            <ScrollArea vertical>
              {loading ? (
                <Spinner size='4x' />
              ) : this.state.isFirstOpen ? (
                <div className='center-text text-muted'>{t('emptyListCaption')}</div>
              ) : (
                <div>
                  <SpelledWords
                    spelledWords={spelledWords}
                    sentence={phraseText}
                    onChange={this.checkPhrase}
                    spellingCorrection={Boolean(this.context.currentProject?.spellingCorrection)}
                    enableSpellingCorrection={this.enableSpellingCorrection}
                  />
                  {this.state.cdqaData?.mostWeighted && (
                    <div className={classes.widgetCdqa}>
                      <h3>CDQA</h3>
                      <div className={classes.widgetCdqa__info} onClick={() => this.setState({ cdqaModalOpen: true })}>
                        <p>{this.state.cdqaSkill?.name}</p>
                        <div
                          className={classes.widgetCdqa__clamped}
                          dangerouslySetInnerHTML={{ __html: this.state.cdqaData.mostWeighted.text }}
                        />
                      </div>
                    </div>
                  )}
                  <div className='widget-intents'>
                    <h5>{isVariantsExist ? t('intentsTitle') : t('intentNotRecognizedCaption')}</h5>

                    {variants &&
                      variants.map((variant, index) => (
                        <Intent
                          variant={variant}
                          onClick={this.intentClick(variant)}
                          key={`variant${index}`}
                          isShared={variant.intent?.shared}
                        />
                      ))}
                  </div>

                  {hasEntities ? (
                    <div className='widget-entity'>
                      <h5>{t('entitiesTitle')}</h5>
                      {entities.map((entity, index) => {
                        // eslint-disable-next-line array-callback-return
                        if (entity.hidden) return;
                        return (
                          <WidgetEntity
                            entityObj={entity}
                            key={`entities${index}`}
                            onNameClick={this.entityClick(entity)}
                            onValueClick={this.showJson(entity.value)}
                            isShared={entity.shared}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              )}
            </ScrollArea>
          </div>
        </div>
        <Modal
          isOpen={Boolean(showValue)}
          onCancelClick={this.hideModal}
          title={t('modalHeader')}
          buttonCancelText={t('Widget:modalCloseButton')}
        >
          <Textarea rows={10} value={showValue} />
        </Modal>
        {this.state.cdqaData && this.state.cdqaSkill && (
          <Modal
            isOpen={this.state.cdqaModalOpen}
            onClickCloseIcon={this.hideCdqaModal}
            title={localize.translate('Answer')}
          >
            <CdqaModalContent
              answerData={this.state.cdqaData.mostWeighted}
              question={this.input.current?.value}
              restAnswers={JSON.stringify(this.state.cdqaData.raw, null, 2)}
              skillName={this.state.cdqaSkill.name}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export const NluWidget = withTestWidgetContext(NluWidgetComponent);
