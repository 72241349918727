import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from '../contexts/transtationContext';
import { ClickAwayListener, Icon } from '../index';
//TODO fix floating position
export const UrlEditor = ({ position, text, url, close = () => { }, save = () => { } }) => {
    const { t } = useTranslation();
    const positionCalculated = useRef(false);
    const containerRef = useRef(null);
    const [containerRefOffset, setContainerRefOffset] = useState({ left: 0, top: 0, height: 0, width: 0 });
    const linkRef = useRef(null);
    const textRef = useRef(null);
    const [linkValue, setLinkValue] = useState(url);
    const [textValue, setTextValue] = useState(text);
    const checkKey = useCallback((event) => {
        switch (event.key) {
            case 'Escape': {
                close();
                break;
            }
            case 'Enter': {
                if (linkRef.current && textRef.current)
                    save(linkRef.current.value, textRef.current.value);
                break;
            }
        }
    }, [close, save]);
    useEffect(() => {
        document.addEventListener('keydown', checkKey);
        return () => {
            document.removeEventListener('keydown', checkKey);
        };
    }, [checkKey]);
    useEffect(() => {
        const openEvent = new CustomEvent('customConfirmationOpened', {
            detail: {
                opened: true,
            },
        });
        const closeEvent = new CustomEvent('customConfirmationOpened', {
            detail: {
                opened: false,
            },
        });
        document.dispatchEvent(openEvent);
        if (containerRef.current) {
            const relativeParent = containerRef.current.parentNode;
            if (relativeParent) {
                const relativeParentPosition = relativeParent.getBoundingClientRect();
                let left = 0;
                if (position.left + 420 > relativeParentPosition.width) {
                    left = position.left + 420 - relativeParentPosition.width;
                }
                positionCalculated.current = true;
                setContainerRefOffset(prev => (Object.assign(Object.assign({}, prev), { left: left })));
            }
        }
        return () => {
            document.dispatchEvent(closeEvent);
        };
    }, [position.left]);
    const clearText = useCallback((ref) => () => {
        if (ref.current)
            ref.current.value = '';
    }, []);
    return (React.createElement(ClickAwayListener, { handleClickOut: close, handleEnabled: positionCalculated.current },
        React.createElement("div", { className: classNames('WysiwygUrlEditor', { show: positionCalculated.current }), "data-test-id": 'WysiwygUrlEditor', style: positionCalculated.current
                ? { top: position.top + position.height, left: position.left - containerRefOffset.left }
                : {}, ref: containerRef },
            React.createElement("div", { className: 'urlEditorInput' },
                React.createElement(Icon, { name: 'farLink', wrapperClassName: 'urlEditorInputIconLeft' }),
                React.createElement("input", { type: 'text', ref: linkRef, autoFocus: true, "data-test-id": 'WysiwygUrlEditor:UrlInput', defaultValue: url, onChange: event => setLinkValue(event.target.value) }),
                linkValue && (React.createElement("div", { className: 'urlEditorInputIconRight', onClick: clearText(linkRef) },
                    React.createElement(Icon, { name: 'farTimes' })))),
            React.createElement("div", { className: 'urlEditorInput' },
                React.createElement(Icon, { name: 'faText', wrapperClassName: 'urlEditorInputIconLeft' }),
                React.createElement("input", { type: 'text', "data-test-id": 'WysiwygUrlEditor:TextInput', ref: textRef, defaultValue: text, onChange: event => setTextValue(event.target.value) }),
                textValue && (React.createElement("div", { className: 'urlEditorInputIconRight', onClick: clearText(textRef) },
                    React.createElement(Icon, { name: 'farTimes' })))),
            React.createElement("div", { className: 'helperText', "data-test-id": 'WysiwygUrlEditor:SaveBtn' }, t('UrlEditor:EnterToSave')))));
};
