import { useEffect, useState, useCallback, useMemo } from 'react';

import { ReportType } from '@just-ai/api/dist/generated/Reporter';

import { useAppSelector } from 'storeHooks';

import { LocalStorageService } from 'services/Storage/LocalStorageService';
import { useWSContext } from 'modules/Notifications/context/WSContext';
import { WS_TASKS_PATH, TASKS_PATH, TASKS_LIMIT, DELETE_PATH } from 'modules/Notifications/context/TasksContext';
import { useAppContext } from 'modules/Caila/components/AppContext';

import { TaskStatusData } from './types';

import { getNotificationViewInfo } from './Notification';

const getAlertTypeByTask = (task: TaskStatusData) => {
  const code = task.message.code.code;
  if (code.endsWith('completed')) return 'success';
  if (code.endsWith('failed')) return 'error';
  if (code.endsWith('created')) return 'info';
  if (code.endsWith('started')) return 'info';
  if (code.endsWith('progress')) return 'warning';
  return 'warning';
};

function wrapTaskUpdatesToTasks(
  tasks: TaskStatusData[],
  tasksUpdates: TaskStatusData[]
): { newTasks: TaskStatusData[]; wrappedTasks: TaskStatusData[] } {
  let wrappedTasks = [...tasks];
  let newTasks = [];
  for (let taskUpdate of tasksUpdates) {
    const existedTaskIndex = wrappedTasks.findIndex(el => el.taskId === taskUpdate.taskId);
    if (existedTaskIndex === -1) {
      newTasks.push(taskUpdate);
      wrappedTasks = [taskUpdate, ...wrappedTasks];
      continue;
    }
    const existedTask = wrappedTasks[existedTaskIndex];
    const notifications = existedTask.notifications || [];
    existedTask.notifications = undefined;
    notifications.unshift(existedTask);
    taskUpdate.notifications = notifications;

    wrappedTasks.splice(existedTaskIndex, 1);
    newTasks.push(taskUpdate);
    wrappedTasks = [taskUpdate, ...wrappedTasks];
  }
  return { newTasks, wrappedTasks };
}

const billingReportTaskTypes = [
  ReportType.NLUBILLING,
  ReportType.ASRBILLING,
  ReportType.TTSBILLING,
  ReportType.TELEPHONYBILLING,
];
const allowedTaskTypes = [...billingReportTaskTypes];

function filterTasks(userId: number) {
  return (task: TaskStatusData) => {
    return task.userId === userId && allowedTaskTypes.includes(task.message.data.reportType);
  };
}

export const ACCOUNT_ADMIN_VIEWED_WS_TASKS_KEY = 'AccountAdminViewedWSTasks';

const localStorageService = new LocalStorageService();
localStorageService.addPermanentStoredRules(ACCOUNT_ADMIN_VIEWED_WS_TASKS_KEY);

export function useAccountAdminWSTasks(conf: { onAllTasksDeleted?: () => void } = {}) {
  const { addAlert } = useAppContext();
  const onAllTasksDeleted = conf.onAllTasksDeleted;
  const { userId } = useAppSelector(store => ({
    userId: store.CurrentUserReducer?.currentUser?.id,
  }));
  const [tasks, setTasks] = useState<TaskStatusData[]>([]);
  const [viewedTasks, setViewedTasks] = useState<Set<string>>(() => new Set());
  const wsContext = useWSContext();

  const createToast = useCallback(
    (task: TaskStatusData) => {
      const info = getNotificationViewInfo(task);
      if (!info || !info.isShowInAlert) return;
      const Cmp = info.component;
      addAlert({
        type: getAlertTypeByTask(task),
        title: info.title,
        alertType: 'toasts',
        duration: 5000,
        messageComponent: () => <Cmp task={task} notificationViewInfo={info} onlyContent />,
        className: 'aa_notification_task',
        message: info.description,
        showed: true,
        time: Date.now(),
      });
    },
    [addAlert]
  );

  const initWS = useCallback(async () => {
    if (!userId) return;
    const viewedTasksResponse = await localStorageService.get<string[]>(ACCOUNT_ADMIN_VIEWED_WS_TASKS_KEY);
    setViewedTasks(new Set(viewedTasksResponse.success ? viewedTasksResponse.payload ?? [] : []));

    const eventsSubscriptionId = wsContext.subscribe(
      WS_TASKS_PATH,
      (data: TaskStatusData[] | { records: TaskStatusData[] }) => {
        setTasks(tasks => {
          let oldTasks = [...tasks];
          if (Array.isArray(data)) {
            const { newTasks, wrappedTasks } = wrapTaskUpdatesToTasks(oldTasks, data);
            oldTasks = wrappedTasks;
            newTasks.filter(filterTasks(userId)).forEach(createToast);
          } else {
            oldTasks = data.records;
          }
          return oldTasks.filter(filterTasks(userId));
        });
      }
    );
    wsContext.send(TASKS_PATH, { size: TASKS_LIMIT });
    return () => wsContext.unsubscribe(WS_TASKS_PATH, eventsSubscriptionId);
  }, [userId, wsContext, createToast]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    initWS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteTask = useCallback(
    (task: TaskStatusData) => {
      wsContext.send(`${DELETE_PATH}/${task.taskId}`);
      setTasks(list => {
        const filteredTasks = list.filter(el => el.taskId !== task.taskId);
        if (filteredTasks.length === 0) onAllTasksDeleted?.();
        return filteredTasks;
      });
    },
    [onAllTasksDeleted, wsContext]
  );

  const onDeleteAllTasks = useCallback(() => {
    wsContext.send(DELETE_PATH);
    setTasks([]);
    onAllTasksDeleted?.();
  }, [onAllTasksDeleted, wsContext]);

  const isAllTasksViewed = useMemo(() => {
    return tasks.every(task => viewedTasks.has(task.taskId));
  }, [viewedTasks, tasks]);

  const markTasksAsViewed = useCallback(() => {
    const newViewedTasks = new Set(tasks.map(task => task.taskId));
    setViewedTasks(newViewedTasks);
    localStorageService.set(ACCOUNT_ADMIN_VIEWED_WS_TASKS_KEY, Array.from(newViewedTasks));
  }, [tasks]);

  return { tasks, onDeleteTask, onDeleteAllTasks, isAllTasksViewed, markTasksAsViewed };
}
