export enum ConversationalCloudProduct {
  Jaicp = 'jaicp',
  Aimylogic = 'aimylogic',
  Aimyvoice = 'aimyvoice',
  Cc = 'cc',
  Caila = 'caila',
}

export enum UiMode {
  NoCode_user = 'NoCode_user',
  DevX_user = 'DevX_user',
  Manager_user = 'Manager_user',
}

export interface DomainOptions {
  domain: string;
  redirectAllowed: boolean;
  defaultTheme: string;
  product: ConversationalCloudProduct;
  showInNavigation: boolean;
}

export type CloudDomains = { [key: string]: DomainOptions };

export type BEError = {
  args?: {
    [key: string]: string | number;
  } | null;
  error: string;
  message?: string;
  uuid?: string;
};

type LimitData = { tariffLimit: number; extraLimit: number; usedLimit: number };

export type RCurrentUser = {
  id: number;
  accountOwner: boolean;
  account: { id: number; partner?: any; projectReportsEnabled: boolean; name: string };
  ccSession: boolean;
  email: string;
  login?: string;
  subscription?: any;
  emailVerified: boolean;
  emailVerificationRequired: boolean;
  internal: boolean;
  logInAccount: { accountId: number } | null;
  fullName: string;
  language: string;
  phoneNumberNational?: {
    countryCode?: number;
    nationalNumber?: string;
  };
  phone: string | null;
  phoneVerified: boolean;
  tariff: {
    countryIsoCode: string;
    tariffUniqueName: string;
    nextTariffUniqueName: string;
    blocked: boolean;
    blockType: string;
    dueDate: string;
    startDate: string;
    paymentSystem: string;
    limitsData: {
      asrSeconds: LimitData;
      nluSeconds: LimitData;
      justaiSeconds: LimitData;
      uniqueUsers: LimitData;
      sms: LimitData;
    };
    manualControl: boolean;
    tariffName: string;
    price: number;
  };
};
