var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, Fragment, useState } from 'react';
import { Button, Icon, InputText } from '@just-ai/just-ui';
import { useLabelsContext } from '../../../../../Labels/context/LabelsContext';
export function LabelsGroups(_a) {
    var _this = this;
    var groups = _a.groups, currentLabels = _a.currentLabels, onChange = _a.onChange, t = _a.t, createNewLabelAndAddToMessage = _a.createNewLabelAndAddToMessage;
    var _b = useState(), addNew = _b[0], setAddNew = _b[1];
    var hasTagCreatePermission = useLabelsContext().hasTagCreatePermission;
    var onChangeAddNew = useCallback(function (value, groupId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createNewLabelAndAddToMessage(value, groupId)];
                case 1:
                    _a.sent();
                    setAddNew(undefined);
                    return [2 /*return*/];
            }
        });
    }); }, [createNewLabelAndAddToMessage]);
    return (React.createElement("div", { className: 'MessageForm_labelsGroups' }, Array.isArray(groups) &&
        groups.map(function (x) { return (React.createElement(Fragment, { key: x.name },
            x.name && React.createElement("label", { key: x.id + 'label' }, x.name),
            React.createElement("div", { key: x.id + 'label list' },
                Array.isArray(x === null || x === void 0 ? void 0 : x.labels) &&
                    x.labels.map(function (label) { return (React.createElement(Button, { size: 'sm', disabled: !hasTagCreatePermission, key: label.id, className: 'Message_label_button', onClick: function () { return onChange(label); }, outline: Boolean(currentLabels.find(function (x) { return x.id === label.id; })) === false, color: Boolean(currentLabels.find(function (x) { return x.id === label.id; })) ? 'success' : 'secondary' }, label.name)); }),
                addNew !== x.id && (React.createElement(Button, { color: 'primary', name: 'farPlus', size: 'sm', outline: true, onClick: function () { return setAddNew(x.id); }, disabled: !hasTagCreatePermission },
                    React.createElement(Icon, { name: 'farPlus' }),
                    t('Analytics: label new'))),
                addNew === x.id && (React.createElement(InputText, { autoFocus: true, 
                    //@ts-ignore
                    onBlur: function (event) {
                        var _a;
                        event.preventDefault();
                        if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) {
                            onChangeAddNew(event.target.value, x.id);
                        }
                    }, 
                    //@ts-ignore
                    onKeyPress: function (event) {
                        event.stopPropagation();
                        if ((event === null || event === void 0 ? void 0 : event.key) === 'Enter') {
                            event.preventDefault();
                            event.target.blur();
                            event.target.value = '';
                        }
                    } }))))); })));
}
