import React from 'react';
import { format } from 'date-fns';
import cn from 'classnames';
import { AudioFieldMemo, IconButton, Icon, validateUrl } from '../index';
import { useTranslation } from '../contexts/transtationContext';
import { AnswerReplyType } from './types';
export default function MediaPartBlock(props) {
    const { t } = useTranslation();
    const { type, value, onPartEdit, fileName, error, uploadDate, audioName, writeDisabled } = props;
    if (!validateUrl(value))
        return null;
    const parsedUrl = new URL(value);
    const fileNameFromUrl = decodeURI(parsedUrl.href).split('/').pop();
    return (React.createElement("div", { className: 'faq-answer-part__media' },
        React.createElement("div", { className: cn(`faq-answer-part__media__content faq-answer-part__media__content--${type}`) },
            type === AnswerReplyType.image && (React.createElement(React.Fragment, null,
                React.createElement("img", { src: value, alt: '' }),
                " ",
                React.createElement("p", null, fileNameFromUrl),
                React.createElement(IconButton, { name: 'farUndoAlt', size: 'sm', color: 'none', onClick: onPartEdit, "data-test-id": 'FAQPage:Answer:Media:EditBtn', disabled: writeDisabled, className: 'answer-part__media__undo-btn' }))),
            type === AnswerReplyType.audio && (React.createElement(AudioFieldMemo, { url: value, fileName: fileName || audioName || fileNameFromUrl || 'audio', saveAudioToServer: props.saveAudioToServer, onPartEdit: onPartEdit, disabled: writeDisabled })),
            type === 'file' && (React.createElement(React.Fragment, null,
                React.createElement(Icon, { name: error ? 'farTimesCircle' : 'farCheckCircle', color: error ? 'danger' : 'success' }),
                React.createElement("p", null, fileName || fileNameFromUrl),
                React.createElement(IconButton, { name: 'farUndoAlt', size: 'sm', color: 'none', onClick: onPartEdit, "data-test-id": 'FAQPage:Answer:Media:EditBtn', disabled: writeDisabled, className: 'answer-part__media__undo-btn' })))),
        React.createElement("div", { className: cn(`faq-answer-part__media__url`) },
            React.createElement("a", { href: parsedUrl.href, target: '_blank', rel: 'noopener noreferrer', className: 'text-primary', "data-test-id": 'FAQPage:Answer:Media:Link' }, decodeURI(parsedUrl.href)),
            React.createElement("p", { className: 'text-secondary' }, t('AnswerBlocks:answer_media_date', format(uploadDate || new Date().getDate(), 'dd.MM.yy'))))));
}
