import {
  ADD_NEW_USER_ROLES,
  CLEAN_ERROR,
  DROP_SEARCH_FILTER_FULFILLED,
  DROP_USER_EDIT,
  EDIT_USER_ROLES,
  EDIT_USER_SAVED_FULFILLED,
  EDIT_USER_SAVED_PENDING,
  EDIT_USER_SAVED_REJECTED,
  PAMD_LOAD_FULFILLED,
  ROLES_LOAD_FULFILLED,
  SEARCH_USERS_FULFILLED,
  USER_ADD_FULFILLED,
  USER_ADD_PENDING,
  USER_ADD_REJECTED,
  USER_DELETE_FULFILLED,
  USER_EDIT,
  USERS_LOAD_FULFILLED,
  EDIT_USER_PASSWORD_REJECTED,
  EDIT_USER_PASSWORD_PENDING,
  EDIT_USER_PASSWORD_FULFILLED,
  GET_CAPTCHA_AVAILABLE_ATTEMPTS_PENDING,
  GET_CAPTCHA_AVAILABLE_ATTEMPTS_FULFILLED,
  GET_CAPTCHA_AVAILABLE_ATTEMPTS_REJECTED,
} from '../../constants/usersList.actions';
import { LOGIN_TO_ACCOUNT_FULFILLED, LOGOUT_FROM_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

const InitialState = {
  usersList: [],
  roles: [],
  editing: false,
  setFilter: false,
  searchLogin: '',
  searchName: '',
  selectedUser: {},
  fetching: false,
  newUserRoles: [],
  fetched: false,
  error: null,
  page: 0,
  totalPages: 1,
  totalElements: 1,
  pageSize: 20,
  editUserRoles: false,
  pamd: false,
  userSaving: false,
  onlyActive: true,
  captchaAvailableAttempts: 0,
};

export default function UsersListReducer(state = InitialState, action) {
  switch (action.type) {
    case USER_DELETE_FULFILLED: {
      let newUserList = state.usersList.slice();
      newUserList = newUserList.filter(userItem => userItem.id !== action.payload.config.id);
      return {
        ...state,
        usersList: newUserList,
        selectedUser: {},
        editing: false,
      };
    }
    case USERS_LOAD_FULFILLED:
    case DROP_SEARCH_FILTER_FULFILLED: {
      return {
        ...state,
        usersList: action.payload.data.content,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        totalElements: action.payload.data.totalElements,
        searchLogin: '',
        searchName: '',
        onlyActive: true,
        error: null,
      };
    }
    case ROLES_LOAD_FULFILLED: {
      const filteredRoles = action.payload.data.filter(role => role.editable);
      return {
        ...state,
        roles: filteredRoles,
        error: null,
      };
    }
    case USER_EDIT: {
      return {
        ...state,
        selectedUser: state.usersList.find(item => item.id === action.id),
        editing: true,
        error: null,
      };
    }
    case PAMD_LOAD_FULFILLED: {
      return {
        ...state,
        pamd: action.payload.data,
      };
    }
    case DROP_USER_EDIT: {
      return {
        ...state,
        selectedUser: {},
        editing: false,
        editUserRoles: false,
        newUserRoles: [],
      };
    }
    case CLEAN_ERROR: {
      return {
        ...state,
        error: null,
        editUserRoles: false,
      };
    }
    case EDIT_USER_SAVED_PENDING: {
      return {
        ...state,
        userSaving: true,
      };
    }
    case EDIT_USER_SAVED_REJECTED: {
      return {
        ...state,
        userSaving: false,
        error: action.payload.response.data.errors || [action.payload.response.data.error],
      };
    }
    case EDIT_USER_SAVED_FULFILLED: {
      let usersList = state.usersList;
      const index = usersList.findIndex(user => {
        return user.id === action.payload.data.id;
      });
      usersList.splice(index, 1, action.payload.data);
      return {
        ...state,
        usersList: usersList,
        userSaving: false,
        error: null,
        editUserRoles: false,
      };
    }
    case USER_ADD_PENDING: {
      return {
        ...state,
        userSaving: true,
      };
    }
    case USER_ADD_FULFILLED: {
      let usersList = state.usersList;
      usersList.splice(0, 0, action.payload.data);
      return {
        ...state,
        usersList: usersList,
        userSaving: false,
        editUserRoles: false,
        newUserRoles: [],
        error: null,
      };
    }
    case USER_ADD_REJECTED: {
      return {
        ...state,
        userSaving: false,
        error: action.payload.response.data.errors || action.payload.response.data,
      };
    }
    case EDIT_USER_ROLES: {
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          roles: action.roles,
        },
        editUserRoles: true,
      };
    }

    case ADD_NEW_USER_ROLES: {
      return {
        ...state,
        newUserRoles: action.roles,
        editUserRoles: true,
      };
    }

    case EDIT_USER_PASSWORD_REJECTED: {
      return {
        ...state,
        selectedUser: state.usersList.find(item => item.id === action.payload.response.config.selectedUser.id),
        error: action.payload.response.data.errors,
        userSaving: false,
      };
    }
    case EDIT_USER_PASSWORD_PENDING: {
      return {
        ...state,
        userSaving: true,
      };
    }
    case EDIT_USER_PASSWORD_FULFILLED: {
      let usersList = state.usersList.slice();
      const index = usersList.findIndex(user => {
        return user.id === action.payload.data.id;
      });
      usersList.splice(index, 1, action.payload.data);
      return {
        ...state,
        usersList: usersList,
        fetching: false,
        selectedUser: {},
        error: null,
        editUserRoles: false,
        userSaving: false,
      };
    }

    case SEARCH_USERS_FULFILLED: {
      return {
        ...state,
        usersList: action.payload.data.content,
        page: action.payload.data.number,
        totalPages: action.payload.data.totalPages,
        setFilter: true,
        searchLogin: action.payload.config.params.login,
        searchName: action.payload.config.params.name,
        onlyActive: action.payload.config.params.onlyActive,
      };
    }

    case LOGIN_TO_ACCOUNT_FULFILLED:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      return {
        ...InitialState,
      };
    }
    case GET_CAPTCHA_AVAILABLE_ATTEMPTS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case GET_CAPTCHA_AVAILABLE_ATTEMPTS_FULFILLED:
      return {
        ...state,
        captchaAvailableAttempts: action.payload.data,
        fetching: false,
      };
    case GET_CAPTCHA_AVAILABLE_ATTEMPTS_REJECTED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
