var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
import React, { createContext, PureComponent, useContext } from 'react';
import LabelsService from '../service/LabelsService';
import { Spinner } from '@just-ai/just-ui';
import { makeLabelsFromGroups } from './utils';
import { colorsForLabels } from './helpers';
export var LabelsContext = createContext({});
export var TypeMapToState;
(function (TypeMapToState) {
    TypeMapToState["states"] = "issuesStates";
    TypeMapToState["labels"] = "issuesLabels";
    TypeMapToState["priorities"] = "issuesPriorities";
})(TypeMapToState || (TypeMapToState = {}));
var LabelsContextProviderState = /** @class */ (function () {
    function LabelsContextProviderState() {
        this.loaded = false;
        this.loading = false;
        this.labels = [];
        this.groups = [];
        this.sessionLabels = [];
        this.sessionLabelColors = [];
        this.labelColors = [];
        this[_a] = [];
        this[_b] = [];
        this[_c] = [];
    }
    return LabelsContextProviderState;
}());
export { LabelsContextProviderState };
_a = TypeMapToState.labels, _b = TypeMapToState.states, _c = TypeMapToState.priorities;
var LabelsContextProvider = /** @class */ (function (_super) {
    __extends(LabelsContextProvider, _super);
    function LabelsContextProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.translate = _this.props.translate;
        _this.labelsServiceApi = new LabelsService(NaN, _this.props.useReporter);
        _this.state = new LabelsContextProviderState();
        _this.load = function (promise) {
            _this.setState({ loading: true });
            return promise.finally(function () { return _this.setState({ loading: false }); });
        };
        _this.getLabels = function () { return __awaiter(_this, void 0, void 0, function () {
            var resultsArray, labelGroups, sessionLabels, issuesLabels, issuesStates, issuesPriorities, labelsColors_1, labelsPhraseColors_1, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.setState({ loading: true });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        if (this.props.disabled) {
                            this.setState({ loading: false, loaded: true });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all([
                                this.labelsServiceApi.getLabelGroups(),
                                this.labelsServiceApi.getSessionLabels(),
                                this.labelsServiceApi.getIssuesRequestedType('labels'),
                                this.labelsServiceApi.getIssuesRequestedType('states'),
                                this.labelsServiceApi.getIssuesRequestedType('priorities'),
                            ])];
                    case 2:
                        resultsArray = _d.sent();
                        labelGroups = resultsArray[0].data, sessionLabels = resultsArray[1].data, issuesLabels = resultsArray[2].data, issuesStates = resultsArray[3].data, issuesPriorities = resultsArray[4].data;
                        issuesLabels.sort(function (a, b) { return (a.priority || 0) - (b.priority || 0); });
                        issuesStates.sort(function (a, b) { return (a.priority || 0) - (b.priority || 0); });
                        issuesPriorities.sort(function (a, b) { return (a.priority || 0) - (b.priority || 0); });
                        labelsColors_1 = [];
                        if (sessionLabels === null || sessionLabels === void 0 ? void 0 : sessionLabels.map) {
                            //@ts-ignore
                            sessionLabels === null || sessionLabels === void 0 ? void 0 : sessionLabels.map(function (sessionLabel, i) {
                                labelsColors_1.push({
                                    name: sessionLabel.name,
                                    color: colorsForLabels[i],
                                    active: true,
                                });
                            });
                        }
                        labelsPhraseColors_1 = [];
                        if (Array.isArray(labelGroups)) {
                            labelGroups.map(function (labelGroup, i) {
                                labelGroup.labels.map(function (label, j) {
                                    labelsPhraseColors_1.push({
                                        name: label.name,
                                        color: colorsForLabels[labelsColors_1.length + i + j],
                                        active: true,
                                    });
                                });
                            });
                        }
                        this.setState({
                            loaded: true,
                            loading: false,
                            groups: labelGroups,
                            sessionLabels: sessionLabels,
                            labels: makeLabelsFromGroups(labelGroups),
                            sessionLabelColors: labelsColors_1,
                            labelColors: labelsPhraseColors_1,
                            issuesLabels: issuesLabels,
                            issuesStates: issuesStates,
                            issuesPriorities: issuesPriorities,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _d.sent();
                        this.setState({ loading: false });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.deleteLabelGroup = function (id) {
            _this.setState({
                groups: _this.state.groups.filter(function (group) { return group.id !== id; }),
                labels: _this.state.labels.filter(function (label) {
                    var _d;
                    //@ts-ignore
                    return (label.groupId || ((_d = label.group) === null || _d === void 0 ? void 0 : _d.id)) !== id;
                }),
            });
            _this.labelsServiceApi.deleteLabelGroup(id);
        };
        _this.deleteLabel = function (id) {
            var label = _this.state.labels.find(function (label) { return label.id === id; });
            if (label) {
                var newGroups = __spreadArray([], _this.state.groups, true);
                //@ts-ignore
                var foundGroupId = newGroups.find(function (group) { var _d; return group.id === label.groupId || group.id === ((_d = label.group) === null || _d === void 0 ? void 0 : _d.id); });
                if (foundGroupId) {
                    foundGroupId.labels = foundGroupId.labels.filter(function (label) { return label.id !== id; });
                    _this.setState({
                        labels: _this.state.labels.filter(function (label) { return label.id !== id; }),
                        groups: newGroups,
                    });
                    _this.labelsServiceApi.deleteLabel(id);
                }
            }
        };
        _this.resortGroups = function (groups) {
            _this.setState({
                groups: groups,
            });
            var newPriority = groups.map(function (item, index) { return ({
                id: item.id,
                newPriority: index + 1,
            }); });
            _this.labelsServiceApi.updateGroupsPriority(newPriority);
        };
        _this.resortLabelsInGroup = function (id, labels) {
            var newGroups = __spreadArray([], _this.state.groups, true);
            var group = newGroups.find(function (group) { return group.id === id; });
            if (group) {
                group.labels = labels;
                _this.setState({
                    groups: newGroups,
                });
            }
            var newPriority = labels.map(function (item, index) { return ({
                id: item.id,
                newPriority: index + 1,
            }); });
            _this.labelsServiceApi.updateLogLabelsPriority(newPriority);
        };
        _this.updateLabel = function (labelId, newName) {
            var _d;
            var foundLabel = _this.state.labels.find(function (label) { return label.id === labelId; });
            if (foundLabel) {
                foundLabel.name = newName;
                var newGroups = __spreadArray([], _this.state.groups, true);
                _this.setState({
                    groups: newGroups,
                });
                _this.labelsServiceApi.updateLabel(labelId, {
                    //@ts-ignore
                    groupId: ((_d = foundLabel.group) === null || _d === void 0 ? void 0 : _d.id) || foundLabel.groupId,
                    name: newName,
                    priority: foundLabel.priority,
                });
            }
        };
        _this.createLabel = function (value, groupId) { return __awaiter(_this, void 0, void 0, function () {
            var newGroups, foundGroup, newValue, data;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        newGroups = __spreadArray([], this.state.groups, true);
                        foundGroup = newGroups.find(function (group) { return group.id === groupId; });
                        if (!foundGroup) return [3 /*break*/, 2];
                        newValue = {
                            name: value,
                            groupId: groupId,
                            priority: foundGroup.labels.length + 1,
                        };
                        this.setState({ loading: true });
                        return [4 /*yield*/, this.labelsServiceApi.createLabel(newValue, foundGroup)];
                    case 1:
                        data = (_d.sent()).data;
                        foundGroup.labels.push(data);
                        this.setState({
                            groups: newGroups,
                            labels: makeLabelsFromGroups(newGroups),
                            loading: false,
                        });
                        return [2 /*return*/, data];
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        _this.createSessionLabel = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var newSessionLabel, data;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        newSessionLabel = {
                            name: value,
                            priority: this.state.sessionLabels.length + 1,
                        };
                        return [4 /*yield*/, this.labelsServiceApi.createSessionLabel(newSessionLabel)];
                    case 1:
                        data = (_d.sent()).data;
                        this.setState({ sessionLabels: __spreadArray(__spreadArray([], this.state.sessionLabels, true), [data], false) });
                        return [2 /*return*/, data];
                }
            });
        }); };
        _this.deleteSessionLabel = function (id) {
            _this.setState({
                sessionLabels: _this.state.sessionLabels.filter(function (sessionLabel) { return sessionLabel.id !== id; }),
            });
            _this.labelsServiceApi.deleteSessionLabel(id);
        };
        _this.createLabelGroup = function (name, labels) {
            var group = {
                name: name,
                priority: _this.state.groups.length + 1,
            };
            //@ts-ignore
            _this.load(_this.labelsServiceApi.createLabelGroup(group, labels)).then(function (_d) {
                var data = _d.data;
                var newGroups = __spreadArray([], _this.state.groups, true);
                if (_this.props.useReporter) {
                    return _this.getLabels();
                }
                else {
                    newGroups.push(data);
                }
                _this.setState({
                    groups: newGroups,
                });
            });
        };
        _this.updateSessionsPriority = function (newOrderedSessions) {
            _this.setState({
                sessionLabels: newOrderedSessions,
            });
            var labelPriorityChange = newOrderedSessions.map(function (session, index) { return ({
                id: session.id,
                newPriority: index + 1,
            }); });
            _this.labelsServiceApi.updateSessionsPriority(labelPriorityChange);
        };
        _this.updateSessionLabel = function (sessionLabelId, newName) {
            var newSessionsLabels = __spreadArray([], _this.state.sessionLabels, true);
            var foundIndex = newSessionsLabels.findIndex(function (label) { return label.id === sessionLabelId; });
            if (foundIndex > -1) {
                var updateSessionLabel = __assign(__assign({}, newSessionsLabels[foundIndex]), { name: newName });
                newSessionsLabels.splice(foundIndex, 1, updateSessionLabel);
                _this.setState({
                    sessionLabels: newSessionsLabels,
                });
                _this.labelsServiceApi.updateSessionLabel(sessionLabelId, updateSessionLabel);
            }
        };
        _this.updateLabelGroup = function (id, name) {
            var newGroups = __spreadArray([], _this.state.groups, true);
            var foundGroupIndex = newGroups.findIndex(function (group) { return group.id === id; });
            if (foundGroupIndex > -1) {
                var newValue = __assign({}, newGroups[foundGroupIndex]);
                newValue.name = name;
                newGroups.splice(foundGroupIndex, 1, newValue);
                _this.setState({
                    groups: newGroups,
                    labels: makeLabelsFromGroups(newGroups),
                });
                _this.labelsServiceApi.updateLabelGroup(id, newValue);
            }
        };
        _this.updateIssuesRequestedTypePriority = function (type, newPriority) {
            var _d;
            var newPriorityList = newPriority.map(function (item, index) {
                return __assign(__assign({}, item), { priority: index + 1 });
            });
            _this.labelsServiceApi.updateIssuesRequestedTypePriority(type, newPriorityList.map(function (item) { return ({ id: item.id, newPriority: item.priority }); }));
            //@ts-ignore
            _this.setState((_d = {},
                _d[TypeMapToState[type]] = newPriorityList,
                _d));
        };
        _this.createIssuesRequestedType = function (type, value) {
            var mapRequestTypeToStateLength = {
                labels: _this.state.issuesLabels.length + 1,
                priorities: _this.state.issuesPriorities.length + 1,
                states: _this.state.issuesStates.length + 1,
            };
            var issueReq = {
                active: true,
                name: value,
                priority: mapRequestTypeToStateLength[type],
            };
            _this.load(_this.labelsServiceApi.createIssuesRequestedType(type, issueReq)).then(function (_d) {
                var _e;
                var data = _d.data;
                var mapRequestTypeToState = {
                    labels: __spreadArray([], _this.state.issuesLabels, true),
                    priorities: __spreadArray([], _this.state.issuesPriorities, true),
                    states: __spreadArray([], _this.state.issuesStates, true),
                };
                mapRequestTypeToState[type].push(data);
                //@ts-ignore
                _this.setState((_e = {},
                    _e[TypeMapToState[type]] = mapRequestTypeToState[type],
                    _e));
            });
        };
        _this.updateIssueRequestedType = function (type, id, value) {
            var _d;
            var foundIndex = -1;
            var mapRequestTypeToState = {
                labels: __spreadArray([], _this.state.issuesLabels, true),
                priorities: __spreadArray([], _this.state.issuesPriorities, true),
                states: __spreadArray([], _this.state.issuesStates, true),
            };
            foundIndex = mapRequestTypeToState[type].findIndex(function (state) { return state.id === id; });
            if (foundIndex > -1) {
                var creationValue = __assign(__assign({}, mapRequestTypeToState[type][foundIndex]), { name: value });
                mapRequestTypeToState[type].splice(foundIndex, 1, creationValue);
                //@ts-ignore
                _this.setState((_d = {},
                    _d[TypeMapToState[type]] = mapRequestTypeToState[type],
                    _d));
                _this.labelsServiceApi.updateIssueRequestedType(type, id, creationValue);
            }
        };
        _this.deleteIssueRequestedType = function (type, id) {
            var _d;
            var mapRequestTypeToState = {
                labels: __spreadArray([], _this.state.issuesLabels, true),
                priorities: __spreadArray([], _this.state.issuesPriorities, true),
                states: __spreadArray([], _this.state.issuesStates, true),
            };
            mapRequestTypeToState[type] = mapRequestTypeToState[type].filter(function (label) { return label.id !== id; });
            var stateName = TypeMapToState[type];
            var stateValue = mapRequestTypeToState[type];
            //@ts-ignore stupid typescript
            _this.setState((_d = {}, _d[stateName] = stateValue, _d));
            _this.labelsServiceApi.deleteIssueRequestedType(type, id);
        };
        _this.setLabelToSessions = function (_d) {
            var filters = _d.filters, labels = _d.labels, comparisonType = _d.comparisonType, sessionIds = _d.sessionIds;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, this.labelsServiceApi.setLabelToSessions({ filters: filters, labels: labels, comparisonType: comparisonType, objectIds: sessionIds })];
                        case 1:
                            _e.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        _this.setLabelToMessages = function (_d) {
            var filters = _d.filters, labels = _d.labels, comparisonType = _d.comparisonType, sessionIds = _d.sessionIds;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, this.labelsServiceApi.setLabelToMessages({ filters: filters, labels: labels, comparisonType: comparisonType, objectIds: sessionIds })];
                        case 1:
                            _e.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        return _this;
    }
    LabelsContextProvider.prototype.componentDidMount = function () {
        this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
    };
    LabelsContextProvider.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.accountId !== prevProps.accountId) {
            this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
            this.setState(new LabelsContextProviderState());
        }
    };
    LabelsContextProvider.prototype.componentWillUnmount = function () {
        this.setState(new LabelsContextProviderState());
    };
    LabelsContextProvider.prototype.render = function () {
        var _d, _e;
        var children = this.props.children;
        this.labelsServiceApi = new LabelsService(this.props.accountId, this.props.useReporter);
        return (React.createElement(LabelsContext.Provider, { value: {
                t: this.translate,
                groups: this.state.groups,
                sessionLabels: this.state.sessionLabels,
                issuesLabels: this.state.issuesLabels,
                issuesStates: this.state.issuesStates,
                issuesPriorities: this.state.issuesPriorities,
                labelColors: this.state.labelColors,
                sessionLabelColors: this.state.sessionLabelColors,
                loaded: this.state.loaded,
                loading: this.state.loading,
                getLabels: this.getLabels,
                deleteLabelGroup: this.deleteLabelGroup,
                deleteLabel: this.deleteLabel,
                resortGroups: this.resortGroups,
                resortLabelsInGroup: this.resortLabelsInGroup,
                updateLabel: this.updateLabel,
                createLabel: this.createLabel,
                setLabelToSessions: this.setLabelToSessions,
                setLabelToMessages: this.setLabelToMessages,
                updateSessionLabel: this.updateSessionLabel,
                createSessionLabel: this.createSessionLabel,
                deleteSessionLabel: this.deleteSessionLabel,
                createLabelGroup: this.createLabelGroup,
                updateLabelGroup: this.updateLabelGroup,
                updateSessionsPriority: this.updateSessionsPriority,
                updateIssuesRequestedTypePriority: this.updateIssuesRequestedTypePriority,
                createIssuesRequestedType: this.createIssuesRequestedType,
                updateIssueRequestedType: this.updateIssueRequestedType,
                deleteIssueRequestedType: this.deleteIssueRequestedType,
                useReporter: Boolean(this.props.useReporter),
                hasTagCreatePermission: !!((_e = (_d = this.props.config) === null || _d === void 0 ? void 0 : _d.tags) === null || _e === void 0 ? void 0 : _e.hasWriteRight),
            } },
            this.state.loading && React.createElement(Spinner, { size: '4x' }),
            children));
    };
    return LabelsContextProvider;
}(PureComponent));
export { LabelsContextProvider };
export var useLabelsContext = function () { return useContext(LabelsContext); };
