import React, { useCallback } from 'react';

import { TransitionSettings } from './settings/TransitionSettings';
import { AnswerSettings } from './settings/AnswerSettings';
import { useRightSideMenuContext } from '.';
import { ButtonsSettings } from './settings/ButtonsSettings';
import { StateSettings } from './settings/StateSettings';
import { ScreenBlocksList } from './settings/ScreenBlocksList';
import { RSAddingMenu } from './RSAddingMenu';
import { ImageSettings } from './settings/ImageSettings';

import { EmailSettings } from './settings/EmailSettings';
import { HttpRequestSettings } from './settings/HttpRequestSettings';
import { ActivationEventSettings } from './settings/ActivationEventSettings';
import { PatternEventSettings } from './settings/PatternEventSettings';
import { Message } from '@just-ai/just-ui';
import { t, tWithCheck } from 'localization';
import { IntentEventSettings } from './settings/IntentEventSettings';

import { ConditionSettings } from './settings/ConditionSettings';
import { ScriptSettings } from './settings/ScriptSettings';
import RandomSettings from 'modules/JGraph/view/RightSideMenu/settings/RandomSettings';
import { AudioSettings } from './settings/AudioSettings';

import { CommonCustomTagSettings } from './settings/CommonCustomTagSettings';

export const Body = () => {
  const { getEditBlockType, eventsState, customTags, getEditBlockTag } = useRightSideMenuContext();
  const { addingMenuType } = eventsState;

  const renderBody = useCallback(() => {
    if (addingMenuType) {
      return <RSAddingMenu />;
    }
    switch (getEditBlockType()) {
      /*case 'transitionMenu': { @m.podkopaev © "а может вернем потом"
        return <TransitionMenu />;
      }*/
      case 'stateSettings': {
        return <StateSettings />;
      }
      case 'screenBlocksList': {
        return <ScreenBlocksList />;
      }
      case 'audio':
        return <AudioSettings />;
      case 'image':
        return <ImageSettings />;
      case 'random':
        return <RandomSettings />;
      case 'if':
        return <ConditionSettings />;
      case 'go':
      case 'go!':
        return <TransitionSettings />;
      case 'a': {
        return <AnswerSettings />;
      }
      case 'script': {
        return <ScriptSettings />;
      }
      case 'inlineButtons':
      case 'buttons': {
        return <ButtonsSettings />;
      }
      case 'event!':
      case 'event': {
        return <ActivationEventSettings />;
      }
      case 'q!':
      case 'q': {
        return <PatternEventSettings />;
      }
      case 'intent!':
      case 'intent': {
        return <IntentEventSettings />;
      }
      case 'Email': {
        return <EmailSettings />;
      }
      case 'HttpRequest': {
        return <HttpRequestSettings />;
      }
      default:
        const block = getEditBlockTag();
        if (block) {
          if (customTags.map(tag => tag.tagName).includes(block.tagName)) {
            const descriptor = customTags.find(({ tagName }) => tagName === block.tagName);
            if (descriptor && descriptor.parameters) {
              return <CommonCustomTagSettings descriptor={descriptor} block={block} />;
            }
          }
        }

        const editBlockType = getEditBlockType();
        const translatedStab = tWithCheck(`JGraphSoon:${editBlockType.replace(/!$/, '')}`);
        return <Message type='info' text={translatedStab || t('JGraphSoon', editBlockType)} />;
    }
  }, [addingMenuType, customTags, getEditBlockTag, getEditBlockType]);

  return (
    <div className='JGraph-RightSideMenu-body'>
      <div className='JGraph-RightSideMenu-body-content'>{renderBody()}</div>
    </div>
  );
};
