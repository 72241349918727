import React, { createContext, useContext, useState } from 'react';
var SpeedAudioContext = createContext({});
var getNumberForInitialState = function (value, defaultValue) {
    var optionalNumber = Number(value);
    if (Number.isNaN(optionalNumber))
        return defaultValue;
    return optionalNumber;
};
export var SpeedAudioContextProvider = function (_a) {
    var children = _a.children;
    var initialValue = getNumberForInitialState(localStorage.AUDIO_PLAYBACK_RATE, 1);
    var _b = useState(initialValue), currentAudioSpeed = _b[0], setCurrentAudioSpeed = _b[1];
    return (React.createElement(SpeedAudioContext.Provider, { value: {
            currentAudioSpeed: currentAudioSpeed,
            setCurrentAudioSpeed: setCurrentAudioSpeed,
        } }, children));
};
export var useSpeedAudioContext = function () { return useContext(SpeedAudioContext); };
