var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ProjectApi } from '@just-ai/api/dist/generated/Editorbe';
import { FAQIntentService } from './FAQIntentService';
import { Intents } from '../nlu-related/utils';
import { INITIAL_REPLIES } from '../constants/faqTemplates';
var FAQApiService = /** @class */ (function () {
    function FAQApiService(accountId, projectShortName, IntentsApiInstance, faqApi, axios) {
        this.intentsApi = IntentsApiInstance;
        this.faqApi = faqApi;
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.projectApi = new ProjectApi({}, FAQApiService.BASE_PATH, axios);
    }
    FAQApiService.prototype.createFAQIntent = function (templateTitle, templateType) {
        return __awaiter(this, void 0, void 0, function () {
            var listIntentsResponse, newFAQIntents, rootIntent, firstQuestion, createFAQResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!templateType.includes('empty_template')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.faqApi.importFAQFromTemplate(this.accountId, this.projectShortName, templateType)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.intentsApi.listIntents(this.accountId, this.projectShortName)];
                    case 3:
                        listIntentsResponse = _a.sent();
                        newFAQIntents = FAQIntentService.createNewFAQ(listIntentsResponse.data, templateTitle);
                        rootIntent = newFAQIntents[0];
                        firstQuestion = {
                            intent: newFAQIntents[1],
                            replies: INITIAL_REPLIES,
                        };
                        return [4 /*yield*/, Promise.all([
                                this.intentsApi.createIntent(this.accountId, this.projectShortName, rootIntent),
                                this.faqApi.createFaqQuestion(this.accountId, this.projectShortName, firstQuestion),
                            ])];
                    case 4:
                        createFAQResponse = _a.sent();
                        return [2 /*return*/, createFAQResponse];
                }
            });
        });
    };
    FAQApiService.prototype.createFAQQuestion = function (intentList, templateName) {
        return __awaiter(this, void 0, void 0, function () {
            var newQuestionIntent, createFAQResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newQuestionIntent = FAQIntentService.createNewQuestion(intentList, templateName);
                        return [4 /*yield*/, this.faqApi.createFaqQuestion(this.accountId, this.projectShortName, {
                                intent: newQuestionIntent,
                                replies: INITIAL_REPLIES,
                            })];
                    case 1:
                        createFAQResponse = _a.sent();
                        return [2 /*return*/, createFAQResponse];
                }
            });
        });
    };
    FAQApiService.prototype.createFAQGroup = function (intentList, templateName, categoryName, enabled) {
        return __awaiter(this, void 0, void 0, function () {
            var newGroupIntent, createIntentResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newGroupIntent = FAQIntentService.createNewGroup(intentList, templateName, categoryName);
                        if (enabled !== undefined) {
                            newGroupIntent.enabled = enabled;
                        }
                        return [4 /*yield*/, this.intentsApi.createIntent(this.accountId, this.projectShortName, newGroupIntent)];
                    case 1:
                        createIntentResult = _a.sent();
                        return [2 /*return*/, createIntentResult.data];
                }
            });
        });
    };
    FAQApiService.prototype.deleteQuestion = function (intentIds, templateName) {
        return __awaiter(this, void 0, void 0, function () {
            var deleteQuestionResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.intentsApi.deleteIntents(this.accountId, this.projectShortName, intentIds)];
                    case 1:
                        deleteQuestionResponse = _a.sent();
                        return [2 /*return*/, deleteQuestionResponse];
                }
            });
        });
    };
    FAQApiService.prototype.deleteFAQSkill = function (intentId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.intentsApi.recursiveDelete(this.accountId, this.projectShortName, intentId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FAQApiService.prototype.updateFAQIntent = function (intent, needNormalize, questionData, softValidate, options) {
        if (needNormalize === void 0) { needNormalize = true; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (needNormalize) {
                    intent = FAQIntentService.normalizeIntentLikeFAQQuestion(intent);
                }
                if (!intent.id)
                    return [2 /*return*/];
                if (!questionData || !questionData.id) {
                    return [2 /*return*/, this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, intent.id, intent, undefined, softValidate, options)];
                }
                return [2 /*return*/, this.faqApi.updateFaqQuestion(this.accountId, this.projectShortName, questionData.id, questionData, softValidate, options)];
            });
        });
    };
    FAQApiService.prototype.updateFAQIntents = function (intents) {
        return __awaiter(this, void 0, void 0, function () {
            var updateIntentsArr;
            return __generator(this, function (_a) {
                updateIntentsArr = intents.map(function (intent) { return ({
                    intent: intent,
                    intentId: intent.id,
                }); });
                return [2 /*return*/, this.intentsApi.recursiveUpdateMultipleIntents(this.accountId, this.projectShortName, updateIntentsArr)];
            });
        });
    };
    FAQApiService.prototype.createFaqQuestions = function (questions) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.allSettled(questions.map(function (question) { return _this.faqApi.createFaqQuestion(_this.accountId, _this.projectShortName, question); }))];
            });
        });
    };
    FAQApiService.prototype.onCreateFolderWithNodes = function (templateName, groupName, intents) {
        return __awaiter(this, void 0, void 0, function () {
            var groupPath, newGroupIntent, addedIntentNames, _i, intents_1, intent, intentName, name_1, newIntent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (intents.length === 0)
                            return [2 /*return*/, null];
                        groupPath = "".concat(Intents.generateFAQPathByTemplateName(templateName), "/").concat(groupName);
                        return [4 /*yield*/, this.intentsApi.createIntent(this.accountId, this.projectShortName, {
                                path: groupPath,
                            })];
                    case 1:
                        newGroupIntent = (_a.sent()).data;
                        addedIntentNames = [];
                        _i = 0, intents_1 = intents;
                        _a.label = 2;
                    case 2:
                        if (!(_i < intents_1.length)) return [3 /*break*/, 5];
                        intent = intents_1[_i];
                        intentName = Intents.getNameFromPath(intent.path);
                        name_1 = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                        newIntent = Object.assign(intent, { path: "".concat(groupPath, "/").concat(name_1) });
                        newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                        if (!newIntent.id)
                            return [3 /*break*/, 4];
                        return [4 /*yield*/, this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent)];
                    case 3:
                        _a.sent();
                        addedIntentNames.push(name_1);
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/, newGroupIntent];
                }
            });
        });
    };
    FAQApiService.prototype.moveQuestionInFolder = function (folderIntent, existedChildrenNames, intents) {
        return __awaiter(this, void 0, void 0, function () {
            var addedIntentNames, _i, intents_2, intent, intentName, name_2, newIntent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (intents.length === 0 || !folderIntent.path)
                            return [2 /*return*/, null];
                        addedIntentNames = existedChildrenNames;
                        _i = 0, intents_2 = intents;
                        _a.label = 1;
                    case 1:
                        if (!(_i < intents_2.length)) return [3 /*break*/, 4];
                        intent = intents_2[_i];
                        if (intent.path && FAQIntentService.isIntentChildrenForPath(folderIntent.path, intent.path)) {
                            return [3 /*break*/, 3];
                        }
                        intentName = Intents.getNameFromPath(intent.path);
                        name_2 = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                        newIntent = Object.assign(intent, { path: "".concat(folderIntent.path, "/").concat(name_2) });
                        newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                        if (!newIntent.id)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent)];
                    case 2:
                        _a.sent();
                        addedIntentNames.push(name_2);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    FAQApiService.prototype.moveQuestionInRoot = function (folderPath, existedChildrenNames, intents) {
        return __awaiter(this, void 0, void 0, function () {
            var addedIntentNames, _i, intents_3, intent, intentName, name_3, newIntent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (intents.length === 0 || !folderPath)
                            return [2 /*return*/, null];
                        addedIntentNames = existedChildrenNames;
                        _i = 0, intents_3 = intents;
                        _a.label = 1;
                    case 1:
                        if (!(_i < intents_3.length)) return [3 /*break*/, 4];
                        intent = intents_3[_i];
                        if (intent.path && FAQIntentService.isIntentChildrenForPath(folderPath, intent.path))
                            return [3 /*break*/, 3];
                        intentName = Intents.getNameFromPath(intent.path);
                        name_3 = FAQIntentService.generateNewUniqNameWithIndex(addedIntentNames, intentName);
                        newIntent = Object.assign(intent, { path: "".concat(folderPath, "/").concat(name_3) });
                        newIntent = FAQIntentService.normalizeIntentLikeFAQQuestion(newIntent, intent);
                        if (!newIntent.id)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.intentsApi.recursiveUpdate(this.accountId, this.projectShortName, newIntent.id, newIntent)];
                    case 2:
                        _a.sent();
                        addedIntentNames.push(name_3);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    FAQApiService.prototype.loadFAQTemplates = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            var templates;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.faqApi.getFAQTemplates(this.accountId, this.projectShortName, language)];
                    case 1:
                        templates = (_a.sent()).data;
                        return [2 /*return*/, templates.map(function (template) {
                                var _a, _b;
                                var _c = (_b = (_a = template.id) === null || _a === void 0 ? void 0 : _a.split(/_([^_]+$)/).filter(Boolean)) !== null && _b !== void 0 ? _b : [], type = _c[0], lang = _c[1];
                                return __assign(__assign({}, template), { type: type, lang: lang });
                            })];
                }
            });
        });
    };
    FAQApiService.prototype.uploadFAQTemplateFile = function (file, ignoreError) {
        if (ignoreError === void 0) { ignoreError = false; }
        return __awaiter(this, void 0, void 0, function () {
            var faqName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.faqApi.importFAQFromFile(this.accountId, this.projectShortName, ignoreError, file)];
                    case 1:
                        faqName = (_a.sent()).data;
                        return [2 /*return*/, faqName];
                }
            });
        });
    };
    FAQApiService.prototype.exportFAQ = function (exportIds) {
        return __awaiter(this, void 0, void 0, function () {
            var exportedFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.faqApi.exportFAQ(this.accountId, this.projectShortName, exportIds, {
                            responseType: 'blob',
                        })];
                    case 1:
                        exportedFile = (_a.sent()).data;
                        return [2 /*return*/, window.URL.createObjectURL(exportedFile)];
                }
            });
        });
    };
    FAQApiService.prototype.importFAQ = function (faqName, file, ignoreError) {
        if (ignoreError === void 0) { ignoreError = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.faqApi.importFAQFromFileToExistingFAQ(this.accountId, this.projectShortName, ignoreError, faqName, file)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FAQApiService.prototype.projectHasFaqState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var faqTags, tags, _i, tags_1, tag;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        faqTags = {
                            tagName: 'intentGroup',
                            includeGlobal: true,
                        };
                        return [4 /*yield*/, this.projectApi.getTagsList(this.accountId, this.projectShortName, faqTags)];
                    case 1:
                        tags = (_a.sent()).data;
                        for (_i = 0, tags_1 = tags; _i < tags_1.length; _i++) {
                            tag = tags_1[_i];
                            if (tag.tagName.includes('intentGroup') && tag.tagValue && tag.tagValue.startsWith('/KnowledgeBase')) {
                                return [2 /*return*/, true];
                            }
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    FAQApiService.prototype.activateFaqInScenario = function () {
        return __awaiter(this, void 0, void 0, function () {
            var existingData, newState, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.projectHasFaqState()];
                    case 1:
                        existingData = _a.sent();
                        newState = {
                            state: {
                                value: existingData ? 'KnowledgeBaseHandler' : 'KnowledgeBase',
                                blocks: [
                                    {
                                        tagName: 'intentGroup!',
                                        tagValue: '/KnowledgeBase',
                                    },
                                    {
                                        tagName: 'script',
                                        tagValue: '$faq.pushReplies();',
                                    },
                                ],
                            },
                        };
                        return [4 /*yield*/, this.projectApi.createRootState(this.accountId, this.projectShortName, newState)];
                    case 2:
                        result = (_a.sent()).data;
                        return [2 /*return*/, result];
                }
            });
        });
    };
    FAQApiService.BASE_PATH = '';
    return FAQApiService;
}());
export default FAQApiService;
