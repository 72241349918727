import React, { Component, createContext, FocusEvent, useContext } from 'react';
import { CustomTagData } from '../api/client';
import { EditorContext, EditorContextType } from './EditorContext';
import Konva from 'konva';
import { Block, BlockPosition, MetaBlock, SimpleBlock } from '../model/Block';
import dagre, { GraphLabel } from 'dagre';
import { BlocksPositions } from '../service/GraphService';
import { measureText } from '../components/VisualEditor/utils/blockLayer';
import { getColNumber } from './contextUtils';
import { Connection, TransitionType } from '../model/Graph';
import { getBlockBg } from '../components/VisualEditor/utils/stageFunctions';

const CONVA_PADDING_X = 50;
const CONVA_PADDING_Y = 50;

const GRAPH_INIT_CONFIG: GraphLabel = {
  rankdir: 'LR',
  nodesep: 100,
  ranksep: 100,
  marginx: 100,
  marginy: 100,
  ranker: 'network-simplex', //network-simplex, tight-tree or longest-path
};

class MeasuresData {
  data: { [key: string]: number } = {};
  measureWidth: (text: string) => number = (text: string) => {
    this.data[text] = measureText(text) + 4;
    return this.data[text];
  };
  getMeasure: (text: string) => number = (text: string) => {
    if (this.data[text]) return this.data[text];
    return this.measureWidth(text);
  };
}

export const measureData = new MeasuresData();

export type VisualEditorContextType = {
  customTags: CustomTagData[];

  editBlock?: MetaBlock;
  setEditBlock: (editBlock: MetaBlock) => void;
  closeEditBlock: () => void;

  setBlocksPositions: (blocksPositions: BlocksPositions) => void;
  autoPlaceBlocks: (stage?: Konva.Stage | null) => void;

  placeBlocksLikeAimylogic: (stage?: Konva.Stage | null) => void;

  saveBlockProps: (block: MetaBlock) => void;
  saveBlockCode: (code: string) => void;

  setAddingIntoBlock: (block: Block) => void;
  addingIntoBlock?: Block;
  clearAddingIntoBlock: () => void;

  setAddingMenuPosition: (position: BlockPosition) => void;
  addingMenuPosition?: BlockPosition;

  setOpenCodeEditor: (screen: SimpleBlock) => void;
  closeCodeEditor: () => void;
  openBlockForEdit: (screen: Block) => unknown;
  openCodeEditor?: SimpleBlock;

  handleBlur: (e: FocusEvent<HTMLDivElement>) => void;
  addTransition: (block: Block, type: TransitionType) => void;

  addBlock: (tag: string, position: BlockPosition) => void;
  removeBlocks: (blocks: Block[]) => void;

  addConnection: (from: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>, to: string) => void;
  removeConnections: (connections: Connection[]) => void;
};

export const VisualEditorContext = createContext({} as VisualEditorContextType);

class State {
  openCodeEditor?: SimpleBlock;
  editBlock?: MetaBlock;
  addingMenuPosition?: BlockPosition;
  addingIntoBlock?: Block;
}

export class VisualEditorContextProvider extends Component<{}, State> {
  static contextType = EditorContext;
  context!: EditorContextType;
  state = new State();

  handleBlur = () => {
    if (
      this.state.openCodeEditor ||
      this.state.editBlock ||
      this.state.addingMenuPosition ||
      this.context.isFileModifiedInProgress
    )
      return;
    this.context.save();
  };

  addTransition = (block: Block, type: TransitionType) => {
    if (!this.context.currentGraph) return;
    const [graph, actions] = this.context.graphService.addBlockTransition(this.context.currentGraph, block, type);
    this.setState({ addingIntoBlock: undefined, addingMenuPosition: undefined });
    this.context.setGraph(graph);
    this.context.addActions(actions);
  };

  autoPlaceBlocks = (stage?: Konva.Stage | null) => {
    if (!this.context.currentGraph) return;
    const graph = this.context.currentGraph;
    const blocks = graph.blocks;
    const connections = graph.connections || [];
    if (!stage || !blocks || blocks.length === 0) return;

    const g = new dagre.graphlib.Graph();
    g.setGraph(GRAPH_INIT_CONFIG);

    g.setDefaultEdgeLabel(() => ({}));

    blocks.forEach((block, index) => {
      if (!block.id) return;
      const blockNode = getBlockBg(stage, block);
      if (!blockNode) return;

      g.setNode(block.id, {
        label: block.id,
        name: block.id,
        width: blockNode.width(),
        height: blockNode.height(),
        rank: index === 0 ? 0 : 1,
      });
    });

    if (g.nodeCount() === 0) return;

    connections.forEach(connection => {
      if (connection.label === 'fromState') {
        g.setEdge(connection.to, connection.from);
      } else {
        g.setEdge(connection.from, connection.to);
      }
    });

    try {
      dagre.layout(g);
    } catch (error) {
      console.error(error);
    }

    //FIXME: lodash update has broken dagre: it puts NaN to y.
    // When issue https://github.com/dagrejs/dagre/issues/221 is closed
    // delete costyl and uncomment original block
    //
    // const positions = g.nodes().reduce((ds, nodeId) => {
    //   const nodeData = g.node(nodeId);
    //   if (!nodeData) return ds;
    //   return Object.assign(ds, { [nodeId]: { x: nodeData?.x || 0, y: nodeData?.y || 0 } });
    // }, {} as BlocksPositions);
    const padY: { [x: number]: number } = {};
    const positions = g.nodes().reduce((ds, nodeId) => {
      const nodeData = g.node(nodeId);
      if (!nodeData) return ds;

      const x = nodeData.x || 0;
      let y = nodeData.y;

      if (typeof y !== 'number' || isNaN(y)) {
        if (padY[nodeData.x]) {
          y = padY[nodeData.x];
          padY[nodeData.x] = y + nodeData.height + 100;
        } else {
          y = 0;
          padY[nodeData.x] = nodeData.height + 100;
        }
      }
      return Object.assign(ds, { [nodeId]: { x, y } });
    }, {} as BlocksPositions);
    //costyl ends here

    this.setBlocksPositions(positions);
  };

  placeBlocksLikeAimylogic = (stage?: Konva.Stage | null) => {
    if (!stage || !this.context.currentGraph) return;

    const graph = this.context.currentGraph;
    const blocks = graph.blocks;

    if (!blocks || blocks.length === 0) return;

    const colOffsets: number[] = [];

    const positions = blocks.reduce((positions, block) => {
      const position = {} as BlockPosition;

      const col = getColNumber(String(block.aimyCol));
      if (!colOffsets[col]) colOffsets[col] = CONVA_PADDING_Y;

      position.x = CONVA_PADDING_X + col * 350;
      position.y = colOffsets[col];

      colOffsets[col] += getBlockBg(stage, block).height() + 20;

      return Object.assign(positions, { [block.id]: position });
    }, {});

    this.setBlocksPositions(positions);
  };

  setBlocksPositions = (positions: BlocksPositions) => {
    if (!this.context.currentGraph) return;
    const [graph, actions] = this.context.graphService.setBlocksPositions(this.context.currentGraph, positions);
    this.context.setGraph(graph);
    this.context.addActions(actions);
  };

  addBlock = (tag: string, position: BlockPosition) => {
    if (!this.context.currentGraph) return;
    const [graph, newBlock, actions] = this.context.graphService.addBlock(
      this.context.currentGraph,
      tag,
      position,
      this.context.customTags
    );
    this.context.setGraph(graph);
    this.context.addActions(actions);
    this.openBlockForEdit(newBlock);
  };

  saveBlockProps = (block: Block) => {
    if (!this.context.currentGraph) return;
    const [graph, action] = this.context.graphService.updateBlockProps(
      this.context.currentGraph,
      block,
      this.state.editBlock
    );
    this.context.setGraph(graph);
    this.setState({ editBlock: undefined });
    this.context.addActions([action]);
  };

  saveBlockCode = (code: string) => {
    if (!this.context.currentGraph || !this.state.openCodeEditor) return;
    const [, action] = this.context.graphService.updateBlockCode(
      this.context.currentGraph,
      this.state.openCodeEditor,
      code
    );
    this.context.addActions([action]).then(() => this.setState({ openCodeEditor: undefined }));
  };

  addConnection = (connection: Pick<Connection, 'from' | 'innerPath' | 'outerType' | 'type'>, to: string) => {
    if (!this.context.currentGraph) return;
    const [graph, actions] = this.context.graphService.addConnection(this.context.currentGraph, connection, to);
    this.context.setGraph(graph);
    this.context.addActions(actions);
  };

  openBlockForEdit = (block: Block) => {
    if (block.type === 'block' && this.context.currentVisualSettings.useCodeEditorOnScreenAdd) {
      this.setState({ openCodeEditor: block });
    }
    if (block.type === 'metaBlock') {
      this.setState({ editBlock: block });
    }
  };

  removeBlocks = (blocks: Block[]) => {
    if (!this.context.currentGraph) return;
    if (blocks.some(block => block.id === this.state.editBlock?.id)) this.setState({ editBlock: undefined });
    if (blocks.some(block => block.id === this.state.openCodeEditor?.id)) this.setState({ openCodeEditor: undefined });

    const [graph, actions] = this.context.graphService.removeBlocks(this.context.currentGraph, blocks);
    this.context.setGraph(graph);
    this.context.addActions(actions);
  };

  removeConnections = (connections: Connection[]) => {
    if (!this.context.currentGraph) return;
    const [graph, actions] = this.context.graphService.removeConnections(this.context.currentGraph, connections);
    this.context.setGraph(graph);
    this.context.addActions(actions);
  };

  closeEditBlock = () => {
    if (!this.context.currentGraph) return;
    if (this.state.editBlock?.isNew) {
      const graph = this.context.graphService.removeNewBlock(this.context.currentGraph, this.state.editBlock.statePath);
      this.context.setGraph(graph);
    }
    this.setState({ editBlock: undefined });
  };

  render() {
    return (
      <VisualEditorContext.Provider
        value={{
          customTags: this.context.customTags,

          editBlock: this.state.editBlock,
          setEditBlock: block => this.setState({ editBlock: block }),
          closeEditBlock: this.closeEditBlock,

          setBlocksPositions: this.setBlocksPositions,
          autoPlaceBlocks: this.autoPlaceBlocks,
          placeBlocksLikeAimylogic: this.placeBlocksLikeAimylogic,

          saveBlockProps: this.saveBlockProps,
          saveBlockCode: this.saveBlockCode,

          addingIntoBlock: this.state.addingIntoBlock,
          setAddingIntoBlock: block => this.setState({ addingIntoBlock: block }),
          clearAddingIntoBlock: () => this.setState({ addingIntoBlock: undefined }),

          addingMenuPosition: this.state.addingMenuPosition,
          setAddingMenuPosition: position => this.setState({ addingMenuPosition: position }),

          openCodeEditor: this.state.openCodeEditor,
          setOpenCodeEditor: (block: SimpleBlock) => this.setState({ openCodeEditor: block }),
          closeCodeEditor: () => this.setState({ openCodeEditor: undefined }),

          openBlockForEdit: this.openBlockForEdit,

          handleBlur: this.handleBlur,
          addTransition: this.addTransition,

          addBlock: this.addBlock,
          removeBlocks: this.removeBlocks,

          addConnection: this.addConnection,
          removeConnections: this.removeConnections,
        }}
      >
        {this.props.children}
      </VisualEditorContext.Provider>
    );
  }
}

export const useVisualEditorContext = () => useContext(VisualEditorContext);
