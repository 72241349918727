var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { InputGroup as RsInputGroup, InputGroupAddon, } from 'reactstrap';
import classNames from 'classnames';
import { IconButton } from "..";
const InputGroup = (_a) => {
    var { children, Append, Prepend, AppendInner = [], PrependInner, className, size } = _a, restProps = __rest(_a, ["children", "Append", "Prepend", "AppendInner", "PrependInner", "className", "size"]);
    return (React.createElement(RsInputGroup, Object.assign({}, restProps, { size: size, className: classNames(className, {
            'InputGroup-AppendInner': AppendInner && AppendInner.length > 0,
            'InputGroup-AppendInner-2': AppendInner && AppendInner.length === 2
        }, {
            'InputGroup-PrependInner': PrependInner && PrependInner.length > 0,
            'InputGroup-PrependInner-2': PrependInner && PrependInner.length === 2
        }) }),
        Prepend &&
            React.createElement(InputGroupAddon, { addonType: 'prepend' }, Prepend),
        PrependInner &&
            React.createElement("div", { className: 'IconButtons-wrap IconButtons-wrap-prepend' }, PrependInner.map((_a, index) => {
                var props = __rest(_a, []);
                return React.createElement(IconButton, Object.assign({ withoutPadding: true, flat: true, size: size }, props, { key: `IconButtons-wrap-prepend${index}` }));
            })),
        children,
        AppendInner &&
            React.createElement("div", { className: 'IconButtons-wrap IconButtons-wrap-append' }, AppendInner.map((_a, index) => {
                var props = __rest(_a, []);
                return React.createElement(IconButton, Object.assign({ withoutPadding: true, flat: true, size: size }, props, { key: `IconButtons-wrap-append${index}` }));
            })),
        Append &&
            React.createElement(InputGroupAddon, { addonType: 'append' }, Append)));
};
export default InputGroup;
