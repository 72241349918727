import React from 'react';
import FileUploadContent from './FileUploadContent';
import { FormText, IconButton } from '../../index';
import cn from 'classnames';
import { useTranslation } from '../../contexts/transtationContext';
import { AnswerReplyType } from '../types';
export default function FileTab(props) {
    const { t } = useTranslation();
    const { type, localFile, isLoading, handleAudioChange, localChange, error, isWhiteLabel } = props;
    return (React.createElement("div", null,
        React.createElement(FileUploadContent, { saveAudioToServer: props.saveAudioToServer, type: type, localFile: localFile, isLoading: isLoading, localChange: localChange, handleAudioChange: handleAudioChange }),
        type === AnswerReplyType.audio && React.createElement(FormText, null, t(`AnswerModal:subtext_${type}`)),
        type === AnswerReplyType.image && localFile && (React.createElement("div", { className: 'faq-anwer-modal__img-preview' },
            React.createElement("img", { src: URL.createObjectURL(localFile), alt: '' }),
            React.createElement(IconButton, { size: 'sm', type: 'button', name: 'farTimes', color: 'none', onClick: () => localChange(undefined) }))),
        React.createElement("p", { className: cn('mt-2', { 'links-disabled': isWhiteLabel() }), dangerouslySetInnerHTML: {
                __html: t(`AnswerModal:info_${type}`, t(`AnswerModal:info_link_${type}`)),
            } }),
        isLoading && (React.createElement(FormText, { "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.CloseWarning' }, t('AnswerModal:close_warning'))),
        error && (React.createElement(FormText, { color: 'danger', "data-test-id": 'FAQPage.AnswerBlock.ModalEdit.Error' }, error))));
}
