var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Intents } from '../nlu-related/utils';
import '../localization';
import { t } from '../localization';
import { FAQBaseIntentName, FAQBaseIntentNamePrefix, FAQIntentBasePath, FAQIntentBaseQuestion, FAQIntentBaseGroup, FAQIntentRootGroup, } from '../constants/faqTemplates';
export var FAQIntentPathType;
(function (FAQIntentPathType) {
    FAQIntentPathType[FAQIntentPathType["Base"] = 0] = "Base";
    FAQIntentPathType[FAQIntentPathType["TemplateName"] = 1] = "TemplateName";
    FAQIntentPathType[FAQIntentPathType["Group"] = 2] = "Group";
    FAQIntentPathType[FAQIntentPathType["Question"] = 3] = "Question";
})(FAQIntentPathType || (FAQIntentPathType = {}));
var FAQIntentService = /** @class */ (function () {
    function FAQIntentService() {
    }
    FAQIntentService.generateFAQTemplateName = function (name) {
        return "".concat(FAQBaseIntentNamePrefix, ".").concat(name);
    };
    FAQIntentService.getIntentDepth = function (path) {
        return path.split('/').filter(Boolean).length;
    };
    FAQIntentService.isNormalizedFAQQuestionIntent = function (newIntent) {
        var intentName = Intents.getNameFromPath(newIntent === null || newIntent === void 0 ? void 0 : newIntent.path);
        if (!newIntent.phrases || newIntent.phrases.length === 0) {
            return false;
        }
        return !!newIntent.phrases.find(function (phrase) { return phrase.text === intentName; });
    };
    FAQIntentService.normalizeIntentLikeFAQQuestion = function (newIntent, oldIntent) {
        var oldIntentName = Intents.getNameFromPath(oldIntent === null || oldIntent === void 0 ? void 0 : oldIntent.path);
        var intentQuestion = Intents.getNameFromPath(newIntent.path);
        var basePhrase = { text: intentQuestion };
        if (!newIntent.phrases || newIntent.phrases.length === 0) {
            return __assign(__assign({}, newIntent), { phrases: [basePhrase] });
        }
        var existedPhrase = newIntent.phrases.find(function (phrase) { return phrase.text === intentQuestion; });
        if (!existedPhrase) {
            var phrases = __spreadArray(__spreadArray([], newIntent.phrases, true), [basePhrase], false);
            var index = phrases.findIndex(function (el) { return el.text === oldIntentName; });
            if (index !== -1 && oldIntentName !== intentQuestion) {
                phrases.splice(index, 1);
            }
            newIntent = __assign(__assign({}, newIntent), { phrases: phrases });
        }
        return newIntent;
    };
    FAQIntentService.generateNewQuestionName = function (intentsList, templateName) {
        var startTemplatePathForChildren = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
        var topChildren = intentsList
            .filter(function (intent) {
            var parentPath = Intents.getParentPathFromPath(intent.path);
            return parentPath === startTemplatePathForChildren || parentPath === "".concat(startTemplatePathForChildren, "/Root");
        })
            .map(function (intent) { return Intents.getNameFromPath(intent.path); });
        return this.generateNewUniqNameWithIndex(topChildren, FAQIntentBaseQuestion);
    };
    FAQIntentService.createNewQuestion = function (intentsList, templateName) {
        var newQuestionName = this.generateNewQuestionName(intentsList, templateName);
        var newIntent = {
            path: FAQIntentPathBuilder.initWithTemplateName(templateName)
                .addRootGroup()
                .addQuestion(newQuestionName)
                .getPath(),
        };
        newIntent = this.normalizeIntentLikeFAQQuestion(newIntent);
        return newIntent;
    };
    FAQIntentService.createNewGroup = function (intentsList, templateName, groupName) {
        var newGroupName = this.normalizeNewGroupName(intentsList, templateName, groupName);
        var newIntent = {
            path: FAQIntentPathBuilder.initWithTemplateName(templateName).addGroup(newGroupName).getPath(),
        };
        return newIntent;
    };
    FAQIntentService.getTreeByIntentName = function (intentsList, intentName) {
        return intentsList
            .filter(function (intent) { var _b; return (_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith("/".concat(FAQBaseIntentName, "/").concat(intentName, "/")); })
            .map(function (intent) {
            var _b;
            return (__assign(__assign({}, intent), { path: (_b = intent.path) === null || _b === void 0 ? void 0 : _b.replace("/KnowledgeBase/".concat(intentName), '') }));
        })
            .filter(function (intent) { return intent.path; });
    };
    FAQIntentService.getTopChildrenIntentsByParentPath = function (parentPath, intentsList) {
        return intentsList
            .filter(function (intent) { var _b; return (_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(parentPath); })
            .map(function (intent) {
            var _b, _c;
            var parts = (_c = (_b = intent.path) === null || _b === void 0 ? void 0 : _b.split('/').filter(Boolean)) !== null && _c !== void 0 ? _c : [];
            return parts.length === 1 ? parts[0] : '';
        })
            .filter(function (name) { return !!name; });
    };
    FAQIntentService.generateNewUniqNameWithIndex = function (existedNames, baseName, initStep) {
        if (initStep === void 0) { initStep = 2; }
        var newName = baseName;
        var step = initStep;
        while (existedNames.includes(newName)) {
            newName = "".concat(baseName, " ").concat(step++);
        }
        return newName;
    };
    FAQIntentService.parseTemplatesNameForIntents = function (intents) {
        var templates = intents.reduce(function (acc, intent) {
            if (!intent.path)
                return acc;
            var templateName = Intents.getFAQTemplateName(intent.path);
            if (templateName && templateName.startsWith(FAQBaseIntentNamePrefix)) {
                acc.add(templateName);
            }
            return acc;
        }, new Set());
        return Array.from(templates);
    };
    FAQIntentService.getTemplatePath = function (templateName) {
        return "/".concat(FAQBaseIntentName, "/").concat(templateName);
    };
    FAQIntentService.generateRootPath = function (templateName) {
        return "".concat(FAQIntentService.getTemplatePath(templateName), "/").concat(FAQIntentRootGroup);
    };
    FAQIntentService.isNodeFolder = function (path) {
        return (path !== "/".concat(FAQIntentRootGroup) &&
            !(path === null || path === void 0 ? void 0 : path.startsWith("/".concat(FAQIntentRootGroup, "/"))) &&
            FAQIntentService.getIntentDepth(path || '') === 1);
    };
    FAQIntentService.isInRoot = function (path) {
        return !!(path === null || path === void 0 ? void 0 : path.startsWith("/".concat(FAQIntentRootGroup, "/")));
    };
    FAQIntentService.transformToFAQTree = function (intents) {
        var intentsDataset = Intents.mapIntentsToTree(intents);
        var treeDataset = {};
        for (var nodeId in intentsDataset) {
            var node = intentsDataset[nodeId];
            treeDataset[nodeId] = __assign(__assign({}, node), { isFolder: FAQIntentService.isNodeFolder(node.path), isInRoot: FAQIntentService.isInRoot(node.path) });
        }
        return treeDataset;
    };
    FAQIntentService.switchIntentsEnablement = function (intents, enabled) {
        if (enabled === void 0) { enabled = false; }
        return intents.map(function (intent) { return (__assign(__assign({}, intent), { enabled: enabled })); });
    };
    FAQIntentService.areAllIntentsDisabled = function (intents) {
        return !intents.find(function (intent) { return intent.enabled; });
    };
    FAQIntentService.filterIntentsByTemplateName = function (intents, templateName, excludeRootIntent) {
        if (excludeRootIntent === void 0) { excludeRootIntent = false; }
        var path = FAQIntentPathBuilder.initWithTemplateName(templateName).add('/').getPath();
        var filteredIntents = intents.filter(function (intent) { var _b; return (_b = intent === null || intent === void 0 ? void 0 : intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path); });
        if (!excludeRootIntent)
            return filteredIntents;
        var rootPath = FAQIntentPathBuilder.init(path).addRootGroup().getPath();
        return filteredIntents.filter(function (intent) { return (intent === null || intent === void 0 ? void 0 : intent.path) !== rootPath; });
    };
    FAQIntentService.findClosestNode = function (nodes, nodesToMeetIds) {
        var newSelectedNode = undefined;
        var nodeWasFound = false;
        nodes.some(function (node) {
            if (nodesToMeetIds.find(function (id) { return id === node.id; })) {
                if (!newSelectedNode) {
                    nodeWasFound = true;
                    return false;
                }
                return true;
            }
            if (node.isFolder)
                return false;
            if (nodeWasFound) {
                newSelectedNode = node;
                return true;
            }
            newSelectedNode = node;
            return false;
        });
        return newSelectedNode;
    };
    var _a;
    _a = FAQIntentService;
    FAQIntentService.getFAQIntentPathIndexRegExp = function (initPath) { return new RegExp("^".concat(initPath, "\\s*(\\d*)")); };
    FAQIntentService.findFAQIntent = function (intentsList, path, strict) {
        return intentsList.find(function (intent) { var _b; return (strict ? intent.path === path : !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path))); });
    };
    FAQIntentService.isIntentChildrenForPath = function (parentPath, childrenPath) {
        if (!parentPath.endsWith('/')) {
            parentPath = parentPath + '/';
        }
        return childrenPath.startsWith(parentPath);
    };
    FAQIntentService.filterFAQIntents = function (intentsList, path) {
        if (path === void 0) { path = FAQIntentBasePath; }
        return intentsList.filter(function (intent) { var _b; return !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(path)); });
    };
    FAQIntentService.getFAQIntentByName = function (intentsList, templateName, strict) {
        var intentPath = FAQIntentPathBuilder.init().addTemplateName(templateName).getPath();
        return _a.findFAQIntent(intentsList, intentPath, strict);
    };
    FAQIntentService.getNewFAQIntentPath = function (intentsList, baseName) {
        var existingPathes = intentsList
            .filter(function (intent) { return intent.path; })
            .map(function (intent) { return _a.getTemplateTitleFromPath(intent.path); });
        var templateName = _a.generateNewUniqNameWithIndex(existingPathes, baseName, 1);
        var templateIntentPath = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
        var questionIntentPath = FAQIntentPathBuilder.initBaseQuestionIntent(templateName).getPath();
        return [templateIntentPath, questionIntentPath];
    };
    FAQIntentService.createNewFAQ = function (intentsList, templateTitle) {
        var _b = _a.getNewFAQIntentPath(intentsList, templateTitle), templateIntentPath = _b[0], questionIntentPath = _b[1];
        return [
            { path: templateIntentPath },
            {
                path: questionIntentPath,
                phrases: [{ text: FAQIntentBaseQuestion }],
            },
        ];
    };
    FAQIntentService.getTemplateTitleFromPath = function (path) {
        if (!path)
            return '';
        var childrenPath = path.replace(FAQIntentBasePath, '');
        return childrenPath.indexOf('/') === -1 ? childrenPath : childrenPath.substring(0, childrenPath.indexOf('/'));
    };
    FAQIntentService.getIntentType = function (path) {
        var question = FAQIntentPathParser.getQuestion(path);
        if (question)
            return FAQIntentPathType['Question'];
        var group = FAQIntentPathParser.getGroup(path);
        if (group)
            return FAQIntentPathType['Group'];
        var template = FAQIntentPathParser.getTemplateName(path);
        if (template)
            return FAQIntentPathType['TemplateName'];
        return FAQIntentPathType['Base'];
    };
    FAQIntentService.getLelevelNameFromPath = function (path, parentPath) {
        if (parentPath === void 0) { parentPath = FAQIntentBasePath; }
        if (!path)
            return '';
        var childrenPath = path.replace(parentPath, '');
        return childrenPath.substring(0, childrenPath.indexOf('/'));
    };
    FAQIntentService.normalizeNewGroupName = function (intentsList, templateName, groupName) {
        if (groupName === void 0) { groupName = FAQIntentBaseGroup; }
        var baseIntentPath = FAQIntentPathBuilder.initWithTemplateName(templateName).getPath();
        var groupNames = intentsList
            .filter(function (intent) { var _b; return !!((_b = intent.path) === null || _b === void 0 ? void 0 : _b.startsWith(baseIntentPath)); })
            .map(function (intent) { return FAQIntentPathParser.getGroup(intent.path); });
        return _a.generateNewUniqNameWithIndex(groupNames, groupName);
    };
    return FAQIntentService;
}());
export { FAQIntentService };
var FAQIntentPathParser = /** @class */ (function () {
    function FAQIntentPathParser() {
    }
    FAQIntentPathParser.getPathPart = function (path, pathPartName) {
        if (path === void 0) { path = undefined; }
        if (!path)
            return undefined;
        var pathParts = path.split('/').filter(Boolean);
        if (!pathParts.length || pathParts.length - 1 < FAQIntentPathType[pathPartName])
            return undefined;
        return pathParts[FAQIntentPathType[pathPartName]];
    };
    FAQIntentPathParser.getBase = function (path) {
        return this.getPathPart(path, 'Base');
    };
    FAQIntentPathParser.getTemplateName = function (path) {
        return this.getPathPart(path, 'TemplateName');
    };
    FAQIntentPathParser.getGroup = function (path) {
        return this.getPathPart(path, 'Group');
    };
    FAQIntentPathParser.getQuestion = function (path) {
        return this.getPathPart(path, 'Question');
    };
    return FAQIntentPathParser;
}());
export { FAQIntentPathParser };
var FAQIntentPathBuilder = /** @class */ (function () {
    function FAQIntentPathBuilder() {
    }
    FAQIntentPathBuilder.init = function (initPath) {
        if (initPath === void 0) { initPath = FAQIntentBasePath; }
        this.path = initPath;
        return this;
    };
    FAQIntentPathBuilder.initBaseQuestionIntent = function (templateName) {
        this.path = this.init().addTemplateName(templateName).addRootGroup().addQuestion().getPath();
        return this;
    };
    FAQIntentPathBuilder.initWithTemplateName = function (templateName) {
        if (templateName === void 0) { templateName = FAQIntentBasePath; }
        this.path = this.init().addTemplateName(templateName).getPath();
        return this;
    };
    FAQIntentPathBuilder.add = function (path) {
        this.path += path;
        return this;
    };
    FAQIntentPathBuilder.addTemplateName = function (templateName) {
        this.path += templateName;
        return this;
    };
    FAQIntentPathBuilder.resetPath = function () {
        this.path = null;
        return this;
    };
    FAQIntentPathBuilder.getPath = function () {
        var pathToReturn = this.path;
        this.resetPath();
        return pathToReturn || '';
    };
    FAQIntentPathBuilder.addIndex = function (index) {
        if (index === void 0) { index = 0; }
        if (index !== 0) {
            this.path += " ".concat(index);
        }
        return this;
    };
    FAQIntentPathBuilder.addGroup = function (group) {
        if (group === void 0) { group = FAQIntentBaseGroup; }
        this.path += "/".concat(group);
        return this;
    };
    FAQIntentPathBuilder.addRootGroup = function () {
        return this.addGroup(FAQIntentRootGroup);
    };
    FAQIntentPathBuilder.addQuestion = function (question) {
        if (question === void 0) { question = t('FAQ:Intent:BaseQuestionName'); }
        this.path += "/".concat(question);
        return this;
    };
    FAQIntentPathBuilder.path = null;
    return FAQIntentPathBuilder;
}());
export { FAQIntentPathBuilder };
