import { Dropdown, DropdownToggle, DropdownMenu } from '@just-ai/just-ui';
import React, { useCallback, useState, useEffect } from 'react';

import { ItemMenu } from '../types';
import styles from './styles.module.scss';

import { Icon } from '@just-ai/just-ui/dist';
import cn from 'classnames';

const DEFAULT_MODIFIERS = {
  offset: { offset: '-9, -8' },
};

const voidFn = () => {};

interface MenuItemInterface {
  menuItem: ItemMenu;
  onSelect: (item: ItemMenu) => void;
  expanded: boolean;
  'data-test-id'?: string;
}
const MenuItem = function ({ menuItem, onSelect, expanded, 'data-test-id': dataTestId }: MenuItemInterface) {
  const [expandedChild, setExpandedChild] = useState<ItemMenu>();

  const onToggle = useCallback(() => onSelect(menuItem), [menuItem, onSelect]);

  const onSelectInner = useCallback(
    item => {
      if (item.children && item.children?.length > 0) {
        setExpandedChild(prevItem => (prevItem?.value === item.value ? prevItem : item));
        return;
      }
      onSelect(item);
    },
    [onSelect]
  );

  useEffect(() => {
    if (expanded) return;
    setExpandedChild(undefined);
  }, [expanded]);

  const IconCmp = menuItem?.iconCmp;
  return (
    <Dropdown direction='right' isOpen={expanded} toggle={voidFn}>
      <DropdownToggle tag='div' outline withoutPadding compact disabled>
        <div data-test-id={dataTestId} className={cn(styles.MenuItem)} onClick={onToggle}>
          <div className={cn('flex-row gap-2x')}>
            {IconCmp && <IconCmp />}
            <div className={styles.MenuItem__text}>{menuItem.label}</div>
          </div>
          {menuItem?.children && menuItem?.children.length > 0 && (
            <>
              <Icon name='farChevronRight' wrapperClassName={styles.MenuItem__arrowIcon} />
              <DropdownMenu className={styles.MenuItem__menu} flip={false} modifiers={DEFAULT_MODIFIERS}>
                {menuItem.children.map(option => (
                  <MenuItem
                    key={option.value}
                    menuItem={option}
                    onSelect={onSelectInner}
                    expanded={expandedChild?.value === option.value}
                    data-test-id={`MultiLevelSelect.Item.${option.value}`}
                  />
                ))}
              </DropdownMenu>
            </>
          )}
        </div>
      </DropdownToggle>
    </Dropdown>
  );
};

export default React.memo(MenuItem);
