import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, FormGroup, JustSelect, Label, OptionType } from '@just-ai/just-ui';

import { t } from 'localization';
import { hideRootSlashInPath } from 'modules/JGraph/utils/state';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { LabelComponentSelectState } from 'modules/JGraph/components/NewCommonActivationsParams/LabelComponentForSelectAndCreate';

import { setEditMenuBlock } from '../../../../../../reducers/JGraph.reducer';
import { findScreenByPath, getAllStates, getValidKonvaName } from '../../../../../../reducers/JGraph.reducer/Graph';
import { addNewStateWithSave } from '../../../../../../reducers/JGraph.reducer/JGraphAsyncActions';
import { Target } from '../../settings/TransitionMenu';
import classes from './CommonActivationParams.module.scss';

import { checkSlashInStateName } from 'modules/JGraph/utils/validators/stateName';

export const CommonActivationSelectState: FC<{
  isFromState?: boolean;
  isToState?: boolean;
  isGlobal: boolean;
  fromStateValue: string;
  toStateValue: string;
  onChangeStateValue: (value: string) => unknown;
  label?: string | React.ReactNode;
}> = ({ isFromState, isToState, isGlobal, fromStateValue, toStateValue, onChangeStateValue, label }) => {
  const dispatch = useAppDispatch();
  const { states } = useAppSelector(state => ({
    states: state.JGraphReducer.graph.blocks,
  }));
  const [errorText, setErrorText] = useState('');

  const allStates = useMemo(() => {
    let returnOptions: OptionType[] = getAllStates(states).map(state => ({
      value: state,
      label: hideRootSlashInPath(state),
    }));
    return returnOptions;
  }, [states]);

  const [localAllStates, setAllStates] = useState(allStates);

  useEffect(() => {
    setAllStates(allStates);
  }, [allStates]);

  const onInputHandler = useCallback(
    async (searchValue: string) => {
      searchValue = searchValue.trim();
      if (!searchValue) {
        setErrorText('');
        setAllStates(allStates);
        return;
      }
      const validationResult = await checkSlashInStateName({ name: searchValue });
      if (!validationResult.isValid) {
        setErrorText(validationResult.errors?.name?.message || '');
        setAllStates([]);
        return;
      }

      setErrorText('');
      const match = allStates.filter(option => (option.label as string).toLowerCase() === searchValue.toLowerCase());
      let newOptions = allStates.filter(option => option.value !== 'new_value');

      if (searchValue && match.length === 0) {
        newOptions.splice(0, 0, {
          label: searchValue,
          value: 'new_value',
        });
        newOptions = [...newOptions];
      }
      setAllStates(newOptions);
    },
    [allStates]
  );

  const onSelectHandler = useCallback(
    value => {
      if (value && value[0]) {
        if (value[0] === 'new_value') {
          const option = localAllStates.find(option => option.value === value[0]);
          if (option) {
            const path = option.label[0] === '/' ? option.label.trim() : '/' + option.label.trim();
            dispatch(addNewStateWithSave({ screenPath: path, saveWithoutSetEdit: true }));
            setTimeout(() => {
              onChangeStateValue(path);
            }, 0);
          }
        } else {
          onChangeStateValue(value[0] as string);
        }
        return;
      }
      onChangeStateValue('');
    },
    [onChangeStateValue, localAllStates, dispatch]
  );

  const goToTargetState = useCallback(() => {
    const targetState = findScreenByPath(fromStateValue || toStateValue, states);
    if (targetState) {
      scrollToTargetGlobal$.next({
        targetPathId: getValidKonvaName(fromStateValue || toStateValue),
        isSideMenuOpen: true,
      });
      dispatch(setEditMenuBlock({ screen: targetState || toStateValue, jBlockIndex: undefined, path: undefined }));
    }
  }, [dispatch, fromStateValue, states, toStateValue]);

  const onOpen = useCallback((isOpen: boolean) => {
    if (!isOpen) setErrorText('');
  }, []);

  return (
    <div>
      <FormGroup tag='div' className={classNames(classes.formGroupCustom, { [classes.formGroupDisabled]: isGlobal })}>
        {label || (
          <Label>
            {isToState ? t('EventActivation:fromStateLabelAsToState') : t('EventActivation:fromStateLabel')}
          </Label>
        )}
        <JustSelect
          data-test-id='CommonActivationSelectState'
          disabled={isGlobal}
          fullWidth
          value={fromStateValue || toStateValue}
          options={localAllStates}
          errorText={errorText}
          onOpen={onOpen}
          onChange={onSelectHandler}
          hint={t('CreationScreenMenu:title:hint')}
          optionComponentProps={{
            rightSideText: t('CommonActivationSelectState:create'),
          }}
          rightRenderSlot={() =>
            isFromState || isToState ? (
              <Button
                className={classes.forwardButton}
                color='primary'
                disabled={isFromState ? !fromStateValue : isToState ? !toStateValue : !fromStateValue}
                outline
                flat
                onClick={goToTargetState}
              >
                <Target />
              </Button>
            ) : null
          }
          optionComponent={LabelComponentSelectState}
          onInput={isFromState || isToState ? onInputHandler : undefined}
          inputPlaceholder={
            isToState
              ? t('EventActivation:stateSelectPlaceholderAsToState')
              : t('EventActivation:stateSelectPlaceholder')
          }
          position='fixed'
        />
      </FormGroup>
    </div>
  );
};
