var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Input } from 'reactstrap';
import './style.scss';
import classNames from 'classnames';
export function RadioButton(_a) {
    var { name, value, className, onChange = () => { }, label, dark, disabled, readOnly, rootClassName } = _a, restProps = __rest(_a, ["name", "value", "className", "onChange", "label", "dark", "disabled", "readOnly", "rootClassName"]);
    return (React.createElement("label", { className: classNames('justui_input-radioButton-container', rootClassName, {
            disabled: disabled || readOnly,
            dark,
            label,
        }) },
        label && React.createElement("span", { className: 'justui_input-radioButton-label' }, label),
        React.createElement(Input, Object.assign({ type: 'radio', name: name, value: value, onChange: event => onChange(event.target.value, name), disabled: disabled, readOnly: readOnly, style: { height: 0, width: 0 } }, restProps)),
        React.createElement("span", { className: classNames('justui_input-radioButton', className) })));
}
