import React from 'react';
import { TranslationContextProvider } from '@just-ai/just-ui/dist/contexts/transtationContext';
import { faqLocalization } from '@just-ai/nlu-modules/dist/localization/faq.loc';
import ReactDOM from 'react-dom';
import localize, { t, tWithCheck } from 'localization';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';
import '@just-ai/just-ui/fonts/build.fonts.scss';
import './App.scss';
import './sentry';
import { AppContextProvider } from './modules/Caila/components/AppContext';

localize.addTranslations(faqLocalization);

ReactDOM.render(
  <Provider store={store}>
    <TranslationContextProvider t={t} tWithCheck={tWithCheck} getLocale={localize.getLocale}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </TranslationContextProvider>
  </Provider>,
  document.getElementById('root')
);
