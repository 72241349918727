import React, { useState, useCallback, useEffect } from 'react';
import { Modal, InputText, Spinner, useTranslation } from '@just-ai/just-ui';
import { useLoading } from '../../../../nlu-related/utils';
var FAQ_SKILL_PREFIX = 'FAQ.';
var RenameSkillModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, skill = _a.skill, skills = _a.skills, onRename = _a.onRename;
    var _b = useState(skill.name), name = _b[0], setName = _b[1];
    var _c = useState(false), isValid = _c[0], setIsValid = _c[1];
    var _d = useState(''), errorText = _d[0], setErrorText = _d[1];
    var _e = useLoading(), isLoading = _e[0], load = _e[1];
    var t = useTranslation().t;
    useEffect(function () {
        setName(skill.name);
        setIsValid(true);
        setErrorText('');
    }, [skill]);
    useEffect(function () {
        setName(skill.name);
    }, [skill.name]);
    var onChange = useCallback(function (title) {
        if (title.length < 4 || !title.startsWith(FAQ_SKILL_PREFIX)) {
            title = FAQ_SKILL_PREFIX;
        }
        setName(title);
        title = title.trim();
        var isUniqTitle = !skills.some(function (otherSkill) { return skill.name !== title && otherSkill.name === title; });
        setIsValid(isUniqTitle);
        setErrorText(!isUniqTitle ? t('FAQ:Home:RenameModal:Error:NoUniq') : '');
    }, [skill.name, skills]);
    var OnCloseInner = useCallback(function () {
        setName(skill.name);
        onClose();
    }, [onClose, skill.name]);
    var onSubmit = useCallback(function () {
        if (name.trim().length <= FAQ_SKILL_PREFIX.length)
            return OnCloseInner();
        return load(onRename(name.trim())).finally(function () { return OnCloseInner(); });
    }, [OnCloseInner, load, name, onRename]);
    return (React.createElement(Modal, { isOpen: isOpen, title: t("FAQ:Home:RenameModal:Title"), buttonSubmitColor: 'primary', buttonSubmitText: t("FAQ:Home:RenameModal:Submit"), buttonCancelColor: 'secondary', buttonCancelOutline: true, buttonCancelText: t('Cancel'), buttonSubmitDisabled: !isValid, onCancelClick: onClose, onActionClick: onSubmit, disableActionButtonAutoFocus: true },
        React.createElement("div", null,
            isLoading && React.createElement(Spinner, { size: 'sm' }),
            React.createElement("div", { className: 'margin-bottom-1x', style: { color: 'var(--gray-800)' } }, t('FAQ:Home:RenameModal:Field')),
            React.createElement(InputText, { id: 'skillName', name: 'skillName', autoFocus: true, defaultValue: skill.name, value: name, errorText: errorText, onChange: onChange }))));
};
export default React.memo(RenameSkillModal);
