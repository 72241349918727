import { useCallback, useState } from 'react';
export var useDeleteModal = function (initIsOpenState, onDelete) {
    if (initIsOpenState === void 0) { initIsOpenState = false; }
    var _a = useState(undefined), deleteItem = _a[0], setDeleteItem = _a[1];
    var _b = useState(initIsOpenState), isOpen = _b[0], setIsOpen = _b[1];
    var open = useCallback(function (item) {
        setDeleteItem(item);
        setIsOpen(true);
    }, []);
    var close = useCallback(function () {
        setDeleteItem(undefined);
        setIsOpen(false);
    }, []);
    var handleDelete = useCallback(function () {
        deleteItem && onDelete(deleteItem);
        close();
    }, [close, deleteItem, onDelete]);
    return [isOpen, open, close, handleDelete, deleteItem];
};
