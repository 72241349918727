import React, { useState, useCallback } from 'react';
import { Tab } from '@just-ai/just-ui/dist/Tabs';
import { ObservedScrollElement } from '@just-ai/just-ui/dist/ProviderObserverScroll';
import FAQTabContextMenu from '../FAQTabContextMenu';
import cn from 'classnames';
import { useNLUModuleContext } from '../../../../context';
export default function TabWithContextMenu(props) {
    var tabItem = props.tabItem, contextKey = props.contextKey, index = props.index, collapseLimit = props.collapseLimit, activeTab = props.activeTab, tabSelectProp = props.tabSelectProp, onClose = props.onClose, renameTab = props.renameTab, onTabDisable = props.onTabDisable;
    var isAccessFunction = useNLUModuleContext().isAccessFunction;
    var _a = useState(), contextMenu = _a[0], setContextMenu = _a[1];
    var closeMenu = useCallback(function () {
        setContextMenu(undefined);
    }, []);
    var handleTabContextMenu = function (e) {
        e.preventDefault();
        if (tabItem.withoutContextMenu)
            return;
        setContextMenu({ position: { x: e.clientX, y: e.clientY } });
    };
    return (React.createElement(React.Fragment, { key: tabItem.value },
        React.createElement(ObservedScrollElement, { key: tabItem.value, contextKey: contextKey, innerValue: tabItem },
            React.createElement(Tab, { className: cn('justui_tabs-tab-container', {
                    hidden: index >= collapseLimit,
                    disabled: tabItem.skillDisabled,
                }), item: tabItem, keyId: tabItem.value, isActive: activeTab === tabItem.value, onClick: tabSelectProp(tabItem.value), onClose: function () { return onClose && onClose(tabItem.value); }, onContextMenu: handleTabContextMenu, tooltip: 'onOverflow' })),
        contextMenu && (React.createElement(FAQTabContextMenu, { toggle: closeMenu, onDelete: onClose, onRename: renameTab, position: contextMenu.position, tabValue: tabItem.value, isDisabled: tabItem.skillDisabled, onDisable: onTabDisable, writeDisabled: !isAccessFunction('BOT_CONTENT_WRITE') }))));
}
