import React, { useCallback } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '@just-ai/just-ui/dist/Dropdown';
import { ClickAwayListener, useTranslation } from '@just-ai/just-ui';
import classes from './styles.module.scss';
import './style.scss';
export default function FAQTabContextMenu(props) {
    var onDelete = props.onDelete, onRename = props.onRename, toggle = props.toggle, position = props.position, tabValue = props.tabValue, isDisabled = props.isDisabled, onDisable = props.onDisable, writeDisabled = props.writeDisabled;
    var t = useTranslation().t;
    var handleRenameTab = useCallback(function () {
        onRename(tabValue);
    }, [tabValue, onRename]);
    var handleDeleteTab = useCallback(function () {
        onDelete(tabValue);
    }, [tabValue, onDelete]);
    var handleDisableTab = useCallback(function () {
        onDisable(tabValue);
    }, [onDisable, tabValue]);
    return (React.createElement(Dropdown, { isOpen: !!position, toggle: toggle, direction: 'down', style: {
            zIndex: 3002,
            position: 'fixed',
            left: position.x,
            top: position.y,
        } },
        React.createElement(DropdownToggle, { className: classes.faqContext__toggle }),
        React.createElement(ClickAwayListener, { handleClickOut: toggle, handleEnabled: !!position },
            React.createElement(DropdownMenu, { className: 'faq-tabs-context-menu', id: 'tabContextMenu' },
                React.createElement(DropdownItem, { disabled: writeDisabled, onClick: handleRenameTab, iconLeft: 'farPen' }, t('Rename')),
                React.createElement(DropdownItem, { disabled: writeDisabled, onClick: handleDisableTab, iconLeft: isDisabled ? 'farToggleOn' : 'farToggleOff' }, t(isDisabled ? 'enableSkill' : 'disableSkill')),
                React.createElement(DropdownItem, { disabled: writeDisabled, className: 'faq-tabs-context-menu__tab-delete', onClick: handleDeleteTab, iconLeft: 'farTrashAlt' }, t('Delete'))))));
}
