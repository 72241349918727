import { t } from 'localization';

import { buildCustomValidationResolver } from 'utils/validator/customValidationResolver';

import { isStateNameAlreadyExist, isTextHasSlash } from '../stageUtils';

export const checkEmptyStateName = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  name = name.trim();
  if (name) return {};
  return { name: '' };
});

export const checkStateNameNotTheSame = buildCustomValidationResolver<{ name: string }, { currentName: string }>(
  async ({ name }, context) => {
    if (!context?.currentName) return {};
    name = name.trim();
    if (name !== context?.currentName.trim()) return {};
    return { name: '' };
  }
);

export const checkSlashInStateName = buildCustomValidationResolver<{ name: string }>(async ({ name }) => {
  if (!isTextHasSlash(name)) return {};
  return { name: t('CreationScreenMenu:title:error:denySlash') };
});

export const checkStateNameIsBusy = buildCustomValidationResolver<
  { name: string },
  { allStates: string[]; parentPath?: string }
>(async ({ name }, context) => {
  if (!context) throw new Error('Context in validator "checkStateNameIsBusy" not found');
  if (!isStateNameAlreadyExist(name, context.allStates, context.parentPath)) return {};
  return { name: t('CreationScreenMenu:NameField:Error:AlreadyExist') };
});
