import { Icon } from '@just-ai/just-ui';
import React from 'react';
import './StatusMessage.scss';
export var MessageStatusDialog = function (_a) {
    var status = _a.status;
    switch (status) {
        case 'SENT':
            return React.createElement(Icon, { name: 'farCheck', className: 'grayColorIconMessageStatus ml-2', size: 'sm' });
        case 'DELIVERED':
            return React.createElement(Icon, { name: 'farCheck', className: 'primaryColorIconMessageStatus ml-2', size: 'sm' });
        case 'NOT_DELIVERED':
            return React.createElement(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus ml-2', size: 'sm' });
        case 'READ':
            return React.createElement(Icon, { name: 'farCheckDouble', className: 'primaryColorIconMessageStatus ml-2', size: 'sm' });
        case 'NOT_SENT':
            return React.createElement(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus ml-2', size: 'sm' });
        default:
            return null;
    }
};
export var MessageStatusSession = function (_a) {
    var status = _a.status, t = _a.t;
    switch (status) {
        case 'SENT':
            return (React.createElement("div", { "data-test-id": 'messageStatusSessionSent', className: 'px-2 grayBackgroundMessageStatus' },
                React.createElement(Icon, { name: 'farCheck', className: 'grayColorIconMessageStatus mr-1', size: 'sm' }),
                React.createElement("span", null, t("Analytics:ClientsDialogs:".concat(status)))));
        case 'DELIVERED':
            return (React.createElement("div", { "data-test-id": 'messageStatusSessionDelivered', className: 'px-2 primaryBackgroundMessageStatus', style: { borderRadius: '.15rem' } },
                React.createElement(Icon, { name: 'farCheck', className: 'primaryColorIconMessageStatus mr-1', size: 'sm' }),
                React.createElement("span", null, t("Analytics:ClientsDialogs:".concat(status)))));
        case 'NOT_DELIVERED':
            return (React.createElement("div", { "data-test-id": 'messageStatusSessionNotDelivered', className: 'px-2 errorBackgroundMessageStatus', style: { borderRadius: '.15rem' } },
                React.createElement(Icon, { name: 'farTimes', className: 'errorBackgroundMessageStatus mr-1', size: 'sm' }),
                React.createElement("span", null, t("Analytics:ClientsDialogs:".concat(status)))));
        case 'READ':
            return (React.createElement("div", { "data-test-id": 'messageStatusSessionRead', className: 'px-2 primaryBackgroundMessageStatus', style: { borderRadius: '.15rem' } },
                React.createElement(Icon, { name: 'farCheckDouble', className: 'primaryColorIconMessageStatus mr-1', size: 'sm' }),
                React.createElement("span", null, t("Analytics:ClientsDialogs:".concat(status)))));
        case 'NOT_SENT':
            return (React.createElement("div", { "data-test-id": 'messageStatusSessionNotSent', className: 'errorBackgroundMessageStatus mr-1' },
                React.createElement(Icon, { name: 'farTimes', className: 'errorColorIconMessageStatus mr-1', size: 'sm' }),
                React.createElement("span", null, t("Analytics:ClientsDialogs:".concat(status)))));
        default:
            return null;
    }
};
export var checkChannelForSystemMessage = function (channelType) {
    return channelType && ['chatapi', 'telegram', 'viber', 'vk'].includes(channelType);
};
