import { useEffect, useState } from 'react';
import { EditMenuBlock } from '../../../reducers/JGraph.reducer/types';
import { cacheGroup, clearCache } from '../utils/blockLayerUtils';
import { BehaviorSubject, scan, shareReplay } from 'rxjs';
import Konva from 'konva';
import { highLightConnectors$ } from '../hooks/highLightConnectors';
import { usePipeValue } from '@just-ai/just-ui/dist/utils/usePipeValue';

import { getValidKonvaName } from '../../../reducers/JGraph.reducer/Graph';

export const EditMenuBlock$ = new BehaviorSubject<EditMenuBlock | undefined>(undefined);

export const useBlockActiveStatus = (
  screenPathId: string,
  StateRef: Konva.Group | null
): [EditMenuBlock | undefined, boolean] => {
  const [editMenuBlock, setInnerEditMenuBlock] = useState<EditMenuBlock | undefined>(undefined);
  const [isActiveByConnectorHighLight, setIsActive] = useState<boolean>(false);
  useEffect(() => {
    const sub = EditMenuBlock$.subscribe(value => {
      if (screenPathId === value?.screen.pathId) {
        clearCache(StateRef);
        setInnerEditMenuBlock(value);
      }
      if (!!editMenuBlock && screenPathId !== value?.screen.pathId) {
        clearCache(StateRef);
        setInnerEditMenuBlock(undefined);
        if (StateRef) {
          cacheGroup(StateRef);
        }
      }
    });
    const sub2 = highLightConnectors$.subscribe(value => {
      const shouldHighLighted = screenPathId === value?.connector?.fromNode || screenPathId === value?.connector?.to;
      let shouldRecacheGroup = false;
      setIsActive(wasHighLighted => {
        if (wasHighLighted || shouldHighLighted) {
          clearCache(StateRef);
          shouldRecacheGroup = true;
          return shouldHighLighted;
        }
        return wasHighLighted;
      });
      if (StateRef && shouldRecacheGroup) {
        cacheGroup(StateRef);
      }
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, [screenPathId, editMenuBlock, StateRef]);

  return [editMenuBlock, isActiveByConnectorHighLight];
};
type StateCollapsed = { statePathId: string; statePath: string; collapsed: boolean };
type StoredCollapsed = { value: StateCollapsed[]; lastAction: 'load' | 'update' };
type loadStateCollapsed = { statePaths: string[] };
export const StateCollapseSubject$ = new BehaviorSubject<StateCollapsed | loadStateCollapsed | null>(null);
export const StateCollapseSubjectPipe$ = StateCollapseSubject$.pipe(
  scan((currentValue, newValue) => {
    if (newValue && 'statePathId' in newValue) {
      const newValueIndexInCurrentValue = currentValue.value.findIndex(
        stored => stored.statePathId === newValue.statePathId
      );
      if (newValueIndexInCurrentValue > -1) {
        currentValue.value.splice(newValueIndexInCurrentValue, 1, newValue);
        currentValue.value = [...currentValue.value];
      } else {
        currentValue.value.push(newValue);
        currentValue.value = [...currentValue.value];
      }
      currentValue.lastAction = 'update';
    }
    if (newValue && 'statePaths' in newValue) {
      currentValue.value = newValue.statePaths.map(statePath => ({
        statePath: statePath,
        statePathId: getValidKonvaName(statePath),
        collapsed: true,
      }));
      currentValue.lastAction = 'load';
    }
    return { ...currentValue };
  }, {} as StoredCollapsed),
  shareReplay(1)
);
export const useStateCollapse = (pathId: string) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const bhSubjectValue = usePipeValue(StateCollapseSubjectPipe$);

  useEffect(() => {
    const found = bhSubjectValue?.value.find(bhSubjectValueItem => bhSubjectValueItem.statePathId === pathId);
    if (found) {
      setCollapsed(found.collapsed);
    }
  }, [bhSubjectValue, pathId]);
  return {
    isCollapsed,
  };
};
