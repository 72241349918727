import React, { useCallback, useContext, useMemo } from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import cn from 'classnames';
import { TreeContext, TREE_DEPTH_PADDING, TREE_NODE_PADDING } from '../../index';
import { DragTypes } from '../../../../../FAQTreePage/DragTypes';
import styles from '../../styles.module.scss';
var Leaf = function (_a) {
    var _b, _c;
    var _d, _e, _f;
    var node = _a.node, isParentSelected = _a.isParentSelected, className = _a.className, style = _a.style, categoryName = _a.categoryName, isSearchActive = _a.isSearchActive, isDndEnabled = _a.isDndEnabled;
    var context = useContext(TreeContext);
    var isSelected = useMemo(function () { return context.selectedIds.includes(node.id); }, [context.selectedIds, node.id]);
    var isActive = useMemo(function () { return context.activeId && context.activeId === node.id; }, [context.activeId, node.id]);
    var onContextMenu = useCallback(function (event) {
        event.stopPropagation();
        event.preventDefault();
        context.handleContextMenu(event, node);
    }, [context, node]);
    var _g = useDrag({
        item: { node: node, type: DragTypes.LEAF },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
        canDrag: !isSearchActive,
    }), isDragging = _g[0].isDragging, drag = _g[1];
    var _h = useDrop({
        canDrop: function (item) { return item.node !== node || isDndEnabled; },
        accept: DragTypes.LEAF,
        drop: function (item) {
            isDndEnabled && context.onDragDrop(item.node, node);
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
        }); },
    }), isOver = _h[0].isOver, drop = _h[1];
    var _j = useDragLayer(function (monitor) { return ({
        isDraggingOtherElement: monitor.isDragging(),
        draggingElement: monitor.getItem(),
    }); }), isDraggingOtherElement = _j.isDraggingOtherElement, draggingElement = _j.draggingElement;
    var depthLevel = (_d = node.depthLevel) !== null && _d !== void 0 ? _d : 0;
    return (React.createElement("div", { ref: drop, style: style, className: cn((_b = {},
            _b[styles.node__dragging] = isDragging,
            _b)), onContextMenu: onContextMenu },
        React.createElement("div", { ref: drag, className: cn(styles.node, className, (_c = {},
                _c[styles.node__selected] = isSelected,
                _c[styles.node__secondary_selected] = isParentSelected,
                _c[styles.node__active] = isActive,
                _c[styles.node__dragging] = isDragging,
                _c[styles.node__dragged] = ((_e = draggingElement === null || draggingElement === void 0 ? void 0 : draggingElement.node) === null || _e === void 0 ? void 0 : _e.id) === (node === null || node === void 0 ? void 0 : node.id),
                _c[styles.node__elementDisabled] = !node.enabled,
                _c[styles.disableHover] = isDndEnabled && isDraggingOtherElement && ((_f = draggingElement === null || draggingElement === void 0 ? void 0 : draggingElement.node) === null || _f === void 0 ? void 0 : _f.id) !== (node === null || node === void 0 ? void 0 : node.id),
                _c[styles.node__isOver] = isOver,
                _c)), style: {
                paddingLeft: depthLevel ? depthLevel * TREE_DEPTH_PADDING + TREE_NODE_PADDING : '',
            }, onClick: function () { return context.onSelectToggle(node); }, "data-test-id": "FAQ.Tree.Leaf.".concat(node.name) }, categoryName ? (React.createElement("div", { className: styles.node__category },
            React.createElement("span", null, categoryName),
            React.createElement("span", { className: styles.text }, "/ ".concat(node.name)))) : (React.createElement("span", { className: styles.text }, node.name)))));
};
export default React.memo(Leaf);
