import localize from '../../../localization';

export interface i18options {
  count?: number;
  name?: string;
  error?: Array<string | null>;
}

const searchKey = /\$t\(([\w\s:]*),/;
const searchCount = /count['|"]:(.\d*)/;
const replaceRegex = /\$t\([(\w\s:,{"')]*}\)/;

const i18nTranslation = (namespace?: string, component?: string) => {
  const componentPrefix = component ? component + '.' : '';
  const t = (key: string, options?: i18options): string => {
    const nsKey = key.includes(':') ? componentPrefix + key : `${namespace}:${componentPrefix}${key}`;
    let translate = localize.translate(nsKey);
    if (options && options.count !== undefined) {
      const count = Number(options.count);
      const arrayPhrases: string[] = [
        localize.translate(nsKey + '_0') !== nsKey + '_0'
          ? localize.translate(nsKey + '_0')
          : localize.translate(nsKey),
        localize.translate(nsKey + '_1') !== nsKey + '_1'
          ? localize.translate(nsKey + '_1')
          : localize.translate(nsKey),
        localize.translate(nsKey + '_2') !== nsKey + '_2'
          ? localize.translate(nsKey + '_2')
          : localize.translate(nsKey),
      ];
      translate = localize.decliner(arrayPhrases, count).replace(/{{count}}/g, count);
      const matchedKey = translate.match(searchKey);
      if (matchedKey && matchedKey[1]) {
        const matchedCount = translate.match(searchCount);
        const newCount = matchedCount && matchedCount[1] !== undefined ? Number(matchedCount[1]) : null;
        translate = translate.replace(replaceRegex, t(matchedKey[1], newCount ? { count: newCount } : undefined));
      }
    }
    if (options && options.name !== undefined) {
      translate = translate.replace(/{{name}}/g, options.name);
    }
    if (options && options.error !== undefined) {
      return localize.translate(nsKey, typeof options.error === 'object' ? '' : options.error);
    }
    return translate;
  };
  return { t };
};

const isExistKey = (namespacedKey: string) => {
  return localize.translate(namespacedKey) !== namespacedKey;
};

export { i18nTranslation, isExistKey };
