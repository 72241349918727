import React, { useContext, useCallback, useMemo, useRef } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsContext';
import { IconButton } from '@just-ai/just-ui';
import moment from 'moment';
export default function SessionInfo(_a) {
    var t = _a.t;
    var session = useContext(AnalyticsContext).session;
    var sessionRef = useRef(null);
    var clientRef = useRef(null);
    var _b = session || {}, sessionId = _b.sessionId, client = _b.client, botId = _b.botId, rawDuration = _b.duration, startTime = _b.startTime;
    var duration = useMemo(function () {
        var duration = moment(new Date(rawDuration));
        return ['years', 'days', 'hours', 'minutes', 'seconds'].reduce(function (result, x) {
            //@ts-ignore
            var diff = duration.diff(moment(0), x);
            if (diff > 0) {
                //@ts-ignore
                duration.subtract(diff, x);
                return result + "".concat(diff, " ").concat(t(x, diff), " ");
            }
            else {
                return result;
            }
        }, '');
    }, [rawDuration]);
    var copySession = useCallback(function () {
        var _a, _b;
        var supported = document.queryCommandSupported('copy');
        if ((sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) && supported) {
            (_a = sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) === null || _a === void 0 ? void 0 : _a.select();
            (_b = sessionRef === null || sessionRef === void 0 ? void 0 : sessionRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            window.document.execCommand('copy');
        }
    }, []);
    var copyClient = useCallback(function () {
        var _a, _b;
        var supported = document.queryCommandSupported('copy');
        if ((clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) && supported) {
            (_a = clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) === null || _a === void 0 ? void 0 : _a.select();
            (_b = clientRef === null || clientRef === void 0 ? void 0 : clientRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            window.document.execCommand('copy');
        }
    }, []);
    return (React.createElement("div", { className: 'Session_SessionInfo_container' }, session && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'Session_SessionInfo_sessionId_clientId' },
            sessionId && (React.createElement("div", null,
                React.createElement("div", null, t('Analytics: sessionId label')),
                React.createElement("div", { title: sessionId },
                    sessionId,
                    React.createElement("input", { type: 'text', value: sessionId, ref: function (ref) { return (sessionRef.current = ref); }, className: 'hiddenInput' })),
                React.createElement(IconButton, { name: 'farCopy', onClick: copySession, withoutPadding: true }))),
            (client === null || client === void 0 ? void 0 : client.id) && (React.createElement("div", null,
                React.createElement("div", null, t('Analytics: clientId label')),
                React.createElement("div", { className: 'Session_SessionInfo_clientId', title: client.id },
                    client.id,
                    React.createElement("input", { type: 'text', value: client.id, ref: function (ref) { return (clientRef.current = ref); }, className: 'hiddenInput' })),
                React.createElement(IconButton, { name: 'farCopy', onClick: copyClient, withoutPadding: true })))),
        botId && (React.createElement("div", { className: 'Session_SessionInfo_botId' },
            React.createElement("div", null, t('Analytics: botId label')),
            React.createElement("div", { title: botId }, botId))),
        React.createElement("div", { className: 'Session_SessionInfo_time' },
            React.createElement("div", null,
                React.createElement("div", null, t('Analytics: sessionsTable head startTime')),
                React.createElement("div", null, moment(startTime).format('LLL'))),
            React.createElement("div", null,
                React.createElement("div", null, t('Analytics: sessionsTable head duration')),
                React.createElement("div", null, duration)))))));
}
