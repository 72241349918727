import React, { FC } from 'react';
import { SwitchButton } from '@just-ai/just-ui';

import { withController } from './Controller';
import { CommonFieldProps } from './types';

const BoolFieldView: FC<CommonFieldProps> = ({ name, label, onChange, value }) => {
  return (
    <div className='form-group form-group-fix'>
      <SwitchButton
        id={`switch-button-${name}`}
        label={label || name}
        labelPosition='left'
        offLabel=''
        onChange={onChange}
        onLabel=''
        size='md'
        value={value}
      />
    </div>
  );
};

export const BoolField = withController(BoolFieldView);
