var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { UncontrolledDropdown, DropdownToggle as RsDropdownToggle, DropdownItem as RsDropdownItem, Dropdown as RsDropdown, DropdownMenu as RsDropdownMenu, } from 'reactstrap';
import { Icon } from '../Icon';
import './Dropdown.scss';
import classNames from 'classnames';
export const DropdownButton = (_a) => {
    var { className, direction = 'right', compact, dark } = _a, restProps = __rest(_a, ["className", "direction", "compact", "dark"]);
    return (React.createElement(UncontrolledDropdown, Object.assign({ className: classNames('justui_dropdown', 'justui_dropdown-button', { dark: dark, compact: compact }, className), direction: direction }, restProps)));
};
export const DropdownToggle = (_a) => {
    var { className, children, caret, iconButton, compact, dark, color } = _a, restProps = __rest(_a, ["className", "children", "caret", "iconButton", "compact", "dark", "color"]);
    return (React.createElement(RsDropdownToggle, Object.assign({ color: dark ? 'none' : color, className: classNames('justui_dropdown-toggle', {
            'justui_icon-button': iconButton,
            'compact btn-flat': compact,
            'btn-white': dark,
        }, className) }, restProps),
        children,
        caret ? (React.createElement(Icon, { name: compact ? 'faCaretDown' : 'faAngleDown', size: compact ? 'xs' : '1x', className: 'dropdown-caret' })) : null));
};
export const DropdownItem = (_a) => {
    var { children, style, danger = false, iconRight, iconLeft } = _a, restProps = __rest(_a, ["children", "style", "danger", "iconRight", "iconLeft"]);
    return (React.createElement(RsDropdownItem, Object.assign({ style: Object.assign({ cursor: 'pointer' }, style), className: classNames('justui_dropdown-item', restProps.className, { 'dropdown-item-danger': danger }) }, restProps),
        iconLeft ? React.createElement(Icon, { name: iconLeft, size: 'sm' }) : null,
        children,
        iconRight ? React.createElement(Icon, { name: iconRight, size: 'sm' }) : null));
};
export const Dropdown = (_a) => {
    var { className, compact, dark } = _a, restProps = __rest(_a, ["className", "compact", "dark"]);
    return (React.createElement(RsDropdown, Object.assign({ className: classNames('justui_dropdown', { compact, dark }, className) }, restProps)));
};
export const DropdownMenu = (_a) => {
    var { className } = _a, restProps = __rest(_a, ["className"]);
    return (React.createElement(RsDropdownMenu, Object.assign({ className: classNames('justui_dropdown-menu', className) }, restProps)));
};
