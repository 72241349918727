import React from 'react';
import { Tooltip, ChannelIcon } from '../../index';
import cn from 'classnames';
import { useSupportAudioInfo } from './useSupportAudioInfo';
import { useTranslation } from '../../contexts/transtationContext';
const channelTypeMap = {
    ALISA: 'YANDEX',
    GOOGLE_ASSISTANCE: 'GOOGLE',
    OUTGOING_CALLS: 'telephony',
    MESSENGERS: 'messengers',
};
const SupportChannelIcon = React.memo(({ channel, compatibleFlag }) => {
    const type = channel in channelTypeMap ? channelTypeMap[channel] : channel;
    const { t } = useTranslation();
    if (compatibleFlag) {
        if (type === 'messengers') {
            return (React.createElement(React.Fragment, null,
                React.createElement(ChannelIcon, { size: 'medium', type: 'chat_widget' }),
                React.createElement("span", { id: `compatibleChannel-${type}-${channel}` },
                    React.createElement(ChannelIcon, { size: 'medium', type: 'messengers' })),
                React.createElement(Tooltip, { placement: 'top', target: `compatibleChannel-${type}-${channel}`, textAlign: 'left' }, t(`Player renderCompatibleChannels ${channel}`)
                    .split('\n')
                    .map((text) => (React.createElement("div", { key: text }, text))))));
        }
        return React.createElement(ChannelIcon, { size: 'medium', type: type });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { id: `incompatibleChannel-${type}-${channel}` },
            React.createElement(ChannelIcon, { size: 'medium', type: type })),
        React.createElement(Tooltip, { placement: 'top', target: `incompatibleChannel-${type}-${channel}`, textAlign: 'left' },
            React.createElement(React.Fragment, null, t(`Player renderIncompatibleChannels ${channel}`)
                .split('\n')
                .map((text) => (React.createElement("div", { key: text }, text)))))));
});
const SupportAudioChannels = function ({ fileUrl, className }) {
    const supportInfo = useSupportAudioInfo(fileUrl);
    const { t } = useTranslation();
    if (supportInfo.incompatible.length === 0 && supportInfo.compatible.length === 0)
        return null;
    return (React.createElement("div", { className: cn('flex-column gap-4x', className) },
        supportInfo.compatible.length && (React.createElement("div", { className: 'flex-column gap-2x supported-channels-block' },
            React.createElement("div", null, t('AudioSettings:compatibleChannelsTitle')),
            React.createElement("div", { className: 'flex-row gap-1x' }, supportInfo.compatible.map(channel => (React.createElement(SupportChannelIcon, { key: channel, channel: channel, compatibleFlag: true })))))),
        supportInfo.incompatible.length && (React.createElement("div", { className: 'flex-column gap-2x unsupported-channels-block' },
            React.createElement("div", null, t('AudioSettings:incompatibleChannelsTitle')),
            React.createElement("div", { className: 'flex-row gap-1x' }, supportInfo.incompatible.map(channel => (React.createElement(SupportChannelIcon, { key: channel, channel: channel }))))))));
};
export default React.memo(SupportAudioChannels);
