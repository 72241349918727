import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { RootState } from 'storeTypes';
import { isSystemAccess } from 'isAccessFunction';

import JGraphRollbackService, { RevertEvent } from 'modules/JGraph/services/JGraphRollbackService';
import { JGraphLicensed } from 'modules/JGraph/constants';
import { blockModalSubject$ } from 'modules/JGraph/view/BlockModal';

import { mainSave$ } from 'modules/JGraph/hooks/savingPipe';
import { getMinMaxScreensCoords } from 'modules/JGraph/utils/common';
import { scrollToTargetGlobal$ } from 'modules/JGraph/utils/stageUtils';
import { findScreenByPath } from 'reducers/JGraph.reducer/Graph';
import { restoreState, reverting, setEditMenuBlock, updateLastModification } from '../index';
import { getGraph, getJGraphVisuals } from 'reducers/JGraph.reducer/JGraphAsyncActions';

import { GraphApi, getParentPaths, checkNameExistInGroup } from '../utils';
import { getStickerAfterChangeStateLevel, insertStickersIdsToStates } from 'modules/JGraph/view/Sticker/utils';
import moveStickers from './moveStickers';

export const moveStateInGroup = createAsyncThunk(
  'JGraph/moveStateInGroup',
  async (moveStatePath: { what: string; where: string }, thunkAPI) => {
    let state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;
    if (!isSystemAccess([JGraphLicensed])) {
      blockModalSubject$.next(true);
      return;
    }

    let jStateToMove = cloneDeep(findScreenByPath(moveStatePath.what, state.JGraphReducer.graph.blocks));
    if (!jStateToMove) return;
    const stateBeforeUpdate = {
      x: jStateToMove.x,
      y: jStateToMove.y,
      path: jStateToMove.path,
    };

    const { data } = await GraphApi.deleteState(userAccountId || accountId, projectShortName, {
      targetStatePath: jStateToMove.path,
      file: {
        filename: jStateToMove.filename,
        lastModification: state.JGraphReducer.graph.files[jStateToMove.filename],
      },
      includeChildren: true,
    });
    await thunkAPI.dispatch(
      updateLastModification({ lastModification: data.lastModification, filename: jStateToMove.filename })
    );
    state = thunkAPI.getState() as RootState;

    if (moveStatePath.where === '/') {
      const { isExist, guessName } = checkNameExistInGroup(state.JGraphReducer.graph.blocks, '', jStateToMove.path);

      if (isExist) {
        jStateToMove.path = guessName;
        jStateToMove.value = guessName.slice(1);
      }
      const { minX, minY } = getMinMaxScreensCoords(state.JGraphReducer.graph.blocks);
      jStateToMove.x = minX - 450;
      jStateToMove.y = minY;

      const stateWithStickers = insertStickersIdsToStates(jStateToMove, state.JGraphReducer.stickers);

      await GraphApi.createState(userAccountId || accountId, projectShortName, {
        stateToCreate: stateWithStickers,
        parentThemePath: '/',
        parentStatePath: undefined,
        file: {
          filename: state.JGraphReducer.graph.blocks[0].filename,
          lastModification: state.JGraphReducer.graph.files[state.JGraphReducer.graph.blocks[0].filename],
        },
      });
    } else {
      const jStateIntoMove = findScreenByPath(moveStatePath.where, state.JGraphReducer.graph.blocks);
      if (!jStateIntoMove) return;
      const { isExist, guessName } = checkNameExistInGroup(
        jStateIntoMove.states || [],
        jStateIntoMove.path,
        jStateToMove.path
      );

      if (isExist) {
        jStateToMove.path = guessName;
        jStateToMove.value = guessName.slice(1);
      }
      const { minX, minY } =
        (jStateIntoMove.states || []).length === 0 ||
        (jStateIntoMove.states || []).every(state => state.x === 0 && state.y === 0)
          ? { minX: 0, minY: 0 }
          : getMinMaxScreensCoords(jStateIntoMove.states || []);

      jStateToMove.x = minX !== 0 ? minX - 450 : minX;
      jStateToMove.y = minY;

      const stateWithStickers = insertStickersIdsToStates(jStateToMove, state.JGraphReducer.stickers);
      await GraphApi.createState(userAccountId || accountId, projectShortName, {
        stateToCreate: stateWithStickers,
        parentThemePath: jStateIntoMove.theme,
        parentStatePath: jStateIntoMove.path,
        file: {
          filename: jStateIntoMove.filename,
          lastModification: state.JGraphReducer.graph.files[jStateIntoMove.filename],
        },
      });
    }

    const connectedStickers = getStickerAfterChangeStateLevel(
      stateBeforeUpdate,
      jStateToMove,
      state.JGraphReducer.stickers
    );
    await thunkAPI.dispatch(getGraph({}));
    await thunkAPI.dispatch(moveStickers(connectedStickers));
    await thunkAPI.dispatch(getGraph({}));

    await thunkAPI.dispatch(setEditMenuBlock(undefined));

    mainSave$.next({
      type: 'delete',
      path: jStateToMove.path,
      action: () => Promise.resolve(),
    });
    scrollToTargetGlobal$.next({
      targetPathId: jStateToMove.path,
      isSideMenuOpen: true,
    });
    return jStateToMove.path;
  }
);

export const revertMoveStateInGroup = createAsyncThunk(
  'JGraph/revertMoveStateInGroup',
  async (revertEvent: RevertEvent<{ what: string; where: string }, string>, thunkAPI) => {
    const revertScreen = findScreenByPath(revertEvent.payload.what, revertEvent.prevState.JGraphReducer.graph.blocks);
    if (!revertScreen) return;
    await thunkAPI.dispatch(reverting(true));
    const { parentStatePath } = getParentPaths(revertEvent.payload.what, revertScreen.theme);
    await thunkAPI.dispatch(
      restoreState({
        screen: revertScreen,
      })
    );

    let state = thunkAPI.getState() as RootState;
    // @ts-ignore
    const userAccountId = state.CurrentUserReducer.account?.id;
    const accountId = state.CurrentAccountReducer.account?.id;
    const projectShortName = state.CurrentProjectsReducer.currentProject;

    const stateToDelete = findScreenByPath(
      [
        revertEvent.payload.where,
        revertEvent.result.startsWith(parentStatePath || '')
          ? revertEvent.result.slice((parentStatePath || '').length)
          : revertEvent.result,
      ]
        .join('/')
        .replace(/\/+/g, '/'),
      state.JGraphReducer.graph.blocks
    );

    if (!stateToDelete) return;

    const { data: deleteStateLastModification } = await GraphApi.deleteState(
      userAccountId || accountId,
      projectShortName,
      {
        targetStatePath: stateToDelete.path,
        file: {
          filename: stateToDelete.filename,
          lastModification: state.JGraphReducer.graph.files[stateToDelete.filename],
        },
        includeChildren: true,
      }
    );
    await thunkAPI.dispatch(
      updateLastModification({
        lastModification: deleteStateLastModification.lastModification,
        filename: stateToDelete.filename,
      })
    );
    state = thunkAPI.getState() as RootState;
    const { data } = await GraphApi.createState(userAccountId || accountId, projectShortName, {
      stateToCreate: revertScreen,
      parentThemePath: stateToDelete.theme,
      parentStatePath,
      file: {
        filename: revertScreen.filename,
        lastModification: state.JGraphReducer.graph.files[revertScreen.filename],
      },
    });
    await thunkAPI.dispatch(getJGraphVisuals());
    await thunkAPI.dispatch(getGraph({}));
    await thunkAPI.dispatch(reverting(false));
    mainSave$.next({
      type: 'delete',
      path: revertScreen.path,
      action: () => Promise.resolve(),
    });
    return data;
  }
);

export default JGraphRollbackService.addRevertAsyncDecorator(revertMoveStateInGroup, moveStateInGroup);
