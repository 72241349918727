import React from 'react';
import { Line, Path } from 'react-konva';
import { STATIC_TRIANGLE } from '../../../utils/connectionLayerUtils';
import { border_active, BUTTON_WIDTH, gray_400, STATIC_TOP_OFFSET } from './index';

export function IncomingCurvedLine(props: { textSize: number; topIncomingOffset: number; debugActive?: boolean }) {
  return (
    <>
      <Line
        points={[props.textSize + 6 + BUTTON_WIDTH, 0, props.textSize + 6 + BUTTON_WIDTH + 14, 0]}
        strokeWidth={2}
        stroke={props.debugActive ? border_active : gray_400}
      />
      <Line
        points={[props.textSize + 6 + BUTTON_WIDTH + 14, 0, props.textSize + 6 + BUTTON_WIDTH + 14, -32]}
        strokeWidth={2}
        stroke={props.debugActive ? border_active : gray_400}
      />
      {props.topIncomingOffset === STATIC_TOP_OFFSET && (
        <>
          <Line
            points={[props.textSize + 6 + BUTTON_WIDTH + 14, -32, props.textSize + 6 + BUTTON_WIDTH + 14 + 46, -32]}
            strokeWidth={2}
            stroke={props.debugActive ? border_active : gray_400}
          />
          <Path
            x={props.textSize + 6 + BUTTON_WIDTH + 14 + 46}
            stroke={props.debugActive ? border_active : gray_400}
            y={-32}
            data={STATIC_TRIANGLE()}
            strokeWidth={2}
            hitStrokeWidth={10}
          />
        </>
      )}
    </>
  );
}
