var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { IntentItemType } from '../../../../nlu-related/utils/intents';
import { Intents } from '../../../../nlu-related/utils';
export var generateUniqId = function () {
    return '_' + Math.random().toString(36).substr(2, 9);
};
export var toggleIntentItemType = function (item) { return (__assign(__assign({}, item), { type: item.type === IntentItemType.pattern ? IntentItemType.phrase : IntentItemType.pattern })); };
export var toIntentPhrase = function (text, type) { return ({
    id: generateUniqId(),
    type: type,
    text: text,
}); };
export var phraseToIntentItem = function (phrase) {
    return toIntentPhrase(phrase.text || '', IntentItemType.phrase);
};
export var patternToIntentItem = function (pattern) {
    return toIntentPhrase(pattern || '', IntentItemType.pattern);
};
export var convertToIntentPhrases = function (intent) {
    var intentItems = __spreadArray(__spreadArray([], (intent.patterns || []).map(patternToIntentItem), true), (intent.phrases || []).map(phraseToIntentItem), true);
    var intentQuestion = Intents.getNameFromPath(intent.path);
    return intentItems.filter(function (phrase) { return phrase.text !== intentQuestion; });
};
