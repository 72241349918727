import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { withController } from './Controller';
import { Button } from '@just-ai/just-ui';
import { v4 as uuid } from 'uuid';
import { NameValueItem } from './NameValueItem.field';
import { t } from 'localization';
import { CommonFieldProps } from './types';
import { CommonLabel } from './CommonLabel';

type TNameValueArrayItem = { name: string; value: string };
type NameValueArrayItemWithKey = { key: string; value: TNameValueArrayItem };

const prepareValues = (value: string | TNameValueArrayItem[]): NameValueArrayItemWithKey[] => {
  const nameValueArray: TNameValueArrayItem[] = typeof value === 'string' ? JSON.parse(value) : value;
  return nameValueArray.map(value => ({
    key: uuid(),
    value,
  }));
};

const NameValueView: FC<CommonFieldProps> = ({ name, label, isRequired, value, onChange, description }) => {
  const [innerValues, setInnerValues] = useState(prepareValues(value));
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const onClickAdd = useCallback(() => {
    setInnerValues(innerValues => {
      innerValues.push({
        key: uuid(),
        value: {
          name: '',
          value: '',
        },
      });
      return [...innerValues];
    });
  }, []);

  const onChangeItem = useCallback((index: number, field: keyof TNameValueArrayItem, value: string) => {
    setInnerValues(innerValues =>
      produce(innerValues, draft => {
        draft[index].value[field] = value;
      })
    );
  }, []);

  const onRemoveItem = useCallback((index: number) => {
    setInnerValues(innerValues => {
      innerValues.splice(index, 1);
      return [...innerValues];
    });
  }, []);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(innerValues.map(value => value.value || { name: '', value: '' }));
    }, 100);
  }, [innerValues, onChange]);

  return (
    <div className='form-group form-group-fix'>
      <CommonLabel name={name} label={label} description={description} isRequired={isRequired} />
      {innerValues.map((innerValue, index) => (
        <NameValueItem
          key={innerValue.key}
          onRemove={onRemoveItem}
          index={index}
          onChange={onChangeItem}
          value={innerValue.value}
        />
      ))}
      <div>
        <Button color='primary' iconLeft='farPlus' iconRight='' flat onClick={onClickAdd} size='md'>
          {t('CustomTags:StringArray:AddField')}
        </Button>
      </div>
    </div>
  );
};

export const NameValueArray = withController(NameValueView);
