export const jgloaderrorLocalization = {
  'LoadJGraphErrorModal:title': {
    eng: 'Error loading J-Graph',
    ru: 'Ошибка загрузки J-Graph',
  },
  'LoadJGraphErrorModal:Return to code': {
    eng: 'Go to code editor',
    ru: 'Перейти в редактор кода',
  },
  'LoadJGraphErrorModal:editorbe.jgraph.empty_state_name': {
    eng: 'The script contains states without names. It may cause J-Graph instability. Go to the code editor and specify the names.',
    ru: 'В сценарии есть стейты без названий. Это может привести к нестабильной работе J-Graph. Перейдите в редактор кода и укажите названия.',
  },
};
